// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './CheckBoxCardV2.module.scss';
import React from 'react';
import { CustomCheckBoxV2 } from 'components/atoms/CustomCheckboxV2';

interface Props {
  onChange?: (e: React.ChangeEvent) => void;
  checked: boolean;
  id?: string;
  children?: React.ReactNode;
  childCSS?: string;
  type?: 'dark' | 'default';
}

export function CheckBoxCardV2({
  onChange,
  id,
  checked,
  children,
  childCSS,
  type,
}: Props) {
  return (
    <div className={styles.checkCard}>
      <div>
        <CustomCheckBoxV2
          id={id}
          type={type}
          checked={checked}
          onChange={(value) => onChange && onChange(value)}
        />
      </div>
      <div className={childCSS ? childCSS : styles.children}>{children}</div>
    </div>
  );
}
