// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { useTranslation } from 'react-i18next';
import styles from './AdminSiteBarcodeListManagement.module.scss';
import { DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import { IconEyeOpen } from 'components/atoms/icons/IconEyeOpen';
import { ChangeEvent, useEffect, useState } from 'react';
import { ScanBarcodeModal } from 'components/molecules/ScanBarcodeModal';
import { createAxios } from 'ts/createAxios';
import { handleError } from 'util/errorHandler';
import TableLoading from 'components/atoms/TableLoading';

export type BarCodeItem = {
  id: string;
  name: string;
  barcode: string;
};

export type FetchBarcodeData = {
  page?: number;
  total?: number;
  tenants: BarCodeItem[];
};

const AdminSiteBarcodeListManagement = () => {
  const { t } = useTranslation();
  const api = createAxios();
  const [barcode, setBarcode] = useState<BarCodeItem>();
  const [listOfBarcode, setListOfBarcode] = useState<BarCodeItem[]>([]);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [total, setTotal] = useState(0);

  //call api list barcode
  const fetchListbarcode = async (
    nextPage?: number,
    forceRefresh?: boolean
  ) => {
    const params = new URLSearchParams();
    try {
      if (nextPage) {
        params.append('page', String(nextPage || DEFAULT_PAGE));
        params.append('take', String(PAGE_SIZE));
      }
      const response = await api.get<FetchBarcodeData>('/emissions/barcodes', {
        params,
      });

      if (forceRefresh) {
        setListOfBarcode(response.data.tenants);
      } else {
        setListOfBarcode([...listOfBarcode, ...response.data.tenants]);
      }
      nextPage && setCurrentPage(nextPage);
      response.data.total && setTotal(response.data.total);
    } catch (error) {
      handleError(error);
    }
  };

  const handleClickBarcode = (e: ChangeEvent, item: BarCodeItem) => {
    e.stopPropagation();
    item && setBarcode(item);
  };

  useEffect(() => {
    fetchListbarcode();
  }, []);

  return (
    <PrimaryTemplate
      h1={t('barcodes.heading')}
      className={styles.barcodesListManagement}
    >
      <div className={styles.barcodesList}>
        <div
          className={styles.countList}
          dangerouslySetInnerHTML={{
            __html:
              t('common.page', {
                page: total || 0,
              }) ?? '',
          }}
        />

        <div className={styles.table}>
          <table id={styles.barcodesListTable}>
            <thead>
              <tr>
                <th className={styles.tenantName}>
                  {t('barcodes.tenant_name')}
                </th>
                <th className={styles.barcode}>{t('barcodes.barcode')}</th>
                <th className={styles.actionField}>{t('barcodes.action')}</th>
              </tr>
            </thead>
            <tbody>
              {listOfBarcode && listOfBarcode.length > 0 ? (
                listOfBarcode.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      onClick={() => {
                        //todo
                      }}
                    >
                      <td
                        className={`${styles.tenantName} ${styles.tareName1}`}
                      >
                        <p>{item.name}</p>
                      </td>
                      <td className={`${styles.barcode} ${styles.weight1}`}>
                        <p>{item.barcode}</p>
                      </td>
                      <td className={styles.actionField}>
                        {item.barcode && (
                          <div
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onClick={(e: any) => {
                              handleClickBarcode(e, item);
                            }}
                          >
                            <IconEyeOpen />
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4} className={styles.msgError}>
                    {t('messages.M_008')}
                  </td>
                </tr>
              )}

              {barcode && (
                <ScanBarcodeModal
                  barcode={barcode}
                  onClose={() => setBarcode(undefined)}
                />
              )}
            </tbody>
          </table>

          {listOfBarcode && listOfBarcode.length > 0 && (
            <TableLoading
              onNext={() => {
                if (
                  listOfBarcode.length >= PAGE_SIZE &&
                  listOfBarcode.length < total
                ) {
                  fetchListbarcode(currentPage + 1);
                  setCurrentPage(currentPage + 1);
                }
              }}
            />
          )}
        </div>
      </div>
    </PrimaryTemplate>
  );
};
export default AdminSiteBarcodeListManagement;
