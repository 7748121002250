// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Modal } from 'components/atoms/Modal';
import 'react-calendar/dist/Calendar.css';
import styles from './ScanBarcodeModal.module.scss';
import { IconClose } from 'components/atoms/icons/IconClose';
import { BarCodeItem } from 'pages/AdminSiteBarcodeListManagement/AdminSiteBarcodeListManagement';
import Barcode from 'react-barcode';
import { useTranslation } from 'react-i18next';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';

export type DownloadModalProps = {
  onClose: () => void;
  barcode?: BarCodeItem;
};

export const ScanBarcodeModal = ({ onClose, barcode }: DownloadModalProps) => {
  const { t } = useTranslation();

  const handleCaptureClick = async () => {
    const barcodeImage = document.getElementById('barcodeImage');
    barcodeImage?.setAttribute('style', 'background: #16171B');
    const canvas = await html2canvas(barcodeImage as HTMLElement);
    const dataURL = canvas.toDataURL('image/png');
    downloadjs(dataURL, `${barcode?.barcode}.png`, 'image/png');
  };

  return (
    <section className={styles.scanBarcodeModal}>
      <Modal>
        <div className={styles.customTitle}>
          <div className={styles.title}>{barcode?.name}</div>
          <div className={styles.top} onClick={onClose}>
            <IconClose />
          </div>
        </div>

        <div className={styles.body} id="barcodeImage">
          <Barcode
            margin={12}
            textMargin={20}
            fontSize={14}
            width={3}
            value={barcode?.barcode || ''}
            displayValue={false}
          />
          <p>{barcode?.barcode || ''}</p>
        </div>

        <div className={styles.submit}>
          <button
            className={styles.submitButton}
            type="submit"
            onClick={handleCaptureClick}
          >
            {t('barcodes.modal_download.button_download')}
          </button>
        </div>
      </Modal>
    </section>
  );
};
