// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import React, { useRef, useState } from 'react';
import styles from './AdminUploadFileCSVModal.module.scss';
import { IconDelete } from 'components/atoms/icons/IconDelete';
import { IconCloudUpload } from 'components/atoms/icons/IconCloudUpload';
import { formatBytes } from 'ts/formatBytes';
import { ErrorText } from 'components/atoms/ErrorText';
import { createAxios } from 'ts/createAxios';
import { toast } from 'react-toastify';
import { msg } from 'util/message';
import { handleError } from 'util/errorHandler';
import { getBase64 } from 'components/molecules/UploadFiles';
import { useTranslation } from 'react-i18next';
import { validateFiles } from 'util/validator';

interface Props {
  onClose: () => void;
  onSubmitImportFile: () => void;
  endPoint: string;
  isImportDataResource?: boolean;
}

const AdminUploadFileCSVModal: React.FC<Props> = ({
  onClose,
  onSubmitImportFile,
  endPoint,
  isImportDataResource,
}) => {
  const api = createAxios();
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [fileObject, setFileObject] = useState<{
    file: File | undefined;
    base64: string;
  }>({
    file: undefined,
    base64: '',
  });
  const [error, setError] = useState('');
  const [errorShow, setErrorShow] = useState(false);

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      setFileObject({ file: undefined, base64: '' });
      return;
    }
    setErrorShow(false);
    const msgError = validateFiles({
      files: e.target.files,
    });
    if (msgError) {
      setError(msgError);
      e.target.value = '';
      return;
    }
    setError('');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getBase64(e.target.files[0], (res: any) => {
      setFileObject({ file: e.target.files?.[0], base64: res });
      e.target.value = '';
    });
  };

  const handleSubmitImportFile = async () => {
    try {
      if (isImportDataResource) {
        await api.put(endPoint, {
          file: fileObject.base64,
        });
      } else {
        await api.post(endPoint || '', {
          file: fileObject.base64,
        });
      }

      onSubmitImportFile();
      toast.success(t('messages.M_026'));
    } catch (e) {
      handleError(e, true);
      setErrorShow(true);
    }
  };

  return (
    <div className={styles.uploadFileCSVModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.top}>
            <div className={styles.title}>CSVをインポート</div>
            <div className={styles.closeIcon} onClick={onClose}>
              <IconClose />
            </div>
          </div>
          <div>
            <div className={styles.uploadFileContent}>
              <div className={styles.uploadFileIcon}>
                <IconCloudUpload />
              </div>
              <span>インポートするファイルを選択</span>
              <p>ファイルが1MBを超えないようにしてください。</p>
              <label htmlFor="file-upload" className={styles.customFileUpload}>
                <p className={styles.uploadFileButton}>ファイルを選択</p>
              </label>
              <input
                ref={inputRef}
                id="file-upload"
                name="file"
                type="file"
                accept=".csv"
                onChange={handleChangeFile}
              />
            </div>
          </div>
          {fileObject.file && (
            <div className={styles.uploadFileInfo}>
              <div className={styles.uploadFileName}>
                <span>{fileObject.file?.name}</span>
              </div>

              {!errorShow ? (
                <div className={styles.uploadFileAction}>
                  <div className={styles.uploadFileSize}>
                    {formatBytes(fileObject.file?.size)}
                  </div>
                  <div
                    className={styles.deleteIcon}
                    onClick={() => {
                      if (inputRef && inputRef.current?.value) {
                        inputRef.current.value = '';
                      }
                      setFileObject({ file: undefined, base64: '' });
                      setError('');
                    }}
                  >
                    <IconDelete />
                  </div>
                </div>
              ) : (
                <ErrorText
                  text={t('messages.M_038') || msg('M_038')}
                  className="error"
                />
              )}
            </div>
          )}

          {error && (
            <div className={styles.error}>
              <p>{error}</p>
            </div>
          )}
          <div className={styles.uploadFileButton}>
            <button
              disabled={!fileObject.file || errorShow}
              className={styles.submitButton}
              type="button"
              onClick={handleSubmitImportFile}
            >
              ファイルをインポート
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AdminUploadFileCSVModal;
