// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  onClick?: () => void;
}

export const IconNoBarcode = ({ onClick }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 20V4H4V20H2ZM5 20V4H7V20H5ZM7.5 4V20H10.5V4H7.5ZM11.5 20V4H12.5V20H11.5ZM13.5 4V20H16.5V4H13.5ZM17.5 20V4H18.5V20H17.5ZM20 4V20H22V4H20Z"
        fill="#6F7175"
      />
    </svg>
  );
};
