// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import useNetworkConnection from './useNetworkConnection';
import { OFFLINE_USAGE_SUPPORT_ROUTES } from 'routes';

export const useCheckOfflineUsage = () => {
  const isOnline = useNetworkConnection();
  const { pathname } = useLocation();

  const offlineUsageAvailable = useMemo(
    () => OFFLINE_USAGE_SUPPORT_ROUTES.some((path) => pathname.includes(path)),
    [pathname]
  );

  useEffect(() => {
    if (!isOnline) {
      try {
        window.useWebsiteOffline(offlineUsageAvailable);
      } catch (e) {
        console.error(e);
      }
    }
  }, [offlineUsageAvailable, pathname, isOnline]);

  return {
    showOfflineModal: !isOnline && !offlineUsageAvailable,
  };
};
