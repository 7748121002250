import { ImagePath } from 'util/ConstantValues';
import styles from './P404.module.scss';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { OperatorType } from 'util/Enums';
const Page404 = () => {
  const history = useHistory();
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  return (
    <div
      className={`${styles.page} ${
        user.operatorType === OperatorType.Admin ? styles.pageAdmin : ''
      }`}
    >
      <div
        className={styles.bgr}
        style={{
          backgroundImage: `url(${ImagePath.background404})`,
        }}
      ></div>

      <div
        className={`${styles.pageNotFound} ${
          user.operatorType === OperatorType.Admin
            ? styles.pageNotFoundAdmin
            : ''
        }`}
      >
        <p className={styles.p404}>404</p>
        <p className={styles.title}>{t('page404.title')}</p>
        <p className={styles.description}>{t('page404.description')}</p>
        <button
          className={`${styles.button} ${
            user.operatorType === OperatorType.Admin ? styles.buttonAdmin : ''
          }`}
          onClick={() => {
            history.push('/');
          }}
        >
          {t('page404.button')}
        </button>
      </div>
    </div>
  );
};
export default Page404;
