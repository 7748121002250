// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { createAxios } from 'ts/createAxios';
import { getApiPath } from 'util/commons';
import { API_ENDPOINTS } from 'util/endPoints';
import { handleError } from 'util/errorHandler';
import { ResSites } from 'util/siteManagementTypes';
import { AccountOperator } from 'util/accountManagementTypes';
import { OperatorType } from 'util/Enums';
import {
  CertificatesPDFData,
  HistoryProduct,
  PurchaseReceiveObj,
  PurchasesReceivesParams,
  TraceDataDetail,
  WorkHistories,
} from 'types/purchase';
import { GetListParams } from 'types/api';

export const getPurchasesReceives = async (p: {
  params: PurchasesReceivesParams;
  successCallback?: (data: PurchaseReceiveObj) => void;
}) => {
  const { params, successCallback } = p;
  const api = createAxios();

  try {
    const { data } = await api.get<PurchaseReceiveObj>(
      getApiPath(API_ENDPOINTS.PURCHASES_RECEIVES),
      {
        params,
      }
    );
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const getOperators = async (
  successCallback?: (data: { operators: AccountOperator[] }) => void
) => {
  const api = createAxios();
  try {
    const { data } = await api.get<{ operators: AccountOperator[] }>(
      API_ENDPOINTS.OPERATORS,
      {
        params: {
          type: OperatorType.Producer,
        },
      }
    );
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const getSites = async (
  operatorId: number | string,
  successCallback?: (data: ResSites) => void
) => {
  const api = createAxios();

  try {
    const { data } = await api.get<ResSites>(API_ENDPOINTS.SITE, {
      params: {
        operatorId,
        type: OperatorType.Producer,
      },
    });
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const createPurchasesProductsReceives = async (p: {
  deliveryIds: string[];
  materialName: string;
  params: PurchasesReceivesParams;
  successCallback?: (data: string) => void;
}) => {
  const { deliveryIds, params, successCallback } = p;
  const api = createAxios();
  try {
    const { data } = await api.post(
      API_ENDPOINTS.PURCHASES_PRODUCTS_RECEIVES,
      { deliveryIds, materialName: p.materialName },
      {
        params,
      }
    );
    successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const getPurchasesTracesDetail = async (
  id: string,
  successCallback?: (data: TraceDataDetail[]) => void
) => {
  const api = createAxios();

  try {
    const { data } = await api.get<TraceDataDetail[]>(
      getApiPath(API_ENDPOINTS.PURCHASES_TRACES_DETAIL, {
        id,
      })
    );
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const fetchPurchasesCertificatesDownload = async (
  processingObjectId: string,
  successCallback?: (data: CertificatesPDFData) => void
) => {
  const api = createAxios();

  try {
    const { data } = await api.get<CertificatesPDFData>(
      getApiPath(API_ENDPOINTS.PURCHASES_CERTIFICATES_DOWNLOAD, {
        processingObjectId,
      })
    );

    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

// Purchase History
export const getWorkHistoriesPurchasesProducts = async (p: {
  params?: GetListParams;
  successCallback?: (data: WorkHistories) => void;
}) => {
  const { params, successCallback } = p;
  const api = createAxios();

  try {
    const { data } = await api.get<WorkHistories>(
      API_ENDPOINTS.WORK_HISTORIES_PURCHASES_PRODUCTS,
      {
        params,
      }
    );

    if (data) {
      successCallback?.(data);
    }
  } catch (error) {
    handleError(error);
  }
};

export const updatePurchasesProduct = async (
  id: string,
  params: { form: HistoryProduct },
  successCallback?: (data: string) => void
) => {
  const api = createAxios();
  try {
    const { data } = await api.put(
      getApiPath(API_ENDPOINTS.PURCHASES_PRODUCERS, {
        id,
      }),
      {
        processingMethod: params.form.processingMethod.trim(),
        packingStyle: params.form.packingStyle.trim(),
        quantity: Number(params.form.quantity),
        weight: params.form.weight.trim(),
        name: params.form.name.trim(),
      }
    );
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};
