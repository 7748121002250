// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
type IconFixedItemProps = {
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
};

export const IconFixedItem = ({
  width = 24,
  height = 24,
}: IconFixedItemProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0480976 20.6147L2.38918 5.63185L4.13989 7.38256L2.16842 20H16.7573L18.7573 22H1.23371C0.49711 22 -0.0656162 21.3425 0.0480976 20.6147ZM8.03925 13.1739V11.2819L6.82133 10.064H6.51925V16H8.03925V14.9393L8.49022 14.4801L9.70325 16H11.5433L9.5949 13.519L9.93015 13.1728L8.99824 12.2409L8.03925 13.1739ZM9.83554 11.664L10.6259 12.4543L11.5601 13.3885L13.934 15.7624C13.934 15.7624 13.934 15.7624 13.934 15.7624L14.4942 16.3226L15.4579 17.2864L18.1715 20L20.1715 22H20.1716L21.799 23.6274L23.2132 22.2132L21.9566 20.9566L19.6153 18.6153L16.472 15.4721L14.9405 13.9406L13.8188 12.8188L12.7708 11.7708L6.99997 6L4.99997 4L2 1L0.585786 2.41421L2.6861 4.51455L4.33099 6.15945L4.331 6.15946L9.83554 11.664ZM13.0081 16.328C13.0447 16.3395 13.0812 16.3502 13.1174 16.3601L14.3616 17.6043C14.2259 17.6174 14.0841 17.624 13.9361 17.624C13.5201 17.624 13.1228 17.576 12.7441 17.48C12.3708 17.384 12.0508 17.2427 11.7841 17.056L12.3361 15.992C12.5175 16.136 12.7415 16.248 13.0081 16.328ZM21.7525 19.3384L19.3534 16.9392L17.6442 6H8.41418L6.41418 4H6.94029C6.94029 1.79086 8.73115 0 10.9403 0C13.1494 0 14.9403 1.79086 14.9403 4H18.3289C18.9201 4 19.4232 4.43062 19.5145 5.01475L21.7525 19.3384ZM16.4801 14.0659L14.0326 11.6185C14.2501 11.6521 14.4446 11.718 14.6161 11.816C14.7799 11.9063 14.9186 12.029 15.0321 12.1842V11.664H16.4801V14.0659ZM10.9403 2C12.0449 2 12.9403 2.89543 12.9403 4H8.94029C8.94029 2.89543 9.83572 2 10.9403 2Z"
        fill="#F6F6F6"
      />
    </svg>
  );
};
