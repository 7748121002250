// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
export enum OperatorType {
  Emissions = 'Emissions', // 排出事業者
  Collects = 'Collects', // 回収・配送事業者
  Recycle = 'Recycle', // リサイクル事業者
  Compressions = 'Compressions', // 圧縮事業者
  Admin = 'Admin', // 圧縮事業者
  Separates = 'Separates',
  Brand = 'Brand',
  Purchase = 'Purchase',
  Producer = 'Producer', // 製品化
  AdminPool = 'AdminPool', // pool管理者
}

export enum IdType {
  Emissions = 'emissions',
  Collects = 'collects',
  Recycle = 'recycle',
  Separate = 'separate',
  Compressions = 'compressions',
}

export enum RoleType {
  AdminOperator = 'AdminOperator',
  AdminSite = 'AdminSite',
  UserNormal = 'UserNormal',
  BrandOwner = 'BrandOwner',
  AdminRecotech = 'AdminRecotech',
  AdminPool = 'AdminPool',
  AdminTenant = 'AdminTenant',
}

export enum ModalType {
  add = 'add',
  update = 'update',
}

export enum OperatorMenuItem {
  Emissions = '排出',
  Collects = '回収',
  Compressions = '中間処理',
  Recycle = 'リサイクル',
  Purchase = '調達',
  Producer = '製品化',
  None = '',
}

export enum AdminManagementType {
  operator = 'operator',
  site = 'site',
}

export enum TenantAuthen {
  None = 'None',
  ICCard = 'ICCard',
  Barcode = 'BarCode',
}

export enum ItemSize {
  LARGE = 'Large',
  MEDIUM = 'Medium',
  SMALL = 'Small',
}

export enum DateTypeRange {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

export enum SiteOption {
  GRADE = 'grade',
  SITE_TYPE = 'siteType',
  PROVINCE = 'province',
}

export enum ColorsPaletteKeys {
  PURCHASE_MATERIAL_CHART = 'purchaseMaterialChart',
  PURCHASE_MATERIAL_FLOW = 'purchaseMaterialFlow',
  TENANT_DASHBOARD_CHARTS = 'tenantDashboardCharts',
}

export enum StorageKeys {
  COLORS_SETTING = 'colorsSettingV2',
}

export enum ImportStatus {
  success = 'Success',
  fail = 'Fail',
  no_updates = 'No updates',
}

export enum OverallRank {
  high = 'High',
  medium = 'Medium',
  low = 'Low',
}
