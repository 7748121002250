// gkc_hash_code : 596AVJ0U6O4MG74XBQH62VSQ6T
import { apiService } from 'apis/site_recycle/siteApi';
import { TreeOption } from 'components/molecules/TreeSelect';
import {
  MaterialStatisticParams,
  ProcurementIntensity,
  RateCo2Emissions,
} from 'types/dashboard';
import {
  ChainsMaterialNames,
  FetchPurchaseTraces,
  IPurchaseWeightEmissionStatistic,
  PurchaseLocation,
  RateCo2BodyRequest,
  ResourcesStatistic,
  THeatMap,
  TResourcesRequest,
  TResourcesResponse,
  WeightEmissionStatisticResponse,
} from 'types/purchase';
import { API_ENDPOINTS } from 'util/endPoints';

const addTagTypes = ['purchase'] as const;

export const purchaseApi = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      resources: build.mutation<TResourcesResponse, TResourcesRequest>({
        query: (data) => ({
          url: API_ENDPOINTS.PURCHASE_GET_RESOURCES,
          method: 'POST',
          data: data.body,
          params: data.params,
        }),
      }),
      fetchMaterialNameFromChains: build.query<ChainsMaterialNames[], void>({
        query: () => ({
          url: API_ENDPOINTS.PURCHASE_GET_MATERIALNAMES,
          method: 'GET',
        }),
      }),
      fetchPurchaseResourcesStatistic: build.query<
        ResourcesStatistic,
        { id: number }
      >({
        query: () => ({
          url: API_ENDPOINTS.PURCHASE_RESOURCES_STATISTIC,
          method: 'GET',
        }),
      }),
      queryPurchaseRateCo2: build.mutation<
        ProcurementIntensity,
        RateCo2BodyRequest
      >({
        query: (body) => ({
          url: API_ENDPOINTS.PURCHASE_RATE_CO2,
          method: 'POST',
          data: body,
        }),
        transformResponse(baseQueryReturnValue: RateCo2Emissions) {
          return {
            co2ReductionAmount: baseQueryReturnValue.co2ReductionAmount,
            poolProcurementCost: baseQueryReturnValue.poolProcurementCost,
            total: baseQueryReturnValue.total,
            rate: [
              {
                type: 'emissions',
                rate: baseQueryReturnValue.rate.emission,
              },
              {
                type: 'collection',
                rate: baseQueryReturnValue.rate.collect,
              },
              {
                type: 'compressions',
                rate: baseQueryReturnValue.rate.compression,
              },
              {
                type: 'deliveries',
                rate: baseQueryReturnValue.rate.delivery,
              },
              {
                type: 'recycle',
                rate: baseQueryReturnValue.rate.recycle,
              },
              {
                type: 'producer',
                rate: baseQueryReturnValue.rate.producer,
              },
            ],
            comparisonOfProcurementIntensity:
              baseQueryReturnValue.comparisonOfProcurementIntensity,
          } as unknown as ProcurementIntensity;
        },
      }),
      fetchTraces: build.query<
        FetchPurchaseTraces,
        { take?: number; page?: number } | void
      >({
        query: (args) => ({
          url: API_ENDPOINTS.PURCHASE_TRACES,
          method: 'GET',
          params: args,
        }),
      }),
      queryPurchaseHeatmap: build.mutation<THeatMap[], MaterialStatisticParams>(
        {
          query: (body) => ({
            url: API_ENDPOINTS.WEIGHT_EMISSION_STATISTIC,
            method: 'POST',
            data: body,
          }),
          transformResponse(
            baseQueryReturnValue: WeightEmissionStatisticResponse[]
          ) {
            return baseQueryReturnValue.map((val) => ({
              lat: val.latitude,
              lng: val.longitude,
              weight: val.weight,
              siteId: val.siteId,
            })) as unknown as THeatMap[];
          },
        }
      ),
      fetchDistricts: build.query<
        TreeOption[],
        { isEmissionsType: boolean } | void
      >({
        query: (isEmissionsType) => ({
          url: API_ENDPOINTS.LOCATIONS,
          method: 'GET',
          params: isEmissionsType,
        }),
        transformResponse: (baseQueryReturnValue: PurchaseLocation[]) =>
          baseQueryReturnValue.reduce(
            (result: TreeOption[], value: PurchaseLocation) => {
              const exist = result.findIndex((x) => x.label === value.province);

              if (exist > 0) {
                if (value.district) {
                  result[exist].subOptions?.push({
                    label: value.district,
                    value: value.district,
                  });
                }
              } else {
                result.push({
                  label: value.province,
                  value: value.province,
                  subOptions: value.district
                    ? [
                        {
                          label: value.district,
                          value: value.district,
                        },
                      ]
                    : [],
                });
              }

              return result;
            },
            [] as TreeOption[]
          ),
      }),
      queryWeightEmissionStatistic: build.mutation<
        IPurchaseWeightEmissionStatistic,
        MaterialStatisticParams
      >({
        query: (data) => ({
          url: API_ENDPOINTS.PURCHASE_WEIGHT_EMISSION_STATISTIC,
          method: 'POST',
          data,
        }),
      }),
    }),
  });

export const {
  useResourcesMutation,
  useFetchMaterialNameFromChainsQuery,
  useFetchPurchaseResourcesStatisticQuery,
  useQueryPurchaseRateCo2Mutation,
  useFetchTracesQuery,
  useLazyFetchTracesQuery,
  useQueryPurchaseHeatmapMutation,
  useFetchDistrictsQuery,
  useQueryWeightEmissionStatisticMutation,
} = purchaseApi;
