// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useTranslation } from 'react-i18next';
import styles from './OfflineModal.module.scss';
const OfflineModal = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.offlineModal}>
      <div className={styles.content}>
        <div className={styles.box}>
          <p>{t('messages.M_003')}</p>
        </div>
      </div>
    </div>
  );
};
export default OfflineModal;
