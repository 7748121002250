import { createAxios } from 'ts/createAxios';
import { API_ENDPOINTS } from 'util/endPoints';
import {
  MachineTypeSuggestionData,
  PackingStyleData,
  ProducerMethodData,
  QualityInspectionMethodData,
} from 'util/siteManagementTypes';

export const fetchMachineTypeSuggestions = async (
  siteType: string,
  callback?: (data?: MachineTypeSuggestionData) => void
) => {
  const api = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { data } = await api.get<MachineTypeSuggestionData>(
    API_ENDPOINTS.COMMON_MACHINE_TYPES,
    {
      params: { siteType },
    }
  );
  callback?.(data);
};

export const fetchQualityInspectionSuggestions = async (
  callback?: (data?: QualityInspectionMethodData) => void
) => {
  const api = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { data } = await api.get<QualityInspectionMethodData>(
    API_ENDPOINTS.COMMON_QUALITY
  );
  callback?.(data);
};

export const fetchPackingStyleSuggestions = async (
  siteType: string,
  callback?: (data?: PackingStyleData) => void
) => {
  const api = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { data } = await api.get<PackingStyleData>(
    API_ENDPOINTS.COMMON_PACKING_STYLES,
    {
      params: { siteType },
    }
  );
  callback?.(data);
};

export const fetchProducerMethodSuggestions = async (
  callback?: (data?: ProducerMethodData) => void
) => {
  const api = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { data } = await api.get<ProducerMethodData>(
    API_ENDPOINTS.COMMON_PRODUCER_METHOD
  );
  callback?.(data);
};
