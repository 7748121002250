// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { SelectField, SelectOption } from 'components/molecules/SelectField';
import { Chart } from 'react-chartjs-2';
import styles from './SitesStatisticOrganism.module.scss';
import { useEffect, useMemo, useState } from 'react';
import PreviewChartModal from 'components/molecules/PreviewChartModal';
import { SitesStatistic } from 'types/dashboard';
import { fetchDashboardsPurchasesSitesStatistic } from 'apis/dashboards/dashboardApi';
import { SiteOption } from 'util/Enums';
import { cutText, forceBreakLine } from 'util/commons';
import { ChartOptions } from 'chart.js';

const SitesStatisticOrganism = () => {
  const { t } = useTranslation();
  const [previewChartModal, setPreviewChartModal] = useState<boolean>(false);
  const [sitesStatistic, setSitesStatistic] = useState<SitesStatistic[]>([]);
  const [optionSelected, setOptionSelected] = useState<string>(
    SiteOption.GRADE
  );

  useEffect(() => {
    getDashboardsPurchasesSitesStatistic(optionSelected);
  }, [optionSelected]);

  const getDashboardsPurchasesSitesStatistic = (option: string) => {
    option &&
      fetchDashboardsPurchasesSitesStatistic(option, (data) => {
        data && setSitesStatistic(data);
      });
  };

  const getSitesStatisticChart = (previewChart?: boolean) => {
    if (previewChart) {
      return {
        labels: sitesStatistic.map((data) => cutText(data.option, 7)),
        datasets: [
          {
            data: sitesStatistic.map((data) => String(data.siteCount ?? 0)),
            backgroundColor: '#86EA5C',
            borderRadius: 4,
            datalabels: {
              display: true,
              color: '#1B1B1B',
            },
          },
        ],
      };
    }

    return {
      labels: sitesStatistic.map((data) => cutText(data.option, 7)).slice(0, 5),
      datasets: [
        {
          data: sitesStatistic
            .map((data) => String(data.siteCount ?? 0))
            .slice(0, 5),
          backgroundColor: '#86EA5C',
          borderRadius: 4,
          datalabels: {
            display: true,
            color: '#1B1B1B',
          },
        },
      ],
    };
  };

  const options: ChartOptions<'bar'> = {
    indexAxis: 'y' as const,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: '#E6E6E6',
        },
        grid: {
          color: '#3C3C3C',
          borderColor: '#6F6F6F',
          borderWidth: 1,
          drawBorder: true,
        },
      },
      y: {
        ticks: {
          color: '#E6E6E6',
          maxTicksLimit: sitesStatistic.length,
        },
        grid: {
          display: false,
          borderColor: '#6F6F6F',
          borderWidth: 1,
          drawBorder: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: ([tooltipItem]) =>
            tooltipItem?.dataIndex != null
              ? forceBreakLine({
                  text: sitesStatistic[tooltipItem.dataIndex].option,
                  breakNode: 50,
                })
              : '',
          label: () => '',
        },
      },
    },
  };

  const analysisOptions: SelectOption[] = useMemo(
    () => [
      {
        label: t('purchase_page.resource_search.grade'),
        value: SiteOption.GRADE,
      },
      {
        label: t('purchase_page.resource_search.site_type'),
        value: SiteOption.SITE_TYPE,
      },
      {
        label: t('purchase_page.resource_search.prefectures'),
        value: SiteOption.PROVINCE,
      },
    ],
    [t]
  );

  const plugins = (isPreview?: boolean) => {
    return [
      {
        id: 'customCanvasBackgroundColor',
        beforeDraw: (chart: any) => {
          const { ctx } = chart;
          ctx.save();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = isPreview ? '#1B1B1B' : '#2C2C2C';
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        },
      },
    ];
  };

  return (
    <div className={styles.analysis_left_top_column}>
      <div className={styles.analysis_left_top_column_title}>
        <div className={styles.analysis_left_top_column_title_text}>
          {t('purchase_page.resource_search.number_of_locations')}
        </div>
        <div className={styles.analysis_left_top_column_title_select}>
          <Form
            onSubmit={() => {}}
            initialValues={{ analysis_select: optionSelected }}
          >
            {() => (
              <SelectField
                name="analysis_select"
                options={analysisOptions}
                onChange={(e) => {
                  setOptionSelected(e.toString());
                }}
                isDarkmode
              />
            )}
          </Form>
        </div>
      </div>
      <div className={styles.analysis_left_top_column_chart}>
        {sitesStatistic.length > 0 ? (
          <Chart
            onClick={() => setPreviewChartModal(true)}
            type="bar"
            data={getSitesStatisticChart()}
            options={options}
            plugins={plugins()}
          />
        ) : (
          <div className={styles.msgEmpty}>
            <p>{t('messages.M_008')}</p>
          </div>
        )}

        {previewChartModal && (
          <PreviewChartModal
            title={t('purchase_page.resource_search.number_of_locations')}
            children={
              <div className={styles.modal}>
                <div className={styles.selectContainer}>
                  <div className={styles.select}>
                    <Form
                      onSubmit={() => {}}
                      initialValues={{ analysis_select: optionSelected }}
                    >
                      {() => (
                        <SelectField
                          name="analysis_select"
                          options={analysisOptions}
                          onChange={(e) => {
                            setOptionSelected(e.toString());
                          }}
                          isDarkmode
                        />
                      )}
                    </Form>
                  </div>
                </div>
                <div className={styles.chartContainer}>
                  <div className={styles.chartContainerBox}>
                    <Chart
                      type="bar"
                      data={getSitesStatisticChart(true)}
                      options={{
                        ...options,
                        maintainAspectRatio: true,
                        responsive: true,
                        aspectRatio: 0.5,
                      }}
                      plugins={plugins(true)}
                    />
                  </div>
                </div>
              </div>
            }
            onClose={() => setPreviewChartModal(false)}
          />
        )}
      </div>
      <div className={styles.analysis_left_top_column_description}>
        <span>{t('purchase_page.resource_search.on_details')}</span>
      </div>
    </div>
  );
};

export default SitesStatisticOrganism;
