// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useRef, useState } from 'react';
import { Option } from 'util/Types';
import styles from './index.module.scss';
import { IconDropDown } from 'components/atoms/icons/IconDropDown';
import useOutsideClick from 'util/hooks/useClickOutside';
import { mergeClasses } from 'util/commons';
import { useGetBoundingClientRect } from 'util/hooks/useGetBoundingClientRect';
import Portal from 'components/atoms/Portal';
import { useTranslation } from 'react-i18next';
import TableFilterExpandedValue from 'components/atoms/TableFilterExpandedValue';

type TableFilterSelectProps = {
  label: string;
  options?: Option[];
  value?: string;
  onChange?: (value?: string) => void;
  allowClear?: boolean;
  disabled?: boolean;
  expanded?: boolean;
  fullWidth?: boolean;
};

const TableFilterSelect: React.FC<TableFilterSelectProps> = ({
  label,
  options,
  value,
  onChange,
  allowClear,
  disabled,
  expanded,
  fullWidth,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { ref: dropdownRef } = useOutsideClick({
    onClickOutside: () => {
      setOpen(false);
    },
    exceptRefs: [containerRef],
  });

  const { domRect } = useGetBoundingClientRect(
    {
      el: containerRef.current?.parentElement,
    },
    [open]
  );

  if (!options?.length || disabled) {
    return <>{label}</>;
  }

  const renderActionButton = () => {
    if (expanded) {
      return (
        <TableFilterExpandedValue
          value={value}
          allowClear={allowClear}
          valueCondition={Boolean(value)}
          clearCondition={Boolean(value)}
          onChange={onChange}
        />
      );
    }

    return <IconDropDown color={value ? '#86EA5C' : '#B7B7B7'} />;
  };

  return (
    <div ref={containerRef}>
      <div
        onClick={() => setOpen(!open)}
        className={mergeClasses(styles.labelContainer, {
          [styles.labelExpanded]: expanded,
        })}
      >
        <span className={styles.label}>{label}</span>
        {renderActionButton()}
      </div>
      {open && domRect && (
        <Portal>
          <div ref={dropdownRef}>
            <div
              className={styles.dropDown}
              style={{
                top: domRect.top + domRect.height + 4,
                left: domRect.left,
                ...(fullWidth && {
                  width: domRect.width,
                  maxWidth: domRect.width,
                  minWidth: domRect.width,
                }),
              }}
            >
              <ul>
                {options?.map((opt, index) => {
                  return (
                    <li
                      key={index}
                      className={mergeClasses({
                        [styles.selected]: value === opt.value,
                      })}
                      onClick={() => {
                        if (value === opt.value) {
                          onChange?.(undefined);
                        } else {
                          onChange?.(opt.value);
                        }

                        setOpen(false);
                      }}
                    >
                      <span className={styles.optionName}>{opt.label}</span>
                    </li>
                  );
                })}
              </ul>
              {allowClear && Boolean(value) && !expanded && (
                <div
                  onClick={(e) => {
                    e?.stopPropagation();
                    e?.preventDefault();
                    onChange?.(undefined);
                  }}
                  className={styles.clear}
                >
                  {t('common.button.clear')}
                </div>
              )}
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

TableFilterSelect.defaultProps = {
  allowClear: true,
};

export default TableFilterSelect;
