// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useState } from 'react';
import { Modal } from 'components/atoms/Modal';
import { IconClose } from 'components/atoms/icons/IconClose';
import { useTranslation } from 'react-i18next';
import styles from './CheckPasswordAndConfirmLogoutModal.module.scss';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { TextInputFieldLogin } from '../TextInputFieldLogin';
import { validateLoginPasswordField } from 'util/validator';
import { Form } from 'react-final-form';
import { createAxios } from 'ts/createAxios';

type LogoutInput = {
  password: string;
};

interface Props {
  onClose: () => void;
  onSubmit: () => void;
}

const CheckPasswordAndConfirmLogoutModal: React.FC<Props> = ({
  onClose,
  onSubmit,
}) => {
  const api = createAxios('login');
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const getErrorMessage = async (
    userId: string,
    password: string,
    operatorId: string | null
  ) => {
    try {
      await api.post('/auth/login', {
        userId,
        password,
        operatorId,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      // return message when network error
      if (err.message === 'Network Error') {
        return t('modal_check_password_and_confirm_logout.network_error');
      }

      // return message when the password is incorrect or the request failed
      return `${t('modal_check_password_and_confirm_logout.input_error')}\n${t(
        'login.message_error'
      )}`;
    }

    return undefined;
  };

  const handleSubmit = async (values: LogoutInput) => {
    setErrorMessage('');
    const errorMessage = await getErrorMessage(
      user.userId,
      values.password,
      user.operatorId
    );

    if (errorMessage) {
      setErrorMessage(errorMessage);
    } else {
      onSubmit();
    }
  };

  return (
    <div className={styles.checkPasswordAndConfirmLogoutModal}>
      <Modal>
        <Form<LogoutInput> onSubmit={handleSubmit}>
          {({ handleSubmit, invalid }) => {
            return (
              <form>
                <div
                  className={styles.checkPasswordAndConfirmLogoutModalContent}
                >
                  <div
                    className={
                      styles.checkPasswordAndConfirmLogoutModalContentIconClose
                    }
                    onClick={onClose}
                  >
                    <IconClose width={16} height={16} />
                  </div>

                  <h3
                    className={
                      styles.checkPasswordAndConfirmLogoutModalContentTitle
                    }
                  >
                    {t('modal_check_password_and_confirm_logout.heading')}
                  </h3>

                  <p
                    className={
                      styles.checkPasswordAndConfirmLogoutModalContentDescription
                    }
                    dangerouslySetInnerHTML={{
                      __html:
                        t(
                          'modal_check_password_and_confirm_logout.content_confirm'
                        ) ?? '',
                    }}
                  />

                  <TextInputFieldLogin
                    label={t('login.password') || 'パスワード'}
                    name={'password'}
                    type={'password'}
                    icon="password"
                    placeholder={t('login.password') || 'パスワード'}
                    validate={(password) => {
                      return validateLoginPasswordField(
                        t('login.password') || 'パスワード',
                        6,
                        password
                      )
                        ? (t) =>
                            validateLoginPasswordField(
                              t('login.password') || 'パスワード',
                              6,
                              password
                            )
                        : undefined;
                    }}
                  />
                  {errorMessage && (
                    <div
                      className={
                        styles.checkPasswordAndConfirmLogoutModalContentErrors
                      }
                    >
                      {errorMessage}
                    </div>
                  )}
                </div>

                <div
                  className={styles.checkPasswordAndConfirmLogoutModalFooter}
                >
                  <button
                    type="submit"
                    className={
                      styles.checkPasswordAndConfirmLogoutModalFooterSubmitButton
                    }
                    disabled={invalid}
                    onClick={handleSubmit}
                  >
                    {t('modal_check_password_and_confirm_logout.button')}
                  </button>
                </div>
              </form>
            );
          }}
        </Form>
      </Modal>
    </div>
  );
};

export default CheckPasswordAndConfirmLogoutModal;
