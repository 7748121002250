// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { ChartOptions } from 'chart.js';
import DateTimeUnitTabButton from 'components/molecules/DateTimeUnitTabButton';
import { uniqBy } from 'lodash';
import { memo, useMemo, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { MaterialStatistic, MaterialStatisticSearch } from 'types/dashboard';
import { ColorsPaletteKeys, DateTypeRange } from 'util/Enums';
import { normalizeNumber } from 'util/commons';
import { useGenerateColorsPalette } from 'util/hooks/useGenerateColorsPalette';
import styles from './MaterialStatisticOrganism.module.scss';

type Props = {
  materialStatistic: MaterialStatistic[];
  setSearch: (values: MaterialStatisticSearch) => void;
  search: MaterialStatisticSearch;
};

const MaterialStatisticOrganism: React.FC<Props> = ({
  materialStatistic,
  search,
  setSearch,
}) => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<DateTypeRange>(
    search.typeDateRange
  );

  const materialStatisticLabels = useMemo(
    () =>
      materialStatistic.map((i) => {
        if (activeTab === DateTypeRange.YEAR) {
          return String(i.year);
        }

        if (activeTab === DateTypeRange.MONTH) {
          return `${i.year}/${i.month}`;
        }

        return String(`${i.month}/${i.day}`);
      }),
    [materialStatistic]
  );

  const materialStatisticData = useMemo(
    () =>
      materialStatistic.map((item) =>
        item.data.reduce(
          (res: { label: string; value: string; isOther: boolean }[], it) => {
            res.push({
              label: it.materialName,
              value: `${Number(it.weight) / 1000}`,
              isOther: Boolean(it.isOther),
            });
            return res;
          },
          []
        )
      ),
    [materialStatistic]
  );

  const materialNameLegends = useMemo(
    () =>
      uniqBy(
        materialStatisticData
          .flat()
          .sort((item1, item2) => {
            if (item1.isOther) {
              return -1;
            }

            if (item2.isOther) {
              return 1;
            }

            return 0;
          })
          .map((item) => ({ title: item.label })),
        'title'
      ),
    [materialStatisticData]
  );

  const { colorsPalette } = useGenerateColorsPalette({
    key: ColorsPaletteKeys.PURCHASE_MATERIAL_CHART,
    numOfColors: materialNameLegends.length,
    hueStep: 60,
    skipStartColor: true,
  });

  const materialStatisticLegends: { title: string; color: string }[] = useMemo(
    () =>
      materialNameLegends.map((x, i) => ({
        title: x.title,
        color: colorsPalette[i],
      })) || [],
    [t, materialStatisticData, colorsPalette]
  );

  const materialStatisticFilledData: {
    label: string;
    value: {
      val: number;
      isOther: boolean;
    }[];
  }[] = useMemo(
    () =>
      materialStatisticLegends.reduce(
        (
          rs: {
            label: string;
            value: {
              val: number;
              isOther: boolean;
            }[];
          }[],
          { title }
        ) => {
          materialStatistic.forEach(({ data }) => {
            const dataItemIndex = rs.findIndex(({ label }) => label === title);

            const statisticData = data.find(
              ({ materialName }) => materialName === title
            );

            const newVal = {
              val: statisticData?.weight
                ? Number(statisticData?.weight) / 1000
                : 0,
              isOther: Boolean(statisticData?.isOther),
            };

            if (dataItemIndex === -1) {
              rs.push({
                label: title,
                value: [newVal],
              });
            } else {
              rs[dataItemIndex].value.push(newVal);
            }
          });

          return rs;
        },
        []
      ),
    [materialStatisticLegends]
  );

  const materialStatisticOptions: ChartOptions<'bar'> = {
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: '#e6e6e6',
        },
      },
      y: {
        ticks: {
          color: '#e6e6e6',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (item) =>
            `${item?.dataset?.label}: ${
              item?.raw?.[1] != null && item?.raw?.[0] != null
                ? normalizeNumber({
                    value: item.raw[1] - item.raw[0],
                  })
                : ''
            }`,
        },
      },
      datalabels: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };

  const materialStatisticChartDataSets = useMemo(() => {
    const datasets = materialStatisticFilledData.map((x, i) => ({
      label: x.label,
      data: x.value,
      backgroundColor: colorsPalette[i],
    }));

    if (datasets.length) {
      datasets[0].data.forEach((_, datasetIndex) => {
        const sortedDatasets = datasets
          .map((d, dataIndex) => [
            d.data[datasetIndex].val,
            d.data[datasetIndex].isOther,
            dataIndex,
          ])
          .sort(([data1, isOther1], [data2, isOther2]) => {
            if (isOther1) {
              return 1;
            }

            if (isOther2) {
              return -1;
            }

            return Number(data2) - Number(data1);
          });
        let sum = 0;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        sortedDatasets.forEach(([data, _, datasetIdx]) => {
          if (datasetIdx != null) {
            datasets[datasetIdx.toString()].data[datasetIndex] = [
              sum,
              sum + Number(data ?? 0),
            ];
            sum = sum + Number(data ?? 0);
          }
        });
      });
    }

    return datasets;
  }, [materialStatisticFilledData, colorsPalette]);

  return (
    <div className={styles.materialStatistic}>
      <div className={styles.materialStatistic_header}>
        <div className={styles.materialStatistic_header_text}>
          {t('purchase_page.resource_search.resource_generation')}
        </div>
        <DateTimeUnitTabButton
          dateFrom={search.dateFrom}
          dateTo={search.dateTo}
          activeTab={activeTab}
          setActiveTab={(value) => {
            setActiveTab(value);
            setSearch({
              ...search,
              typeDateRange: value,
            });
          }}
        />
      </div>
      <div className={styles.materialStatistic_content}>
        <div className={styles.materialStatistic_content_chartContainer}>
          <Chart
            type="bar"
            options={materialStatisticOptions}
            data={{
              labels: materialStatisticLabels,
              datasets: materialStatisticChartDataSets,
            }}
            height={232}
          />
        </div>

        <div className={styles.materialStatistic_content_legend}>
          {materialStatisticLegends.map((legend) => (
            <div
              className={styles.materialStatistic_content_legend_item}
              key={legend.color}
            >
              <span
                className={styles.materialStatistic_content_legend_item_box}
                style={{ background: legend.color }}
              />
              <span
                className={styles.materialStatistic_content_legend_item_text}
              >
                {legend.title}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(MaterialStatisticOrganism);
