// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import {
  useCreateCompressionMutation,
  useFetchCompressionResourceInputQuery,
  useFetchSiteInfoByIdQuery,
} from 'apis/compressions_user/compressionUserApi';
import BigNumber from 'bignumber.js';
import Toggle from 'components/atoms/Toggle';
import { BottomField } from 'components/molecules/BottomField';
import LabelOrSelectField from 'components/molecules/LabelOrSelectField';
import { ConfirmModal } from 'components/organisms/ConfirmModal';
import { FormApi } from 'final-form';
import i18n from 'i18n';
import { useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectUser } from 'redux/slices/userSlice';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { DATE_FORMAT, REGEX_OBJECT } from 'util/ConstantValues';
import { OperatorType } from 'util/Enums';
import {
  Option,
  PreRegisSelectedResource,
  ResourcePreRegisFilter,
  SelectedResources,
  UserCompressionCreateRequest,
} from 'util/Types';
import { normalizeNumber, normalizeNumberString } from 'util/commons';
import { isError } from 'util/errorHandler';
import { useConnectToScale } from 'util/hooks/useConnectToScale';
import { validateRequiredField } from 'util/validator';
import PreRegisResourceSelect from '../PreRegisResourceSelect';
import styles from './CompressionRegistration.module.scss';
import { useWeightInput } from 'util/hooks/useScaleWeightInput';

export type Compression = {
  compressionId: string;
  datetime: string;
  userId: string;
  userName: string;
  weight: string;
  description: string;
  grade: { id: number; name: string };
  gradeId: number;
  siteId: string;
};
interface Props {
  setRefresh?: (isRefresh: boolean) => void;
  isRefresh?: boolean;
}

const CompressionRegistration: React.FC<Props> = ({
  setRefresh,
  isRefresh,
}) => {
  const { t } = useTranslation();

  const user = useSelector(selectUser);

  const [step, setStep] = useState<'info' | 'weight' | 'regis'>('regis');
  const [regisValues, setRegisValues] = useState<PreRegisSelectedResource[]>(
    []
  );
  const [selectedResources, setSelectedResources] = useState<SelectedResources>(
    {}
  );
  const [filter, setFilter] = useState<ResourcePreRegisFilter>({});
  const [createCompression] = useCreateCompressionMutation();
  const { data: categories } = useFetchSiteInfoByIdQuery(user?.siteId, {
    skip: !user.siteId,
  });
  const { scaleConnected, handleChangeScaleConnectionStatus } =
    useConnectToScale();

  const [confirm, setConfirm] = useState<{ open: boolean; alert?: string }>();
  const [prevProcessingObjectId, setPrevProcessingObjectId] =
    useState<string>();
  const [prevRegisWeight, setPrevRegisWeight] = useState<BigNumber>(
    new BigNumber(0)
  );
  const { data, refetch: refetchInputResources } =
    useFetchCompressionResourceInputQuery();

  const { weightInputRef } = useWeightInput(
    {
      receiveValueFromScaleCondition: !confirm?.open,
      itemType: 'compression',
    },
    [step]
  );

  const compressionMethod = useMemo(
    (): Option[] =>
      categories?.compressionMethod.map((x: string) => ({
        label: x,
        value: x,
      })) || [],
    [categories]
  );

  const grades = useMemo(
    (): Option[] =>
      categories?.grades.map((x) => ({
        label: x.name,
        value: x.id,
      })) || [],
    [categories]
  );

  const materialTypes = useMemo(
    (): Option[] =>
      categories?.materialTypes.map((x) => ({
        label: x.name,
        value: x.name,
      })) || [],
    [categories]
  );

  const packingStyles = useMemo(
    (): Option[] =>
      categories?.packingStyles.map((x: string) => ({
        label: x,
        value: x,
      })) || [],
    [categories]
  );

  const handleCallFunctionInit = () => {
    try {
      window && window.regisCompressionInitial();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleCallFunctionInit();
  }, []);

  const regisWeight = useMemo(() => {
    return regisValues.reduce((sum, item) => {
      return sum.plus(item.weight);
    }, new BigNumber(0));
  }, [regisValues]);

  const resetFormData = (
    form: FormApi<
      UserCompressionCreateRequest,
      Partial<UserCompressionCreateRequest>
    >
  ) => {
    setRefresh?.(!isRefresh);
    form.restart();
    setFilter({});
    setSelectedResources({});
    setPrevProcessingObjectId(undefined);
    setPrevRegisWeight(new BigNumber(0));
    refetchInputResources().unwrap();
  };

  const onSubmit = async (
    values: UserCompressionCreateRequest,
    form: FormApi<
      UserCompressionCreateRequest,
      Partial<UserCompressionCreateRequest>
    >,
    isSubmitContinue?: boolean
  ) => {
    const total = new BigNumber(values.weight || '').plus(prevRegisWeight);

    if (!confirm?.open) {
      setConfirm({
        open: true,
        alert:
          total.comparedTo(regisWeight) > 0
            ? t('compressions.alert') || ''
            : undefined,
      });

      return;
    }

    createCompression({
      ...values,
      weight: normalizeNumberString(values.weight || ''),
      gradeId: Number(values.gradeId),
      inputResources: regisValues.map(({ processingObjectId, weight }) => ({
        processingObjectId,
        weight,
      })),
      prevProcessingObjectId: prevProcessingObjectId ?? undefined,
    })
      .then((result) => {
        if (isError(result)) return;
        if (result['data']) {
          toast.success(t('messages.M_127'));
          const data = result['data'];
          setConfirm(undefined);

          if (isSubmitContinue) {
            setStep('weight');
            setPrevProcessingObjectId(data.id);
            setPrevRegisWeight((prev) =>
              prev.plus(new BigNumber(values.weight))
            );
            form.mutators.setValue('weight', '');
          } else {
            setStep('regis');
            resetFormData(form);
          }

          if (window && window.postCompressions) {
            window.postCompressions(
              JSON.stringify({
                ...data,
                materialName: values.materialName,
                registDatetime: getFormatDateWithCurrentTimezone(
                  data.registDatetime,
                  DATE_FORMAT.slaYMDHms
                ),
              }),
              i18n.language
            );
          }
        }
      })
      .catch((e) => {
        if (e.message && e.message.includes('Android')) {
          return;
        }
        alert(
          e?.response?.data?.message
            ? e.response.data.message
            : t('compressions.signup_messages.failed')
        );
      });
  };

  const initialValues = useMemo(
    () => ({
      gradeId: grades.length > 0 ? grades[0].value : '',
      materialName: regisValues?.[0]?.materialName || '',
      packingStyle: packingStyles.length > 0 ? packingStyles[0].value : '',
      processingMethod:
        compressionMethod.length > 0 ? compressionMethod[0].value : '',
      type: materialTypes.length > 0 ? materialTypes[0].value : '',
      weight: '',
      inputResources: [],
    }),
    [regisValues, grades, packingStyles, compressionMethod, materialTypes]
  );

  const handleNext = (values: PreRegisSelectedResource[]) => {
    setRegisValues(values);
    setStep('info');
  };

  const handleSubmitContinue = (
    values: UserCompressionCreateRequest,
    form: FormApi<
      UserCompressionCreateRequest,
      Partial<UserCompressionCreateRequest>
    >
  ) => {
    onSubmit(values, form, true);
  };

  return (
    <div className={styles.compressionRegistration}>
      <Form<UserCompressionCreateRequest>
        onSubmit={(values, form) => onSubmit(values, form)}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        validate={(values) => ({
          weight: validateRequiredField(
            values.weight,
            t('emission_input.label_weight') || ''
          ),
          materialName: validateRequiredField(
            values.materialName,
            t('emission_input.material_name') || ''
          ),
          processingMethod: validateRequiredField(
            values.processingMethod,
            t('emission_input.processing_method') || ''
          ),
          type: validateRequiredField(
            values.type,
            t('emission_input.material_types') || ''
          ),
          gradeId: validateRequiredField(
            values.gradeId,
            t('emission_input.grade') || ''
          ),
          packingStyle: validateRequiredField(
            values.packingStyle,
            t('emission_input.packing_style') || ''
          ),
        })}
        initialValues={initialValues}
      >
        {({ valid, handleSubmit, values, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              {step === 'regis' && (
                <div className={styles.register}>
                  <PreRegisResourceSelect
                    className={styles.regis}
                    operatorType={OperatorType.Compressions}
                    resourceData={data || []}
                    onNext={handleNext}
                    actionProps={{
                      selectedResources,
                      setSelectedResources,
                      filter,
                      setFilter,
                    }}
                  />
                </div>
              )}

              {step === 'info' && (
                <>
                  <div className={styles.scrollContents}>
                    <LabelOrSelectField
                      options={[]}
                      label={t('emission_input.material_name')}
                      fieldName="materialName"
                    />

                    <LabelOrSelectField
                      options={compressionMethod}
                      label={t('emission_input.processing_method')}
                      fieldName="processingMethod"
                    />

                    <LabelOrSelectField
                      options={materialTypes}
                      label={t('emission_input.material_types')}
                      fieldName="type"
                    />

                    <LabelOrSelectField
                      options={grades}
                      label={t('emission_input.grade')}
                      fieldName="gradeId"
                    />

                    <LabelOrSelectField
                      options={packingStyles}
                      label={t('emission_input.packing_style')}
                      fieldName="packingStyle"
                    />
                  </div>

                  <BottomField>
                    <div className={styles.bottomBtn}>
                      <button
                        className={styles.back}
                        onClick={() => {
                          if (prevProcessingObjectId) {
                            resetFormData(form);
                          }

                          setStep('regis');
                        }}
                      >
                        {t('common.return')}
                      </button>
                      <button
                        className={styles.btnRequest}
                        type="button"
                        onClick={() => setStep('weight')}
                      >
                        {t('common.button.next')}
                      </button>
                    </div>
                  </BottomField>
                </>
              )}

              {step === 'weight' && (
                <div className={styles.weightContent}>
                  <div className={styles.scaleConnectToggle}>
                    <Toggle
                      content={t('emission_input.connect_with_scale') || ''}
                      value={scaleConnected}
                      setValue={handleChangeScaleConnectionStatus}
                      isDarkMode
                    />
                  </div>

                  <div className={styles.weightContainer}>
                    <div className={styles.scaleConnectToggleMobile}>
                      <Toggle
                        content={t('emission_input.connect_with_scale') || ''}
                        value={scaleConnected}
                        setValue={handleChangeScaleConnectionStatus}
                        isDarkMode
                      />
                    </div>

                    <div className={styles.title}>
                      {t('compressions.label_weight')}
                    </div>
                    <div className={styles.content}>
                      {`${t('compressions.input_resources')}: ${
                        values.materialName
                      } ${regisWeight}kg`}
                    </div>
                    <div className={styles.field}>
                      <Field name="weight">
                        {({ input }) => (
                          <input
                            {...input}
                            ref={weightInputRef}
                            className={styles.input}
                            onChange={(e) => {
                              if (
                                e.target.value &&
                                !REGEX_OBJECT.tenDigitsWith5Decimal.test(
                                  e.target.value
                                )
                              ) {
                                return;
                              }
                              input.onChange(e);
                            }}
                          />
                        )}
                      </Field>
                      <div className={styles.unit}>kg</div>
                    </div>
                    <div className={styles.bottom}>
                      <div className={styles.buttons}>
                        <button
                          className={styles.back}
                          onClick={() => setStep('info')}
                        >
                          {t('common.return')}
                        </button>
                        <button
                          className={styles.submit}
                          onClick={(e) => {
                            e.preventDefault();
                            handleSubmit();
                          }}
                          disabled={!valid}
                        >
                          {t('common.button.register')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <ConfirmModal
                fullBottomWidth
                title={t('common.alert.confirm')}
                submitAndFinishLabel={
                  t('common.button.continue_to_register') || ''
                }
                submitLabel={t('common.button.processing_completed') || ''}
                alert={confirm?.alert}
                isOpen={confirm?.open}
                closeEditModal={() => {
                  setConfirm(undefined);
                }}
                onClickSubmitAndFinish={() =>
                  handleSubmitContinue(values, form)
                }
                onClick={handleSubmit}
                confirmContents={[
                  {
                    label: `${t('emission_input.material_name')}:`,
                    contents: values.materialName,
                  },
                  {
                    label: `${t('emission_input.processing_method')}:`,
                    contents: values.processingMethod,
                  },
                  {
                    label: `${t('emission_input.material_types')}:`,
                    contents: values.type,
                  },
                  {
                    label: `${t('emission_input.grade')}:`,
                    contents:
                      grades.find((g) => g.value === values.gradeId)?.label ||
                      '',
                  },
                  {
                    label: `${t('emission_input.packing_style')}:`,
                    contents: values.packingStyle,
                  },
                  {
                    label: t('emission_input.label_weight'),
                    contents: `${normalizeNumber({
                      value: values.weight,
                      toFixed: false,
                    })}kg`,
                  },
                ]}
              />
            </form>
          );
        }}
      </Form>
    </div>
  );
};
export default CompressionRegistration;
