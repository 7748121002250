// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import styles from './ExportDataInvoice.module.scss';
import { useTranslation } from 'react-i18next';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import { useEffect, useMemo, useState } from 'react';
import CheckboxField from 'components/atoms/CheckboxField';
import { FilterInvoiceData, InvoiceData } from 'util/Types';
import { normalizeNumber } from 'util/commons';
import { getTenantsInvoices } from 'apis/tenants';
import Csv from 'util/csv';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import dayjs from 'dayjs';
import TableFilterCalendar from 'components/molecules/TableFilterCalendar';
import IconCalendarTablet from 'components/atoms/icons/IconCalendarTablet';
import { getSlaFormatYearMonthDate } from 'ts/formatDate';

const ExportDataInvoice = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [showItem, setShowItem] = useState(false);

  const initialFilter = useMemo(() => {
    return {
      dateFrom: dayjs().startOf('month').format('YYYY/M/DD'),
      dateTo: dayjs().format('YYYY/M/DD'),
    };
  }, []);

  const [invoiceFilter, setInvoiceFilter] =
    useState<Partial<FilterInvoiceData>>(initialFilter);
  const [invoiceData, setInvoiceData] = useState<InvoiceData>({
    ...initialFilter,
    invoices: [],
  });

  useEffect(() => {
    fetchInvoiceData();
  }, [invoiceFilter]);

  const invoiceExportMonth = useMemo(() => {
    return t('export_data_invoice.invoice_export_month', {
      year: dayjs(invoiceFilter.dateTo).format('YYYY'),
      month: dayjs(invoiceFilter.dateTo).format('MM'),
    });
  }, [t, invoiceFilter]);

  const fetchInvoiceData = () => {
    if (invoiceFilter.dateFrom && invoiceFilter.dateTo) {
      const params: FilterInvoiceData = {
        dateFrom: invoiceFilter.dateFrom,
        dateTo: invoiceFilter.dateTo,
      };

      getTenantsInvoices({
        params,
        successCallback: (res) => {
          setInvoiceData(res);
        },
        failedCallback: () => {
          setInvoiceData({
            ...params,
            invoices: [],
          });
        },
      });
    }
  };

  const handleChangeDateRange = (value: [string, string] | undefined) => {
    setInvoiceFilter({
      dateFrom: value?.[0] ? getSlaFormatYearMonthDate(value[0]) : undefined,
      dateTo: value?.[1] ? getSlaFormatYearMonthDate(value[1]) : undefined,
    });
  };

  const csvVerShowItemHeader: (string | [string, string])[] = useMemo(() => {
    return [
      ['invoiceMonth', t('export_data_invoice.invoice_month')],
      ['tenantNumber', t('export_data_invoice.tenant_number')],
      ['name', t('export_data_invoice.tenant_name')],
      ['itemName', t('export_data_invoice.item')],
      ['price', t('export_data_invoice.unit_price')],
      ['weight', t('export_data_invoice.weight')],
      ['cost', t('export_data_invoice.total_processing_cost')],
    ];
  }, [t]);

  const csvHeader: (string | [string, string])[] = useMemo(() => {
    return [
      ['invoiceMonth', t('export_data_invoice.invoice_month')],
      ['tenantNumber', t('export_data_invoice.tenant_number')],
      ['name', t('export_data_invoice.tenant_name')],
      ['totalCost', t('export_data_invoice.total_processing_cost')],
    ];
  }, [t]);

  const getInvoiceDataCsv = (invoices: InvoiceData['invoices']) => {
    if (showItem) {
      return {
        headers: csvVerShowItemHeader,
        data: invoices.map((item) => {
          if (item?.tenantItems?.length) {
            return item?.tenantItems?.map((it) => ({
              invoiceMonth: invoiceExportMonth,
              tenantNumber: item.tenantNumber,
              name: item.name,
              itemName: it.itemName,
              price: normalizeNumber({
                value: it.price,
                toFixed: 0,
              }),
              weight: normalizeNumber({
                value: it.weight,
              }),
              cost: normalizeNumber({
                value: it.cost,
                toFixed: 0,
              }),
            }));
          }

          return {
            invoiceMonth: invoiceExportMonth,
            tenantNumber: item.tenantNumber,
            name: item.name,
            itemName: '',
            price: 0,
            weight: 0,
            cost: 0,
          };
        }),
      };
    }

    return {
      headers: csvHeader,
      data: invoices.map((item) => ({
        invoiceMonth: invoiceExportMonth,
        tenantNumber: item.tenantNumber,
        name: item.name,
        totalCost: item.totalCost,
      })),
    };
  };

  const handleDownloadCSV = () => {
    const invoices = getInvoiceDataCsv(invoiceData.invoices);

    const fileNameCsv = `${user.siteName}_${t(
      'export_data_invoice.tenant_invoice_data'
    )}_${dayjs(invoiceData.dateTo).format('YYYYMM')}`;

    new Csv({
      headers: invoices.headers,
      data: invoiceData.invoices.length ? invoices.data.flat() : [],
      headNote: `${dayjs(invoiceData.dateFrom).format('YYYY/MM/DD')}-${dayjs(
        invoiceData.dateTo
      ).format('YYYY/MM/DD')}`,
    }).downLoadCsv({
      filename: fileNameCsv,
    });
  };

  return (
    <PrimaryTemplate
      h1={t('export_data_invoice.heading')}
      className={styles.exportDataInvoice}
    >
      <div className={styles.exportDataInvoiceManage}>
        <div
          className={styles.countList}
          dangerouslySetInnerHTML={{
            __html:
              t('common.page', {
                page: invoiceData.invoices.length,
              }) ?? '',
          }}
        />

        <form className={styles.formInput}>
          <div className={styles.inputFrame}>
            <div className={styles.inputFrameWrap}>
              <div
                style={{
                  width: 214,
                }}
              >
                <TableFilterCalendar
                  expanded={{
                    placeholder: t('common.placeholder.select_date_range'),
                    icon: <IconCalendarTablet />,
                  }}
                  fullWidth
                  value={[
                    invoiceFilter.dateFrom || '',
                    invoiceFilter.dateTo || '',
                  ]}
                  allowClear={false}
                  onChange={handleChangeDateRange}
                  chosenDatesLimit={{
                    limit: 60,
                    required: true,
                  }}
                />
              </div>

              <div className={styles.showItem}>
                <CheckboxField
                  title={t('export_data_invoice.show_item')}
                  input={{
                    checked: showItem,
                    onChange: (e) => setShowItem(e.target.checked),
                  }}
                />
              </div>
            </div>

            <button type="button" onClick={handleDownloadCSV}>
              <IconDownload />
            </button>
          </div>
        </form>
      </div>

      <div className={styles.exportDataInvoiceTable}>
        <table>
          <thead>
            <tr>
              <th>{t('export_data_invoice.invoice_month')}</th>
              <th>{t('export_data_invoice.tenant_number')}</th>
              <th
                className={
                  showItem ? styles.tenantName : styles.tenantNameColSpan2
                }
              >
                {t('export_data_invoice.tenant_name')}
              </th>
              {showItem && (
                <>
                  <th>{t('export_data_invoice.item')}</th>
                  <th className={styles.textAlignRight}>
                    {t('export_data_invoice.unit_price')}
                  </th>
                  <th className={styles.textAlignRight}>
                    {t('export_data_invoice.weight')}
                  </th>
                </>
              )}
              <th className={styles.textAlignRight}>
                {t('export_data_invoice.total_processing_cost')}
              </th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.invoices.length > 0 ? (
              invoiceData.invoices.map((invoice) => {
                return (
                  <tr key={invoice.id}>
                    <td>
                      <p>{invoiceExportMonth}</p>
                    </td>
                    <td>
                      <p>{invoice.tenantNumber}</p>
                    </td>
                    <td>
                      <p>{invoice.name}</p>
                    </td>
                    {showItem ? (
                      invoice.tenantItems.length ? (
                        <td colSpan={4} className={styles.itemAndTotal}>
                          {invoice.tenantItems?.map((item, index) => {
                            return (
                              <tr
                                key={index}
                                className={styles.itemAndTotalItem}
                              >
                                <td>
                                  <p>{item.itemName}</p>
                                </td>
                                <td className={styles.textAlignRight}>
                                  <p>
                                    {normalizeNumber({
                                      value: item.price,
                                      toFixed: 0,
                                    })}
                                  </p>
                                </td>
                                <td className={styles.textAlignRight}>
                                  <p>
                                    {normalizeNumber({
                                      value: item.weight,
                                    })}
                                  </p>
                                </td>
                                <td className={styles.textAlignRight}>
                                  <p>
                                    {normalizeNumber({
                                      value: item.cost,
                                      toFixed: 0,
                                    })}
                                  </p>
                                </td>
                              </tr>
                            );
                          })}
                        </td>
                      ) : (
                        <>
                          <td></td>
                          <td className={styles.textAlignRight}>
                            <p>0</p>
                          </td>
                          <td className={styles.textAlignRight}>
                            <p>0</p>
                          </td>
                          <td className={styles.textAlignRight}>
                            <p>0</p>
                          </td>
                        </>
                      )
                    ) : (
                      <td className={styles.textAlignRight}>
                        <p>
                          {normalizeNumber({
                            value: invoice.totalCost,
                            toFixed: 0,
                          })}
                        </p>
                      </td>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={7} className={styles.msgError}>
                  {t('messages.M_008')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </PrimaryTemplate>
  );
};

export default ExportDataInvoice;
