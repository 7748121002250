// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import styles from './InvoiceEditMailForm.module.scss';
import { InputDataListField } from 'components/molecules/InputDataListField';
import { TextAreaDataListField } from '../TextAreaDataListField';
import { Label } from 'components/atoms/Label';
import { IconDelete } from 'components/atoms/icons/IconDelete';
import { IconAddCircleOutline } from 'components/atoms/icons/IconAddCircleOutline';
import { validateEmailAddress, validateRequiredField } from 'util/validator';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { DetailInvoiceTemplateData, updateInvoiceTemplate } from 'apis/invoice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { urlify } from 'util/commons';
import { ErrorText } from 'components/atoms/ErrorText';

interface Props {
  dataInvoiceTemplate?: DetailInvoiceTemplateData;
  currentSiteId: string;
  initialValue?: InvoiceEditMailPut;
  onSuccess: () => void;
}
export type Email = {
  id: string;
  email: string;
};
export type InvoiceEditMailPut = {
  emailInvoices?: {
    email: string;
  }[];
  title: string;
  bodyTemplate: string;
  siteId?: string;
  emails?: string[];
};
const InvoiceEditMailForm: React.FC<Props> = ({
  currentSiteId,
  initialValue,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const [isEditable, setEditable] = useState(false);

  const handleUpdateInvoiceTemplate = (e: InvoiceEditMailPut) => {
    updateInvoiceTemplate({
      params: {
        siteId: currentSiteId,
        title: e.title.trim(),
        bodyTemplate: urlify(e.bodyTemplate.trim()),
        emails:
          e.emailInvoices &&
          e.emailInvoices.map((item) => {
            return item.email;
          }),
      },
    }).then(() => {
      toast.success(t('messages.M_011'));
      onSuccess();
    });
  };

  return (
    <div className={styles.invoiceEditMailForm}>
      <Form<InvoiceEditMailPut>
        initialValues={initialValue}
        onSubmit={(e) => {
          handleUpdateInvoiceTemplate(e);
        }}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
          ...arrayMutators,
        }}
        validate={(values) => {
          return {
            title: validateRequiredField(values.title, 'メールタイトル'),
            bodyTemplate: validateRequiredField(
              values.bodyTemplate,
              'メール本文'
            ),
          };
        }}
      >
        {(props) => {
          const bodyError =
            props.touched &&
            props.touched.bodyTemplate &&
            props.errors &&
            props.errors.bodyTemplate;
          return (
            <form onSubmit={props.handleSubmit} className={styles.formInput}>
              <div className={styles.inputFrame}>
                <div className={styles.required}>
                  送信先
                  <span>*</span>
                </div>
                <FieldArray name="emailInvoices">
                  {({ fields }) => {
                    return (
                      <div>
                        {fields &&
                          fields.map((name, index) => (
                            <div key={index} className={styles.email}>
                              <div className={styles.emailField}>
                                <InputDataListField
                                  required
                                  placeholder="admin@gmail.com"
                                  name={`${name}.email`}
                                  validate={validateEmailAddress}
                                />
                                {fields.length && fields.length > 1 && (
                                  <div
                                    className={styles.deleteIcon}
                                    onClick={() => {
                                      fields.remove(index);
                                    }}
                                  >
                                    <IconDelete size={16} />
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}

                        <div
                          className={
                            props.values.emailInvoices &&
                            props.values.emailInvoices.length < 3
                              ? styles.addEmailField
                              : styles.addEmailFieldDisabled
                          }
                        >
                          <div>
                            <div
                              onClick={() => {
                                if (
                                  props.values.emailInvoices &&
                                  props.values.emailInvoices.length < 3
                                ) {
                                  fields.push('emailInvoices');
                                }
                              }}
                            >
                              <IconAddCircleOutline />
                              <p>メールを追加</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </FieldArray>

                <InputDataListField
                  name={'title'}
                  label={'メールタイトル'}
                  placeholder={'【Invoice/ご請求書】2023年3月'}
                  required
                  maxlength={100}
                  handleChange={(value) => {
                    props.form.mutators.setValue('title', value);
                  }}
                />
                <div
                  className={`${styles.textAreaField} ${
                    !isEditable ? styles.hidden : ''
                  }`}
                >
                  <TextAreaDataListField
                    required
                    name={'bodyTemplate'}
                    label={'メール本文'}
                    placeholder={'メール本文'}
                    onBlur={() => {
                      setEditable(false);
                    }}
                    handleChange={(value) => {
                      props.form.mutators.setValue('bodyTemplate', value);
                    }}
                    maxlength={5000}
                  />
                </div>
                {!isEditable && (
                  <div>
                    <Label text={'メール本文'} tag={'div'} required />
                    <div
                      className={`${styles.previewBody} ${
                        bodyError ? styles.previewError : ''
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: urlify(
                          props.values?.bodyTemplate
                            ? props.values?.bodyTemplate.trim()
                            : ''
                        ),
                      }}
                      onClick={() => {
                        setEditable(true);
                      }}
                    />
                  </div>
                )}
                {bodyError && (
                  <ErrorText
                    text={props.errors?.bodyTemplate}
                    className={styles.error}
                  />
                )}
              </div>

              <div className={styles.submit}>
                <button
                  className={styles.submitButton}
                  disabled={props.invalid}
                  type="submit"
                >
                  保存する
                </button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default InvoiceEditMailForm;
