// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import { Form } from 'react-final-form';
import styles from './CreateUpdateCategoryInputForm.module.scss';
import { InputDataListField, Suggestion } from '../InputDataListField';
import { createAxios } from 'ts/createAxios';
import { validateMaxLength } from 'util/validator';
import { Category } from 'components/organisms/CustomizeListCategory';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { handleError } from 'util/errorHandler';
import { useTranslation } from 'react-i18next';

export enum CreateUpdateCategoryFormInputType {
  add,
  update,
}

interface Props {
  type: CreateUpdateCategoryFormInputType;
  onSubmit: () => void;
  detailCategory?: Category;
  dataSuggestions: Suggestion[];
}

type WasteInput = {
  name: string;
  photo: File;
  dischargeBase: string;
};

const CreateUpdateCategoryInputForm: React.FC<Props> = ({
  type,
  onSubmit,
  detailCategory,
  dataSuggestions,
}) => {
  const { t } = useTranslation();
  const api = createAxios();
  const { siteId } = useParams<{ operatorId: string; siteId: string }>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const callAPICreateCategory = async (e: any) => {
    try {
      await api.post('/categories', {
        name: e.name,
        siteId: siteId,
      });
      onSubmit();
      toast.success(t('messages.M_010'));
    } catch (error) {
      handleError(error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const callAPIUpdateCategory = async (e: any, id: number) => {
    try {
      await api.put(`/categories/${id}`, {
        name: e.name.trim(),
        sideId: siteId,
        categoryId: id,
      });
      onSubmit();
      toast.success(t('messages.M_011'));
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className={styles.createBody}>
      <Form<WasteInput>
        initialValues={{ name: detailCategory?.name }}
        onSubmit={(e) => {
          if (type === CreateUpdateCategoryFormInputType.add) {
            callAPICreateCategory(e);
          } else {
            callAPIUpdateCategory(e, Number(detailCategory?.id));
          }
        }}
        mutators={{
          setPhoto: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        validate={(values) => {
          return {
            name:
              !values.name || values.name.trim().length === 0
                ? t('category_management.validator_message')
                : undefined,
          };
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} className={styles.formInput}>
            <div className={styles.inputFrame}>
              <InputDataListField
                validate={(value) =>
                  validateMaxLength(
                    value,
                    100,
                    t('category_management.thead_category')
                  )
                }
                label={t('category_management.thead_category') || 'カテゴリ'}
                placeholder={
                  t('category_management.thead_category') || 'カテゴリ'
                }
                name="name"
                required
                dataSuggestions={dataSuggestions}
                onSelectSuggestItem={(value) => {
                  props.form.mutators.setPhoto('name', value);
                }}
                maxlength={100}
              />
            </div>

            <div className={styles.line} />

            <div className={styles.submit}>
              <button
                disabled={props.invalid}
                className={
                  props.invalid ? styles.disableButton : styles.submitButton
                }
                type="submit"
              >
                {type === CreateUpdateCategoryFormInputType.add
                  ? t('common.modal_create.add_button')
                  : t('common.modal_update.edit_button')}
              </button>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

export default CreateUpdateCategoryInputForm;
