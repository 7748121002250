// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export type LoadingState = {
  isLoading: boolean;
  loadingTask: string[];
  offlineInitialized: boolean;
};

const initialState: LoadingState = {
  isLoading: false,
  loadingTask: [],
  offlineInitialized: false,
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    addLoading: (state) => {
      state.loadingTask.push('1');
      state.isLoading = true;
    },
    endLoading: (state) => {
      if (state.loadingTask.length > 0) {
        state.loadingTask.shift();
      }
      if (state.loadingTask.length == 0) {
        state.isLoading = false;
      }
    },
    setOfflineInitialized: (state, { payload }: PayloadAction<boolean>) => {
      state.offlineInitialized = payload;
    },
    resetLoading: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    },
  },
});

export const { addLoading, endLoading, setOfflineInitialized, resetLoading } =
  loadingSlice.actions;
export const selectLoading = (state: RootState) => state.loading;

export default loadingSlice.reducer;
