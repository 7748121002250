// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import TimeLineChart, {
  TimeLineData,
} from 'components/molecules/TimeLineChart';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createAxios } from 'ts/createAxios';
import { DateTypeRange } from 'util/Enums';
import { mergeClasses } from 'util/commons';
import { API_ENDPOINTS } from 'util/endPoints';
import { handleError } from 'util/errorHandler';
import { PoolDashboardMasterFilter } from '../PoolDashboardFilter';
import styles from './PoolDashboardLineCharts.module.scss';

type PoolDashboardLineChartsProps = {
  filter?: PoolDashboardMasterFilter;
  className?: string;
  fetchTrigger?: number;
};

type WasteTrend = {
  date: string;
  weight: string;
};

const PoolDashboardLineCharts: React.FC<PoolDashboardLineChartsProps> = ({
  filter,
  className,
  fetchTrigger,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState<WasteTrend[]>([]);
  const [dateType, setDateType] = useState<DateTypeRange>(DateTypeRange.MONTH);

  useEffect(() => {
    createAxios()
      .post(API_ENDPOINTS.DASHBOARDS_POOL_WASTE_TREND, {
        ...filter,
        typeDateRange: dateType,
      })
      .then(({ data }) => setData(data))
      .catch((err) => {
        handleError(err);
        setData([]);
      });
  }, [filter, dateType, fetchTrigger]);

  const convertedData = useMemo(
    (): TimeLineData[] =>
      data.map(({ date, weight }) => ({
        label: date,
        value: Number(weight),
        isPredictive: dayjs().isBefore(date, dateType),
      })),
    [data]
  );

  return (
    <div className={mergeClasses(styles.PoolDashboardLineCharts, className)}>
      <TimeLineChart
        title={t('pool_dashboard.changes_in_generated_weight')}
        noteMessage={t('pool_dashboard.line_chart_note_message') || ''}
        data={convertedData}
        height={284}
        filterType={dateType}
        onFilterTypeChange={setDateType}
        unit="kg"
        legends={{
          normal: t('pool_dashboard.solid_line') || '',
          predictive: t('pool_dashboard.dashed_line') || '',
        }}
      />
    </div>
  );
};

export default PoolDashboardLineCharts;
