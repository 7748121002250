// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { ProducerSite, ProductRegistrationForm } from 'types/purchase';
import {
  ProducerSiteItem,
  PurchaseSiteItem,
  UserRecycleSiteForm,
} from 'util/siteManagementTypes';

export const basePurchaseSiteForm: PurchaseSiteItem = {
  id: '',
  name: '',
  province: '',
  district: '',
  carbonPrice: '',
  costDisplayUnit: '',
  procurementUnitPrice: '',
  prevYearScope1: '',
  prevYearScope2: '',
  prevYearInputAmount: '',
  comparisonOfProcurementIntensity: [
    {
      intensityCo2OfProcuredMaterialToCompare: '',
      procuredMaterialToCompare: '',
    },
  ],
};

export const baseUserRecycleSiteForm: UserRecycleSiteForm = {
  materialName: '',
  processingMethod: '',
  packingStyle: '',
  materialTypeId: '',
  gradeId: '',
  weight: '',
  processingMethods: [],
  inputResources: [],
};

export const baseProducerSiteForm: ProducerSiteItem = {
  id: '',
  name: '',
  englishName: '',
  province: '',
  district: '',
  producerMethod: [],
  machineType: [],
  acceptanceMaterial: [],
  acceptanceGrade: [],
  enrollableType: [],
  enrollableGrade: [],
  packingStyles: [],
  qualityInspectionMethod: [],
  staffTel: '',
  prevYearInputAmount: '',
  prevYearScope1: '',
  prevYearScope2: '',
  address: '',
  postalCode: '',
  latitude: '',
  longitude: '',
};

export const baseProductSiteForm: ProductRegistrationForm = {
  operatorId: '',
  siteId: '',
  materialName: '',
  processingMethod: '',
  packingStyle: '',
  qualityInspectionMethod: [],
  materialTypeId: '',
  quantity: '',
  weight: '',
  name: '',
  procurementPercentage: '',
  gradeId: '',
  inputResources: [],
};

export const initProducerSite: ProducerSite = {
  producerMethod: [],
  packingStyles: [],
  qualityInspectionMethod: [],
  enrollableGrade: [],
  enrollableType: [],
};
