// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Modal } from 'components/atoms/Modal';
import { useEffect, useMemo, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { ItemType, UncollectEmission } from 'util/Types';
import { addition } from 'util/Calc';
import styles from './EmissionEditModal.module.scss';
import { IconClose } from 'components/atoms/icons/IconClose';
import { EmissionEditItem } from 'components/molecules/EmissionEditItem/EmissionEditItem';
import { createAxios } from 'ts/createAxios';
import { handleError } from 'util/errorHandler';
import { useTranslation } from 'react-i18next';
import { normalizeNumber } from 'util/commons';

export type DownloadModalProps = {
  afterSubmit: () => void;
  emissionData: UncollectEmission[];
  onClose: () => void;
};

const getSumQuantity = (emissions: UncollectEmission[]) => {
  if (emissions.length <= 0) return 0;
  const quantities: Array<number> = emissions.map((emission) => {
    const { wasteUnitQuantity, wasteUnit, weight } = emission;

    if (wasteUnitQuantity && wasteUnit?.convertKg) {
      return Number(wasteUnitQuantity) * Number(wasteUnit.convertKg);
    } else if (weight && wasteUnit?.type === ItemType.OPTIONAL) {
      return Number(weight);
    } else {
      return 0;
    }
  });

  return quantities.reduce((sum, quantity) => addition(sum, quantity));
};

export const EmissionEditModal = ({
  afterSubmit,
  emissionData,
  onClose,
}: DownloadModalProps) => {
  const { t } = useTranslation();
  const api = createAxios();
  const [newData, setNewData] = useState<UncollectEmission[]>([]);
  const [sumQuantity, setSumQuantity] = useState<number>(
    getSumQuantity(emissionData)
  );

  const isDisabled = useMemo(() => {
    return newData.some((value: UncollectEmission) => {
      return value.wasteUnit?.type === ItemType.FIX
        ? !value.wasteUnitQuantity
        : !value.weight;
    });
  }, [newData]);

  useEffect(() => {
    setNewData(emissionData);
  }, [emissionData]);

  const handleChangeInput = (
    value: string,
    itemEditting: UncollectEmission
  ) => {
    const newDataChanged = newData.map((item) => {
      return item.id === itemEditting.id
        ? {
            ...item,
            ...(item.wasteUnit?.type === ItemType.OPTIONAL
              ? { weight: value }
              : { wasteUnitQuantity: value }),
          }
        : item;
    });
    setNewData(newDataChanged);
    setSumQuantity(getSumQuantity(newDataChanged));
  };

  const callAPIUpdateEmission = async () => {
    const body = newData
      .filter((n) => {
        const oldItem = emissionData.find((e) => e.id === n.id);
        return (
          oldItem &&
          (oldItem.weight !== n.weight ||
            oldItem.wasteUnitQuantity !== n.wasteUnitQuantity)
        );
      })
      .map((data) => {
        return {
          emissionId: data.id,
          quantity: Number(
            Math.round(Number(data.wasteUnitQuantity || data.weight) * 100000) /
              100000
          ),
        };
      });

    if (body.length > 0) {
      try {
        await api.put('/emissions', {
          waste: body,
        });
        onClose();
        afterSubmit();
      } catch (error) {
        handleError(error);
      }
    } else {
      onClose();
    }
  };

  return (
    <section className={styles.downloadModal}>
      <Modal>
        <div className={styles.customTitle}>
          <div className={styles.title}>
            {t('un_collect.edit_registration_data')}
          </div>
          <div className={styles.top} onClick={onClose}>
            <IconClose />
          </div>
        </div>

        <div className={styles.totalRecovery}>
          <p>
            {t('un_collect.total_recovery')}&nbsp;
            <span>{normalizeNumber({ value: sumQuantity })}kg</span>
          </p>
        </div>

        <div className={styles.body}>
          {newData.map((emissionItem) => {
            return (
              <EmissionEditItem
                key={emissionItem.id}
                emissionEditData={emissionItem}
                onChange={(value) => {
                  handleChangeInput(value, emissionItem);
                }}
              />
            );
          })}
        </div>

        <div className={styles.submit}>
          <button
            className={styles.submitButton}
            type="submit"
            disabled={isDisabled}
            onClick={() => {
              callAPIUpdateEmission();
            }}
          >
            {t('common.button.reflect_the_correction')}
          </button>
        </div>
      </Modal>
    </section>
  );
};
