// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import AddBarcodeICCardForm from 'components/molecules/AddBarcodeICCardForm';
import { BarcodeICCardInput, BarcodeInput } from 'util/Types';
import { TenantItem } from '../AdminTenantManagement';
import styles from './AddBarcodeICCardModal.module.scss';

type Props = {
  genBarcode: BarcodeInput | undefined;
  tenantItemData: TenantItem | undefined;
  onClose: () => void;
  onGenBarcodeNew: (tenantItemData: TenantItem | undefined) => void;
  onSubmitAddICCard: (e: BarcodeICCardInput, tenantId: number) => void;
  onSubmitCreateBarcode: (e: BarcodeICCardInput, tenantId: number) => void;
};

const AddBarcodeICCardModal: React.FC<Props> = ({
  genBarcode,
  tenantItemData,
  onClose,
  onGenBarcodeNew,
  onSubmitAddICCard,
  onSubmitCreateBarcode,
}) => {
  return (
    <div className={styles.addBarcodeICCardModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>{tenantItemData?.name}</p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>

          <AddBarcodeICCardForm
            genBarcode={genBarcode}
            tenantItemData={tenantItemData}
            onGenBarcodeNew={onGenBarcodeNew}
            onSubmitAddICCard={onSubmitAddICCard}
            onSubmitCreateBarcode={onSubmitCreateBarcode}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AddBarcodeICCardModal;
