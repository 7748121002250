// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import styles from './CreateUpdateOperatorInputForm.module.scss';
import {
  validateFieldEN,
  validateId,
  validateInputNumber,
  validateRequiredField,
  validateStringField,
} from 'util/validator';
import { useTranslation } from 'react-i18next';
import { InputDataListField } from 'components/molecules/InputDataListField';
import { SelectField } from 'components/molecules/SelectField';
import { ModalType } from 'util/Enums';
import { REGEX_OBJECT, operatorTypeList } from 'util/ConstantValues';
import { OperatorCreateEditModalType } from 'components/organisms/AdminOperatorSiteTable/AdminOperatorSiteTable';
import { handleError } from 'util/errorHandler';
import { createAxios } from 'ts/createAxios';
import { useQuery } from 'util/queryParams';
import { toast } from 'react-toastify';
import { BusinessType, ResDetailOperator } from 'util/siteManagementTypes';

interface Props {
  type: ModalType;
  onSubmit: () => void;
  createUpdateOperatorModal: OperatorCreateEditModalType;
  businessTypeList?: BusinessType[];
}

type OperatorInputType = {
  operatorId: string;
  operatorType: string;
  operatorName: string;
  englishName: string;
  postalCode: string;
  operatorContent: string;
  operatorContentDetail: string;
  address: string;
};

const CreateUpdateOperatorInputForm: React.FC<Props> = ({
  type,
  createUpdateOperatorModal,
  onSubmit,
  businessTypeList,
}) => {
  const { t } = useTranslation();
  const api = createAxios();
  const { id } = createUpdateOperatorModal;
  const query = useQuery();
  const operatorType = query.get('type');
  const [itemEditing, setItemEditing] = useState<ResDetailOperator>();
  const handleCreateOperator = async (
    createOperatorForm: OperatorInputType
  ) => {
    try {
      await api.post('/operators', {
        id: createOperatorForm.operatorId.trim(),
        type: operatorType,
        name: createOperatorForm.operatorName.trim(),
        englishName: createOperatorForm.englishName.trim(),
        postalCode: createOperatorForm.postalCode.trim(),
        address: createOperatorForm.address.trim(),
        businessTypeId: Number(createOperatorForm.operatorContentDetail),
      });
      onSubmit();
      toast.success(t('messages.M_010'));
    } catch (error) {
      handleError(error);
    }
  };
  const handleUpdateOperator = async (
    id: string,
    updateOperatorForm: OperatorInputType
  ) => {
    try {
      await api.put(`/operators/${id}`, {
        id: updateOperatorForm.operatorId.trim(),
        type: updateOperatorForm.operatorType,
        name: updateOperatorForm.operatorName.trim(),
        englishName: updateOperatorForm.englishName.trim(),
        postalCode: updateOperatorForm.postalCode.trim(),
        address: updateOperatorForm.address.trim(),
        businessTypeId: Number(updateOperatorForm.operatorContentDetail),
      });
      onSubmit();
      toast.success(t('messages.M_011'));
    } catch (error) {
      handleError(error);
    }
    onSubmit();
  };

  useEffect(() => {
    if (id) {
      fetchDetailOperator(id);
    }
  }, [id]);

  const fetchDetailOperator = async (id: string) => {
    try {
      const responseDetail = await api.get<ResDetailOperator>(
        `/operators/${id}`
      );
      setItemEditing(responseDetail.data);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className={styles.createBody}>
      <Form<OperatorInputType>
        initialValues={{
          operatorId: itemEditing?.id,
          operatorType: operatorType || '',
          operatorName: itemEditing?.name,
          englishName: itemEditing?.englishName,
          postalCode: itemEditing?.postalCode,
          address: itemEditing?.address,
          ...(itemEditing?.businessType
            ? {
                operatorContent: itemEditing?.businessType.id,
                operatorContentDetail: itemEditing?.businessType.item?.id,
              }
            : {}),
        }}
        validate={(values) => {
          return {
            operatorId: validateId(
              t('modal_create_brand.label_operator_id'),
              20,
              values.operatorId
            ),
            operatorName: validateStringField(
              t('operator_management.operator_name'),
              100,
              values.operatorName
            ),
            englishName: validateFieldEN(
              t('operator_management.operator_name_en'),
              values.englishName,
              100
            ),
            postalCode: validateInputNumber(values.postalCode),
            address: validateStringField(
              t('operator_management.address'),
              200,
              values.address
            ),
            operatorType:
              !values.operatorType || values.operatorType.length === 0
                ? ''
                : undefined,
            operatorContent: validateRequiredField(
              values.operatorContent,
              t('operator_management.operator_content') || ''
            ),
            operatorContentDetail: validateRequiredField(
              values.operatorContentDetail,
              t('operator_management.operator_content') || ''
            ),
          };
        }}
        onSubmit={(e) => {
          if (type === ModalType.add) handleCreateOperator(e);
          else if (itemEditing) {
            handleUpdateOperator(itemEditing.id, e);
          }
        }}
        mutators={{
          changeField: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
      >
        {(props) => {
          return (
            <form onSubmit={props.handleSubmit} className={styles.formInput}>
              <div className={styles.inputFrame}>
                <div className={styles.inputFramRow1}>
                  <div className={styles.operatorId}>
                    <InputDataListField
                      name={'operatorId'}
                      label={t('operator_management.operator_id') || ''}
                      placeholder={t('operator_management.operator_id') || ''}
                      required
                      className={styles.inputItem}
                      maxlength={20}
                      disabled={type === ModalType.update}
                    />
                  </div>

                  <div className={styles.operatorType}>
                    <SelectField
                      name={'operatorType'}
                      options={operatorTypeList}
                      label={t('operator_management.operator_type') || ''}
                      placeholder={t('operator_management.operator_type') || ''}
                      required
                      className={styles.inputItem}
                      disabled
                    />
                  </div>
                </div>

                <div className={styles.inputFramRow2}>
                  <div className={styles.operatorName}>
                    <InputDataListField
                      name={'operatorName'}
                      label={t('operator_management.operator_name') || ''}
                      placeholder={t('operator_management.operator_name') || ''}
                      required
                      className={styles.inputItem}
                      maxlength={100}
                    />
                  </div>

                  <div className={styles.postCode}>
                    <InputDataListField
                      name={'englishName'}
                      label={t('operator_management.operator_name_en')}
                      placeholder={t('operator_management.operator_name_en')}
                      required
                      className={styles.inputItem}
                      maxlength={100}
                      validRegex={REGEX_OBJECT.halfWidthCharacter}
                    />
                  </div>
                </div>

                <InputDataListField
                  name={'postalCode'}
                  label={t('operator_management.post_code') || ''}
                  placeholder={t('operator_management.post_code') || ''}
                  required
                  className={styles.inputItem}
                  maxlength={7}
                  type="number"
                />

                <div className={styles.inputFramRow3}>
                  <div className={styles.operatorContent}>
                    <SelectField
                      name="operatorContent"
                      options={
                        businessTypeList?.map((item) => {
                          return {
                            value: item.id,
                            label: item.name,
                          };
                        }) || []
                      }
                      onChange={() => {
                        props.form.mutators.changeField(
                          'operatorContentDetail',
                          undefined
                        );
                      }}
                      label={t('operator_management.operator_content') || ''}
                      placeholder={'選択する'}
                      required
                    />
                  </div>

                  <div className={styles.operatorContentDetail}>
                    <SelectField
                      required
                      label=""
                      name={'operatorContentDetail'}
                      value={props.values.operatorContentDetail}
                      options={
                        businessTypeList
                          ?.find(
                            (item) => item.id === props.values.operatorContent
                          )
                          ?.items.map((item) => {
                            return {
                              value: item.id,
                              label: item.name,
                            };
                          }) || []
                      }
                      placeholder={'選択する'}
                      disabled={!props.values.operatorContent}
                    />
                  </div>
                </div>

                <InputDataListField
                  label={t('operator_management.address') || ''}
                  placeholder={t('operator_management.address') || ''}
                  name="address"
                  required
                  onSelectSuggestItem={(value) => {
                    props.form.mutators.changeField('address', value);
                  }}
                  maxlength={200}
                />
              </div>

              <div className={styles.submit}>
                <button
                  className={
                    props.invalid ? styles.disableButton : styles.submitButton
                  }
                  disabled={props.invalid}
                  type="submit"
                >
                  {type === ModalType.add
                    ? t('operator_management.button_create_operator')
                    : t('operator_management.button_update_operator')}
                </button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default CreateUpdateOperatorInputForm;
