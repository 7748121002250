// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './BottomField.scss';
import React from 'react';

interface Props {
  children?: React.ReactNode;
}

export function BottomField({ children }: Props) {
  return <div className="bottom-field">{children}</div>;
}
