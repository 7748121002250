// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  selectTenants,
  selectTenantsInitialized,
} from 'redux/slices/siteInfoSlice';
import { Path } from 'routes';
import { useQuery } from 'util/queryParams';

export const useCheckTenantIdQuery = () => {
  const history = useHistory();
  const query = useQuery();
  const tenantId = query.get('tenantId');
  const tenants = useSelector(selectTenants);
  const tenantsInitialized = useSelector(selectTenantsInitialized);
  const isFirstTimeRender = useRef<boolean>(true);

  useEffect(() => {
    if (
      !tenantsInitialized ||
      !isFirstTimeRender.current ||
      !tenantId ||
      (tenants.length && tenants.some(({ id }) => id.toString() === tenantId))
    ) {
      return;
    }

    history.push(tenants.length ? Path.selectTenant : Path.emissionNew);
    isFirstTimeRender.current = false;
  }, [tenants, tenantId, tenantsInitialized]);
};
