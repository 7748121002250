// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import styles from './index.module.scss';

import { CategoryItem, ItemType } from 'util/Types';
import { ItemSize } from 'util/Enums';
import { IconFixedItem } from 'components/atoms/icons/IconFixedItem';
import OfflineFirstImage from 'components/molecules/OfflineFirstImage';
import { getUnitLabel } from 'util/commons';
import useNetworkConnection from 'util/hooks/useNetworkConnection';

export type EmissionBySizeData = {
  [ItemSize.LARGE]: CategoryItem[];
  [ItemSize.MEDIUM]: CategoryItem[];
  [ItemSize.SMALL]: CategoryItem[];
};

export const emissionBySizeDataFromEmissions = (
  emissions: CategoryItem[]
): EmissionBySizeData => {
  const emissionsBySizeData: EmissionBySizeData = {
    [ItemSize.LARGE]: [],
    [ItemSize.MEDIUM]: [],
    [ItemSize.SMALL]: [],
  };

  emissions?.forEach(
    (item) =>
      emissionsBySizeData[item.size] &&
      emissionsBySizeData[item.size].push(item)
  );

  return emissionsBySizeData;
};

type Props = {
  emissionBySizeData: EmissionBySizeData;
  onChange: (isModified: boolean) => void;
  onSubmit: (emissionBySizeData: EmissionBySizeData) => void;
};

type DragDropSortItem = {
  id: ItemSize;
  translationTitle: string;
  emissions: CategoryItem[];
};

const SortEmissionsDragDropSection: React.FC<Props> = ({
  emissionBySizeData,
  onChange,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const isOnline = useNetworkConnection();

  const [dragDropSortItems, setDragDropSortItems] = useState<
    DragDropSortItem[]
  >([
    {
      id: ItemSize.LARGE,
      translationTitle: 'common.size_item.large',
      emissions: [],
    },
    {
      id: ItemSize.MEDIUM,
      translationTitle: 'common.size_item.medium',
      emissions: [],
    },
    {
      id: ItemSize.SMALL,
      translationTitle: 'common.size_item.small',
      emissions: [],
    },
  ]);
  const [isModifiedDragDropSortItems, setIsModifiedDragDropSortItems] =
    useState<boolean>(false);

  const emissionItemContentRender = (emissionCategoryItem: CategoryItem) => {
    const isItemTypeFix = emissionCategoryItem.type === ItemType.FIX;

    return (
      <>
        <OfflineFirstImage
          src={emissionCategoryItem.imageUrl}
          cacheBucket="categoryImages"
          className={styles.emissionsDraggableItemImage}
        />

        <div className={styles.emissionsDraggableItemContent}>
          <p
            className={`${styles.emissionsDraggableItemName} ${
              isItemTypeFix ? styles.ellipseText : styles.ellipseTextTwoLine
            }`}
            title={emissionCategoryItem.name}
          >
            {emissionCategoryItem.name}
          </p>

          {isItemTypeFix && (
            <p
              className={`${styles.emissionsDraggableItemWasteUnitInfo} ${styles.ellipseText}`}
              title={`${emissionCategoryItem.convertKg}kg/${
                emissionCategoryItem.quantity
              }${getUnitLabel(emissionCategoryItem.unit)}`}
            >
              {`${emissionCategoryItem.convertKg}kg/${
                emissionCategoryItem.quantity
              }${getUnitLabel(emissionCategoryItem.unit)}`}
            </p>
          )}
        </div>

        {emissionCategoryItem.type &&
          emissionCategoryItem.type === ItemType.FIX && (
            <div
              className={
                styles[`emissionsDraggableItemType${emissionCategoryItem.type}`]
              }
            >
              <IconFixedItem />
            </div>
          )}
      </>
    );
  };

  const handleOnDragEnd = (result) => {
    const { source, destination } = result || {};
    if (!source || !destination) {
      return;
    }

    const { droppableId: sourceDroppableId, index: sourceIndex } = source;
    const { droppableId: destinationDroppableId, index: destinationIndex } =
      destination;

    const newDragDropSortItems = dragDropSortItems.map((dragDropSortItem) => ({
      ...dragDropSortItem,
      emissions: [...dragDropSortItem.emissions],
    }));
    const sourceDragDropSortItem = newDragDropSortItems.find(
      (dragDropSortItem) => dragDropSortItem.id === sourceDroppableId
    );
    const destinationDragDropSortItem =
      sourceDroppableId === destinationDroppableId
        ? sourceDragDropSortItem
        : newDragDropSortItems.find(
            (dragDropSortItem) => dragDropSortItem.id === destinationDroppableId
          );

    if (!sourceDragDropSortItem || !destinationDragDropSortItem) {
      return;
    }

    const changedDragDropSortItems = sourceDragDropSortItem.emissions.splice(
      sourceIndex,
      1
    );
    changedDragDropSortItems &&
      destinationDragDropSortItem.emissions.splice(
        destinationIndex,
        0,
        changedDragDropSortItems[0]
      );

    setDragDropSortItems(newDragDropSortItems);

    const isModified = newDragDropSortItems.some((dragDropSortItem) => {
      const initialEmissionIds = emissionBySizeData[dragDropSortItem.id]
        .map((emission) => emission.id)
        .join(',');
      const currentEmissionIds = dragDropSortItem.emissions
        .map((emission) => emission.id)
        .join(',');

      return currentEmissionIds !== initialEmissionIds;
    });
    setIsModifiedDragDropSortItems(isModified);

    onChange(isModified);
  };

  const handleSubmitDragDropSortItems = () => {
    if (!isModifiedDragDropSortItems) {
      return;
    }

    const newEmissionBySizeData: EmissionBySizeData = dragDropSortItems.reduce(
      (previous, current) => {
        return {
          ...previous,
          [current.id]: previous[current.id].concat(current.emissions),
        };
      },
      {
        [ItemSize.LARGE]: [],
        [ItemSize.MEDIUM]: [],
        [ItemSize.SMALL]: [],
      } as EmissionBySizeData
    );
    onSubmit(newEmissionBySizeData);
  };

  useEffect(() => {
    if (!emissionBySizeData) return;

    const newDragDropSortItems = dragDropSortItems.map((dragDropSortItem) => ({
      ...dragDropSortItem,
      emissions: emissionBySizeData[dragDropSortItem.id] || [],
    }));
    setDragDropSortItems(newDragDropSortItems);
    setIsModifiedDragDropSortItems(false);
  }, [emissionBySizeData]);

  return (
    <div className={styles.sortEmissionsDragDropSection}>
      <div className={styles.sortEmissionsDragDropSectionHeader}>
        <p className={styles.sortEmissionsDragDropSectionHeaderDescription}>
          {t('emission_new.sort_drag_drop.description')}
        </p>
      </div>

      <div className={styles.sortEmissionsDragDropSectionContent}>
        <div className={styles.emissionsDragDropContext}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            {dragDropSortItems.map(
              (dragDropSortItem, dragDropSortItemIndex) => {
                return (
                  <div
                    key={`droppable-${dragDropSortItemIndex}`}
                    className={styles.emissionsDroppable}
                  >
                    <div className={styles.emissionsDroppableHeader}>
                      <div
                        className={styles.emissionsDroppableHeaderLine}
                      ></div>
                      <h3 className={styles.emissionsDroppableHeaderTitle}>
                        {t(dragDropSortItem.translationTitle)}
                      </h3>
                      <div
                        className={styles.emissionsDroppableHeaderLine}
                      ></div>
                    </div>

                    <div className={styles.emissionsDroppableContent}>
                      <Droppable droppableId={dragDropSortItem.id}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={styles.emissionsDraggable}
                          >
                            {dragDropSortItem.emissions.map(
                              (emission, emissionIndex) => {
                                return (
                                  <Draggable
                                    key={emission.id.toString()}
                                    draggableId={emission.id.toString()}
                                    index={emissionIndex}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={
                                          styles.emissionsDraggableItem
                                        }
                                      >
                                        {emissionItemContentRender(emission)}
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              }
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </div>
                );
              }
            )}
          </DragDropContext>
        </div>
      </div>

      <div className={styles.sortEmissionsDragDropSectionFooter}>
        <button
          type="submit"
          className={styles.sortEmissionsDragDropSectionFooterSubmitButton}
          disabled={!isModifiedDragDropSortItems || !isOnline}
          onClick={handleSubmitDragDropSortItems}
        >
          {t('common.button.keep')}
        </button>
      </div>
    </div>
  );
};

export default SortEmissionsDragDropSection;
