// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './CompressionReceived.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { createAxios } from 'ts/createAxios';
import { LabelContents } from 'components/molecules/LabelContents';
import { CheckBoxCard } from 'components/molecules/CheckBoxCard';
import { IdType } from 'util/Enums';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { useTranslation } from 'react-i18next';
import { BottomField } from 'components/molecules/BottomField';
import { DATE_FORMAT } from 'util/ConstantValues';
import { toast } from 'react-toastify';
import { normalizeNumber } from 'util/commons';
import { EdiStatus } from 'util/Types';
import WarningModal from 'components/molecules/WarningModal';

export type Receive = {
  deliveryId: number;
  deliveryFromAreaName: string;
  deliveriedDatetime: string;
  weight: number;
  targetType: string;
  description: string;
  targetIds: string[];
  deliveryFromSiteName: string;
  ediNumber?: string;
  ediStatus?: EdiStatus;
};

export function CompressionReceived({ active }: { active: boolean }) {
  const { t } = useTranslation();
  const api = createAxios();
  const [checkedItems, setCheckedItems] = useState({});
  const [isCheckAll, setCheckAll] = useState(false);
  const [receives, setReceives] = useState<Receive[]>([]);
  const [alertEdiNumber, setAlertEdiNumber] = useState<boolean>(false);

  const handleChange = (e) => {
    setCheckedItems({
      ...checkedItems,
      [e.target.id]: e.target.checked,
    });
  };

  const callGetReceivesApi = async () => {
    const res = await api.get('/compressions/receives');
    setReceives(res.data);
  };

  useEffect(() => {
    if (active) callGetReceivesApi();
  }, [active]);

  const checkedReceives = useMemo(() => {
    return receives.filter((item) => checkedItems[item.deliveryId]);
  }, [receives, checkedItems, isCheckAll]);

  const submit = async () => {
    const selectedDuplicatedMaterialNames =
      checkedReceives.length > 0 &&
      !checkedReceives.every(
        (item, _, array) => item.description === array[0].description
      );
    if (selectedDuplicatedMaterialNames) {
      toast.error(t('collect_registration.submit_error_material_name'));
    } else {
      if (
        checkedReceives.some(
          ({ ediNumber, ediStatus }) =>
            !ediNumber && ediStatus != null && ediStatus !== EdiStatus.None
        ) &&
        !alertEdiNumber
      ) {
        setAlertEdiNumber(true);

        return;
      }

      setAlertEdiNumber(false);

      api
        .post('/compressions/receives', {
          deliveryIds: Object.keys(checkedItems).filter((deliveryId) => {
            return checkedItems[deliveryId];
          }),
        })
        .then(() => {
          toast.success(t('messages.M_135'));
          setCheckedItems({});
          callGetReceivesApi();
          setCheckAll(false);
        })
        .catch((e) => {
          toast.error(
            e?.response?.data?.message
              ? e.response.data.message
              : t('receives.signup_messages.failed')
          );
        });
    }
  };

  const handleCheckAll = () => {
    if (isCheckAll) {
      setCheckedItems({});
      setCheckAll(false);
    } else {
      const data = receives.map((i) => {
        return {
          [i.deliveryId]: true,
        };
      });
      setCheckedItems(Object.assign({}, ...data));
      setCheckAll(true);
    }
  };

  return (
    <div className={styles.compressionReceived}>
      <Form<Receive> onSubmit={() => {}} keepDirtyOnReinitialize>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.topSelect}>
              <div className={styles.checkBox} onClick={handleCheckAll}>
                {t('common.collective_selection')}&nbsp;
              </div>
              <div className={styles.label}>{t('common.content')}</div>
            </div>
            <div className="scroll-contents">
              {receives.length > 0 ? (
                receives.map((item, i) => {
                  return (
                    <CheckBoxCard
                      key={i}
                      id={String(item.deliveryId)}
                      checked={checkedItems[item.deliveryId] ?? false}
                      onChange={handleChange}
                    >
                      <LabelContents
                        label={t('common.date_time')}
                        contents={getFormatDateWithCurrentTimezone(
                          item.deliveriedDatetime,
                          DATE_FORMAT.slaYMDHm
                        )}
                      />
                      <LabelContents
                        label={t('common.place')}
                        contents={item.deliveryFromSiteName}
                      />
                      <LabelContents
                        label={t('common.material_name')}
                        contents={item.description}
                      />
                      <LabelContents
                        label={t('common.weight')}
                        contents={`${normalizeNumber({
                          value: item.weight,
                        })}kg`}
                      />
                      <LabelContents
                        label={t(`receives.id_type.${IdType[item.targetType]}`)}
                        contents={item.targetIds.join('<br>')}
                      />
                    </CheckBoxCard>
                  );
                })
              ) : (
                <div className="no-data">{t('messages.M_008')}</div>
              )}
            </div>
            <BottomField>
              <div className={styles.bottomBtn}>
                <button
                  className={styles.btnRequest}
                  type="button"
                  disabled={
                    Object.keys(checkedItems).filter(function (index) {
                      return checkedItems[index];
                    }).length === 0
                  }
                  onClick={submit}
                >
                  {t('common.receive')}
                </button>
              </div>
            </BottomField>

            {alertEdiNumber && (
              <WarningModal
                isDarkMode
                title={t('common.alert_no_edi_manifest_number.title')}
                content={t('common.alert_no_edi_manifest_number.content')}
                buttonConfirm={t('common.receive')}
                onClose={() => {
                  setAlertEdiNumber(false);
                }}
                onConfirm={submit}
              />
            )}
          </form>
        )}
      </Form>
    </div>
  );
}
