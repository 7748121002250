// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { BottomBtn } from 'components/molecules/BottomBtn';
import { LabelContents } from 'components/molecules/LabelContents';
import { CheckBoxCard } from 'components/molecules/CheckBoxCard';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT, DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import { toast } from 'react-toastify';
import styles from './PurchaseReceived.module.scss';
import {
  PurchaseReceive,
  PurchaseReceiveObj,
  PurchasesReceivesParams,
} from 'types/purchase';
import { createPurchasesProductsReceives } from 'apis/purchases/purchaseApi';
import TableLoading from 'components/atoms/TableLoading';
import { GetListParams } from 'types/api';
import _omit from 'lodash/omit';
import { mergeClasses, normalizeNumber } from 'util/commons';

type Props = {
  active: boolean;
  receives: PurchaseReceiveObj;
  getPurchasesReceives: (
    params?: GetListParams,
    forceRefresh?: boolean,
    operatorIdChange?: boolean
  ) => void;
  filterObj: PurchasesReceivesParams;
};

const PurchaseReceived: React.FC<Props> = ({
  active,
  receives,
  getPurchasesReceives,
  filterObj,
}) => {
  const { t } = useTranslation();
  const [checkedItems, setCheckedItems] = useState({});

  const [isCheckAll, setCheckAll] = useState(false);

  useEffect(() => {
    if (active)
      getPurchasesReceives(
        {
          page: DEFAULT_PAGE,
        },
        true,
        false
      );
  }, [active]);

  const handleChange = (e) => {
    if (filterObj?.operatorId && filterObj?.siteId) {
      setCheckedItems({
        ...checkedItems,
        [e.target.id]: e.target.checked,
      });
    }
  };

  const handleCheckAll = () => {
    if (filterObj?.operatorId && filterObj?.siteId) {
      if (isCheckAll) {
        setCheckedItems({});
        setCheckAll(false);
      } else {
        const data = receives.data.map((i) => {
          return {
            [i.deliveryId]: true,
          };
        });
        setCheckedItems(Object.assign({}, ...data));
        setCheckAll(true);
      }
    }
  };

  useEffect(() => {
    setCheckedItems((checkedItems) => {
      if (!receives.data.length) {
        return {};
      }

      const deliveryIds = receives.data.map(({ deliveryId }) =>
        deliveryId.toString()
      );
      const exceptionKeys = Object.keys(checkedItems).filter(
        (e) => !deliveryIds.includes(e)
      );

      return _omit(checkedItems, exceptionKeys);
    });
  }, [receives.data]);

  const checkedReceives = useMemo(() => {
    return receives.data.filter(
      (item) => checkedItems[Number(item.deliveryId)]
    );
  }, [receives, checkedItems, isCheckAll]);

  const submit = async () => {
    const selectedDuplicatedMaterialNames =
      checkedReceives.length > 0 &&
      !checkedReceives.every((item, _, array) => {
        return item.description === array[0].description;
      });

    if (selectedDuplicatedMaterialNames) {
      toast.error(t('purchase_page.receive.submit_error_material_name'));
    } else {
      createPurchasesProductsReceives({
        deliveryIds: Object.keys(checkedItems).filter((deliveryId) => {
          return checkedItems[deliveryId];
        }),
        materialName: checkedReceives[0].description,
        params: {
          operatorId: filterObj.operatorId || '',
          siteId: filterObj.siteId || '',
        },
        successCallback: () => {
          toast.success(t('messages.M_135'));
          getPurchasesReceives(
            {
              page: DEFAULT_PAGE,
            },
            true,
            false
          );
          setCheckedItems({});
          setCheckAll(false);
        },
      });
    }
  };

  useEffect(() => {
    getPurchasesReceives(
      {
        page: DEFAULT_PAGE,
      },
      true,
      true
    );
  }, [filterObj.operatorId]);
  return (
    <div className={styles.purchaseReceives}>
      <Form<PurchaseReceive>
        onSubmit={submit}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
      >
        {({ handleSubmit }) => (
          <form>
            <div className={styles.topSelect}>
              <div
                className={mergeClasses(styles.checkBox, {
                  [styles.disabledCheckAll]:
                    !filterObj.operatorId || !filterObj.siteId,
                })}
                onClick={handleCheckAll}
              >
                {t('purchase_page.receive.select_all')}&nbsp;
              </div>
              <div className={styles.label}>
                {t('purchase_page.receive.content')}
              </div>
            </div>
            <div className="scroll-contents">
              {receives.data.length > 0 ? (
                receives.data.map((item, i) => {
                  return (
                    <CheckBoxCard
                      key={i}
                      id={String(item.deliveryId)}
                      checked={checkedItems[item.deliveryId] ?? false}
                      onChange={handleChange}
                      disabled={!filterObj.operatorId || !filterObj.siteId}
                    >
                      <LabelContents
                        label={t('purchase_page.receive.date_time')}
                        contents={getFormatDateWithCurrentTimezone(
                          item.deliveriedDatetime,
                          DATE_FORMAT.slaYMDHm
                        )}
                      />
                      <LabelContents
                        label={t('purchase_page.receive.material_name')}
                        contents={item.description}
                      />
                      <LabelContents
                        label={t('purchase_page.receive.site_name')}
                        contents={item.deliveryFromSiteName}
                      />
                      <LabelContents
                        label={t('purchase_page.receive.weight')}
                        contents={`${normalizeNumber({
                          value: item.weight,
                        })}kg`}
                      />
                      <LabelContents
                        label={t('purchase_page.receive.registration_id')}
                        contents={item.targetIds.join('\n')}
                      />
                    </CheckBoxCard>
                  );
                })
              ) : (
                <div className="no-data">{t('messages.M_008')}</div>
              )}

              {receives.data.length > 0 && (
                <TableLoading
                  onNext={() => {
                    if (
                      receives.data.length >= PAGE_SIZE &&
                      receives.data.length < receives.total
                    ) {
                      getPurchasesReceives(
                        {
                          page: (receives.page || DEFAULT_PAGE) + 1,
                        },
                        undefined,
                        false
                      );
                    }
                  }}
                />
              )}
            </div>

            <BottomBtn
              text={t('purchase_page.receive.button')}
              onClick={handleSubmit}
              disabled={
                Object.keys(checkedItems).filter(function (index) {
                  return checkedItems[index];
                }).length === 0 ||
                !filterObj.operatorId ||
                !filterObj.siteId
              }
            ></BottomBtn>
          </form>
        )}
      </Form>
    </div>
  );
};

export default PurchaseReceived;
