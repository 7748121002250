// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import {
  createCollectSite,
  updateCollectSite,
} from 'apis/operator_site/siteApi';
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import CreateUpdateCollectSiteInputForm from 'components/molecules/CreateUpdateCollectSiteInputForm';
import { ModalSiteState } from 'pages/AdminSiteListManagement/AdminSiteListManagement';
import React, { useEffect, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import { ModalType } from 'util/Enums';
import {
  Vehicle,
  CollectSiteInput,
  ProvinceDistrict,
  CollectSite,
} from 'util/siteManagementTypes';
import styles from './index.module.scss';

interface Props {
  onClose?: () => void;
  createUpdateModal: ModalSiteState;
  operatorId?: string;
  onCreateSuccess: () => void;
  onUpdateSuccess: () => void;
}

const CreateUpdateCollectSiteModal: React.FC<Props> = ({
  onClose,
  createUpdateModal,
  onCreateSuccess,
  operatorId,
  onUpdateSuccess,
}) => {
  const api = createAxios();
  const commonsApi = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const [vehicleTypes, setVehicleTypes] = useState<Vehicle[]>([]);
  const [acceptanceGrades, setAcceptanceGrades] = useState<string[]>([]);
  const [acceptanceMaterials, setAcceptanceMaterials] = useState<string[]>([]);
  const [inputForm, setInputForm] = useState<CollectSiteInput>({
    siteAddresses: [],
    emails: [
      {
        email: '',
      },
    ],
    name: '',
    id: '',
    vehicleType: '',
    acceptanceGrades: [],
    acceptanceMaterials: [],
    truckNumbers: [],
  });

  const { siteEditing, type } = createUpdateModal;
  const [provinceSuggestion, setProvinceSuggestion] = useState<
    ProvinceDistrict[]
  >([]);
  const [collectSite, setCollectSite] = useState<CollectSite>();

  useEffect(() => {
    if (collectSite) {
      setInputForm({
        ...collectSite,
        siteAddresses: collectSite.siteAddresses.map((i) => {
          return {
            id: i.province,
            name: i.province,
          };
        }),
        emails:
          collectSite.emails.length > 0
            ? collectSite.emails.map((i) => {
                return { email: i };
              })
            : [
                {
                  email: '',
                },
              ],
        acceptanceGrades: collectSite.acceptanceGrade,
        acceptanceMaterials: collectSite.acceptanceMaterial,
        vehicleType: collectSite.vehicleType || '',
        truckNumbers:
          collectSite.trucks.map(({ truckNumber }) => truckNumber) || [],
      });
    }
  }, [collectSite]);

  useEffect(() => {
    commonsApi
      .get<Vehicle[]>('/commons/vehicle-types')
      .then((res) => setVehicleTypes(res.data));
    commonsApi
      .get<ProvinceDistrict[]>(
        '/commons/provinces-districts?isEmissionsType=true'
      )
      .then((res) => setProvinceSuggestion(res.data));
    commonsApi
      .get<{ acceptanceMaterial: string }[]>('/commons/acceptance-materials')
      .then((res) =>
        setAcceptanceMaterials(res.data.map((i) => i.acceptanceMaterial))
      );
    commonsApi
      .get<{ acceptanceGrade: string }[]>('/commons/acceptance-grades')
      .then((res) =>
        setAcceptanceGrades(res.data.map((i) => i.acceptanceGrade))
      );
    siteEditing
      ? api
          .get<CollectSite>(`/sites/${siteEditing?.id}`)
          .then((res) => setCollectSite(res.data))
      : undefined;
  }, []);

  const handleCreateCollectSite = async (form: CollectSiteInput) => {
    if (operatorId) {
      createCollectSite({ operatorId, form }, () => {
        onCreateSuccess();
      });
    }
  };

  const handleUpdateCollectSite = async (form: CollectSiteInput) => {
    if (collectSite) {
      updateCollectSite({ form, collectSite }, () => {
        onUpdateSuccess();
      });
    }
  };

  return (
    <div className={styles.createTenantModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {type === ModalType.add ? '拠点を追加' : '拠点を編集'}
            </p>
            <div className={styles.top}>
              <IconClose
                onClick={() => {
                  onClose?.();
                }}
              />
            </div>
          </div>
          <CreateUpdateCollectSiteInputForm
            createUpdateModal={createUpdateModal}
            vehicleTypes={vehicleTypes}
            provinceSuggestion={provinceSuggestion}
            acceptanceMaterials={acceptanceMaterials}
            onSubmit={(form) => {
              createUpdateModal.type === ModalType.add
                ? handleCreateCollectSite(form)
                : handleUpdateCollectSite(form);
            }}
            acceptanceGrades={acceptanceGrades}
            inputForm={inputForm}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateUpdateCollectSiteModal;
