// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  onClick?: (e?: React.MouseEvent<SVGElement>) => void;
}

export const IconBarcode = ({ onClick }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4V18H3.75V4H2ZM4.625 4V18H6.375V4H4.625ZM7.25 18V4H9.875V12.7181C9.5124 12.8645 9.17263 13.0847 8.87868 13.3787C7.70711 14.5503 7.70711 16.4497 8.87868 17.6213L9.25736 18H7.25ZM19.5 9.76382V4H17.75V11.5074L19.3787 9.87868C19.4183 9.83905 19.4588 9.80077 19.5 9.76382ZM16.875 12.3824V4H16V13.2574L16.875 12.3824ZM15.125 14.1324V4H12.5V12.9011C12.7221 13.0294 12.9313 13.1887 13.1213 13.3787L14.5 14.7574L15.125 14.1324ZM11.625 12.5655V4H10.75V12.5104C11.0422 12.4861 11.3372 12.5045 11.625 12.5655Z"
        fill="#6F7175"
      />
      <path
        d="M11 15.5L14.3586 18.8586C14.4367 18.9367 14.5633 18.9367 14.6414 18.8586L21.5 12"
        stroke="#6F7175"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
