// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styles from './TotalWeightOfEachProcessFilter.module.scss';
import { useTranslation } from 'react-i18next';
import { DashboardCalendarField } from 'components/molecules/DashboardCalendarField';
import IconUnitKg from 'components/atoms/icons/IconUnitKg';
import IconUnitT from 'components/atoms/icons/IconUnitT';
import { FilterObject } from 'types/dashboard';
import { UnitType } from 'util/constants/dashboard';

type Props = {
  filterObject: FilterObject;
  setFilter: (filter: FilterObject) => void;
  unitType: UnitType;
  setUnitType: (unitType: UnitType) => void;
};

const TotalWeightOfEachProcessFilter: React.FC<Props> = ({
  filterObject,
  setFilter,
  unitType,
  setUnitType,
}) => {
  const { t } = useTranslation();

  const onChangeFilter = (key: string, value: any) => {
    setFilter({ ...filterObject, [key]: value });
  };

  const title =
    t('purchase_page.dashboard.title') || '各プロセスでの累計取扱量';

  return (
    <div className={styles.totalWeightOfEachProcessFilter}>
      <div className={styles.heading}>
        <p>{title}</p>
        <div className={styles.actions}>
          <div className={styles.filter}>
            <button
              className={
                unitType === UnitType.KG ? styles.filterButtonSelected : ''
              }
              onClick={() => {
                setUnitType(UnitType.KG);
              }}
            >
              <IconUnitKg />
            </button>
            <button
              className={
                unitType === UnitType.T ? styles.filterButtonSelected : ''
              }
              onClick={() => {
                setUnitType(UnitType.T);
              }}
            >
              <IconUnitT />
            </button>
          </div>

          <div className={styles.filterDate}>
            <DashboardCalendarField
              onChange={(dates) =>
                onChangeFilter('dates', { from: dates[0], to: dates[1] })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalWeightOfEachProcessFilter;
