// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import CreateUpdateCategoryInputForm, {
  CreateUpdateCategoryFormInputType,
} from 'components/molecules/CreateUpdateCategoryInputForm';
import { Suggestion } from 'components/molecules/InputDataListField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Category } from '../CustomizeListCategory';
import styles from './CreateUpdateCategoryModal.module.scss';
interface Props {
  onClose: () => void;
  type: CreateUpdateCategoryFormInputType;
  itemId?: string;
  onSubmit: () => void;
  detailCategory?: Category;
  dataSuggestions: Suggestion[];
}

const CreateUpdateCategoryModal: React.FC<Props> = ({
  onClose,
  type,
  onSubmit,
  detailCategory,
  dataSuggestions,
}) => {
  const { t } = useTranslation();

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };
  return (
    <div className={styles.createCategoryModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {type === CreateUpdateCategoryFormInputType.add
                ? t('common.button.add_category')
                : t('modal_category.edit_title')}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <CreateUpdateCategoryInputForm
            dataSuggestions={dataSuggestions}
            detailCategory={detailCategory}
            onSubmit={handleSubmit}
            type={type}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateUpdateCategoryModal;
