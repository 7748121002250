// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import { Form } from 'react-final-form';
import styles from './CreateUpdateFloorTenantInputForm.module.scss';
import { InputDataListField } from '../InputDataListField';
import { validateMaxLength } from 'util/validator';
import { ModalType } from 'util/Enums';
import { SelectField } from '../SelectField';
import { SectionItem } from 'util/Types';
import { ModalFloorState } from 'pages/AdminSectionFloorManagement';
import { useTranslation } from 'react-i18next';

interface Props {
  sectionList: SectionItem[];
  createUpdateFloorModal: ModalFloorState;
  onCreateFloorTenant: (createFloorForm: FloorInput) => void;
  onUpdateFloorTenant: (updateFloorForm: FloorInput, id: number) => void;
}

export type FloorInput = {
  id: number;
  floorName: string;
  sectionId?: number;
};

const CreateUpdateFloorTenantInputForm: React.FC<Props> = ({
  sectionList,
  createUpdateFloorModal,
  onCreateFloorTenant,
  onUpdateFloorTenant,
}) => {
  const { t } = useTranslation();
  const { floorEditing } = createUpdateFloorModal;

  const sectionOptions = sectionList.map((section) => {
    return {
      value: section.id,
      label: section.name,
    };
  });

  return (
    <div className={styles.createBody}>
      <Form<FloorInput>
        initialValues={
          floorEditing
            ? {
                id: floorEditing.id,
                floorName: floorEditing.name.trim(),
                sectionId: floorEditing.sectionId,
              }
            : {
                sectionId: createUpdateFloorModal.sectionId,
              }
        }
        onSubmit={(values: FloorInput) => {
          if (createUpdateFloorModal.type === ModalType.add) {
            onCreateFloorTenant(values);
          } else {
            onUpdateFloorTenant(values, Number(floorEditing?.id));
          }
        }}
        mutators={{
          changeField: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        validate={(values) => {
          return {
            floor:
              !values.floorName || values.floorName.trim().length === 0
                ? ''
                : undefined,
          };
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} className={styles.formInput}>
            <div className={styles.inputFrame}>
              <SelectField
                label={t('tenant.thead_section') || 'セクション'}
                name="sectionId"
                placeholder={t('tenant.thead_section') || 'セクション'}
                required
                disabled
                options={sectionOptions}
              />

              <InputDataListField
                validate={(value) => validateMaxLength(value, 100, 'フロア')}
                label={t('tenant.thead_floor') || 'フロア'}
                placeholder={t('tenant.thead_floor') || 'フロア'}
                name="floorName"
                required
                maxlength={100}
              />
            </div>

            <div className={styles.line} />

            <div className={styles.submit}>
              <button
                disabled={props.invalid}
                className={
                  props.invalid ? styles.disableButton : styles.submitButton
                }
                type="submit"
              >
                {createUpdateFloorModal.type === ModalType.add
                  ? t('common.modal_create.add_button')
                  : t('common.modal_update.edit_button')}
              </button>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

export default CreateUpdateFloorTenantInputForm;
