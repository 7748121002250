// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

export const IconICPlus = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9" stroke="#24252A" strokeWidth="2" />
      <path
        d="M5.5 10H14.5"
        stroke="#24252A"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
