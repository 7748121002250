import { createAxios } from 'ts/createAxios';
import { CurrencyConvertResponse } from 'types/purchase';
import { API_ENDPOINTS } from 'util/endPoints';
import { handleError } from 'util/errorHandler';

export const fetchExchangeRateCurrencies = async (p: {
  params: {
    base: string;
    target: string;
  };
  successCallback?: (data: CurrencyConvertResponse) => void;
}) => {
  const { params, successCallback } = p;

  try {
    const api = createAxios();
    const { data } = await api.get(
      API_ENDPOINTS.COMMON_EXCHANGE_RATE_CURRENCIES,
      {
        params,
      }
    );
    successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};
