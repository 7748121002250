// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import Portal from 'components/atoms/Portal';
import { IconDropDown } from 'components/atoms/icons/IconDropDown';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from 'util/Types';
import { mergeClasses } from 'util/commons';
import useOutsideClick from 'util/hooks/useClickOutside';
import { useGetBoundingClientRect } from 'util/hooks/useGetBoundingClientRect';
import { CheckBoxCardV2 } from '../CheckBoxCardV2';
import styles from './index.module.scss';
import TableFilterExpandedValue from 'components/atoms/TableFilterExpandedValue';
import { IconRemoveTag } from 'components/atoms/icons/IconRemoveTag';

type TableFilterMultiSelectProps = {
  label: string;
  options?: Option[];
  values?: string[];
  onChange?: (values?: string[]) => void;
  allowClear?: boolean;
  disabled?: boolean;
  expanded?: boolean;
  fullWidth?: boolean;
};

const TableFilterMultiSelect: React.FC<TableFilterMultiSelectProps> = ({
  label,
  options,
  values,
  onChange,
  allowClear,
  disabled,
  expanded,
  fullWidth,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { ref: dropdownRef } = useOutsideClick({
    onClickOutside: () => {
      setOpen(false);
    },
    exceptRefs: [containerRef],
  });

  const { domRect } = useGetBoundingClientRect(
    {
      el: containerRef.current?.parentElement,
    },
    [open]
  );

  if (!options?.length || disabled) {
    return <>{label}</>;
  }

  const handleChange = (opt: Option) => {
    if (values?.includes(opt.value)) {
      onChange?.(values.filter((e) => e !== opt.value));
    } else {
      onChange?.([...(values ?? []), opt.value]);
    }
  };

  const handleCheckAll = () => {
    if (options.every(({ value }) => values?.includes(value))) {
      onChange?.(undefined);
    } else {
      onChange?.(options.map(({ value }) => value));
    }
  };

  const renderActionButton = () => {
    if (expanded) {
      return (
        <TableFilterExpandedValue
          value={
            <div className={styles.valuesList}>
              {values?.map((item, index) => (
                <div key={index} className={styles.valueItem}>
                  <div className={styles.value}>{item}</div>
                  <IconRemoveTag
                    onClick={(e) => {
                      e?.stopPropagation();
                      e?.preventDefault();
                      onChange?.(values.filter((val) => val !== item));
                    }}
                  />
                </div>
              ))}
            </div>
          }
          allowClear={allowClear}
          valueCondition={Boolean(values?.length)}
          clearCondition={Boolean(values?.length)}
          onChange={onChange}
        />
      );
    }

    return <IconDropDown color={values?.length ? '#86EA5C' : '#B7B7B7'} />;
  };

  const renderClearButton = () => {
    if (!allowClear || !values?.length || expanded) {
      return null;
    }

    return (
      <div
        onClick={(e) => {
          e?.stopPropagation();
          e?.preventDefault();
          onChange?.(undefined);
        }}
        className={styles.clear}
      >
        {t('common.button.clear')}
      </div>
    );
  };

  return (
    <div ref={containerRef}>
      <div
        onClick={() => setOpen(!open)}
        className={mergeClasses(styles.labelContainer, {
          [styles.labelExpanded]: expanded,
        })}
      >
        <span className={styles.label}>{label}</span>
        {renderActionButton()}
      </div>
      {open && domRect && (
        <Portal>
          <div ref={dropdownRef}>
            <div
              className={styles.dropDown}
              style={{
                top: domRect.top + domRect.height + 4,
                left: domRect.left,
                ...(fullWidth && {
                  width: domRect.width,
                  maxWidth: domRect.width,
                  minWidth: domRect.width,
                }),
              }}
            >
              <ul>
                {options.length > 1 && (
                  <li
                    key={-1}
                    className={mergeClasses({
                      [styles.selected]: options.every(({ value }) =>
                        values?.includes(value)
                      ),
                    })}
                    onClick={handleCheckAll}
                  >
                    <CheckBoxCardV2
                      type="dark"
                      checked={options.every(({ value }) =>
                        values?.includes(value)
                      )}
                    >
                      <span className={styles.optionName}>
                        {t('common.button.all')}
                      </span>
                    </CheckBoxCardV2>
                  </li>
                )}

                {options.map((opt, index) => {
                  return (
                    <li
                      key={index}
                      className={mergeClasses({
                        [styles.selected]: values?.includes(opt.value),
                      })}
                      onClick={() => handleChange(opt)}
                    >
                      <CheckBoxCardV2
                        type="dark"
                        checked={!!values?.includes(opt.value)}
                      >
                        <span className={styles.optionName}>{opt.label}</span>
                      </CheckBoxCardV2>
                    </li>
                  );
                })}
              </ul>
              {renderClearButton()}
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

TableFilterMultiSelect.defaultProps = {
  allowClear: true,
};

export default TableFilterMultiSelect;
