// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

interface Props {
  width?: number;
  height?: number;
  strokeWidth?: number;
  onClick?: () => void;
}
export const IconClose: React.FC<Props> = ({
  width = 16,
  height = 16,
  strokeWidth = 1.5,
  onClick,
}) => {
  return (
    <div onClick={onClick} style={{ cursor: 'pointer' }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2L14 14M2 14L14 2L2 14Z"
          stroke="#6F7175"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
