// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  onClick?: () => void;
}

export const IconCloudUpload = ({ onClick }: Props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M8.79967 20.7999C7.36691 20.798 5.98937 20.247 4.95051 19.2604C3.91164 18.2737 3.29048 16.9263 3.21485 15.4955C3.13921 14.0648 3.61485 12.6594 4.54389 11.5687C5.47293 10.478 6.7847 9.78485 8.20927 9.63191C7.78981 7.98693 8.04099 6.24269 8.90755 4.78291C9.77412 3.32313 11.1851 2.26738 12.8301 1.84791C14.4751 1.42844 16.2193 1.67962 17.6791 2.54619C19.1389 3.41276 20.1946 4.82373 20.6141 6.46871C21.5924 6.33249 22.5883 6.39902 23.5398 6.66417C24.4913 6.92932 25.378 7.38742 26.1449 8.00996C26.9118 8.6325 27.5424 9.40618 27.9974 10.2829C28.4525 11.1595 28.7223 12.1205 28.79 13.1059C28.8578 14.0913 28.7221 15.0802 28.3913 16.0109C28.0606 16.9416 27.5419 17.7943 26.8674 18.516C26.193 19.2377 25.3773 19.8128 24.4711 20.2058C23.5648 20.5987 22.5874 20.8009 21.5997 20.7999H17.5997V15.0607L19.6685 17.1295C19.9702 17.421 20.3744 17.5822 20.7939 17.5786C21.2134 17.5749 21.6147 17.4067 21.9114 17.11C22.208 16.8134 22.3763 16.4121 22.38 15.9926C22.3836 15.573 22.2223 15.1689 21.9309 14.8671L17.1309 10.0671C16.8308 9.76715 16.4239 9.59865 15.9997 9.59865C15.5754 9.59865 15.1685 9.76715 14.8685 10.0671L10.0685 14.8671C9.77702 15.1689 9.61575 15.573 9.61939 15.9926C9.62304 16.4121 9.79131 16.8134 10.088 17.11C10.3846 17.4067 10.7859 17.5749 11.2054 17.5786C11.6249 17.5822 12.0291 17.421 12.3309 17.1295L14.3997 15.0623V20.7999H8.79967Z"
        fill="#D1D1D1"
      />
      <path
        d="M14.4004 20.7998H17.6004V28.7998C17.6004 29.2242 17.4318 29.6311 17.1318 29.9312C16.8317 30.2312 16.4247 30.3998 16.0004 30.3998C15.576 30.3998 15.1691 30.2312 14.869 29.9312C14.569 29.6311 14.4004 29.2242 14.4004 28.7998V20.7998Z"
        fill="#D1D1D1"
      />
    </svg>
  );
};
