// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { getOperatorPools, getStations } from 'apis/commons';
import { fetchTruckOption } from 'apis/operator_site/siteApi';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { createAxios } from 'ts/createAxios';
import { REGEX_OBJECT } from 'util/ConstantValues';
import { ModalType, OperatorType, RoleType } from 'util/Enums';
import { Station, Truck } from 'util/Types';
import {
  AccountForm,
  AccountOperator,
  getRoleOptions,
  listOperatorType,
} from 'util/accountManagementTypes';
import { ResSites } from 'util/siteManagementTypes';
import {
  validateId,
  validatePasswordField,
  validateRequiredField,
  validateSelectField,
  validateStringField,
} from 'util/validator';
import { InputDataListField } from '../InputDataListField';
import { SelectField } from '../SelectField';
import { SelectFieldMultipleChoise } from '../SelectFieldMultipleChoise';
import styles from './index.module.scss';
import { Tenant } from '../SelectTenantCard';

interface Props {
  initForm: AccountForm;
  type: ModalType;
  handleSubmit: (createUpdateAccountForm: AccountForm) => void;
  operators: AccountOperator[];
  trucks: Truck[];
  setTrucks: (trucks: Truck[]) => void;
  operatorType?: string;
  tenants: Tenant[];
  fetchTenants: (siteId: string) => void;
}

const CreateUpdateAccountForm: React.FC<Props> = ({
  initForm,
  type,
  handleSubmit,
  operators,
  trucks,
  setTrucks,
  operatorType,
  tenants,
  fetchTenants,
}) => {
  const api = createAxios();
  const [operatorWithType, setOperatorWithType] = useState<AccountOperator[]>(
    []
  );
  const [stations, setStations] = useState<Station[]>([]);
  const [operatorPools, setOperatorPools] = useState<{ id: number }[]>([]);

  const handleFetchTruck = (siteId: string, formProps) => {
    if (!siteId) {
      formProps.form.mutators.setValue('truckId', '');
      return;
    }
    fetchTruckOption(siteId, (data) => {
      if (data?.trucks) {
        setTrucks(data.trucks);
      }
    });
  };

  useEffect(() => {
    if (operatorWithType.length === 0) {
      setOperatorWithType(operators.sort((a, b) => a.id.localeCompare(b.id)));
    }
  }, [operators]);

  useEffect(() => {
    fetchStations();
    if (operatorType === OperatorType.AdminPool) {
      fetchOperatorPools();
    }
  }, []);

  const fetchStations = () => {
    getStations((res) => {
      setStations(res);
    });
  };

  const fetchOperatorPools = () => {
    getOperatorPools((res) => {
      setOperatorPools(res);
    });
  };

  return (
    <div className={styles.createBody}>
      <Form<AccountForm>
        onSubmit={handleSubmit}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        initialValues={initForm}
        validate={(values) => {
          return {
            id: validateId('ユーザーID', 20, values.id),
            name: validateStringField('名前', 100, values.name),
            password:
              type === ModalType.add
                ? validatePasswordField('パスワード', 6, values.password)
                : values.password && values.password.length > 0
                ? validatePasswordField('パスワード', 6, values.password)
                : undefined,
            operatorType: validateSelectField(
              '事業者タイプ',
              values.operatorType
            ),
            role: validateSelectField('役割', values.role),
            operatorId:
              values.operatorType !== OperatorType.AdminPool
                ? validateSelectField('事業者ID', values.operatorId)
                : undefined,
            operatorPoolId:
              values.operatorType === OperatorType.AdminPool
                ? validateSelectField('事業者ID', values.operatorPoolId)
                : undefined,
            siteId:
              (values.operatorType === OperatorType.Emissions &&
                values.role !== RoleType.AdminOperator) ||
              values.operatorType === OperatorType.Compressions ||
              values.operatorType === OperatorType.Recycle ||
              values.operatorType === OperatorType.Purchase ||
              (values.operatorType === OperatorType.Collects &&
                values.role === RoleType.UserNormal)
                ? validateSelectField('拠点ID', values.siteId)
                : undefined,
            stationIds:
              values.operatorType === OperatorType.AdminPool
                ? validateRequiredField(
                    values.stationIds,
                    '表示されるステーション'
                  )
                : undefined,
            tenantId:
              values.role === RoleType.AdminTenant
                ? validateSelectField('テナント', values.tenantId)
                : undefined,
          };
        }}
      >
        {(props) => {
          const handleResetOperatorType = (value) => {
            if (value === OperatorType.AdminPool) {
              fetchOperatorPools();
            } else {
              api
                .get<{ operators: AccountOperator[] }>(
                  `/operators?type=${value}`
                )
                .then((res) => {
                  setOperatorWithType(
                    res.data.operators.sort((a, b) => a.id.localeCompare(b.id))
                  );
                });
            }
            props.form.mutators.setValue('operatorId', '');
            props.form.mutators.setValue('role', '');
            props.form.mutators.setValue('siteId', '');
            props.form.mutators.setValue('truckId', '');
            props.form.mutators.setValue('operatorPoolId', '');
            props.form.mutators.setValue('stationIds', []);
            props.form.mutators.setValue('tenantId', '');
          };
          const handleFetchSite = async (value: string | number) => {
            props.form.mutators.setValue('siteId', '');
            props.form.mutators.setValue('tenantId', '');
            props.form.mutators.setValue('truckId', '');

            if (
              props.values.operatorType !== OperatorType.Admin &&
              props.values.operatorType !== OperatorType.Brand
            ) {
              await api
                .get<ResSites>(`/sites?operatorId=${value}`)
                .then((res) => {
                  props.form.mutators.setValue('sites', res.data.sites);
                });
            }
          };

          return (
            <form onSubmit={props.handleSubmit} className={styles.formInput}>
              <div className={styles.inputFrame}>
                <InputDataListField
                  label={'ユーザーID'}
                  placeholder={'ユーザーID'}
                  name="id"
                  required
                  maxlength={20}
                  disabled={type === ModalType.update ? true : false}
                />
                <InputDataListField
                  label={'名前'}
                  placeholder={'名前'}
                  name="name"
                  maxlength={100}
                  required
                />
                <InputDataListField
                  label={'パスワード'}
                  placeholder={'パスワード'}
                  name="password"
                  type="password"
                  required={type === ModalType.add}
                  maxlength={20}
                />
                <SelectField
                  required
                  name="operatorType"
                  options={listOperatorType}
                  label={'事業者タイプ'}
                  placeholder={'事業者タイプ'}
                  onChange={handleResetOperatorType}
                />
                <SelectField
                  disabled={!props.values.operatorType}
                  required
                  options={
                    props.values.operatorType
                      ? getRoleOptions(props.values.operatorType)
                      : []
                  }
                  name="role"
                  label={'役割'}
                  placeholder={'役割'}
                  onChange={(value) => {
                    if (
                      value === RoleType.AdminOperator &&
                      (props.values.operatorType === OperatorType.Emissions ||
                        props.values.operatorType === OperatorType.Collects)
                    ) {
                      props.form.mutators.setValue('siteId', '');
                      props.form.mutators.setValue('truckId', '');
                    }

                    props.form.mutators.setValue('tenantId', '');
                    if (value === RoleType.AdminTenant && props.values.siteId) {
                      fetchTenants(props.values.siteId);
                    }
                  }}
                />
                {props.values.operatorType === OperatorType.AdminPool ? (
                  <InputDataListField
                    label="事業者ID"
                    placeholder="事業者ID"
                    name="operatorPoolId"
                    required
                    maxlength={20}
                    dataSuggestions={
                      operatorPools?.map((i) => {
                        return { name: i.id.toString() };
                      }) || []
                    }
                    onSelectSuggestItem={(value) => {
                      props.form.mutators.setValue('operatorPoolId', value);
                    }}
                    validRegex={REGEX_OBJECT.numberStringOnly}
                  />
                ) : (
                  <SelectField
                    required
                    disabled={!props.values.operatorType}
                    options={operatorWithType.map((i) => {
                      return { label: i.id, value: i.id };
                    })}
                    name="operatorId"
                    label={'事業者ID'}
                    placeholder={'事業者ID'}
                    onChange={handleFetchSite}
                  />
                )}
                <SelectField
                  disabled={
                    !props.values.operatorType ||
                    !props.values.operatorId ||
                    props.values.operatorType === OperatorType.Admin ||
                    props.values.operatorType === OperatorType.Brand ||
                    props.values.operatorType === OperatorType.AdminPool ||
                    (props.values.operatorType === OperatorType.Emissions &&
                      props.values.role === RoleType.AdminOperator)
                  }
                  required={
                    (props.values.operatorType === OperatorType.Emissions &&
                      props.values.role !== RoleType.AdminOperator) ||
                    props.values.operatorType === OperatorType.Compressions ||
                    props.values.operatorType === OperatorType.Recycle ||
                    props.values.operatorType === OperatorType.Purchase ||
                    (props.values.operatorType === OperatorType.Collects &&
                      props.values.role === RoleType.UserNormal)
                  }
                  options={
                    props.values.sites?.map((i) => {
                      return { label: i.id, value: i.id };
                    }) || []
                  }
                  name="siteId"
                  label={'拠点ID'}
                  placeholder={'拠点ID'}
                  onChange={(value) => {
                    if (props.values.operatorType === OperatorType.Collects) {
                      props.form.mutators.setValue('truckId', '');
                      handleFetchTruck(value.toString(), props);
                    }

                    if (props.values.role === RoleType.AdminTenant) {
                      props.form.mutators.setValue('tenantId', '');

                      if (!value.toString()) {
                        return;
                      }

                      fetchTenants(value.toString());
                    }
                  }}
                />

                {props.values.operatorType === OperatorType.AdminPool && (
                  <SelectFieldMultipleChoise
                    options={stations}
                    name="stationIds"
                    label="表示されるステーション"
                    placeholder="表示されるステーション"
                    onChange={(tags) =>
                      props.form.mutators.setValue(
                        'stationIds',
                        tags.map(({ id }) => id)
                      )
                    }
                    selectedOptions={props.values.stationIds || []}
                    required
                  />
                )}

                {props.values.role === RoleType.AdminTenant && (
                  <SelectField
                    required
                    disabled={!props.values.operatorId || !props.values.siteId}
                    options={tenants?.map((i) => {
                      return { label: i.name, value: i.id };
                    })}
                    name="tenantId"
                    label={'テナント'}
                    placeholder={'テナント'}
                  />
                )}

                {props.values.operatorType === OperatorType.Collects && (
                  <SelectField
                    name="truckId"
                    label={'トラックナンバー'}
                    placeholder={'トラックナンバー'}
                    disabled={!props.values.siteId}
                    options={trucks?.map((i) => {
                      return { label: i.truckNumber, value: i.id };
                    })}
                  />
                )}
              </div>
              <div className={styles.submit}>
                <button
                  className={`${styles.submitButton} ${
                    props.invalid ? styles.disableButton : ''
                  }`}
                  disabled={props.invalid}
                  type="submit"
                >
                  {type === ModalType.add ? 'アカウントを追加する' : '保存する'}
                </button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default CreateUpdateAccountForm;
