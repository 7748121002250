// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import CheckboxField from 'components/atoms/CheckboxField';
import Tag from 'components/atoms/Tag';
import { IconDropDown } from 'components/atoms/icons/IconDropDown';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'util/commons';
import useOutsideClick from 'util/hooks/useClickOutside';
import styles from './styles.module.scss';

type SingleVal = string | number;

export type TreeOption = {
  label: React.ReactNode;
  value: SingleVal;
  subOptions?: TreeOption[];
};

export type TreeValue = {
  value: SingleVal;
  subValues?: TreeValue[];
};

export type TreeSelectProps = {
  names: string[];
  selectedValues?: TreeValue[];
  onChange?: (param?: TreeValue[]) => void;
  options?: TreeOption[];
  className?: string;
  placeholder?: string | null;
};

const TreeSelect: React.FC<TreeSelectProps> = ({
  names,
  selectedValues: selectedValuesProp,
  onChange,
  options,
  className,
  placeholder,
}) => {
  const [dropdownOpened, setDropdownOpened] = useState<boolean>(false);
  const [openedIds, setOpenedIds] = useState<string[]>([]);
  const [search, setSearch] = useState<string>();
  const { t } = useTranslation();

  const { ref } = useOutsideClick({
    onClickOutside: () => {
      setDropdownOpened(false);
      setSearch('');
    },
  });

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleScrollTagInput = () => {
    wrapperRef.current?.scrollTo({
      left: wrapperRef.current.scrollWidth - wrapperRef.current.clientWidth,
      behavior: 'smooth',
    });
  };

  const selectedValues = useMemo(() => {
    const convertSelectedValue = (values: TreeValue[] | undefined) => {
      if (!values?.length) {
        return values;
      }

      return values.reduce((rs: TreeValue[], item) => {
        rs.push({
          value: item.value,
          subValues: !item.subValues?.length
            ? []
            : convertSelectedValue(item.subValues),
        });

        return rs;
      }, []);
    };

    return convertSelectedValue(selectedValuesProp);
  }, [selectedValuesProp]);

  const checkTextInclude = (target: string | number, search: string | number) =>
    target?.toString().toLowerCase().includes(search.toString().toLowerCase());

  const filteredOptions = useMemo(() => {
    if (!search) {
      return options;
    }

    const checkSearchMatch = (options?: TreeOption[]) =>
      Boolean(
        options?.find(
          ({ value, subOptions }) =>
            checkTextInclude(value, search) || checkSearchMatch(subOptions)
        )
      );

    const filterOpt = (options?: TreeOption[]) =>
      options
        ?.filter(
          ({ value, subOptions }) =>
            checkTextInclude(value, search) || checkSearchMatch(subOptions)
        )
        .map(({ subOptions, label, value }) => ({
          label,
          value,
          subOptions: checkTextInclude(value, search)
            ? subOptions
            : filterOpt(subOptions),
        }));

    return filterOpt(options);
  }, [search]);

  const firstNodeLabelMapper = useMemo(
    () =>
      options?.reduce((rs: Record<string, React.ReactNode>, item) => {
        rs[item.value] = item.label;

        return rs;
      }, {}),
    [options]
  );

  const handleClickOption = (
    event: React.MouseEvent<HTMLDivElement>,
    keyId: string
  ) => {
    event.stopPropagation();

    if (openedIds.includes(keyId)) {
      setOpenedIds((prev) => prev.filter((e) => e !== keyId));
    } else {
      setOpenedIds((prev) => [...prev, keyId]);
    }
  };

  const valueChecked = (
    value: SingleVal,
    selectedValuesTmp: TreeValue[] | undefined,
    itemsLeft: TreeOption[]
  ) => {
    if (!itemsLeft.length) {
      return Boolean(selectedValuesTmp?.find((e) => e?.value === value));
    }

    return valueChecked(
      value,
      selectedValuesTmp?.find((e) => e.value === itemsLeft[0]?.value)
        ?.subValues,
      itemsLeft.slice(1)
    );
  };

  const removeAllEmptySubValues = (
    values: TreeValue[] | undefined,
    options?: TreeOption[]
  ) => {
    if (!values?.length) {
      return values;
    }

    return values.reduce((rs: TreeValue[], item) => {
      const mappedOption = options?.find(({ value }) => value === item.value);

      if (item.subValues?.length) {
        rs.push({
          value: item.value,
          subValues: removeAllEmptySubValues(
            item.subValues,
            mappedOption?.subOptions
          ),
        });
      } else {
        if (!mappedOption?.subOptions?.length) {
          rs.push({
            value: item.value,
          });
        }
      }

      return rs;
    }, []);
  };

  const getValues = (opts?: TreeOption[]): TreeValue[] | undefined =>
    opts?.map(({ value, subOptions }) => ({
      value,
      subValues: getValues(subOptions),
    }));

  const updateValue = (p: {
    items: TreeOption[];
    mode: 'remove' | 'add';
    currentValues?: TreeValue[];
  }) => {
    const { items, mode } = p;
    let { currentValues } = p;

    if (!items.length) {
      return;
    }

    const updatingItem = items[0];

    if (mode === 'add') {
      if (!currentValues?.find((e) => e.value === updatingItem.value)) {
        const newValue: TreeValue = {
          value: updatingItem.value,
          subValues: getValues(
            updatingItem.subOptions?.filter(
              (e) => items[1] == null || e.value === items[1].value
            )
          ),
        };

        if (!currentValues) {
          currentValues = [newValue];
        } else {
          currentValues.push(newValue);
        }
      }
    } else {
      if (items.length === 1) {
        if (currentValues) {
          const deletingIndex = currentValues.findIndex(
            ({ value }) => value === updatingItem.value
          );

          if (deletingIndex > -1) {
            currentValues.splice(deletingIndex, 1);
          }
        }
      }
    }

    updateValue({
      items: items.slice(1),
      currentValues: currentValues?.find((e) => e.value === updatingItem.value)
        ?.subValues,
      mode,
    });
  };

  const renderOptions = (
    targetOptions?: TreeOption[],
    level = 0,
    rootIndex = 0,
    parentOpts: TreeOption[] = []
  ) => {
    if (!targetOptions?.length) {
      return (
        <div className={styles.emptyListOption}>{t('messages.M_008')}</div>
      );
    }

    const allOptionsHaveBeenChecked = targetOptions.every(({ value }) =>
      valueChecked(value, selectedValues, parentOpts)
    );

    return (
      <div>
        {targetOptions.length && level === 0 && (
          <div
            className={styles.optionItem}
            style={{
              paddingLeft: level * 24 + 16,
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <CheckboxField
              title={t('common.button.all')}
              input={{
                checked: allOptionsHaveBeenChecked,
                onChange: () => {
                  if (onChange) {
                    targetOptions.forEach((item) => {
                      updateValue({
                        items: [...parentOpts, item],
                        mode: allOptionsHaveBeenChecked ? 'remove' : 'add',
                        currentValues: selectedValues,
                      });
                    });

                    onChange(removeAllEmptySubValues(selectedValues, options));
                  }
                },
              }}
            />
          </div>
        )}
        {targetOptions.map((opt, i) => {
          const { value, label, subOptions } = opt;
          const key = names[level];

          if (!key) {
            return null;
          }

          const keyId = key + (i + rootIndex);
          rootIndex++;

          const valueHasBeenChecked = valueChecked(
            value,
            selectedValues,
            parentOpts
          );

          return (
            <div key={keyId}>
              <div
                className={styles.optionItem}
                style={{
                  paddingLeft: level * 24 + 16,
                }}
                onClick={(event) => {
                  event.stopPropagation();

                  subOptions?.length && handleClickOption(event, keyId);
                }}
              >
                <CheckboxField
                  title={label}
                  input={{
                    checked: valueHasBeenChecked,
                    onChange: () => {
                      if (onChange) {
                        updateValue({
                          items: [...parentOpts, opt],
                          mode: valueHasBeenChecked ? 'remove' : 'add',
                          currentValues: selectedValues,
                        });

                        onChange(
                          removeAllEmptySubValues(selectedValues, options)
                        );
                      }
                    },
                  }}
                />

                {subOptions && names[level + 1] && (
                  <div
                    className={mergeClasses(styles.dropdownIcon, {
                      [styles.dropdownIconReverse]: openedIds.includes(keyId),
                    })}
                  >
                    <IconDropDown />
                  </div>
                )}
              </div>
              {openedIds.includes(keyId) &&
                renderOptions(subOptions, level + 1, rootIndex, [
                  ...parentOpts,
                  opt,
                ])}
            </div>
          );
        })}
      </div>
    );
  };

  const renderSelectedTags = () => {
    if (!selectedValues?.length) {
      return null;
    }

    return selectedValues.map(({ value }, index) =>
      firstNodeLabelMapper?.[value] ? (
        <Tag
          key={index}
          label={firstNodeLabelMapper?.[value]}
          onRemove={(e) => {
            e.stopPropagation();

            if (onChange) {
              const targetItem = options?.find(
                ({ value: val }) => val === value
              );

              if (targetItem != null) {
                updateValue({
                  items: [targetItem],
                  mode: 'remove',
                  currentValues: selectedValues,
                });

                onChange(selectedValues);
              }
            }
          }}
        />
      ) : null
    );
  };

  return (
    <div
      ref={ref}
      onClick={() => setDropdownOpened(!dropdownOpened)}
      className={mergeClasses(styles.treeSelectWrapper, className)}
    >
      <div className={styles.treeSelectContainer} ref={wrapperRef}>
        {renderSelectedTags()}
        <input
          className={styles.treeSelectInput}
          onChange={({ target: { value } }) => {
            setDropdownOpened(true);
            setSearch(value);
            setOpenedIds([]);
          }}
          value={search}
          placeholder={placeholder || ''}
          onClick={() => {
            handleScrollTagInput();
          }}
        />

        <div className={styles.containerDropdownIcon}>
          <IconDropDown />
        </div>
      </div>

      {dropdownOpened && (
        <div className={styles.treeOptionWrapper}>
          {renderOptions(filteredOptions)}
        </div>
      )}
    </div>
  );
};

export default TreeSelect;
