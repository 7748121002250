// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import styles from './index.module.scss';
import { ResourceManagementItem } from 'util/adminResourceManagementTypes';
import { SelectField } from 'components/molecules/SelectField';
import { InputDataListField } from 'components/molecules/InputDataListField';
import { validateRequiredField, validateSelectField } from 'util/validator';
import { Form } from 'react-final-form';
import { REGEX_OBJECT } from 'util/ConstantValues';
import {
  getDetailProducerSite,
  updateProducerResourceData,
} from 'apis/operator_site/siteApi';
import { ProducerSite } from 'types/purchase';
import { initProducerSite } from 'util/constants/sites';
import { useTranslation } from 'react-i18next';
import { SelectFieldMultipleChoise } from 'components/molecules/SelectFieldMultipleChoise';
import WeightLimitExceededModal from '../WeightLimitExceededModal';

interface Props {
  editData: ResourceManagementItem;
  onClose: () => void;
  onUpdateSuccess: () => void;
}

const EditProducerResourceDataModal: React.FC<Props> = ({
  editData,
  onClose,
  onUpdateSuccess,
}) => {
  const { t } = useTranslation();

  const [inputForm, setInputForm] = useState<ResourceManagementItem>();
  const [producerData, setProducerData] =
    useState<ProducerSite>(initProducerSite);
  const [isShownWeightLimitExceededModal, setIsShownWeightLimitExceededModal] =
    useState(false);
  const [formValues, setFormValues] = useState<ResourceManagementItem | null>(
    null
  );

  useEffect(() => {
    editData &&
      setInputForm({
        ...editData,
        siteName: editData.site.name,
        operatorName: editData.operator.name,
        processingMethod: editData.processingMethod,
        gradeId: editData.grade?.id,
        materialTypeId: String(editData.materialType?.id),
        qualityInspectionMethod: editData.qualityInspectionMethod,
      });
  }, [editData]);

  useEffect(() => {
    getDetailProducerSite(editData.site.id, (data) => {
      if (data) {
        setProducerData(data);
      }
    });
  }, [inputForm]);

  const handleSubmit = (form: ResourceManagementItem) => {
    if (Number(editData?.totalInputWeight) >= Number(form.weight)) {
      updateProducerResourceData({ form }, () => {
        onUpdateSuccess();
      });
    } else {
      setIsShownWeightLimitExceededModal(true);
      setFormValues(form);
    }
  };

  const handleModalSubmit = () => {
    if (formValues) {
      updateProducerResourceData({ form: formValues }, () => {
        onUpdateSuccess();
      });
    }
    setIsShownWeightLimitExceededModal(false);
  };

  return (
    <div className={styles.editResourceDataModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {t('purchase_page.histories.title')}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <div className={styles.createBody}>
            <Form<ResourceManagementItem>
              onSubmit={handleSubmit}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              initialValues={inputForm}
              validate={(values) => {
                return {
                  processingMethod: validateSelectField(
                    t('purchase_page.product_registration.processing_method'),
                    values.processingMethod
                  ),
                  packingStyle: validateSelectField(
                    t('purchase_page.product_registration.packing_style'),
                    values.packingStyle
                  ),
                  qualityInspectionMethod: validateRequiredField(
                    values.qualityInspectionMethod,
                    t(
                      'purchase_page.product_registration.modal_confirm.quality_tests'
                    ) || ''
                  ),
                  materialTypeId: validateSelectField(
                    t('purchase_page.product_registration.material_type'),
                    values.materialTypeId
                  ),
                  productQuantity: validateRequiredField(
                    values.productQuantity,
                    t('purchase_page.product_registration.quality') || ''
                  ),
                  weight: validateRequiredField(
                    values.weight,
                    t('purchase_page.product_registration.weight') || ''
                  ),
                  productName: validateRequiredField(
                    values.productName,
                    t('purchase_page.product_registration.product_name') || ''
                  ),
                  gradeId: validateSelectField(
                    t('purchase_page.product_registration.grade'),
                    values.gradeId
                  ),
                };
              }}
            >
              {(props) => {
                return (
                  <form className={styles.formInput}>
                    <div className={styles.inputFrame}>
                      <InputDataListField
                        label={t('operator_management.operator_name')}
                        placeholder={t('operator_management.operator_name')}
                        name="operatorName"
                        disabled
                      />
                      <InputDataListField
                        label={t('dashboard.site_name')}
                        placeholder={t('dashboard.site_name')}
                        name="siteName"
                        disabled
                      />
                      <InputDataListField
                        label={t(
                          'purchase_page.product_registration.material_name'
                        )}
                        placeholder={t(
                          'purchase_page.product_registration.material_name'
                        )}
                        name="item"
                        disabled
                      />

                      <SelectField
                        label={t(
                          'purchase_page.product_registration.processing_method'
                        )}
                        name="processingMethod"
                        options={producerData.producerMethod.map((i) => {
                          return { label: i, value: i };
                        })}
                        placeholder={t(
                          'purchase_page.product_registration.processing_method'
                        )}
                        showPlaceholderOption
                        required
                      />

                      <SelectField
                        label={t(
                          'purchase_page.product_registration.packing_style'
                        )}
                        name="packingStyle"
                        placeholder={t(
                          'purchase_page.product_registration.packing_style'
                        )}
                        options={producerData.packingStyles.map((i) => {
                          return { label: i, value: i };
                        })}
                        showPlaceholderOption
                        required
                      />

                      <SelectFieldMultipleChoise
                        name="qualityInspectionMethod"
                        label={
                          t(
                            'purchase_page.product_registration.quality_tests'
                          ) || ''
                        }
                        required
                        placeholder={
                          t(
                            'purchase_page.product_registration.quality_tests'
                          ) || ''
                        }
                        selectedOptions={
                          props.values.qualityInspectionMethod || []
                        }
                        options={producerData.qualityInspectionMethod.map(
                          (i) => {
                            return { id: i, name: i };
                          }
                        )}
                        onChange={(items) => {
                          props.form.mutators.setValue(
                            'qualityInspectionMethod',
                            items.map((item) => item.id)
                          );
                        }}
                        checkAllWithCheckBox
                      />

                      <SelectField
                        label={t(
                          'purchase_page.product_registration.material_type'
                        )}
                        name="materialTypeId"
                        value={props.values.materialTypeId}
                        placeholder={t(
                          'purchase_page.product_registration.material_type'
                        )}
                        options={producerData.enrollableType.map((i) => {
                          return { label: i.name, value: i.id };
                        })}
                        showPlaceholderOption
                        required
                      />

                      <InputDataListField
                        label={t('purchase_page.product_registration.quality')}
                        name="productQuantity"
                        placeholder={t(
                          'purchase_page.product_registration.quality'
                        )}
                        type="text"
                        required
                        maxlength={10}
                        validRegex={REGEX_OBJECT.positiveIntegerRegex}
                      />

                      <InputDataListField
                        label={`${t(
                          'purchase_page.product_registration.weight'
                        )} (kg)`}
                        name="weight"
                        placeholder={t(
                          'purchase_page.product_registration.weight'
                        )}
                        type="text"
                        required
                        maxlength={10}
                        subFix={
                          <span className={styles.weightUnit}>
                            投入資源：{editData?.totalInputWeight} Kg
                          </span>
                        }
                        validRegex={REGEX_OBJECT.tenDigitsWith5Decimal}
                        normalizeNumber
                      />

                      <InputDataListField
                        label={t(
                          'purchase_page.product_registration.product_name'
                        )}
                        name="productName"
                        placeholder={t(
                          'purchase_page.product_registration.product_name'
                        )}
                        type="text"
                        required
                        maxlength={50}
                      />

                      <SelectField
                        label={t('purchase_page.product_registration.grade')}
                        name="gradeId"
                        placeholder={t(
                          'purchase_page.product_registration.grade'
                        )}
                        options={producerData.enrollableGrade.map((i) => {
                          return { label: i.name, value: i.id };
                        })}
                        showPlaceholderOption
                        required
                      />
                    </div>
                    <div className={styles.submit}>
                      <button
                        className={styles.submitButton}
                        disabled={!props.valid}
                        type="submit"
                        onClick={props.handleSubmit}
                      >
                        {t('purchase_page.product_registration.edit_btn')}
                      </button>
                    </div>
                  </form>
                );
              }}
            </Form>
          </div>
        </div>
      </Modal>

      <WeightLimitExceededModal
        open={isShownWeightLimitExceededModal}
        onSubmit={handleModalSubmit}
        onClose={() => setIsShownWeightLimitExceededModal(false)}
      />
    </div>
  );
};

export default EditProducerResourceDataModal;
