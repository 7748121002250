// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e?: any) => void;
}
export const IconExport: React.FC<Props> = ({ onClick }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g
        fill="none"
        stroke="#6F7175"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M12 22v-9m0 9l-2.5-2m2.5 2l2.5-2M5.034 9.117A4.002 4.002 0 0 0 6 17h1" />
        <path d="M15.83 7.138a5.5 5.5 0 0 0-10.796 1.98S5.187 10 5.5 10.5" />
        <path d="M17 17a5 5 0 1 0-1.17-9.862L14.5 7.5" />
      </g>
    </svg>
  );
};
