// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import CreateUpdateOperatorInputForm from 'components/molecules/CreateUpdateOperatorInputForm/CreateUpdateOperatorInputForm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalType } from 'util/Enums';
import { BusinessType } from 'util/siteManagementTypes';
import { OperatorCreateEditModalType } from '../AdminOperatorSiteTable/AdminOperatorSiteTable';
import styles from './CreateUpdateOperatorModal.module.scss';

interface Props {
  onClose: () => void;
  onSubmit: () => void;
  createUpdateOperatorModal: OperatorCreateEditModalType;
  businessTypeList?: BusinessType[];
}

const CreateUpdateOperatorModal: React.FC<Props> = ({
  onClose,
  onSubmit,
  createUpdateOperatorModal,
  businessTypeList,
}) => {
  const { t } = useTranslation();

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <div className={styles.createUpdateOperatorModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {createUpdateOperatorModal.type === ModalType.add
                ? t('operator_management.modal_create_operator.title')
                : t('operator_management.modal_update_operator.title')}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <CreateUpdateOperatorInputForm
            businessTypeList={businessTypeList}
            createUpdateOperatorModal={createUpdateOperatorModal}
            type={createUpdateOperatorModal.type}
            onSubmit={handleSubmit}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateUpdateOperatorModal;
