import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { useTranslation } from 'react-i18next';
import styles from './ShippingRegistration.module.scss';
import CompressionBooking from 'components/organisms/CompressionBooking';
import { useFetchCompressionBookingsQuery } from 'apis/compressions_user/compressionUserApi';

const ShippingRegistration = () => {
  const { t } = useTranslation();
  const { data } = useFetchCompressionBookingsQuery();

  return (
    <PrimaryTemplate
      h1={t('compressions.shipping_registration')}
      className={styles.container}
    >
      <div className="scroll-contents">
        <CompressionBooking compressions={data?.bookingDeliveries || []} />
      </div>
    </PrimaryTemplate>
  );
};

export default ShippingRegistration;
