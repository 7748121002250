import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectUser } from 'redux/slices/userSlice';
import { fnLogout } from 'util/commons';
import { labelSliceToDisplay } from 'util/dashboardTypes';
import useOutsideClick from 'util/hooks/useClickOutside';
import IconDown from '../icons/IconDown';
import IcAvatar from '../icons/IconIcAvatar';
import './UserDropdown.scss';

const UserDropdown = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const user = useSelector(selectUser);

  const [isShowDropdown, setIsShowDropdown] = useState(false);

  const { ref } = useOutsideClick({
    onClickOutside: () => {
      setIsShowDropdown(false);
    },
  });

  const handleLogout = () => fnLogout(history);

  return (
    <div ref={ref} className="container">
      <div
        className="dropdown-toggle"
        onClick={() => setIsShowDropdown((isShowDropdown) => !isShowDropdown)}
      >
        <div className="avatar">
          <IcAvatar />
        </div>
        <p title={user.name}>{labelSliceToDisplay(user.name, 20)}</p>
        <IconDown />
      </div>

      {isShowDropdown && (
        <div className="dropdown-menu">
          <div className="dropdown-item" onClick={handleLogout}>
            {t('common.sidebar_logout')}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
