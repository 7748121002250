// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

type IconTrashProps = {
  onClick?: VoidFunction;
};

const IconTrash = ({ onClick }: IconTrashProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className="pointer"
    >
      <path
        d="M6.66602 7.33333V11.3333M9.33268 7.33333V11.3333M2.66602 4.66667H13.3327M12.666 4.66667L12.088 12.7613C12.0641 13.0977 11.9135 13.4125 11.6668 13.6424C11.42 13.8722 11.0953 14 10.758 14H5.24068C4.90344 14 4.57873 13.8722 4.33194 13.6424C4.08515 13.4125 3.93463 13.0977 3.91068 12.7613L3.33268 4.66667H12.666ZM9.99935 4.66667V2.66667C9.99935 2.48986 9.92911 2.32029 9.80409 2.19526C9.67906 2.07024 9.50949 2 9.33268 2H6.66602C6.4892 2 6.31964 2.07024 6.19461 2.19526C6.06959 2.32029 5.99935 2.48986 5.99935 2.66667V4.66667H9.99935Z"
        stroke="#878787"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconTrash;
