// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
const IconHeatMapDescription = () => {
  return (
    <svg
      width="203"
      height="16"
      viewBox="0 0 203 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2"
        y="4"
        width="200"
        height="8"
        fill="url(#paint0_linear_17098_16429)"
      />
      <circle
        cx="8"
        cy="8"
        r="6"
        fill="#4D4D4D"
        stroke="white"
        strokeWidth="4"
      />
      <circle
        cx="195"
        cy="8"
        r="6"
        fill="#4D4D4D"
        stroke="white"
        strokeWidth="4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_17098_16429"
          x1="202"
          y1="4"
          x2="2"
          y2="4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFA337" />
          <stop offset="1" stopColor="#93F070" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconHeatMapDescription;
