import i18n from 'i18n';
import { toast } from 'react-toastify';

export type CommonError = {
  response: {
    status: number;
    data: { message: string };
  };
};

export const handleError = (
  e: unknown,
  disableAutoClose?: boolean,
  statusHandler?: Record<number, () => void>
) => {
  const { response } = e as CommonError;

  if (response) {
    if (statusHandler?.[response.status]) {
      statusHandler[response.status]?.();

      return;
    }

    if (response.status === 500) {
      toast.error(i18n.t('messages.M_013'));

      return;
    }

    if (response.data?.message) {
      toast.error(
        response.data.message,
        disableAutoClose ? { autoClose: false } : {}
      );

      return;
    }
  }

  toast.error(i18n.t('messages.M_013'));
};

/**
 * check rtk mutation error
 * @param result response from rtk mutation
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isError = (result: any) => !!result?.error;
