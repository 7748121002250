// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Modal } from 'components/atoms/Modal';
import { IconCloseTablet } from 'components/atoms/icons/IconCloseTablet';
import React from 'react';
import styles from './PreviewChartModal.module.scss';

interface Props {
  onClose: () => void;
  children?: React.ReactNode;
  title?: string | null;
  description?: React.ReactNode | string | null;
}

const PreviewChartModal: React.FC<Props> = ({
  onClose,
  children,
  title,
  description,
}) => {
  return (
    <div className={styles.tareModal}>
      <Modal>
        <div>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>{title}</p>
            <div className={styles.top} onClick={onClose}>
              <IconCloseTablet />
            </div>
          </div>
          <div className={styles.body}>
            {children}
            {description &&
              (typeof description === 'string' ? (
                <div className={styles.descriptionContainer}>
                  <div className={styles.description}>
                    <div className={styles.text}>{description}</div>
                  </div>
                </div>
              ) : (
                description
              ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PreviewChartModal;
