// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './ScaleEmission.scss';
import { useState, useEffect } from 'react';
import { createAxios } from 'ts/createAxios';
import { TextButton } from 'components/atoms/TextButton';
import { ScaleTemplate } from 'components/templates/ScaleTemplate';
import { PrimaryBtnField } from 'components/molecules/PrimaryBtnField';
import { ScaleEmissionInput } from 'components/organisms/ScaleEmissionInput';
import { ScaleEmissionConfirm } from 'components/organisms/ScaleEmissionConfirm';
import { useHistory } from 'react-router-dom';
import { Modal } from 'components/atoms/Modal';
import { Path } from 'routes';
import { ScaleState, ScaleOperator } from 'util/Types';
import { PrevProps } from 'components/templates/ScaleTemplate';
import { useTranslation } from 'react-i18next';

type EmissionOperator = {
  id: string;
  name: string;
  siteId: string;
};

const ScaleEmission = () => {
  const api = createAxios('login');
  const history = useHistory();
  const [operators, setOperators] = useState<EmissionOperator[]>([]);
  const [operatorInfo, setOperatorInfo] = useState<ScaleOperator>();
  const [isInput, setIsInput] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [weight, setWeight] = useState<string>('');
  const [scale, setScale] = useState<ScaleState>();

  useEffect(() => {
    const operatorId = localStorage.getItem('operatorId');
    const operatorName = localStorage.getItem('operatorName');
    const siteId = localStorage.getItem('siteId');
    const wasteId = localStorage.getItem('wasteId');
    const wasteName = localStorage.getItem('wasteName');

    if (!operatorId || !operatorName || !siteId) {
      history.push(Path.scaleSelectOperator);
      return;
    } else if (!wasteId || !wasteName) {
      history.push(Path.scaleSelectType);
      return;
    } else {
      setScale({
        operatorId: operatorId,
        operatorName: operatorName,
        siteId: siteId,
        wasteId: wasteId,
        wasteName: wasteName,
      });
    }

    callApi();
  }, []);

  const onConfirm = (values) => {
    setWeight(values.weight);
    setIsInput(false);
  };

  const onSubmit = () => {
    api
      .post(
        '/scales/emission',
        {
          operatorId: scale?.operatorId,
          siteId: scale?.siteId,
          type: localStorage.getItem('wasteId'),
          quantity: weight,
        },
        { method: 'post' }
      )
      .then(function () {
        setShowModal(true);
      })
      .catch(function () {
        alert('登録に失敗しました。');
        return;
      });
  };

  const callApi = async () => {
    api.get('/scales/operators').then((res) => {
      setOperators(res.data);
    });
    api
      .get('/scales/operators/' + localStorage.getItem('siteId'))
      .then((res) => {
        setOperatorInfo(res.data);
      });
  };

  const prevProp = (): PrevProps => {
    if (isInput) {
      if (operatorInfo && operatorInfo.waste.length > 1) {
        return {
          href: Path.scaleSelectType,
          onClick: undefined,
        };
      } else {
        return {
          href: Path.scaleSelectOperator,
          onClick: undefined,
        };
      }
    } else {
      return {
        href: undefined,
        onClick: () => {
          setIsInput(true);
        },
      };
    }
  };

  return (
    <ScaleTemplate h1={scale?.operatorName} prev={prevProp()}>
      {isInput ? (
        <ScaleEmissionInput
          wasteId={scale?.wasteId as string}
          wasteName={scale?.wasteName as string}
          onSubmit={onConfirm}
          weight={weight}
        />
      ) : (
        <ScaleEmissionConfirm
          wasteId={scale?.wasteId as string}
          wasteName={scale?.wasteName as string}
          weight={weight}
          onSubmit={onSubmit}
          onPrev={() => {
            setIsInput(true);
          }}
        />
      )}
      <CompleteModal
        isOpen={showModal}
        operatorsLength={operators.length}
        onClose={() => {
          setIsInput(true);
          setWeight('');
          setShowModal(false);
        }}
        onPrev={() => {
          history.push(Path.scaleSelectOperator);
        }}
      />
    </ScaleTemplate>
  );
};

type modalProps = {
  operatorsLength: number;
  isOpen: boolean;
  onClose: () => void;
  onPrev?: () => void;
};
function CompleteModal({
  operatorsLength,
  onClose,
  onPrev,
  isOpen,
}: modalProps) {
  const { t } = useTranslation();
  return (
    <section className={'download-modal ' + (isOpen ? 'open' : 'close')}>
      <Modal isSmall>
        <div className="title">{t('scale_emission.modal_title')}</div>
        <div className="body">{t('scale_emission.modal_body')}</div>
        <div className={'modal-bottom-field'}>
          <div className="bottom-btn-field">
            {operatorsLength > 1 ? (
              <>
                <PrimaryBtnField
                  text={t('scale_emission.modal_button_close')}
                  onClick={onClose}
                />
                <TextButton
                  buttonType="white"
                  type="button"
                  text={t('scale_emission.modal_button_prev')}
                  onClick={onPrev}
                />
              </>
            ) : (
              <PrimaryBtnField text={t('common.return')} onClick={onClose} />
            )}
          </div>
        </div>
      </Modal>
    </section>
  );
}

export default ScaleEmission;
