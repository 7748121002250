// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import styles from './CustomRadio.module.scss';
interface Props {
  inputName: string;
  checked: boolean;
  value: string | number;
  onChange: (value: string | number) => void;
}

const CustomRadio: React.FC<Props> = ({
  children,
  inputName,
  onChange,
  checked,
  value,
}) => {
  const onInputChange = (e) => {
    const { value } = e.target;
    onChange(value);
  };
  return (
    <label className={styles.container}>
      <input
        type="radio"
        value={value}
        name={inputName}
        checked={checked}
        onChange={onInputChange}
      />
      <span className={styles.children}>{children}</span>

      <span className={styles.checkmark}></span>
    </label>
  );
};

export default CustomRadio;
