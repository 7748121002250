// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { getStations } from 'apis/commons';
import { Label } from 'components/atoms/Label';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { REGEX_OBJECT } from 'util/ConstantValues';
import { ModalType } from 'util/Enums';
import { Station } from 'util/Types';
import { mergeClasses, objectFetch } from 'util/commons';
import { EmissionSiteItem } from 'util/siteManagementTypes';
import {
  validateExactLength,
  validateFieldEN,
  validateId,
  validatePostCodeForm,
  validateStringField,
} from 'util/validator';
import { InputDataListField } from '../InputDataListField';
import PostalCodeForm from '../PostalCodeForm';
import styles from './index.module.scss';
import { InputTagField } from '../InputTagField';

interface Props {
  initForm: EmissionSiteItem;
  type: ModalType;
  provinceSuggestion: string[];
  districtSuggestion: string[];
  onNextStep: (createUpdateSiteBaseForm: EmissionSiteItem) => void;
}

const CreateUpdateSiteBaseForm: React.FC<Props> = ({
  initForm,
  type,
  provinceSuggestion,
  districtSuggestion,
  onNextStep,
}) => {
  const { t } = useTranslation();
  const [stationSuggestion, setStationSuggestion] = useState<Station[]>([]);
  const [formHasError, setFormHasError] = useState<boolean>(false);

  useEffect(() => {
    getStations((res) => setStationSuggestion(res));
  }, []);

  return (
    <div className={styles.createBody}>
      <Form<EmissionSiteItem>
        onSubmit={(values: EmissionSiteItem) => {
          const stations = values.station.map((name) => {
            return (
              stationSuggestion.find((it) => it.name === name) || {
                id: null,
                name: name,
              }
            );
          });

          onNextStep({ ...values, inputStations: stations });
        }}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        initialValues={initForm}
        validate={(values) => {
          return {
            id: validateId(t('site_management.modal.field_id'), 20, values.id),
            name: validateStringField(
              t('site_management.modal.field_name'),
              100,
              values.name
            ),
            englishName: validateFieldEN(
              t('site_management.modal.site_name_en'),
              values.englishName,
              100
            ),
            ediSubscriberNumber: validateExactLength({
              value: values.ediSubscriberNumber,
              length: 7,
            }),
            ediConfirmationKey: validateExactLength({
              value: values.ediConfirmationKey,
              length: 8,
            }),
            ...validatePostCodeForm(values),
            address: validateStringField(
              t('site_management.modal.field_address'),
              80,
              objectFetch(values, 'address')
            ),
          };
        }}
      >
        {(props) => {
          return (
            <form className={styles.formInput} autoComplete="off">
              <div className={styles.inputFrame}>
                <InputDataListField
                  label={t('site_management.modal.field_id') || '拠点ID'}
                  placeholder={t('site_management.modal.field_id') || '拠点ID'}
                  name="id"
                  required
                  maxlength={20}
                  disabled={type === ModalType.update ? true : false}
                />
                <InputDataListField
                  label={t('site_management.modal.field_name') || '拠点名'}
                  placeholder={
                    t('site_management.modal.field_name') || '拠点名'
                  }
                  name="name"
                  required
                  maxlength={100}
                />
                <InputDataListField
                  label={t('site_management.modal.site_name_en')}
                  placeholder={t('site_management.modal.site_name_en')}
                  name="englishName"
                  required
                  maxlength={100}
                  validRegex={REGEX_OBJECT.halfWidthCharacter}
                />
                <InputTagField
                  tagValues={props.values.station}
                  label={t('site_management.modal.field_station')}
                  placeholder={t('site_management.modal.field_station') || ''}
                  name="station"
                  dataSuggestions={stationSuggestion.map(({ name }) => {
                    return { name };
                  })}
                  onChangeData={(tags) => {
                    props.form.mutators.setValue('station', tags);
                  }}
                  uniqueTags
                  maxTags={100}
                  maxlength={100}
                  onError={setFormHasError}
                  maxTagError={`ステーションを100個まで設定してください。`}
                />
                <InputDataListField
                  label="EDI加入者番号"
                  placeholder="EDI加入者番号"
                  name="ediSubscriberNumber"
                  maxlength={7}
                  validRegex={REGEX_OBJECT.numberStringOnly}
                />
                <InputDataListField
                  label="EDI確認キー"
                  placeholder="EDI確認キー"
                  maxlength={8}
                  name="ediConfirmationKey"
                  validRegex={REGEX_OBJECT.numberStringOnly}
                />

                <Label
                  className={styles.headingAddress}
                  text={
                    t('site_management.modal.heading_address') || '住所情報'
                  }
                  tag={'div'}
                />

                <PostalCodeForm
                  provinceSuggestions={provinceSuggestion}
                  districtSuggestions={districtSuggestion}
                  formProps={props}
                  mode={type}
                  isVertical
                  addressMaxLength={80}
                />
              </div>
              <div className={styles.submit}>
                <button
                  className={mergeClasses(styles.submitButton, {
                    [styles.disableButton]: props.invalid || formHasError,
                  })}
                  disabled={props.invalid || formHasError}
                  type="button"
                  onClick={props.handleSubmit}
                >
                  {t('site_management.modal.button_next')}
                </button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default CreateUpdateSiteBaseForm;
