// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './RadioField.module.scss';

const RadioField = (props) => {
  return (
    <label className={styles['custom-radio']}>
      <input {...props?.input} type="radio" />
      {props.title}
    </label>
  );
};

export default RadioField;
