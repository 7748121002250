// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import AdminUploadFileCSVModal from 'components/molecules/AdminUploadFileCSVModal';
import DeleteItemModal from 'components/molecules/DeleteItemForm/DeleteItemModal';
import TableList, {
  OperatorCreateEditModalType,
} from 'components/organisms/AdminOperatorSiteTable/AdminOperatorSiteTable';
import CreateUpdateOperatorModal from 'components/organisms/CreateUpdateOperatorModal/CreateUpdateOperatorModal';
import { AdminTemplate } from 'components/templates/AdminTemplate';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { createAxios } from 'ts/createAxios';
import { DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import { AdminManagementType, ModalType, OperatorMenuItem } from 'util/Enums';
import { OperatorType } from 'util/Enums';
import { handleError } from 'util/errorHandler';
import { useQuery } from 'util/queryParams';
import { useHistory } from 'react-router-dom';
import { Path } from 'routes';
import {
  BusinessType,
  OperatorItem,
  ResOperators,
} from 'util/siteManagementTypes';

export const getActive = (type: OperatorType) => {
  switch (type) {
    case OperatorType.Emissions:
      return OperatorMenuItem.Emissions;
    case OperatorType.Collects:
      return OperatorMenuItem.Collects;
    case OperatorType.Compressions:
      return OperatorMenuItem.Compressions;
    case OperatorType.Recycle:
      return OperatorMenuItem.Recycle;
    case OperatorType.Purchase:
      return OperatorMenuItem.Purchase;
    case OperatorType.Producer:
      return OperatorMenuItem.Producer;
  }
};

export const getActiveSite = (type: OperatorType) => {
  switch (type) {
    case OperatorType.Emissions:
      return OperatorMenuItem.Emissions;
    case OperatorType.Collects:
      return '集積';
    case OperatorType.Compressions:
      return OperatorMenuItem.Compressions;
    case OperatorType.Recycle:
      return OperatorMenuItem.Recycle;
    case OperatorType.Purchase:
      return OperatorMenuItem.Purchase;
    case OperatorType.Producer:
      return OperatorMenuItem.Producer;
  }
};

const AdminOperatorListManagement = () => {
  const api = createAxios();
  const commonsApi = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { t } = useTranslation();
  const query = useQuery();
  const history = useHistory();
  const type = query.get('type');
  const [deleteItem, setDeleteItem] = useState<OperatorItem>();
  const [listOfOperators, setListOfOperator] = useState<OperatorItem[]>([]);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [total, setTotal] = useState(0);
  const [businessType, setBusinessType] = useState<BusinessType[]>([]);
  const [createUpdateOperatorModal, setCreateUpdateOperatorModal] =
    useState<OperatorCreateEditModalType>({
      isShow: false,
      type: ModalType.add,
    });
  const [isOpenImportCSV, setOpenImportCSV] = useState(false);

  const fetchOperators = async (
    keyword?: string,
    nextPage?: number,
    forceRefresh?: boolean
  ) => {
    const urlParams = new URLSearchParams(location.search);
    const currentType = urlParams.get('type');
    if (!currentType || !getActive(currentType as OperatorType)) {
      return;
    }
    const params = new URLSearchParams();
    try {
      if (keyword) {
        params.append('keyword', keyword);
      }

      if (nextPage || !keyword) {
        params.append('page', String(nextPage || DEFAULT_PAGE));
        params.append('take', String(PAGE_SIZE));
      }

      const response = await api.get<ResOperators>(
        `/operators?type=${currentType}`,
        {
          params,
        }
      );

      if (keyword) {
        setListOfOperator(response.data.operators);
      } else {
        setListOfOperator(
          forceRefresh
            ? response.data.operators
            : [...listOfOperators, ...response.data.operators]
        );
      }
      nextPage && setCurrentPage(nextPage);
      setTotal(response.data.total);
    } catch (error) {
      handleError(error);
    }
  };

  const fetchBusinessTypes = async () => {
    try {
      const response = await commonsApi.get('/commons/business-types');
      setBusinessType(response.data);
    } catch (error) {
      handleError(error);
    }
  };

  const handleDeleteOperator = (id: string | number | undefined) => {
    api
      .delete(`/operators/${id}`)
      .then(() => {
        fetchOperators('', DEFAULT_PAGE, true);
        toast.success(t('messages.M_012'));
        setDeleteItem(undefined);
      })
      .catch((e) => {
        handleError(e);
      });
  };

  useEffect(() => {
    if (!type || !getActive(type as OperatorType)) {
      history.push(`${Path.adminOperatorListManagement}?type=Emissions`);
    }
  }, [type]);

  useEffect(() => {
    fetchOperators('', DEFAULT_PAGE, true);
    fetchBusinessTypes();
  }, [type]);

  return (
    <AdminTemplate
      h1={t('operator_management.heading')}
      active={getActive(type as OperatorType)}
      breadcrumb={[{ label: getActive(type as OperatorType) || '-' }]}
      className="operator-management-page"
    >
      <TableList
        type={AdminManagementType.operator}
        dataList={listOfOperators}
        totalItems={total}
        currentPage={currentPage}
        updateData={fetchOperators}
        onClickEditOperator={(id) => {
          setCreateUpdateOperatorModal({
            isShow: true,
            type: ModalType.update,
            id: id,
          });
        }}
        onClickAddOperator={() => {
          setCreateUpdateOperatorModal({
            isShow: true,
            type: ModalType.add,
          });
        }}
        onClickDeleteOperator={(item) => {
          setDeleteItem(item);
        }}
        onClickImportOperatorCSV={() => {
          setOpenImportCSV(true);
        }}
      />

      {createUpdateOperatorModal.isShow == true && (
        <CreateUpdateOperatorModal
          businessTypeList={businessType}
          createUpdateOperatorModal={createUpdateOperatorModal}
          onClose={() => {
            setCreateUpdateOperatorModal({
              isShow: false,
              type: ModalType.add,
            });
          }}
          onSubmit={() => {
            setCreateUpdateOperatorModal({
              isShow: false,
              type: ModalType.add,
            });
            fetchOperators('', DEFAULT_PAGE, true);
          }}
        />
      )}
      {deleteItem && (
        <DeleteItemModal
          item={deleteItem}
          onClose={() => {
            setDeleteItem(undefined);
          }}
          onHandleDelete={handleDeleteOperator}
          isOperator
        />
      )}
      {isOpenImportCSV && (
        <AdminUploadFileCSVModal
          onClose={() => {
            setOpenImportCSV(false);
          }}
          onSubmitImportFile={() => {
            setOpenImportCSV(false);
            fetchOperators('', DEFAULT_PAGE, true);
          }}
          endPoint="/operators/import"
        />
      )}
    </AdminTemplate>
  );
};

export default AdminOperatorListManagement;
