// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './ScaleSelectTypeCard.scss';
import { ImagePath } from 'util/ConstantValues';

interface Props {
  id: string;
  name: string;
  onClick: (id: string) => void;
}

export function ScaleSelectTypeCard({ id, name, onClick }: Props) {
  return (
    <div
      className="scale-select-card waste-card"
      onClick={() => {
        onClick(id);
      }}
    >
      <div className="img">
        <img src={ImagePath[id]} />
      </div>
      <div className="name">{name}</div>
    </div>
  );
}
