// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import 'react-calendar/dist/Calendar.css';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { CustomCheckBox } from 'components/atoms/CustomCheckbox';
import { ChangeEvent } from 'react';
import { Resource } from 'types/purchase';
import {
  getOperatorTypeLabel,
  getStatusPurchaseLabel,
  normalizeNumber,
} from 'util/commons';

type Props = {
  allChecked: () => void;
  resources: Resource[];
  checkedItems: string[];
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const ResourceTable: React.FC<Props> = ({
  allChecked,
  resources,
  checkedItems,
  handleChange,
}) => {
  const { t } = useTranslation();

  return (
    <table className={`primary-table ${styles.primaryTable}`}>
      <thead>
        <tr>
          <th id="select" className={styles.checkBox} onClick={allChecked}>
            {t('collect_registration.select_all')}
          </th>
          <th className={styles.type}>
            {t('purchase_page.resource.category_registration')}
          </th>
          <th className={styles.type}>
            {t('purchase_page.resource.resource_status')}
          </th>
          <th className={styles.label}>{t('purchase_page.resource.grade')}</th>
          <th className={styles.content}>{t('purchase_page.resource.id')}</th>
          <th className={styles.weight}>
            {t('purchase_page.resource.weight')}
          </th>
          <th className={styles.label}>
            {t('purchase_page.resource.packing')}
          </th>
        </tr>
      </thead>
      <tbody>
        {resources.map((resource, i) => {
          return (
            <tr key={i}>
              <td className={styles.checkBox}>
                <CustomCheckBox
                  id={resource.id}
                  checked={checkedItems?.includes(resource.id)}
                  onChange={handleChange}
                />
              </td>

              <td className={styles.type}>
                {getOperatorTypeLabel(resource.type)}
              </td>
              <td className={styles.type}>
                {getStatusPurchaseLabel(resource.status)}
              </td>
              <td className={styles.label}>{resource.grade.name}</td>
              <td className={styles.content}>{resource.id}</td>
              <td className={styles.weight}>
                {normalizeNumber({ value: resource.weight })}
              </td>
              <td className={styles.label}>{resource.packingStyle}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export default ResourceTable;
