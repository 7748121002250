// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import styles from './AdminSelectOperatorType.module.scss';
import { CheckBoxCardV2 } from '../CheckBoxCardV2';
import { operatorTypeData } from 'util/ConstantValues';
import { useState } from 'react';
import { OperatorType } from 'util/Enums';

type Props = {
  onClose: () => void;
  onSubmit: (endPoint: string) => void;
};

const AdminSelectOperatorType: React.FC<Props> = ({ onClose, onSubmit }) => {
  const [selected, setSelected] = useState<OperatorType>();
  const getImportDataResourceEndpoint = (type: OperatorType) => {
    switch (type) {
      case OperatorType.Emissions:
        return '/resources/emissions/import';
      case OperatorType.Compressions:
        return '/resources/compressions/import';
      case OperatorType.Recycle:
        return '/resources/recycle/import';
      case OperatorType.Producer:
        return '/resources/products/import';
      default:
        return '';
    }
  };

  return (
    <div className={styles.adminDownloadDataModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>インポート区分を選択</p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <div className={styles.adminOperatorTypeForm}>
            <form className={styles.formInput}>
              <div className={styles.inputFrame}>
                <div className={styles.operatorTypeFrame}>
                  {operatorTypeData.map((operator) => {
                    return (
                      <div
                        className={styles.operatorTypeFrameItem}
                        key={operator.value}
                      >
                        <CheckBoxCardV2
                          id={`${operator.value}`}
                          onChange={(e) => {
                            const id = e.target.id;
                            setSelected(id as OperatorType);
                          }}
                          checked={selected === operator.value}
                        >
                          <span className={styles.operatorTypeFrameItemLabel}>
                            {operator.label}
                          </span>
                        </CheckBoxCardV2>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={styles.submit}>
                <button
                  className={styles.submitButton}
                  disabled={!selected}
                  onClick={() => {
                    selected &&
                      onSubmit(getImportDataResourceEndpoint(selected));
                  }}
                  type="button"
                >
                  インポートファイル選択へ
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AdminSelectOperatorType;
