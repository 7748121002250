// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import { Form } from 'react-final-form';
import styles from './CreateUpdateSettingItemSite.module.scss';
import { InputDataListField } from 'components/molecules/InputDataListField';
import { REGEX_OBJECT } from 'util/ConstantValues';
import { ModalType } from 'util/Enums';
import { useTranslation } from 'react-i18next';
import { TenantCreateEditModalType } from '../AdminTenantManagement';
import { TenantForm } from 'util/Types';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

interface Props {
  initValues: TenantForm;
  createUpdateModal: TenantCreateEditModalType;
  onPrevStep: (tenantForm: TenantForm) => void;
  onSubmit: (tenantForm: TenantForm, tenantId?: number) => void;
}

const CreateUpdateSettingItemSite: React.FC<Props> = ({
  initValues,
  createUpdateModal,
  onPrevStep,
  onSubmit,
}) => {
  const { tenantEditing } = createUpdateModal;
  const { t } = useTranslation();
  return (
    <div className={styles.createBody}>
      <Form<TenantForm>
        initialValues={initValues}
        onSubmit={(values) => onSubmit(values, tenantEditing?.id)}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
          ...arrayMutators,
        }}
      >
        {({ handleSubmit, invalid, values }) => (
          <form className={styles.formInput}>
            {initValues.inputTenantItems.length ? (
              <FieldArray name="inputTenantItems">
                {({ fields }) =>
                  fields.map((name, index) => (
                    <div className={styles.inputFrame} key={index}>
                      <InputDataListField
                        disabled
                        label={index === 0 ? t('tenant.setting_item.item') : ''}
                        placeholder={t('tenant.setting_item.item')}
                        name={`${name}.name`}
                      />

                      <InputDataListField
                        label={
                          index === 0 ? t('tenant.setting_item.price') : ''
                        }
                        placeholder={t('tenant.setting_item.price')}
                        name={`${name}.price`}
                        validRegex={REGEX_OBJECT.number}
                        maxlength={9}
                        subFix={<span className={styles.price}>円</span>}
                      />
                    </div>
                  ))
                }
              </FieldArray>
            ) : (
              <div className={styles.noData}>
                {t('tenant.setting_item.no_data')}
              </div>
            )}

            <div className={styles.submit}>
              <button
                className={`${styles.prevButton}`}
                onClick={() => onPrevStep(values)}
                type="button"
              >
                {t('common.cancel')}
              </button>

              <button
                disabled={invalid}
                className={invalid ? styles.disableButton : styles.submitButton}
                onClick={handleSubmit}
                type="button"
              >
                {createUpdateModal.type === ModalType.add
                  ? t('common.modal_create.add_button')
                  : t('common.edit')}
              </button>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

export default CreateUpdateSettingItemSite;
