// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { WasteUnitItem } from 'pages/AdminCustomizeItemsDetail';
import { FetchTenantsRes } from 'pages/SelectTenant';
import { stringify } from 'querystring';
import { createAxios } from 'ts/createAxios';
import { GetListParams } from 'types/api';
import { ResourceManagementItem } from 'util/adminResourceManagementTypes';
import {
  getApiPath,
  normalizeNumberString,
  trimStringArray,
  trimStringData,
} from 'util/commons';
import { API_ENDPOINTS } from 'util/endPoints';
import { OperatorType, TenantAuthen } from 'util/Enums';
import { handleError } from 'util/errorHandler';
import {
  CollectSite,
  CollectSiteInput,
  CompressionSiteItem,
  EmissionSiteItem,
  GradeCommon,
  MaterialType,
  PostalCodeData,
  ProducerSiteItem,
  ProducerSiteItemResponse,
  PurchaseSiteItem,
  PurchaseSiteItemResponse,
  RecycleMachine,
  RecycleSiteItemRequestCreate,
  RecycleSiteItemRequestUpdate,
  SiteAddressData,
  SiteTypeSuggestionData,
  VehicleTypeSuggestionData,
} from 'util/siteManagementTypes';
import {
  CommonSite,
  EmissionsSiteStation,
  ResTruck,
  Station,
} from 'util/Types';

export const fetchProvinceDistrictWithPostalCode = async (
  postalCode: string,
  callback?: (data?: PostalCodeData) => void
) => {
  const api = createAxios(
    undefined,
    undefined,
    true,
    process.env.REACT_APP_API_COMMONS_URL
  );

  try {
    const { data } = await api.get<PostalCodeData>(
      `/commons/provinces-districts/${postalCode}`
    );
    callback && callback(data);
  } catch {
    callback && callback(undefined);
  }
};

export const fetchTudetWithAddress = async (
  address: string,
  callback?: (data?: SiteAddressData) => void,
  hideLoading = true
) => {
  const api = createAxios(
    undefined,
    undefined,
    hideLoading,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const addressString = stringify({ address });
  try {
    const { data } = await api.get<SiteAddressData>(
      `/commons/coordinates?${addressString}`
    );
    callback?.({
      latitude: data?.latitude?.toString(),
      longitude: data?.longitude?.toString(),
    });
  } catch {
    callback && callback(undefined);
  }
};

export const fetchProvinceDistrictSuggestion = async (
  isEmissionsType: boolean,
  callback?: (data?: PostalCodeData[]) => void
) => {
  const api = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const isEmissionsTypeString = stringify({ isEmissionsType });
  const { data } = await api.get<PostalCodeData[]>(
    `/commons/provinces-districts?${isEmissionsTypeString}`
  );
  callback && callback(data);
};

export const fetchSiteTypeSuggestion = async (
  callback?: (data?: SiteTypeSuggestionData) => void
) => {
  const api = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { data } = await api.get<SiteTypeSuggestionData>(`/commons/site-types`);
  callback && callback(data);
};

export const fetchVehicleTypeSuggestion = async (
  callback?: (data?: VehicleTypeSuggestionData) => void
) => {
  const api = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { data } = await api.get<VehicleTypeSuggestionData>(
    `/commons/vehicle-types`
  );
  callback && callback(data);
};

export type CreateEmissionSiteParams = {
  operatorId: string;
  body: EmissionSiteItem;
};

export type ParamsFetchGradeSite = {
  isEmissionsType: boolean;
};

export const createEmissionSite = async (
  params: CreateEmissionSiteParams,
  callback?: (data: { data: string }) => void
) => {
  const api = createAxios();
  try {
    const data = await api.post(
      `/sites/emissions?operatorId=${params.operatorId}`,
      {
        id: params.body.id.trim(),
        name: params.body.name.trim(),
        englishName: params.body.englishName.trim(),
        ediSubscriberNumber: params.body.ediSubscriberNumber?.trim() || '',
        ediConfirmationKey: params.body.ediConfirmationKey?.trim() || '',
        postalCode: params.body.postalCode?.trim(),
        workingDay: params.body.workingDay,
        province: params.body.province.trim(),
        district: params.body.district.trim(),
        address: params.body.address.trim(),
        latitude: params.body.latitude.trim(),
        longitude: params.body.longitude.trim(),
        siteType: params.body.siteType.trim(),
        floorSpace: params.body.floorSpace.trim(),
        grade: params.body.grade.trim(),
        startTime: params.body.startTime,
        endTime: params.body.endTime,
        startTime2: params.body.startTime2,
        endTime2: params.body.endTime2,
        vehicleLimit: params.body.vehicleLimit.trim(),
        networkOperator: params.body.networkOperator.trim(),
        emails: params.body.emails
          .filter((email) => email.value.trim())
          .map((email) => email.value.trim()),
        images: params.body.images
          .filter((item) => item.file)
          .map((image) => image.file.trim()),
        staff: params.body.staff.trim(),
        staffTel: params.body.staffTel.trim(),
        staffEmail: params.body.staffEmail.trim(),
        billingAddress: params.body.billingAddress.trim(),
        dueDate: +params.body.dueDate,
        paymentDate: +params.body.paymentDate,
        invoiceDate: +params.body.invoiceDate,
        systemUsageFee: params.body.systemUsageFee.trim(),
        originalInvoice: params.body.originalInvoice,
        tenantAuthen: params.body.tenantAuthen,
        createDefaultWasteUnit: params.body.createDefaultWasteUnit,
        dataSyncTime: Number(params.body.dataSyncTime),
        inputStations: params.body.inputStations,
      }
    );
    data && callback && callback(data);
  } catch (error) {
    handleError(error);
  }
};

export type EmissionSiteItemResponse = {
  englishName: string;
  ediConfirmationKey?: string;
  ediSubscriberNumber?: string;
  postalCode: string;
  address: string;
  latitude: string;
  longitude: string;
  siteType: string;
  floorSpace: string;
  grades: { id: number; name: string }[];
  vehicleLimit: string;
  networkOperator: string;
  imageUrls: string[];
  workingDay: number[];
  startTime: string | null;
  endTime: string | null;
  startTime2: string | null;
  endTime2: string | null;
  staff: string;
  staffTel: string;
  staffEmail: string;
  emails: string[];
  billingAddress: string;
  dueDate: string | null;
  invoiceDate: string | null;
  paymentDate: string | null;
  systemUsageFee: string;
  dataSyncTime: number | null;
  originalInvoice: boolean;
  id: string;
  name: string;
  type: string;
  siteAddresses: { id: number; province: string; district: string }[];
  tenantAuthen: TenantAuthen;
  siteStations: Station[];
};
export const getDetailSite = async (
  id: string,
  callback?: (data?: EmissionSiteItemResponse) => void
) => {
  const api = createAxios(undefined, undefined, false);

  const { data } = await api.get<EmissionSiteItemResponse>(`/sites/${id}`);
  callback && callback(data);
};

export type CompressionSiteItemResponse = {
  id: string;
  address: string;
  compressionMethod: string[];
  imageUrls: string[];
  machineType: string[];
  name: string;
  packingStyles: string[];
  siteAddresses: { province: string; district: string; id: number }[];
  acceptanceMaterial: string[];
  acceptanceGrade: string[];
  enrollableGrade: { id: number; name: string }[];
  staffTel: string;
  startTime: string;
  type: OperatorType;
  isEdiEnabled: boolean;
  ediSubscriberNumber: string;
  ediOfficeNumber: string;
  wastePermits: string[];
  wastePermitUrl: string;
  prevYearScope1: string;
  prevYearScope2: string;
  prevYearInputAmount: string;
  enrollableType: { id: number; name: string }[];
  postalCode: string;
  latitude: string;
  longitude: string;
  siteStations: Station[];
};
export const getDetailCompressionSite = async (
  id: string,
  callback?: (data?: CompressionSiteItemResponse) => void
) => {
  const api = createAxios(undefined, undefined, false);

  const { data } = await api.get<CompressionSiteItemResponse>(`/sites/${id}`);
  callback && callback(data);
};

export type UpdateEmissionSiteParams = {
  form: EmissionSiteItem;
};

export const updateEmissionSite = async (
  params: UpdateEmissionSiteParams,
  callback?: (data: { data: string }) => void
) => {
  const api = createAxios();
  try {
    const data = await api.put(`/sites/emissions/${params.form.id}`, {
      imagesDelete: params.form?.imagesDelete || [],
      name: params.form.name.trim(),
      englishName: params.form.englishName.trim(),
      ediSubscriberNumber: params.form.ediSubscriberNumber?.trim() || '',
      ediConfirmationKey: params.form.ediConfirmationKey?.trim() || '',
      postalCode: params.form.postalCode?.trim(),
      workingDay: params.form.workingDay,
      province: params.form.province.trim(),
      district: params.form.district.trim(),
      address: params.form.address.trim(),
      latitude: trimStringData(params.form.latitude),
      longitude: trimStringData(params.form.longitude),
      siteType: params.form.siteType.trim(),
      floorSpace: params.form.floorSpace && params.form.floorSpace.trim(),
      grade: params.form.grade.trim(),
      startTime: params.form.startTime,
      endTime: params.form.endTime,
      startTime2: params.form.startTime2,
      endTime2: params.form.endTime2,
      vehicleLimit: trimStringData(params.form.vehicleLimit),
      networkOperator: trimStringData(params.form.networkOperator),
      emails: params.form.emails
        .filter((email) => email.value.trim())
        .map((email) => email.value.trim()),
      images: params.form?.imagesAdded
        ? params.form.imagesAdded
            .filter((item: { name: string; file: string }) => item.file)
            .map((image: { name: string; file: string }) => image.file.trim())
        : [],
      staff: params.form.staff.trim(),
      staffTel: params.form.staffTel.trim(),
      staffEmail: params.form.staffEmail.trim(),
      billingAddress: params.form.billingAddress.trim(),
      dueDate: +params.form.dueDate,
      paymentDate: +params.form.paymentDate,
      invoiceDate: +params.form.invoiceDate,
      systemUsageFee: params.form.systemUsageFee.trim(),
      originalInvoice: params.form.originalInvoice,
      tenantAuthen: params.form.tenantAuthen,
      dataSyncTime: Number(params.form.dataSyncTime),
      inputStations: params.form.inputStations,
    });
    data && callback && callback(data);
  } catch (error) {
    handleError(error);
  }
};

export type CreateCompressionSiteParams = {
  operatorId: string;
  body: CompressionSiteItem;
};
export const createCompressionSite = async (
  params: CreateCompressionSiteParams,
  callback?: (data: { data: string }) => void
) => {
  const api = createAxios();
  try {
    const data = await api.post(
      `/sites/compressions?operatorId=${params.operatorId}`,
      {
        id: params.body.id.trim(),
        name: params.body.name.trim(),
        englishName: params.body.englishName.trim(),
        address: params.body.address.trim(),
        province: params.body.province.trim(),
        district: params.body.district.trim(),
        compressionMethod: params.body.compressionMethod.map((item) =>
          item.trim()
        ),
        machineType: params.body.machineType.map((item) => item.trim()),
        acceptanceMaterial: params.body.acceptanceMaterial.map((item) =>
          item.trim()
        ),
        acceptanceGrade: params.body.acceptanceGrade.map((item) => item.trim()),
        enrollableGrade: params.body.enrollableGrade.map((item) => item.trim()),
        packingStyles: params.body.packingStyles.map((item) => item.trim()),
        staffTel: params.body.staffTel.trim(),
        images: params.body.images
          .filter((item) => item.file)
          .map((image) => image.file.trim()),
        enrollableType: params.body.enrollableType.map((item) => item.trim()),
        wastePermit: params.body.wastePermit,
        prevYearScope1: normalizeNumberString(params.body.prevYearScope1),
        prevYearScope2: normalizeNumberString(params.body.prevYearScope2),
        prevYearInputAmount: normalizeNumberString(
          params.body.prevYearInputAmount
        ),
        postalCode: params.body.postalCode?.trim(),
        latitude: params.body.latitude.trim(),
        longitude: params.body.longitude.trim(),
        ...(params.body.startTime && { startTime: params.body.startTime }),
        ...(params.body.endTime && { endTime: params.body.endTime }),
        ediConfirmationKey: params.body.ediConfirmationKey?.trim() || '',
        ediSubscriberNumber: params.body.ediSubscriberNumber?.trim() || '',
        ediOfficeNumber: params.body.ediOfficeNumber?.trim() || '',
      }
    );

    data && callback && callback(data);
  } catch (error) {
    handleError(error);
  }
};

export type UpdateCompressionSiteParams = {
  form: CompressionSiteItem;
};
export const updateCompressionSite = async (
  params: UpdateCompressionSiteParams,
  callback?: (data: { data: string }) => void
) => {
  const api = createAxios();
  try {
    const data = await api.put(`/sites/compressions/${params.form.id}`, {
      name: params.form.name.trim(),
      englishName: params.form.englishName.trim(),
      address: params.form.address ? params.form.address.trim() : '',
      province: params.form.province ? params.form.province.trim() : '',
      district: params.form.district ? params.form.district.trim() : '',
      machineType: params.form.machineType?.map((item) => item.trim()) || [],
      images: params.form?.imagesAdded
        ? params.form.imagesAdded
            .filter((item: { name: string; file: string }) => item.file)
            .map((image: { name: string; file: string }) => image.file.trim())
        : [],
      imagesDelete: params.form?.imagesDelete || [],
      acceptanceMaterial: params.form.acceptanceMaterial.map((item) =>
        item.trim()
      ),
      acceptanceGrade: params.form.acceptanceGrade.map((item) => item.trim()),
      enrollableGrade: params.form.enrollableGrade.map((item) => ({
        id:
          params.form.enrollableGradeDefault?.find((d) => d.name === item)
            ?.id || null,
        name: item,
      })),
      staffTel: params.form.staffTel?.trim() || '',
      packingStyles: params.form.packingStyles.map((item) => item.trim()),
      compressionMethod:
        params.form.compressionMethod?.map((item) => item.trim()) || [],
      enrollableType: params.form.enrollableType.map((item) => ({
        id:
          params.form.enrollableTypeDefault?.find((d) => d.name === item)?.id ||
          null,
        name: item,
      })),
      ...(params.form.wastePermit !== params.form.wastePermitDefault && {
        wastePermit: params.form.wastePermit,
      }),
      ...(params.form.wastePermitDefault &&
        !params.form.wastePermit && { wastePermitDelete: true }),
      prevYearScope1: normalizeNumberString(params.form.prevYearScope1),
      prevYearScope2: normalizeNumberString(params.form.prevYearScope2),
      prevYearInputAmount: normalizeNumberString(
        params.form.prevYearInputAmount
      ),
      postalCode: params.form.postalCode?.trim(),
      latitude: trimStringData(params.form.latitude),
      longitude: trimStringData(params.form.longitude),
      ...(params.form.startTime && { startTime: params.form.startTime }),
      ...(params.form.endTime && { endTime: params.form.endTime }),
      ediConfirmationKey: params.form.ediConfirmationKey?.trim() || '',
      ediSubscriberNumber: params.form.ediSubscriberNumber?.trim() || '',
      ediOfficeNumber: params.form.ediOfficeNumber?.trim() || '',
    });

    data && callback && callback(data);
  } catch (error) {
    handleError(error);
  }
};

export interface EmissionFactor {
  id: number;
  wasteTypeMaster: {
    id: number;
    name: string;
  };
  recycleMethod: {
    id: number;
    name: string;
  };
  name: string;
}

export const fetchEmissionFactors = async (
  callback?: (data?: EmissionFactor[]) => void
) => {
  const api = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { data } = await api.get<EmissionFactor[]>('/commons/emission-factors');
  callback && callback(data);
};

export interface WasteTypeMaster {
  id: number;
  name: string;
  englishName: string;
}
export const fetchWasteTypeMaster = async (
  callback?: (data?: WasteTypeMaster[]) => void
) => {
  const api = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { data } = await api.get<WasteTypeMaster[]>('/commons/waste-types');
  callback && callback(data);
};

export const fetchWasteUnit = async (
  id: number,
  callback?: (data?: WasteUnitItem) => void
) => {
  const api = createAxios();
  const { data } = await api.get<WasteUnitItem>(`/waste-units/${id}`);
  callback && callback(data);
};

export const fetchRecyleMachineSuggestion = async (
  callback?: (data?: RecycleMachine[]) => void
) => {
  const api = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { data } = await api.get<RecycleMachine[]>('/commons/recycle-machines');
  callback && callback(data);
};

export type CreateRecycleSite = {
  operatorId: string;
  body: RecycleSiteItemRequestCreate;
};

export type UpdateRecycleSite = {
  siteId: string;
  body: RecycleSiteItemRequestUpdate;
};

export const createSiteRecycle = async (
  params: CreateRecycleSite,
  callback?: (data: { data: string }) => void
) => {
  const api = createAxios();
  try {
    const data = await api.post(
      `/sites/recycle?operatorId=${params.operatorId}`,
      {
        id: params.body.id.trim(),
        name: params.body.name.trim(),
        acceptanceGrade: params.body.acceptanceGrade || [],
        enrollableType: params.body.enrollableType || [],
        enrollableGrade: params.body.enrollableGrade || [],
        recycleMethod: Number(params.body.recycleMethod) || 1,
        recycleMachine: params.body.recycleMachine.trim() || '',
        wastePermits: params.body.wastePermits || [],
        province: params.body.province.trim() || '',
        district: params.body.district.trim() || '',
        address: params.body.address.trim() || '',
      }
    );
    data && callback && callback(data);
  } catch (error) {
    handleError(error);
  }
};

export const updateSiteRecycle = async (
  params: UpdateRecycleSite,
  callback?: (data: { data: string }) => void
) => {
  const api = createAxios();
  try {
    const data = await api.put(`/sites/recycle/${params.siteId}`, {
      name: params.body.name,
      acceptanceGrade: params.body.acceptanceGrade || [],
      enrollableType: params.body.enrollableType || [],
      enrollableGrade: params.body.enrollableGrade || [],
      recycleMethod: Number(params.body.recycleMethod) || null,
      recycleMachine: params.body.recycleMachine?.trim() || '',
      wastePermits: params.body.wastePermits || [],
      province: params.body.province?.trim() || '',
      district: params.body.district?.trim() || '',
      address: params.body.address?.trim() || '',
    });
    data && callback && callback(data);
  } catch (error) {
    handleError(error);
  }
};

export const fetchMaterialTypeNames = async (
  callback?: (data?: MaterialType[]) => void
) => {
  const api = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { data } = await api.get<MaterialType[]>(
    '/commons/material-types/name'
  );
  callback && callback(data);
};

export const fetchGrades = async (
  callback?: (data?: GradeCommon[]) => void
) => {
  const api = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { data } = await api.get<GradeCommon[]>('/commons/grades/name');
  callback && callback(data);
};

export const fetchAcceptanceGrades = async (
  callback?: (data?: { acceptanceGrade: string }[]) => void
) => {
  const api = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { data } = await api.get<{ acceptanceGrade: string }[]>(
    '/commons/acceptance-grades'
  );
  callback && callback(data);
};

export const fetchAcceptanceMaterials = async (
  callback?: (data?: { acceptanceMaterial: string }[]) => void
) => {
  const api = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { data } = await api.get<{ acceptanceMaterial: string }[]>(
    '/commons/acceptance-materials'
  );
  callback && callback(data);
};

export const fetchMaterial = async (
  callback?: (data?: { materialName: string }[]) => void
) => {
  const api = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { data } = await api.get<{ materialName: string }[]>(
    '/commons/material-names?isRecycleChain=true'
  );
  callback && callback(data);
};

export const createCollectSite = async (
  params: { operatorId: string; form: CollectSiteInput },
  successCallback?: (data: { data: string }) => void
) => {
  const api = createAxios();
  try {
    const data = await api.post(
      getApiPath(API_ENDPOINTS.SITES_COLLECTS, {
        id: params.operatorId,
      }),
      {
        id: params.form.id.trim(),
        name: params.form.name.trim(),
        siteAddresses: params.form.siteAddresses.map((i) => i.name.trim()),
        acceptanceMaterial: trimStringArray(params.form.acceptanceMaterials),
        acceptanceGrade: trimStringArray(params.form.acceptanceGrades),
        emails: params.form.emails
          .map((i) => i.email)
          .filter((i) => i && i.length > 0),
        ...(params.form.vehicleType &&
          params.form.vehicleType.trim() && {
            vehicleType: params.form.vehicleType.trim(),
          }),
        ...(params.form.startTime && { startTime: params.form.startTime }),
        ...(params.form.endTime && { endTime: params.form.endTime }),
        ...(params.form.noticeTime && { noticeTime: params.form.noticeTime }),
        ediConfirmationKey: params.form.ediConfirmationKey?.trim() || '',
        ediSubscriberNumber: params.form.ediSubscriberNumber?.trim() || '',
        ...(params.form.truckNumbers?.length && {
          truckNumbers: trimStringArray(params.form.truckNumbers),
        }),
      }
    );
    data && successCallback && successCallback(data);
  } catch (error) {
    handleError(error);
  }
};

export const getDetailPurchaseSite = async (
  id: string,
  successCallback?: (data: PurchaseSiteItemResponse) => void
) => {
  const api = createAxios();
  const { data } = await api.get<PurchaseSiteItemResponse>(
    getApiPath(API_ENDPOINTS.SITES, { id: id })
  );
  successCallback && successCallback(data);
};

export const createPurchaseSite = async (
  params: { operatorId: string; body: PurchaseSiteItem },
  successCallback?: (data: { data: string }) => void
) => {
  const api = createAxios();
  try {
    const data = await api.post(
      getApiPath(API_ENDPOINTS.SITES_PURCHASES, {
        id: params.operatorId,
      }),
      {
        ...params.body,
        id: params.body.id.trim(),
        name: params.body.name.trim(),
        province: params.body.province.trim(),
        district: params.body.district.trim(),
        costDisplayUnit: params.body.costDisplayUnit
          ? params.body.costDisplayUnit
          : '',
        procurementUnitPrice: normalizeNumberString(
          params.body.procurementUnitPrice
        ),
        carbonPrice: normalizeNumberString(params.body.carbonPrice),
        prevYearScope1: normalizeNumberString(params.body.prevYearScope1),
        prevYearScope2: normalizeNumberString(params.body.prevYearScope2),
        prevYearInputAmount: normalizeNumberString(
          params.body.prevYearInputAmount
        ),
      }
    );
    data && successCallback && successCallback(data);
  } catch (error) {
    handleError(error);
  }
};

export const updateCollectSite = async (
  params: {
    form: CollectSiteInput;
    collectSite: CollectSite;
  },
  successCallback?: (data: { data: string }) => void
) => {
  const api = createAxios();
  try {
    const oldSiteAddresses =
      params.collectSite?.siteAddresses.filter(
        (i) =>
          i.province ===
          params.form.siteAddresses.find((p) => p.name === i.province)?.name
      ) || [];

    const newSiteAddress = params.form.siteAddresses.reduce(
      (current: { id: null; province: string }[], item) => {
        const target =
          item.name !==
          params.collectSite?.siteAddresses.find(
            (old) => old.province === item.name
          )?.province;

        if (!target) return current;

        return current.concat({
          id: null,
          province: item.name,
        });
      },
      []
    );

    const data = await api.put(
      getApiPath(API_ENDPOINTS.SITES_COLLECTS_UPDATE, {
        id: params.form.id,
      }),
      {
        name: params.form.name.trim(),
        siteAddresses: [...oldSiteAddresses, ...newSiteAddress],
        emails: params.form.emails
          .map((i) => i.email)
          .filter((i) => i && i.length > 0),
        acceptanceMaterial: trimStringArray(params.form.acceptanceMaterials),
        acceptanceGrade: trimStringArray(params.form.acceptanceGrades),
        ...(params.form.vehicleType &&
          params.form.vehicleType.trim() && {
            vehicleType: params.form.vehicleType.trim(),
          }),
        ...(params.form.startTime && { startTime: params.form.startTime }),
        ...(params.form.endTime && { endTime: params.form.endTime }),
        ...(params.form.noticeTime && { noticeTime: params.form.noticeTime }),
        ediConfirmationKey: params.form.ediConfirmationKey?.trim() || '',
        ediSubscriberNumber: params.form.ediSubscriberNumber?.trim() || '',
        ...(params.form.truckNumbers?.length && {
          truckNumbers: trimStringArray(params.form.truckNumbers),
        }),
      }
    );
    data && successCallback && successCallback(data);
  } catch (error) {
    handleError(error);
  }
};

export const updatePurchaseSite = async (
  params: { form: PurchaseSiteItem },
  successCallback?: (data: { data: string }) => void
) => {
  const api = createAxios();
  try {
    const data = await api.put(
      getApiPath(API_ENDPOINTS.SITES_PURCHASES_UPDATE, {
        id: params.form.id,
      }),
      {
        ...params.form,
        name: params.form.name.trim(),
        province: params.form.province.trim(),
        district: params.form.district.trim(),
        costDisplayUnit: params.form.costDisplayUnit
          ? params.form.costDisplayUnit
          : '',
        procurementUnitPrice: normalizeNumberString(
          params.form.procurementUnitPrice
        ),
        carbonPrice: normalizeNumberString(params.form.carbonPrice),
        prevYearScope1: normalizeNumberString(params.form.prevYearScope1),
        prevYearScope2: normalizeNumberString(params.form.prevYearScope2),
        prevYearInputAmount: normalizeNumberString(
          params.form.prevYearInputAmount
        ),
      }
    );
    data && successCallback && successCallback(data);
  } catch (error) {
    handleError(error);
  }
};

export const fetchTruckOption = async (
  siteId?: string,
  callback?: (data?: ResTruck) => void
) => {
  if (siteId) {
    const api = createAxios();
    const { data } = await api.get<ResTruck>(API_ENDPOINTS.TRUCKS, {
      params: { siteId },
    });

    callback?.(data);
  }
};

export const getDetailProducerSite = async (
  id: string,
  successCallback?: (data?: ProducerSiteItemResponse) => void
) => {
  const api = createAxios();
  const { data } = await api.get<ProducerSiteItemResponse>(
    getApiPath(API_ENDPOINTS.SITES, { id: id })
  );

  successCallback?.(data);
};

export const createProduceSite = async (
  params: { operatorId: string; form: ProducerSiteItem },
  successCallback?: (data: { data: string }) => void
) => {
  const api = createAxios();
  try {
    const data = await api.post(
      API_ENDPOINTS.SITES_PRODUCERS,
      {
        id: params.form.id.trim(),
        name: params.form.name.trim(),
        englishName: params.form.englishName.trim(),
        province: params.form.province.trim(),
        district: params.form.district.trim(),
        producerMethod: trimStringArray(params.form.producerMethod),
        machineType: trimStringArray(params.form.machineType),
        acceptanceMaterial: trimStringArray(params.form.acceptanceMaterial),
        acceptanceGrade: trimStringArray(params.form.acceptanceGrade),
        enrollableType: params.form.enrollableType.map((item) => item.trim()),
        enrollableGrade: params.form.enrollableGrade.map((item) => item.trim()),
        packingStyles: trimStringArray(params.form.packingStyles),
        qualityInspectionMethod: trimStringArray(
          params.form.qualityInspectionMethod
        ),
        staffTel: params.form.staffTel.trim(),
        prevYearScope1: normalizeNumberString(params.form.prevYearScope1),
        prevYearScope2: normalizeNumberString(params.form.prevYearScope2),
        prevYearInputAmount: normalizeNumberString(
          params.form.prevYearInputAmount
        ),
        postalCode: params.form.postalCode?.trim(),
        address: params.form.address.trim(),
        latitude: params.form.latitude.trim(),
        longitude: params.form.longitude.trim(),
      },
      {
        params: { operatorId: params.operatorId },
      }
    );

    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const updateProducerSite = async (
  params: { form: ProducerSiteItem },
  successCallback?: (data: { data: string }) => void
) => {
  const api = createAxios();
  try {
    const data = await api.put(
      getApiPath(API_ENDPOINTS.SITES_PRODUCERS_UPDATE, {
        id: params.form.id,
      }),
      {
        name: params.form.name.trim(),
        englishName: params.form.englishName.trim(),
        province: params.form.province.trim(),
        district: params.form.district.trim(),
        producerMethod: trimStringArray(params.form.producerMethod),
        machineType: trimStringArray(params.form.machineType),
        acceptanceMaterial: trimStringArray(params.form.acceptanceMaterial),
        acceptanceGrade: trimStringArray(params.form.acceptanceGrade),
        enrollableType: params.form.enrollableType.map((item) => ({
          id:
            params.form.enrollableTypeDefault?.find((d) => d.name === item)
              ?.id ?? null,
          name: item,
        })),
        enrollableGrade: params.form.enrollableGrade.map((item) => ({
          id:
            params.form.enrollableGradeDefault?.find((d) => d.name === item)
              ?.id ?? null,
          name: item,
        })),
        packingStyles: trimStringArray(params.form.packingStyles),
        qualityInspectionMethod: trimStringArray(
          params.form.qualityInspectionMethod
        ),
        staffTel: params.form.staffTel ? params.form.staffTel.trim() : '',
        prevYearScope1: normalizeNumberString(params.form.prevYearScope1),
        prevYearScope2: normalizeNumberString(params.form.prevYearScope2),
        prevYearInputAmount: normalizeNumberString(
          params.form.prevYearInputAmount
        ),
        postalCode: params.form.postalCode?.trim(),
        address: params.form.address.trim(),
        latitude: trimStringData(params.form.latitude),
        longitude: trimStringData(params.form.longitude),
      }
    );
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const getOperatorSite = async (
  type: string,
  successCallback?: (operators: CommonSite[]) => void
) => {
  const api = createAxios();
  const { data } = await api.get(API_ENDPOINTS.OPERATORS, {
    params: { type },
  });
  successCallback?.(data.operators);
};

export const getSite = async (p: {
  params: {
    operatorId: string;
    type: string;
  };
  successCallback?: (sites: CommonSite[]) => void;
}) => {
  const { params, successCallback } = p;
  const api = createAxios();
  const { data } = await api.get(API_ENDPOINTS.SITE, {
    params,
  });
  successCallback?.(data.sites);
};

export const updateProducerResourceData = async (
  params: { form: ResourceManagementItem },
  successCallback?: (data: { data: string }) => void
) => {
  const api = createAxios();
  try {
    const data = await api.put(
      getApiPath(API_ENDPOINTS.RESOURCES_PRODUCT, {
        id: params.form.id,
      }),
      {
        processingMethod: params.form.processingMethod,
        packingStyle: params.form.packingStyle,
        qualityInspectionMethod: params.form.qualityInspectionMethod,
        gradeId: Number(params.form.gradeId),
        materialTypeId: Number(params.form.materialTypeId),
        quantity: Number(params.form.productQuantity),
        weight: params.form.weight,
        name: params.form.productName.trim(),
      }
    );
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const getSitesEmissionsSiteStations = async (p: {
  params?: GetListParams;
  successCallback?: (data: EmissionsSiteStation) => void;
}) => {
  try {
    const { params, successCallback } = p;
    const api = createAxios();
    const { data } = await api.get<EmissionsSiteStation>(
      API_ENDPOINTS.SITES_EMISSIONS_SITE_STATIONS,
      {
        params,
      }
    );
    successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const getTenants = async (
  siteId: string,
  successCallback?: (data: FetchTenantsRes) => void
) => {
  const api = createAxios();

  try {
    const { data } = await api.get<FetchTenantsRes>(API_ENDPOINTS.TENANTS, {
      params: {
        siteId,
      },
    });
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};
