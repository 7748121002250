// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

export enum UnitType {
  KG = 'kg',
  T = 't',
}

export enum CurrencyUnitType {
  USD = 'USD',
  JPY = 'JPY',
  EUR = 'EUR',
}
