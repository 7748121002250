// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useTranslation } from 'react-i18next';
import { ResourceTableProps } from '.';
import { OperatorType } from 'util/Enums';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { DATE_FORMAT } from 'util/ConstantValues';
import styles from './PreRegisResourceSelect.module.scss';
import { mergeClasses, objectFetch } from 'util/commons';
import { ErrorText } from 'components/atoms/ErrorText';
import { useState } from 'react';
import TableFilterCalendar from 'components/molecules/TableFilterCalendar';
import TableFilterMultiSelect from 'components/molecules/TableFilterMultiSelect';
import TableFilterSelect from 'components/molecules/TableFilterSelect';
import { ResourcePreRegisFilter } from 'util/Types';

const MobileResourceList: React.FC<ResourceTableProps> = ({
  handleCheckAll,
  filter,
  setFilter,
  resourceData,
  siteOptions,
  materialNameOptions,
  operatorType,
  displayResourceData,
  selectedResources,
  isActionDisabled,
  handleCheckItem,
  handleChangeInput,
  isCheckAllDisabled,
  allChecked,
}) => {
  const { t } = useTranslation();
  const [filterOpened, setFilterOpened] = useState<boolean>(false);
  const [tmpFilter, setTmpFilter] = useState<ResourcePreRegisFilter>({
    ...filter,
  });

  const renderRecordDetail = (
    title: string | null,
    value: string | number | null | undefined
  ) => {
    if (title == null || value == null) {
      return null;
    }

    return (
      <p
        style={{
          wordBreak: 'break-word',
        }}
      >
        <strong>{title}:</strong> <span>{value}</span>
      </p>
    );
  };

  const renderContent = () => {
    if (filterOpened) {
      return (
        <div className={styles.filterWrapper}>
          <div className={styles.filterForm}>
            <div>
              <TableFilterCalendar
                label={t('pre_regist_resource_select.date_time')}
                value={tmpFilter?.receivedDatetime}
                onChange={(value) =>
                  setTmpFilter((prev) => ({ ...prev, receivedDatetime: value }))
                }
                centered
                expanded
                calendarWrapperStyle={{
                  border: '1px solid white',
                }}
              />
            </div>
            <div>
              <TableFilterMultiSelect
                label={t('pre_regist_resource_select.receive_from')}
                options={siteOptions}
                values={tmpFilter?.siteIds}
                onChange={(value) =>
                  setTmpFilter((prev) => ({ ...prev, siteIds: value }))
                }
                disabled={!resourceData.length}
                expanded
                fullWidth
              />
            </div>
            <div>
              <TableFilterSelect
                label={t('pre_regist_resource_select.material_name')}
                options={materialNameOptions}
                value={tmpFilter?.materialName}
                onChange={(value) =>
                  setTmpFilter((prev) => ({ ...prev, materialName: value }))
                }
                disabled={!resourceData.length}
                expanded
                fullWidth
              />
            </div>
          </div>
          <div className={styles.filterButtons}>
            <button
              onClick={() => {
                setTmpFilter(filter);
                setFilterOpened(false);
              }}
            >
              {t('common.button.close')}
            </button>
            {Object.values(tmpFilter).some(
              (val) =>
                (!Array.isArray(val) && val != null) ||
                (Array.isArray(val) && objectFetch(val, 'length'))
            ) && (
              <button
                className={styles.clearFilter}
                onClick={() => {
                  setFilter({});
                  setTmpFilter({});
                  setFilterOpened(false);
                }}
              >
                {t('common.button.clear')}
              </button>
            )}
            <button
              className={styles.saveFilter}
              onClick={() => {
                setFilter(tmpFilter);
                setFilterOpened(false);
              }}
            >
              {t('common.button.save')}
            </button>
          </div>
        </div>
      );
    }

    if (displayResourceData.length > 0) {
      return (
        <div className={styles.resourcesListWrapper}>
          {displayResourceData.map((item, index) => {
            const isItemDisabled = isActionDisabled(item);

            return (
              <div
                key={index}
                onClick={() =>
                  handleCheckItem(
                    !selectedResources[item.processingObjectId]?.checked,
                    item
                  )
                }
                className={mergeClasses(styles.resourceItem, {
                  [styles.checkedResourceItem]:
                    selectedResources[item.processingObjectId]?.checked &&
                    !isItemDisabled,
                  [styles.disabledResourceItem]: isItemDisabled,
                  [styles.checkedResourceError]:
                    selectedResources[item.processingObjectId]?.hasError &&
                    !isItemDisabled,
                })}
              >
                <div className={styles.resourceItemContent}>
                  {renderRecordDetail(
                    t('pre_regist_resource_select.date_time'),
                    getFormatDateWithCurrentTimezone(
                      item.receivedDatetime,
                      DATE_FORMAT.slaYMDHm
                    )
                  )}
                  {renderRecordDetail(
                    t('pre_regist_resource_select.receive_from'),
                    item.site.name
                  )}
                  {renderRecordDetail(
                    t('pre_regist_resource_select.material_name'),
                    item.materialName
                  )}
                  {operatorType === OperatorType.Recycle &&
                    renderRecordDetail(
                      t('pre_regist_resource_select.grade'),
                      item.grade?.name
                    )}
                  {renderRecordDetail(
                    t('pre_regist_resource_select.resource_id'),
                    item.processingObjectId
                  )}
                  {renderRecordDetail(
                    t('pre_regist_resource_select.weight'),
                    `${item.remainingWeight} kg`
                  )}
                </div>

                {!isItemDisabled && (
                  <>
                    <div className={styles.resourceWeightInputWrapper}>
                      <input
                        className={mergeClasses(styles.resourceWeightInput, {
                          [styles.resourceWeightInputError]:
                            selectedResources[item.processingObjectId]
                              ?.hasError,
                        })}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) =>
                          handleChangeInput(e.target.value, item)
                        }
                        value={
                          selectedResources[item.processingObjectId]?.weight ||
                          ''
                        }
                        placeholder={
                          t('pre_regist_resource_select.input_weight') || ''
                        }
                      />
                      <span>kg</span>
                    </div>

                    {selectedResources[item.processingObjectId]?.hasError && (
                      <ErrorText
                        text={
                          t('pre_regist_resource_select.message_error', {
                            field: item.remainingWeight,
                          }) || ''
                        }
                        className={styles.error}
                      />
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
      );
    }

    return <div className={styles.mobileNoData}>{t('messages.M_008')}</div>;
  };

  return (
    <div className={styles.mobileResourceList}>
      {resourceData.length > 0 && (
        <>
          <div className={styles.actionButtons}>
            <button
              onClick={() =>
                setFilterOpened((prev) => {
                  if (prev) {
                    setTmpFilter(filter);
                  }

                  return !prev;
                })
              }
              className={mergeClasses({
                [styles.active]: Object.values(filter).some(
                  (val) =>
                    (!Array.isArray(val) && val != null) ||
                    (Array.isArray(val) && objectFetch(val, 'length'))
                ),
              })}
            >
              {t('common.button.filter')}
            </button>

            <button
              onClick={handleCheckAll}
              className={mergeClasses({
                [styles.active]: allChecked,
              })}
              disabled={isCheckAllDisabled}
            >
              {t('pre_regist_resource_select.selection')}
            </button>
          </div>
        </>
      )}

      {renderContent()}
    </div>
  );
};

export default MobileResourceList;
