// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

type Props = {
  onClick?: VoidFunction;
};
const IconCalendarTablet = ({ onClick }: Props) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M5.83333 4.66667V2M11.1667 4.66667V2M5.16667 7.33333H11.8333M3.83333 14H13.1667C13.5203 14 13.8594 13.8595 14.1095 13.6095C14.3595 13.3594 14.5 13.0203 14.5 12.6667V4.66667C14.5 4.31304 14.3595 3.97391 14.1095 3.72386C13.8594 3.47381 13.5203 3.33333 13.1667 3.33333H3.83333C3.47971 3.33333 3.14057 3.47381 2.89052 3.72386C2.64048 3.97391 2.5 4.31304 2.5 4.66667V12.6667C2.5 13.0203 2.64048 13.3594 2.89052 13.6095C3.14057 13.8595 3.47971 14 3.83333 14Z"
        stroke="#6F7175"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconCalendarTablet;
