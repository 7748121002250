// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { stringify } from 'querystring';
import { createAxios } from 'ts/createAxios';
import { ResourceHistoryItem } from 'util/adminResourceManagementTypes';

export type GetResourceHistoryList = {
  total: number;
  resourceHistories: ResourceHistoryItem[];
};
export const getResourceHistoryList = async (
  filter: { page: number; take: number },
  callback?: (data: GetResourceHistoryList) => void
) => {
  const query = stringify(filter);
  const api = createAxios();
  const { data } = await api.get<GetResourceHistoryList>(
    `/resources/history?${query}`
  );
  data && callback && callback(data);
};
