// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './ScaleLogin.scss';
import { useState, useEffect } from 'react';
import { createAxios } from 'ts/createAxios';
import { TextInputField } from 'components/molecules/TextInputField';
import { ScaleLoginTemplate } from 'components/templates/ScaleLoginTemplate';
import { Form } from 'react-final-form';
import { PrimaryBtnField } from 'components/molecules/PrimaryBtnField';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setUser } from 'redux/slices/userSlice';
import {
  setIsCheckingSignin,
  setIsSignin,
  setPathAfterSignin,
} from 'redux/slices/signinSlice';
import { Path } from 'routes';
import { useTranslation } from 'react-i18next';
import CookiesAuth from 'util/CookiesAuth';

type LoginInput = {
  userId: string;
  password: string;
};

const ScaleLogin = () => {
  const api = createAxios('login');
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    // アイテムをリセット
    CookiesAuth.logout();
  }, []);

  return (
    <ScaleLoginTemplate>
      <div>
        <div className="logo">POOL</div>
        <Form<LoginInput>
          onSubmit={(values) => {
            dispatch(setIsCheckingSignin(true));
            setIsError(false);

            if (values.userId == '' || values.password == '') {
              setIsError(true);
              return;
            }

            api
              .post(
                '/auth/scale-login',
                {
                  userId: values.userId,
                  password: values.password,
                },
                { method: 'post' }
              )
              .then(function (res) {
                if (res.status == 401) {
                  setIsError(true);
                  return;
                } else {
                  setIsError(false);
                  // クッキー設定
                  if (res.data.IdToken && res.data.RefreshToken) {
                    CookiesAuth.login({
                      accessToken: res.data.IdToken,
                      refreshToken: res.data.RefreshToken,
                    });
                  }

                  // クッキー設定後にもう一度axiosのインスタンスを生成する
                  const myAccountApi = createAxios('login');
                  // ユーザ情報取得を呼ぶ
                  myAccountApi.get('/auth/my-account').then(function (res) {
                    if (res.status == 401) {
                      setIsError(true);
                      return;
                    } else {
                      dispatch(setIsSignin(true));
                      dispatch(setUser(res.data));
                      dispatch(setPathAfterSignin(Path.scaleSelectOperator));

                      // 初回画面に遷移する
                      localStorage.setItem('scale-login', 'true');
                      history.push(Path.scaleSelectOperator);
                      return;
                    }
                  });
                }
              })
              .catch(function () {
                setIsError(true);
                return;
              });
            dispatch(setIsCheckingSignin(false));
          }}
          keepDirtyOnReinitialize
        >
          {({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              className="login-form"
              id="scale-login-form"
            >
              <div>
                <TextInputField
                  label={t('login.user_id') || 'ユーザーID'}
                  name={'userId'}
                ></TextInputField>
                <TextInputField
                  label={t('login.password') || 'パスワード'}
                  name={'password'}
                  type={'password'}
                ></TextInputField>
              </div>
              {isError && (
                <div className="errors">{t('scale_login.error_message')}</div>
              )}

              <PrimaryBtnField text={t('login.button')}></PrimaryBtnField>
            </form>
          )}
        </Form>
      </div>
    </ScaleLoginTemplate>
  );
};

export default ScaleLogin;
