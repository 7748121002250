// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
const IconBox = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 7.76471V15.7647L12.5 20M4.5 7.76471L8.5 5.88235M4.5 7.76471L12.5 12M12.5 20L20.5 15.7647V7.76471M12.5 20V12M20.5 7.76471L12.5 4L8.5 5.88235M20.5 7.76471L12.5 12M8.5 5.88235L16.2647 9.64706V13.4118"
        stroke="#6F7175"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconBox;
