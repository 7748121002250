// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import RecycleChainDesignAddSiteForm from 'components/organisms/RecycleChainDesignAddSiteForm';
import RecycleChainDesignAddForm, {
  RecycleChainDesignPut,
} from 'components/organisms/RecycleChainDesignDetailForm';
import { AdminTemplate } from 'components/templates/AdminTemplate';
import { useState } from 'react';
import { Path } from 'routes';

const AdminRecycleChainDesignAdd = () => {
  const [isAddSite, setIsAddSite] = useState(false);
  const [chainForm, setChainForm] = useState<RecycleChainDesignPut>();
  const handleNextToAddSite = (form: RecycleChainDesignPut) => {
    setChainForm({
      ...form,
      chainName: form.chainName.trim(),
      englishMaterialName: form.englishMaterialName.trim(),
      chainStages: form.chainStages.filter((item) => item.stage),
    });
    setIsAddSite(true);
  };
  return (
    <AdminTemplate
      h1={!isAddSite ? 'アカウント区分設定' : '拠点の紐付け'}
      active="リサイクルチェーンデザイン"
      breadcrumb={[
        {
          label: 'リサイクルチェーンリスト',
          href: Path.adminRecycleChainDesignManagement,
        },
        {
          label: 'リサイクルチェーン追加',
        },
      ]}
      className="recycle_chain_page"
    >
      {!isAddSite ? (
        <RecycleChainDesignAddForm onNextToAddSite={handleNextToAddSite} />
      ) : (
        <RecycleChainDesignAddSiteForm
          chainForm={chainForm}
          setChainForm={setChainForm}
          type="add"
        />
      )}
    </AdminTemplate>
  );
};

export default AdminRecycleChainDesignAdd;
