// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useLayoutEffect, useRef } from 'react';
import { convertWeightValueReceivedFromScale } from 'util/commons';
import { ItemType } from 'util/Types';

export const useWeightInput = (
  params?: {
    itemType?: ItemType | 'compression';
    receiveValueFromScaleCondition?: boolean;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dependencies: any[] = []
) => {
  const { itemType, receiveValueFromScaleCondition = true } = params ?? {};
  const weightInputRef = useRef<HTMLInputElement | null>(null);

  useLayoutEffect(() => {
    if (weightInputRef.current) {
      weightInputRef.current.id = receiveValueFromScaleCondition
        ? 'scale-weight-input'
        : 'scale-weight-input-stopped';

      Object.assign(weightInputRef.current, {
        weightConverter: (value: string) =>
          convertWeightValueReceivedFromScale(value, itemType),
      });
    }
  }, [itemType, receiveValueFromScaleCondition, ...dependencies]);

  return {
    weightInputRef,
  };
};
