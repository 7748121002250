// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './AdminNavbar.scss';
import React, { useEffect } from 'react';
import { Path } from 'routes';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectAdminSidebar,
  setAdminSidebar,
} from 'redux/slices/localSettingSlice';

import { OperatorType } from 'util/Enums';

import { IconChevronDown } from 'components/atoms/icons/IconChevronDown';
import { IconChevronUp } from 'components/atoms/icons/IconChevronUp';
import Badge from 'components/atoms/Badge';
import { getTotalUnassignedBrands } from 'apis/brands';
import { version } from '../../../../package.json';

type Props = {
  active?: string;
};

type SidebarMenu = {
  label: string;
  active: string;
  path: string;
  subMenus?: SidebarMenu[];
  badge?: React.ReactNode;
};

export function AdminNavbar({ active }: Props) {
  const adminSidebar = useSelector(selectAdminSidebar);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!adminSidebar.totalUnassignedBrands) {
      getTotalUnassignedBrands();
    }
  }, []);

  const AdminSidebarMasterSubMenus: SidebarMenu[] = [
    {
      label: t('common.sidebar_operator'),
      active: '排出',
      path: `${Path.adminOperatorListManagement}?type=${OperatorType.Emissions}`,
    },
    {
      label: t('common.sidebar_collect'),
      active: '回収',
      path: `${Path.adminOperatorListManagement}?type=${OperatorType.Collects}`,
    },
    {
      label: t('common.sidebar_volume'),
      active: '中間処理',
      path: `${Path.adminOperatorListManagement}?type=${OperatorType.Compressions}`,
    },
    {
      label: t('common.sidebar_recycling'),
      active: 'リサイクル',
      path: `${Path.adminOperatorListManagement}?type=${OperatorType.Recycle}`,
    },
    {
      label: t('common.sidebar_producer'),
      active: t('common.sidebar_producer'),
      path: `${Path.adminOperatorListManagement}?type=${OperatorType.Producer}`,
    },
    {
      label: t('common.sidebar_purchase'),
      active: t('common.sidebar_purchase'),
      path: `${Path.adminOperatorListManagement}?type=${OperatorType.Purchase}`,
    },
    {
      label: 'ブランド管理',
      active: 'ブランド管理',
      path: Path.adminBrandOwnerManagement,
      badge: adminSidebar.totalUnassignedBrands || null,
    },
  ];
  const EmissionDataManagementSubmenu = [
    {
      label: t('common.sidebar_resource_management'),
      active: '資源一覧',
      path: `${Path.adminEmissionResourceManagement}?operatorType=Emissions`,
    },
    {
      label: t('common.sidebar_resource_history'),
      active: '履歴',
      path: `${Path.adminEmissionResourceHistory}`,
    },
  ];
  const AdminSidebarMenus: SidebarMenu[] = [
    {
      label: t('common.sidebar_master'),
      active: AdminSidebarMasterSubMenus.map((sub) => sub.active).join(','),
      path: AdminSidebarMasterSubMenus[0].path,
      subMenus: AdminSidebarMasterSubMenus,
    },
    {
      label: 'ユーザー管理',
      active: 'ユーザー管理',
      path: Path.userManagement,
    },
    {
      label: 'リサイクルチェーンデザイン',
      active: 'リサイクルチェーンデザイン',
      path: Path.adminRecycleChainDesignManagement,
    },
    {
      label: 'トレーサビリティ',
      active: 'トレーサビリティ',
      path: Path.traceSearch,
    },
    {
      label: t('common.sidebar_download'),
      active: 'データダウンロード',
      path: Path.downloads,
    },

    {
      label: t('common.sidebar_emission_resource'),
      active: EmissionDataManagementSubmenu.map((sub) => sub.active).join(','),
      path: EmissionDataManagementSubmenu[0].path,
      subMenus: EmissionDataManagementSubmenu,
    },
    {
      label: t('common.sidebar_notification'),
      active: '通知リスト',
      path: Path.weightNotices,
    },
    {
      label: '請求管理',
      active: '請求管理',
      path: Path.invoiceManagegement,
    },
  ];

  useEffect(() => {
    if (!active || adminSidebar.isInitialState) {
      return;
    }

    const activeMenu = AdminSidebarMenus.find((sidebarMenu: SidebarMenu) => {
      if (sidebarMenu.subMenus) {
        return sidebarMenu.active.includes(active);
      }

      return sidebarMenu.active === active;
    });

    dispatch(
      setAdminSidebar({
        ...adminSidebar,
        isInitialState: true,
        activeMenuShowedSubMenus: activeMenu ? [activeMenu.active] : [],
      })
    );
  }, [active]);

  const renderSidebarItem = (item: SidebarMenu) => (
    <Badge show={Boolean(item.badge)} content={item.badge} color="red">
      {item.label}
    </Badge>
  );

  return (
    <div className="side-nav">
      <div className="logo">
        <img src="/assets/images/admin-logo.svg" alt="logo-admin" />
      </div>

      <div className="side-nav-content">
        <div className="side-nav-links">
          <ul>
            {AdminSidebarMenus.map(
              (sidebarMenu: SidebarMenu, index: number) => {
                if (sidebarMenu.subMenus) {
                  const isShowSubMenus =
                    adminSidebar.activeMenuShowedSubMenus.includes(
                      sidebarMenu.active
                    );
                  const addActiveMenuAdminSidebarState = () => {
                    const newActiveMenuShowedSubMenus =
                      adminSidebar.activeMenuShowedSubMenus.concat([
                        sidebarMenu.active,
                      ]);
                    dispatch(
                      setAdminSidebar({
                        ...adminSidebar,
                        activeMenuShowedSubMenus: newActiveMenuShowedSubMenus,
                      })
                    );
                  };

                  const removeActiveMenuAdminSidebarState = () => {
                    const newActiveMenuShowedSubMenus =
                      adminSidebar.activeMenuShowedSubMenus.filter(
                        (activeMenu) => activeMenu != sidebarMenu.active
                      );
                    dispatch(
                      setAdminSidebar({
                        ...adminSidebar,
                        activeMenuShowedSubMenus: newActiveMenuShowedSubMenus,
                      })
                    );
                  };

                  return (
                    <li
                      key={`sidebar-menu-with-sub-menus-${index}`}
                      className={`${
                        active && sidebarMenu.active.includes(active)
                          ? 'active'
                          : ''
                      } has_submenu`}
                      onClick={() => addActiveMenuAdminSidebarState()}
                    >
                      <Link to={sidebarMenu.path}>
                        <p>
                          {renderSidebarItem(sidebarMenu)}

                          {isShowSubMenus ? (
                            <IconChevronUp
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                removeActiveMenuAdminSidebarState();
                              }}
                            />
                          ) : (
                            <IconChevronDown
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                addActiveMenuAdminSidebarState();
                              }}
                            />
                          )}
                        </p>
                      </Link>

                      {isShowSubMenus && (
                        <ul onClick={(event) => event.preventDefault()}>
                          {sidebarMenu.subMenus.map(
                            (subSideBarMenu: SidebarMenu, subIndex: number) => {
                              return (
                                <Link
                                  to={subSideBarMenu.path}
                                  key={`sidebar-menu-${index}-sub-menu-${subIndex}`}
                                >
                                  <li
                                    className={`${
                                      subSideBarMenu.active == active
                                        ? 'active'
                                        : ''
                                    } submenu`}
                                  >
                                    {renderSidebarItem(subSideBarMenu)}
                                  </li>
                                </Link>
                              );
                            }
                          )}
                        </ul>
                      )}
                    </li>
                  );
                }

                return (
                  <Link
                    to={sidebarMenu.path}
                    key={`sidebar-menu-without-sub-menus-${index}`}
                  >
                    <li
                      className={sidebarMenu.active === active ? 'active' : ''}
                    >
                      {renderSidebarItem(sidebarMenu)}
                    </li>
                  </Link>
                );
              }
            )}

            <li className="version">Ver {version}</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
