// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Modal } from 'components/atoms/Modal';
import { IconClose } from 'components/atoms/icons/IconClose';
import CreateUpdateSiteRecycleInputForm from 'components/molecules/CreateUpdateSiteRecycleInputForm/CreateUpdateSiteRecycleInputForm';
import { ModalSiteState } from 'pages/AdminSiteListManagement/AdminSiteListManagement';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initialFormData } from 'util/ConstantValues';
import { ModalType } from 'util/Enums';
import { SiteDataItem, SiteRecycleItem } from 'util/siteManagementTypes';
import styles from './CreateUpdateSiteRecycleModal.module.scss';
import {
  useFetchAcceptanceGradesQuery,
  useFetchGradesQuery,
  useFetchLocationQuery,
  useFetchMateriaTypelNamesQuery,
  useFetchRecyleMachineSuggestionQuery,
} from 'apis/site_recycle/siteCategoryApi';
import { useFetchRecycleDetailQuery } from 'apis/site_recycle/siteRecycleApi';
import { fetchPackingStyleSuggestions } from 'apis/commons/suggestsApi';
import { fetchAcceptanceMaterials } from 'apis/operator_site/siteApi';

interface Props {
  createUpdateSiteRecycleModal: ModalSiteState;
  onClose: () => void;
  onCreateRecycleSite: (createFloorForm: SiteRecycleItem) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateRecycleSite: (updateForm: any, siteEditing: SiteDataItem) => void;
}

const CreateUpdateSiteRecycleModal: React.FC<Props> = ({
  createUpdateSiteRecycleModal,
  onClose,
  onCreateRecycleSite,
  onUpdateRecycleSite,
}) => {
  const { t } = useTranslation();
  const { siteEditing, type } = createUpdateSiteRecycleModal;

  const { data: location } = useFetchLocationQuery(false);
  const { data: acceptanceGrades } = useFetchAcceptanceGradesQuery();
  const { data: materiaTypelNames } = useFetchMateriaTypelNamesQuery();
  const { data: grades } = useFetchGradesQuery({});
  const { data: recycleMachine } = useFetchRecyleMachineSuggestionQuery();

  const { data: detailSiteRecycle } = useFetchRecycleDetailQuery(
    siteEditing?.id,
    {
      skip: !siteEditing?.id && type !== ModalType.update,
    }
  );

  const [packingStyleSuggestions, setPackingStyleSuggestions] = useState<
    string[]
  >([]);
  const [acceptanceMaterialSuggestions, setAcceptanceMaterialSuggestions] =
    useState<string[]>([]);

  const suggestion = useMemo(() => {
    return {
      provinceSuggestion: location?.province || [],
      districtSuggestion: location?.district || [],
      acceptanceGrades: acceptanceGrades || [],
      materialTypes: materiaTypelNames || [],
      grades: grades || [],
    };
  }, [location, acceptanceGrades, materiaTypelNames, grades]);

  const inputForm = useMemo(() => {
    if (detailSiteRecycle) {
      return {
        ...detailSiteRecycle,
        acceptanceGrade: detailSiteRecycle.acceptanceGrade,
        enrollableType: detailSiteRecycle.enrollableType.map((item) => {
          return item.name;
        }),
        enrollableGrade: detailSiteRecycle.enrollableGrade.map(
          (item) => item.name
        ),
        recycleMethod: detailSiteRecycle.recycleMethod,
        district: detailSiteRecycle.siteAddresses[0]?.district || '',
        province: detailSiteRecycle.siteAddresses[0]?.province || '',
        staffTel: detailSiteRecycle.staffTel || '',
        wastePermit: detailSiteRecycle?.wastePermitUrl,
      } as unknown as SiteRecycleItem;
    } else {
      return initialFormData;
    }
  }, [detailSiteRecycle]);

  useEffect(() => {
    fetchAcceptanceMaterials((data) => {
      if (data) {
        setAcceptanceMaterialSuggestions(
          data.map((item) => item.acceptanceMaterial)
        );
      }
    });
    fetchPackingStyleSuggestions('Recycle', (data) => {
      if (data) {
        setPackingStyleSuggestions(data.map((item) => item.packingStyle));
      }
    });
  }, []);

  const handleUpdateData = (
    updateForm: SiteRecycleItem,
    siteEditing: SiteDataItem
  ) => {
    const convertData = {
      ...updateForm,
      postalCode: updateForm.postalCode || '',
      enrollableType: updateForm.enrollableType.map((name) => ({
        id:
          detailSiteRecycle?.enrollableType.find((d) => d.name === name)?.id ||
          null,
        name,
      })),
      enrollableGrade: updateForm.enrollableGrade.map((name) => ({
        id:
          detailSiteRecycle?.enrollableGrade.find((d) => d.name === name)?.id ||
          null,
        name,
      })),
      wastePermit:
        updateForm.wastePermit === updateForm.wastePermitUrl
          ? undefined
          : updateForm.wastePermit,
      wastePermitDelete:
        !updateForm.wastePermit && updateForm.wastePermitUrl ? true : undefined,
      ediConfirmationKey: updateForm.ediConfirmationKey || '',
      ediSubscriberNumber: updateForm.ediSubscriberNumber || '',
    };

    onUpdateRecycleSite(convertData, siteEditing);
  };

  return (
    <div className={styles.createUpdateSiteRecycleModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {createUpdateSiteRecycleModal.type === ModalType.add
                ? t('site_management.modal_create_site.title')
                : t('site_management.modal_update_site.title')}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <CreateUpdateSiteRecycleInputForm
            intitalValue={inputForm}
            machineType={recycleMachine}
            createUpdateSiteRecycleModal={createUpdateSiteRecycleModal}
            onCreateRecycleSite={onCreateRecycleSite}
            onUpdateRecycleSite={handleUpdateData}
            suggestion={suggestion}
            acceptanceMaterialSuggestions={acceptanceMaterialSuggestions}
            packingStyleSuggestions={packingStyleSuggestions}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateUpdateSiteRecycleModal;
