// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './AdminUserManagementList.module.scss';
import { PAGE_SIZE } from 'util/ConstantValues';
import { IconEdit } from 'components/atoms/icons/IconEdit';
import { IconDelete } from 'components/atoms/icons/IconDelete';
import TableLoading from 'components/atoms/TableLoading';
import { messageErrors } from 'util/messageErrors';
import {
  Account,
  listOperatorType,
  roleOptions,
} from 'util/accountManagementTypes';
import { OperatorType } from 'util/Enums';

interface Props {
  accountId: string;
  userList: Account[];
  totalAccount: number;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  renderUsersData: (
    keyword?: string,
    nextPage?: number,
    forceRefresh?: boolean,
    operatorType?: string
  ) => void;
  onClickEditAccount: (acc: Account) => void;
  onClickDeleteAccount: (acc: Account) => void;
}
const AdminUserManagementTable = ({
  userList,
  totalAccount,
  currentPage,
  setCurrentPage,
  renderUsersData,
  onClickEditAccount,
  onClickDeleteAccount,
  accountId,
}: Props) => {
  return (
    <div className={styles.tableList}>
      <div className={`scroll-wrapper ${styles.scrollWapper}`}>
        <table id={styles.customizeItemTable} className="">
          <thead>
            <tr>
              <th className={styles.userId}>ユーザーID</th>
              <th className={styles.userName}>名前</th>
              <th className={styles.operatorType}>事業者タイプ</th>
              <th className={styles.role}>役割</th>
              <th className={styles.operatorId}>事業者ID</th>
              <th className={styles.siteId}>拠点ID</th>
              <th className={styles.actionField}>アクション</th>
            </tr>
          </thead>
          <tbody>
            {userList && userList.length > 0 ? (
              userList.map((item, i) => {
                const role = roleOptions.find((r) => r.value === item.type);
                const operator = listOperatorType.find(
                  (o) =>
                    o.value ===
                    (item.type === OperatorType.AdminPool
                      ? item.type
                      : item.operator.type)
                );
                return (
                  <tr
                    key={i}
                    onClick={() => {
                      // Todo
                    }}
                  >
                    <td className={styles.userId}>
                      <p title={item.id}>{item.id || '-'}</p>
                    </td>
                    <td className={styles.userName}>
                      <p title={item.name}>{item.name || '-'}</p>
                    </td>
                    <td className={styles.operatorType}>
                      <p title={operator?.label}>{operator?.label || '-'}</p>
                    </td>
                    <td className={styles.role}>
                      <p title={role?.label}>{role?.label || '-'}</p>
                    </td>
                    <td className={styles.operatorId}>
                      <p title={item.operator?.id}>
                        {item.type === OperatorType.AdminPool
                          ? item.operatorPoolId
                          : item.operator?.id || '-'}
                      </p>
                    </td>
                    <td className={styles.siteId}>
                      <p title={item.siteId}>{item.siteId || '-'}</p>
                    </td>
                    <td className={styles.actionField}>
                      <div className={styles.contentAction}>
                        <div className={styles.actionEdit}>
                          <IconEdit
                            onClick={() => {
                              onClickEditAccount(item);
                            }}
                          />
                        </div>
                        {item.id !== accountId && (
                          <div className={styles.actionDelete}>
                            <IconDelete
                              onClick={() => {
                                onClickDeleteAccount(item);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className={styles.msgError}>{messageErrors.M_008}</td>
              </tr>
            )}
            {userList && userList.length > 0 && (
              <TableLoading
                onNext={() => {
                  if (
                    userList.length >= PAGE_SIZE &&
                    userList.length < totalAccount
                  ) {
                    setCurrentPage(currentPage + 1);
                    renderUsersData();
                  }
                }}
              />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminUserManagementTable;
