// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  onClick?: VoidFunction;
}
export const IconArrowLeft = ({ onClick }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M15 19L8 12L15 5"
        stroke="#6F7175"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
