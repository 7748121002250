// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useResourcesMutation } from 'apis/purchase/purchaseApi';
import { useFetchRecycleChainQuery } from 'apis/recycle_chain';
import {
  createPurchaseDelivery,
  fetchNextStageSites,
} from 'apis/site_purchase/purchase';
import { useLazyFetchGradesByRecycleChainQuery } from 'apis/site_recycle/siteCategoryApi';
import { CustomCheckBox } from 'components/atoms/CustomCheckbox';
import TableLoading from 'components/atoms/TableLoading';
import CheckShippingDestination from 'components/molecules/CheckShippingDestination';
import ErrorModal from 'components/molecules/ErrorModal';
import ExpectedDestinationModal from 'components/molecules/ExpectedDestinationModal';
import PurchaseResourceMapBox from 'components/molecules/PurchaseResourceMapBox';
import ResourceConfirmModal from 'components/molecules/ResourceConfirmModal';
import {
  OptionItem,
  SelectFieldMultipleChoise,
} from 'components/molecules/SelectFieldMultipleChoise';
import PurchaseTemplate from 'components/templates/PurchaseTemplate';
import _uniqBy from 'lodash/uniqBy';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { requestFormatDateTime } from 'ts/formatDate';
import {
  PurchaseDelivery,
  Resource,
  TGradeByIdRequest,
  TGradeByIdResponse,
  TResourcesRequest,
} from 'types/purchase';
import { DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import { OperatorType } from 'util/Enums';
import { CommonSite } from 'util/Types';
import {
  getOperatorTypeLabel,
  getStatusPurchaseLabel,
  mergeClasses,
  normalizeNumber,
} from 'util/commons';
import styles from './index.module.scss';

const OPERATOR_LIST: string[] = [
  OperatorType.Emissions,
  OperatorType.Compressions,
  OperatorType.Recycle,
  OperatorType.Producer,
];

type QueryType = 'isSearch' | 'isPaging' | 'isStatus';

const ResourceManagement = ({ recycleChainId }: { recycleChainId: string }) => {
  const { t, i18n } = useTranslation();

  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [allCheckedFlg, setAllCheckedFlg] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [shippingDestinationOptions, setShippingDestinationOptions] = useState<
    CommonSite[]
  >([]);
  const [stage, setStage] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [isShowMap, setShowMap] = useState<boolean>(true);
  const [isShowError, setIsShowError] = useState<boolean>(false);
  const [contentError, setContentError] = useState<string | null>('');
  const [resources, setResources] = useState<Resource[]>([]);
  const [grades, setGrades] = useState<TGradeByIdResponse[]>([]);
  const tableRef = useRef<HTMLTableElement>(null);

  const { data: chainData } = useFetchRecycleChainQuery(
    {
      id: recycleChainId,
    },
    {
      skip: !recycleChainId,
    }
  );

  const [query, setQuery] = useState<TResourcesRequest>({
    params: {
      page: DEFAULT_PAGE,
      take: PAGE_SIZE,
    },
    body: {
      chainStageIds: [],
      recycleChainId: Number(recycleChainId),
      siteTypes: [],
      siteIds: [],
      statusList: [],
    },
  });

  const [fnGetResources] = useResourcesMutation(); // Table data
  const [fnQueryGrades, gradesResult] = useLazyFetchGradesByRecycleChainQuery();

  useEffect(() => {
    if (gradesResult.data) {
      setGrades(gradesResult.data);
    }
  }, [gradesResult]);

  const filteredResources =
    resources.filter((resource) =>
      checkedItems.map((id) => id).includes(resource.id)
    ) || [];

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const itemId = e.target.id;
    const isChecked = e.target.checked;
    let updatedCheckedItems;

    if (isChecked) {
      updatedCheckedItems = [...checkedItems, itemId];
      setCheckedItems(updatedCheckedItems);
    } else {
      updatedCheckedItems = checkedItems.filter((item) => item !== itemId);
      setCheckedItems(updatedCheckedItems);
    }

    setAllCheckedFlg(updatedCheckedItems.length === resources.length);
  };

  const allChecked = () => {
    if (allCheckedFlg) {
      onResetCheckItems();
    } else {
      const allItemIds = resources.map((item) => item.id.toString());
      setCheckedItems(allItemIds || []);
      setAllCheckedFlg(true);
    }
  };

  const groupedByPackingStyle = filteredResources.reduce((groups, item) => {
    const packingStyle = item.packingStyle;

    if (!groups[packingStyle]) {
      groups[packingStyle] = [];
    }

    groups[packingStyle].push(item);

    return groups;
  }, {});

  const resultArray = Object.values(groupedByPackingStyle);

  const renderPackingStyles = resultArray.map((group: any) => {
    const totalWeight = group.reduce(
      (total, item) => total + parseFloat(item.weight),
      0
    );
    return {
      weight: totalWeight.toString(),
      packingStyle: group[0].packingStyle,
      total: group.length,
    };
  });

  const handleValidateConfirm = () => {
    if (checkedItems.length) {
      if (
        filteredResources.some(
          (item) =>
            item.type !== OperatorType.Recycle &&
            item.type !== OperatorType.Producer
        )
      ) {
        setContentError(t('purchase_page.resource.error.type'));
        setIsShowError(true);
        return;
      }

      if (
        filteredResources.some(
          (item) => item.site.id !== filteredResources[0].site.id
        )
      ) {
        setContentError(t('purchase_page.resource.error.site'));
        setIsShowError(true);
        return;
      }
      if (filteredResources.some((item) => item.status !== 'Processed')) {
        setContentError(t('purchase_page.resource.error.status'));
        setIsShowError(true);
        return;
      }

      return setStep(1);
    }
  };

  const handleNextShippingDestination = () => {
    fetchNextStageSites({ ids: checkedItems }, (data) => {
      setShippingDestinationOptions(data);
      setStep(2);
    });
  };

  const handleNextCheckShippingDestination = (stage: string) => {
    const resultObject = shippingDestinationOptions.find(
      (item) => item.id === stage
    );
    if (stage && resultObject) {
      setStage?.(resultObject?.name);
      setStep(3);
    }
  };

  const handleSubmitModalConfirm = (form: any) => {
    const deliveryById =
      stage && shippingDestinationOptions
        ? shippingDestinationOptions?.find((item) => item.name === stage)?.id
        : '';

    const data: PurchaseDelivery = {
      resourceIds: checkedItems,
      deliveryToSiteId: deliveryById?.toString(),
      deliveriedDatetime: requestFormatDateTime(form.date + ' ' + form.time),
    };

    createPurchaseDelivery({ form: data }, async () => {
      toast.success(t('purchase_page.resource.message_success'));
      setStep(0);
      await onQuery('isSearch');
    });
  };

  const chainStageIdOperatorMapper = useMemo(
    () =>
      chainData?.chainStages.reduce(
        (rs: Record<string, string>, { stage, id }) => {
          rs[id] = stage;

          return rs;
        },
        {}
      ),
    [chainData?.chainStages]
  );

  const sortedChainStages = useMemo(
    () =>
      [...(chainData?.chainStages ?? [])]
        .sort((a, b) => a.stageIndex - b.stageIndex)
        .filter(({ stage }) => OPERATOR_LIST.includes(stage)),
    [chainData?.chainStages]
  );

  const operatorTypeOptions: OptionItem[] = useMemo(() => {
    return (
      sortedChainStages.reduce((rs: OptionItem[], { stage, id }, index) => {
        rs.push({
          id,
          name: `${getOperatorTypeLabel(stage)} (${index + 1})`,
        });

        return rs;
      }, []) ?? []
    );
  }, [chainData, i18n.language]);

  const resourceStatusOptions: OptionItem[] = [
    { id: 'Received', name: t('purchase_page.resource.received') },
    { id: 'Processed', name: t('purchase_page.resource.processed') },
    { id: 'Delivery', name: t('purchase_page.resource.delivery') },
  ];

  const gradeOptions: OptionItem[] = useMemo(
    () =>
      grades?.map(({ grade }) => ({
        id: grade,
        name: grade,
      })) || [],
    [grades]
  );

  const getSiteTypeOptions = (chainStageIds: number[]): OptionItem[] => {
    const options =
      chainData?.chainStages.reduce(
        (rs: OptionItem[], { stage, stageSites, id: stageId }) => {
          stageSites.forEach(({ site: { id, name, siteType } }) => {
            if (chainStageIds.includes(stageId)) {
              if (stage === OperatorType.Emissions) {
                if (siteType != null) {
                  rs.push({
                    id: siteType,
                    name: siteType,
                    type: 'siteTypes',
                  });
                }
              } else {
                rs.push({
                  id,
                  name,
                  type: 'siteIds',
                });
              }
            }
          });

          return rs;
        },
        []
      ) ?? [];

    return _uniqBy(options, 'id');
  };

  const siteTypeOptions = useMemo(
    () => getSiteTypeOptions(query.body.chainStageIds),
    [chainData?.chainStages, query.body.chainStageIds]
  );

  const handleFetchGrade = (
    params: TGradeByIdRequest
  ): Promise<TGradeByIdResponse[]> =>
    new Promise((res) => {
      fnQueryGrades(params)
        .then((data) => res(data.data ?? []))
        .catch(() => res([]));
    });

  const handleSetQuery = async (props, field, values) => {
    tableRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    let newGrades = grades;

    const getNewSiteTypeNameIds = (opts: OptionItem[]) =>
      opts.reduce(
        (rs: [string[], string[]], item) => {
          if (item.id != null) {
            if (item.type === 'siteTypes') {
              rs[0].push(item.id.toString());
            } else {
              rs[1].push(item.id.toString());
            }
          }

          return rs;
        },
        [[], []]
      );

    let [newSiteTypeIds, newSiteNameIds] =
      getNewSiteTypeNameIds(siteTypeOptions);

    if (field === 'siteTypes') {
      const siteTypes = values.filter((x) => x.type === 'siteTypes');
      const fieldSiteTypes = siteTypes.length
        ? (siteTypes.map((x) => x.id) as string[])
        : [];

      const siteIds = values.filter((x) => x.type === 'siteIds');
      const fieldSiteIds = siteIds.length
        ? (siteIds.map((x) => x.id) as string[])
        : [];

      setQuery({
        ...query,
        params: {
          page: DEFAULT_PAGE,
          take: PAGE_SIZE,
        },
        body: {
          ...query.body,
          siteTypes: values.length ? fieldSiteTypes : [],
          siteIds: values.length ? fieldSiteIds : [],
        },
      });
    } else {
      const ids = values.map((x: any) => x.id);
      props.form.mutators.setValue(field, values);

      const isChainStageIdsChanged =
        field === 'chainStageIds' &&
        (ids.some((e: number) => !query.body.chainStageIds.includes(e)) ||
          ids.length !== query.body.chainStageIds.length);

      const isStatusesChanged =
        field === 'statusList' &&
        (ids.some((e: string) => !query.body.statusList.includes(e)) ||
          ids.length !== query.body.siteIds);

      const newChainStageIds = [
        ...(field === 'chainStageIds' ? ids : query.body.chainStageIds),
      ].sort((a, b) => a - b);

      if (isChainStageIdsChanged || isStatusesChanged) {
        const newStatusList =
          field === 'statusList' ? ids : query.body.statusList;

        if (newChainStageIds.length) {
          newGrades = await handleFetchGrade({
            chainStageIds: Array.from(new Set(newChainStageIds)),
            isReceivedSelected:
              newStatusList.includes('Received') || !newStatusList.length,
          });
        } else {
          setGrades([]);
        }
      }

      if (isChainStageIdsChanged) {
        const [tmpSiteTypes, tmpSiteNames] = getNewSiteTypeNameIds(
          getSiteTypeOptions(ids)
        );

        newSiteTypeIds = tmpSiteTypes;
        newSiteNameIds = tmpSiteNames;
      }

      setQuery({
        ...query,
        params: {
          page: DEFAULT_PAGE,
          take: PAGE_SIZE,
        },
        body: {
          ...query.body,
          ...(field === 'chainStageIds' &&
            (!ids?.length ||
              (ids?.length === 1 &&
                chainStageIdOperatorMapper?.[ids[0]] ===
                  OperatorType.Emissions)) && {
              statusList: [],
            }),
          ...((isChainStageIdsChanged || isStatusesChanged) && {
            gradeNames: newChainStageIds.length
              ? query.body.gradeNames?.filter((name) =>
                  newGrades.map(({ grade }) => grade).includes(name)
                )
              : [],
            siteIds: newChainStageIds.length
              ? query.body.siteIds?.filter((e) => newSiteNameIds.includes(e))
              : [],
            siteTypes: newChainStageIds.length
              ? query.body.siteTypes?.filter((e) => newSiteTypeIds.includes(e))
              : [],
          }),
          [field]: ids,
        },
      });
    }
  };

  const totalWeight = useMemo(() => {
    return filteredResources.reduce((acc, cur) => {
      return acc + Number(cur.weight);
    }, 0);
  }, [filteredResources]);

  useEffect(() => {
    if (chainData) {
      tableRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      const listStageIds = chainData.chainStages.reduce(
        (rs: number[], { stage, id }) => {
          if (OPERATOR_LIST.includes(stage)) {
            rs.push(id);
          }

          return rs;
        },
        []
      );

      setQuery({
        ...query,
        params: {
          page: DEFAULT_PAGE,
          take: PAGE_SIZE,
        },
        body: {
          ...query.body,
          ...((!listStageIds.length ||
            (listStageIds.length === 1 &&
              chainStageIdOperatorMapper?.[listStageIds[0]] ===
                OperatorType.Emissions)) && {
            statusList: [],
          }),
          chainStageIds: listStageIds,
          recycleChainId: Number(recycleChainId),
          gradeNames: [],
          siteTypes: [],
          siteIds: [],
        },
      });

      if (listStageIds.length) {
        handleFetchGrade({
          chainStageIds: listStageIds,
          isReceivedSelected: true,
        });
      } else {
        setGrades([]);
      }
    }
  }, [chainData]);

  const onResetCheckItems = () => {
    setCheckedItems([]);
    setAllCheckedFlg(false);
  };

  const onQuery = async (type: QueryType) => {
    if (query.body.chainStageIds.length < 1) {
      setResources([]);
      onResetCheckItems();
      return;
    }

    const result = await fnGetResources(query).unwrap();

    if (type === 'isSearch') {
      setResources(result.resources);
      setTotal(result.total);
      onResetCheckItems();
    } else {
      setResources([...resources, ...result.resources]);
    }
  };

  useEffect(() => {
    onQuery('isSearch');
  }, [query.body]);

  useEffect(() => {
    if (query.params.page > 1) {
      onQuery('isPaging');
    }
  }, [query.params.page]);

  const placeholder = useMemo(() => {
    if (
      query.body.chainStageIds.length === 1 &&
      chainStageIdOperatorMapper?.[query.body.chainStageIds[0]] ===
        OperatorType.Emissions
    ) {
      return t('purchase_page.resource.size_type');
    }

    if (
      query.body.chainStageIds.length > 0 &&
      !query.body.chainStageIds.some(
        (e) => chainStageIdOperatorMapper?.[e] === OperatorType.Emissions
      )
    ) {
      return t('download.thead_base_name');
    }

    return t('purchase_page.resource_search.placeholder_all');
  }, [query, t]);

  return (
    <PurchaseTemplate title={t('purchase_page.resource.header_title')}>
      <div className={styles.resourceContainer}>
        {resources.length > 0 && (
          <PurchaseResourceMapBox
            key={`resource-${recycleChainId}-${resources.length}`}
            isShowMap={isShowMap}
            setShowMap={setShowMap}
            resources={resources}
          />
        )}
        <div
          className={mergeClasses(
            styles.resource,
            isShowMap ? styles.min : styles.max
          )}
        >
          <div className={styles.title}>
            <p>{t('purchase_page.resource.title')}</p>
            <button
              className={styles.submitButton}
              onClick={handleValidateConfirm}
              disabled={!checkedItems.length}
            >
              <span>{t('purchase_page.resource.btn_produce')}</span>
            </button>
          </div>
          <div className={styles.search}>
            <Form
              onSubmit={() => {}}
              initialValues={query}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
            >
              {(props) => {
                return (
                  <form
                    onSubmit={props.handleSubmit}
                    className={styles.formInput}
                  >
                    <div className={styles.inputFrame}>
                      <div className={styles.textFrame}>
                        <SelectFieldMultipleChoise
                          options={operatorTypeOptions}
                          onChange={(items) =>
                            handleSetQuery(props, 'chainStageIds', items)
                          }
                          placeholder={t(
                            'purchase_page.resource.category_registration'
                          )}
                          name="chainStageIds"
                          selectedOptions={[
                            ...(query.body.chainStageIds ?? []),
                          ].sort((a, b) => a - b)}
                          darkMode
                        />
                        <SelectFieldMultipleChoise
                          options={resourceStatusOptions}
                          onChange={(items) =>
                            handleSetQuery(props, 'statusList', items)
                          }
                          placeholder={t(
                            'purchase_page.resource.resource_status'
                          )}
                          disabled={
                            !query.body.chainStageIds.length ||
                            (query.body.chainStageIds.length === 1 &&
                              chainStageIdOperatorMapper?.[
                                query.body.chainStageIds[0]
                              ] === OperatorType.Emissions)
                          }
                          name="statusList"
                          selectedOptions={query.body.statusList || []}
                          darkMode
                        />
                        <SelectFieldMultipleChoise
                          name="gradeNames"
                          options={gradeOptions}
                          onChange={(items) =>
                            handleSetQuery(props, 'gradeNames', items)
                          }
                          placeholder={t('purchase_page.resource.grade')}
                          disabled={query.body.chainStageIds.length < 1}
                          selectedOptions={query.body.gradeNames || []}
                          darkMode
                        />
                        <SelectFieldMultipleChoise
                          name="siteTypes"
                          options={siteTypeOptions}
                          onChange={(items) => {
                            const mapper = items.map((x) =>
                              siteTypeOptions.find((y) => y.id === x.id)
                            );
                            handleSetQuery(props, 'siteTypes', mapper);
                          }}
                          placeholder={placeholder}
                          disabled={query.body.chainStageIds.length < 1}
                          selectedOptions={[
                            ...(query.body.siteTypes || []),
                            ...(query.body.siteIds || []),
                          ]}
                          darkMode
                        />
                      </div>
                    </div>
                  </form>
                );
              }}
            </Form>
          </div>
          <table
            ref={tableRef}
            className={`primary-table ${styles.primaryTable}`}
          >
            <thead>
              <tr>
                <th
                  id="select"
                  className={styles.checkBox}
                  onClick={allChecked}
                >
                  {t('collect_registration.select_all')}
                </th>
                <th className={styles.type}>
                  {t('purchase_page.resource.category_registration')}
                </th>
                <th className={styles.type}>
                  {t('purchase_page.resource.resource_status')}
                </th>
                <th className={styles.type}>
                  {t('purchase_page.resource.grade')}
                </th>

                <th className={styles.label}>
                  {t('purchase_page.resource.site_type_site_name')}
                </th>
                <th className={styles.content}>
                  {t('purchase_page.resource.id')}
                </th>
                <th className={styles.weight}>
                  {t('purchase_page.resource.weight')}
                </th>
              </tr>
            </thead>
            <tbody>
              {resources.length > 0 ? (
                resources?.map((resource, i) => {
                  return (
                    <tr key={`${resource.id}-${i}`}>
                      <td className={styles.checkBox}>
                        <CustomCheckBox
                          id={resource.id}
                          checked={!!checkedItems?.includes(resource.id)}
                          onChange={handleChange}
                        />
                      </td>

                      <td className={styles.type}>
                        {getOperatorTypeLabel(resource.type)}
                      </td>
                      <td className={styles.type}>
                        {getStatusPurchaseLabel(resource.status)}
                      </td>
                      <td className={styles.type}>{resource.grade.name}</td>
                      <td className={styles.label}>
                        {resource.type === OperatorType.Emissions
                          ? resource.site.siteType
                          : resource.site.name}
                      </td>
                      <td className={styles.content}>{resource.id}</td>
                      <td className={styles.weight}>
                        {normalizeNumber({ value: resource.weight })}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <div className={mergeClasses('no-data', styles.noData)}>
                  {t('messages.M_008')}
                </div>
              )}

              {resources.length > 0 && (
                <TableLoading
                  onNext={() => {
                    if (
                      resources.length >= PAGE_SIZE &&
                      resources.length < total
                    ) {
                      setQuery({
                        ...query,
                        params: {
                          ...query.params,
                          page: query.params.page + 1,
                        },
                      });
                    }
                  }}
                />
              )}
            </tbody>
          </table>
          <div className={styles.total}>
            <p>
              {t('purchase_page.resource.total')}&nbsp;
              <span>
                {totalWeight && normalizeNumber({ value: totalWeight })} kg
              </span>
            </p>
          </div>
        </div>

        {step === 1 && (
          <ResourceConfirmModal
            resources={filteredResources}
            packingStyle={renderPackingStyles}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            onClose={() => setStep(0)}
            onSubmit={handleNextShippingDestination}
          />
        )}

        {step === 2 && (
          <ExpectedDestinationModal
            title={t('purchase_page.resource.select_destination')}
            onClose={() => setStep(1)}
            options={shippingDestinationOptions.map((i) => {
              return { label: i.name, value: i.id };
            })}
            onSubmit={handleNextCheckShippingDestination}
            submitButtonLabel={t('purchase_page.resource.btn_next')}
            stage={
              shippingDestinationOptions.find((item) => item.name === stage)?.id
            }
            setStage={setStage}
          />
        )}

        {step === 3 && (
          <CheckShippingDestination
            onClose={() => setStep(2)}
            onSubmit={handleSubmitModalConfirm}
            packingStyles={renderPackingStyles}
            shipping={stage}
            siteName={filteredResources[0].site.name}
          />
        )}

        {isShowError && (
          <ErrorModal
            onClose={() => setIsShowError(false)}
            content={contentError || ''}
          />
        )}
      </div>
    </PurchaseTemplate>
  );
};

export default ResourceManagement;
