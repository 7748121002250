// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoleType, TenantAuthen } from 'util/Enums';
import { RootState } from '../store';

export type UserState = {
  userId: string;
  name: string;
  isAdmin: boolean;
  operatorType: string;
  operatorId: string | null;
  siteId: string | null;
  role: RoleType | null;
  isShowTenants: boolean;
  tenantAuthen: TenantAuthen;
  originalTenantAuthen: TenantAuthen;
  operatorName: string;
  siteName: string;
  temporaryAdminSite?: boolean;
  tenantId?: string;
  tenantName?: string;
};

const initialState: UserState = {
  userId: '',
  name: '',
  isAdmin: false,
  operatorType: '',
  operatorId: null,
  operatorName: '',
  siteName: '',
  siteId: null,
  role: null,
  isShowTenants: false,
  tenantAuthen: TenantAuthen.None,
  originalTenantAuthen: TenantAuthen.None,
  temporaryAdminSite: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state.userId = action.payload.userId || '';
      state.name = action.payload.name || '';
      state.siteName = action.payload.siteName || '';
      state.operatorName = action.payload.operatorName || '';
      state.isAdmin = action.payload.isAdmin || false;
      state.operatorType = action.payload.operatorType || '';
      state.operatorId = action.payload.operatorId || null;
      state.siteId = action.payload.siteId || null;
      state.role = action.payload.role || null;
      state.isShowTenants = action.payload.isShowTenants || false;
      state.tenantAuthen = action.payload.tenantAuthen || TenantAuthen.None;
      state.originalTenantAuthen =
        action.payload.originalTenantAuthen || TenantAuthen.None;
      state.tenantName = action.payload.tenantName || '';
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetUser: (state) => {
      return initialState;
    },
    setUserAsAdminSite: (state) => {
      state.temporaryAdminSite = true;
      return state;
    },
    resetUserAsAdminSite: (state) => {
      state.temporaryAdminSite = false;
      return state;
    },
  },
});

export const { setUser, resetUser, setUserAsAdminSite, resetUserAsAdminSite } =
  userSlice.actions;
export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
