// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal } from 'components/atoms/Modal';
import 'react-calendar/dist/Calendar.css';
import styles from './index.module.scss';
import { IconClose } from 'components/atoms/icons/IconClose';
import { useTranslation } from 'react-i18next';
import TimeInputField from '../TimeInputField';
import { Form } from 'react-final-form';
import { Label } from 'components/atoms/Label';
import { addition } from 'util/Calc';
import { useEffect, useState } from 'react';
import { useFetchMaterialNameFromChainsQuery } from 'apis/purchase/purchaseApi';
import { useParams } from 'react-router';
import { CalendarField } from '../CalendarField';
import { validateDateTime } from 'util/validator';
import dayjs from 'dayjs';
import { normalizeNumber } from 'util/commons';

type Props = {
  siteName: string;
  shipping: string;
  packingStyles: any[];
  onClose: () => void;
  onSubmit: (form: any) => void;
};

const CheckShippingDestination: React.FC<Props> = ({
  shipping,
  siteName,
  packingStyles,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [totalWeight, setTotalWeight] = useState<number>(0);
  const { data: materialNames } = useFetchMaterialNameFromChainsQuery();
  const params = useParams();

  const materialName = materialNames
    ? materialNames?.find((i) => i.id === Number(params['id']))?.materialName
    : '';

  const calcTotal = () => {
    const total =
      packingStyles.reduce(
        (prev, data) => addition(prev, parseFloat(data.weight)),
        0
      ) || 0;
    setTotalWeight(total);
  };

  useEffect(() => {
    calcTotal();
  }, [setTotalWeight]);

  const getResourceInfo = (label: string, data?: string) => (
    <div className={styles.confirmInfo}>
      <div className={styles.confirmInfoLabel}>{label}</div>
      <div className={styles.confirmInfoContent}>{data}</div>
    </div>
  );

  const handleSubmit = (form: any) => {
    onSubmit(form);
  };

  return (
    <section className={styles.confirmModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.title}>
            {t('purchase_page.resource.check_destination')}
          </div>
          <div className={styles.top} onClick={onClose}>
            <IconClose />
          </div>
        </div>

        <div className={styles.body}>
          {getResourceInfo(t('purchase_page.resource.ship_form'), siteName)}
          {getResourceInfo(t('purchase_page.resource.resource'), materialName)}
          {getResourceInfo(
            t('purchase_page.resource.total_weight'),
            `${normalizeNumber({ value: totalWeight })}kg`
          )}
          <div className={styles.quantity}>
            {packingStyles.map((item, index) => {
              return (
                <div className={styles.item} key={index}>
                  <div className={styles.confirmInfo}>
                    <div className={styles.shipping}>
                      <div className={styles.label}>
                        {t('purchase_page.resource.packing_style')}
                      </div>
                      <div className={styles.confirmInfoContent}>
                        {item.packingStyle}
                      </div>
                    </div>
                    <div className={styles.shipping}>
                      <div className={styles.confirmToLabel}>
                        {t('purchase_page.resource.to')}
                      </div>
                      <div className={styles.confirmInfoContent}>
                        {shipping}
                      </div>
                    </div>
                  </div>
                  <div className={styles.confirmInfo}>
                    <div className={styles.label}>
                      {t('purchase_page.resource.quantity')}
                    </div>
                    <div className={styles.confirmInfoContent}>
                      {normalizeNumber({ value: item.total })}
                      {t('purchase_page.resource.pieces')}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.inputForm}>
          <Form
            onSubmit={handleSubmit}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            validate={(values) => {
              if (values.date && values.time.length === 5) {
                const selectedDate = dayjs(values.date + ' ' + values.time);
                return {
                  time: validateDateTime({ compareValue: selectedDate }),
                };
              }
            }}
          >
            {(props) => {
              return (
                <form className={styles.formInput}>
                  <div className={styles.time}>
                    <Label
                      className={styles.label}
                      text={t('purchase_page.resource.shipping_date') || ''}
                      tag={'div'}
                      required
                    />
                    <div className={styles.inputFrame}>
                      <CalendarField
                        minDate={new Date()}
                        placeholder={t(
                          'purchase_page.resource.date_placeholder'
                        )}
                        onChange={(value) => {
                          props.form.mutators.setValue('date', value);
                        }}
                        isDarkMode
                        readOnly
                      />
                      <div className={styles.timeFrame}>
                        <TimeInputField
                          name="time"
                          value={''}
                          isDarkMode={true}
                          onChangeTime={(time) => {
                            props.form.mutators.setValue('time', time);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={styles.submit}>
                    <button
                      className={styles.submitButton}
                      type="submit"
                      disabled={
                        !props.values.date ||
                        props.values.time.length < 5 ||
                        props.invalid
                      }
                      onClick={props.handleSubmit}
                    >
                      {t('purchase_page.resource.register_shipping_btn')}
                    </button>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
      </Modal>
    </section>
  );
};
export default CheckShippingDestination;
