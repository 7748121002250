// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import AdminOperatorTypeForm from 'components/molecules/AdminOperatorTypeForm';
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import { DownloadResourceDataForm, OperatorTypeForm } from 'util/Types';
import styles from './AdminDownloadDataModal.module.scss';
import { useEffect, useRef, useState } from 'react';
import AdminSelectRangeDownloadForm from 'components/molecules/AdminSelectRangeDownloadForm';
import { OperatorType } from 'util/Enums';
import { CSVLink } from 'react-csv';
import { resourseHistoryStatusObject } from 'util/adminResourceManagementTypes';
import { getSlaYMDhm } from 'ts/formatDate';
import { createAxios } from 'ts/createAxios';

type Props = {
  onClose: () => void;
};

export const initDownloadDataForm: DownloadResourceDataForm = {
  operatorType: [],
  startDate: '',
  endDate: '',
};
export type EmissionExportData = {
  categoryName: string;
  emissionDatetime: string;
  emissionId: string;
  operatorId: number;
  operatorName: string;
  operatorType: string;
  siteName: string;
  wasteUnit: string;
  grade: string;
  weight: string;
};

export type CompressionsExportData = {
  categoryName: string;
  registDatetime: string;
  compressionId: string;
  operatorId: number;
  operatorName: string;
  operatorType: string;
  siteName: string;
  grade: string;
  item: string;
  weight: string;
};
export type RecycleExportData = {
  categoryName: string;
  registDatetime: string;
  recycleId: string;
  operatorId: number;
  operatorName: string;
  operatorType: string;
  siteName: string;
  item: string;
  weight: string;
  grade: string;
  materialType: string;
};

export type PurchaseExportData = {
  operatorId: string;
  operatorName: string;
  siteName: string;
  description: string;
  productId: string;
  processingMethod: string;
  packingStyle: string;
  qualityInspectionMethod: string[];
  materialType: string;
  quantity: number;
  weight: string;
  name: string;
  grade: string;
  operatorType: OperatorType;
};

type ExportData = EmissionExportData &
  CompressionsExportData &
  RecycleExportData &
  PurchaseExportData;
const AdminDownloadDataModal: React.FC<Props> = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const [inputDataForm, setInputDataForm] =
    useState<DownloadResourceDataForm>(initDownloadDataForm);
  const [emissionExportData, setEmissionExportData] = useState<
    EmissionExportData[]
  >([]);
  const [compressionsExportData, setCompressionsExportData] = useState<
    CompressionsExportData[]
  >([]);
  const [recycleExportData, setRecycleExportData] = useState<
    RecycleExportData[]
  >([]);
  const [purchaseExportData, setPurchaseExportData] = useState<
    PurchaseExportData[]
  >([]);
  const handleNextStep2 = (operatorForm: OperatorTypeForm) => {
    setInputDataForm({ ...inputDataForm, ...operatorForm });
    setStep(2);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchEmissionRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchCompressionsRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchRecycleRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchPurchaseRef = useRef<any>();
  const [isFetchEmissionDone, setIsFetchEmissionDone] = useState(false);
  const [isFetchCompressionsDone, setIsFetchCompressionsDone] = useState(false);
  const [isFetchRecycleDone, setIsFetchRecycleDone] = useState(false);
  const [isFetchPurchaseDone, setIsFetchPurchaseDone] = useState(false);

  const getData = async (
    operatorType: OperatorType,
    dateFrom: string,
    dateTo: string
  ) => {
    const res: { data: ExportData[] } = await createAxios().get(
      `/resources/${
        operatorType === OperatorType.Producer
          ? 'products'
          : operatorType.toLocaleLowerCase()
      }/export?dateFrom=${dateFrom}&dateTo=${dateTo}`
    );
    return { type: operatorType, data: res.data };
  };
  const handleSubmit = async (dateForm: {
    startDate: string;
    endDate: string;
  }) => {
    const fullForm = { ...inputDataForm, ...dateForm };
    const operatorTypes = fullForm.operatorType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const exportsPromises: any = [];
    operatorTypes.forEach((type) => {
      exportsPromises.push(getData(type, dateForm.startDate, dateForm.endDate));
    });
    const datas: { type: OperatorType; data: ExportData[] }[] =
      await Promise.all(exportsPromises);
    datas.forEach(
      ({ type, data }: { type: OperatorType; data: ExportData[] }) => {
        if (type === OperatorType.Emissions) {
          setEmissionExportData(data);
          setIsFetchEmissionDone(true);
        }
        if (type === OperatorType.Compressions) {
          setCompressionsExportData(data);
          setIsFetchCompressionsDone(true);
        }
        if (type === OperatorType.Recycle) {
          setRecycleExportData(data);
          setIsFetchRecycleDone(true);
        }
        if (type === OperatorType.Producer) {
          setPurchaseExportData(data);
          setIsFetchPurchaseDone(true);
        }
      }
    );
  };

  useEffect(() => {
    if (isFetchEmissionDone) {
      fetchEmissionRef.current.link.click();
      setIsFetchEmissionDone(false);
    }
  }, [isFetchEmissionDone]);

  useEffect(() => {
    if (isFetchCompressionsDone) {
      fetchCompressionsRef.current.link.click();
      setIsFetchCompressionsDone(false);
    }
  }, [isFetchCompressionsDone]);

  useEffect(() => {
    if (isFetchRecycleDone) {
      fetchRecycleRef.current.link.click();
      setIsFetchRecycleDone(false);
    }
  }, [isFetchRecycleDone]);

  useEffect(() => {
    if (isFetchPurchaseDone) {
      fetchPurchaseRef.current.link.click();
      setIsFetchPurchaseDone(false);
    }
  }, [isFetchPurchaseDone]);
  return (
    <div
      className={`${styles.adminDownloadDataModal} ${
        step === 2 ? styles.dateModal : ''
      }`}
    >
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {step === 1 ? 'ダウンロード区分を選択' : 'CSVダウンロード範囲'}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>

          {step === 1 && (
            <AdminOperatorTypeForm
              initOperatorType={inputDataForm}
              onNextStep={handleNextStep2}
            />
          )}

          {step === 2 && (
            <AdminSelectRangeDownloadForm onSubmitDownloadData={handleSubmit} />
          )}
        </div>
      </Modal>
      {
        <CSVLink
          className="hidden"
          filename={'排出' + '.csv'}
          headers={[
            '事業者ID',
            '事業者名',
            '拠点名',
            '登録ID',
            '日付（yyyy/mm/dd hh:mm）',
            'カテゴリ',
            '品目',
            '重量・数量',
            'グレード',
            '区分',
          ]}
          data={emissionExportData.map((item) => {
            return [
              item.operatorId,
              item.operatorName,
              item.siteName,
              item.emissionId,
              item.emissionDatetime ? getSlaYMDhm(item.emissionDatetime) : '-',
              item.categoryName,
              item.wasteUnit,
              item.weight,
              item.grade,
              resourseHistoryStatusObject[item.operatorType],
            ];
          })}
          ref={fetchEmissionRef}
        >
          {'排出.csv'}
        </CSVLink>
      }
      {
        <CSVLink
          className="hidden"
          filename={'中間処理' + '.csv'}
          headers={[
            '事業者ID',
            '事業者名',
            '拠点名',
            '登録ID',
            '日付',
            // 'カテゴリ',
            '品目',
            '重量',
            'グレード',
            '区分',
          ]}
          data={compressionsExportData.map((item) => {
            return [
              item.operatorId,
              item.operatorName,
              item.siteName,
              item.compressionId,
              item.registDatetime ? getSlaYMDhm(item.registDatetime) : '-',
              // item.categoryName,
              item.item,
              item.weight,
              item.grade,
              resourseHistoryStatusObject[item.operatorType],
            ];
          })}
          ref={fetchCompressionsRef}
        >
          {'中間処理.csv'}
        </CSVLink>
      }
      {
        <CSVLink
          className="hidden"
          filename={'リサイクル' + '.csv'}
          headers={[
            '事業者ID',
            '事業者名',
            '拠点名',
            '登録ID',
            '日付',
            // 'カテゴリ',
            '品目',
            '重量',
            'グレード',
            '種別',
            '区分',
          ]}
          data={recycleExportData.map((item) => {
            return [
              item.operatorId,
              item.operatorName,
              item.siteName,
              item.recycleId,
              item.registDatetime ? getSlaYMDhm(item.registDatetime) : '-',
              // item.categoryName,
              item.item,
              item.weight,
              item.grade,
              item.materialType,
              resourseHistoryStatusObject[item.operatorType],
            ];
          })}
          ref={fetchRecycleRef}
        >
          {'リサイクル.csv'}
        </CSVLink>
      }
      {
        <CSVLink
          className="hidden"
          filename={'製品化' + '.csv'}
          headers={[
            '事業者ID',
            '事業者名',
            '拠点名',
            '材料名',
            '登録ID',
            '加工方法',
            '荷姿',
            '品質検査',
            '種別',
            '数量',
            '重量',
            '製品名',
            'グレード',
            '区分',
          ]}
          data={purchaseExportData.map((item) => {
            return [
              item.operatorId,
              item.operatorName,
              item.siteName,
              item.description,
              item.productId,
              item.processingMethod,
              item.packingStyle,
              item.qualityInspectionMethod,
              item.materialType,
              item.quantity,
              item.weight,
              item.name,
              item.grade,
              item.operatorType,
            ];
          })}
          ref={fetchPurchaseRef}
        >
          {'製品化.csv'}
        </CSVLink>
      }
    </div>
  );
};

export default AdminDownloadDataModal;
