// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useLayoutEffect, useState } from 'react';
import _debounce from 'lodash/debounce';

export const useGetWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    windowWidth: number;
    windowHeight: number;
  }>({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  });

  useLayoutEffect(() => {
    const init = () => {
      setWindowSize({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    };

    init();
    const setWindowSizeOnResize = _debounce(init, 200);

    window.addEventListener('resize', setWindowSizeOnResize);

    return () => {
      window.removeEventListener('resize', setWindowSizeOnResize);
    };
  }, []);

  return windowSize;
};
