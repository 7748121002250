import { useFetchTrucksQuery } from 'apis/collect_user/collectUserApi';
import { Modal } from 'components/atoms/Modal';
import { IconClose } from 'components/atoms/icons/IconClose';
import { SelectField } from 'components/molecules/SelectField';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { TSelectTruckModal } from 'types/Collect';
import { TModal } from 'types/modal';
import { SelectOption } from '../EditCompressionsResourceDataModal';
import styles from './CollectEmissionsSelectTruckModal.module.scss';

type TruckModalProps = TModal & {
  isOpen: boolean;
  site: SelectOption;
  onSubmit: (values: TSelectTruckModal) => void;
};

const CollectEmissionsSelectTruckModal = ({
  isOpen,
  site,
  closeModal,
  onSubmit,
}: TruckModalProps) => {
  const { t } = useTranslation();

  const { truckSelect } = useFetchTrucksQuery(
    { siteId: site.value },
    {
      skip: !isOpen,
      selectFromResult: ({ data }) => {
        return {
          truckSelect:
            data?.trucks.map((s) => ({
              label: s.truckNumber,
              value: s.id,
            })) || [],
        };
      },
    }
  );

  return (
    <section className={styles['truck-modal']}>
      <Modal>
        <div className={styles['customTitle']}>
          <div className={styles['title']}>
            {t('collect_registration.trucks_modal.title')}
          </div>
          <div className={styles['top']} onClick={closeModal}>
            <IconClose />
          </div>
        </div>
        <div className={styles['form-body']}>
          <Form<TSelectTruckModal>
            onSubmit={(values: TSelectTruckModal) => {
              onSubmit(values);
            }}
            render={({ handleSubmit, invalid }) => (
              <form onSubmit={handleSubmit} className={styles['form-body']}>
                <div className={styles['selectBox']}>
                  <SelectField
                    name="siteId"
                    options={[site]}
                    label={t('collect_registration.trucks_modal.site_id')}
                    placeholder={t('collect_registration.trucks_modal.site_id')}
                    value={site.value.toString()}
                    isDarkmode
                    disabled
                    required
                  />
                </div>
                <div className={styles['selectBox']}>
                  <SelectField
                    name="truckId"
                    options={truckSelect}
                    label={t('collect_registration.trucks_modal.truck_id')}
                    placeholder={t(
                      'collect_registration.trucks_modal.truck_id'
                    )}
                    isDarkmode
                  />
                </div>
                <div className={styles['submit']}>
                  <button
                    className={styles['submitButton']}
                    type="submit"
                    disabled={invalid}
                  >
                    {t('collect_registration.button_to_recover')}
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </Modal>
    </section>
  );
};

export default CollectEmissionsSelectTruckModal;
