// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { CategoryItem, ItemType } from 'util/Types';
import styles from './index.module.scss';
import { IconFixedItem } from 'components/atoms/icons/IconFixedItem';
import { ItemSize } from 'util/Enums';
import OfflineFirstImage from '../OfflineFirstImage';
import { getUnitLabel, mergeClasses } from 'util/commons';

export type Props = {
  item: CategoryItem;
  onClick?: (emission: CategoryItem) => void;
  classNameSelectSize?: string;
  className?: string;
};

const EmissionChildSelect = ({ item, onClick, className }: Props) => {
  return (
    <div
      className={
        className ??
        mergeClasses(styles.item, {
          [styles.itemMedium]: item.size === ItemSize.MEDIUM,
          [styles.itemSmall]: item.size === ItemSize.SMALL,
        })
      }
    >
      <div
        className={mergeClasses(styles.item_content, {
          [styles.item_contentMedium]: item.size === ItemSize.MEDIUM,
          [styles.item_contentSmall]: item.size === ItemSize.SMALL,
        })}
        onClick={() => onClick && onClick(item)}
      >
        {item.imageUrl && (
          <div className={styles.imageWrapper}>
            <OfflineFirstImage
              cacheBucket="categoryImages"
              src={item.imageUrl}
            />
          </div>
        )}

        <div
          className={mergeClasses(styles.customizeItemBody, {
            [styles.customizeItemNameFix]: item.type === ItemType.FIX,
          })}
        >
          <div
            className={mergeClasses(styles.customizeItemName, {
              [styles.itemNameMedium]: item.size === ItemSize.MEDIUM,
              [styles.itemNameSmall]: item.size === ItemSize.SMALL,
            })}
          >
            {item.name}
          </div>

          {item.type === ItemType.FIX && (
            <div
              className={mergeClasses(styles.customizeItemQuantity, {
                [styles.ellipseText1]:
                  item.size === ItemSize.MEDIUM || item.size === ItemSize.SMALL,
              })}
            >{`${item.convertKg}kg/${item.quantity}${getUnitLabel(
              item.unit
            )}`}</div>
          )}
        </div>

        {item.type === ItemType.FIX && (
          <div className={styles.optionsArea}>
            <div className={`${styles.itemType}`}>
              <IconFixedItem />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmissionChildSelect;
