// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
const IconHistory = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="history"
    >
      <path
        d="M4 5V8.5C4 8.77614 4.22386 9 4.5 9H8"
        stroke="#6F7175"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2106 9.03426C5.52017 8.97497 5.77969 8.7708 5.93761 8.49801C7.14823 6.4068 9.40982 5 12 5C15.866 5 19 8.13401 19 12C19 15.866 15.866 19 12 19C9.04612 19 6.51958 17.1704 5.49181 14.5826C5.26908 14.0218 4.64892 13.681 4.08596 13.8982C3.62236 14.0771 3.3598 14.5765 3.52785 15.0442C4.77575 18.5167 8.09786 21 12 21C16.9705 21 21 16.9706 21 12C21 7.02944 16.9705 3 12 3C8.52495 3 5.50991 4.96948 4.01019 7.85313C3.66312 8.52046 4.27287 9.21384 5.01163 9.07236L5.2106 9.03426Z"
        fill="currentColor"
      />
      <path
        d="M12 8V11.7929C12 11.9255 12.0527 12.0527 12.1464 12.1464L14.5 14.5"
        stroke="#6F7175"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default IconHistory;
