// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
const IconUnitKg = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 10H12.0274H13.8185H19.1814H20.9725H22L24.2099 10L25.7654 24H6.23449L7.79005 10L11 10ZM11 8H12.0274C12.2761 5.75002 14.1837 4 16.5 4C18.8162 4 20.7238 5.75002 20.9725 8H22H24.2099C25.229 8 26.0851 8.76627 26.1976 9.77914L27.7532 23.7791C27.8848 24.9639 26.9575 26 25.7654 26H6.23449C5.04247 26 4.11509 24.9639 4.24672 23.7791L5.80228 9.77914C5.91482 8.76627 6.77095 8 7.79005 8H11ZM18.9503 8C18.7195 6.85963 17.7089 6 16.5 6C15.291 6 14.2804 6.85963 14.0496 8H18.9503Z"
        fill="#878787"
      />
      <path
        d="M10.0448 21V13.6H11.5248V16.81H11.5548L14.0048 13.6H15.6348L13.3748 16.51L16.0348 21H14.4048L12.4948 17.68L11.5248 18.92V21H10.0448ZM19.7873 21.14C17.7673 21.14 16.2373 19.8 16.2373 17.33C16.2373 14.89 17.8173 13.46 19.7873 13.46C20.8573 13.46 21.5973 13.92 22.0773 14.41L21.2873 15.35C20.9273 15 20.5073 14.73 19.8373 14.73C18.6073 14.73 17.7473 15.69 17.7473 17.28C17.7473 18.89 18.4873 19.86 19.9373 19.86C20.2973 19.86 20.6573 19.76 20.8673 19.58V18.12H19.5573V16.91H22.1873V20.25C21.6873 20.74 20.8173 21.14 19.7873 21.14Z"
        fill="#878787"
      />
    </svg>
  );
};

export default IconUnitKg;
