// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
const IconUnitT = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 10H12.0274H13.8185H19.1814H20.9725H22L24.2099 10L25.7654 24H6.23449L7.79005 10L11 10ZM11 8H12.0274C12.2761 5.75002 14.1837 4 16.5 4C18.8162 4 20.7238 5.75002 20.9725 8H22H24.2099C25.229 8 26.0851 8.76627 26.1976 9.77914L27.7532 23.7791C27.8848 24.9639 26.9575 26 25.7654 26H6.23449C5.04247 26 4.11509 24.9639 4.24672 23.7791L5.80228 9.77914C5.91482 8.76627 6.77095 8 7.79005 8H11ZM18.9503 8C18.7195 6.85963 17.7089 6 16.5 6C15.291 6 14.2804 6.85963 14.0496 8H18.9503Z"
        fill="#878787"
      />
      <path
        d="M17.2404 21.14C15.9104 21.14 15.4004 20.3 15.4004 19.04V16.56H14.6104V15.47L15.4704 15.4L15.6404 13.9H16.8704V15.4H18.2504V16.56H16.8704V19.04C16.8704 19.68 17.1304 19.98 17.6304 19.98C17.8104 19.98 18.0304 19.92 18.1804 19.86L18.4204 20.93C18.1204 21.03 17.7304 21.14 17.2404 21.14Z"
        fill="#878787"
      />
    </svg>
  );
};

export default IconUnitT;
