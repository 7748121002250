// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './HistoryRecycleCompletes.module.scss';
import { useEffect, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import { LabelContents } from 'components/molecules/LabelContents';
import { RecycleCompleteData } from 'util/Types';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { RecycleCompleteEditModal } from 'components/organisms/RecycleCompleteEditModal';
import { useTranslation } from 'react-i18next';
import { BottomBtn } from 'components/molecules/BottomBtn';
import { DATE_FORMAT } from 'util/ConstantValues';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { normalizeNumber } from 'util/commons';

export type Props = {
  id?: string;
};

export const HistoryRecycleCompletes = () => {
  const commonsApi = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const [recycleCompleteData, setRecycleCompleteData] = useState<
    RecycleCompleteData[]
  >([]);
  const user = useSelector(selectUser);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const callApi = async () => {
    const res = await commonsApi.get('/work-histories/recycle/complete');
    setRecycleCompleteData(res.data);
  };
  const { t } = useTranslation();

  useEffect(() => {
    callApi();
  }, []);

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  return (
    <div className={styles.historyRecycleCompletes}>
      <div className="scroll-contents">
        {recycleCompleteData &&
          recycleCompleteData.length > 0 &&
          recycleCompleteData.map((item, index) => {
            return (
              <div className={styles.commonCard} key={index}>
                <LabelContents
                  label={t('common_history_field.date_time')}
                  contents={getFormatDateWithCurrentTimezone(
                    item.datetime,
                    DATE_FORMAT.slaYMDHm
                  )}
                ></LabelContents>
                <LabelContents
                  label={t('common_history_field.responsible_person')}
                  contents={item.userId + '(' + item.userName + ')'}
                ></LabelContents>
                <LabelContents
                  label={t('common_history_field.material_name')}
                  contents={item.description}
                ></LabelContents>
                <LabelContents
                  label={t('common_history_field.type')}
                  contents={item.materialType.name}
                ></LabelContents>
                <LabelContents
                  label={t('common_history_field.quality')}
                  contents={item.grade.name}
                ></LabelContents>
                <LabelContents
                  label={t('common_history_field.weight')}
                  contents={`${normalizeNumber({ value: item.weight })}kg`}
                ></LabelContents>
                <LabelContents
                  label={t('common_history_field.registration_id')}
                  contents={item.targetIds.join('\n')}
                ></LabelContents>
              </div>
            );
          })}
      </div>

      <BottomBtn
        text={t('common.button.edit_product_data')}
        onClick={() => {
          setIsEditModalOpen(true);
        }}
        disabled={recycleCompleteData.length === 0}
      ></BottomBtn>

      {isEditModalOpen && recycleCompleteData.length > 0 && (
        <RecycleCompleteEditModal
          isOpen={isEditModalOpen}
          closeEditModal={closeEditModal}
          afterSubmit={() => {
            callApi();
          }}
          recycleCompleteData={recycleCompleteData.filter(
            (i) => i.siteId === user.siteId
          )}
        />
      )}
    </div>
  );
};
