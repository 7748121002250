// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useRef, useState } from 'react';
import { Field, FieldInputProps } from 'react-final-form';
import { Label } from 'components/atoms/Label';
import { ErrorText } from 'components/atoms/ErrorText';
import useOutsideClick from 'util/hooks/useClickOutside';
import styles from './SelectField.module.scss';
import { IconDropDown } from 'components/atoms/icons/IconDropDown';
import { FieldValidator } from 'final-form';
import Portal from 'components/atoms/Portal';
import { useGetBoundingClientRect } from 'util/hooks/useGetBoundingClientRect';
import { mergeClasses } from 'util/commons';

export interface SelectOption {
  label: string;
  value: string | number;
  type?: string;
}

interface lastRecordOptions {
  isLastRecord: boolean;
  onOpenPulldown: (value: boolean) => void;
}

interface Props {
  className?: string;
  name: string;
  options: SelectOption[];
  label?: string | null;
  value?: string;
  required?: boolean;
  hint?: React.ReactNode;
  placeholder?: string | null;
  disabled?: boolean;
  onChange?: (value: string | number) => void;
  validate?: FieldValidator<string>;
  isDarkmode?: boolean;
  isLanguage?: boolean;
  showPlaceholderOption?: boolean;
  lastRecord?: lastRecordOptions;
  showValue?: boolean;
  usePortal?: boolean;
}

export function SelectField({
  label,
  name,
  value,
  placeholder,
  options,
  disabled,
  className,
  onChange,
  required,
  validate,
  isDarkmode,
  isLanguage,
  showPlaceholderOption,
  lastRecord,
  showValue,
  usePortal,
}: Props) {
  const [inputValue, setInputValue] = useState<{
    label: string;
    value: string | number;
  }>();
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const optionListRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    value
      ? setInputValue(
          options.find((option) => String(option.value) === value) || undefined
        )
      : setInputValue(undefined);
  }, [value]);

  useEffect(() => {
    if (lastRecord) lastRecord.onOpenPulldown(isOpenOptions);
    if (isOpenOptions && lastRecord?.isLastRecord)
      optionListRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
  }, [isOpenOptions]);

  const { ref: dropDownRef } = useOutsideClick({
    onClickOutside: () => {
      setIsOpenOptions(false);
    },
    exceptRefs: [containerRef],
  });

  const { domRect } = useGetBoundingClientRect(
    {
      el: containerRef.current,
    },
    [isOpenOptions]
  );

  const renderOptions = (input: FieldInputProps<string, HTMLElement>) => (
    <ul
      ref={optionListRef}
      className={mergeClasses(styles.SelectOptionsList, {
        [styles.DarkSelectOptionsList]: isDarkmode,
      })}
    >
      {!isLanguage && placeholder && !showPlaceholderOption && (
        <li
          className={`${!inputValue?.value ? styles.selected : ''} `}
          onClick={() => {
            input.onChange('');
            setInputValue(undefined);
            onChange && onChange('');
          }}
        >
          {placeholder}
        </li>
      )}
      {options.map((option, i) => {
        return (
          <li
            className={`${
              option.value === inputValue?.value ? styles.selected : ''
            }`}
            key={i}
            title={option.label}
            onClick={() => {
              input.onChange(String(option.value));
              setInputValue(option);
              onChange && onChange(String(option.value));
            }}
          >
            {option.label}
          </li>
        );
      })}
    </ul>
  );

  const renderOptionsList = (input: FieldInputProps<string, HTMLElement>) => {
    if (!isOpenOptions) {
      return null;
    }

    if (usePortal && domRect) {
      return (
        <Portal>
          <div ref={dropDownRef}>
            <div
              style={{
                position: 'absolute',
                top: domRect.top + domRect.height + 4,
                left: domRect.left,
                width: domRect.width,
              }}
            >
              {renderOptions(input)}
            </div>
          </div>
        </Portal>
      );
    }

    return renderOptions(input);
  };

  return (
    <Field name={name} value={value} validate={validate}>
      {({ input, meta }) => {
        const displayedOption = options.find(
          (o) =>
            String(o.value) === String(input.value) ||
            String(o.value) === String(value)
        );

        if (displayedOption) {
          setInputValue(displayedOption);
        }

        return (
          <div
            ref={usePortal ? containerRef : dropDownRef}
            className={`${className ?? styles.selectField} ${
              isDarkmode ? styles.selectFieldDarkmode : ''
            }`}
            onClick={() => {
              if (!disabled) {
                options.length > 0 && setIsOpenOptions(!isOpenOptions);
              }
            }}
          >
            {label && <Label text={label} tag={'div'} required={required} />}

            <div className={styles.select}>
              <input
                {...input}
                value={
                  (isLanguage || showValue
                    ? placeholder
                    : displayedOption?.label || placeholder) ?? undefined
                }
                className={`${styles.input} ${
                  !inputValue ? styles.empty : ''
                } ${
                  meta &&
                  (meta.touched || meta.modified) &&
                  meta.error &&
                  !isOpenOptions
                    ? styles.inputError
                    : ''
                } ${!displayedOption ? styles.placeHolder : ''} ${
                  disabled ? styles.inputDisable : ''
                }`}
                readOnly
                disabled={disabled}
              />
              {renderOptionsList(input)}
              <IconDropDown />
            </div>

            {meta &&
              (meta.touched || meta.modified) &&
              meta.error &&
              !isOpenOptions && (
                <ErrorText text={meta.error} className={styles.error} />
              )}
          </div>
        );
      }}
    </Field>
  );
}
