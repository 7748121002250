// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { parseCookies, setCookie, destroyCookie } from 'nookies';
import { StorageKeys } from './Enums';

export enum CookiesKeys {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
}

class CookiesAuth {
  getCookies() {
    return parseCookies();
  }

  set accessToken(value: string) {
    setCookie(null, CookiesKeys.ACCESS_TOKEN, value, { path: '/' });
  }

  get accessToken(): string {
    return this.getCookies()[CookiesKeys.ACCESS_TOKEN];
  }

  set refreshToken(value: string) {
    setCookie(null, CookiesKeys.REFRESH_TOKEN, value, { path: '/' });
  }

  get refreshToken(): string {
    return this.getCookies()[CookiesKeys.REFRESH_TOKEN];
  }

  login(params: { accessToken: string; refreshToken: string }) {
    this.accessToken = params.accessToken;
    this.refreshToken = params.refreshToken;
  }

  logout() {
    destroyCookie(null, CookiesKeys.ACCESS_TOKEN, { path: '/' });
    destroyCookie(null, CookiesKeys.REFRESH_TOKEN, { path: '/' });
    localStorage.removeItem(StorageKeys.COLORS_SETTING);
  }
}

export default new CookiesAuth();
