// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { InputString } from 'util/Types';
import {
  SiteRecycleFetchResponse,
  SiteRecycleItemUpdateRequest,
} from 'util/siteManagementTypes';
import { apiService } from './siteApi';

const addTagTypes = ['siteRecycle'] as const;
const siteRecycleApi = apiService
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchRecycleDetail: build.query<SiteRecycleFetchResponse, InputString>({
        query: (id) => ({
          url: `/sites/${id}`,
          method: 'GET',
        }),
        providesTags: ['siteRecycle'],
      }),
      createRecycle: build.mutation({
        query: (args) => ({
          url: `/sites/recycle?operatorId=${args.operatorId}`,
          method: 'POST',
          data: args.data,
        }),
        invalidatesTags: (result, error) => (error ? [] : ['siteRecycle']),
      }),
      updateRecycle: build.mutation<string, SiteRecycleItemUpdateRequest>({
        query: (args) => ({
          url: `/sites/recycle/${args.siteId}`,
          method: 'PUT',
          data: args.data,
        }),
        invalidatesTags: (result, error) => (error ? [] : ['siteRecycle']),
      }),
    }),
  });

export const {
  useFetchRecycleDetailQuery,
  useCreateRecycleMutation,
  useUpdateRecycleMutation,
} = siteRecycleApi;
