// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { OptionItem } from 'components/molecules/SelectFieldMultipleChoise';
import { createAxios } from 'ts/createAxios';
import { Station } from 'util/Types';
import { API_ENDPOINTS } from 'util/endPoints';
import { handleError } from 'util/errorHandler';
import { EdiCategoryType } from 'util/siteManagementTypes';

export const fetchEdiCategories = async (
  successCallback?: (data: EdiCategoryType) => void
) => {
  const api = createAxios();
  const { data } = await api.get<EdiCategoryType>(
    API_ENDPOINTS.COMMONS_EDI_CATEGORIES
  );
  successCallback?.(data);
};

export const getStations = async (
  successCallback?: (data: Station[]) => void
) => {
  const api = createAxios();
  try {
    const { data } = await api.get<Station[]>(API_ENDPOINTS.COMMONS_STATIONS);
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const getOperatorPools = async (
  successCallback?: (data: { id: number }[]) => void
) => {
  const api = createAxios();
  try {
    const { data } = await api.get<{ id: number }[]>(
      API_ENDPOINTS.COMMONS_OPERATOR_POOLS
    );
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const getSiteCollects = async (
  successCallback?: (data: OptionItem[]) => void
) => {
  const api = createAxios();
  try {
    const { data } = await api.get<OptionItem[]>(
      API_ENDPOINTS.COMMONS_SITE_COLLECTS
    );
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const getMaterialNames = async (params?: {
  isEmissionAll?: boolean;
  successCallback?: (
    data: { materialName: string; englishMaterialName: string }[]
  ) => void;
}) => {
  const { successCallback, ...apiParams } = params ?? {};

  const api = createAxios();
  try {
    const { data } = await api.get<
      { materialName: string; englishMaterialName: string }[]
    >(API_ENDPOINTS.COMMONS_MATERIAL_NAMES, {
      params: apiParams,
    });
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const getRecycleMethods = async (
  successCallback?: (data: (OptionItem & { englishName: string })[]) => void
) => {
  const api = createAxios();
  try {
    const { data } = await api.get<(OptionItem & { englishName: string })[]>(
      API_ENDPOINTS.COMMONS_RECYCLE_METHODS
    );
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};
