// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { ModalType, OperatorType, RoleType } from './Enums';
import { Station } from './Types';

export type ResponseAccount = {
  page: number;
  total: number;
  users: Account[];
};

export type Operator = {
  id: string;
  type: string;
};

export type Account = {
  id: string;
  name: string;
  type: string;
  operator: Operator;
  tenantId: string;
  truckId: string;
  operatorPoolId: string;
  userStations: Station[];
  siteId?: string;
};

export const OperatorOption = [
  { value: 1, label: '排出' },
  { value: 2, label: '回収' },
  { value: 3, label: '中間処理 ' },
  { value: 4, label: '配送' },
  { value: 5, label: 'リサイクル' },
  { value: 6, label: 'ブランド企業' },
  { value: 7, label: 'Recotech管理者' },
];

export const MonthOptions = [
  { value: '1', label: '1月' },
  { value: '2', label: '2月' },
  { value: '3', label: '3月' },
  { value: '4', label: '4月' },
  { value: '5', label: '5月' },
  { value: '6', label: '6月' },
  { value: '7', label: '7月' },
  { value: '8', label: '8月' },
  { value: '9', label: '9月' },
  { value: '10', label: '10月' },
  { value: '11', label: '11月' },
  { value: '12', label: '12月' },
];

export type AccountForm = {
  id: string;
  password: string;
  operatorType: OperatorType | string;
  role: RoleType | string;
  operatorId: string;
  name: string;
  truckId: string;
  sites?: { id: string; name: string; operatorId?: string }[];
  siteId?: string;
  operatorPoolId?: string;
  stationIds?: number[];
  tenantId?: string;
};

export type ModalAccountState = {
  isOpen: boolean;
  type: ModalType;
  accountEditing?: Account;
};

export const baseAccountForm = {
  id: '',
  password: '',
  operatorType: '',
  role: '',
  operatorId: '',
  siteId: '',
  name: '',
  truckId: '',
};

export const listOperatorType = [
  {
    value: OperatorType.Emissions,
    label: '排出',
    i18nLabel: 'operator_list.emissions',
  },
  {
    value: OperatorType.Collects,
    label: '回収',
    i18nLabel: 'operator_list.collects',
  },
  {
    value: OperatorType.Compressions,
    label: '中間処理',
    i18nLabel: 'operator_list.compressions',
  },
  {
    value: OperatorType.Recycle,
    label: 'リサイクル',
    i18nLabel: 'operator_list.recycle',
  },
  {
    value: OperatorType.Purchase,
    label: '調達',
    i18nLabel: 'operator_list.purchase',
  },
  {
    value: OperatorType.Brand,
    label: 'ブランド企業',
    i18nLabel: 'operator_list.brand',
  },
  {
    value: OperatorType.Admin,
    label: 'Recotech管理者',
    i18nLabel: 'operator_list.admin',
  },
  {
    value: OperatorType.AdminPool,
    label: 'pool管理者',
    i18nLabel: 'operator_list.pool_admin',
  },
];

export const roleOptions = [
  { value: RoleType.AdminOperator, label: '事業者管理者' },
  { value: RoleType.AdminSite, label: '拠点管理者' },
  { value: RoleType.UserNormal, label: '作業担当者' },
  { value: RoleType.BrandOwner, label: 'ブランドオーナー' },
  { value: RoleType.AdminRecotech, label: 'Recotech管理者' },
  { value: RoleType.AdminPool, label: 'pool管理者' },
  { value: RoleType.AdminTenant, label: 'テナント管理者' },
];

export const roleMapper: Record<RoleType, { value: RoleType; label: string }> =
  roleOptions.reduce(
    (res: Record<string, { value: RoleType; label: string }>, item) => {
      res[item.value] = item;

      return res;
    },
    {}
  );

export const emissionsRoleOptions = [
  roleMapper.AdminOperator,
  roleMapper.AdminSite,
  roleMapper.UserNormal,
  roleMapper.AdminTenant,
];
export const collectRoleOptions = [
  roleMapper.AdminOperator,
  roleMapper.UserNormal,
];
export const compressionsRoleOptions = [roleMapper.AdminOperator];
export const recycleRoleOptions = [roleMapper.AdminOperator];
export const brandRoleOptions = [roleMapper.BrandOwner];
export const adminRoleOptions = [roleMapper.AdminRecotech];
export const purchaseRoleOptions = [roleMapper.AdminOperator];
export const adminPoolRoleOptions = [roleMapper.AdminPool];

const roleOptionsObject = {
  [OperatorType.Admin]: adminRoleOptions,
  [OperatorType.Collects]: collectRoleOptions,
  [OperatorType.Compressions]: compressionsRoleOptions,
  [OperatorType.Emissions]: emissionsRoleOptions,
  [OperatorType.Recycle]: recycleRoleOptions,
  [OperatorType.Purchase]: purchaseRoleOptions,
  [OperatorType.Brand]: brandRoleOptions,
  [OperatorType.AdminPool]: adminPoolRoleOptions,
};
export const getRoleOptions = (operatorType: OperatorType | string) => {
  return roleOptionsObject[operatorType] || [];
};

export interface AccountOperator {
  id: string;
  name: string;
}

export type FilterObj = {
  operatorType: string;
  year: string;
  month: string;
};
