// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
export function multiplication(value1, value2) {
  // それぞれの小数点の位置を取得
  const dotPosition1 = getDotPosition(value1);
  const dotPosition2 = getDotPosition(value2);

  // 位置の値が大きい方（小数点以下の位が多い方）の位置を取得
  const max = Math.max(dotPosition1, dotPosition2);

  // 大きい方に小数の桁を合わせて文字列化、
  // 小数点を除いて整数の値にする
  const intValue1 = parseInt(
    (parseFloat(value1).toFixed(max) + '').replace('.', '')
  );
  const intValue2 = parseInt(
    (parseFloat(value2).toFixed(max) + '').replace('.', '')
  );

  // 10^N の値を計算
  const power = Math.pow(10, max);

  // 整数値で掛けた後に10^Nで割る
  return (intValue1 * intValue2) / power;
}

export function addition(value1, value2) {
  if (!value1) {
    return value2;
  } else if (!value2) {
    return value1;
  }
  // それぞれの小数点の位置を取得
  const dotPosition1 = getDotPosition(value1);
  const dotPosition2 = getDotPosition(value2);

  // 位置の値が大きい方（小数点以下の位が多い方）の位置を取得
  const max = Math.max(dotPosition1, dotPosition2);

  // 大きい方に小数の桁を合わせて文字列化、
  // 小数点を除いて整数の値にする
  const intValue1 = parseInt(
    (parseFloat(value1).toFixed(max) + '').replace('.', '')
  );
  const intValue2 = parseInt(
    (parseFloat(value2).toFixed(max) + '').replace('.', '')
  );

  // 10^N の値を計算
  const power = Math.pow(10, max);

  // 整数値で引き算した後に10^Nで割る
  return (intValue1 + intValue2) / power;
}

function getDotPosition(value) {
  // 数値のままだと操作できないので文字列化する
  const strVal = String(value);
  let dotPosition = 0;

  // 小数点が存在するか確認
  if (strVal.lastIndexOf('.') !== -1) {
    // 小数点があったら位置を取得
    dotPosition = strVal.length - 1 - strVal.lastIndexOf('.');
  }

  return dotPosition;
}
