// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useEffect, useMemo, useState } from 'react';
import { getUtcFormatDateTime } from 'ts/formatDate';
import { UncollectEmission } from 'util/Types';
import { CustomCheckBox } from 'components/atoms/CustomCheckbox';
import styles from './UnCollectEmissions.module.scss';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from 'util/ConstantValues';
import useOutsideClick from 'util/hooks/useClickOutside';
import { FilterItemDropDown } from 'components/molecules/FilterItemDropDown';
import UnCollectChainTag from 'components/atoms/UnCollectChainTag';
import { normalizeNumber } from 'util/commons';

interface Props {
  emissions: UncollectEmission[];
  descriptionOptions: string[];
  selectedDescriptions: string[];
  onSetSelectedDescriptions: (description: string[]) => void;
  onChange?: (checkedItems: string[]) => void;
  itemOffset?: number;
}

export function UnCollectEmissions({
  emissions,
  onChange,
  onSetSelectedDescriptions,
  descriptionOptions,
  selectedDescriptions,
  itemOffset,
}: Props) {
  const [checkedItems, setCheckedItems] = useState({});
  const [allCheckedFlg, setAllCheckedFlg] = useState(false);
  const [openEmissionItemsDropDown, setOpenEmissionItemsDropDown] =
    useState<boolean>(false);

  const { t } = useTranslation();

  const checkableEmissions = useMemo(
    () => emissions.filter(({ nextStage }) => nextStage),
    [emissions]
  );

  const onClickOutside = () => {
    setOpenEmissionItemsDropDown(false);
  };
  const { ref } = useOutsideClick({ onClickOutside });

  useEffect(() => {
    const checkedList = Object.keys(checkedItems).filter(
      (key) => checkedItems[key] == true
    );

    if (onChange) {
      onChange(checkedList);
    }

    setAllCheckedFlg(
      checkedList.length > 0 && checkedList.length >= checkableEmissions.length
    );
  }, [checkedItems, setAllCheckedFlg]);

  useEffect(() => {
    setCheckedItems({});
    setAllCheckedFlg(!allCheckedFlg);
  }, [itemOffset]);

  const updateSelectedDescriptions = (emissionDescription: string) => {
    const selectEmissionItems = selectedDescriptions.includes(
      emissionDescription
    )
      ? selectedDescriptions.filter(
          (description) => description !== emissionDescription
        )
      : [...selectedDescriptions, emissionDescription];
    onSetSelectedDescriptions(selectEmissionItems);
  };

  const handleChange = (e) => {
    setCheckedItems({
      ...checkedItems,
      [e.target.id]: e.target.checked,
    });
  };

  const handleOpenEmissionItemsDropDown = () => {
    descriptionOptions.length > 0 && setOpenEmissionItemsDropDown(true);
  };

  const allChecked = () => {
    if (allCheckedFlg) {
      // 全チェック -> 全チェック外し
      Object.keys(checkedItems).forEach(function (key) {
        checkedItems[key] = false;
      });
      setCheckedItems(checkedItems);
      if (onChange) {
        onChange([]);
      }
    } else {
      checkableEmissions.forEach(function (emission) {
        checkedItems[emission.id] = true;
      });
      // 全チェック
      setCheckedItems(checkedItems);
      if (onChange) {
        onChange(checkableEmissions.map((v) => v.id));
      }
    }
    setAllCheckedFlg(!allCheckedFlg);
  };

  return (
    <div className={styles.unCollectList}>
      <table className={`${styles.tableUnCollectList} ${'primary-table'}`}>
        <thead>
          <tr>
            <th onClick={allChecked} className={styles.selection}>
              <div className={styles.checkAllContainer}>
                <span className={styles.checkAll}>
                  {t('common.collective_selection')}
                </span>
              </div>
            </th>
            <th className={styles.description}>
              <div className={styles.descriptionContainer} ref={ref}>
                <span className={styles.itemName}>{t('common.item')}</span>
                <div className={styles.dropDownBntContainer}>
                  <FilterItemDropDown
                    isOpen={openEmissionItemsDropDown}
                    handleOpen={handleOpenEmissionItemsDropDown}
                    descriptionOptions={descriptionOptions}
                    selectedDescriptions={selectedDescriptions}
                    value={'common.filter_by_item'}
                    selectEmissionItem={updateSelectedDescriptions}
                  />
                </div>
              </div>
            </th>
            <th className={styles.weight}>{t('common.weight')}</th>
            <th className={styles.datetime}>
              {t('history_emission_card.card_discharge_date_time')}
            </th>
          </tr>
        </thead>
        <tbody>
          {emissions && emissions.length > 0 ? (
            emissions.map((emission, i) => {
              return (
                <tr key={i}>
                  <td className={styles.selection}>
                    {emission.nextStage && (
                      <CustomCheckBox
                        id={emission.id}
                        checked={checkedItems[emission.id] ?? false}
                        onChange={handleChange}
                        darkMode
                      />
                    )}
                  </td>
                  <td className={styles.description}>
                    <div className={styles.unCollectContent}>
                      <p>{emission.description}</p>
                      <UnCollectChainTag
                        collectionOperatorName={emission.collectionOperatorName}
                        emissionStatus={emission.emissionStatus}
                      />
                    </div>
                  </td>
                  <td className={styles.weight}>
                    {normalizeNumber({ value: emission.weight })}&nbsp;kg
                  </td>
                  <td className={styles.datetime}>
                    {/* BE return time with JP timezone but has Z in the end of string -> must show UTC to keep data correct */}
                    {getUtcFormatDateTime(
                      emission.datetime,
                      DATE_FORMAT.slaYMDHm
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={4} className={styles.msgError}>
                {t('messages.M_008')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
