import { apiService } from 'apis/site_recycle/siteApi';
import { PreRegisResource } from 'util/Types';
import { getApiPath } from 'util/commons';
import { API_ENDPOINTS } from 'util/endPoints';

const addTagTypes = ['recycle_booking', 'compression_booking'] as const;

const bookingApi = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchRecycleBookingByQr: build.query({
        query: (qrCode: string) => ({
          url: getApiPath(API_ENDPOINTS.RECYCLE_BOOKING_BY_QR, { qr: qrCode }),
          method: 'GET',
        }),
        forceRefetch: () => true,
        providesTags: ['recycle_booking'],
      }),
      registRecycleReceive: build.mutation({
        query: (data) => ({
          url: API_ENDPOINTS.RECYCLE_REGIST_TEMP_RECEIVE,
          method: 'POST',
          data: data,
        }),
        invalidatesTags: (result, error) => (error ? [] : ['recycle_booking']),
      }),
      fetchCompressionBookingByQr: build.query({
        query: (qrCode: string) => ({
          url: getApiPath(API_ENDPOINTS.COMPRESSION_BOOKING_BY_QR, {
            qrCode: qrCode,
          }),
          method: 'GET',
        }),
        providesTags: ['compression_booking'],
      }),
      registCompressionReceive: build.mutation({
        query: (data) => ({
          url: API_ENDPOINTS.COMPRESSION_REGIST_TEMP_RECEIVE,
          method: 'POST',
          data: data,
        }),
        invalidatesTags: (result, error) =>
          error ? [] : ['compression_booking'],
      }),
      fetchRecycleInputResources: build.query<PreRegisResource[], void>({
        query: () => ({
          url: API_ENDPOINTS.RECYCLE_INPUT_RESOURCES,
          method: 'GET',
        }),
      }),
    }),
  });

export const {
  useFetchRecycleBookingByQrQuery,
  useRegistRecycleReceiveMutation,
  useFetchCompressionBookingByQrQuery,
  useRegistCompressionReceiveMutation,
  useFetchRecycleInputResourcesQuery,
} = bookingApi;
