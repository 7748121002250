// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useTranslation } from 'react-i18next';
import DateTimeUnitTabButton from '../DateTimeUnitTabButton';
import styles from './StackedBarChartZone.module.scss';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import {
  generateColorPalette,
  mergeClasses,
  normalizeNumber,
} from 'util/commons';
import { DateTypeRange } from 'util/Enums';
import _uniqBy from 'lodash/uniqBy';
import { ChartOptions } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useMemo, useState } from 'react';
import { PluginMode } from '../DonutChart';
import { Modal } from 'components/atoms/Modal';
import { IconCloseTablet } from 'components/atoms/icons/IconCloseTablet';
import DashboardDownloadModal from '../DashboardDownloadModal';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import { DATE_FORMAT, LAYOUT_BREAK_POINTS } from 'util/ConstantValues';
import downloadjs from 'downloadjs';
import { useGetWindowSize } from 'util/hooks/useGetWindowSize';

export type TimeStackedData = {
  date: string;
  day: number;
  month: number;
  year: number;
  data: {
    label: string;
    value: number;
  }[];
};

type ShortStackedData = {
  label: string;
  value: number;
  isOther?: boolean;
};

type FilledData = {
  label: string;
  value: {
    val: number;
    isOther: boolean;
  }[];
}[];

export type TimeStackedBarChartProps = {
  title: string;
  stackedData?: TimeStackedData[];
  className?: string;
  emptyMessage?: string;
  downloadable?: boolean;
  autoSort?: boolean;
  maximumStacks?: number;
  colorsSetting?: {
    shortColorPalettes?: string[];
    fullColorPalettes?: string[];
    endColors?: string[];
    startColor?: string;
    minimumPrimaryColors?: number;
  };
  filterType?: DateTypeRange | false;
  onFilterTypeChange?: (filter: DateTypeRange) => void;
  unit?: string;
  onCsvDownload?:
    | ((
        autoTimeLabeler: (data: Omit<TimeStackedData, 'data'>) => string
      ) => void)
    | (() => void);
  legendDisplayMode?: 'grid' | 'col';
};

const TimeStackedBarChart: React.FC<TimeStackedBarChartProps> = ({
  title,
  stackedData,
  className,
  emptyMessage,
  downloadable,
  autoSort,
  maximumStacks,
  colorsSetting,
  filterType,
  onFilterTypeChange,
  unit,
  onCsvDownload,
  legendDisplayMode,
}) => {
  const SHORT_CHART_HEIGHT = 254;
  const FULL_CHART_HEIGHT = 650;
  const { t } = useTranslation();
  const [isDetailModalOpened, setIsDetailModalOpened] =
    useState<boolean>(false);
  const [isDownloadModalOpened, setIsDownloadModalOpened] =
    useState<boolean>(false);
  const { windowWidth, windowHeight } = useGetWindowSize();

  const isMobileScreen = useMemo(
    () => windowWidth <= LAYOUT_BREAK_POINTS.MD,
    [windowWidth]
  );

  const getTimeLabel = (data: Omit<TimeStackedData, 'data'>) => {
    if (filterType != null) {
      if (filterType === DateTypeRange.YEAR) {
        return data.year.toString();
      }

      if (filterType === DateTypeRange.DAY) {
        return `${data.year}/${data.month
          .toString()
          .padStart(2, '0')}/${data.day.toString().padStart(2, '0')}`;
      }
    }

    return `${data.year}/${data.month.toString().padStart(2, '0')}`;
  };

  const renderChart = (mode?: PluginMode) => {
    if (!stackedData?.length || stackedData.every(({ data }) => !data.length)) {
      return (
        <div className={styles.Empty}>
          {emptyMessage || t('dashboard.empty_chart_data') || ''}
        </div>
      );
    }

    const isNormalMode = !mode || mode === PluginMode.Normal;

    const timeLabels = stackedData.map((i) => getTimeLabel(i));

    const convertedData: ShortStackedData[][] = stackedData.map((item) => {
      let data: ShortStackedData[] = [...item.data];
      const sortedData = [...item.data];
      sortedData.sort((a, b) => a.label.localeCompare(b.label));

      if (autoSort) {
        data = sortedData;
      }

      if (isNormalMode && maximumStacks && data.length > maximumStacks + 1) {
        const others = sortedData.slice(maximumStacks, sortedData.length);
        const otherValue = others.reduce(
          (sum: number, { value }) => sum + value,
          0
        );

        data = [
          ...data.filter(
            ({ label }) =>
              !others.length || others.every((item) => item.label !== label)
          ),
          ...(otherValue
            ? [
                {
                  label: t('common.other'),
                  value: otherValue,
                  isOther: true,
                },
              ]
            : []),
        ];
      }

      return data;
    });

    const legends: { title: string }[] = _uniqBy(
      convertedData
        .flat()
        .sort((item1, item2) => {
          if (item1.isOther) {
            return -1;
          }

          if (item2.isOther) {
            return 1;
          }

          return 0;
        })
        .map((item) => ({ title: item.label })),
      'title'
    );

    let colorsPalette = [
      ...generateColorPalette({
        startColor: colorsSetting?.startColor,
        totalColors: Math.max(
          colorsSetting?.minimumPrimaryColors ?? 0,
          legends.length - (colorsSetting?.endColors?.length ?? 0)
        ),
        hueStep: 50,
      }),
      ...(colorsSetting?.endColors ?? []),
    ];

    if (
      isNormalMode &&
      maximumStacks &&
      convertedData.flat().some(({ isOther }) => isOther)
    ) {
      colorsPalette = [
        '#5E5E5E',
        ...colorsPalette.filter((val) => val != '#5E5E5E'),
      ];
    }

    const legendColorsMapper = legends.map(({ title }, index) => ({
      title,
      color: colorsPalette[index],
    }));

    const filledData: FilledData = legends.reduce(
      (rs: FilledData, { title }) => {
        convertedData.forEach((data) => {
          const dataItemIndex = rs.findIndex(({ label }) => label === title);
          const statisticData = data.find(({ label }) => label === title);

          const newVal = {
            val: statisticData?.value || 0,
            isOther: Boolean(statisticData?.isOther),
          };

          if (dataItemIndex === -1) {
            rs.push({
              label: title,
              value: [newVal],
            });
          } else {
            rs[dataItemIndex].value.push(newVal);
          }
        });

        return rs;
      },
      []
    );

    const datasets = filledData.map((x, i) => ({
      label: x.label,
      data: x.value,
      backgroundColor: colorsPalette[i],
    }));

    if (datasets.length) {
      datasets[0].data.forEach((_, datasetIndex) => {
        const sortedDatasets = datasets
          .map((d, dataIndex) => [
            d.data[datasetIndex].val,
            d.data[datasetIndex].isOther,
            dataIndex,
          ])
          .sort((isOther) => {
            if (isOther) {
              return -1;
            }

            return 1;
          });

        let sum = 0;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        sortedDatasets.forEach(([data, _, datasetIdx]) => {
          if (datasetIdx != null) {
            datasets[datasetIdx.toString()].data[datasetIndex] = [
              sum,
              sum + Number(data ?? 0),
            ];
            sum = sum + Number(data ?? 0);
          }
        });
      });
    }

    const chartOptions: ChartOptions<'bar'> = {
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: '#CFCFCF',
          },
          grid: {
            color: 'transparent',
            borderColor: '#6F6F6F',
            borderWidth: 1,
            drawBorder: true,
          },
        },
        y: {
          ticks: {
            color: '#CFCFCF',
          },
          grid: {
            color: '#3D3D3D',
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: (item) =>
              `${item?.dataset?.label}: ${
                item?.raw?.[1] != null && item?.raw?.[0] != null
                  ? normalizeNumber({
                      value: item.raw[1] - item.raw[0],
                    })
                  : ''
              }${unit || ''}`,
          },
        },
        datalabels: {
          display: false,
        },
      },
      maintainAspectRatio: false,
    };

    return (
      <>
        <div
          className={mergeClasses(styles.ChartWrapper, {
            [styles.ChartWrapperDownload]:
              mode === PluginMode.Download || legendDisplayMode === 'grid',
          })}
        >
          <div
            className={mergeClasses(styles.ChartBox, {
              [styles.ChartBoxDownload]:
                mode === PluginMode.Download || legendDisplayMode === 'grid',
            })}
          >
            <Chart
              {...(isNormalMode && {
                onClick: () => setIsDetailModalOpened(true),
                style: {
                  cursor: 'pointer',
                },
              })}
              type="bar"
              options={chartOptions}
              data={{
                labels: timeLabels,
                datasets,
              }}
              height={
                isNormalMode
                  ? SHORT_CHART_HEIGHT
                  : mode === PluginMode.Preview && isMobileScreen
                  ? Math.min(FULL_CHART_HEIGHT, windowHeight / 2.5)
                  : FULL_CHART_HEIGHT
              }
            />
          </div>

          <div
            className={mergeClasses(styles.Legends, {
              [styles.LegendsDownload]: mode === PluginMode.Download,
              [styles.LegendsMobile]:
                mode !== PluginMode.Download && isMobileScreen,
              [styles.LegendsGrid]: legendDisplayMode === 'grid',
            })}
            style={{
              height: isNormalMode
                ? !isMobileScreen && legendDisplayMode === 'col'
                  ? SHORT_CHART_HEIGHT
                  : undefined
                : mode === PluginMode.Download || isMobileScreen
                ? undefined
                : FULL_CHART_HEIGHT,
              maxHeight:
                isNormalMode && !isMobileScreen && legendDisplayMode === 'col'
                  ? SHORT_CHART_HEIGHT
                  : undefined,
            }}
          >
            {legendColorsMapper.map(({ title, color }, index) => (
              <div
                className={mergeClasses(styles.Legend, {
                  [styles.Legend1]:
                    (mode === PluginMode.Download || isMobileScreen) &&
                    index % 2 === 0,
                  [styles.Legend2]:
                    (mode === PluginMode.Download || isMobileScreen) &&
                    index % 2 > 1,
                })}
                key={index}
              >
                <div
                  style={{
                    backgroundColor: color,
                    width: 12,
                    height: 12,
                    minWidth: 12,
                    minHeight: 12,
                    borderRadius: 2,
                  }}
                />
                <p
                  className={mergeClasses({
                    [styles.textLeft]: mode === PluginMode.Preview,
                  })}
                >
                  {title}
                </p>
              </div>
            ))}
          </div>
        </div>

        {isNormalMode && isDetailModalOpened && (
          <Modal className={styles.TimeStackChartModalWrapper}>
            <div className={styles.TimeStackChartModal}>
              <div className={styles.ModalTitle}>
                <p>{title}</p>
                <IconCloseTablet
                  onClick={() => setIsDetailModalOpened(false)}
                />
              </div>

              <div className={styles.ModalBody}>
                {renderChart(PluginMode.Preview)}
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  };

  const handleDownloadChartImage = async () => {
    const exportChart = document.getElementById(`${title}-exportChart`);
    const canvas = await html2canvas(exportChart as HTMLElement, {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    });

    const dataURL = canvas.toDataURL('image/png');
    downloadjs(
      dataURL,
      `${title} ${dayjs().format(DATE_FORMAT.slaYMDHm)}.png`,
      'image/png'
    );
  };

  return (
    <div className={mergeClasses(styles.StackedBarChart, className)}>
      <div className={styles.TitleBar}>
        <p>{title}</p>
        <div className={styles.Actions}>
          {filterType !== false && (
            <DateTimeUnitTabButton
              disableFilterByYearAndDay={false}
              activeTab={filterType}
              setActiveTab={(value) => {
                onFilterTypeChange?.(value);
              }}
            />
          )}

          {downloadable && (
            <button
              className={styles.DownloadButton}
              disabled={!stackedData?.length}
              onClick={() => {
                setIsDownloadModalOpened(true);
              }}
            >
              <IconDownload />
            </button>
          )}
        </div>
      </div>

      <div
        className={styles.Chart}
        style={{
          height:
            !isMobileScreen && legendDisplayMode === 'col'
              ? SHORT_CHART_HEIGHT
              : undefined,
          minHeight: SHORT_CHART_HEIGHT,
        }}
      >
        {renderChart()}
      </div>

      {isDownloadModalOpened && (
        <DashboardDownloadModal
          onClose={() => {
            setIsDownloadModalOpened(false);
          }}
          handleDownload={(data) => {
            if (data.isImage) {
              handleDownloadChartImage();
            }

            if (data.isCsv) {
              onCsvDownload?.(getTimeLabel);
            }

            setIsDownloadModalOpened(false);
          }}
        />
      )}

      {downloadable && (
        <div
          id={`${title}-exportChart`}
          style={{
            padding: '0 16px 16px',
            position: 'fixed',
            zIndex: -Number.MAX_SAFE_INTEGER,
            top: -Number.MAX_SAFE_INTEGER,
            left: -Number.MAX_SAFE_INTEGER,
            width: 1000,
          }}
        >
          {renderChart(PluginMode.Download)}
        </div>
      )}
    </div>
  );
};

TimeStackedBarChart.defaultProps = {
  downloadable: true,
  legendDisplayMode: 'col',
};

export default TimeStackedBarChart;
