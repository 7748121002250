// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './HistoryCompressionReceives.module.scss';
import { useEffect, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import { LabelContents } from 'components/molecules/LabelContents';
import { CompressionReceiveData, EdiStatus } from 'util/Types';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from 'util/ConstantValues';
import _snakeCase from 'lodash/snakeCase';
import EdiStatusTag from 'components/atoms/EdiStatusTag';
import { normalizeNumber } from 'util/commons';

export type Props = {
  id?: string;
};

export const HistoryCompressionReceives = () => {
  const commonsApi = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { t } = useTranslation();
  const [compressionData, setCompressionData] = useState<
    CompressionReceiveData[]
  >([]);

  const callApi = async () => {
    const res = await commonsApi.get('/work-histories/compression/receives');
    setCompressionData(res.data);
  };

  useEffect(() => {
    callApi();
  }, []);

  return (
    <div className={styles.historyCompressionReceives}>
      {compressionData &&
        compressionData.length > 0 &&
        compressionData.map((item, index) => {
          return (
            <div className={styles.commonCard} key={index}>
              <LabelContents
                label={t('common_history_field.date_time')}
                contents={getFormatDateWithCurrentTimezone(
                  item.datetime,
                  DATE_FORMAT.slaYMDHm
                )}
              />
              <LabelContents
                label={t('common_history_field.responsible_person')}
                contents={item.userId + '(' + item.userName + ')'}
              />
              <LabelContents
                label={t('common_history_field.place')}
                contents={item.deliveryFromSiteName}
              />
              <LabelContents
                label={t('common_history_field.material_name')}
                contents={item.description}
              />
              <LabelContents
                label={t('common_history_field.weight')}
                contents={`${normalizeNumber({ value: item.weight })}kg`}
              />
              <LabelContents
                label={t('common_history_field.reception_method')}
                contents={t(`histories.${_snakeCase(item.receptionMethod)}`)}
              />
              <LabelContents
                label={t('common_history_field.registration_id')}
                contents={item.targetIds.map(({ id }) => id).join('\n')}
              />
              <LabelContents
                label={t('common_history_field.label_manifest_number')}
                contents={item.targetIds[0].ediNumber}
                hideOnEmpty
              />
              <LabelContents
                label={t('common_history_field.label_edi_id')}
                hideOnEmpty
                contents={
                  item.targetIds[0].ediStatus != null &&
                  item.targetIds[0].ediStatus !== EdiStatus.None && (
                    <EdiStatusTag status={item.targetIds[0].ediStatus} />
                  )
                }
              />
            </div>
          );
        })}
    </div>
  );
};
