// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import { Form } from 'react-final-form';
import { InputDataListField } from '../InputDataListField';
import { validateMaxLength, validateStringField } from 'util/validator';
import styles from './AddICCardForm.module.scss';
import { BarcodeICCardInput } from 'util/Types';
import { SiteTenantAuthenticationItem } from 'util/siteManagementTypes';
interface Props {
  authenticationItem: SiteTenantAuthenticationItem | undefined;
  onSubmitAddICCard: (e: BarcodeICCardInput, tenantId?: number) => void;
}

const AddICCardForm: React.FC<Props> = ({
  authenticationItem,
  onSubmitAddICCard,
}) => {
  const cardLabel =
    authenticationItem?.type === 'tenant' ? 'ICカード' : '管理カード';

  return (
    <div className={styles.addICCardForm}>
      <Form<BarcodeICCardInput>
        initialValues={{}}
        onSubmit={(e) =>
          onSubmitAddICCard(
            e,
            authenticationItem?.type === 'tenant'
              ? Number(authenticationItem?.id)
              : undefined
          )
        }
        validate={(values) => {
          return {
            icCard:
              !values.icCard || values.icCard.trim().length === 0
                ? validateStringField(cardLabel, 32, values.icCard)
                : undefined,
          };
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div className={styles.inputFrame}>
              <InputDataListField
                validate={(value) => validateMaxLength(value, 32, cardLabel)}
                label={cardLabel}
                placeholder={cardLabel}
                name="icCard"
                required
                maxlength={32}
              />
            </div>

            <div className={styles.line} />

            <div className={styles.submit}>
              <button
                disabled={props.invalid}
                className={
                  props.invalid ? styles.disableButton : styles.submitButton
                }
                type="submit"
              >
                追加する
              </button>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

export default AddICCardForm;
