// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal } from 'components/atoms/Modal';
import 'react-calendar/dist/Calendar.css';

import styles from './index.module.scss';
import { IconClose } from 'components/atoms/icons/IconClose';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { InputDataListField } from '../InputDataListField';
import { SelectField } from '../SelectField';
import { REGEX_OBJECT } from 'util/ConstantValues';
import { validateRequiredField, validateSelectField } from 'util/validator';
import { HistoryProduct, ProducerSite } from 'types/purchase';
import { useEffect, useState } from 'react';
import { initProducerSite } from 'util/constants/sites';
import { getDetailProducerSite } from 'apis/operator_site/siteApi';

type Props = {
  data: HistoryProduct;
  onSubmit: (form: HistoryProduct, productId: string) => void;
  onClose: () => void;
};

const UpdateHistoryProductRegistration: React.FC<Props> = ({
  data,
  onClose,
  onSubmit,
}) => {
  const { t, i18n } = useTranslation();
  const [producerData, setProducerData] =
    useState<ProducerSite>(initProducerSite);

  useEffect(() => {
    getProducerSite();
  }, []);

  const getProducerSite = () => {
    getDetailProducerSite(data.siteId, (data) => {
      if (data) {
        setProducerData(data);
      }
    });
  };

  return (
    <section className={styles.historyModal}>
      <Modal>
        <div className={styles.customTitle}>
          <div className={styles.title}>
            {t('purchase_page.histories.product_title') || ''}
          </div>
          <div className={styles.top} onClick={onClose}>
            <IconClose />
          </div>
        </div>

        <div className={styles.inputForm}>
          <Form<HistoryProduct>
            initialValues={
              data
                ? {
                    id: data.id,
                    processingMethod: data.processingMethod,
                    packingStyle: data.packingStyle,
                    quantity: data.quantity,
                    weight: data.weight,
                    name: data.name,
                    totalInputWeight: data.totalInputWeight,
                  }
                : {}
            }
            onSubmit={(values) => {
              onSubmit(values, data.id);
            }}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            validate={(values) => ({
              processingMethod: validateSelectField(
                t('purchase_page.product_registration.processing_method'),
                values.processingMethod
              ),
              packingStyle: validateSelectField(
                t('purchase_page.product_registration.packing_style'),
                values.packingStyle
              ),
              quantity: validateRequiredField(
                values.quantity,
                t('purchase_page.product_registration.quality') || ''
              ),
              weight: validateRequiredField(
                values.weight,
                t('purchase_page.product_registration.weight') || ''
              ),
              name: validateRequiredField(
                values.name,
                t('purchase_page.product_registration.product_name') || ''
              ),
            })}
          >
            {(props) => {
              return (
                <form className={styles.formInput}>
                  <div className={styles.inputFrame}>
                    <SelectField
                      label={t(
                        'purchase_page.product_registration.processing_method'
                      )}
                      name="processingMethod"
                      value={props.values.processingMethod}
                      options={producerData.producerMethod.map((i) => {
                        return { label: i, value: i };
                      })}
                      placeholder={t(
                        'purchase_page.product_registration.processing_method'
                      )}
                      showPlaceholderOption
                      onChange={() => null}
                      required
                      isDarkmode
                    />

                    <SelectField
                      label={t('purchase_page.histories.packing')}
                      name="packingStyle"
                      value={props.values.packingStyle}
                      options={producerData.packingStyles.map((i) => {
                        return { label: i, value: i };
                      })}
                      placeholder={t('purchase_page.histories.packing')}
                      showPlaceholderOption
                      onChange={() => null}
                      required
                      isDarkmode
                    />

                    <InputDataListField
                      inputStyle={
                        i18n.language === 'en'
                          ? {
                              paddingRight: '56px',
                            }
                          : {}
                      }
                      label={t('purchase_page.product_registration.quality')}
                      name="quantity"
                      placeholder={t(
                        'purchase_page.product_registration.quality'
                      )}
                      type="text"
                      required
                      maxlength={10}
                      validRegex={REGEX_OBJECT.positiveIntegerRegex}
                      isDarkMode
                      endExtraText={t('purchase_page.resource.pieces')}
                    />

                    <InputDataListField
                      label={t('purchase_page.product_registration.weight')}
                      name="weight"
                      placeholder={t(
                        'purchase_page.product_registration.weight'
                      )}
                      type="text"
                      required
                      maxlength={10}
                      validRegex={REGEX_OBJECT.tenDigitsWith5Decimal}
                      isDarkMode
                      normalizeNumber
                      endExtraText={t('common.input_resources', {
                        weight: data.totalInputWeight,
                        unit: 'kg',
                      })}
                    />

                    <InputDataListField
                      label={t(
                        'purchase_page.product_registration.product_name'
                      )}
                      name="name"
                      placeholder={t(
                        'purchase_page.product_registration.product_name'
                      )}
                      type="text"
                      required
                      maxlength={50}
                      isDarkMode
                    />
                  </div>

                  <div className={styles.submit}>
                    <button
                      className={styles.submitButton}
                      disabled={props.invalid}
                      type="submit"
                      onClick={props.handleSubmit}
                    >
                      {t('purchase_page.histories.edit_button')}
                    </button>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
      </Modal>
    </section>
  );
};
export default UpdateHistoryProductRegistration;
