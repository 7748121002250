// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './AdminBrandItemTable.module.scss';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import { DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import TableLoading from 'components/atoms/TableLoading';
import { Form } from 'react-final-form';
import { SelectField } from 'components/molecules/SelectField';
import { InputDataListField } from 'components/molecules/InputDataListField';
import { IconSearch } from 'components/atoms/icons/IconSearch';
import Badge from 'components/atoms/Badge';
import { DEFAULT_EMPTY_VALUE, SELECT_ALL } from 'util/commons';
import { useParams } from 'react-router';
import {
  getBrandItemOptions,
  getBrandSites,
  getOperatorsBrandItems,
  updateSiteBrandItems,
} from 'apis/brands';
import { OperatorsBrandItem, GetOperatorsBrandItemsParams } from 'types/brand';
import { toast } from 'react-toastify';
import { CustomCheckBox } from 'components/atoms/CustomCheckbox';

export interface FilterObject {
  inputSearch?: string;
  operatorType: string;
}

const NOT_USED_VALUE = '不使用';

const AdminBrandItemTable: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const [operatorsBrandItems, setOperatorBrandItems] = useState<
    OperatorsBrandItem[]
  >([]);

  const [listMeta, setListMeta] = useState<
    GetOperatorsBrandItemsParams & { total: number }
  >({
    total: 0,
  });
  const [brandSiteOptions, setBrandSiteOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [brandItemOptions, setBrandItemOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [brandItemIdMapper, setBrandItemIdMapper] = useState<
    Record<string, number>
  >({});
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [allCheckedFlg, setAllCheckedFlg] = useState<boolean>(false);
  const [brandName, setBrandName] = useState<string>('');

  const handleGetOperatorsBrandItems = (
    params?: GetOperatorsBrandItemsParams,
    forceReset?: boolean
  ) => {
    getOperatorsBrandItems({
      brandId: id,
      params: {
        ...params,
        take: PAGE_SIZE,
        page: params?.page || DEFAULT_PAGE,
      },
      successCallback: (data, meta) => {
        if (forceReset) {
          setOperatorBrandItems([]);
          setOperatorBrandItems(data);
        } else {
          setOperatorBrandItems([...operatorsBrandItems, ...data]);

          if (allCheckedFlg) {
            const newItemsIds = data.map((item) => item.wasteUnitId.toString());
            setCheckedItems((prevCheckedItems) => [
              ...prevCheckedItems,
              ...newItemsIds.filter((id) => !prevCheckedItems.includes(id)),
            ]);
            setAllCheckedFlg(true);
          }
        }

        setListMeta({
          ...listMeta,
          ...meta,
        });
      },
    });
  };

  useEffect(() => {
    handleGetOperatorsBrandItems();
  }, []);

  useEffect(() => {
    getBrandItemOptions({
      brandId: id,
      successCallback: (data) => {
        setBrandItemOptions([
          { value: NOT_USED_VALUE, label: NOT_USED_VALUE },
          ...data.map(({ name }) => ({ value: name, label: name })),
        ]);
        setBrandItemIdMapper(
          data.reduce((rs: Record<string, number>, { id, name }) => {
            rs[name] = id;

            return rs;
          }, {})
        );
      },
    });
  }, []);

  useEffect(() => {
    getBrandSites({
      brandId: id,
      successCallback: (data) =>
        setBrandSiteOptions([
          { value: SELECT_ALL, label: SELECT_ALL },
          ...data.map(({ id }) => ({ value: id, label: id })),
        ]),
    });
  }, []);

  const handleUpdateSiteBrandItem = () => {
    const filter = operatorsBrandItems?.filter((item) =>
      checkedItems.includes(item.wasteUnitId.toString())
    );

    const selectedBrandItemId = brandItemIdMapper[brandName];

    const records = filter.map((item) => ({
      wasteUnitId: item.wasteUnitId,
      wasteUnitName: item.wasteUnitName,
      ...(brandName &&
        brandName !== NOT_USED_VALUE &&
        brandName !== DEFAULT_EMPTY_VALUE && {
          brandItemId: selectedBrandItemId,
          brandItemName: brandName,
        }),
      isDeleting: !brandName || brandName === DEFAULT_EMPTY_VALUE,
    }));

    updateSiteBrandItems({
      brandId: id,
      params: records,
      successCallback: (msg) => {
        toast.success(msg);
        handleGetOperatorsBrandItems(
          {
            ...listMeta,
            page: DEFAULT_PAGE,
          },
          true
        );
        setCheckedItems([]);
        setAllCheckedFlg(false);
        setBrandName('');
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmitFilter = (value: Record<string, any>) => {
    const filter = {
      keyword: value.keyword?.trim() || undefined,
      siteId:
        value.siteId !== SELECT_ALL && value.siteId ? value.siteId : undefined,
    };

    setListMeta({
      ...listMeta,
      ...filter,
    });
    handleGetOperatorsBrandItems(
      {
        page: DEFAULT_PAGE,
        ...filter,
      },
      true
    );
    setCheckedItems([]);
    setAllCheckedFlg(false);
    setBrandName('');
  };

  const getBrandItem = (brandItem?: string) => {
    if (brandItem === 'N/A') {
      return NOT_USED_VALUE;
    }
    if (brandItem == null) {
      return DEFAULT_EMPTY_VALUE;
    }

    return brandItem;
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const itemId = e.target.id;
    const isChecked = e.target.checked;
    let updatedCheckedItems;

    if (isChecked) {
      updatedCheckedItems = [...checkedItems, itemId];
      setCheckedItems(updatedCheckedItems);
    } else {
      updatedCheckedItems = checkedItems.filter((item) => item !== itemId);
      setCheckedItems(updatedCheckedItems);
    }

    const isAllChecked =
      updatedCheckedItems.length === operatorsBrandItems.length;
    setAllCheckedFlg(isAllChecked);
  };

  const handleCheckAll = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setAllCheckedFlg(isChecked);

    if (isChecked) {
      const allItemIds = operatorsBrandItems.map((item) =>
        item.wasteUnitId.toString()
      );
      setCheckedItems(allItemIds);
    } else {
      setCheckedItems([]);
    }
  };

  return (
    <div className={styles.adminBrandItemTable}>
      <div className={styles.titleTable}>
        <div
          dangerouslySetInnerHTML={{
            __html:
              t('common.page', {
                page: listMeta.total,
              }) ?? '',
          }}
        />

        <div className={styles.tool}>
          <div className={styles.search}>
            <Form onSubmit={handleSubmitFilter}>
              {(props) => {
                return (
                  <form
                    onSubmit={props.handleSubmit}
                    className={styles.formInput}
                  >
                    <div className={styles.inputFrame}>
                      <SelectField
                        name="siteId"
                        options={brandSiteOptions}
                        placeholder={t('common.placeholder.select') || ''}
                        showPlaceholderOption
                        onChange={(value) =>
                          handleSubmitFilter({
                            siteId: value.toString(),
                            keyword: props.values.keyword,
                          })
                        }
                      />

                      <div className={styles.inputSearch}>
                        <button type="submit" className={styles.searchButton}>
                          <IconSearch />
                        </button>

                        <InputDataListField
                          placeholder={t('common.placeholder.search') || ''}
                          name="keyword"
                          required
                        />
                      </div>
                    </div>

                    <div className={styles.line} />
                  </form>
                );
              }}
            </Form>
          </div>

          <div className={styles.submit}>
            <div className={styles.contentAction}>
              <Form
                onSubmit={() => {}}
                initialValues={{
                  brandItem: brandName,
                }}
              >
                {() => {
                  return (
                    <form className={styles.formInput}>
                      <div className={styles.inputFrame}>
                        <SelectField
                          name="brandItem"
                          options={brandItemOptions}
                          placeholder={DEFAULT_EMPTY_VALUE}
                          onChange={(value) => {
                            setBrandName(value.toString());
                          }}
                        />
                      </div>
                    </form>
                  );
                }}
              </Form>
            </div>
            <button
              className={styles.submitButton}
              onClick={handleUpdateSiteBrandItem}
              disabled={!checkedItems.length}
              type="submit"
            >
              {t('brand_items.save_btn')}
            </button>
          </div>
        </div>
      </div>

      <div className={`scroll-wrapper ${styles.scrollWrapper}`}>
        <table id={styles.brandItemTable} style={{ height: 'auto' }}>
          <thead>
            <tr>
              <th className={styles.checkbox}>
                <CustomCheckBox
                  id={'checkAll'}
                  checked={allCheckedFlg}
                  onChange={handleCheckAll}
                />
              </th>
              <th className={styles.chainName}>
                {t('brand_items.businessId')}
              </th>
              <th className={styles.chainName}>{t('brand_items.baseId')}</th>
              <th className={styles.materialName}>
                {t('brand_items.item_category')}
              </th>
              <th className={styles.materialName}>
                {t('brand_items.item_name')}
              </th>
              <th className={styles.action}>{t('brand_items.brand_item')}</th>
            </tr>
          </thead>
          <tbody>
            {operatorsBrandItems?.length > 0 ? (
              operatorsBrandItems.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className={styles.checkbox}>
                      <CustomCheckBox
                        id={item.wasteUnitId.toString()}
                        checked={
                          !!checkedItems?.includes(item.wasteUnitId.toString())
                        }
                        onChange={handleInputChange}
                      />
                    </td>
                    <td className={styles.chainName}>
                      <p title={item.operatorId}>
                        {item.operatorId || DEFAULT_EMPTY_VALUE}
                      </p>
                    </td>
                    <td className={styles.chainName}>
                      <p title={item.siteId}>
                        {item.siteId || DEFAULT_EMPTY_VALUE}
                      </p>
                    </td>
                    <td className={styles.materialName}>
                      <p title={item.categoryName}>
                        {item.categoryName || DEFAULT_EMPTY_VALUE}
                      </p>
                    </td>
                    <td className={styles.materialName}>
                      <div className={styles.itemName}>
                        <Badge
                          content={t('common.tag.new')}
                          show={item.brandItemName == null}
                          className={styles.tagNew}
                        >
                          <span title={item.wasteUnitName}>
                            {item.wasteUnitName || DEFAULT_EMPTY_VALUE}
                          </span>
                        </Badge>
                      </div>
                    </td>

                    <td className={styles.action}>
                      <p title={item.categoryName}>
                        {getBrandItem(item.brandItemName)}
                      </p>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="msg-error">{t('messages.M_008')}</td>
              </tr>
            )}

            {operatorsBrandItems?.length > 0 && (
              <TableLoading
                onNext={() => {
                  if (
                    operatorsBrandItems.length >= PAGE_SIZE &&
                    operatorsBrandItems.length < listMeta.total
                  ) {
                    handleGetOperatorsBrandItems({
                      page: (listMeta.page || DEFAULT_PAGE) + 1,
                    });
                  }
                }}
              />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminBrandItemTable;
