// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { getOperatorSite, getSite } from 'apis/operator_site/siteApi';
import { getPurchasesProducersInputResources } from 'apis/site_purchase/purchase';
import { SelectField } from 'components/molecules/SelectField';
import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  ProductRegistrationForm,
  PurchasesReceivesParams,
} from 'types/purchase';
import { baseProductSiteForm } from 'util/constants/sites';
import { OperatorType } from 'util/Enums';
import {
  PreRegisResource,
  Option,
  PreRegisSelectedResource,
  SelectedResources,
  ResourcePreRegisFilter,
} from 'util/Types';
import PreRegisResourceSelect from '../PreRegisResourceSelect';
import ProductRegistrationFormOrganism from '../ProductRegistrationFormOrganism';
import styles from './PurchaseRegisResource.module.scss';

type Props = {
  active: boolean;
};

const PurchaseRegisResource: React.FC<Props> = ({ active = false }) => {
  const { t } = useTranslation();

  const [step, setStep] = useState<number>(1);
  const [operatorOptions, setOperatorOptions] = useState<Option[]>([]);
  const [siteOptions, setSiteOptions] = useState<Option[]>([]);
  const [purchaseInputResources, setPurchaseInputResource] = useState<
    PreRegisResource[]
  >([]);
  const [preRegisSelectedResource, setPreRegisSelectedResource] = useState<
    PreRegisSelectedResource[]
  >([]);
  const [filterObj, setFilterObj] = useState<PurchasesReceivesParams>({
    operatorId: '',
    siteId: '',
  });
  const [selectedResources, setSelectedResources] = useState<SelectedResources>(
    {}
  );
  const [filterInTable, setFilterInTable] = useState<ResourcePreRegisFilter>(
    {}
  );
  const [productSiteForm, setProductSiteForm] =
    useState<ProductRegistrationForm>(baseProductSiteForm);

  useEffect(() => {
    if (active) {
      getOperatorSite(OperatorType.Producer, (data) => {
        if (data) {
          setOperatorOptions(
            data.map((item) => ({ value: item.id, label: item.name }))
          );
        }
      });
    } else {
      setStep(1);
    }
  }, [active]);

  useEffect(() => {
    filterObj.operatorId &&
      filterObj.siteId &&
      fetchPurchasesProducersInputResources();
  }, [filterObj.siteId]);

  useEffect(() => {
    setFilterObj({ ...filterObj, siteId: '' });
    setPurchaseInputResource([]);
    filterObj.operatorId && fetchSites(filterObj.operatorId);
  }, [filterObj.operatorId]);

  const fetchSites = (operatorId: string) => {
    getSite({
      params: { operatorId, type: OperatorType.Producer },
      successCallback: (data) => {
        if (data) {
          setSiteOptions(
            data.map((item) => ({ value: item.id, label: item.name }))
          );
        }
      },
    });
  };

  const fetchPurchasesProducersInputResources = () => {
    getPurchasesProducersInputResources({
      params: {
        operatorId: filterObj.operatorId || '',
        siteId: filterObj.siteId || '',
      },
      successCallback: (data) => {
        if (data) {
          setPurchaseInputResource(data);
        }
      },
    });
  };

  const handleNext = (values: PreRegisSelectedResource[]) => {
    setStep(2);
    setPreRegisSelectedResource(values);
  };

  return (
    <div className={styles.purchaseRegisResource}>
      {step === 1 && (
        <div className={styles.purchasePreRegister}>
          <div className={styles.purchasePreRegisterForm}>
            <Form onSubmit={() => {}} initialValues={filterObj}>
              {({ handleSubmit }) => {
                const handleChangeOperatorId = (e) => {
                  setFilterObj({ ...filterObj, operatorId: e });
                };

                const handleChangeSiteId = (e) => {
                  setFilterObj({ ...filterObj, siteId: e });
                };

                return (
                  <form onSubmit={handleSubmit} className={styles.formInput}>
                    <div className={styles.inputFrame}>
                      <div className={styles.textFrame}>
                        <SelectField
                          name="operatorId"
                          placeholder={t(
                            'purchase_page.product_registration.operator_name'
                          )}
                          options={operatorOptions}
                          showPlaceholderOption
                          onChange={handleChangeOperatorId}
                          isDarkmode
                        />

                        <SelectField
                          name="siteId"
                          options={siteOptions}
                          disabled={!filterObj.operatorId}
                          placeholder={t(
                            'purchase_page.product_registration.site_name'
                          )}
                          showPlaceholderOption
                          onChange={handleChangeSiteId}
                          isDarkmode
                        />
                      </div>
                    </div>

                    <div className={styles.line} />
                  </form>
                );
              }}
            </Form>
          </div>

          <PreRegisResourceSelect
            className={styles.preRegist}
            operatorType={OperatorType.Purchase}
            resourceData={purchaseInputResources}
            onNext={handleNext}
            actionDisabled={!filterObj.operatorId || !filterObj.siteId}
            withMobileMode={false}
            actionProps={{
              selectedResources,
              setSelectedResources,
              filter: filterInTable,
              setFilter: setFilterInTable,
            }}
          />
        </div>
      )}

      {step === 2 && (
        <ProductRegistrationFormOrganism
          filterObj={filterObj}
          preRegisSelectedResource={preRegisSelectedResource}
          setStep={setStep}
          getPurchasesResources={fetchPurchasesProducersInputResources}
          productSiteForm={productSiteForm}
          setProductSiteForm={setProductSiteForm}
          resourceSelectProps={{
            setFilter: setFilterInTable,
            setSelectedResources,
          }}
        />
      )}
    </div>
  );
};

export default PurchaseRegisResource;
