// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { apiService } from 'apis/site_recycle/siteApi';
import { createAxios } from 'ts/createAxios';
import { RecycleChain, RecycleChainById } from 'types/recycleChain';
import { getApiPath } from 'util/commons';
import { API_ENDPOINTS } from 'util/endPoints';
import { handleError } from 'util/errorHandler';
import { WasteFuelUnits } from 'util/settingDeliveryType';

export type RecycleChainItem = {
  id: number;
  name: string;
  materialName: string;
  englishMaterialName: string;
};

export type RecycleChainData = {
  page: number;
  total: number;
  recycleChains: RecycleChainItem[];
};
export const getRecycleChainList = async (
  callback?: (data: RecycleChainData) => void
) => {
  const api = createAxios();
  const { data } = await api.get<RecycleChainData>('/recycle-chains');
  data && callback && callback(data);
};

const addTagTypes = ['recycleChains'] as const;

const recycleChainsApi = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchRecycleChain: build.query<RecycleChain, RecycleChainById>({
        query: (params) => ({
          url: getApiPath(API_ENDPOINTS.RECYCLE_CHAIN_DETAIL, {
            id: params.id,
          }),
          method: 'GET',
        }),
      }),
    }),
  });

export const { useFetchRecycleChainQuery } = recycleChainsApi;

export const getWasteFuelUnits = async (params: {
  successCallback?: (data: WasteFuelUnits[]) => void;
}) => {
  const { successCallback } = params;
  const api = createAxios();

  try {
    const { data } = await api.get<WasteFuelUnits[]>(
      getApiPath(API_ENDPOINTS.WASTE_FUEL_UNITS)
    );

    if (data) {
      successCallback?.(data);
    }
  } catch (error) {
    handleError(error);
  }
};
