// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import {
  getOperators,
  getPurchasesReceives,
  getSites,
} from 'apis/purchases/purchaseApi';
import { SelectField } from 'components/molecules/SelectField';
import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { PurchaseReceiveObj, PurchasesReceivesParams } from 'types/purchase';
import { AccountOperator } from 'util/accountManagementTypes';
import { EmissionSiteItem } from 'util/siteManagementTypes';
import PurchaseReceived from '../PurchaseReceived';
import styles from './ProductRegistrationAcceptanceOrganism.module.scss';
import { DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import { GetListParams } from 'types/api';

type props = {
  active: boolean;
};

const ProductRegistrationAcceptanceOrganism = ({ active = false }: props) => {
  const { t } = useTranslation();
  const [operators, setOperators] = useState<AccountOperator[]>([]);
  const [sites, setSites] = useState<EmissionSiteItem[]>([]);
  const [receives, setReceives] = useState<PurchaseReceiveObj>({
    data: [],
    page: 0,
    total: 0,
  });
  const [filterObj, setFilterObj] = useState<PurchasesReceivesParams>({
    operatorId: '',
    siteId: '',
  });

  const fetchOperators = async () => {
    getOperators((data) => {
      if (data) {
        setOperators(data.operators.sort((a, b) => a.id.localeCompare(b.id)));
      }
    });
  };

  const fetchSite = async (operatorId: string | number) => {
    getSites(operatorId, (data) => {
      if (data) {
        setSites(data.sites);
      }
    });
  };

  const callApiGetPurchasesReceives = (
    params?: GetListParams,
    forceRefresh?: boolean,
    operatorIdChange?: boolean
  ) => {
    getPurchasesReceives({
      params: {
        operatorId: filterObj.operatorId || '',
        siteId: !operatorIdChange ? filterObj.siteId : '',
        take: PAGE_SIZE,
        page: params?.page || DEFAULT_PAGE,
      },
      successCallback: (data) => {
        if (forceRefresh) {
          setReceives(data);
        } else {
          setReceives({
            ...receives,
            ...data,
            data: [...receives.data, ...(data.data || [])],
          });
        }
      },
    });
  };

  useEffect(() => {
    if (active) {
      fetchOperators();
    }
  }, [active]);

  useEffect(() => {
    filterObj.operatorId &&
      filterObj.siteId &&
      callApiGetPurchasesReceives(
        {
          page: DEFAULT_PAGE,
        },
        true
      );
  }, [filterObj.siteId]);

  useEffect(() => {
    filterObj.operatorId && fetchSite(filterObj.operatorId);
    setFilterObj({ ...filterObj, siteId: '' });
  }, [filterObj.operatorId]);

  return (
    <div className={styles.productRegistration}>
      <div className={styles.productRegistrationPulldown}>
        <Form onSubmit={() => {}} initialValues={filterObj}>
          {({ handleSubmit }) => {
            const handleChangeOperatorId = (e) => {
              setFilterObj({ ...filterObj, operatorId: e });
            };

            const handleChangeSiteId = (e) => {
              setFilterObj({ ...filterObj, siteId: e });
            };

            return (
              <form onSubmit={handleSubmit} className={styles.formInput}>
                <div className={styles.inputFrame}>
                  <span className={styles.textNotification}>
                    {t('purchase_page.receive.text_notification')}
                  </span>
                  <div className={styles.textFrame}>
                    <SelectField
                      name="operatorId"
                      placeholder={t(
                        'purchase_page.receive.select_operator_name'
                      )}
                      options={operators.map((i) => {
                        return { label: i.name, value: i.id };
                      })}
                      showPlaceholderOption
                      onChange={handleChangeOperatorId}
                      isDarkmode
                    />

                    <SelectField
                      name="siteId"
                      options={sites.map((i) => {
                        return { label: i.name, value: i.id };
                      })}
                      disabled={!filterObj.operatorId}
                      placeholder={t('purchase_page.receive.select_site_name')}
                      showPlaceholderOption
                      onChange={handleChangeSiteId}
                      isDarkmode
                    />
                  </div>
                </div>

                <div className={styles.line} />
              </form>
            );
          }}
        </Form>
      </div>

      <PurchaseReceived
        active={active}
        receives={receives}
        getPurchasesReceives={callApiGetPurchasesReceives}
        filterObj={filterObj}
      />
    </div>
  );
};

export default ProductRegistrationAcceptanceOrganism;
