// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './DeleteItemModal.module.scss';
import { Modal } from 'components/atoms/Modal';
import React from 'react';
import { IconDelete } from 'components/atoms/icons/IconDelete';
import { IconClose } from 'components/atoms/icons/IconClose';
import { WasteUnitItem } from 'pages/AdminCustomizeItemsDetail';
import { Category } from 'components/organisms/CustomizeListCategory';
import { TenantItem } from 'components/organisms/AdminTenantManagement';
import { BrandItem, DeleteFloorSectionItem } from 'util/Types';
import { useTranslation } from 'react-i18next';
import { OperatorItem } from 'util/siteManagementTypes';

interface Props {
  onClose: () => void;
  onHandleDelete: (id?: number | string) => void;
  item?:
    | Category
    | WasteUnitItem
    | TenantItem
    | DeleteFloorSectionItem
    | BrandItem
    | OperatorItem;
  isOperator?: boolean;
}

const DeleteItemModal: React.FC<Props> = ({
  onClose,
  onHandleDelete,
  item,
  isOperator,
}) => {
  const { t } = useTranslation();

  const confirmMsg = isOperator
    ? `${item?.name}の事業者を削除すると、拠点、ユーザーなど\n${item?.name}の事業者に関する全て情報も削除されます。\n本当に削除しますか？`
    : `${t('common.confirm_delete', { field: item?.name })}`;
  return (
    <div className={styles.customModal}>
      <Modal>
        <div className={styles.deleteItemModal}>
          <div className={styles.customIcon}>
            <div className={styles.iconDeleteItem}>
              <IconDelete />
            </div>

            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>

          <div className={styles.customTitle}>
            <div className={styles.title}>{t('common.confirm')}</div>
            <p
              className={isOperator ? styles.msgDeleteOperator : styles.msg}
              title={confirmMsg}
            >
              {confirmMsg}
            </p>
          </div>

          <div className={styles.submit}>
            <button
              className={styles.submitButton}
              type="submit"
              onClick={() => onHandleDelete(item?.id)}
            >
              {t('common.delete')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteItemModal;
