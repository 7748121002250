// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  onClick?: () => void;
}
export const IconLanguage: React.FC<Props> = ({ onClick }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M17.5 10C17.5 11.9891 16.7098 13.8968 15.3033 15.3033C13.8968 16.7098 11.9891 17.5 10 17.5M17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5M17.5 10H2.5M10 17.5C8.01088 17.5 6.10322 16.7098 4.6967 15.3033C3.29018 13.8968 2.5 11.9891 2.5 10M10 17.5C11.3808 17.5 12.5 14.1417 12.5 10C12.5 5.85833 11.3808 2.5 10 2.5M10 17.5C8.61917 17.5 7.5 14.1417 7.5 10C7.5 5.85833 8.61917 2.5 10 2.5M10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10"
        stroke="#E7E7E7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
