// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useEffect, useState } from 'react';
export default function useNetworkConnection() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Listen to visibilitychange status
    window.addEventListener('visibilitychange', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
      window.removeEventListener('visibilitychange', handleStatusChange);
    };
  }, [isOnline]);
  return isOnline;
}
