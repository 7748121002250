// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import {
  getWorkHistoriesPurchasesProducts,
  updatePurchasesProduct,
} from 'apis/purchases/purchaseApi';
import TableLoading from 'components/atoms/TableLoading';
import { IconEditIC } from 'components/atoms/icons/IconEditIC';
import UpdateHistoryProductRegistration from 'components/molecules/UpdateHistoryProductRegistration';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { GetListParams } from 'types/api';
import { HistoryProduct, WorkHistories } from 'types/purchase';
import { DATE_FORMAT, DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import { mergeClasses, normalizeNumber } from 'util/commons';
import styles from './index.module.scss';
import WeightLimitExceededModal from '../WeightLimitExceededModal';

type PurchaseHistoryProductRegistrationProps = {
  active: boolean;
};

const PurchaseHistoryProductRegistration = ({
  active = false,
}: PurchaseHistoryProductRegistrationProps) => {
  const { t } = useTranslation();

  const [historyEditItem, setHistoryEditItem] = useState<HistoryProduct>();
  const [workHistories, setWorkHistories] = useState<WorkHistories>({
    page: 0,
    total: 0,
    data: [],
  });
  const [isWeightExceeded, setIsWeightExceeded] = useState<boolean>(false);
  const [editingFormValue, setEditingFormValue] = useState<HistoryProduct>();

  const fetchWorkHistoriesPurchasesProducts = (
    params?: GetListParams,
    forceRefresh?: boolean
  ) => {
    getWorkHistoriesPurchasesProducts({
      params: {
        ...params,
        take: PAGE_SIZE,
        page: params?.page || DEFAULT_PAGE,
      },
      successCallback: (res) => {
        if (forceRefresh) {
          setWorkHistories(res);
        } else {
          setWorkHistories({
            ...workHistories,
            ...res,
            data: [...workHistories.data, ...(res.data || [])],
          });
        }
      },
    });
  };

  const callApiUpdatePurchasesProduct = (form: HistoryProduct) => {
    updatePurchasesProduct(form.id, { form }, () => {
      fetchWorkHistoriesPurchasesProducts(
        {
          page: DEFAULT_PAGE,
        },
        true
      );
      toast.success(t('messages.M_011'));
      setHistoryEditItem(undefined);
    }).then(() => {
      setEditingFormValue(undefined);
    });
  };

  const handleEditProduct = (form: HistoryProduct) => {
    if (Number(form.weight) > Number(form.totalInputWeight)) {
      setIsWeightExceeded(true);
      setEditingFormValue(form);
    } else {
      callApiUpdatePurchasesProduct(form);
    }
  };

  const handleSubmitWeightExceeded = () => {
    setIsWeightExceeded(false);

    editingFormValue && callApiUpdatePurchasesProduct(editingFormValue);
  };

  useEffect(() => {
    if (active) {
      fetchWorkHistoriesPurchasesProducts();
    } else {
      setWorkHistories({
        page: DEFAULT_PAGE,
        total: 0,
        data: [],
      });
    }
  }, [active]);

  return (
    <div className={styles.productRegistration}>
      <div className={styles.table}>
        <table id={styles.tareWeightSubtractionTable}>
          <thead>
            <tr>
              <th className={styles.date}>
                {t('purchase_page.histories.date_time')}
              </th>
              <th className={styles.shipping}>
                {t('purchase_page.histories.registration_id')}
              </th>
              <th className={styles.shipping}>
                {t('purchase_page.receive.material_name')}
              </th>
              <th className={styles.weight}>
                {t('purchase_page.product_registration.processing_method')}
              </th>
              <th className={styles.weight}>
                {t('purchase_page.product_registration.material_type')}
              </th>
              <th className={styles.weight}>
                {t('purchase_page.product_registration.grade')}
              </th>
              <th className={styles.weight}>
                {t('purchase_page.product_registration.packing_style')}
              </th>
              <th className={styles.weight}>
                {t('purchase_page.product_registration.weight')}
              </th>
              <th className={styles.weight}>
                {t('purchase_page.product_registration.product_name')}
              </th>
              <th colSpan={2} className={styles.actionField}>
                {t('tare_management.label_action')}
              </th>
            </tr>
          </thead>
          <tbody>
            {workHistories.data.length > 0 ? (
              workHistories.data.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className={styles.date}>
                      <p title={item.datetime}>
                        {getFormatDateWithCurrentTimezone(
                          item.datetime,
                          DATE_FORMAT.slaYMDHm
                        )}
                      </p>
                    </td>
                    <td className={styles.item}>
                      <p>{item.id}</p>
                    </td>
                    <td className={styles.item}>
                      <p>{item.description}</p>
                    </td>
                    <td className={styles.item}>
                      <p>{item.processingMethod}</p>
                    </td>
                    <td className={styles.item}>
                      <p>{item.materialType.name}</p>
                    </td>
                    <td className={styles.item}>
                      <p>{item.grade.name}</p>
                    </td>
                    <td className={styles.item}>
                      <p>{item.packingStyle}</p>
                    </td>
                    <td className={styles.item}>
                      <p>
                        {normalizeNumber({ value: item.weight })}&nbsp;
                        <span>kg</span>
                      </p>
                    </td>
                    <td className={styles.item}>
                      <p>{item.name}</p>
                    </td>
                    <td className={styles.actionField1}>
                      <div className={styles.contentAction}>
                        <div
                          className={mergeClasses(styles.actionEdit, {
                            [styles.disabledBtn]: !!item.deliverySetting,
                          })}
                          onClick={() => {
                            !item.deliverySetting && setHistoryEditItem(item);
                          }}
                        >
                          <IconEditIC />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={12} className={styles.msgError}>
                  {t('messages.M_008')}
                </td>
              </tr>
            )}

            {workHistories.data?.length > 0 && (
              <TableLoading
                onNext={() => {
                  if (
                    workHistories.data.length >= PAGE_SIZE &&
                    workHistories.data.length < workHistories.total
                  ) {
                    fetchWorkHistoriesPurchasesProducts({
                      page: (workHistories.page || DEFAULT_PAGE) + 1,
                    });
                  }
                }}
              />
            )}
          </tbody>
        </table>
      </div>

      {historyEditItem && (
        <UpdateHistoryProductRegistration
          data={historyEditItem}
          onClose={() => setHistoryEditItem(undefined)}
          onSubmit={handleEditProduct}
        />
      )}

      <WeightLimitExceededModal
        open={isWeightExceeded}
        onClose={() => setIsWeightExceeded(false)}
        onSubmit={handleSubmitWeightExceeded}
        isDarkmode
      />
    </div>
  );
};

export default PurchaseHistoryProductRegistration;
