// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import { usePagination } from 'util/hooks/usePagination';
import { IconArrowLeft } from '../icons/IconArrowLeft';
import styles from './index.module.scss';
import { IconArrowRight } from '../icons/IconArrowRight';

type Props = {
  currentPage: number;
  totalRecords: number;
  pageSize: number;
  siblingCount?: number;
  onPageChange: (page: number) => void;
};

const Pagination: React.FC<Props> = ({
  currentPage,
  totalRecords,
  pageSize,
  siblingCount,
  onPageChange,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const paginationRange: any = usePagination({
    currentPage,
    totalRecords,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const lastPage = paginationRange[paginationRange.length - 1];

  const handleNextPage = () => {
    if (currentPage < lastPage) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  return (
    <div className={styles.paginationWrapper}>
      <ul className={styles.pagination}>
        <li onClick={handlePreviousPage}>
          <button
            className={`${currentPage === 1 ? styles.disableButton : ''} ${
              styles.btnArrow
            }`}
          >
            <IconArrowLeft />
          </button>
        </li>

        {paginationRange.map((pageNumber, i) => {
          return (
            <li
              key={i}
              className={styles.dots}
              onClick={() => {
                pageNumber !== '...' && onPageChange(pageNumber);
              }}
            >
              <button
                className={`${
                  pageNumber === currentPage ? styles.active : ''
                } ${pageNumber === '...' ? styles.dots : ''}`}
              >
                {pageNumber}
              </button>
            </li>
          );
        })}

        <li onClick={handleNextPage}>
          <button
            className={`${
              currentPage === lastPage ? styles.disableButton : ''
            } ${styles.btnArrow}`}
          >
            <IconArrowRight />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
