// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import CompressionQRScanning from 'components/organisms/CompressionQRScanning';
import { CompressionReceived } from 'components/organisms/CompressionReceived';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './Receives.module.scss';
import TabExtends from 'components/molecules/TabExtends';

function Receives() {
  const { t } = useTranslation();
  const history = useHistory();

  const tabItems = [
    {
      title: t('receives.tab_left_heading'),
      content: (
        <CompressionReceived
          active={!history.location.hash || history.location.hash === '#0'}
        />
      ),
    },
    {
      title: t('receives.tab_right_heading'),
      content: <CompressionQRScanning />,
    },
  ];

  return (
    <PrimaryTemplate
      h1={t('receives.heading')}
      className={styles.compressionReceives}
    >
      <TabExtends tabItems={tabItems} />
    </PrimaryTemplate>
  );
}

export default Receives;
