import { Modal } from 'components/atoms/Modal';
import { IconClose } from 'components/atoms/icons/IconClose';
import { Field, Form } from 'react-final-form';
import { Option } from 'util/Types';
import styles from './expectedDestination.module.scss';

type ExpectedProps = {
  title: string;
  onClose: VoidFunction;
  onSubmit: (stage: string) => void;
  options: Option[];
  submitButtonLabel: string;
  stage?: string;
  setStage?: (stage: string) => void;
};

const ExpectedDestinationModal = ({
  title,
  onClose,
  onSubmit,
  options,
  submitButtonLabel,
  stage,
  setStage,
}: ExpectedProps) => {
  return (
    <div className={styles.expected}>
      <Modal>
        <div className={styles.description}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>{title}</div>
            <div className={styles.top} onClick={onClose}>
              <IconClose />
            </div>
          </div>
          <Form
            onSubmit={(value) => {
              onSubmit(value.stage || stage);
            }}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit} className={styles.form}>
                <div className={styles.formItem}>
                  {options?.map((op) => (
                    <div className={styles.radio} key={`radio-${op.value}`}>
                      <label className={styles.container}>
                        <Field
                          name="stage"
                          component="input"
                          type="radio"
                          checked={stage ? stage === op.value : undefined}
                          value={op.value}
                          id={op.value}
                          onChange={(event) => {
                            setStage?.(event.target.value);
                            props.form.mutators.setValue(
                              'stage',
                              event.target.value
                            );
                          }}
                        />
                        {op.label}
                      </label>
                    </div>
                  ))}
                </div>
                <div className={styles.bottom}>
                  <button
                    className={styles.button}
                    type="submit"
                    disabled={!props.values.stage && !stage}
                  >
                    {submitButtonLabel}
                  </button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default ExpectedDestinationModal;
