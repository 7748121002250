import { createAxios } from 'ts/createAxios';

export const addSiteAdminCard = async (siteId: string, icCard: string) => {
  const api = createAxios();
  const data = await api.post('/sites/admin-cards', {
    siteId: siteId,
    card: icCard,
  });
  return data;
};

export const deleteSiteAdminCard = async (icCardId: number) => {
  const api = createAxios();
  const data = await api.delete(`/sites/admin-cards/${icCardId}`);
  return data;
};
