// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { createAxios } from 'ts/createAxios';
import { GetListParams } from 'types/api';
import { TeraokaImportHistories } from 'util/Types';
import { getApiPath } from 'util/commons';
import { API_ENDPOINTS } from 'util/endPoints';
import { handleError } from 'util/errorHandler';

export const getWorkHistoriesEmissionTeraokaImports = async (p: {
  params?: GetListParams;
  successCallback?: (data: TeraokaImportHistories) => void;
}) => {
  const { params, successCallback } = p;
  const api = createAxios();

  try {
    const { data } = await api.get<TeraokaImportHistories>(
      API_ENDPOINTS.WORK_HISTORIES_EMISSION_TERAOKA_IMPORTS,
      {
        params,
      }
    );

    if (data) {
      successCallback?.(data);
    }
  } catch (error) {
    handleError(error);
  }
};

export const downloadTeraokaCsv = async (
  id: string,
  successCallback?: (data: { file: string }) => void
) => {
  const api = createAxios();

  try {
    const { data } = await api.get(
      getApiPath(
        API_ENDPOINTS.DOWNLOAD_CSV_WORK_HISTORIES_EMISSION_TERAOKA_IMPORTS,
        {
          id,
        }
      )
    );
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};
