// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';

type PortalProps = {
  children: React.ReactNode;
  className?: string;
  selector?: string;
};

const Portal: React.FC<PortalProps> = ({
  className,
  children,
  selector = '#portal',
}) => {
  const [targetEl, setTargetEl] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const el = document.querySelector(selector);

    if (!document.querySelector(selector)) {
      const p = document.createElement('div');
      const selectorSymbol = selector.substring(0, 1);
      const selectorStr = selector.substring(1, selector.length);

      if (selectorSymbol === '#') {
        p.id = selectorStr;
      } else if (selectorSymbol === '.') {
        p.classList.add(selectorStr);
      } else {
        p.id = 'portal';
      }

      if (className) {
        p.classList.add(className);
      }

      document.body.appendChild(p);
      setTargetEl(p);
    } else {
      setTargetEl(el as HTMLDivElement);
    }
  }, [className, selector]);

  useEffect(() => {
    return () => {
      if (targetEl) {
        try {
          document.body.removeChild(targetEl);
        } catch {
          return;
        }
        setTargetEl(null);
      }
    };
  }, [targetEl]);

  if (targetEl) {
    return ReactDom.createPortal(children, targetEl);
  }

  return null;
};

export default Portal;
