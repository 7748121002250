// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import { Form } from 'react-final-form';
import styles from './index.module.scss';
import { SelectField } from '../SelectField';
import { useTranslation } from 'react-i18next';
import {
  SettingDeliveryFormData,
  fuelTypeList,
  WasteFuelUnits,
} from 'util/settingDeliveryType';
import { validateRequiredField, validateSelectField } from 'util/validator';
import snakeCase from 'lodash/snakeCase';
import { SiteItem } from 'components/organisms/RecycleChainDesignAddSiteForm';
import { SelectFieldMultipleChoise } from '../SelectFieldMultipleChoise';

interface Props {
  wasteFuelUnits: WasteFuelUnits[];
  initForm: SettingDeliveryFormData;
  handleSubmit: (settingDeliveryFormData: SettingDeliveryFormData) => void;
  sites?: SiteItem[];
}

const SettingDeliveryForm: React.FC<Props> = ({
  wasteFuelUnits,
  initForm,
  handleSubmit,
  sites,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.createBody}>
      <Form<SettingDeliveryFormData>
        initialValues={initForm}
        validate={(values) => {
          return {
            fuelOfType: validateSelectField('燃料の種類', values.fuelOfType),
            truckOfType: validateSelectField('最大積載量', values.truckOfType),
            ...(sites && {
              siteIds: validateRequiredField(values.siteIds, '回収拠点'),
            }),
          };
        }}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        onSubmit={handleSubmit}
      >
        {(props) => {
          return (
            <form className={styles.formInput}>
              <div className={styles.inputFrame}>
                {sites && (
                  <SelectFieldMultipleChoise
                    options={sites.map(({ stageSiteId, name }) => ({
                      id: stageSiteId,
                      name,
                    }))}
                    name="siteIds"
                    label="回収拠点"
                    placeholder="回収拠点"
                    onChange={(tags) =>
                      props.form.mutators.setValue(
                        'siteIds',
                        tags.map(({ id }) => id)
                      )
                    }
                    selectedOptions={props.values.siteIds || []}
                    required
                  />
                )}

                <SelectField
                  required
                  name="fuelOfType"
                  options={fuelTypeList}
                  label="燃料の種類"
                  placeholder="燃料の種類"
                  onChange={() => {
                    props.form.mutators.setValue('truckOfType', '');
                  }}
                />
                <SelectField
                  disabled={!props.values.fuelOfType}
                  required
                  options={wasteFuelUnits.reduce(
                    (res: { label: string; value: string }[], item) => {
                      if (item.fuelType === props.values.fuelOfType) {
                        res.push({
                          label: t(
                            `recycle_chains.setting_delivery_modal.truck_of_type.${snakeCase(
                              item.truckType
                            )}`
                          ),
                          value: item.truckType,
                        });
                      }

                      return res;
                    },
                    []
                  )}
                  name="truckOfType"
                  label="最大積載量 (kg)"
                  placeholder="最大積載量 (kg)"
                />
              </div>
              <div className={styles.submit}>
                <button
                  className={`${styles.submitButton} ${
                    props.invalid ? styles.disableButton : ''
                  }`}
                  disabled={props.invalid}
                  onClick={props.handleSubmit}
                  type="button"
                >
                  保存する
                </button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default SettingDeliveryForm;
