// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Floor } from '../SelectFloorCard';
import { Tenant } from '../SelectTenantCard';
import styles from './SelectSectionCard.module.scss';
export interface Section {
  id: number;
  name: string;
  siteId: string;
  floors?: Floor[];
  tenants?: Tenant[];
}

interface Props {
  section: Section;
  isSelected: boolean;
  onSelectSection: (section: Section) => void;
}
const SelectSectionCard: React.FC<Props> = ({
  section,
  isSelected,
  onSelectSection,
}) => {
  return (
    <div
      key={section.id}
      className={`${styles.section} ${isSelected ? styles.selected : ''}`}
      onClick={() => {
        onSelectSection(section);
      }}
    >
      <p title={section.name}>{section.name}</p>
    </div>
  );
};

export default SelectSectionCard;
