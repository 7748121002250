// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  onClick?: VoidFunction;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const IconLogOut = ({ onClick }: Props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 16L21.5 12M21.5 12L17.5 8M21.5 12H7.5M13.5 16V17C13.5 17.7956 13.1839 18.5587 12.6213 19.1213C12.0587 19.6839 11.2956 20 10.5 20H6.5C5.70435 20 4.94129 19.6839 4.37868 19.1213C3.81607 18.5587 3.5 17.7956 3.5 17V7C3.5 6.20435 3.81607 5.44129 4.37868 4.87868C4.94129 4.31607 5.70435 4 6.5 4H10.5C11.2956 4 12.0587 4.31607 12.6213 4.87868C13.1839 5.44129 13.5 6.20435 13.5 7V8"
        stroke="#6F7175"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
