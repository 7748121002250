// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Modal } from 'components/atoms/Modal';
import React from 'react';
import { IconClose } from 'components/atoms/icons/IconClose';
import styles from './WarningModal.module.scss';
import { mergeClasses } from 'util/commons';

interface Props {
  title: string;
  content: string;
  buttonConfirm: string;
  onClose: () => void;
  onConfirm: () => void;
  isDarkMode?: boolean;
}

const WarningModal: React.FC<Props> = ({
  title,
  content,
  buttonConfirm,
  onClose,
  onConfirm,
  isDarkMode,
}) => {
  return (
    <div
      className={mergeClasses(styles.warningModal, {
        [styles.darkMode]: isDarkMode,
      })}
    >
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.modalHeader}>
            <p className={styles.modalTitle}>{title}</p>
            <div className={styles.buttonClose}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <div className={styles.modalBody}>
            <p>{content}</p>
          </div>

          <div className={styles.modalFooter}>
            <button
              className={styles.submitButton}
              type="submit"
              onClick={onConfirm}
            >
              {buttonConfirm}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WarningModal;
