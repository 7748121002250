// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Tab } from 'components/molecules/Tab';
import ProductRegistrationAcceptanceOrganism from 'components/organisms/ProductRegistrationAcceptanceOrganism';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './ProductRegistration.module.scss';
import PurchaseTemplate from 'components/templates/PurchaseTemplate';
import PurchaseRegisResource from 'components/organisms/PurchaseRegisResource';

const ProductRegistration = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <PurchaseTemplate
      title={t('purchase_page.receive.tab_right_heading') || ''}
      className={styles.productRegistrationPage}
    >
      <Tab
        className={styles.tabAcceptance}
        tabItems={[
          t('purchase_page.receive.tab_left_heading'),
          t('purchase_page.receive.tab_right_heading'),
        ]}
        defaultTabIndex={history.location.hash == '#1' ? 1 : 0}
        renderContent={({ index }) => (
          <div>
            {index === 0 && (
              <ProductRegistrationAcceptanceOrganism
                active={history.location.hash === '#0'}
              />
            )}

            {index === 1 && (
              <PurchaseRegisResource active={history.location.hash === '#1'} />
            )}
          </div>
        )}
      ></Tab>
    </PurchaseTemplate>
  );
};

export default ProductRegistration;
