import { IconTriangle } from 'components/atoms/icons/IconTriangle';
import { Field } from 'react-final-form';
import { Option } from 'util/Types';
import styles from './LabelOrSelectField.module.scss';

type LabelOrSelectFieldProps = {
  options: Option[];
  label: string;
  fieldName: string;
};

const LabelOrSelectField = (props: LabelOrSelectFieldProps) => {
  return (
    <div className={styles.field}>
      <div className={styles.labelBox}>
        <div className={styles.label}>{props.label}</div>
      </div>
      <div className={styles.fieldBox}>
        <Field name={props.fieldName}>
          {({ input }) =>
            props.options?.length > 1 ? (
              <div className={styles.selectBox}>
                <select {...input}>
                  {props.options.map((x) => (
                    <option key={x.value} value={x.value}>
                      {x.label}
                    </option>
                  ))}
                </select>
                <div className={styles.icon}>
                  <IconTriangle />
                </div>
              </div>
            ) : (
              <div className={styles.value}>
                {props.options.find(({ value }) => value === input.value)
                  ?.label || input.value}
              </div>
            )
          }
        </Field>
      </div>
    </div>
  );
};

export default LabelOrSelectField;
