// gkc_hash_code : 596AVJ0U6O4MG74XBQH62VSQ6T
import { HeatmapLayer } from '@react-google-maps/api';
import MapBox from 'components/atoms/MapBox';
import { memo, useMemo } from 'react';
import { THeatMap } from 'types/purchase';
import { TOKYO_COORDINATES } from 'util/ConstantValues';
import styles from './PurchaseResourceSearchMap.module.scss';

const Gradients = {
  first: ['rgba(155, 234, 107, 0)', 'rgba(155, 234, 107, 1)'],
  second: ['rgba(179, 218, 96, 0)', 'rgba(179, 218, 96, 1)'],
  third: ['rgba(213, 193, 77, 0)', 'rgba(213, 193, 77, 1)'],
  fourth: ['rgba(244, 170, 60, 0)', 'rgba(244, 170, 60, 1)'],
};

type PurchaseResourceSearchMapProps = {
  heatMapData: THeatMap[];
  zoom?: number;
};

const MAX_HEATMAP_WEIGHT = 10000000;
const MIN_HEATMAP_WEIGHT = 700000;

const WeightCalc = {
  first: MAX_HEATMAP_WEIGHT / 4,
  second: MAX_HEATMAP_WEIGHT / 2,
  third: (MAX_HEATMAP_WEIGHT * 3) / 4,
  fourth: MAX_HEATMAP_WEIGHT,
};

const HeatMapComp = ({
  heatMapData,
  gradient,
  maxIntensity,
}: Pick<PurchaseResourceSearchMapProps, 'heatMapData'> & {
  gradient: string[];
  maxIntensity: number;
}) => {
  const createHeatPoint = ({ latitude, longitude, weight }: THeatMap) => {
    const cvWeight = Number(weight);

    return {
      location: new google.maps.LatLng(Number(latitude), Number(longitude)),
      weight:
        cvWeight > MAX_HEATMAP_WEIGHT
          ? MAX_HEATMAP_WEIGHT
          : cvWeight < MIN_HEATMAP_WEIGHT
          ? MIN_HEATMAP_WEIGHT
          : cvWeight,
      opacity: 1,
    };
  };

  const data = useMemo(
    () =>
      heatMapData.reduce(
        (
          rs: {
            location: google.maps.LatLng;
            weight: number;
            opacity: number;
          }[],
          { latitude, longitude, weight }
        ) => {
          if (latitude != null && longitude != null) {
            rs.push(createHeatPoint({ latitude, longitude, weight }));
          }

          return rs;
        },
        []
      ),
    [heatMapData]
  );

  return (
    <HeatmapLayer
      data={data}
      options={{
        gradient,
        dissipating: true,
        radius: 40,
        opacity: 0.8,
        maxIntensity,
      }}
    />
  );
};

const PurchaseResourceSearchMap = ({
  heatMapData,
  zoom = 4, // default zoom to view all japan
}: PurchaseResourceSearchMapProps) => {
  const [first, second, third, fourth] = useMemo(
    () =>
      [...heatMapData]
        .sort((a, b) => Number(a.weight) - Number(b.weight))
        .reduce(
          (rs: [THeatMap[], THeatMap[], THeatMap[], THeatMap[]], item) => {
            const weight = Number(item.weight);

            if (weight <= WeightCalc.first) {
              rs[0].push(item);
            } else if (weight <= WeightCalc.second) {
              rs[1].push(item);
            } else if (weight <= WeightCalc.third) {
              rs[2].push(item);
            } else {
              rs[3].push(item);
            }

            return rs;
          },
          [[], [], [], []]
        ),
    [heatMapData]
  );

  return (
    <div className={styles.purchaseResourceSearchMap}>
      <MapBox
        isDarkMode
        bounds={[
          {
            lat: TOKYO_COORDINATES.lat,
            lng: TOKYO_COORDINATES.lng,
          } as unknown as google.maps.LatLngAltitude,
        ]}
        drawInMap={[
          <HeatMapComp
            heatMapData={first}
            gradient={Gradients.first}
            maxIntensity={WeightCalc.first}
          />,
          <HeatMapComp
            heatMapData={second}
            gradient={Gradients.second}
            maxIntensity={WeightCalc.second}
          />,
          <HeatMapComp
            heatMapData={third}
            gradient={Gradients.third}
            maxIntensity={WeightCalc.third}
          />,
          <HeatMapComp
            heatMapData={fourth}
            gradient={Gradients.fourth}
            maxIntensity={WeightCalc.fourth}
          />,
        ]}
        zoom={zoom}
        isCenter={false}
      />
    </div>
  );
};

export default memo(PurchaseResourceSearchMap);
