// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { fetchTudetWithAddress } from 'apis/operator_site/siteApi';
import { useFetchDistrictsQuery } from 'apis/purchase/purchaseApi';
import {
  useFetchAllMaterialQuery,
  useFetchGradesQuery,
} from 'apis/site_recycle/siteCategoryApi';
import CheckboxField from 'components/atoms/CheckboxField';
import RadioField from 'components/atoms/RadioField';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getSlaFormatYearMonthDate } from 'ts/formatDate';
import { MaterialStatisticSearch } from 'types/dashboard';
import {
  MaterialSiteTypeGradeStatistic,
  PurchaseLocationParams,
} from 'types/purchase';
import { OperatorType } from 'util/Enums';
import { CalendarField } from '../CalendarField';
import CoordinateInput from '../CoordinateInput';
import PurchaseResourceSearchTable from '../PurchaseResourceSearchTable';
import { SelectField, SelectOption } from '../SelectField';
import { SelectFieldMultipleChoise } from '../SelectFieldMultipleChoise';
import TreeSelect from '../TreeSelect';
import styles from './PurchaseResourceSearchForm.module.scss';
import BigNumber from 'bignumber.js';
import { useMemo, useState } from 'react';
import PurchaseResourceSearchModal from '../PurchaseResourceSearchModal';

export const DEFAULT_RADIUS_VALUE = 100;

type PurchaseResourceSearchFormProps = {
  search: MaterialStatisticSearch;
  setSearch: (values: MaterialStatisticSearch) => void;
  data: MaterialSiteTypeGradeStatistic[];
  hasTable?: boolean;
};

const PurchaseResourceSearchForm = ({
  search,
  setSearch,
  data,
  hasTable,
}: PurchaseResourceSearchFormProps) => {
  const { t } = useTranslation();
  const [isTableDetailOpened, setIsTableDetailOpened] =
    useState<boolean>(false);

  const { data: grades } = useFetchGradesQuery({
    siteTypes: [OperatorType.Emissions],
    activeSites: true,
  });

  const { data: materials } = useFetchAllMaterialQuery(true);

  const { data: locations } = useFetchDistrictsQuery({ isEmissionsType: true });

  const radius: SelectOption[] = [
    {
      label: '100km',
      value: DEFAULT_RADIUS_VALUE,
    },
    {
      label: '300km',
      value: 300,
    },
    {
      label: '500km',
      value: 500,
    },
  ];

  const resetLandmark = (
    values: MaterialStatisticSearch
  ): MaterialStatisticSearch => {
    values.lat = undefined;
    values.long = undefined;
    values.address = undefined;
    values.radius = DEFAULT_RADIUS_VALUE;

    return values;
  };

  const resetMunicipalities = (
    values: MaterialStatisticSearch
  ): MaterialStatisticSearch => {
    values.locations = [];
    values.tree = [];

    return values;
  };

  const totalWeight = useMemo(() => {
    if (!data?.length) {
      return '0';
    }

    return data
      .reduce(
        (accumulator, currentValue) =>
          new BigNumber(accumulator).plus(new BigNumber(currentValue.weight)),
        new BigNumber(0)
      )
      .dividedBy(1000)
      .toString();
  }, [data]);

  return (
    <>
      <Form
        onSubmit={async (values: MaterialStatisticSearch) => {
          if (values.where === 'all') {
            values = resetLandmark(values);
            values = resetMunicipalities(values);
          }

          if (values.where === 'municipalities') {
            const tree = values.tree;
            const locations = tree?.map((x) => ({
              province: x.value,
              districts: x.subValues?.map((y) => String(y.value)) || [],
            })) as PurchaseLocationParams[];
            values.locations = locations;
            values = resetLandmark(values);
          }

          let hasError = false;

          if (values.where === 'landmark' && values.address) {
            await fetchTudetWithAddress(
              values.address,
              (data) => {
                if (data) {
                  values.lat = Number(data?.latitude);
                  values.long = Number(data?.longitude);
                  values = resetMunicipalities(values);
                } else {
                  toast.error(t('purchase_page.resource_search.coordinates'));
                  hasError = true;
                }
              },
              false
            );
          }

          if (!values.materialOption) {
            values.materialNames = [];
          }

          if (!values.gradeOption) {
            values.grades = [];
          }

          if (!hasError) {
            setSearch(values);
          }
        }}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        initialValues={search}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div className={styles.analysis_right}>
              <div className={styles.analysis_right_title}>
                {t('purchase_page.resource_search.search')}
              </div>
              <div className={styles.analysis_right_filter}>
                <div className={styles.analysis_right_filter_when}>
                  <div className={styles.analysis_right_filter_when_title}>
                    {t('purchase_page.resource_search.date_range')}
                  </div>
                  <div className={styles.analysis_right_filter_when_form}>
                    <CalendarField
                      value={getSlaFormatYearMonthDate(props.values.dateFrom)}
                      maxDate={new Date(props.values.dateTo)}
                      onChange={(value) => {
                        props.form.mutators.setValue('dateFrom', value);
                      }}
                      isDarkMode
                      readOnly
                    />

                    <CalendarField
                      value={getSlaFormatYearMonthDate(props.values.dateTo)}
                      maxDate={new Date()}
                      minDate={new Date(props.values.dateFrom)}
                      onChange={(value) => {
                        props.form.mutators.setValue('dateTo', value);
                      }}
                      isDarkMode
                      readOnly
                    />
                  </div>
                </div>
                <div className={styles.analysis_right_filter_where}>
                  <div className={styles.analysis_right_filter_where_text}>
                    {t('purchase_page.resource_search.area')}
                  </div>
                  <div className={styles.analysis_right_filter_where_radios}>
                    <Field
                      name="where"
                      type="radio"
                      component={RadioField}
                      title={t('purchase_page.resource_search.all')}
                      value="all"
                    />
                    <Field
                      name="where"
                      type="radio"
                      component={RadioField}
                      title={t(
                        'purchase_page.resource_search.filter_municipalities'
                      )}
                      value="municipalities"
                    />
                    {props.values.where === 'municipalities' && (
                      <div className={styles.whereSelect}>
                        <TreeSelect
                          className={styles.treeSelect}
                          selectedValues={props.values.tree}
                          onChange={(value) =>
                            props.form.mutators.setValue('tree', value)
                          }
                          names={['province', 'district']}
                          placeholder={t(
                            'purchase_page.resource_search.province_and_district'
                          )}
                          options={locations}
                        />
                      </div>
                    )}
                    <Field
                      name="where"
                      type="radio"
                      component={RadioField}
                      title={t(
                        'purchase_page.resource_search.filter_landmark_radious'
                      )}
                      value="landmark"
                    />
                    {props.values.where === 'landmark' && (
                      <div className={styles.whereSelect}>
                        <CoordinateInput
                          name="address"
                          placeholder={t('download.thead_address') || ''}
                          value={props.values.address}
                          setValue={(value) => {
                            props.form.mutators.setValue('address', value);

                            if (!value) {
                              props.form.mutators.setValue('lat', undefined);
                              props.form.mutators.setValue('long', undefined);
                            }
                          }}
                        />
                        <SelectField
                          value={props.values.radius?.toString()}
                          name="radius"
                          options={radius}
                          isDarkmode
                          onChange={(value) =>
                            props.form.mutators.setValue(
                              'radius',
                              Number(value)
                            )
                          }
                          disabled={!props.values.address}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.analysis_right_filter_what}>
                  <div className={styles.title}>
                    {t('purchase_page.resource_search.potential_resources')}
                  </div>
                  <div>
                    <Field
                      name="materialOption"
                      type="checkbox"
                      component={CheckboxField}
                      title={t(
                        'purchase_page.resource_search.filter_material_type'
                      )}
                    />
                  </div>
                  {props.values.materialOption && (
                    <div className={styles.right}>
                      <div className={styles.container}>
                        <SelectFieldMultipleChoise
                          name="materialNames"
                          options={
                            materials?.map((x) => ({
                              id: x.materialName,
                              name: x.materialName,
                            })) || []
                          }
                          onChange={(items) => {
                            const ids = items.map((x) => x.id) as string[];
                            props.form.mutators.setValue('materialNames', ids);
                          }}
                          placeholder={t(
                            'purchase_page.product_registration.material_name'
                          )}
                          selectedOptions={props.values.materialNames || []}
                          darkMode
                        />
                      </div>
                    </div>
                  )}
                  <div>
                    <Field
                      name="gradeOption"
                      type="checkbox"
                      component={CheckboxField}
                      title={t('purchase_page.resource_search.filter_grade')}
                    />
                  </div>
                  {props.values.gradeOption && (
                    <div className={styles.right}>
                      <div className={styles.container}>
                        <SelectFieldMultipleChoise
                          name="grades"
                          options={
                            grades?.map((x) => ({
                              id: x,
                              name: x,
                            })) || []
                          }
                          onChange={(items) => {
                            const ids = items.map((x) => x.id) as string[];
                            props.form.mutators.setValue('grades', ids);
                          }}
                          placeholder={t('purchase_page.resource.grade')}
                          selectedOptions={props.values.grades || []}
                          darkMode
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.analysis_right_filter_btn}>
                  <button
                    className={styles.primary}
                    disabled={!props.valid}
                    type="submit"
                  >
                    {t('purchase_page.resource_search.search_label')}
                  </button>
                </div>
              </div>

              {hasTable && (
                <PurchaseResourceSearchTable
                  onClick={() => setIsTableDetailOpened(true)}
                  data={data.slice(0, 5)}
                  totalWeight={totalWeight}
                  totalRecord={data.length}
                />
              )}
            </div>
          </form>
        )}
      </Form>

      {isTableDetailOpened && (
        <PurchaseResourceSearchModal
          title={t('purchase_page.resource_search.search_modal_title')}
          data={data}
          onClose={() => setIsTableDetailOpened(false)}
          totalWeight={totalWeight}
        />
      )}
    </>
  );
};

export default PurchaseResourceSearchForm;
