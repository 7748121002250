// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, setUserAsAdminSite } from 'redux/slices/userSlice';
import { TenantAuthen } from 'util/Enums';
import styles from './TenantAuthen.module.scss';
import Html5QrcodePlugin from 'components/molecules/Html5QrcodePlugin';
import ScanICCardPlugin from 'components/molecules/ScanICCardPlugin';
import { fetchTenantId } from 'apis/tenants';
import { useHistory } from 'react-router-dom';
import { Path } from 'routes';
import { selectTenants, setTenants } from 'redux/slices/siteInfoSlice';
import { DbManager } from 'util/DbManager';
import { createAxios } from 'ts/createAxios';
import { Tenant } from 'components/molecules/SelectTenantCard';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from 'util/endPoints';

const TennantAuthenPage: React.FC = () => {
  const user = useSelector(selectUser);
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tenants = useSelector(selectTenants);

  const [isFetching, setIsFetching] = useState(false);

  const onScanFinish = async ({
    authenMethod,
    code,
  }: {
    authenMethod: TenantAuthen;
    code: string;
  }) => {
    setIsFetching(true);

    const cachedSites = await DbManager.sites.toArray();
    if (authenMethod === TenantAuthen.ICCard && cachedSites.length) {
      const isSiteAdminCard = cachedSites.find((site) =>
        site.adminCards.find((card) => card.card === code)
      );
      if (isSiteAdminCard) {
        dispatch(setUserAsAdminSite());
        history.push(`${Path.selectTenant}`);
        toast.warning(t('messages.M_temporary_admin_site_login'));
        return;
      }
    }

    fetchTenantId({ authenMethod, code }, async (data) => {
      if (data.id != null) {
        let cachedTenants: Tenant[] | undefined = undefined;

        if (cachedSites.length) {
          cachedTenants = await DbManager.tenants.orderBy('name').toArray();
        }

        if (
          !(cachedTenants ?? tenants).find(({ id }) => id === Number(data.id))
        ) {
          await createAxios()
            .get(API_ENDPOINTS.TENANTS, {
              params: {
                siteId: user.siteId,
              },
            })
            .then(async (response) => {
              dispatch(setTenants(response.data.tenants));

              await DbManager.tenants.clear();
              await DbManager.tenants.bulkAdd(response.data.tenants);
            });
        }

        history.push(`${Path.emissionNew}?tenantId=${data.id}`);
      }
    }).finally(() => {
      setIsFetching(false);
      if (authenMethod === TenantAuthen.Barcode) {
        const btnStart = document.querySelector(
          '#html5-qrcode-button-camera-start'
        ) as HTMLButtonElement;
        btnStart?.click();
      }
    });
  };

  const onNewScanBarcode = (decodedText: string) => {
    try {
      window.sendScannedText(decodedText);
    } catch (e) {
      //
    }
    const buttonStop = document.querySelector(
      '#html5-qrcode-button-camera-stop'
    ) as HTMLButtonElement;
    buttonStop.click();
    !isFetching &&
      onScanFinish({ authenMethod: TenantAuthen.Barcode, code: decodedText });
  };
  const onNewScanICCard = (iccard: string) => {
    try {
      window.sendScannedText(iccard);
    } catch (e) {
      //
    }
    !isFetching &&
      onScanFinish({ authenMethod: TenantAuthen.ICCard, code: iccard });
  };
  return (
    <div className={styles.tenantAuthen}>
      {user.tenantAuthen === TenantAuthen.Barcode && (
        <Html5QrcodePlugin
          fps={10}
          qrbox={{ width: 250, height: 250 }}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanBarcode}
        />
      )}
      {user.tenantAuthen === TenantAuthen.ICCard && (
        <ScanICCardPlugin onScanSuccess={onNewScanICCard} />
      )}
    </div>
  );
};

export default TennantAuthenPage;
