// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Resource, Site } from 'types/purchase';
import { mergeClasses, normalizeNumber } from 'util/commons';
import styles from './PurchaseResourceMapBox.module.scss';
import { OperatorType } from 'util/Enums';
import BubbleMap, { BubbleMapItem } from '../BubbleMap';

type PurchaseResourceMapBoxProps = {
  isShowMap: boolean;
  setShowMap: (value: boolean) => void;
  resources: Resource[];
};

type PurchaseMergeSiteType = {
  site: Site;
  received: number;
  processed: number;
  delivery: number;
  totalWeight: number;
  type: string;
};

type PurchaseMapBubbleItem = BubbleMapItem<
  Omit<PurchaseMergeSiteType, 'site' | 'totalWeight'> & {
    siteId: number;
    siteName: string;
  }
>;

const TD = ({
  value,
  leftSide,
}: {
  value: string | number | null;
  leftSide?: boolean;
}) => (
  <td
    className={mergeClasses('text-left', styles.tdContent, {
      [styles.pr]: leftSide,
    })}
  >
    {value}
  </td>
);

const PurchaseResourceMapBox = ({
  isShowMap,
  setShowMap,
  resources = [],
}: PurchaseResourceMapBoxProps) => {
  const { t } = useTranslation();

  const data = useMemo(() => {
    return resources.reduce((acc: PurchaseMapBubbleItem[], currentItem) => {
      const existingItemIndex = acc.findIndex(
        (item) => item.siteId === currentItem.site.id
      );

      const value = Number(currentItem.weight);
      const statusInLowerCase = currentItem.status?.toLowerCase() ?? '';

      if (existingItemIndex !== -1) {
        acc[existingItemIndex].value += value;

        if (acc[existingItemIndex][statusInLowerCase] != null) {
          acc[existingItemIndex][statusInLowerCase] += value;
        }
      } else {
        const val: PurchaseMapBubbleItem = {
          siteId: currentItem.site.id,
          siteName: currentItem.site.name,
          lng: currentItem.site.longitude,
          lat: currentItem.site.latitude,
          value,
          delivery: 0,
          processed: 0,
          received: 0,
          type: currentItem.type,
        };

        if (val[statusInLowerCase] != null) {
          val[statusInLowerCase] = value;
        }

        acc.push(val);
      }

      return acc;
    }, []);
  }, [resources]);

  const handleShow = () => {
    setShowMap(!isShowMap);
  };

  const mapTooltipDraw = useCallback(
    (item: PurchaseMapBubbleItem) => (
      <table className="'primary-table'">
        <tbody>
          <tr className={styles.bold}>
            <TD value={item.siteName} leftSide />
            <TD
              value={`: ${normalizeNumber({
                value: item.value,
              })} kg`}
            />
          </tr>
          {item.type !== OperatorType.Emissions && (
            <>
              <tr>
                <TD value={t('purchase_page.resource.received')} leftSide />
                <TD
                  value={`: ${normalizeNumber({
                    value: item.received,
                  })} kg`}
                />
              </tr>
              <tr>
                <TD value={t('purchase_page.resource.processed')} leftSide />
                <TD
                  value={`: ${normalizeNumber({
                    value: item.processed,
                  })} kg`}
                />
              </tr>
              <tr>
                <TD value={t('purchase_page.resource.delivery')} leftSide />
                <TD
                  value={`: ${normalizeNumber({
                    value: item.delivery,
                  })} kg`}
                />
              </tr>
            </>
          )}
        </tbody>
      </table>
    ),
    [t]
  );

  return (
    <div
      className={mergeClasses(styles.mapContainer, isShowMap ? 'max' : 'min')}
    >
      <div className={styles.headerContainer}>
        <div className={styles.title}>
          {t('purchase_page.resource.inventory_map')}
        </div>
        <div className={styles.buttons}>
          <div className={styles.btnHidden} onClick={handleShow}>
            {isShowMap
              ? t('purchase_page.resource.hidden')
              : t('purchase_page.resource.see_map')}
          </div>
        </div>
      </div>

      {isShowMap && (
        <div className={styles.mapView}>
          <BubbleMap data={data} tooltipDraw={mapTooltipDraw} />
        </div>
      )}
    </div>
  );
};

export default PurchaseResourceMapBox;
