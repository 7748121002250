// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { setTotalUnassignedBrands } from 'redux/slices/localSettingSlice';
import { store } from 'redux/store';
import { createAxios } from 'ts/createAxios';
import {
  BrandDetail,
  BrandItemOption,
  BrandSiteObj,
  GetOperatorsBrandItemsParams,
  OperatorsBrandItem,
  SiteBrandItemFormData,
} from 'types/brand';
import { API_ENDPOINTS } from 'util/endPoints';
import { handleError } from 'util/errorHandler';
import { getApiPath } from 'util/commons';

export const getTotalUnassignedBrands = async () => {
  try {
    const { data } = await createAxios().get(
      API_ENDPOINTS.TOTAL_UNASSIGNED_BRANDS
    );
    store.dispatch(setTotalUnassignedBrands(data?.totalUnassignedItems));
  } catch (error) {
    handleError(error);
  }
};

export const getBrandItemOptions = async (p: {
  brandId: string;
  successCallback?: (data: BrandItemOption[]) => void;
}) => {
  const { brandId, successCallback } = p;

  try {
    const { data } = await createAxios().get(
      getApiPath(API_ENDPOINTS.BRAND_ITEMS, { brand_id: brandId })
    );

    successCallback?.(data.brandItems);
  } catch (error) {
    handleError(error);
  }
};

export const updateSiteBrandItems = async (p: {
  brandId: string;
  params: SiteBrandItemFormData[];
  successCallback?: (msg: string) => void;
}) => {
  const { brandId, params, successCallback } = p;

  try {
    const { data } = await createAxios().post(
      getApiPath(API_ENDPOINTS.SITE_BRAND_ITEMS, { brand_id: brandId }),
      {
        siteBrandItems: params,
      }
    );

    if (data) {
      successCallback?.(data);
      getTotalUnassignedBrands();
    }
  } catch (error) {
    handleError(error);
  }
};

export const getOperatorsBrandItems = async (p: {
  brandId: string;
  params?: GetOperatorsBrandItemsParams;
  successCallback?: (
    data: OperatorsBrandItem[],
    meta?: GetOperatorsBrandItemsParams & { total: number }
  ) => void;
}) => {
  const { brandId, params, successCallback } = p;

  try {
    const { data } = await createAxios().get(
      getApiPath(API_ENDPOINTS.OPERATORS_BRAND_ITEMS, { brand_id: brandId }),
      {
        params,
      }
    );

    if (data) {
      successCallback?.(data.data, {
        ...params,
        page: data.page,
        total: data.total,
      });
    }
  } catch (error) {
    handleError(error);
  }
};

export const getBrandSites = async (p: {
  brandId: string;
  successCallback?: (data: BrandSiteObj[]) => void;
}) => {
  const { brandId, successCallback } = p;

  try {
    const { data } = await createAxios().get(
      getApiPath(API_ENDPOINTS.BRAND_SITES, { brand_id: brandId })
    );

    if (data) {
      successCallback?.(data);
    }
  } catch (error) {
    handleError(error);
  }
};

export const getBrandDetail = async (p: {
  brandId: string;
  successCallback?: (data: BrandDetail) => void;
}) => {
  const { brandId, successCallback } = p;

  try {
    const { data } = await createAxios().get(
      getApiPath(API_ENDPOINTS.BRAND_DETAIL, { brand_id: brandId })
    );

    if (data) {
      successCallback?.(data);
    }
  } catch (error) {
    handleError(error);
  }
};
