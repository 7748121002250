// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './CalendarTextField.scss';
import React from 'react';
import { getFormatDate } from 'ts/formatDate';
import { IconCalendar } from 'components/atoms/icons/IconCalendar';

interface Props {
  className?: string;
  value?: string;
  defaultValue?: string;
}

export const EMPTY_DATE = '---- / -- / --';
export function CalendarTextField({ className, value, defaultValue }: Props) {
  const empty = defaultValue ? defaultValue : EMPTY_DATE;
  return (
    <div className={className ?? 'calendar-text-field'}>
      {value ? getFormatDate(value.toString(), 'YYYY/MM/DD') : empty}
      <span className="icon">
        <IconCalendar />
      </span>
    </div>
  );
}
