// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './InputTagField.module.scss';
import { useRef, useState } from 'react';
import { Label } from 'components/atoms/Label';
import { ErrorText } from 'components/atoms/ErrorText';
import SuggestionList from 'components/atoms/SuggestionList';
import Tag from 'components/atoms/Tag';
import useOutsideClick from 'util/hooks/useClickOutside';
import { useTranslation } from 'react-i18next';

export interface Suggestion {
  name: string;
}

interface Props {
  name?: string;
  label?: string | null;
  tagValues: string[];
  type?: string;
  placeholder?: string;
  dataSuggestions?: Suggestion[];
  maxlength?: number;
  maxTags?: number;
  required?: boolean;
  error?: string;
  onChangeData?: (tagsValues: string[]) => void;
  maxTagError?: string;
  onError?: (isError: boolean) => void;
  className?: string;
  description?: string;
  uniqueTags?: boolean;
  validRegex?: RegExp;
}

export function InputTagField({
  label,
  name,
  placeholder,
  required,
  dataSuggestions,
  maxlength,
  maxTags,
  tagValues,
  onChangeData,
  error,
  maxTagError,
  onError,
  className,
  description,
  uniqueTags,
  validRegex,
}: Props) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState<Suggestion[]>(
    []
  );
  const [checkError, setCheckError] = useState(false);
  const [tagError, setTagError] = useState<string>();

  const handleChangeData = (inputParam?: string) => {
    const newVal = inputParam ?? inputValue.trim();

    if (newVal && validRegex && !validRegex.test(newVal)) {
      setInputValue('');
      return;
    }

    if (tagValues) {
      if (uniqueTags) {
        onChangeData?.([...tagValues.filter((e) => e !== newVal), newVal]);
      } else {
        onChangeData?.([...tagValues, newVal]);
      }
    } else {
      onChangeData?.([newVal]);
    }

    setInputValue('');
  };

  const onClickOutside = () => {
    setFilteredSuggestions([]);
    if (inputValue.trim()) {
      if (maxTags && tagValues.length >= maxTags) return;

      handleChangeData();
    }
  };

  const { ref } = useOutsideClick({ onClickOutside });
  const handleFilterSuggestions = (value: string) => {
    if (maxTags && tagValues.length >= maxTags) {
      setFilteredSuggestions([]);
    } else {
      const filteredSuggestions = dataSuggestions?.filter((suggestion) =>
        suggestion.name?.toLowerCase().includes(value?.toLowerCase())
      );
      setFilteredSuggestions(filteredSuggestions || []);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      validRegex &&
      event.target.value &&
      !validRegex?.test(event.target.value)
    ) {
      return;
    }

    const value = event.target.value;
    setInputValue(value);
    handleFilterSuggestions(value);
  };

  const onSelectSuggestion = (value: string) => {
    if ((maxTags && tagValues.length >= maxTags) || !inputValue.trim()) {
      setFilteredSuggestions([]);
      return;
    }
    const selected =
      filteredSuggestions.find((i) => i.name === value)?.name || '';

    handleChangeData(selected);
    setFilteredSuggestions([]);
    setCheckError(false);
  };

  const divRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleScrollTagInput = () => {
    divRef.current?.scrollTo({
      left: divRef.current.scrollWidth - divRef.current.clientWidth,
      behavior: 'smooth',
    });
  };

  return (
    <div className={className ?? styles.textInput}>
      {label && <Label text={label} tag={'div'} required={required} />}
      <div ref={ref}>
        <div
          className={`${styles.mainFrame} ${
            (required && !tagValues.length && checkError) || tagError
              ? styles.empty
              : ''
          }`}
          ref={divRef}
          onClick={() => {
            inputRef.current?.focus();
            handleScrollTagInput();
          }}
        >
          {tagValues?.map((i, index) => {
            return (
              <Tag
                key={index}
                label={i}
                onRemove={(e) => {
                  e.stopPropagation();
                  maxTags &&
                    tagValues.length >= maxTags &&
                    setTagError(undefined);
                  setCheckError(true);
                  onChangeData?.(tagValues.filter((t) => i !== t));
                }}
              />
            );
          })}
          <input
            ref={inputRef}
            name={name}
            placeholder={tagValues.length === 0 ? placeholder : undefined}
            autoComplete="off"
            value={inputValue}
            className={
              !tagValues || tagValues?.length === 0 ? styles.inputFullWidth : ''
            }
            onChange={(e) => {
              setCheckError(false);
              if (maxTags && tagValues.length >= maxTags && e.target.value) {
                setTagError(maxTagError);
                onError?.(true);
              } else {
                setTagError(undefined);
                onError?.(false);
              }
              if (maxlength) {
                if (e.target.value.length <= maxlength) {
                  onChange(e);
                }
              } else {
                onChange(e);
              }
            }}
            onFocus={() => {
              handleFilterSuggestions(inputValue);
            }}
            onBlur={() => {
              setCheckError(true);
            }}
            onKeyDown={(e) => {
              if ((e.key === 'Enter' || e.keyCode === 13) && inputValue) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.keyCode === 13) {
                if (
                  (maxTags && tagValues.length >= maxTags) ||
                  !inputValue.trim()
                )
                  return;

                handleChangeData();
                setCheckError(false);

                setTimeout(() => {
                  handleScrollTagInput();
                }, 100);
              }
            }}
            maxLength={maxlength}
          />
        </div>

        {inputValue &&
          filteredSuggestions &&
          filteredSuggestions.length > 0 && (
            <SuggestionList
              onClickOutside={onClickOutside}
              selectedSuggestion={inputValue}
              onSelectSuggestion={onSelectSuggestion}
              suggestions={filteredSuggestions}
            />
          )}
        {description && <div className={styles.description}>{description}</div>}
        {((error && checkError) ||
          tagError ||
          (required && !tagValues.length && checkError)) && (
          <ErrorText
            text={
              error || tagError || t('messages.M_007', { field: label }) || ''
            }
            className={styles.errorText}
          />
        )}
      </div>
    </div>
  );
}
