// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export type HistoryState = {
  history: string[];
};

const initialState: HistoryState = {
  history: [],
};

type History = {
  history: string;
};

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    pushHistory: (state, action: PayloadAction<History>) => {
      const path = action.payload.history.slice(1);
      // リファラの最新版とは異なるpathが飛んできた時のみ配列に追加する
      if (
        state.history.length > 0 &&
        state.history[state.history.length - 1] !== path
      )
        state.history.push(path);
      else if (state.history.length === 0) {
        state.history.push(path);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetHistory: (state) => {
      return initialState;
    },
  },
});

export const { pushHistory, resetHistory } = historySlice.actions;
export const selectHistory = (state: RootState) => state.history.history;
export const selectReferrer = (state: RootState): string => {
  const referrers = state.history.history;
  const latestReferrer =
    referrers.length === 0 ? '' : referrers[referrers.length - 1];
  return latestReferrer;
};

export default historySlice.reducer;
