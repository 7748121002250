// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e?: any) => void;
}
export const IconEditIC: React.FC<Props> = ({ onClick }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M8.66755 4.28381H4.26216C3.7948 4.28381 3.34659 4.46947 3.01612 4.79994C2.68566 5.1304 2.5 5.57862 2.5 6.04597V15.7378C2.5 16.2052 2.68566 16.6534 3.01612 16.9839C3.34659 17.3143 3.7948 17.5 4.26216 17.5H13.954C14.4214 17.5 14.8696 17.3143 15.2001 16.9839C15.5305 16.6534 15.7162 16.2052 15.7162 15.7378V11.3324M14.4703 3.03797C14.6329 2.86966 14.8273 2.73542 15.0423 2.64306C15.2573 2.55071 15.4886 2.5021 15.7225 2.50007C15.9565 2.49803 16.1885 2.54262 16.4051 2.63122C16.6217 2.71982 16.8184 2.85067 16.9839 3.01612C17.1493 3.18158 17.2802 3.37833 17.3688 3.59489C17.4574 3.81145 17.502 4.04349 17.4999 4.27747C17.4979 4.51145 17.4493 4.74268 17.3569 4.95767C17.2646 5.17266 17.1303 5.3671 16.962 5.52966L9.39709 13.0946H6.9054V10.6029L14.4703 3.03797Z"
        stroke="#86EA5C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
