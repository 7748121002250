// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import Pagination from 'components/atoms/Pagination';
import { useEffect, useMemo, useState } from 'react';
import styles from './AdminConfirmInvoiceContent.module.scss';
import InvoiceEditMailModal from '../InvoiceEditMailModal';
import { createAxios } from 'ts/createAxios';
import { handleError } from 'util/errorHandler';
import { toast } from 'react-toastify';
import { getSlaFormatYearMonthDate } from 'ts/formatDate';
import { InvoiceStatus } from 'util/Types';
import { messageErrors } from 'util/messageErrors';

interface ConfirmInvoiceDetail {
  id: number;
  title: string;
  body: string;
  emails: string[];
  originalInvoice: boolean;
  site: {
    id: string;
    name: string;
  };
  operator: {
    id: string;
    name: string;
  };
  status: InvoiceStatus;
  sendDate: string;
}
interface Props {
  siteIds?: string | null;
  date?: string | null;
}
const AdminConfirmInvoiceContent: React.FC<Props> = ({ siteIds, date }) => {
  const [numPages, setNumPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [editMailModal, setEditMailModal] = useState(false);

  const [listSiteId, setListSiteId] = useState<string[]>([]);
  const api = createAxios();
  const [detail, setDetail] = useState<ConfirmInvoiceDetail>();
  const [pdfData, setData] = useState<string>();
  useEffect(() => {
    if (siteIds) {
      const listSite = siteIds.split(',');
      setNumPages(listSite.length);
      setListSiteId(listSite);
    }
  }, [siteIds]);

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const currentSiteId = useMemo(() => {
    return listSiteId[currentPage - 1];
  }, [listSiteId, currentPage]);

  const fetchData = (currentSiteId: string, date: string) => {
    api
      .get<ConfirmInvoiceDetail>(`/invoices/preview/${currentSiteId}/${date}`)
      .then((res) => {
        setDetail(res.data);
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const fetchInvoice = (invoiceId: number) => {
    api.get(`/invoices/download/${invoiceId}`).then((res) => {
      setData(`data:application/pdf;base64, ${res.data?.pdfBase64}`);
    });
  };
  useEffect(() => {
    if (detail?.id) {
      fetchInvoice(detail.id);
    }
  }, [detail]);

  useEffect(() => {
    if (currentSiteId && date) {
      fetchData(currentSiteId, date);
    }
  }, [currentSiteId, date]);

  const handleSendMail = (id?: number) => {
    api
      .get(`/invoices/send-email/${id}`)
      .then(() => {
        toast.success(messageErrors.M_109);
        if (currentSiteId && date) {
          fetchData(currentSiteId, date);
        }
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const handleExportInvoice = (
    detail?: ConfirmInvoiceDetail,
    pdfData?: string
  ) => {
    if (pdfData && detail && date) {
      const month = date.split('-')[1];
      const downloadLink = document.createElement('a');
      const fileName = `${detail.operator.name}_${
        detail.site.name
      }様_ご請求書_pool${month}月分_${getSlaFormatYearMonthDate(
        detail.sendDate
      )}`;
      downloadLink.href = pdfData;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  };

  return (
    <div className={styles.adminConfirmInvoiceContent}>
      <div className={styles.operatorName}>{detail?.operator.name}</div>
      <div className={styles.siteGroup}>
        <div className={styles.siteName}>{detail?.site.name}</div>
        <div
          className={styles.editButton}
          onClick={() => setEditMailModal(true)}
        >
          <button type="button">{'編集する'}</button>
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.title}>送信先</div>
          <div className={styles.content}>
            {detail?.emails && detail?.emails.length > 0
              ? detail.emails.join(', ')
              : '送信先メールアドレスがありません、記入ください。'}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>メールタイトル</div>
          <div className={styles.content}>{detail?.title}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>メール本文</div>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: detail?.body || '',
            }}
          />
        </div>
      </div>

      <div className={styles.previewPDF}>
        <iframe src={pdfData} width="100%" height="100%" />
      </div>

      <div className={styles.paging}>
        <Pagination
          currentPage={currentPage}
          totalRecords={numPages}
          pageSize={1}
          onPageChange={handleChangePage}
        />
      </div>
      <div className={styles.bottomButton}>
        <button
          className={styles.btnExport}
          disabled={!pdfData}
          onClick={() => {
            handleExportInvoice(detail, pdfData);
          }}
          type="button"
        >
          {'PDF出力'}
        </button>
        <button
          className={styles.btnSend}
          disabled={
            !detail?.emails ||
            detail.emails.length === 0 ||
            detail.status === InvoiceStatus.Enqueue
          }
          type="button"
          onClick={() => {
            handleSendMail(detail?.id);
            if (detail?.originalInvoice && pdfData) {
              handleExportInvoice(detail, pdfData);
            }
          }}
        >
          {'送信'}
        </button>
      </div>

      {editMailModal === true && (
        <InvoiceEditMailModal
          currentSiteId={currentSiteId}
          onClose={() => setEditMailModal(false)}
          onSuccess={() => {
            setEditMailModal(false);
            if (date) {
              fetchData(currentSiteId, date);
            }
          }}
        />
      )}
    </div>
  );
};

export default AdminConfirmInvoiceContent;
