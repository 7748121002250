// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  onClick?: VoidFunction;
}
export const IconScan = ({ onClick }: Props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 5C8.05228 5 8.5 4.55228 8.5 4C8.5 3.44772 8.05228 3 7.5 3H4.7C4.03726 3 3.5 3.53726 3.5 4.2V7C3.5 7.55228 3.94772 8 4.5 8C5.05228 8 5.5 7.55228 5.5 7V5H7.5ZM4.5 16C5.05228 16 5.5 16.4477 5.5 17V19H7.5C8.05228 19 8.5 19.4477 8.5 20C8.5 20.5523 8.05228 21 7.5 21H4.7C4.03726 21 3.5 20.4627 3.5 19.8V17C3.5 16.4477 3.94772 16 4.5 16ZM16.5 20C16.5 19.4477 16.9477 19 17.5 19H19.5V17C19.5 16.4477 19.9477 16 20.5 16C21.0523 16 21.5 16.4477 21.5 17V19.8C21.5 20.4627 20.9627 21 20.3 21H17.5C16.9477 21 16.5 20.5523 16.5 20ZM20.5 8C19.9477 8 19.5 7.55228 19.5 7V5H17.5C16.9477 5 16.5 4.55228 16.5 4C16.5 3.44772 16.9477 3 17.5 3H20.3C20.9627 3 21.5 3.53726 21.5 4.2V7C21.5 7.55228 21.0523 8 20.5 8ZM4.5 11C3.94772 11 3.5 11.4477 3.5 12C3.5 12.5523 3.94772 13 4.5 13H20.5C21.0523 13 21.5 12.5523 21.5 12C21.5 11.4477 21.0523 11 20.5 11H4.5Z"
        fill="#6F7175"
      />
    </svg>
  );
};
