import { createAxios } from 'ts/createAxios';
import { getApiPath } from 'util/commons';
import { API_ENDPOINTS } from 'util/endPoints';
import { handleError } from 'util/errorHandler';
import {
  UserRecycleSiteForm,
  UserSiteInfoResponse,
} from 'util/siteManagementTypes';

export const getSiteUserInfo = async (
  siteId: string,
  successCallback?: (data: UserSiteInfoResponse) => void
) => {
  const api = createAxios();
  const { data } = await api.get<UserSiteInfoResponse>(
    getApiPath(API_ENDPOINTS.SITE_INFO, { id: siteId })
  );
  successCallback?.(data);
};

export const regisRecycleProduct = async (
  params: { form: UserRecycleSiteForm },
  successCallback?: (data: { id: string }) => void
) => {
  const api = createAxios();
  try {
    const data = await api.post(API_ENDPOINTS.RECYCLE, {
      materialName: params.form.materialName,
      processingMethod: params.form.processingMethod,
      packingStyle: params.form.packingStyle,
      materialTypeId: Number(params.form.materialTypeId),
      gradeId: params?.form?.grades?.find(
        (item) => item.id === Number(params.form.gradeId)
      )?.id,
      weight: params.form.weight.trim(),
      inputResources: params.form.inputResources,
      prevProcessingObjectId: params.form.prevProcessingObjectId,
    });
    data && successCallback?.(data.data);
  } catch (error) {
    handleError(error);
  }
};
