// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { PoolDashboardMasterFilter } from 'components/organisms/PoolDashboardFilter';
import { createAxios } from 'ts/createAxios';
import {
  MaterialStatistic,
  MaterialStatisticParams,
  RateWeight,
  RateWeightParams,
  SitesStatistic,
} from 'types/dashboard';
import { DateTypeRange } from 'util/Enums';
import {
  AverageEmissionStatistics,
  DashboardsPoolRatioData,
  DashboardsPoolResources,
} from 'util/Types';
import { API_ENDPOINTS } from 'util/endPoints';
import { handleError } from 'util/errorHandler';

export const fetchDashboardRateWeight = async (
  params: RateWeightParams,
  successCallback?: (data: RateWeight[]) => void
) => {
  const api = createAxios();

  try {
    const { data } = await api.post(
      API_ENDPOINTS.DASHBOARDS_RECYCLE_CHAINS_RATE_WEIGHT,
      {
        dateFrom: params.dateFrom,
        dateTo: params.dateTo,
        recycleChainId: params.recycleChainId,
      }
    );
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

// Purchase resource search
export const fetchDashboardsPurchasesMaterialStatistic = async (
  params: MaterialStatisticParams,
  successCallback?: (data: MaterialStatistic[]) => void
) => {
  const api = createAxios();

  try {
    const { data } = await api.post(
      API_ENDPOINTS.DASHBOARDS_PURCHASES_MATERIAL_STATISTIC,
      {
        dateFrom: params.dateFrom,
        dateTo: params.dateTo,
        locations: params.locations,
        lat: params.lat,
        long: params.long,
        radius: params.radius,
        materialNames: params.materialNames,
        grades: params.grades,
        typeDateRange: params.typeDateRange,
      }
    );
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const fetchDashboardsPurchasesSitesStatistic = async (
  option: string,
  successCallback?: (data: SitesStatistic[]) => void
) => {
  const api = createAxios();

  try {
    const { data } = await api.get<SitesStatistic[]>(
      API_ENDPOINTS.DASHBOARDS_PURCHASES_SITES_STATISTIC,
      {
        params: { option },
      }
    );

    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

// Pool Dashboard
export const fetchDashboardsPoolAverageEmissionStatistics = async (
  params: PoolDashboardMasterFilter & { typeDateRange: DateTypeRange },
  successCallback?: (data: AverageEmissionStatistics[]) => void
) => {
  const api = createAxios();
  try {
    const { data } = await api.post(
      API_ENDPOINTS.DASHBOARDS_POOL_AVERAGE_EMISSION_STATISTICS,
      {
        dateFrom: params.dateFrom,
        dateTo: params.dateTo,
        stationIds: params.stationIds,
        collectionSiteIds: params.collectionSiteIds,
        siteTypes: params.siteTypes,
        wasteTypeMaster: params.wasteTypeMaster,
        recycleMethodIds: params.recycleMethodIds,
        materialNames: params.materialNames,
        typeDateRange: params.typeDateRange,
      }
    );

    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const fetchDashboardsPoolResources = async (
  params: PoolDashboardMasterFilter,
  successCallback?: (data: DashboardsPoolResources[]) => void
) => {
  const api = createAxios();
  try {
    const { data } = await api.post(API_ENDPOINTS.DASHBOARDS_POOL_RESOURCES, {
      ...params,
    });

    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const fetchDashboardsPoolRatioData = async (
  endPoint: string,
  params: PoolDashboardMasterFilter,
  callback?: (data: DashboardsPoolRatioData) => void
) => {
  const api = createAxios();
  try {
    const { data } = await api.post(endPoint, {
      ...params,
    });

    data && callback?.(data);
  } catch (error) {
    handleError(error);
    callback?.({
      total: '0',
      items: [],
    });
  }
};
