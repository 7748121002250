// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import { Suggestion } from 'components/molecules/InputDataListField';
import React from 'react';
import useOutsideClick from 'util/hooks/useClickOutside';
import styles from './index.module.scss';

type Props = {
  suggestions: Suggestion[];
  selectedSuggestion: string;
  onClickOutside: () => void;
  onSelectSuggestion: (value: string) => void;
};

const SuggestionList: React.FC<Props> = ({
  suggestions,
  selectedSuggestion,
  onClickOutside,
  onSelectSuggestion,
}) => {
  const { ref } = useOutsideClick({ onClickOutside });
  return (
    <div ref={ref}>
      <ul className={styles.suggestionList}>
        {suggestions.map((suggestion, index) => {
          const isSelected = selectedSuggestion === suggestion.name;
          return (
            <li
              key={index}
              className={`${styles.suggestion} ${
                isSelected ? styles.selected : ''
              }`}
              onClick={() => onSelectSuggestion(suggestion.name)}
            >
              <p>{suggestion.name}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SuggestionList;
