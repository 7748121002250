// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { fetchMaterial } from 'apis/operator_site/siteApi';
import { IconAddCircleOutline } from 'components/atoms/icons/IconAddCircleOutline';
import { IconICPlus } from 'components/atoms/icons/IconICPlus';
import { InputDataListField } from 'components/molecules/InputDataListField';
import { SelectField } from 'components/molecules/SelectField';
import arrayMutators from 'final-form-arrays';
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { operatorTypeStep, REGEX_OBJECT } from 'util/ConstantValues';
import { OperatorMenuItem } from 'util/Enums';
import { useTranslation } from 'react-i18next';
import {
  validateFieldEN,
  validateSelectField,
  validateStringField,
} from 'util/validator';
import { SelectOption } from '../EditCompressionsResourceDataModal';
import styles from './RecycleChainDesignDetailForm.module.scss';
import { SiteItem } from '../RecycleChainDesignAddSiteForm';
import WarningModal from 'components/molecules/WarningModal';

type ChainStages = {
  stage?: OperatorMenuItem;
  id?: number;
  sites?: { id: string; name: string }[];
};

export type RecycleChainDesignPut = {
  chainName: string;
  materialName: string;
  englishMaterialName: string;
  chainStages: {
    stage: OperatorMenuItem;
    id?: number;
    fuelType?: string;
    truckType?: string;
    sites?: SiteItem[];
  }[];
};

const RecycleChainDesignAddForm: React.FC<{
  onNextToAddSite: (form: RecycleChainDesignPut) => void;
}> = ({ onNextToAddSite }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [listOfMaterial, setMaterial] = useState<SelectOption[]>([]);
  const [isAlertOpened, setIsAlertOpened] = useState<boolean>(false);

  useEffect(() => {
    fetchMaterial((data) => {
      if (data) {
        setMaterial(
          data.map((item) => {
            return {
              value: item.materialName,
              label: item.materialName,
            };
          })
        );
      }
    });
  }, []);

  const initialValues = useMemo(
    () => ({
      chainName: '',
      materialName: '',
      englishMaterialName: '',
      chainStages: [
        { stage: OperatorMenuItem.Emissions },
        { stage: OperatorMenuItem.Collects },
        { stage: OperatorMenuItem.None },
      ],
    }),
    []
  );

  const afterIsNull = (
    current: number,
    total: number,
    chainStages: ChainStages[]
  ) => {
    for (let next = current; next < total; next++) {
      if (chainStages[next].stage) return false;
    }

    return true;
  };

  const getOperatorTypeStep = (
    optionIndex: number,
    chainStages: {
      stage: OperatorMenuItem;
      id?: number;
      sites?: { id: string; name: string }[];
    }[]
  ) => {
    let stepOptions = cloneDeep(operatorTypeStep);

    if (optionIndex >= 2)
      stepOptions = stepOptions.filter(
        (item) =>
          item.value !== OperatorMenuItem.Emissions &&
          item.value !== OperatorMenuItem.Collects
      );

    const last = chainStages[optionIndex - 1];

    if (!last) {
      return stepOptions;
    }

    if (last.stage === OperatorMenuItem.Recycle) {
      stepOptions = stepOptions.filter(
        (item) => item.value !== OperatorMenuItem.Recycle
      );
    }

    if (last.stage === OperatorMenuItem.Producer) {
      stepOptions = stepOptions.filter(
        (item) => item.value === OperatorMenuItem.Purchase
      );
    }
    return stepOptions;
  };

  return (
    <div className={styles.recycleChainDesignDetailForm}>
      <Form<RecycleChainDesignPut>
        onSubmit={(form) => {
          onNextToAddSite(form);
          setIsAlertOpened(false);
        }}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
          ...arrayMutators,
        }}
        initialValues={initialValues}
        validate={(values) => {
          return {
            chainName: validateStringField('名前', 100, values.chainName),
            materialName: validateSelectField('材料選択', values.materialName),
            englishMaterialName: validateFieldEN(
              t('category_details.material_en'),
              values.englishMaterialName,
              100
            ),
          };
        }}
      >
        {(props) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();

                const noEdiCreateRecycleChain =
                  props.values.chainStages[2]?.stage ===
                    OperatorMenuItem.Purchase ||
                  props.values.chainStages[2]?.stage ===
                    OperatorMenuItem.Producer;

                noEdiCreateRecycleChain
                  ? setIsAlertOpened(true)
                  : props.handleSubmit();
              }}
              className={styles.formInput}
            >
              <div className={styles.inputFrame}>
                <div className={styles.col1}>
                  <InputDataListField
                    label={'名前'}
                    placeholder={'名前'}
                    name="chainName"
                    required
                    maxlength={100}
                  />

                  <div className={styles.stepAdd}>
                    <FieldArray name="chainStages">
                      {({ fields }) => {
                        const lastFieldIndex = fields.length;
                        const handleClearValue = (index: number) => {
                          const oldData = props.values.chainStages;
                          const clearedData = oldData
                            .map((i, idx) =>
                              idx >= index ? { ...i, stage: '' } : i
                            )
                            .filter((_item, idx) => idx !== index);
                          props.form.mutators.setValue(
                            'chainStages',
                            clearedData
                          );
                        };
                        const handleClearAfterRecycle = (
                          index: number,
                          value: OperatorMenuItem,
                          plus?: boolean
                        ) => {
                          const items = props.values.chainStages
                            .map((stage, i) =>
                              i === index ? { ...stage, stage: value } : stage
                            )
                            .filter((_, i) => i <= index);
                          if (plus) {
                            items.push({ stage: OperatorMenuItem.None });
                          }
                          props.form.mutators.setValue('chainStages', items);
                        };

                        return (
                          <div>
                            {fields.map((name, index) => {
                              const options = getOperatorTypeStep(
                                index,
                                props.values.chainStages
                              );

                              return (
                                <div key={index} className={styles.stepArray}>
                                  <div className={styles.nextStep}>
                                    <span>{index + 1}</span>
                                    <div className={styles.select}>
                                      <SelectField
                                        name={`${name}.stage`}
                                        options={options}
                                        disabled={index < 2}
                                        placeholder="" // * display none in value and add new one after
                                        value={
                                          props.values.chainStages[index].stage
                                        }
                                        onChange={(value) => {
                                          if (
                                            index <
                                              props.values.chainStages.length &&
                                            value !== OperatorMenuItem.Purchase
                                          ) {
                                            const isNull = afterIsNull(
                                              index,
                                              props.values.chainStages.length,
                                              props.values.chainStages
                                            );
                                            if (!isNull) {
                                              handleClearAfterRecycle(
                                                index,
                                                value as OperatorMenuItem,
                                                true
                                              );
                                            }
                                          }
                                          value === OperatorMenuItem.Purchase &&
                                            handleClearAfterRecycle(
                                              index,
                                              value
                                            );
                                        }}
                                      />
                                    </div>

                                    {fields.length &&
                                      fields.length > 1 &&
                                      index !== Number(lastFieldIndex) - 1 &&
                                      index > 1 && (
                                        <div
                                          className={styles.buttonDeleteAdd}
                                          onClick={() => {
                                            fields.remove(index);
                                            handleClearValue(index);
                                          }}
                                        >
                                          <IconICPlus />
                                          <p>消去</p>
                                        </div>
                                      )}

                                    {index === Number(lastFieldIndex) - 1 &&
                                      !props.values.chainStages.find(
                                        (item) =>
                                          item.stage ===
                                          OperatorMenuItem.Purchase
                                      ) && (
                                        <div
                                          className={styles.buttonDeleteAdd}
                                          onClick={() => {
                                            fields.push('chainStages');
                                          }}
                                        >
                                          <IconAddCircleOutline />
                                          <p>追加</p>
                                        </div>
                                      )}

                                    {index === Number(lastFieldIndex) - 1 &&
                                      props.values.chainStages.find(
                                        (item) =>
                                          item.stage ===
                                          OperatorMenuItem.Purchase
                                      ) &&
                                      index !== 2 && (
                                        <div
                                          className={styles.buttonDeleteAdd}
                                          onClick={() => {
                                            fields.remove(index);
                                            handleClearValue(index);
                                          }}
                                        >
                                          <IconICPlus />
                                          <p>消去</p>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      }}
                    </FieldArray>
                  </div>
                </div>
                <div className={styles.col2}>
                  <div className={styles.materialName}>
                    <SelectField
                      label={'材料選択'}
                      placeholder={'選択する'}
                      name={'materialName'}
                      options={listOfMaterial}
                      required
                    />

                    <InputDataListField
                      label={t('category_details.material_en')}
                      placeholder={t('category_details.material_en')}
                      name="englishMaterialName"
                      required
                      maxlength={100}
                      validRegex={REGEX_OBJECT.halfWidthCharacter}
                    />
                  </div>

                  <div className={styles.notes}>
                    <p>{t('recycle_chains.note.title')}</p>
                    <li>{t('recycle_chains.note.content_1')}</li>
                    <li>{t('recycle_chains.note.content_2')}</li>
                  </div>
                </div>
              </div>
              <div className={styles.submit}>
                <button
                  className={`${styles.submitButton}`}
                  disabled={
                    props.invalid ||
                    !props.values.chainName ||
                    !props.values.materialName ||
                    !props.values.englishMaterialName ||
                    props.values.chainStages.filter((item) => item.stage)
                      .length < 3
                  }
                >
                  {'拠点の紐づけへ'}
                </button>

                {isAlertOpened && (
                  <WarningModal
                    title={t('common.alert_no_edi_create_recycle_chain.title')}
                    content={t(
                      'common.alert_no_edi_create_recycle_chain.content'
                    )}
                    buttonConfirm={t(
                      'common.alert_no_edi_create_recycle_chain.button'
                    )}
                    onClose={() => setIsAlertOpened(false)}
                    onConfirm={props.handleSubmit}
                  />
                )}
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};
export default RecycleChainDesignAddForm;
