// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import PoolDashboardDonutCharts from 'components/organisms/PoolDashboardDonutCharts';
import PoolDashboardFilter, {
  PoolDashboardMasterFilter,
} from 'components/organisms/PoolDashboardFilter';
import PoolDashboardLineCharts from 'components/organisms/PoolDashboardLineCharts';
import PoolDashboardResourceMap from 'components/organisms/PoolDashboardResourceMap';
import PoolDashboardResourceData from 'components/organisms/PoolDashboardResourceTable';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import dayjs from 'dayjs';
import { useState } from 'react';
import { getSlaFormatYearMonthDate } from 'ts/formatDate';
import { uniqRandomNumber } from 'util/commons';
import styles from './PoolDashboard.module.scss';

const PoolDashboard: React.FC = () => {
  const [masterFilter, setMasterFilter] = useState<PoolDashboardMasterFilter>({
    dateFrom: getSlaFormatYearMonthDate(dayjs().subtract(24, 'month').format()),
    dateTo: getSlaFormatYearMonthDate(dayjs().format()),
  });
  const [fetchTrigger, setFetchTrigger] = useState<number>();

  const handleSubmitMasterFilter = (data?: PoolDashboardMasterFilter) => {
    setMasterFilter(data || {});
    setFetchTrigger(uniqRandomNumber());
  };

  return (
    <PrimaryTemplate hasLogo>
      <div className={styles.PoolDashboard}>
        <PoolDashboardFilter
          filter={masterFilter}
          onSubmit={handleSubmitMasterFilter}
        />

        <div className={styles.ChartMapZone}>
          <PoolDashboardResourceMap
            className={styles.Map}
            filter={masterFilter}
            fetchTrigger={fetchTrigger}
          />

          <div className={styles.Charts}>
            <PoolDashboardDonutCharts
              className={styles.DonutCharts}
              filter={masterFilter}
              fetchTrigger={fetchTrigger}
            />

            <PoolDashboardLineCharts
              className={styles.LineChart}
              filter={masterFilter}
              fetchTrigger={fetchTrigger}
            />
          </div>
        </div>

        <PoolDashboardResourceData
          filter={masterFilter}
          fetchTrigger={fetchTrigger}
        />
      </div>
    </PrimaryTemplate>
  );
};

export default PoolDashboard;
