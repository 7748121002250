// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { IconAddCircleOutline } from 'components/atoms/icons/IconAddCircleOutline';
import { TenantItem } from 'components/organisms/AdminTenantManagement';
import { Form } from 'react-final-form';
import { BarcodeICCardInput, BarcodeInput } from 'util/Types';
import { validateMaxLength } from 'util/validator';
import { InputDataListField } from '../InputDataListField';
import styles from './AddBarcodeICCardForm.module.scss';

type Props = {
  genBarcode: BarcodeInput | undefined;
  tenantItemData: TenantItem | undefined;
  onGenBarcodeNew: (tenantItemData: TenantItem | undefined) => void;
  onSubmitAddICCard: (e: BarcodeICCardInput, tenantId: number) => void;
  onSubmitCreateBarcode: (e: BarcodeICCardInput, tenantId: number) => void;
};

const AddBarcodeICCardForm: React.FC<Props> = ({
  genBarcode,
  tenantItemData,
  onGenBarcodeNew,
  onSubmitAddICCard,
  onSubmitCreateBarcode,
}) => {
  return (
    <div className={styles.addBarcodeICCardForm}>
      <Form<BarcodeICCardInput>
        initialValues={{ barcode: genBarcode?.barcode }}
        onSubmit={(values) => {
          values.barcode
            ? onSubmitCreateBarcode(values, Number(tenantItemData?.id))
            : onSubmitAddICCard(values, Number(tenantItemData?.id));
        }}
        mutators={{
          changeField: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
      >
        {(props) => {
          return (
            <form onSubmit={props.handleSubmit} className={styles.formInput}>
              <div
                className={`${styles.btnBarcodeNew} ${
                  tenantItemData?.icCards && styles.disableBtnBarcodeNew
                }`}
                onClick={() =>
                  !tenantItemData?.icCards && onGenBarcodeNew(tenantItemData)
                }
              >
                <IconAddCircleOutline />
                <span>バーコードを生成</span>
              </div>

              <div className={styles.inputFrame}>
                <InputDataListField
                  validate={(value) =>
                    validateMaxLength(value, 15, 'バーコード')
                  }
                  label={'バーコード'}
                  placeholder={'バーコード'}
                  name="barcode"
                  maxlength={15}
                  disabled={tenantItemData?.icCards ? true : false}
                  type="number"
                />

                <InputDataListField
                  validate={(value) => validateMaxLength(value, 32, 'ICカード')}
                  label={'ICカード'}
                  placeholder={'ICカード'}
                  name="icCard"
                  maxlength={32}
                  disabled={tenantItemData?.barcode === null ? true : false}
                />
              </div>

              <div className={styles.line} />

              <div className={styles.submit}>
                <button
                  className={`${styles.submitButton} ${
                    !props.values.barcode && !props.values.icCard
                      ? styles.disableButton
                      : ''
                  }`}
                  disabled={!props.values.barcode && !props.values.icCard}
                  type="submit"
                >
                  追加する
                </button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default AddBarcodeICCardForm;
