// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './TraceDetail.scss';
import { useEffect, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import { AdminTemplate } from 'components/templates/AdminTemplate';
import { LabelContents } from 'components/molecules/LabelContents';
import { TraceData } from 'util/Types';
import { CustomCsvDownloadButton } from 'components/atoms/CustomCsvDownloadButton';
import { useParams, useHistory } from 'react-router-dom';
import { Path } from 'routes';
import { getSlaYMDhm } from 'ts/formatDate';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { OperatorType } from 'util/Enums';
import { formatNumberWithCommas } from 'util/commons';

const TraceDetail = () => {
  const api = createAxios();
  const params = useParams();
  const history = useHistory();
  const [trace, setTrace] = useState<TraceData[]>();
  const { t } = useTranslation();

  useEffect(() => {
    api
      .get('/traces/' + params['id'])
      .then((res) => {
        setTrace(res.data);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((res) => {
        alert('データの取得に失敗しました。');
        history.push(Path.traceSearch);
      });
  }, [setTrace]);

  const recycles = (record: TraceData) => (
    <div className="trace-detail-field" key={record.stageIndex}>
      <div className="process-title">{t('traces_detail.thead_recycle')}</div>
      {record.data.map((item, i) => (
        <div className="process-detail" key={'recycle-' + i}>
          <LabelContents
            label={t('traces_detail.delivery_completion_date')}
            contents={getSlaYMDhm(item.deliveredDatetime)}
          />
          <LabelContents
            label={t('traces_detail.shipping_address')}
            contents={item.siteName}
          />
          <LabelContents
            label={t('traces_detail.booking_date_time')}
            contents={getSlaYMDhm(item.bookingDatetime)}
          />

          <LabelContents
            label={t('traces_detail.shipping_origin')}
            contents={item.deliveryToSiteName}
          />

          <LabelContents
            label={t('traces.thead_material_name')}
            contents={item.description}
          />

          <LabelContents
            label={t('traces.thead_quality')}
            contents={item.grade}
          />

          <LabelContents
            label={t('traces.thead_weight')}
            contents={formatNumberWithCommas(item.weight) + 'kg'}
          />

          <LabelContents label={t('traces.thead_id')} contents={item.id} />
        </div>
      ))}
    </div>
  );

  const compressions = (record: TraceData) => (
    <div className="trace-detail-field" key={record.stageIndex}>
      <div className="process-title">
        {t('traces_detail.thead_volume_reduction')}
      </div>
      {record.data.map((item, i) => (
        <div className="process-detail" key={'compression-' + i}>
          <LabelContents
            label={t('traces_detail.delivery_completion_date')}
            contents={getSlaYMDhm(item.deliveredDatetime)}
          />
          <LabelContents
            label={t('traces_detail.shipping_address')}
            contents={item.deliveryToSiteName}
          />
          <LabelContents
            label={t('traces_detail.booking_date_time')}
            contents={getSlaYMDhm(item.bookingDatetime)}
          />
          <LabelContents
            label={t('traces_detail.shipping_origin')}
            contents={item.siteName}
          />
          <LabelContents
            label={t('traces.thead_material_name')}
            contents={item.description}
          />
          <LabelContents
            label={t('traces.thead_quality')}
            contents={item.grade}
          />
          <LabelContents
            label={t('traces.thead_weight')}
            contents={formatNumberWithCommas(item.weight) + 'kg'}
          />
          <LabelContents
            label={t('download.thead_volume_reduction_id')}
            contents={item.id}
          />
        </div>
      ))}
    </div>
  );

  const emissions = (record: TraceData) => (
    <div className="trace-detail-field" key={record.stageIndex}>
      <div className="process-title">
        {t('traces_detail.thead_registration_info')}
      </div>
      {record.data.map((item, i) => (
        <div className="process-detail" key={'emission-' + i}>
          <LabelContents
            label={t('traces_detail.delivery_completion_date')}
            contents={getSlaYMDhm(item.deliveredDatetime)}
          />
          <LabelContents
            label={t('traces_detail.shipping_address')}
            contents={item.deliveryToSiteName}
          />
          <LabelContents
            label={t('traces_detail.collection_date')}
            contents={
              item.collectedDatetime ? getSlaYMDhm(item.collectedDatetime) : '-'
            }
          />
          <LabelContents
            label={t('traces_detail.collection_point')}
            contents={item.collectedAreaName}
          />
          <LabelContents
            label={t('traces.thead_item')}
            contents={item.description}
          />
          <LabelContents
            label={t('traces.thead_weight')}
            contents={formatNumberWithCommas(item.weight) + 'kg'}
          />
          <LabelContents
            label={t('download.thead_registration_id')}
            contents={item.id}
          />
        </div>
      ))}
    </div>
  );

  const producers = (record: TraceData) => (
    <div className="trace-detail-field" key={record.stageIndex}>
      <div className="process-title">{t('traces_detail.producer')}</div>
      {record.data.map((item, i) => (
        <div className="process-detail" key={'producer-' + i}>
          <LabelContents
            label={t('traces_detail.delivery_completion_date')}
            contents={getSlaYMDhm(item.deliveredDatetime)}
          />
          <LabelContents
            label={t('traces_detail.shipping_address')}
            contents={item.deliveryToSiteName}
          />
          <LabelContents
            label={t('traces_detail.booking_date_time')}
            contents={getSlaYMDhm(item.bookingDatetime)}
          />
          <LabelContents
            label={t('traces_detail.shipping_origin')}
            contents={item.siteName}
          />
          <LabelContents
            label={t('traces.thead_material_name')}
            contents={item.description}
          />
          <LabelContents
            label={t('traces.thead_quality')}
            contents={item.grade}
          />
          <LabelContents
            label={t('traces.thead_weight')}
            contents={formatNumberWithCommas(item.weight) + 'kg'}
          />
          <LabelContents label={t('traces.thead_id')} contents={item.id} />
        </div>
      ))}
    </div>
  );

  return (
    <AdminTemplate
      h1={params['id'] + ` ${t('traces.data')}`}
      active="トレーサビリティ"
      breadcrumb={[
        { href: Path.traceSearch, label: t('common.sidebar_trace') },
        { label: params['id'] + ` ${t('traces.data')}` },
      ]}
      className="trace-detail-page"
      mainHeaderActionChildren={
        <CustomCsvDownloadButton
          onClick={() => {
            const els = document.getElementsByClassName(`csv-link-download`);
            for (let i = 0; i < els.length; i++) {
              const link = els[i] as HTMLLinkElement;
              link.click();
            }
          }}
        />
      }
    >
      <div className="trace-detail">
        {trace &&
          trace.map((record) => {
            switch (record.stage) {
              case OperatorType.Recycle:
                return recycles(record);
              case OperatorType.Compressions:
                return compressions(record);
              case OperatorType.Emissions:
                return emissions(record);
              case OperatorType.Producer:
                return producers(record);
            }
          })}
      </div>
      {trace &&
        trace.map((i) => {
          if (i.stage === OperatorType.Recycle) {
            return (
              <CSVLink
                key={i.stageIndex}
                className="hidden csv-link-download"
                filename={
                  params['id'] +
                  `${t('traces_detail.csv_recycling_business')}.csv`
                }
                headers={[
                  t('traces_detail.delivery_completion_date'),
                  t('traces_detail.shipping_address'),
                  t('traces_detail.booking_date_time'),
                  t('traces_detail.shipping_origin'),
                  t('traces.thead_material_name'),
                  t('traces.thead_quality'),
                  t('traces.thead_weight'),
                  t('traces.thead_id'),
                ]}
                data={i.data?.map((item) => {
                  return [
                    getSlaYMDhm(item.deliveredDatetime),
                    item.siteName,
                    getSlaYMDhm(item.bookingDatetime),
                    item.deliveryToSiteName,
                    item.description,
                    item.grade,
                    formatNumberWithCommas(item.weight) + 'kg',
                    item.id,
                  ];
                })}
              />
            );
          } else if (i.stage === OperatorType.Compressions) {
            return (
              <CSVLink
                key={i.stageIndex}
                className="hidden csv-link-download"
                filename={
                  params['id'] + `${t('traces_detail.csv_compression')}.csv`
                }
                headers={[
                  t('traces_detail.delivery_completion_date'),
                  t('traces_detail.shipping_address'),
                  t('traces_detail.booking_date_time'),
                  t('traces_detail.shipping_origin'),
                  t('traces.thead_material_name'),
                  t('traces.thead_quality'),
                  t('traces.thead_weight'),
                  t('download.thead_volume_reduction_id'),
                ]}
                data={i.data?.map((item) => {
                  return [
                    getSlaYMDhm(item.deliveredDatetime),
                    item.deliveryToSiteName,
                    getSlaYMDhm(item.bookingDatetime),
                    item.siteName,
                    item.description,
                    item.grade,
                    formatNumberWithCommas(item.weight) + 'kg',
                    item.id,
                  ];
                })}
              />
            );
          } else if (i.stage === OperatorType.Emissions) {
            return (
              <CSVLink
                key={i.stageIndex}
                className="hidden csv-link-download"
                filename={
                  params['id'] + `${t('traces_detail.csv_discharger')}.csv`
                }
                headers={[
                  t('traces_detail.delivery_completion_date'),
                  t('traces_detail.shipping_address'),
                  t('traces_detail.repair_date'),
                  t('traces_detail.collection_point'),
                  t('traces.thead_item'),
                  t('traces.thead_weight'),
                  t('download.thead_registration_id'),
                ]}
                data={i.data?.map((item) => {
                  return [
                    getSlaYMDhm(item.deliveredDatetime),
                    item.deliveryToSiteName,
                    getSlaYMDhm(item.collectedDatetime),
                    item.collectedAreaName,
                    item.description,
                    formatNumberWithCommas(item.weight) + 'kg',
                    item.id,
                  ];
                })}
              />
            );
          } else if (i.stage === OperatorType.Producer) {
            return (
              <CSVLink
                key={i.stageIndex}
                className="hidden csv-link-download"
                filename={
                  params['id'] + `${t('traces_detail.csv_producer')}.csv`
                }
                headers={[
                  t('traces_detail.delivery_completion_date'),
                  t('traces_detail.shipping_address'),
                  t('traces_detail.booking_date_time'),
                  t('traces_detail.shipping_origin'),
                  t('traces.thead_material_name'),
                  t('traces.thead_quality'),
                  t('traces.thead_weight'),
                  t('traces.thead_id'),
                ]}
                data={i.data?.map((item) => {
                  return [
                    getSlaYMDhm(item.deliveredDatetime),
                    item.deliveryToSiteName,
                    getSlaYMDhm(item.bookingDatetime),
                    item.siteName,
                    item.description,
                    item.grade,
                    formatNumberWithCommas(item.weight) + 'kg',
                    item.id,
                  ];
                })}
              />
            );
          }
        })}
    </AdminTemplate>
  );
};

export default TraceDetail;
