// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './TextAreaDataListField.module.scss';
import React, { ReactElement, useState } from 'react';
import { Field } from 'react-final-form';
import { FieldValidator } from 'final-form';
import { Label } from 'components/atoms/Label';
import SuggestionList from 'components/atoms/SuggestionList';

// temp, will refactor in implement api
export interface Suggestion {
  name: string;
}

interface Props {
  className?: string;
  name: string;
  label?: string;
  value?: string;
  required?: boolean;
  hint?: React.ReactNode;
  validate?: FieldValidator<string>;
  type?: string;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  dataSuggestions?: Suggestion[]; // temp, will refactor in implement api
  onSelectSuggestItem?: (value: string) => void;
  maxlength?: number;
  handleChange?: (value: string) => void;
  subFix?: ReactElement;
  validRegex?: RegExp;
  id?: string;
  onBlur?: () => void;
}

export function TextAreaDataListField({
  label,
  name,
  value,
  type,
  placeholder,
  readOnly,
  disabled,
  className,
  validate,
  required,
  dataSuggestions,
  onSelectSuggestItem,
  maxlength,
  handleChange,
  subFix,
  validRegex,
  id,
  onBlur,
}: Props) {
  const [inputValue, setInputValue] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState<Suggestion[]>(
    []
  );

  const onClickOutside = () => {
    setFilteredSuggestions([]);
  };

  const handleFilterSuggestions = (value: string) => {
    const filteredSuggestions = dataSuggestions?.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(value?.toLowerCase())
    );

    setFilteredSuggestions(filteredSuggestions || []);
  };

  const onChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    handleFilterSuggestions(value);
    handleChange && handleChange(value);
  };

  const onSelectSuggestion = (value: string) => {
    const selected =
      filteredSuggestions.find((i) => i.name === value)?.name || '';
    setInputValue(selected);
    setFilteredSuggestions([]);
    onSelectSuggestItem && onSelectSuggestItem(selected);
  };

  return (
    <Field
      name={name}
      type={type}
      validate={validate}
      value={value}
      dataSuggestions={dataSuggestions}
    >
      {({ input, meta }) => (
        <div className={className ?? styles.textInput}>
          {label && <Label text={label} tag={'div'} required={required} />}
          <div
            className={`${styles.textInputWrap} ${
              subFix ? styles.textInputWrapHasSubfix : ''
            }`}
          >
            <textarea
              {...input}
              id={id}
              name={name}
              autoFocus
              placeholder={placeholder}
              lang="ja"
              readOnly={readOnly}
              disabled={disabled}
              className={meta && meta.touched && meta.error ? 'error' : ''}
              autoComplete="off"
              onChange={(e) => {
                if (
                  validRegex &&
                  e.target.value &&
                  !validRegex?.test(e.target.value)
                )
                  return;
                if (maxlength) {
                  if (e.target.value.length <= maxlength) {
                    input.onChange(e);
                    onChange(e);
                  }
                } else {
                  input.onChange(e);
                  onChange(e);
                }
              }}
              onFocus={() => {
                handleFilterSuggestions(inputValue);
              }}
              onBlur={() => {
                input.onBlur();
                onBlur && onBlur();
              }}
              maxLength={maxlength}
            />
            {subFix && subFix}
          </div>
          {inputValue &&
            filteredSuggestions &&
            filteredSuggestions.length > 0 && (
              <SuggestionList
                onClickOutside={onClickOutside}
                selectedSuggestion={inputValue}
                onSelectSuggestion={onSelectSuggestion}
                suggestions={filteredSuggestions}
              />
            )}
        </div>
      )}
    </Field>
  );
}
