// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

export const IconFilter = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.74408 1.74408C1.5878 1.90036 1.5 2.11232 1.5 2.33333V4.48833C1.50005 4.70933 1.58788 4.92126 1.74417 5.0775L7.08917 10.4225C7.24546 10.5787 7.33329 10.7907 7.33333 11.0117V16.5L10.6667 13.1667V11.0117C10.6667 10.7907 10.7545 10.5787 10.9108 10.4225L16.2558 5.0775C16.4121 4.92126 16.5 4.70933 16.5 4.48833V2.33333C16.5 2.11232 16.4122 1.90036 16.2559 1.74408C16.0996 1.5878 15.8877 1.5 15.6667 1.5H2.33333C2.11232 1.5 1.90036 1.5878 1.74408 1.74408Z"
        stroke="#E7E7E7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
