import BookingPrintModal from 'components/molecules/BookingPrintModal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { TCompressionBooking } from 'types/compression';
import styles from './shippingRegistrationDetailContent.module.scss';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { DATE_FORMAT } from 'util/ConstantValues';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { normalizeNumber } from 'util/commons';

type ShippingContentProps = {
  compression: TCompressionBooking;
};

const ShippingRegistrationDetailContent = ({
  compression,
}: ShippingContentProps) => {
  const { t } = useTranslation();
  const { siteName } = useSelector(selectUser);
  const [print, setPrint] = useState<boolean>(false);

  useEffect(() => {
    try {
      window?.requestPrinterConnection?.();
    } catch (_) {
      return;
    }
  }, []);

  const onChangePrint = () => setPrint(!print);

  const Row = ({ title, content }: { title: string; content?: string }) => (
    <tr className={styles.row}>
      <td className={styles.title}>{title}</td>
      <td className={styles.content}>: {content}</td>
    </tr>
  );

  return (
    <div className={styles.shippingContent}>
      <div className={styles.qrCodeContainer}>
        <QRCode
          size={136}
          className={styles.QRCode}
          value={compression.qrCode}
          viewBox={`0 0 136 136`}
        />
      </div>
      <table className={styles.information}>
        <Row
          title={t('compressions.delivery_date')}
          content={getFormatDateWithCurrentTimezone(
            compression.requestedDatetime,
            DATE_FORMAT.slaYMDHm
          )}
          key={`${compression.deliveryId}-${compression.requestedDatetime}`}
        />
        <Row
          title={t('compressions.sender')}
          content={siteName}
          key={`${compression.deliveryId}-${siteName}`}
        />
        <Row
          title={t('compressions.delivery_destination')}
          content={compression.temporaryTargetSite.name}
          key={`${compression.deliveryId}-${compression.temporaryTargetSite.name}`}
        />
        <Row
          title={t('compressions.material_name')}
          content={compression.description}
          key={`${compression.deliveryId}-${compression.description}`}
        />
        <Row
          title={t('compressions.weight')}
          content={`${normalizeNumber({ value: compression.weight })}kg`}
          key={`${compression.deliveryId}-${compression.weight}`}
        />
      </table>
      <div className={styles.bottom}>
        <button className={styles.btn} type="button" onClick={onChangePrint}>
          {t('compressions.confirm')}
        </button>
      </div>
      {print && (
        <BookingPrintModal
          onClose={onChangePrint}
          compression={{ ...compression, sender: siteName }}
        />
      )}
    </div>
  );
};

export default ShippingRegistrationDetailContent;
