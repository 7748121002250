// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { createAxios } from 'ts/createAxios';
import {
  EmissionOperatorSiteTotal,
  GetHistoryPurchaseDeliveryParams,
  HistoryPurchaseDelivery,
  ProductRegistrationForm,
  PurchaseDelivery,
  RecycleChainMaterialFlow,
  UpdateHistoryPurchaseDelivery,
} from 'types/purchase';
import { getApiPath } from 'util/commons';
import { API_ENDPOINTS } from 'util/endPoints';
import { handleError } from 'util/errorHandler';
import { CommonSite, PreRegisResource } from 'util/Types';

export const fetchNextStageSites = async (
  params: { ids: string[] },
  successCallback?: (data: CommonSite[]) => void
) => {
  const api = createAxios();
  try {
    const data = await api.post(API_ENDPOINTS.NEXT_STAGE_SITE, {
      processingObjectsIds: params.ids,
    });
    data && successCallback?.(data.data);
  } catch (error) {
    handleError(error);
  }
};

export const createPurchaseDelivery = async (
  params: { form: PurchaseDelivery },
  successCallback?: (data: { data: string }) => void
) => {
  const api = createAxios();
  try {
    const data = await api.post(API_ENDPOINTS.PURCHASE_DELIVERY, {
      resourceIds: params.form.resourceIds,
      deliveryToSiteId: params.form.deliveryToSiteId,
      deliveriedDatetime: params.form.deliveriedDatetime,
    });
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const createProductRegistration = async (
  params: ProductRegistrationForm,
  successCallback?: (data: { id: string }) => void
) => {
  const api = createAxios();
  try {
    const data = await api.post(API_ENDPOINTS.PURCHASE_PRODUCES, {
      operatorId: params.operatorId,
      siteId: params.siteId,
      materialName: params.materialName,
      processingMethod: params.processingMethod,
      packingStyle: params.packingStyle,
      qualityInspectionMethod: params.qualityInspectionMethod,
      materialTypeId: Number(params.materialTypeId),
      quantity: Number(params.quantity),
      weight: params.weight,
      name: params.name.trim(),
      procurementPercentage: params.procurementPercentage,
      gradeId: Number(params.gradeId),
      inputResources: params.inputResources,
      prevProcessingObjectId: params.prevProcessingObjectId,
    });

    data && successCallback?.(data.data);
  } catch (error) {
    handleError(error);
  }
};

export const fetchDashboardPurchaseMaterialFlow = async (p: {
  params: {
    recycleChainId: string;
    dateFrom?: string;
    dateTo?: string;
  };
  successCallback?: (data?: RecycleChainMaterialFlow) => void;
}) => {
  const { params, successCallback } = p;

  try {
    const api = createAxios();
    const { data } = await api.get(
      API_ENDPOINTS.DASHBOARD_PURCHASES_RECYCLE_CHAINS_MATERIAL_FLOW,
      {
        params,
      }
    );
    successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const fetchHistoriesPurchaseDelivery = async (p: {
  params?: GetHistoryPurchaseDeliveryParams;
  successCallback?: (data: {
    total: number;
    data: HistoryPurchaseDelivery[];
    page: number;
  }) => void;
}) => {
  try {
    const { params, successCallback } = p;
    const api = createAxios();
    const { data } = await api.get(
      API_ENDPOINTS.HISTORIES_PURCHASES_DELIVERED,
      {
        params,
      }
    );
    successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const updateHistoryPurchaseDelivery = async (
  id: string,
  params: { form: UpdateHistoryPurchaseDelivery },
  successCallback?: (data: { data: string }) => void
) => {
  const api = createAxios();
  try {
    const { data } = await api.put(
      getApiPath(API_ENDPOINTS.EDIT_HISTORIES_PURCHASES_DELIVERED, {
        id,
      }),
      {
        deliveriedDatetime: params.form.deliveriedDatetime,
        deliveryToSiteId: params.form.deliveryToSiteId,
      }
    );
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const deleteHistoryPurchaseDelivery = async (
  id: string,
  successCallback?: (data: { data: string }) => void
) => {
  const api = createAxios();
  try {
    const { data } = await api.delete(
      getApiPath(API_ENDPOINTS.EDIT_HISTORIES_PURCHASES_DELIVERED, {
        id,
      })
    );
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const dashboardPurchaseEmissonOperatorSiteTotal = async (
  successCallback?: (data: EmissionOperatorSiteTotal) => void
) => {
  try {
    const api = createAxios();
    const { data } = await api.get(
      API_ENDPOINTS.DASHBOARD_PURCHASES_EMISSION_OPERATOR_SITE_TOTAL
    );
    successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const getPurchasesProducersInputResources = async (p: {
  params: {
    operatorId: string;
    siteId: string;
  };
  successCallback?: (data: PreRegisResource[]) => void;
}) => {
  const { params, successCallback } = p;
  try {
    const api = createAxios();
    const { data } = await api.get(
      API_ENDPOINTS.PURCHASES_PRODUCERS_INPUT_RESOURCES,
      {
        params,
      }
    );
    successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};
