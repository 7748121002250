// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { InputDataListField } from '../InputDataListField';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { ModalType } from 'util/Enums';
import { Label } from 'components/atoms/Label';
import PhotoPickerMultipleFields from '../PhotoPickerMultipleFields';
import { CompressionSiteItem } from 'util/siteManagementTypes';
import { InputTagField } from '../InputTagField';
import {
  COMPRESSION_METHOD_OPTIONS,
  PdfType,
  REGEX_OBJECT,
} from 'util/ConstantValues';
import TimeInputField from '../TimeInputField';
import {
  isValidTime,
  validateExactLength,
  validateFieldEN,
  validateId,
  validatePostCodeForm,
  validateRequiredField,
  validateStringField,
} from 'util/validator';
import { uniqBy } from 'lodash';
import UploadFiles from '../UploadFiles';
import { SelectFieldMultipleChoise } from '../SelectFieldMultipleChoise';
import PostalCodeForm from '../PostalCodeForm';

type FileType = {
  name: string;
  file: string;
};
interface Props {
  initForm: CompressionSiteItem;
  setInputForm: (value: CompressionSiteItem) => void;
  type: ModalType;
  provinceSuggestions: string[];
  districtSuggestions: string[];
  machineTypeSuggestions: string[];
  enrollableGradeSuggestions: string[];
  acceptanceMaterialSuggestions: string[];
  acceptanceGradeSuggestions: string[];
  enrollableTypeSuggestions: string[];
  packingStyleSuggestions: string[];
  onCreateCompressionSite: (createCompressionForm: CompressionSiteItem) => void;
  onUpdateCompressionSite: (updateCompressionForm: CompressionSiteItem) => void;
}

const CreateUpdateCompressionSiteForm: React.FC<Props> = ({
  initForm,
  provinceSuggestions,
  districtSuggestions,
  machineTypeSuggestions,
  enrollableGradeSuggestions,
  acceptanceMaterialSuggestions,
  acceptanceGradeSuggestions,
  enrollableTypeSuggestions,
  type,
  packingStyleSuggestions,
  onCreateCompressionSite,
  onUpdateCompressionSite,
  setInputForm,
}) => {
  const { t } = useTranslation();
  const [imagesDelete, setImagesDelete] = useState<string[]>([]);
  const [imagesAdded, setImageAdded] = useState<FileType[]>([]);
  const [step, setStep] = useState(1);
  const [formHasError, setFormHasError] = useState(false);
  const [file, setFile] = React.useState<File>();

  const renderStep = (
    currentStep: number,
    formProps: FormRenderProps<
      CompressionSiteItem,
      Partial<CompressionSiteItem>
    >
  ) => {
    if (currentStep === 1) {
      return (
        <>
          <div className={styles.textFrame}>
            <InputDataListField
              label={t('site_management.modal.field_id') || '拠点ID'}
              placeholder={t('site_management.modal.field_id') || '拠点ID'}
              name="id"
              required
              maxlength={20}
              disabled={type === ModalType.update}
            />
          </div>
          <div className={styles.textFrame}>
            <InputDataListField
              label={t('site_management.modal.field_name') || '拠点名'}
              placeholder={t('site_management.modal.field_name') || '拠点名'}
              name="name"
              required
              maxlength={100}
            />
            <InputDataListField
              label={t('site_management.modal.site_name_en')}
              placeholder={t('site_management.modal.site_name_en')}
              name="englishName"
              required
              maxlength={100}
              validRegex={REGEX_OBJECT.halfWidthCharacter}
            />
            <InputDataListField
              label={'EDI加入者番号'}
              placeholder={'EDI加入者番号'}
              name="ediSubscriberNumber"
              maxlength={7}
              validRegex={REGEX_OBJECT.numberStringOnly}
            />
            <InputDataListField
              label={'EDI事業場番号'}
              placeholder={'EDI事業場番号'}
              name="ediOfficeNumber"
              maxlength={3}
              onSelectSuggestItem={(value) => {
                formProps.form.mutators.setValue('ediOfficeNumber', value);
              }}
              validRegex={REGEX_OBJECT.number}
            />
          </div>

          <div className={styles.textFrame}>
            <PostalCodeForm
              provinceSuggestions={provinceSuggestions}
              districtSuggestions={districtSuggestions}
              formProps={formProps}
              mode={type}
              prefix={
                <InputDataListField
                  label="EDI確認キー"
                  placeholder="EDI確認キー"
                  name="ediConfirmationKey"
                  maxlength={8}
                  validRegex={REGEX_OBJECT.numberStringOnly}
                />
              }
            />
          </div>
        </>
      );
    }

    if (currentStep === 2) {
      return (
        <>
          <div className={styles.textFrame}>
            <SelectFieldMultipleChoise
              name="compressionMethod"
              label={t('site_management.modal.field_compression_method') || ''}
              placeholder={
                t('site_management.modal.field_compression_method') || ''
              }
              selectedOptions={formProps.values.compressionMethod}
              options={COMPRESSION_METHOD_OPTIONS}
              onChange={(items) =>
                formProps.form.mutators.setValue(
                  'compressionMethod',
                  items.map((e) => e.id)
                )
              }
              required
              checkAllWithCheckBox
            />
            <InputTagField
              label={t('site_management.modal.field_machine_type')}
              placeholder={t('site_management.modal.field_machine_type') || ''}
              name="machineType"
              maxlength={100}
              tagValues={formProps.values.machineType}
              dataSuggestions={machineTypeSuggestions.map((item) => ({
                name: item,
              }))}
              onChangeData={(tags) => {
                formProps.form.mutators.setValue('machineType', uniqBy(tags));
              }}
            />
            <InputTagField
              label={'受け取り材料'}
              placeholder={'受け取り材料'}
              name="acceptanceMaterial"
              maxlength={100}
              tagValues={formProps.values.acceptanceMaterial}
              dataSuggestions={acceptanceMaterialSuggestions.map((item) => ({
                name: item,
              }))}
              onChangeData={(tags) => {
                formProps.form.mutators.setValue(
                  'acceptanceMaterial',
                  uniqBy(tags)
                );
              }}
            />
            <InputTagField
              label={'受け取りグレード'}
              placeholder={'受け取りグレード'}
              name="acceptanceGrade"
              maxlength={100}
              tagValues={formProps.values.acceptanceGrade}
              dataSuggestions={acceptanceGradeSuggestions.map((item) => ({
                name: item,
              }))}
              onChangeData={(tags) => {
                formProps.form.mutators.setValue(
                  'acceptanceGrade',
                  uniqBy(tags)
                );
              }}
            />
            <InputTagField
              label={t('site_management.enrollable_grade') || ''}
              placeholder={t('site_management.enrollable_grade') || ''}
              name="enrollableGrade"
              maxlength={100}
              tagValues={formProps.values.enrollableGrade}
              dataSuggestions={enrollableGradeSuggestions.map((item) => ({
                name: item,
              }))}
              required
              onChangeData={(tags) => {
                formProps.form.mutators.setValue(
                  'enrollableGrade',
                  uniqBy(tags)
                );
              }}
            />
            <InputTagField
              label={
                t('site_management.modal.field_packing_style') ||
                '中間処理後の荷姿'
              }
              placeholder={
                t('site_management.modal.field_packing_style') ||
                '中間処理後の荷姿'
              }
              required
              name="packingStyles"
              maxlength={100}
              tagValues={formProps.values.packingStyles}
              dataSuggestions={packingStyleSuggestions.map((item) => ({
                name: item,
              }))}
              onChangeData={(tags) => {
                formProps.form.mutators.setValue('packingStyles', uniqBy(tags));
              }}
            />
            <TimeInputField
              label="営業開始時間"
              name="startTime"
              value={formProps.values.startTime}
              onChangeTime={(time) => {
                formProps.form.mutators.setValue('startTime', time);
              }}
              validate={(value) => {
                return isValidTime('営業開始時間', value);
              }}
            />
            <TimeInputField
              label="営業終了時間"
              name="endTime"
              value={formProps.values.endTime}
              onChangeTime={(time) => {
                formProps.form.mutators.setValue('endTime', time);
              }}
              validate={(value) => {
                return isValidTime('営業終了時間', value);
              }}
            />
          </div>
          <Label
            className={styles.headingImages}
            text={
              t('site_management.modal.field_compression_site_images') ||
              '写真を選ぶ'
            }
            tag={'div'}
          />
          <PhotoPickerMultipleFields
            defaultFile={initForm.images}
            onChangePhoto={(images) => {
              type === ModalType.update &&
                setImageAdded(
                  images
                    .filter(
                      (image) =>
                        !initForm.images.find(
                          (item) => item.name === image.name
                        )
                    )
                    .map((file) => ({
                      file: file.file,
                      name: file.name,
                    }))
                );
              formProps.form.mutators.setValue('images', images);
            }}
            maxLength={3}
            onRemove={(item) => {
              type === ModalType.update &&
                !item.file &&
                setImagesDelete(imagesDelete.concat(item.name));
            }}
            onError={setFormHasError}
          />
          <div className={styles.textFrame}>
            <InputDataListField
              label={
                t('site_management.modal.field_staff_tel') || '現場担当者 TEL'
              }
              placeholder={
                t('site_management.modal.field_staff_tel') || '現場担当者 TEL'
              }
              maxlength={15}
              name="staffTel"
              validRegex={REGEX_OBJECT.number}
            />
          </div>
        </>
      );
    }

    return (
      <div>
        <div className={styles.textFrame}>
          <InputTagField
            label={t('site_management.modal.field_waste_permits') || ''}
            placeholder={t('site_management.modal.field_waste_permits') || ''}
            name="enrollableType"
            required
            maxlength={100}
            tagValues={formProps.values.enrollableType}
            dataSuggestions={enrollableTypeSuggestions.map((item) => ({
              name: item,
            }))}
            onChangeData={(tags) => {
              formProps.form.mutators.setValue('enrollableType', uniqBy(tags));
            }}
            validRegex={REGEX_OBJECT.halfWidthCharacter}
          />
          <InputDataListField
            label={
              t('site_management.modal.field_prev_year_input_amount') || ''
            }
            placeholder={
              t('site_management.modal.field_prev_year_input_amount') || ''
            }
            name="prevYearInputAmount"
            validRegex={REGEX_OBJECT.decimalNumberWithDot5}
            endExtraText="kg"
            required
          />
          <InputDataListField
            label={t('site_management.modal.field_prev_year_scope1') || ''}
            placeholder={
              t('site_management.modal.field_prev_year_scope1') || ''
            }
            name="prevYearScope1"
            validRegex={REGEX_OBJECT.decimalNumberWithDot5}
            endExtraText="kg-CO2"
            required
          />
          <InputDataListField
            label={t('site_management.modal.field_prev_year_scope2') || ''}
            placeholder={
              t('site_management.modal.field_prev_year_scope2') || ''
            }
            name="prevYearScope2"
            validRegex={REGEX_OBJECT.decimalNumberWithDot10}
            endExtraText="kg-CO2"
            required
          />
        </div>
        <UploadFiles
          label={t('site_management.modal.field_pdf') || ''}
          content={t('common.upload_pdf')}
          defaultFile={type === ModalType.update ? initForm?.wastePermit : ''}
          onChangePhoto={(e) => {
            formProps.form.mutators.setValue('wastePermit', e ?? '');
          }}
          onError={setFormHasError}
          fileTypes={PdfType}
          accept=".pdf"
          exFile={{
            file: file,
            setFile,
          }}
        />
      </div>
    );
  };

  const renderButtonSubmit = () => {
    if (step === 1 || step === 2) {
      return t('site_management.modal.button_next');
    }

    return type === ModalType.add
      ? t('site_management.modal.compression_button_add')
      : t('site_management.modal.compression_button_update');
  };

  const renderValidate = (currentStep: number, values: CompressionSiteItem) => {
    if (currentStep === 1) {
      return {
        id: validateId(t('site_management.modal.field_id'), 20, values.id),
        name: validateStringField(
          t('site_management.modal.field_name'),
          100,
          values.name
        ),
        englishName: validateFieldEN(
          t('site_management.modal.site_name_en'),
          values.englishName,
          100
        ),
        ediSubscriberNumber: validateExactLength({
          value: values.ediSubscriberNumber,
          length: 7,
        }),
        ediConfirmationKey: validateExactLength({
          value: values.ediConfirmationKey,
          length: 8,
        }),
        ...validatePostCodeForm(values),
      };
    }

    if (currentStep === 2) {
      return {
        compressionMethod: validateRequiredField(
          values.compressionMethod,
          t('site_management.modal.field_compression_method') || ''
        ),
        enrollableGrade: validateRequiredField(
          values.enrollableGrade,
          t('site_management.enrollable_grade') || ''
        ),
        packingStyles: validateRequiredField(
          values.packingStyles,
          t('site_management.modal.field_packing_style') || ''
        ),
      };
    }

    return {
      enrollableType: validateFieldEN(
        t('site_management.modal.field_waste_permits'),
        values.enrollableType
      ),
      prevYearInputAmount: validateRequiredField(
        values.prevYearInputAmount,
        t('site_management.modal.field_prev_year_input_amount') || ''
      ),
      prevYearScope1: validateRequiredField(
        values.prevYearScope1,
        t('site_management.modal.field_prev_year_scope1') || ''
      ),
      prevYearScope2: validateRequiredField(
        values.prevYearScope2,
        t('site_management.modal.field_prev_year_scope2') || ''
      ),
    };
  };

  const handleSubmit = (formData: CompressionSiteItem) => {
    type === ModalType.add
      ? onCreateCompressionSite(formData)
      : onUpdateCompressionSite(formData);
  };

  const handleStep = (
    currentStep: number,
    direction: 'next' | 'prev',
    data: CompressionSiteItem
  ) => {
    if (currentStep === 1 && direction === 'next') {
      setInputForm(data);
      setStep(2);
      return;
    }
    if (currentStep === 2 && direction === 'next') {
      setInputForm(data);
      setStep(3);
      return;
    }
    if (currentStep === 3 && direction === 'next') {
      handleSubmit(data);
      return;
    }
    if (currentStep === 3 && direction === 'prev') {
      setInputForm(data);
      setStep(2);
      setFormHasError(false);
      return;
    }
    if (currentStep === 2 && direction === 'prev') {
      setInputForm(data);
      setFormHasError(false);
      setStep(1);
      return;
    }
  };

  return (
    <div className={styles.createBody}>
      <Form<CompressionSiteItem>
        onSubmit={(values: CompressionSiteItem) => {
          handleStep(step, 'next', {
            ...values,
            ...(type === ModalType.update ? { imagesAdded, imagesDelete } : {}),
          });
        }}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        initialValues={initForm}
        validate={(values) => {
          return renderValidate(step, values);
        }}
      >
        {(props) => {
          return (
            <form className={styles.formInput}>
              <div className={styles.inputFrame}>{renderStep(step, props)}</div>

              <div className={styles.submit}>
                {(step === 2 || step === 3) && (
                  <button
                    type="button"
                    className={`${styles.prevButton}`}
                    onClick={() => {
                      handleStep(step, 'prev', {
                        ...props.values,
                        ...(type === ModalType.update
                          ? { imagesAdded, imagesDelete }
                          : {}),
                      });
                    }}
                  >
                    {t('site_management.modal.button_prev')}
                  </button>
                )}

                <button
                  className={styles.submitButton}
                  disabled={props.invalid || formHasError}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    props.handleSubmit();
                  }}
                >
                  {renderButtonSubmit()}
                </button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default CreateUpdateCompressionSiteForm;
