// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './AdminRecycleChainDesignTable.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { DEFAULT_PAGE, listChain, PAGE_SIZE } from 'util/ConstantValues';
import { IconEdit } from 'components/atoms/icons/IconEdit';
import TableLoading from 'components/atoms/TableLoading';
import { IconAddCircleOutline } from 'components/atoms/icons/IconAddCircleOutline';
import { useHistory } from 'react-router-dom';
import { RecycleChainItem, getRecycleChainList } from 'apis/recycle_chain';
import { Path } from 'routes';

export interface FilterObject {
  inputSearch?: string;
  operatorType: string;
}

const AdminRecycleChainDesignTable: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [totalItems, setTotalItems] = useState(0);
  const [listRecycleChains, setListRecycleChains] = useState<
    RecycleChainItem[]
  >([]);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fetchRecycleChainList = async (nextPage?: number) => {
    getRecycleChainList((data) => {
      if (data) {
        setListRecycleChains([
          ...listRecycleChains,
          ...(data.recycleChains || []),
        ]);
        setTotalItems(data?.total || 0);
      }
    });
  };

  useEffect(() => {
    fetchRecycleChainList();
  }, []);

  return (
    <div className={styles.adminRecycleChainDesignTable}>
      <div className={styles.titleTable}>
        <div
          dangerouslySetInnerHTML={{
            __html:
              t('common.page', {
                page: totalItems,
              }) ?? '',
          }}
        />

        <div
          className={styles.buttonAdd}
          onClick={() => {
            history.push(Path.adminRecycleChainDesignAdd);
          }}
        >
          <IconAddCircleOutline />
          <span>チェーンを追加</span>
        </div>
      </div>

      <div className={`scroll-wrapper ${styles.scrollWrapper}`}>
        <table id={styles.recycleChainDesignTable}>
          <thead>
            <tr>
              <th className={styles.chainName}>名前</th>
              <th className={styles.materialName}>材料名</th>
              <th className={styles.materialName}>
                {t('category_details.material_en')}
              </th>
              <th className={styles.action}>アクション</th>
            </tr>
          </thead>
          <tbody>
            {listRecycleChains && listRecycleChains.length > 0 ? (
              listRecycleChains.map((item: RecycleChainItem, i) => {
                return (
                  <tr key={i}>
                    <td className={styles.chainName}>
                      <p title={item.name}>{item.name || '-'}</p>
                    </td>
                    <td className={styles.materialName}>
                      <p title={item.materialName}>
                        {item.materialName || '-'}
                      </p>
                    </td>
                    <td className={styles.materialName}>
                      <p title={item.englishMaterialName}>
                        {item.englishMaterialName || '-'}
                      </p>
                    </td>

                    <td className={styles.action}>
                      <div className={styles.contentAction}>
                        <div
                          className={styles.actionEdit}
                          onClick={() =>
                            history.push(
                              `/admin-recycle-chain-design-edit/${item.id}`
                            )
                          }
                        >
                          <IconEdit />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="msg-error">{t('messages.M_008')}</td>
              </tr>
            )}

            {listChain && listChain.length > 0 && (
              <TableLoading
                onNext={() => {
                  if (
                    listChain.length >= PAGE_SIZE &&
                    listChain.length < listChain.length
                  ) {
                    fetchRecycleChainList(currentPage + 1);
                    setCurrentPage(currentPage + 1);
                  }
                }}
              />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminRecycleChainDesignTable;
