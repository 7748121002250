// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { SelectField } from 'components/molecules/SelectField';
import { AdminTemplate } from 'components/templates/AdminTemplate';
import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import {
  AccountOperator,
  FilterObj,
  MonthOptions,
} from 'util/accountManagementTypes';
import { DEFAULT_PAGE } from 'util/ConstantValues';
import styles from './AdminInvoiceManagement.module.scss';
import AdminInvoiceTable from 'components/organisms/AdminInvoiceTable';
import { UpdateMailTemplateModal } from 'components/organisms/UpdateMailTemplateModal/UpdateMailTemplateModal';
import { InvoiceItem, getListInvoice } from 'apis/invoice';
import { handleError } from 'util/errorHandler';
import { createAxios } from 'ts/createAxios';
import { useQuery } from 'util/queryParams';
import dayjs from 'dayjs';

export type TimeChoosen = {
  year: string;
  month: string;
};

export type ObjFilter = {
  operatorId: string;
  month?: string | number;
  year?: string | number;
};

const AdminInvoiceManagement = () => {
  const query = useQuery();
  const year = query.get('year');
  const month = query.get('month');
  const operator = query.get('operator');
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [listOperator, setListOperator] = useState<AccountOperator[]>([]);
  const api = createAxios();
  const [defaultEmailModal, setDefaultEmailModal] = useState({
    isShow: false,
  });

  const getBeforeMonthAndYear = () => {
    const target = dayjs().subtract(1, 'month');

    return {
      year: target.get('year'),
      month: target.get('month') + 1,
    };
  };

  const getYearOptions = () => {
    const { year } = getBeforeMonthAndYear();
    const currentYear = dayjs().get('year');

    if (year === currentYear) {
      return [
        { value: year.toString(), label: `${year}年` },
        {
          value: `${year - 1}`,
          label: `${year - 1}年`,
        },
      ];
    }

    return [{ value: year.toString(), label: `${year}年` }];
  };

  const [filterObj, setFilterObj] = useState<ObjFilter>(() => {
    const { month, year } = getBeforeMonthAndYear();

    return {
      operatorId: '',
      year: year.toString(),
      month: month.toString(),
    };
  });

  useEffect(() => {
    if (operator || year || month) {
      setFilterObj({
        operatorId: operator || '',
        year: year || '',
        month: month || '',
      });
    }
  }, [operator, year, month]);

  const operatorList =
    listOperator &&
    listOperator.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const [invoiceList, setInvoiceList] = useState<InvoiceItem[]>([]);

  const handleEditTemplateDefaultMail = () => {
    setDefaultEmailModal({
      isShow: true,
    });
  };

  const fetchOperatorListAll = async () => {
    try {
      const res = await api.get('/operators?type=Emissions&isInvoice=true');
      setListOperator(res.data.operators);
      setFilterObj((s) => {
        return {
          ...s,
          operatorId: s.operatorId ? s.operatorId : res.data.operators[0].id,
        };
      });
    } catch (error) {
      handleError(error);
    }
  };
  useEffect(() => {
    filterObj.operatorId &&
      getListInvoice({
        params: {
          operatorId: filterObj.operatorId,
          date: `${filterObj.year}-${filterObj.month}`,
        },
        callback: (data) => {
          setInvoiceList(data || []);
        },
      });
  }, [filterObj]);

  useEffect(() => {
    fetchOperatorListAll();
  }, []);

  return (
    <AdminTemplate
      h1={'資源循環費請求書の発行'}
      active="請求管理"
      breadcrumb={[
        {
          label: '請求管理',
        },
      ]}
    >
      <div className={styles.adminUserListManagement}>
        <div className={styles.tool}>
          <div className={styles.search}>
            <Form<FilterObj>
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              onSubmit={(values) => {
                //Todo
              }}
              initialValues={
                operatorList.length > 0
                  ? {
                      operatorType: filterObj?.operatorId
                        ? filterObj.operatorId
                        : operatorList[0].value,
                      year: filterObj?.year
                        ? String(filterObj.year)
                        : getYearOptions()[0].value,
                      month: filterObj?.month
                        ? String(filterObj.month)
                        : getBeforeMonthAndYear().month.toString(),
                    }
                  : {}
              }
              mutators={{
                changeField: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
            >
              {(props) => {
                const handleChangeOperatoId = (e) => {
                  setFilterObj({ ...filterObj, operatorId: e });
                };

                const { month: targetMonth, year: targetYear } =
                  getBeforeMonthAndYear();

                const currentMonthOptions = MonthOptions.filter(
                  (item) => Number(item.value) <= targetMonth
                );

                const timeChoosen = {
                  year: props.values.year,
                  month: props.values.month,
                } as TimeChoosen;

                return (
                  <form
                    onSubmit={props.handleSubmit}
                    className={styles.formInput}
                  >
                    <div className={styles.inputFrame}>
                      <div className={styles.chooseDate}>
                        <SelectField
                          name={'operatorType'}
                          options={operatorList}
                          onChange={handleChangeOperatoId}
                        />
                      </div>

                      <div className={styles.chooseDate}>
                        <SelectField
                          name={'year'}
                          options={getYearOptions()}
                          onChange={(e) => {
                            const month =
                              e === targetYear.toString()
                                ? targetMonth.toString()
                                : undefined;

                            setFilterObj({
                              ...filterObj,
                              year: e,
                              ...(month && { month }),
                            });
                          }}
                        />
                      </div>

                      <div className={styles.chooseDate}>
                        <SelectField
                          name={'month'}
                          options={
                            props.values.year === `${new Date().getFullYear()}`
                              ? currentMonthOptions
                              : MonthOptions
                          }
                          placeholder={'選択'}
                          showPlaceholderOption
                          disabled={!props.values.year}
                          onChange={(e) => {
                            setFilterObj({ ...filterObj, month: e });
                          }}
                        />
                      </div>
                    </div>

                    <div className={styles.line} />

                    {defaultEmailModal.isShow === true && (
                      <UpdateMailTemplateModal
                        timeChoosen={timeChoosen}
                        onClose={() => {
                          setDefaultEmailModal({
                            isShow: false,
                          });
                        }}
                      />
                    )}
                  </form>
                );
              }}
            </Form>
          </div>

          <div
            className={styles.buttonAdd}
            onClick={handleEditTemplateDefaultMail}
          >
            <span>デフォルトメールを更新</span>
          </div>
        </div>

        <div className={styles.userTable}>
          <AdminInvoiceTable
            invoiceData={invoiceList}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            date={`${filterObj.year}-${filterObj.month}`}
            operator={filterObj.operatorId}
          />
        </div>
      </div>
    </AdminTemplate>
  );
};

export default AdminInvoiceManagement;
