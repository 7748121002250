import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'util/commons';
import { DateTypeRange } from 'util/Enums';
import styles from './DateTimeUnitTabButton.module.scss';

export type DateTimeUnitTabButtonsProps = {
  dateFrom?: string;
  dateTo?: string;
  activeTab?: DateTypeRange;
  setActiveTab?: (value: DateTypeRange) => void;
  className?: string;
  disableFilterByYearAndDay?: boolean;
};

const DateTimeUnitTabButton = ({
  dateFrom,
  dateTo,
  activeTab = DateTypeRange.MONTH,
  setActiveTab,
  className,
  disableFilterByYearAndDay = true,
}: DateTimeUnitTabButtonsProps) => {
  const { t } = useTranslation();

  const isDisable = useMemo(() => {
    const date1 = dayjs(dateFrom).startOf('day');
    const range = date1.diff(dayjs(dateTo).startOf('day'));
    const diff = dayjs.duration(range);

    if (!disableFilterByYearAndDay) {
      return {
        isDisabledByYear: false,
        isDisabledByDay: false,
      };
    }

    return {
      isDisabledByYear: diff.asYears() <= 1,
      isDisabledByDay: diff.asYears() > 1,
    };
  }, [dateFrom, dateTo]);

  useEffect(() => {
    if (
      (activeTab === DateTypeRange.DAY && isDisable.isDisabledByDay) ||
      (activeTab === DateTypeRange.YEAR && isDisable.isDisabledByYear)
    ) {
      setActiveTab?.(DateTypeRange.MONTH);
    }
  }, [activeTab, isDisable.isDisabledByDay, isDisable.isDisabledByYear]);

  return (
    <div className={mergeClasses(styles.buttonTabs, className)}>
      <button
        disabled={isDisable.isDisabledByYear}
        className={mergeClasses(styles.buttonStyle, {
          [styles.active]: activeTab === DateTypeRange.YEAR,
        })}
        onClick={() => {
          setActiveTab?.(DateTypeRange.YEAR);
        }}
      >
        {t('purchase_page.resource_search.year')}
      </button>
      <button
        className={mergeClasses(styles.buttonStyle, {
          [styles.active]: activeTab === DateTypeRange.MONTH,
        })}
        onClick={() => setActiveTab?.(DateTypeRange.MONTH)}
      >
        {t('purchase_page.resource_search.month')}
      </button>
      <button
        disabled={isDisable.isDisabledByDay}
        className={mergeClasses(styles.buttonStyle, {
          [styles.active]: activeTab === DateTypeRange.DAY,
        })}
        onClick={() => setActiveTab?.(DateTypeRange.DAY)}
      >
        {t('purchase_page.resource_search.day')}
      </button>
    </div>
  );
};

export default DateTimeUnitTabButton;
