// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { fetchExchangeRateCurrencies } from 'apis/commons/exchangeRateCurrenciesApi';
import { fetchDashboardRateWeight } from 'apis/dashboards/dashboardApi';
import { useQueryPurchaseRateCo2Mutation } from 'apis/purchase/purchaseApi';
import { fetchDashboardPurchaseMaterialFlow } from 'apis/site_purchase/purchase';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import MaterialFlowChart from 'components/molecules/MaterialFlowChart';
import CO2ReductionCostOrganism from 'components/organisms/CO2ReductionCostOrganism';
import ComparisonOfProcurementIntensityOrganism from 'components/organisms/ComparisonOfProcurementIntensityOrganism';
import ProcessInfoBoxOrganism from 'components/organisms/ProcessInfoBoxOrganism';
import ProcurementIntensityOrganism from 'components/organisms/ProcurementIntensityOrganism';
import TotalWeightOfEachProcessFilter from 'components/organisms/TotalWeightOfEachProcessFilter';
import PurchaseTemplate from 'components/templates/PurchaseTemplate';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { setCurrency } from 'redux/slices/currencySlice';
import { getSlaFormatYearMonthDate } from 'ts/formatDate';
import {
  CO2ReductionCostData,
  FilterObject,
  RateWeight,
} from 'types/dashboard';
import { RecycleChainMaterialFlow } from 'types/purchase';
import { CurrencyUnitType, UnitType } from 'util/constants/dashboard';
import styles from './PurchaseDashboard.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
  LineController,
  ChartDataLabels
);

const PurchaseDashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { recycleChainId } = useParams<{ recycleChainId: string }>();
  const [filter, setFilter] = useState<FilterObject>({
    dates: {
      from: getSlaFormatYearMonthDate(dayjs().subtract(6, 'month').format()),
      to: getSlaFormatYearMonthDate(dayjs().format()),
    },
  });
  const [rateWeight, setRateWeight] = useState<RateWeight[]>([]);
  const [materialFlowData, setMaterialFlowData] =
    useState<RecycleChainMaterialFlow>();
  const [unitType, setUnitType] = useState<UnitType>(UnitType.T);
  const [co2ReductionCost, setCo2ReductionCost] =
    useState<CO2ReductionCostData>();

  const [
    fnQuery,
    { data: procurementIntensity, isLoading: procurementIntensityLoading },
  ] = useQueryPurchaseRateCo2Mutation();

  useEffect(() => {
    callApiRateWeight();
    getDashboardMaterialFlow();
  }, [filter, recycleChainId]);

  useEffect(() => {
    setCo2ReductionCost({
      currencyUnitType: CurrencyUnitType.JPY,
      totalCo2: Number(
        procurementIntensity?.co2ReductionAmount || 0
      ).toString(),
      totalPrice: Number(
        procurementIntensity?.poolProcurementCost || 0
      ).toString(),
    });
  }, [procurementIntensity]);

  useEffect(() => {
    callApiRateWeight();
  }, [recycleChainId]);

  const commonParams = useMemo(() => {
    return {
      dateFrom: filter.dates.from,
      dateTo: filter.dates.to,
      recycleChainId: Number(recycleChainId),
    };
  }, [filter, recycleChainId]);

  useEffect(() => {
    fnQuery({
      recycleChainId: Number(recycleChainId),
    }).unwrap();
  }, [recycleChainId]);

  const callApiRateWeight = () => {
    fetchDashboardRateWeight(commonParams, (data) => {
      if (data) {
        setRateWeight(data);
      }
    });
  };

  const getDashboardMaterialFlow = () => {
    fetchDashboardPurchaseMaterialFlow({
      params: {
        dateFrom: filter.dates.from,
        dateTo: filter.dates.to,
        recycleChainId: recycleChainId,
      },
      successCallback: (data) => {
        if (data) {
          setMaterialFlowData(data);
        }
      },
    });
  };

  const handleGetExchangeRateCurrencies = () => {
    fetchExchangeRateCurrencies({
      params: {
        base: CurrencyUnitType.JPY,
        target: [CurrencyUnitType.EUR, CurrencyUnitType.USD].toString(),
      },
      successCallback: (data) => {
        if (data) dispatch(setCurrency(data));
      },
    });
  };

  useEffect(() => {
    handleGetExchangeRateCurrencies();
  }, []);

  return (
    <PurchaseTemplate title={t('purchase_page.dashboard.heading')}>
      <div className={styles.purchaseDashboardContainer}>
        <div className={styles.totalWeightOfEachProcessFilter}>
          <TotalWeightOfEachProcessFilter
            filterObject={filter}
            setFilter={setFilter}
            unitType={unitType}
            setUnitType={setUnitType}
          />
        </div>

        {rateWeight.length > 0 && (
          <div className={styles.generalsProcessInfo}>
            <ProcessInfoBoxOrganism
              unitType={unitType}
              rateWeight={rateWeight}
            />
          </div>
        )}

        <div className={styles.topChart}>
          <MaterialFlowChart
            materialFlowData={materialFlowData?.materialFlow || []}
            sites={materialFlowData?.sites || []}
            unitType={unitType}
          />
          <div className={styles.donutChart}>
            <div className={styles.donutContainer}>
              <ComparisonOfProcurementIntensityOrganism
                comparisonOfProcurementIntensity={{
                  comparisonOfProcurementIntensity:
                    procurementIntensity?.comparisonOfProcurementIntensity ||
                    [],
                }}
              />
              <CO2ReductionCostOrganism co2ReductionCost={co2ReductionCost} />
              <ProcurementIntensityOrganism
                filter={filter}
                title={t('purchase_page.dashboard.procurement_intensity')}
                content={t(
                  'purchase_page.dashboard.co2_emissions_per_1kg_of_Procurement'
                )}
                textCenter="kg-CO2/kg"
                procurementIntensity={procurementIntensity}
                loading={procurementIntensityLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </PurchaseTemplate>
  );
};

export default PurchaseDashboard;
