// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './ScaleEmissionConfirm.scss';
import { PrimaryBtnField } from 'components/molecules/PrimaryBtnField';
import { ImagePath } from 'util/ConstantValues';
import { useTranslation } from 'react-i18next';

interface Props {
  wasteId: string;
  wasteName: string;
  weight: string;
  onSubmit: () => void;
  onPrev: () => void;
}

export function ScaleEmissionConfirm({
  wasteId,
  wasteName,
  weight,
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="description">
        {t('scale_emission.confirm.description')}
      </div>

      <div className="scale-input scale-confirm">
        <div className="input-type">
          <img src={ImagePath[wasteId]} />
        </div>
        <div className="item">
          <div className="label">{t('common.item')}</div>
          <div className="value">{wasteName}</div>
        </div>
        <div className="item">
          <div className="label">{t('common.weight')}</div>
          <div className="value">{weight}kg</div>
        </div>
        <br />
        <br />
        <PrimaryBtnField
          text={t('common.button.register')}
          onClick={onSubmit}
        ></PrimaryBtnField>
      </div>
    </>
  );
}
