// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { GoogleMap, Libraries, useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MAP_NIGHT_MODE } from 'util/ConstantValues';

type MapBoxProps = {
  bounds: google.maps.LatLngAltitude[] | google.maps.LatLng[];
  drawInMap?: JSX.Element[];
  zoom?: number;
  isDarkMode?: boolean;
  isCenter?: boolean;
  onCreatedMap?: (value: google.maps.Map) => void; // props map to parent component
  onMouseDown?: () => void;
};

const containerStyle = {
  width: '100%',
  height: '100%',
};

function MapBox({
  bounds,
  drawInMap,
  isDarkMode,
  zoom = 4,
  isCenter = true,
  onCreatedMap,
  onMouseDown,
}: MapBoxProps) {
  const [map, setMap] = useState<google.maps.Map>();
  const librariesRef = useRef<Libraries>(['maps', 'visualization', 'places']);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    language: 'JA',
    region: 'JP',
    libraries: librariesRef.current,
  });

  const getCenter = () => {
    const latLngBounds = new window.google.maps.LatLngBounds();
    bounds.forEach((b: google.maps.LatLngAltitude | google.maps.LatLng) =>
      latLngBounds.extend(b)
    );
    return latLngBounds;
  };

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  useEffect(() => {
    if (!map || !isLoaded) {
      return;
    }

    onCreatedMap?.(map);

    if (!isCenter) {
      const latLngBounds = getCenter();
      map.setCenter(latLngBounds.getCenter());
      map.setZoom(zoom);
    }
  }, [map, bounds, isCenter, isLoaded, drawInMap]); // drawInMap to re-center or default zoom when data change

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      onLoad={onLoad}
      options={{
        gestureHandling: 'greedy',
        scaleControl: false,
        zoomControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        styles: isDarkMode ? MAP_NIGHT_MODE : [],
      }}
      onMouseDown={onMouseDown}
    >
      {drawInMap}
    </GoogleMap>
  ) : null;
}

export default MapBox;
