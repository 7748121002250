// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import Chart, { SankeyDataPoint } from 'chart.js/auto';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MaterialFlow } from 'types/purchase';
import { DATE_FORMAT } from 'util/ConstantValues';
import { ColorsPaletteKeys } from 'util/Enums';
import { CommonSite } from 'util/Types';
import { convertWeight, normalizeNumber, objectFetch } from 'util/commons';
import { UnitType } from 'util/constants/dashboard';
import { useGenerateColorsPalette } from 'util/hooks/useGenerateColorsPalette';
import { Flow, SankeyController } from 'util/sankeyChartPlugin';
import styles from './index.module.scss';

Chart.register(SankeyController, Flow);

type Props = {
  materialFlowData: MaterialFlow[];
  sites: CommonSite[];
  unitType: UnitType;
};

const MINIMUM_FLOW_HEIGHT = 3;

const MaterialFlowChart: React.FC<Props> = ({
  materialFlowData,
  sites,
  unitType,
}) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const { t } = useTranslation();

  const allSiteId = Array.from(
    new Set(
      materialFlowData.reduce(
        (rs: string[], { deliveryFromSiteId, deliveryToSiteId, sortIndex }) => {
          if (sortIndex !== 1) {
            return [deliveryFromSiteId, deliveryToSiteId, ...rs];
          }

          return [deliveryToSiteId, ...rs];
        },
        []
      )
    )
  );

  const { colorsPalette } = useGenerateColorsPalette({
    key: ColorsPaletteKeys.PURCHASE_MATERIAL_FLOW,
    numOfColors: allSiteId.length,
    hueStep: 40,
    startColor: '#00FF55',
    randomSaturationAndBrightness: true,
    skipStartColor: true,
  });

  const siteNameMapper = useMemo(
    () =>
      materialFlowData.reduce(
        (
          rs: Record<string, string>,
          { deliveryFromSiteId, deliveryToSiteId, sortIndex }
        ) => {
          if (!rs[deliveryFromSiteId] || !rs[deliveryToSiteId]) {
            [deliveryFromSiteId, deliveryToSiteId].forEach((item) => {
              const site = sites.find(({ id }) => id === item);

              if (site) {
                if (sortIndex === 1 && site.name == null) {
                  rs[item] = t('dashboard.site_type_none');
                } else {
                  rs[item] = site.name;
                }
              }
            });
          }

          return rs;
        },
        {}
      ),
    [sites, allSiteId, materialFlowData]
  );

  const { assigned, column } = materialFlowData.reduce(
    (
      rs: {
        assigned: Record<string, string>;
        index: number;
        column: Record<string, number>;
      },
      { sortIndex, deliveryFromSiteId, deliveryToSiteId }
    ) => {
      const sortIndexes = materialFlowData.map((item) => item.sortIndex);
      const minSortIndex = Math.min(...sortIndexes);

      if (sortIndex === 1) {
        rs.assigned[deliveryFromSiteId] = '#73d052';
      }

      [deliveryFromSiteId, deliveryToSiteId].forEach((siteId, i) => {
        if (!rs.assigned[siteId]) {
          rs.assigned[siteId] = colorsPalette[rs.index];
          rs.index = rs.index + 1;
        }

        if (!rs.column[siteId]) {
          rs.column[siteId] =
            (i === 0 ? sortIndex : sortIndex + 1) - minSortIndex;
        }
      });

      return rs;
    },
    { assigned: {}, index: 0, column: {} }
  );

  const getColor = (name: string) => {
    return assigned[name];
  };

  const maxFlow = Math.max(
    ...(materialFlowData?.map(({ total }) => Number(total)) ?? [0])
  );

  const transformedData: SankeyDataPoint[] = materialFlowData?.map((item) => {
    const flow = Number(item.total);
    const percentCompareToMax = (flow / maxFlow) * 100;

    return {
      from: item.deliveryFromSiteId,
      to: item.deliveryToSiteId,
      flow:
        percentCompareToMax < MINIMUM_FLOW_HEIGHT
          ? (MINIMUM_FLOW_HEIGHT / 100) * maxFlow
          : flow,
      originalFlow: flow,
    };
  });

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext?.('2d');

      if (ctx) {
        const sankeyChart = new Chart(ctx, {
          type: 'sankey',
          data: {
            datasets: [
              {
                data: transformedData,
                borderWidth: 0,
                colorFrom: (context) =>
                  getColor(context?.dataset?.data?.[context?.dataIndex]?.from),
                colorTo: (context) =>
                  getColor(context?.dataset?.data?.[context?.dataIndex]?.to),
                colorMode: 'gradient',
                size: 'max',
                column,
                labels: siteNameMapper,
              },
            ],
          },
          options: {
            font: {
              size: 13,
              weight: '700',
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label(context) {
                    const item = context.dataset.data[context.dataIndex];

                    return (
                      siteNameMapper[item.from] +
                      ' -> ' +
                      siteNameMapper[item.to] +
                      ': ' +
                      normalizeNumber({
                        value: convertWeight(
                          unitType,
                          Number(objectFetch(item, 'originalFlow'))
                        ),
                      })
                    );
                  },
                },
              },
            },
          },
        });

        return () => {
          sankeyChart.destroy();
        };
      }
    }
  }, [chartRef, materialFlowData, unitType, t, colorsPalette]);

  const handleDownload = () => {
    if (chartRef.current) {
      html2canvas(chartRef.current, {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      }).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = `${t('dashboard.material_flow')} ${dayjs().format(
          DATE_FORMAT.slaYMDHm
        )}.png`;
        link.click();
        link.remove();
      });
    }
  };

  return (
    <div className={styles.materialFlowChart}>
      <div className={styles.heading}>
        <div className={styles.label}>
          <img src="/assets/images/logos.svg" alt="logo" />
          <p>{t('dashboard.material_flow')}</p>
        </div>
        {materialFlowData.length > 0 && (
          <div
            className={styles.buttonDownload}
            onClick={() => {
              handleDownload();
            }}
          >
            <IconDownload />
          </div>
        )}
      </div>
      <div className={styles.body}>
        {materialFlowData?.length ? (
          <canvas ref={chartRef} className={styles.chart} />
        ) : (
          <div className="no-data">{t('messages.M_008')}</div>
        )}
      </div>
    </div>
  );
};

export default MaterialFlowChart;
