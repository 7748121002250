// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './CsvDownloadButton.scss';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { IconDownload } from '../icons/IconDownload';

type CsvDownloadButtonProps = {
  disabled?: boolean;
  onClick?: VoidFunction;
  fileName: string;
  headers: string[];
  fetchData: string[][];
};

export const CsvDownloadButton = (props: CsvDownloadButtonProps) => {
  const { t } = useTranslation();

  return (
    <CSVLink
      className="csv-button"
      onClick={props.onClick}
      disabled={props.disabled}
      filename={props.fileName + '.csv'}
      headers={props.headers}
      data={props.fetchData}
    >
      <IconDownload />
      {t('common.csv_download')}
    </CSVLink>
  );
};
