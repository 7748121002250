// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './CheckBoxCard.scss';
import React from 'react';
import { CheckBox } from 'components/atoms/CheckBox';

interface Props {
  onChange: (e: React.ChangeEvent) => void;
  checked: boolean;
  id: string;
  children: React.ReactNode;
  disabled?: boolean;
}

export function CheckBoxCard({
  onChange,
  id,
  checked,
  children,
  disabled,
}: Props) {
  return (
    <div className="check-card">
      <div>
        <CheckBox
          id={id}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
      <div>{children}</div>
    </div>
  );
}
