// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useTranslation } from 'react-i18next';
import { MaterialSiteTypeGradeStatistic } from 'types/purchase';
import { convertWeight, normalizeNumber } from 'util/commons';
import { UnitType } from 'util/constants/dashboard';
import styles from './PurchaseResourceSearchTable.module.scss';

type PurchaseResourceSearchTableProps = {
  data: MaterialSiteTypeGradeStatistic[];
  onClick?: VoidFunction;
  isHighLight?: boolean;
  totalWeight?: string;
  totalRecord?: number;
};

const PurchaseResourceSearchTable = ({
  data,
  onClick,
  isHighLight = false,
  totalWeight,
  totalRecord,
}: PurchaseResourceSearchTableProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper} onClick={onClick}>
      <table>
        <thead>
          <tr>
            <th className={isHighLight ? styles.highlight : ''}>
              {t('purchase_page.resource.site_type')}
            </th>
            <th className={isHighLight ? styles.highlight : ''}>
              {t('purchase_page.resource_search.material_name')}
            </th>
            <th className={isHighLight ? styles.highlight : ''}>
              {t('purchase_page.resource_search.grade')}
            </th>
            <th className={styles.weight}>
              {t('purchase_page.receive.weight')} (t)
            </th>
          </tr>
        </thead>
        <tbody>
          {data.length ? (
            data.map((d, i) => (
              <tr key={i}>
                <td>{d.siteType}</td>
                <td>{d.materialName}</td>
                <td>{d.gradeName}</td>
                <td style={{ textAlign: 'right' }}>
                  {normalizeNumber({
                    value: convertWeight(UnitType.T, Number(d.weight)),
                  })}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className="text-center">
                {t('messages.M_008')}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className={styles.totalWeight}>
        <div className={styles.container}>
          <span>
            {totalWeight != null &&
              `${t('purchase_page.resource.total')} ${normalizeNumber({
                value: totalWeight,
              })} t`}
          </span>
          <span>
            {totalRecord != null &&
              t('purchase_page.resource_search.results', {
                records: normalizeNumber({ value: totalRecord }),
              })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PurchaseResourceSearchTable;
