// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { TabletTab } from 'components/molecules/TabletTab';
import CompressionDelivery from 'components/organisms/CompressionDelivery';
import CompressionRegistration from 'components/organisms/CompressionRegistration';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styles from './Compressions.module.scss';

function Compressions() {
  const { t } = useTranslation();
  const history = useHistory();
  const [isRefresh, setRefresh] = useState(false);

  return (
    <PrimaryTemplate
      h1={t('compressions.heading')}
      className={styles.container}
    >
      <TabletTab
        tabItems={[
          t('compressions.tab_left_heading'),
          t('compressions.tab_right_heading'),
        ]}
        defaultTabIndex={history.location.hash == '#1' ? 1 : 0}
        renderContent={({ index }) => (
          <div>
            {index === 0 && (
              <CompressionRegistration
                setRefresh={(value) => setRefresh(value)}
                isRefresh={isRefresh}
              />
            )}
            {index === 1 && (
              <CompressionDelivery
                setRefresh={(value) => setRefresh(value)}
                isRefresh={isRefresh}
              />
            )}
          </div>
        )}
      />
    </PrimaryTemplate>
  );
}

export default Compressions;
