// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { InputDataListField } from '../InputDataListField';
import styles from './index.module.scss';
import { ResourceManagementItem } from 'util/adminResourceManagementTypes';
import { SelectField, SelectOption } from '../SelectField';
import { validateInputWeight, validateRequiredField } from 'util/validator';
import { REGEX_OBJECT } from 'util/ConstantValues';
import WeightLimitExceededModal from 'components/organisms/WeightLimitExceededModal';

interface Props {
  initForm?: ResourceManagementItem;
  onSubmit: (resourceForm: ResourceManagementItem) => void;
  grades: SelectOption[];
  types: SelectOption[];
}

const EditRecycleResourceForm: React.FC<Props> = ({
  initForm,
  onSubmit,
  grades,
  types,
}) => {
  const [isShownWeightLimitExceededModal, setIsShownWeightLimitExceededModal] =
    useState(false);
  const [formValues, setFormValues] = useState<ResourceManagementItem | null>(
    null
  );

  const handleSubmit = (values) => {
    if (Number(initForm?.totalInputWeight) >= Number(values.weight)) {
      onSubmit(values);
    } else {
      setIsShownWeightLimitExceededModal(true);
      setFormValues(values);
    }
  };

  const handleModalSubmit = () => {
    if (formValues) {
      onSubmit(formValues);
    }
    setIsShownWeightLimitExceededModal(false);
  };

  return (
    <div className={styles.createBody}>
      <Form<ResourceManagementItem>
        onSubmit={handleSubmit}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        initialValues={initForm}
        validate={(values) => {
          return {
            weight: validateInputWeight('重量', 10, Number(values.weight)),
            gradeId: validateRequiredField(values.gradeId, 'グレード'),
            materialTypeId: validateRequiredField(
              values.materialTypeId,
              'グレード'
            ),
          };
        }}
      >
        {(props) => {
          return (
            <form onSubmit={props.handleSubmit} className={styles.formInput}>
              <div className={styles.inputFrame}>
                <InputDataListField
                  label="事業者名"
                  placeholder="事業者名"
                  name="operatorName"
                  disabled
                />
                <InputDataListField
                  label="拠点"
                  placeholder="拠点"
                  name="siteName"
                  disabled
                />
                <InputDataListField
                  label={'材料名'}
                  placeholder={'材料名'}
                  name="item"
                  disabled
                />
                <InputDataListField
                  required
                  label="重量 (kg)"
                  placeholder="重量"
                  name="weight"
                  validRegex={REGEX_OBJECT.numberWithDot5Decimal}
                  maxlength={10}
                  subFix={
                    <span className={styles.weightUnit}>
                      投入資源：{initForm?.totalInputWeight} Kg
                    </span>
                  }
                />
                <SelectField
                  label="グレード"
                  placeholder="グレード"
                  name="gradeId"
                  options={
                    initForm &&
                    initForm.grade &&
                    !grades.find((i) => i.value === initForm.grade?.id)
                      ? [
                          ...grades,
                          {
                            label: initForm.grade.name,
                            value: initForm.grade.id,
                          },
                        ]
                      : grades
                  }
                  required
                />
                <SelectField
                  label="種別"
                  placeholder="種別"
                  name="materialTypeId"
                  options={
                    initForm &&
                    initForm.materialType &&
                    !types.find((i) => i.value === initForm.materialType?.id)
                      ? [
                          ...types,
                          {
                            label: initForm.materialType.name,
                            value: initForm.materialType.id,
                          },
                        ]
                      : types
                  }
                  required
                />
              </div>
              <div className={styles.submit}>
                <button
                  className={`${styles.submitButton} ${
                    props.invalid ? styles.disableButton : ''
                  }`}
                  disabled={props.invalid}
                  type="submit"
                >
                  {'保存する'}
                </button>
              </div>
            </form>
          );
        }}
      </Form>

      <WeightLimitExceededModal
        open={isShownWeightLimitExceededModal}
        onSubmit={handleModalSubmit}
        onClose={() => setIsShownWeightLimitExceededModal(false)}
      />
    </div>
  );
};

export default EditRecycleResourceForm;
