// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import BigNumber from 'bignumber.js';
import IconCurrencyUnitEUR from 'components/atoms/icons/IconCurrencyUnitEUR';
import IconCurrencyUnitJPY from 'components/atoms/icons/IconCurrencyUnitJPY';
import IconCurrencyUnitUSD from 'components/atoms/icons/IconCurrencyUnitUSD';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { CO2ReductionCostData } from 'types/dashboard';
import { normalizeNumber } from 'util/commons';
import { CurrencyUnitType } from 'util/constants/dashboard';
import styles from './CO2ReductionCostOrganism.module.scss';

type Props = {
  co2ReductionCost?: CO2ReductionCostData;
};

const CO2ReductionCostOrganism: React.FC<Props> = ({ co2ReductionCost }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(CurrencyUnitType.JPY);
  const [price, setPrice] = useState<string>(
    co2ReductionCost?.totalPrice || ''
  );

  const title = t('purchase_page.dashboard.co2_reduction_cost');

  const rateUSD = useSelector((state: RootState) => state.currency.rates.USD);

  const rateEUR = useSelector((state: RootState) => state.currency.rates.EUR);

  useEffect(() => {
    const totalPrice = new BigNumber(co2ReductionCost?.totalPrice || '');

    switch (active) {
      case CurrencyUnitType.EUR:
        setPrice(totalPrice.multipliedBy(rateEUR).toString());
        break;
      case CurrencyUnitType.USD:
        setPrice(totalPrice.multipliedBy(rateUSD).toString());
        break;
      default:
        setPrice(totalPrice.toString());
    }
  }, [active, co2ReductionCost]);

  return (
    <div className={styles.CO2ReductionCost}>
      <div className={styles.heading}>
        <p>{title}</p>
        <div className={styles.actions}>
          <div className={styles.filter}>
            <button
              className={
                active === CurrencyUnitType.USD
                  ? styles.filterButtonSelected
                  : ''
              }
              onClick={() => {
                setActive(CurrencyUnitType.USD);
              }}
            >
              <IconCurrencyUnitUSD />
            </button>
            <button
              className={
                active === CurrencyUnitType.JPY
                  ? styles.filterButtonSelected
                  : ''
              }
              onClick={() => {
                setActive(CurrencyUnitType.JPY);
              }}
            >
              <IconCurrencyUnitJPY />
            </button>
            <button
              className={
                active === CurrencyUnitType.EUR
                  ? styles.filterButtonSelected
                  : ''
              }
              onClick={() => {
                setActive(CurrencyUnitType.EUR);
              }}
            >
              <IconCurrencyUnitEUR />
            </button>
          </div>
        </div>
      </div>
      <div className={styles.CO2ReductionCostInfo}>
        <div className={styles.generalItem}>
          <div className={styles.generalItemWrapper}>
            <p className={styles.label}>
              {t('purchase_page.dashboard.pool_procurement_post')}
            </p>
            <p className={styles.quantity}>
              {price &&
                normalizeNumber({
                  value: price,
                })}
            </p>
            <p className={styles.labelType}>{active}</p>
          </div>
        </div>
        <div className={styles.generalItem}>
          <div className={styles.generalItemWrapper}>
            <p className={styles.label}>
              {t('purchase_page.dashboard.co2_reduction')}
            </p>
            <p className={styles.quantity}>
              {(co2ReductionCost?.totalCo2 &&
                normalizeNumber({
                  value: co2ReductionCost.totalCo2,
                })) ||
                0}
            </p>
            <p className={styles.labelType}>
              {t('purchase_page.dashboard.t_co2')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CO2ReductionCostOrganism;
