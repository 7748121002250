// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e?: any) => void;
  size?: number;
}
export const IconDashboardEmission: React.FC<Props> = ({ onClick, size }) => {
  return (
    <svg
      width={size || '48'}
      height={size || '48'}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && onClick}
    >
      <path
        d="M42.4386 16.9386L41.3812 19.932L38.5413 18.5132"
        stroke="#86EA5C"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.56764 18.5941C8.82302 13.453 12.4504 8.96978 17.7319 6.83593C26.4483 3.31425 36.3693 7.52544 39.891 16.2419L41.3817 19.9317"
        stroke="#86EA5C"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.77539 28.2952L6.83276 25.3019L9.67265 26.7206"
        stroke="#86EA5C"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.625 20.6389V29.8056M26.375 20.6389V29.8056M17 17.4444H32M31.0625 17.4444L30.2497 30.9356C30.216 31.4962 30.0043 32.0209 29.6573 32.404C29.3102 32.787 28.8536 33 28.3794 33H20.6206C20.1464 33 19.6898 32.787 19.3427 32.404C18.9957 32.0209 18.784 31.4962 18.7503 30.9356L17.9375 17.4444H31.0625ZM27.3125 17.4444V14.1111C27.3125 13.8164 27.2137 13.5338 27.0379 13.3254C26.8621 13.1171 26.6236 13 26.375 13H22.625C22.3764 13 22.1379 13.1171 21.9621 13.3254C21.7863 13.5338 21.6875 13.8164 21.6875 14.1111V17.4444H27.3125Z"
        stroke="#86EA5C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1296 34C9.01876 32.6353 8.09055 31.0834 7.39686 29.3665L5.90609 25.6767C5.6992 25.1646 5.9466 24.5818 6.45867 24.3749C6.97074 24.168 7.55357 24.4154 7.76046 24.9275L9.25122 28.6173C10.0935 30.7019 11.3246 32.5128 12.8272 34H10.1296ZM38.0814 34C39.7779 31.9137 40.9846 29.4741 41.6188 26.8768C41.7498 26.3403 41.4211 25.7992 40.8846 25.6682C40.3481 25.5372 39.8069 25.8659 39.6759 26.4024C38.9778 29.2612 37.5016 31.9026 35.3863 34H38.0814Z"
        fill="#86EA5C"
      />
      <path
        d="M12.2104 43.5V37.8765H14.1118V36.1685H8.1875V37.8765H10.0889V43.5H12.2104ZM16.6094 39.4448C16.6094 38.333 17.0928 37.7368 17.8501 37.7368C18.6074 37.7368 19.0854 38.333 19.0854 39.4448V40.2397C19.0854 41.3569 18.6074 41.9316 17.8501 41.9316C17.0928 41.9316 16.6094 41.3569 16.6094 40.2397V39.4448ZM14.4395 40.229C14.4395 42.2861 15.6587 43.6289 17.8501 43.6289C20.0469 43.6289 21.2554 42.2861 21.2554 40.229V39.4717C21.2554 37.4038 20.0522 36.0449 17.8501 36.0449C15.6587 36.0449 14.4395 37.3877 14.4395 39.4717V40.229ZM25.606 43.5V37.8765H27.5073V36.1685H21.583V37.8765H23.4844V43.5H25.606ZM29.0918 43.5L29.4624 42.0337H31.5356L31.8955 43.5H34.1621L31.917 36.1685H29.2529L26.9917 43.5H29.0918ZM30.5635 37.957L31.1865 40.5298H29.8223L30.4614 37.957H30.5635ZM39.6997 41.7544H36.939V36.1685H34.8174V43.5H39.6997V41.7544Z"
        fill="#86EA5C"
      />
    </svg>
  );
};
