// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './LoadingModal.scss';
import { LoadingSpiner } from 'components/atoms/LoadingSpiner';

export type LoadingModalProps = {
  isOpen: boolean;
  hasCash: boolean;
  zIndex: number;
};

export const LoadingModal = (props: LoadingModalProps) => {
  let modalClassName = props.hasCash
    ? 'loading-modal black'
    : 'loading-modal white';
  modalClassName = props.isOpen
    ? modalClassName
    : modalClassName + ' ' + 'closed';

  return (
    <section className={modalClassName} style={{ zIndex: props.zIndex }}>
      <div>
        <LoadingSpiner />
      </div>
    </section>
  );
};
