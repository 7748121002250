// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  onClick?: () => void;
}
export const IconChevronRight: React.FC<Props> = ({ onClick }) => {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M1 1.33301L5.66667 5.99967L1 10.6663"
        stroke="#333333"
        strokeOpacity="0.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
