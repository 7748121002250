// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './LoadingSpiner.scss';
import Loader from 'react-loader-spinner';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};
export const LoadingSpiner: React.FC<Props> = ({ height, width, color }) => {
  return (
    <Loader
      type="Oval"
      color={color ? color : '#86EA5C'}
      height={height || 70}
      width={width || 70}
    />
  );
};
