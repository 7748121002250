// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import AdminResourceHistoryTable from 'components/organisms/AdminResourceHistoryTable/AdminResourceHistoryTable';
import { AdminTemplate } from 'components/templates/AdminTemplate';
import { Path } from 'routes';

const AdminEmissionResourceHistory = () => {
  return (
    <AdminTemplate
      h1={'資源一覧画面'}
      active={'履歴'}
      breadcrumb={[
        { label: 'データ編集', href: Path.adminEmissionResourceHistory },
        { label: '履歴' },
      ]}
    >
      <AdminResourceHistoryTable />
    </AdminTemplate>
  );
};

export default AdminEmissionResourceHistory;
