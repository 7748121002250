// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { BottomField } from 'components/molecules/BottomField';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LAYOUT_BREAK_POINTS, REGEX_OBJECT } from 'util/ConstantValues';
import { OperatorType } from 'util/Enums';
import {
  Option,
  PreRegisResource,
  PreRegisSelectedResource,
  ResourcePreRegisFilter,
  SelectedResources,
} from 'util/Types';
import styles from './PreRegisResourceSelect.module.scss';
import { mergeClasses } from 'util/commons';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import ResourceTable from './ResourceTable';
import MobileResourceList from './MobileResouceList';
import { useGetWindowSize } from 'util/hooks/useGetWindowSize';

export type ResourceTableProps = {
  handleCheckAll: () => void;
  filter: ResourcePreRegisFilter;
  setFilter: Dispatch<SetStateAction<ResourcePreRegisFilter>>;
  resourceData: PreRegisResource[];
  siteOptions: Option[];
  materialNameOptions: Option[];
  operatorType: OperatorType;
  displayResourceData: PreRegisResource[];
  selectedResources: SelectedResources;
  isActionDisabled: (item: PreRegisResource) => boolean;
  handleCheckItem: (value: boolean, item: PreRegisResource) => void;
  handleChangeInput: (value: string, item: PreRegisResource) => void;
  isCheckAllDisabled?: boolean;
  allChecked?: boolean;
};

type Props = {
  operatorType: OperatorType;
  resourceData: PreRegisResource[];
  onNext?: (values: PreRegisSelectedResource[]) => void;
  actionDisabled?: boolean;
  className?: string;
  withMobileMode?: boolean;
  actionProps?: {
    selectedResources: SelectedResources;
    setSelectedResources: Dispatch<SetStateAction<SelectedResources>>;
    filter: ResourcePreRegisFilter;
    setFilter: Dispatch<SetStateAction<ResourcePreRegisFilter>>;
  };
};

const PreRegisResourceSelect: React.FC<Props> = ({
  operatorType,
  resourceData,
  onNext,
  actionDisabled,
  className,
  actionProps,
  withMobileMode,
}) => {
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);

  const { t } = useTranslation();
  const [selectedResources, setSelectedResources] = actionProps
    ? [actionProps.selectedResources, actionProps.setSelectedResources]
    : useState<SelectedResources>({});
  const [filter, setFilter] = actionProps
    ? [actionProps.filter, actionProps.setFilter]
    : useState<ResourcePreRegisFilter>({});

  const { windowWidth } = useGetWindowSize();

  const { siteOptions, materialNameOptions } = useMemo(
    () =>
      resourceData.reduce(
        (
          rs: { siteOptions: Option[]; materialNameOptions: Option[] },
          item
        ) => {
          if (!rs.siteOptions.find(({ value }) => value === item.site.id)) {
            rs.siteOptions.push({
              value: item.site.id,
              label: item.site.name,
            });
          }

          if (
            !rs.materialNameOptions.find(
              ({ value }) => value === item.materialName
            )
          ) {
            rs.materialNameOptions.push({
              value: item.materialName,
              label: item.materialName,
            });
          }

          return rs;
        },
        { siteOptions: [], materialNameOptions: [] }
      ),
    [resourceData]
  );

  const displayResourceData = useMemo(
    () =>
      resourceData.filter((e) => {
        const cvReceivedDatetime = dayjs(e.receivedDatetime).subtract(
          9,
          'hour'
        );

        return (
          (!filter?.siteIds?.length || filter.siteIds.includes(e.site.id)) &&
          (filter?.receivedDatetime == null ||
            (dayjs(filter.receivedDatetime[0]).isSameOrBefore(
              cvReceivedDatetime,
              'date'
            ) &&
              dayjs(filter.receivedDatetime[1]).isSameOrAfter(
                cvReceivedDatetime,
                'date'
              ))) &&
          (filter?.materialName == null ||
            filter.materialName === e.materialName)
        );
      }),
    [resourceData, filter]
  );

  const isCheckAllDisabled = useMemo(() => {
    const hasSomeCheckedData = Object.values(selectedResources).some(
      ({ checked }) => checked
    );

    return (
      actionDisabled ||
      !displayResourceData.length ||
      (!hasSomeCheckedData &&
        displayResourceData.some(
          ({ materialName }) =>
            materialName !== displayResourceData[0].materialName
        ))
    );
  }, [displayResourceData, actionDisabled, selectedResources]);

  const isActionDisabled = (item: PreRegisResource) => {
    const selectedValues = Object.entries(selectedResources).filter(
      ([id, { checked }]) =>
        checked &&
        displayResourceData.find(
          ({ processingObjectId }) => processingObjectId === id
        )
    );

    return (
      actionDisabled ||
      Boolean(
        selectedValues.length &&
          selectedValues[0][1].materialName !== item.materialName
      )
    );
  };

  const unCheckDifferentMaterials = (
    data: SelectedResources,
    materialName: string
  ) => {
    return Object.entries(data).reduce((rs: SelectedResources, [key, val]) => {
      rs[key] = {
        ...val,
        checked: val.materialName === materialName ? val.checked : false,
        hasError: val.materialName === materialName ? val.hasError : false,
      };

      return rs;
    }, {});
  };

  const isSubmitButtonDisabled = useMemo(() => {
    const displayingSelectedData = Object.entries(selectedResources).filter(
      ([id, { checked }]) =>
        checked &&
        displayResourceData.find(
          ({ processingObjectId }) => processingObjectId === id
        )
    );

    return (
      !displayingSelectedData.length ||
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayingSelectedData.some(([_, { hasError }]) => hasError)
    );
  }, [displayResourceData, selectedResources]);

  const handleChangeInput = (value: string, item: PreRegisResource) => {
    if (
      (value && !REGEX_OBJECT.numberWithDot2Decimal.test(value)) ||
      isActionDisabled(item)
    ) {
      return;
    }

    setSelectedResources((prev) => ({
      ...unCheckDifferentMaterials(prev, item.materialName),
      [item.processingObjectId]: {
        weight: value,
        materialName: item.materialName,
        checked: Boolean(value),
        hasError: Number(value) > Number(item.remainingWeight),
      },
    }));
  };

  const handleCheckItem = (value: boolean, item: PreRegisResource) => {
    if (isActionDisabled(item)) {
      return;
    }

    setSelectedResources((prev) => ({
      ...unCheckDifferentMaterials(prev, item.materialName),
      [item.processingObjectId]: {
        weight: prev[item.processingObjectId]?.weight || item.remainingWeight,
        materialName: item.materialName,
        checked: value,
        hasError:
          value &&
          Number(prev[item.processingObjectId]?.weight ?? 0) >
            Number(item.remainingWeight),
      },
    }));
  };

  const checkableData = useMemo(
    () => displayResourceData.filter((item) => !isActionDisabled(item)),
    [displayResourceData, isActionDisabled]
  );

  const allChecked = useMemo(
    () =>
      Object.entries(selectedResources).filter(
        ([id, { checked }]) =>
          checked &&
          checkableData.find(
            ({ processingObjectId }) => processingObjectId === id
          )
      ).length === checkableData.length,
    [checkableData, selectedResources]
  );

  const handleCheckAll = () => {
    if (isCheckAllDisabled) {
      return;
    }

    setSelectedResources((prev) => {
      return {
        ...unCheckDifferentMaterials(prev, checkableData[0].materialName),
        ...checkableData.reduce((rs: typeof selectedResources, it) => {
          rs[it.processingObjectId] = {
            weight: prev[it.processingObjectId]?.weight || it.remainingWeight,
            materialName: it.materialName,
            checked: !allChecked,
            hasError: allChecked
              ? false
              : Number(prev[it.processingObjectId]?.weight ?? 0) >
                Number(it.remainingWeight),
          };

          return rs;
        }, {}),
      };
    });
  };

  const handleSubmit = () => {
    onNext?.(
      Object.entries(selectedResources).reduce(
        (
          rs: PreRegisSelectedResource[],
          [id, { weight, checked, materialName }]
        ) => {
          if (
            !checked ||
            !displayResourceData.find(
              ({ processingObjectId }) => processingObjectId === id
            )
          ) {
            return rs;
          }

          return [
            ...rs,
            {
              processingObjectId: id,
              weight,
              materialName,
            },
          ];
        },
        []
      )
    );
  };

  return (
    <div className={mergeClasses(className, styles.preRegistResourceSelect)}>
      <div className={styles.title}>
        {t('pre_regist_resource_select.title')}
      </div>

      <div className={styles.form}>
        {withMobileMode && windowWidth <= LAYOUT_BREAK_POINTS.MD ? (
          <MobileResourceList
            handleCheckAll={handleCheckAll}
            filter={filter}
            setFilter={setFilter}
            resourceData={resourceData}
            siteOptions={siteOptions}
            materialNameOptions={materialNameOptions}
            operatorType={operatorType}
            displayResourceData={displayResourceData}
            selectedResources={selectedResources}
            isActionDisabled={isActionDisabled}
            handleCheckItem={handleCheckItem}
            handleChangeInput={handleChangeInput}
            isCheckAllDisabled={isCheckAllDisabled}
            allChecked={allChecked}
          />
        ) : (
          <ResourceTable
            handleCheckAll={handleCheckAll}
            filter={filter}
            setFilter={setFilter}
            resourceData={resourceData}
            siteOptions={siteOptions}
            materialNameOptions={materialNameOptions}
            operatorType={operatorType}
            displayResourceData={displayResourceData}
            selectedResources={selectedResources}
            isActionDisabled={isActionDisabled}
            handleCheckItem={handleCheckItem}
            handleChangeInput={handleChangeInput}
            isCheckAllDisabled={isCheckAllDisabled}
          />
        )}

        <BottomField>
          <div className={styles.bottom}>
            <div className={styles.bottomBtn}>
              <button
                className={styles.btnRequest}
                type="button"
                disabled={isSubmitButtonDisabled || actionDisabled}
                onClick={handleSubmit}
              >
                {t('pre_regist_resource_select.button_next')}
              </button>
            </div>
          </div>
        </BottomField>
      </div>
    </div>
  );
};

PreRegisResourceSelect.defaultProps = {
  withMobileMode: true,
};

export default PreRegisResourceSelect;
