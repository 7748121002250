// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import styles from './index.module.scss';
import { mergeClasses } from 'util/commons';

interface Props {
  id: string;
  className?: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  darkMode?: boolean;
  disabled?: boolean;
}

export const CustomCheckBox = ({
  id,
  checked,
  onChange,
  darkMode,
  disabled,
}: React.PropsWithChildren<Props>) => {
  return (
    <div
      className={mergeClasses(styles.customCheckBox, {
        [styles.dark]: !!darkMode,
        [styles.disabled]: disabled,
      })}
    >
      <input
        id={id}
        checked={checked}
        onChange={(e) => onChange(e)}
        disabled={disabled}
        type="checkbox"
      />
      <label htmlFor={id} />
    </div>
  );
};
