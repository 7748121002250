// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { objectFetch } from 'util/commons';
import CookiesAuth from 'util/CookiesAuth';
import { OperatorType, RoleType } from 'util/Enums';

const UnScopedActionTrigger: React.FC = () => {
  const user = useSelector(selectUser);

  const getAccessToken = () => {
    const accessToken = CookiesAuth.accessToken;

    if (!accessToken) {
      throw 'No access token found!';
    }

    return accessToken;
  };

  const triggerTeraokaUpload = () => {
    if (
      user.operatorType !== OperatorType.Emissions ||
      (user.role !== RoleType.AdminSite && user.role !== RoleType.UserNormal)
    ) {
      throw 'Upload teraoka csv error: No permission';
    }

    try {
      window?.uploadFileTeraokaCsv(getAccessToken());
    } catch (e) {
      console.error(e);
    }
  };

  const handleClick = async (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { currentTarget } = e;
    const actionTriggerKey = objectFetch(currentTarget, 'actionTriggerKey');
    const actionType = objectFetch(currentTarget, 'actionType');

    Object.assign(e.currentTarget, {
      actionType: undefined,
      actionTriggerKey: undefined,
    });

    if (actionTriggerKey !== process.env.REACT_APP_UNSCOPED_ACTION_KEY) {
      throw 'Wrong key, please check the unscoped action key value!';
    }

    switch (actionType) {
      case 'triggerTeraokaUpload':
        return triggerTeraokaUpload();

      // Add other actions here

      default:
        throw 'No action type found';
    }
  };

  return (
    <div
      onClick={handleClick}
      style={{ display: 'none' }}
      id="unscoped-action-trigger"
    />
  );
};

export default UnScopedActionTrigger;
