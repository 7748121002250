import { IconRemoveTag } from '../icons/IconRemoveTag';
import styles from './Tag.module.scss';
interface Props {
  label: React.ReactNode;
  onRemove: (e) => void;
}

const Tag: React.FC<Props> = ({ onRemove, label }) => {
  return (
    <div className={styles.tag}>
      <span>{label}</span>
      <div className={styles.tagIcon} onClick={onRemove}>
        <IconRemoveTag />
      </div>
    </div>
  );
};
export default Tag;
