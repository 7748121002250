// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLayoutEffect, useState } from 'react';
import _debounce from 'lodash/debounce';

export const useGetBoundingClientRect = (
  params: {
    el?: HTMLElement | null;
  },
  dependencies?: any[]
) => {
  const { el } = params;
  const [domRect, setDomRect] = useState<DOMRect>();

  useLayoutEffect(() => {
    const setSize = () => {
      const boundingClient = el?.getBoundingClientRect?.();

      if (boundingClient) {
        setDomRect(boundingClient);
      }
    };

    setSize();
    const handleSetSizeOnResize = _debounce(setSize, 200);

    window.addEventListener('resize', handleSetSizeOnResize);

    return () => {
      window.removeEventListener('resize', handleSetSizeOnResize);
    };
  }, [el, ...(dependencies ?? [])]);

  return {
    domRect,
  };
};
