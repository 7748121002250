// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import { Html5QrcodeScanner } from 'html5-qrcode';
import {
  Html5QrcodeResult,
  Html5QrcodeScanType,
  Html5QrcodeSupportedFormats,
  QrDimensions,
} from 'html5-qrcode/esm/core';
import { useEffect } from 'react';
import './Html5QrcodePlugin.scss';
import { Html5QrcodeScannerConfig } from 'html5-qrcode/esm/html5-qrcode-scanner';
import { mergeClasses } from 'util/commons';

type Props = {
  verbose?: boolean;
  qrCodeSuccessCallback: (
    decodedText: string,
    decodedResult: Html5QrcodeResult
  ) => void;
  qrCodeErrorCallback?: () => void;
  fps: number;
  disableFlip: boolean;
  qrbox?: number | QrDimensions;
  aspectRatio?: number;
  className?: string;
  formatToSupports?: Html5QrcodeSupportedFormats[];
  supportedScanTypes?: Html5QrcodeScanType[];
  qrCodeRegionId?: string;
};
const createConfig = (props: Html5QrcodeScannerConfig) => {
  const config = {} as Html5QrcodeScannerConfig;
  if (props.fps) {
    config.fps = props.fps;
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox;
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio;
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip;
  }

  config.aspectRatio = props.aspectRatio;
  config.formatsToSupport = props.formatsToSupport;
  config.supportedScanTypes = props.supportedScanTypes;

  return config;
};
const Html5QrcodePlugin: React.FC<Props> = (props) => {
  useEffect(() => {
    const config = createConfig(props);
    const verbose = props.verbose === true;
    const html5QrcodeScanner = new Html5QrcodeScanner(
      props.qrCodeRegionId ?? 'html5qr-code-full-region',
      config,
      verbose
    );
    html5QrcodeScanner.render(
      props.qrCodeSuccessCallback,
      props.qrCodeErrorCallback
    );

    return () => {
      html5QrcodeScanner.clear();
    };
  }, []);

  return (
    <div className={mergeClasses('wraper', props.className)}>
      <div id={props.qrCodeRegionId ?? 'html5qr-code-full-region'} />
    </div>
  );
};

export default Html5QrcodePlugin;
