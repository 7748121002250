// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { IconAdd } from 'components/atoms/icons/IconAdd';
import { IconSearch } from 'components/atoms/icons/IconSearch';
import { InputDataListField } from 'components/molecules/InputDataListField';
import { SelectField } from 'components/molecules/SelectField';
import AdminUserManagementTable from 'components/organisms/AdminUserManagementList';
import CreateUpdateAccountModal from 'components/organisms/CreateUpdateAccountModal';
import { AdminTemplate } from 'components/templates/AdminTemplate';
import { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { createAxios } from 'ts/createAxios';
import {
  Account,
  AccountForm,
  ModalAccountState,
  ResponseAccount,
  listOperatorType,
} from 'util/accountManagementTypes';
import { DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import { ModalType } from 'util/Enums';
import { handleError } from 'util/errorHandler';
import styles from './AdminUserListManagement.module.scss';
import _debounce from 'lodash/debounce';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import DeleteItemModal from 'components/molecules/DeleteItemForm/DeleteItemModal';
import { stringify } from 'querystring';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';

const AdminUserListManagement = () => {
  const user = useSelector(selectUser);
  const api = createAxios();
  const [isOpenModal, setOpenModal] = useState<ModalAccountState>({
    isOpen: false,
    type: ModalType.add,
  });
  const [listOfUser, setListOfUser] = useState<Account[]>([]);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [total, setTotal] = useState<number>(0);
  const [deleteItem, setDeleteItem] = useState<Account>();
  const { t } = useTranslation();
  const [filterObject, setFilterObject] = useState({
    inputSearch: '',
    operatorType: '',
  });
  // Call API get list of user
  const fetchUsers = async (
    values?: { inputSearch?: string; operatorType?: string },
    nextPage?: number,
    forceRefresh?: boolean
  ) => {
    try {
      const query = stringify({
        page: nextPage,
        take: PAGE_SIZE,
        keyword: values?.inputSearch || '',
        operatorType: values?.operatorType || '',
      });
      const data = await api.get<ResponseAccount>(`/users?${query}`);

      setListOfUser(
        forceRefresh ? data.data.users : [...listOfUser, ...data.data.users]
      );
      setTotal(data.data.total);
      setCurrentPage(data.data.page);
    } catch (error) {
      handleError(error);
    }
  };

  // Search debounce
  const debounceFn = useCallback(
    _debounce((callback) => callback(), 400),
    []
  );

  useEffect(() => {
    fetchUsers(filterObject, DEFAULT_PAGE, true);
  }, []);

  const handleCreateAccount = (form: AccountForm) => {
    api
      .post('/users', {
        id: form.id,
        name: form.name.trim(),
        password: form.password,
        operatorId: form.operatorId,
        operatorType: form.operatorType,
        type: form.role,
        siteId: form.siteId,
        tenantId: form.tenantId ? Number(form.tenantId) : null,
        truckId: form.truckId ? Number(form.truckId) : null,
        operatorPoolId: form.operatorPoolId,
        stationIds: form.stationIds,
      })
      .then(() => {
        fetchUsers(filterObject, undefined, true);
        toast.success(t('messages.M_010'));
        setOpenModal({
          isOpen: false,
          type: ModalType.add,
        });
      })
      .catch((e) => {
        handleError(e);
      });
  };
  const handleUpdateAccount = (form: AccountForm) => {
    api
      .put(`/users/${form.id}`, {
        id: form.id,
        name: form.name.trim(),
        operatorId: form.operatorId,
        operatorType: form.operatorType,
        type: form.role,
        siteId: form.siteId,
        ...(form.password && { password: form.password }),
        tenantId: form.tenantId ? Number(form.tenantId) : null,
        truckId: form.truckId ? Number(form.truckId) : null,
        operatorPoolId: form.operatorPoolId,
        stationIds: form.stationIds,
      })
      .then(() => {
        fetchUsers(filterObject, undefined, true);
        toast.success(t('messages.M_011'));
        setOpenModal({
          isOpen: false,
          type: ModalType.add,
        });
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const handleDeleteAccount = (id?: string | number) => {
    api
      .delete(`/users/${id}`)
      .then(() => {
        fetchUsers(filterObject, undefined, true);
        toast.success(t('messages.M_012'));
        setDeleteItem(undefined);
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const loadMore = () => {
    fetchUsers(filterObject, currentPage + 1, false);
  };
  return (
    <AdminTemplate
      h1={'ユーザーアカウントの管理'}
      active="ユーザー管理"
      breadcrumb={[
        {
          label: 'ユーザーアカウントの管理',
        },
      ]}
      className="admin-user-list"
    >
      <div className={styles.adminUserListManagement}>
        <div className={styles.countUser}>
          全<span>{total}</span>件
        </div>
        <div className={styles.tool}>
          <div className={styles.search}>
            <Form
              onSubmit={(values) => {
                setFilterObject({
                  inputSearch: values.inputSearch,
                  operatorType: values.operatorType,
                });
                fetchUsers(values, DEFAULT_PAGE, true);
              }}
              initialValues={{}}
              mutators={{
                changeField: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
            >
              {(props) => {
                const handleChangeOperatoId = () => {
                  props.form.submit();
                };
                const handleChange = () => {
                  debounceFn(() => props.form.submit());
                };
                return (
                  <form
                    onSubmit={props.handleSubmit}
                    className={styles.formInput}
                  >
                    <div className={styles.inputFrame}>
                      <div className={styles.inputSearch}>
                        <div className={styles.iconSearch}>
                          <IconSearch />
                        </div>

                        <InputDataListField
                          handleChange={handleChange}
                          placeholder={'検索'}
                          name="inputSearch"
                          required
                        />
                      </div>

                      <div className={styles.selectOperator}>
                        <SelectField
                          name={'operatorType'}
                          options={listOperatorType}
                          placeholder={'事業者タイプ'}
                          onChange={handleChangeOperatoId}
                        />
                      </div>
                    </div>

                    <div className={styles.line} />
                  </form>
                );
              }}
            </Form>
          </div>

          <div
            className={styles.buttonAdd}
            onClick={() => {
              setOpenModal({
                isOpen: true,
                type: ModalType.add,
              });
            }}
          >
            <IconAdd />
            <span>アカウントを追加する</span>
          </div>
        </div>

        <div className={styles.userTable}>
          <AdminUserManagementTable
            accountId={user.userId}
            userList={listOfUser}
            totalAccount={total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onClickEditAccount={(acc) => {
              setOpenModal({
                isOpen: true,
                type: ModalType.update,
                accountEditing: acc,
              });
            }}
            onClickDeleteAccount={setDeleteItem}
            renderUsersData={loadMore}
          />
        </div>
      </div>
      {isOpenModal.isOpen && (
        <CreateUpdateAccountModal
          createUpdateAccountModal={isOpenModal}
          onClose={() => {
            setOpenModal({
              isOpen: false,
              type: ModalType.add,
            });
          }}
          onCreateAccount={handleCreateAccount}
          onUpdateAccount={handleUpdateAccount}
        />
      )}
      {deleteItem && (
        <DeleteItemModal
          item={{ id: deleteItem.id, name: deleteItem.id }}
          onClose={() => setDeleteItem(undefined)}
          onHandleDelete={handleDeleteAccount}
        />
      )}
    </AdminTemplate>
  );
};

export default AdminUserListManagement;
