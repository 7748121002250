// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './CollectPhotoModal.module.scss';
import { Modal } from 'components/atoms/Modal';
import { IconClose } from 'components/atoms/icons/IconClose';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImagePath } from 'util/ConstantValues';
import { TCollectEmission } from 'util/Types';

export type CollectPhothoModalProps = {
  id?: string;
  isOpen: boolean;
  areaId: string;
  targetEmissions: TCollectEmission[];
  closeEditModal: () => void;
  onSubmit: (string) => Promise<void>;
  onClose: () => void;
  areaName?: string;
};

/**
 * 回収物の写真を撮影してアップロードするモーダル
 *
 * @param props
 * @returns
 */
export const CollectPhotoModal = (props: CollectPhothoModalProps) => {
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [photoDataUrls, setPhotoDataUrls] = useState<string[]>([]);
  const [isVideoAvailable, setIsVideoAvailable] = useState<boolean>(false);
  const [videoMessage, setVideoMessage] = useState<string>(
    t('collect_registration.photo_loading_message') || ''
  );
  const [isBusy, setIsBusy] = useState<boolean>(false);

  useEffect(() => {
    if (
      photoDataUrls.length === 0 &&
      navigator.mediaDevices &&
      typeof navigator.mediaDevices.getUserMedia === 'function'
    ) {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: {
              ideal: 'environment',
            },
          },
        })
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            setIsVideoAvailable(true);
          }
        })
        .catch(() => {
          setVideoMessage(t('collect_registration.photo_error_message') || '');
        });
    }
  }, [photoDataUrls.length]);

  const capturePicture = () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        const photoDataUrl = canvas.toDataURL('image/jpeg');
        setPhotoDataUrls([...photoDataUrls, photoDataUrl]); // Update the state with the most recent photo
      }
    }
  };

  return (
    <section className={styles.collectPhotoModal}>
      <Modal>
        <div className={styles.customTitle}>
          <div className={styles.title}>
            {t('collect_registration.thead_collect_photo_content')}
          </div>
          <div className={styles.top} onClick={props.closeEditModal}>
            <IconClose />
          </div>
        </div>
        {photoDataUrls.length > 0 ? (
          <div className={styles.body}>
            <div className={styles.videoContainer}>
              {photoDataUrls.map((photoDataUrl, index) => {
                return (
                  <img
                    key={index}
                    className={styles.previewImg}
                    src={photoDataUrl}
                  ></img>
                );
              })}
            </div>
            <p>{t('collect_registration.photo_check_message')}</p>
            <div className={styles.bottomButton}>
              <button
                className={styles.recaptureButton}
                type="button"
                disabled={isBusy}
                onClick={() => {
                  setPhotoDataUrls([]);
                }}
              >
                {t('collect_registration.button_to_recapture')}
              </button>
              <button
                className={styles.submitButton}
                type="submit"
                disabled={isBusy}
                onClick={async () => {
                  setIsBusy(true);
                  try {
                    await props.onSubmit(photoDataUrls[0]);
                  } catch (_) {
                    setIsBusy(false);
                    return;
                  }
                  props.onClose();
                }}
              >
                {t('collect_registration.button_to_recover')}
              </button>
            </div>
          </div>
        ) : (
          <div className={styles.body}>
            <div className={styles.videoContainer}>
              {!isVideoAvailable && (
                <img
                  loading="lazy"
                  className={styles.defaultImg}
                  src={ImagePath.defaultImage}
                />
              )}
              <video
                ref={videoRef}
                style={{ display: isVideoAvailable ? 'block' : 'none' }}
                autoPlay
                disablePictureInPicture
                disableRemotePlayback
                playsInline
              />
            </div>
            {isVideoAvailable ? (
              <p>{t('collect_registration.photo_warning_message')}</p>
            ) : (
              <p>{videoMessage}</p>
            )}
            <div className={styles.bottomButton}>
              <button
                className={styles.captureButton}
                type="submit"
                disabled={!isVideoAvailable}
                onClick={capturePicture}
              >
                {t('collect_registration.button_to_capture')}
              </button>
            </div>
          </div>
        )}
      </Modal>
    </section>
  );
};
