// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import CreateUpdateFloorTenantInputForm, {
  FloorInput,
} from 'components/molecules/CreateUpdateFloorTenantInputForm/CreateUpdateFloorTenantInputForm';
import { ModalFloorState } from 'pages/AdminSectionFloorManagement';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalType } from 'util/Enums';
import { SectionItem } from 'util/Types';
import styles from './CreateUpdateFloorTenantModal.module.scss';

interface Props {
  onClose: () => void;
  sectionList: SectionItem[];
  createUpdateFloorModal: ModalFloorState;
  onCreateFloorTenant: (createFloorForm: FloorInput) => void;
  onUpdateFloorTenant: (updateFloorForm: FloorInput, id: number) => void;
}

const CreateUpdateFloorTenantModal: React.FC<Props> = ({
  onClose,
  sectionList,
  createUpdateFloorModal,
  onCreateFloorTenant,
  onUpdateFloorTenant,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.createUpdateFloorTenantModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {createUpdateFloorModal.type === ModalType.add
                ? t('tenant.modal_create_floor.title')
                : t('tenant.modal_update_floor.title')}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <CreateUpdateFloorTenantInputForm
            createUpdateFloorModal={createUpdateFloorModal}
            sectionList={sectionList}
            onCreateFloorTenant={onCreateFloorTenant}
            onUpdateFloorTenant={onUpdateFloorTenant}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateUpdateFloorTenantModal;
