// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import './ScaleLoginTemplate.scss';

interface Props {
  children?: React.ReactNode;
}

export function ScaleLoginTemplate({ children }: Props) {
  return (
    <div>
      <div id="scale-login">{children}</div>
    </div>
  );
}
