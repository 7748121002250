// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { SelectField } from 'components/molecules/SelectField';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styles from './ProductRegistrationFormOrganism.module.scss';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { InputDataListField } from 'components/molecules/InputDataListField';
import {
  validateSelectField,
  validateRequiredField,
  validateMaxMinValue,
} from 'util/validator';
import ConfirmProductRegistrationModal from '../ConfirmProductRegistrationModal';
import { getDetailProducerSite } from 'apis/operator_site/siteApi';
import { createProductRegistration } from 'apis/site_purchase/purchase';
import {
  PreRegisSelectedResource,
  ResourcePreRegisFilter,
  SelectedResources,
} from 'util/Types';
import { REGEX_OBJECT } from 'util/ConstantValues';
import {
  ProducerSite,
  ProductRegistrationForm,
  PurchasesReceivesParams,
} from 'types/purchase';
import { baseProductSiteForm, initProducerSite } from 'util/constants/sites';
import { SelectFieldMultipleChoise } from 'components/molecules/SelectFieldMultipleChoise';
import { toast } from 'react-toastify';
import { addition } from 'util/Calc';
import { normalizeNumber } from 'util/commons';
import BigNumber from 'bignumber.js';
import { FormApi } from 'final-form';

type Props = {
  filterObj: PurchasesReceivesParams;
  preRegisSelectedResource: PreRegisSelectedResource[];
  setStep: (step: number) => void;
  getPurchasesResources: (operatorIdChange?: boolean) => void;
  productSiteForm: ProductRegistrationForm;
  setProductSiteForm: (values: ProductRegistrationForm) => void;
  resourceSelectProps?: {
    setSelectedResources: Dispatch<SetStateAction<SelectedResources>>;
    setFilter: Dispatch<SetStateAction<ResourcePreRegisFilter>>;
  };
};

const ProductRegistrationFormOrganism: React.FC<Props> = ({
  filterObj,
  preRegisSelectedResource,
  setStep,
  getPurchasesResources,
  productSiteForm,
  setProductSiteForm,
  resourceSelectProps,
}) => {
  const { t, i18n } = useTranslation();

  const [producerData, setProducerData] =
    useState<ProducerSite>(initProducerSite);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [isReset, setReset] = useState<boolean>(false);
  const [totalWeight, setTotalWeight] = useState<number>(0);
  const [prevProcessingObjectId, setPrevProcessingObjectId] =
    useState<string>();
  const [prevRegisWeight, setPrevRegisWeight] = useState<BigNumber>(
    new BigNumber(0)
  );

  const calcTotal = () => {
    const total =
      preRegisSelectedResource.reduce(
        (prev, data) => addition(prev, parseFloat(data.weight)),
        0
      ) || 0;
    setTotalWeight(total);
  };

  useEffect(() => {
    calcTotal();
  }, [setTotalWeight]);

  useEffect(() => {
    filterObj.siteId && handleGetProducerLatestReceives(filterObj.siteId);
  }, [filterObj.siteId]);

  const handleGetProducerLatestReceives = (siteId: string) => {
    getDetailProducerSite(siteId, (data) => {
      if (data) {
        setProducerData(data);
      }
    });
  };

  const resetFormData = (
    form: FormApi<ProductRegistrationForm, Partial<ProductRegistrationForm>>
  ) => {
    setReset(!isReset);
    form.reset();
    resourceSelectProps?.setSelectedResources({});
    resourceSelectProps?.setFilter({});
    setPrevProcessingObjectId(undefined);
    setPrevRegisWeight(new BigNumber(0));
    getPurchasesResources();
    setStep(1);
    setProductSiteForm(baseProductSiteForm);
  };

  const handleCreateProductRegistration = async (
    values: ProductRegistrationForm,
    form: FormApi<ProductRegistrationForm, Partial<ProductRegistrationForm>>,
    isSubmitContinue?: boolean
  ) => {
    if (!isConfirm) {
      setIsConfirm(true);

      return;
    }

    createProductRegistration(
      {
        ...values,
        prevProcessingObjectId: prevProcessingObjectId ?? undefined,
      },
      (res) => {
        toast.success(t('purchase_page.product_registration.message_success'));
        setIsConfirm(false);

        if (isSubmitContinue) {
          setPrevProcessingObjectId(res.id);
          setPrevRegisWeight((prev) => prev.plus(new BigNumber(values.weight)));
          form.mutators.setValue('weight', '');
          form.resetFieldState('weight');
        } else {
          resetFormData(form);
        }
      }
    );
  };

  const confirmContents = (values: ProductRegistrationForm) => [
    {
      label: t(
        'purchase_page.product_registration.modal_confirm.operator_name'
      ),
      contents: filterObj.operatorId || '',
    },
    {
      label: t('purchase_page.product_registration.modal_confirm.site_name'),
      contents: filterObj.siteId || '',
    },
    {
      label: t(
        'purchase_page.product_registration.modal_confirm.material_name'
      ),
      contents: values.materialName,
    },

    {
      label: t(
        'purchase_page.product_registration.modal_confirm.processing_method'
      ),
      contents: values.processingMethod,
    },
    {
      label: t(
        'purchase_page.product_registration.modal_confirm.packing_style'
      ),
      contents: values.packingStyle,
    },
    {
      label: t(
        'purchase_page.product_registration.modal_confirm.quality_tests'
      ),
      contents: values.qualityInspectionMethod.join(', '),
    },
    {
      label: t(
        'purchase_page.product_registration.modal_confirm.material_type'
      ),
      contents:
        producerData.enrollableType?.find(
          (item) => item.id === Number(values.materialTypeId)
        )?.name || '',
    },
    {
      label: t('purchase_page.product_registration.modal_confirm.quality'),
      contents: values.quantity || '',
    },
    {
      label: t('purchase_page.product_registration.modal_confirm.weight'),
      contents: `${normalizeNumber({
        value: values.weight,
        toFixed: false,
      })}kg`,
    },
    {
      label: t('purchase_page.product_registration.modal_confirm.product_name'),
      contents: values.name || '',
    },
    {
      label: t(
        'purchase_page.product_registration.modal_confirm.ratio_of_procurement_via_pool'
      ),
      contents: values?.procurementPercentage + '%',
    },
    {
      label: t('purchase_page.product_registration.modal_confirm.grade'),
      contents:
        producerData.enrollableGrade?.find(
          (item) => item.id === Number(values.gradeId)
        )?.name || '',
    },
  ];

  const handleBack = (values: ProductRegistrationForm) => {
    setProductSiteForm(values);
    setStep(1);
  };

  const initialForm = useMemo(
    () => ({
      ...productSiteForm,
      operatorId: filterObj.operatorId,
      siteId: filterObj.siteId,
      materialName: preRegisSelectedResource[0]?.materialName,
      inputResources: preRegisSelectedResource.map(
        ({ processingObjectId, weight }) => ({
          processingObjectId,
          weight,
        })
      ),
    }),
    [productSiteForm, filterObj, preRegisSelectedResource]
  );

  const handleSubmitContinue = (
    values: ProductRegistrationForm,
    form: FormApi<ProductRegistrationForm, Partial<ProductRegistrationForm>>
  ) => {
    handleCreateProductRegistration(values, form, true);
  };

  return (
    <div className={styles.productRegistrationForm}>
      {!isReset && (
        <Form<ProductRegistrationForm>
          initialValues={initialForm}
          onSubmit={(values, form) =>
            handleCreateProductRegistration(values, form)
          }
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          validate={(values) => ({
            materialName: validateRequiredField(
              values.materialName,
              t('purchase_page.product_registration.material_name') || ''
            ),
            processingMethod: validateSelectField(
              t('purchase_page.product_registration.processing_method'),
              values.processingMethod
            ),
            packingStyle: validateSelectField(
              t('purchase_page.product_registration.packing_style'),
              values.packingStyle
            ),
            qualityInspectionMethod: validateRequiredField(
              values.qualityInspectionMethod,
              t(
                'purchase_page.product_registration.modal_confirm.quality_tests'
              ) || ''
            ),
            materialTypeId: validateSelectField(
              t('purchase_page.product_registration.material_type'),
              values.materialTypeId
            ),
            quantity: validateRequiredField(
              values.quantity,
              t('purchase_page.product_registration.quality') || ''
            ),
            weight: validateRequiredField(
              values.weight,
              t('purchase_page.product_registration.weight') || ''
            ),
            name: validateRequiredField(
              values.name,
              t('purchase_page.product_registration.product_name') || ''
            ),
            procurementPercentage: validateRequiredField(
              values.procurementPercentage,
              t(
                'purchase_page.product_registration.ratio_of_procurement_via_pool'
              ) || ''
            ),
            gradeId: validateSelectField(
              t('purchase_page.product_registration.grade'),
              values.gradeId
            ),
          })}
        >
          {(props) => {
            return (
              <form
                onSubmit={(values) => {
                  props.handleSubmit(values);
                  setIsConfirm(false);
                }}
                className={styles.formInput}
              >
                <div className={styles.inputFrame}>
                  <p className={styles.content}>
                    {t('purchase_page.product_registration.title')}
                  </p>
                  <div className={styles.textFrame}>
                    <InputDataListField
                      label={t(
                        'purchase_page.product_registration.material_name'
                      )}
                      name="materialName"
                      placeholder={t(
                        'purchase_page.product_registration.material_name'
                      )}
                      type="text"
                      required
                      disabled
                      maxlength={50}
                      isDarkMode
                    />

                    <SelectField
                      label={t(
                        'purchase_page.product_registration.processing_method'
                      )}
                      name="processingMethod"
                      value={props.values.processingMethod}
                      options={producerData.producerMethod.map((i) => {
                        return { label: i, value: i };
                      })}
                      placeholder={t(
                        'purchase_page.product_registration.processing_method'
                      )}
                      showPlaceholderOption
                      onChange={() => null}
                      required
                      isDarkmode
                    />
                  </div>

                  <div className={styles.textFrame}>
                    <SelectField
                      label={t(
                        'purchase_page.product_registration.packing_style'
                      )}
                      name="packingStyle"
                      value={props.values.packingStyle}
                      placeholder={t(
                        'purchase_page.product_registration.packing_style'
                      )}
                      options={producerData.packingStyles.map((i) => {
                        return { label: i, value: i };
                      })}
                      showPlaceholderOption
                      required
                      isDarkmode
                    />

                    <SelectFieldMultipleChoise
                      name="qualityInspectionMethod"
                      label={
                        t('purchase_page.product_registration.quality_tests') ||
                        ''
                      }
                      selectedOptions={props.values.qualityInspectionMethod}
                      required
                      placeholder={
                        t('purchase_page.product_registration.quality_tests') ||
                        ''
                      }
                      options={producerData.qualityInspectionMethod.map((i) => {
                        return { id: i, name: i };
                      })}
                      onChange={(items) => {
                        props.form.mutators.setValue(
                          'qualityInspectionMethod',
                          items.map((item) => item.id)
                        );
                      }}
                      checkAllWithCheckBox
                      darkMode
                    />
                  </div>

                  <div className={styles.textFrame}>
                    <div className={styles.textQuantity}>
                      <InputDataListField
                        inputStyle={
                          i18n.language === 'en'
                            ? {
                                paddingRight: '56px',
                              }
                            : {}
                        }
                        label={t('purchase_page.product_registration.quality')}
                        name="quantity"
                        placeholder={t(
                          'purchase_page.product_registration.quality'
                        )}
                        type="text"
                        required
                        maxlength={10}
                        validRegex={REGEX_OBJECT.positiveIntegerRegex}
                        isDarkMode
                        endExtraText={t('purchase_page.resource.pieces')}
                      />
                    </div>

                    <InputDataListField
                      label={t('purchase_page.product_registration.weight')}
                      name="weight"
                      placeholder={t(
                        'purchase_page.product_registration.weight'
                      )}
                      type="text"
                      required
                      maxlength={10}
                      validRegex={REGEX_OBJECT.tenDigitsWith5Decimal}
                      isDarkMode
                      normalizeNumber
                      endExtraText={t('common.input_resources', {
                        weight: totalWeight,
                        unit: 'kg',
                      })}
                    />
                  </div>

                  <div className={styles.textFrame}>
                    <SelectField
                      label={t(
                        'purchase_page.product_registration.material_type'
                      )}
                      name="materialTypeId"
                      value={props.values.materialTypeId}
                      placeholder={t(
                        'purchase_page.product_registration.material_type'
                      )}
                      options={producerData.enrollableType.map((i) => {
                        return { label: i.name, value: i.id };
                      })}
                      showPlaceholderOption
                      onChange={() => null}
                      required
                      isDarkmode
                    />

                    <InputDataListField
                      label={t(
                        'purchase_page.product_registration.product_name'
                      )}
                      name="name"
                      placeholder={t(
                        'purchase_page.product_registration.product_name'
                      )}
                      type="text"
                      required
                      maxlength={50}
                      isDarkMode
                    />
                  </div>

                  <div className={styles.textFrame}>
                    <div className={styles.perField}>
                      <InputDataListField
                        validate={(value) =>
                          validateMaxMinValue({
                            value,
                            field: t(
                              'purchase_page.product_registration.ratio_of_procurement_via_pool'
                            ),
                            maxValue: 100,
                            checkRequired: true,
                          })
                        }
                        label={t(
                          'purchase_page.product_registration.ratio_of_procurement_via_pool'
                        )}
                        name="procurementPercentage"
                        placeholder={t(
                          'purchase_page.product_registration.ratio_of_procurement_via_pool'
                        )}
                        type="text"
                        required
                        validRegex={REGEX_OBJECT.tenDigitsWith5Decimal}
                        maxlength={10}
                        isDarkMode
                        normalizeNumber
                        endExtraText="%"
                      />
                    </div>

                    <SelectField
                      label={t('purchase_page.product_registration.grade')}
                      name="gradeId"
                      value={props.values.gradeId}
                      placeholder={t(
                        'purchase_page.product_registration.grade'
                      )}
                      options={producerData.enrollableGrade.map((i) => {
                        return { label: i.name, value: i.id };
                      })}
                      showPlaceholderOption
                      onChange={() => null}
                      required
                      isDarkmode
                    />
                  </div>
                </div>

                <div className={styles.submit}>
                  <button
                    className={styles.backButton}
                    type="button"
                    onClick={() => {
                      if (prevProcessingObjectId) {
                        resetFormData(props.form);
                      } else {
                        handleBack(props.values);
                      }
                    }}
                  >
                    {t('purchase_page.product_registration.button_back')}
                  </button>

                  <button
                    className={styles.submitButton}
                    disabled={!props.valid}
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      props.handleSubmit();
                    }}
                  >
                    {t('purchase_page.product_registration.button_confirm')}
                  </button>
                </div>

                <ConfirmProductRegistrationModal
                  title={t(
                    'purchase_page.product_registration.modal_confirm.heading'
                  )}
                  continueLabel={t('common.button.continue_to_register') || ''}
                  submitLabel={t('common.button.processing_completed') || ''}
                  isOpen={isConfirm}
                  closeEditModal={() => {
                    setIsConfirm(false);
                  }}
                  onSubmitContinue={() => {
                    handleSubmitContinue(props.values, props.form);
                  }}
                  onClick={props.handleSubmit}
                  confirmContents={confirmContents(props.values)}
                  alertContent={
                    new BigNumber(props.values.weight || '')
                      .plus(prevRegisWeight)
                      .comparedTo(totalWeight) > 0
                      ? {
                          title:
                            t(
                              'purchase_page.product_registration.modal_confirm.alert_title'
                            ) || '',
                          content:
                            t(
                              'purchase_page.product_registration.modal_confirm.alert_content'
                            ) || '',
                        }
                      : undefined
                  }
                />
              </form>
            );
          }}
        </Form>
      )}
    </div>
  );
};

export default ProductRegistrationFormOrganism;
