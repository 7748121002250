// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './Label.module.scss';

export type LabelProps = {
  id?: string;
  className?: string;
  text: string | JSX.Element;
  tag: 'div' | 'span' | 'p';
  onClick?: () => void;
  required?: boolean;
  circleNumber?: number;
};

export const Label = (props: LabelProps) => {
  switch (props.tag) {
    case 'div':
      return (
        <div
          id={props.id}
          className={`${props.className} ${styles.label}`}
          onClick={props.onClick}
        >
          {props.text}
          {props.circleNumber && (
            <div className={styles.circleNumber}>
              <span className={styles.text}>{props.circleNumber}</span>
            </div>
          )}
          {props.required && <span className={styles.required}>*</span>}
        </div>
      );
    case 'span':
      return (
        <span id={props.id} className={props.className} onClick={props.onClick}>
          {props.text}
        </span>
      );
    case 'p':
      return (
        <p id={props.id} className={props.className} onClick={props.onClick}>
          {props.text}
        </p>
      );
  }
};
