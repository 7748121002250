// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { InfoWindow, Marker } from '@react-google-maps/api';
import { ReactNode, useRef, useState } from 'react';
import {
  FILL_OPACITY,
  MAP_BUBBLE_FILLCOLOR,
  MAP_BUBBLE_STROKECOLOR,
} from 'util/ConstantValues';
import useGetDevicePlatform from 'util/hooks/useGetDevicePlatform';

type MapCircleProps = {
  id: number;
  center: google.maps.LatLngLiteral;
  radius: number;
  content?: ReactNode;
  zIndex?: number;
  touchedId?: number;
  onTouch?: (touchedId?: number) => void;
};

const MapCircle = ({
  id,
  center,
  radius,
  content,
  zIndex,
  touchedId,
  onTouch,
}: MapCircleProps) => {
  const [tooltip, setTooltip] = useState<boolean>(false);
  const infoWindowRef = useRef<InfoWindow>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const platform = useGetDevicePlatform();

  const handleOpenTooltip = () => {
    clearTimeout(timeoutRef.current);
    setTooltip(true);
  };

  const handleCLoseTooltip = () => {
    timeoutRef.current = setTimeout(() => {
      setTooltip(false);
    }, 200);
  };

  return (
    <>
      <Marker
        position={center}
        options={{
          optimized: true,
          icon: {
            scale: radius,
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: MAP_BUBBLE_FILLCOLOR,
            strokeColor: MAP_BUBBLE_STROKECOLOR,
            fillOpacity: FILL_OPACITY,
            strokeWeight: 1,
          },
        }}
        onMouseOver={platform !== 'mobile' ? handleOpenTooltip : undefined}
        onMouseOut={handleCLoseTooltip}
        onMouseDown={
          platform === 'mobile'
            ? () => onTouch?.(touchedId === id ? undefined : id)
            : undefined
        }
        zIndex={zIndex}
      />
      {(tooltip || touchedId === id) && content && (
        <InfoWindow
          position={new google.maps.LatLng(center)}
          options={{
            disableAutoPan: true,
          }}
          ref={infoWindowRef}
          onDomReady={() => {
            if (
              infoWindowRef.current?.containerElement?.parentElement
                ?.parentElement?.parentElement
            ) {
              infoWindowRef.current.containerElement.parentElement.parentElement
                .getElementsByClassName('gm-ui-hover-effect')[0]
                ?.remove();

              if (platform !== 'mobile') {
                infoWindowRef.current.containerElement.parentElement.parentElement.parentElement.onmouseenter =
                  handleOpenTooltip;

                infoWindowRef.current.containerElement.parentElement.parentElement.parentElement.onmouseleave =
                  handleCLoseTooltip;
              }
            }
          }}
        >
          {content}
        </InfoWindow>
      )}
    </>
  );
};

export default MapCircle;
