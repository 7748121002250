import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LanguageTypeForm,
  LanguageValue,
  languageTypeData,
} from 'util/ConstantValues';
import useOutsideClick from 'util/hooks/useClickOutside';
import IconDown from '../icons/IconDown';
import { IconLanguage } from '../icons/IconLanguage';
import './LanguageSelect.scss';

const LanguageSelect = () => {
  const { i18n } = useTranslation();
  const { ref } = useOutsideClick({
    onClickOutside: () => {
      setDropdownOpened(false);
    },
  });

  const langLocalStorage = useMemo(
    () => localStorage.getItem('i18nextLng') as LanguageValue,
    []
  );

  const [selectLanguage, setSelectLanguage] = useState<LanguageTypeForm>({
    language: LanguageValue.Japanese,
  });

  const [dropdownOpened, setDropdownOpened] = useState<boolean>(false);

  useEffect(() => {
    if (langLocalStorage) {
      setSelectLanguage({ language: langLocalStorage });
    } else {
      i18n.changeLanguage(LanguageValue.Japanese);
    }
  }, []);

  const onChangeLanguage = (lang: LanguageValue) => {
    i18n.changeLanguage(lang);
    setSelectLanguage({ language: lang });
    setDropdownOpened(false);
  };

  const onSelectLanguage = () => setDropdownOpened(!dropdownOpened);

  return (
    <>
      <div className="languages" onClick={onSelectLanguage}>
        <div className="icon-language">
          <IconLanguage />
        </div>
        <div className="text">Language</div>
        <div className="icon-down">
          <IconDown size="20" />
        </div>
      </div>
      {dropdownOpened && (
        <div className="dropdown-content" ref={ref}>
          {languageTypeData.map((lang) => (
            <div
              className={`dropdown-item ${
                selectLanguage.language === lang.value ? 'selected' : ''
              }`}
              key={lang.value}
              onClick={() => onChangeLanguage(lang.value)}
            >
              {lang.label}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default LanguageSelect;
