// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';

import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/atoms/Modal';
import { IconCheck } from 'components/atoms/icons/IconCheck';

interface Props {
  onSubmit: () => void;
}

const EmissionSuccessModal: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.emissionSuccessModal}>
      <Modal>
        <div className={styles.emissionSuccessModalContent}>
          <div className={styles.emissionSuccessModalContentIcon}>
            <IconCheck />
          </div>

          <p className={styles.emissionSuccessModalContentDescription}>
            {t('common.modal_success.save')}
          </p>
        </div>

        <div className={styles.emissionSuccessModalFooter}>
          <button
            type="submit"
            className={styles.emissionSuccessModalFooterSubmitButton}
            onClick={() => onSubmit()}
          >
            {t('common.button.return_pool_registration')}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default EmissionSuccessModal;
