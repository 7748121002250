// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { mergeClasses } from 'util/commons';
import styles from './index.module.scss';

interface Props {
  value?: boolean;
  setValue?: (value: boolean) => void;
  disabled?: boolean;
  content?: string;
  isDarkMode?: boolean;
}

const Toggle: React.FC<Props> = ({
  value,
  setValue,
  disabled,
  content,
  isDarkMode,
}) => {
  return (
    <>
      <div className={styles.toggle}>
        <label className={styles.switch}>
          <input
            type="checkbox"
            checked={value ?? false}
            className={styles.checkbox}
            onChange={() => {
              if (!disabled) {
                setValue?.(!value);
              }
            }}
          />
          <span
            className={mergeClasses(styles.slider, {
              [styles.disableToggle]: disabled ?? false,
              [styles.sliderDarkMode]: !!isDarkMode,
            })}
          />
        </label>
        {content && <div className={styles.content}>{content}</div>}
      </div>
    </>
  );
};
export default Toggle;
