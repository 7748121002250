// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import RecycleChainDesignAddSiteForm from 'components/organisms/RecycleChainDesignAddSiteForm';
import { RecycleChainDesignPut } from 'components/organisms/RecycleChainDesignDetailForm';
import { AdminTemplate } from 'components/templates/AdminTemplate';
import { useState } from 'react';
import { Path } from 'routes';

const AdminRecycleChainDesignEdit = () => {
  const [chainForm, setChainForm] = useState<RecycleChainDesignPut>();

  return (
    <AdminTemplate
      h1={'拠点の紐付け'}
      active="アカウント区分設定"
      breadcrumb={[
        {
          label: 'リサイクルチェーンリスト',
          href: Path.adminRecycleChainDesignManagement,
        },
        {
          label: 'リサイクルチェーン編集',
        },
      ]}
      className="recycle_chain_page"
    >
      <RecycleChainDesignAddSiteForm
        chainForm={chainForm}
        setChainForm={setChainForm}
        type="edit"
      />
    </AdminTemplate>
  );
};

export default AdminRecycleChainDesignEdit;
