// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e?: any) => void;
  size?: number;
}
export const IconDashboardFinalDisposal: React.FC<Props> = ({
  onClick,
  size,
}) => {
  return (
    <svg
      width={size || '48'}
      height={size || '48'}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && onClick}
    >
      <path
        d="M20.25 19.2778V37.6111M27.75 19.2778V37.6111M9 12.8889H39M37.125 12.8889L35.4994 39.8711C35.432 40.9924 35.0087 42.0418 34.3146 42.8079C33.6205 43.5741 32.7072 44 31.7588 44H16.2413C15.2928 44 14.3795 43.5741 13.6854 42.8079C12.9913 42.0418 12.568 40.9924 12.5006 39.8711L10.875 12.8889H37.125ZM29.625 12.8889V6.22222C29.625 5.63285 29.4275 5.06762 29.0758 4.65087C28.7242 4.23413 28.2473 4 27.75 4H20.25C19.7527 4 19.2758 4.23413 18.9242 4.65087C18.5725 5.06762 18.375 5.63285 18.375 6.22222V12.8889H29.625Z"
        stroke="#86EA5C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
