// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
/* eslint-disable @typescript-eslint/no-explicit-any */
import './CalendarField.scss';
import { useState, useRef, useEffect, useMemo, ChangeEvent } from 'react';
import { IconCalendar } from 'components/atoms/icons/IconCalendar';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import { getFormatDate } from 'ts/formatDate';
import IconCalendarTablet from 'components/atoms/icons/IconCalendarTablet';
import { mergeClasses } from 'util/commons';
import dayjs from 'dayjs';
import { REGEX_OBJECT } from 'util/ConstantValues';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export type Props = {
  id?: string;
  onChange?: (value) => void;
  placeholder?: string | null;
  minDate?: Date;
  maxDate?: Date;
  isDarkMode?: boolean;
  disabled?: boolean;
  value?: string;
  readOnly?: boolean;
  format?: string;
};

export function CalendarField({
  onChange,
  placeholder,
  minDate,
  maxDate,
  isDarkMode,
  disabled,
  value,
  readOnly,
  format = 'YYYY/MM/DD',
}: Props) {
  dayjs.extend(customParseFormat);

  const { t } = useTranslation();
  const inputEl = useRef<HTMLInputElement>(null);
  const [date, setDate] = useState<string>();
  const [dateFocus, setDateFocus] = useState<boolean>(false);
  const pressingKey = useRef<{
    key: string;
    code: string;
  }>({
    key: '',
    code: '',
  });
  const inputOldValue = useRef<string>('');
  const oldSelectionStart = useRef<number>(0);

  const handleClick = () => {
    inputEl.current?.focus();
  };

  const { mask, formatOriginalLength } = useMemo(
    () => ({
      mask: format.replaceAll(/\w/g, '_'),
      formatOriginalLength: format.replaceAll(/_|\//g, '').length,
    }),
    [format]
  );

  useEffect(() => {
    if (value) {
      setDate(value);
    }
  }, [value]);

  const formatInputValue = (val?: string) => {
    if (!val) {
      return '';
    }

    const characters = Array.from(val.replaceAll(/_|\//g, ''));
    let index = 0;

    return mask.replace(/_/g, (str) => characters[index++] ?? str);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!inputEl.current) {
      return;
    }

    const {
      target,
      target: { value, selectionStart },
    } = e;
    const clearedValue = value.replaceAll(/_|\//g, '');

    if (
      REGEX_OBJECT.number.test(clearedValue) &&
      pressingKey.current?.key === 'Process' &&
      (isFinite(Number(pressingKey.current.code.split('Numpad')[1])) ||
        isFinite(Number(pressingKey.current.code.split('Digit')[1])))
    ) {
      e.preventDefault();
      e.stopPropagation();

      return;
    }

    if (
      (clearedValue && !REGEX_OBJECT.number.test(clearedValue)) ||
      clearedValue.length > formatOriginalLength ||
      (value.length < (inputOldValue.current?.length || 0) &&
        pressingKey.current?.key !== 'Backspace' &&
        pressingKey.current?.key !== 'Delete')
    ) {
      inputEl.current.value = inputOldValue.current;

      target.setSelectionRange(
        oldSelectionStart.current,
        oldSelectionStart.current
      );

      return;
    }

    const convertedInputVal = formatInputValue(clearedValue);
    inputEl.current.value = convertedInputVal;

    if (clearedValue.length === formatOriginalLength) {
      const isDateDataValid = dayjs(convertedInputVal, format, true).isValid();

      if (isDateDataValid) {
        setDate(convertedInputVal);
        onChange?.(convertedInputVal);
      } else {
        toast.error(t('messages.M_invalid_date'));
      }
    } else if (!clearedValue?.length) {
      setDate(undefined);
      onChange?.(undefined);
    }

    const newCursorPosition = selectionStart || 0;
    target.setSelectionRange(newCursorPosition, newCursorPosition);
    oldSelectionStart.current = newCursorPosition;

    inputOldValue.current = convertedInputVal;
  };

  const handleKeyDown = ({
    key,
    code,
    target,
  }: React.KeyboardEvent<HTMLInputElement>) => {
    pressingKey.current.key = key;
    pressingKey.current.code = code;
    const { selectionStart } = target as any;
    oldSelectionStart.current = selectionStart;

    if (
      key === 'Backspace' &&
      inputEl.current?.value?.[selectionStart - 1] === '/'
    ) {
      (target as any).setSelectionRange(selectionStart - 1, selectionStart - 1);
      oldSelectionStart.current = selectionStart - 1;

      return;
    }

    if (
      (key === 'Delete' || REGEX_OBJECT.number.test(key)) &&
      inputEl.current?.value?.[selectionStart] === '/'
    ) {
      (target as any).setSelectionRange(selectionStart + 1, selectionStart + 1);
      oldSelectionStart.current = selectionStart + 1;
    }
  };

  return (
    <>
      <div className="select-date">
        <input
          ref={inputEl}
          name="date"
          type="text"
          placeholder={placeholder || '日付'}
          defaultValue={date}
          onChange={handleChangeInput}
          onFocus={() => {
            setDateFocus(true);
          }}
          autoComplete="off"
          disabled={disabled}
          readOnly={readOnly}
          onKeyDown={handleKeyDown}
        />
        <div
          className={mergeClasses('calender-datepick', {
            darkMode: isDarkMode,
          })}
        >
          <Calendar
            value={date ? dayjs(date, format, true).toDate() : undefined}
            calendarType="US"
            locale={localStorage.getItem('i18nextLng') || 'ja'}
            allowPartialRange={true}
            prev2Label={null}
            next2Label={null}
            prevLabel={'◀'}
            nextLabel={'▶'}
            formatDay={(_, date) => getFormatDate(date, 'D')}
            className={'calender' + (dateFocus ? ' active' : '')}
            minDate={minDate}
            maxDate={maxDate}
            onClickDay={(value) => {
              setDate(getFormatDate(value, format));

              if (inputEl.current) {
                inputEl.current.value = getFormatDate(value, format);
              }

              setDateFocus(false);
              if (onChange) {
                onChange(getFormatDate(value, format));
              }
            }}
          />
        </div>
        <div
          className={mergeClasses('icon', {
            iconDarkMode: !!isDarkMode && !disabled,
            iconDisable: !!disabled && !!isDarkMode,
          })}
        >
          {isDarkMode ? (
            <IconCalendarTablet onClick={handleClick} />
          ) : (
            <IconCalendar onClick={handleClick} />
          )}
        </div>
      </div>
      <div
        className={'over-ray' + (dateFocus ? ' active' : '')}
        onClick={() => {
          setDateFocus(false);
        }}
      />
    </>
  );
}
