// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useState, useEffect } from 'react';

const useGetDevicePlatform = () => {
  const [deviceType, setDeviceType] = useState<'mobile' | 'desktop'>();

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(
      userAgent
    );

    if (isMobile) {
      setDeviceType('mobile');
    } else {
      setDeviceType('desktop');
    }
  }, []);

  return deviceType;
};

export default useGetDevicePlatform;
