// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import {
  useCreateCollectBySiteIdMutation,
  useFetchCollectAreaQuery,
  useFetchCollectByIdQuery,
} from 'apis/collect_user/collectUserApi';
import { SelectField, SelectOption } from 'components/molecules/SelectField';
import { CollectEmissions } from 'components/organisms/CollectEmissions';
import CollectEmissionsSelectTruckModal from 'components/organisms/CollectEmissionsSelectTruckModal';
import { WeightNoticeModal } from 'components/organisms/WeightNoticeModal';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { unionBy } from 'lodash';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { addition } from 'util/Calc';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Path } from 'routes';
import { TSelectTruckModal } from 'types/Collect';
import styles from './CollectRegist.module.scss';
import { CollectPhotoModal } from 'components/molecules/CollectPhotoModal';
import Popover from 'components/atoms/Popover';
import { mergeClasses } from 'util/commons';
import { Form } from 'react-final-form';
import { isError } from 'util/errorHandler';
import { JWNET_MAXIMUM_WEIGHT } from 'util/ConstantValues';
import _debounce from 'lodash/debounce';
import { normalizeNumber } from 'util/commons';

function Collects() {
  const { t } = useTranslation();
  const params = useParams();
  const { push } = useHistory();

  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<number>();
  const [truckModal, setTruckModal] = useState<boolean>(false);
  const [siteSelected, setSiteSelected] = useState<SelectOption>({
    label: '',
    value: '',
  });
  const [error, setError] = useState<string>('');
  const [isWeightNoticeModalOpen, setIsWeightNoticeModalOpen] =
    useState<boolean>(false);
  const [isCollectPhotoModalOpen, setIsCollectPhotoModalOpen] =
    useState<boolean>(false);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [bottomHeight, setBottomHeight] = useState<number>();

  const { data: dataEmissions } = useFetchCollectByIdQuery(params['id'], {
    skip: !params['id'],
  });

  const { data: areas } = useFetchCollectAreaQuery();

  const [fnCreate] = useCreateCollectBySiteIdMutation();

  const selectOptions = useMemo(() => {
    const records = unionBy(dataEmissions, 'wasteUnit.id');

    if (
      records.length &&
      (selectedFilter == null ||
        !records.some((e) => e.wasteUnit.id === Number(selectedFilter)))
    ) {
      setSelectedFilter(records[0].wasteUnit.id);
    }

    return records.map(({ wasteUnit }) => ({
      label: wasteUnit.name,
      value: wasteUnit.id,
    }));
  }, [dataEmissions]);

  const emissions = useMemo(() => {
    if (selectedFilter) {
      setCheckedIds([]);

      return dataEmissions?.filter(
        (x) => Number(x.wasteUnit.id) === Number(selectedFilter)
      );
    }

    return dataEmissions;
  }, [dataEmissions, selectedFilter]);

  const closeWeightNoticeModal = () => {
    setIsWeightNoticeModalOpen(false);
  };

  const closeCollectPhotoModal = () => {
    setIsCollectPhotoModalOpen(false);
  };

  const areaName = useMemo(() => {
    const record = areas?.filter((area) => area.areaId === params['id'])[0];
    if (record) return record.areaName;
  }, [areas]);

  const totalWeight = useMemo(
    () =>
      emissions
        ?.filter((emission) => checkedIds.includes(emission.id))
        .reduce(
          (prev, emission) => addition(prev, parseFloat(emission.weight)),
          0
        ),
    [checkedIds]
  );

  const isJWNETTotalWeightExceeded = useMemo(
    () =>
      emissions?.[0]?.wasteUnit.isEdiEnabled &&
      (totalWeight ?? 0) > JWNET_MAXIMUM_WEIGHT,
    [emissions, totalWeight]
  );

  useLayoutEffect(() => {
    const init = () => {
      if (bottomRef.current) {
        setBottomHeight(bottomRef.current.clientHeight);
      }
    };
    init();
    const initOnResize = _debounce(init, 200);
    window.addEventListener('resize', initOnResize);

    return () => {
      window.removeEventListener('resize', initOnResize);
    };
  }, [isJWNETTotalWeightExceeded, t]);

  const closeTruckModal = () => setTruckModal(false);

  const onSubmit = async (
    imageData?: string,
    truckValues?: TSelectTruckModal
  ) => {
    if (checkedIds.length > 0) {
      if (error) {
        toast.error(error);
      } else {
        const data: {
          emissionIds: string[];
          imageData?: string;
          truckId?: number;
        } = {
          emissionIds: checkedIds,
        };

        if (imageData) {
          data.imageData = imageData;
        }

        if (truckValues) {
          data.truckId = Number(truckValues.truckId);
        }

        fnCreate({
          id: params['id'],
          data,
        })
          .then(function (res) {
            if (isError(res)) return;
            // checkAll
            if (checkedIds.length === emissions?.length) {
              push(Path.collects);
            }
            // 初期化
            toast.success(t('messages.M_087'));
            setCheckedIds([]);
          })
          .catch(function (e) {
            alert(
              e?.response?.data?.message
                ? e.response.data.message
                : t('collect_registration.submit_api_error')
            );
          });
      }
    } else {
      alert(t('collect_registration.submit_validate_error'));
    }
  };

  const onTruckSubmit = async (values: TSelectTruckModal) => {
    setTruckModal(false);
    await onSubmit(undefined, values);
  };

  const onPreSubmit = async () => {
    const firstRecord = emissions?.[0];
    const isEdiEnabled = Boolean(firstRecord?.wasteUnit?.isEdiEnabled);

    if (isEdiEnabled) {
      setSiteSelected({
        label: firstRecord?.collectionSiteName ?? '',
        value: firstRecord?.collectionSiteId ?? '',
      });
      setTruckModal(true);
    } else {
      await onSubmit();
    }
  };

  return (
    <PrimaryTemplate
      h1={t('collect_registration.title')}
      prevFunction={() => push(Path.collects)}
      className={styles.containerCollectRegistration}
    >
      <div>
        <div className={styles.areaName}>
          <div
            className={mergeClasses(styles.areaNameTitle, {
              [styles.areaNameTitleFull]: !selectOptions?.length,
            })}
          >
            <Popover
              positions={['top', 'bottom', 'right', 'left']}
              containerStyle={{
                zIndex: '100000',
              }}
              autoGen={{
                wrapperStyle: {
                  fontWeight: 'bold',
                  paddingLeft: 16,
                  paddingRight: 8,
                },
              }}
            >
              <>{areaName}</>
            </Popover>
          </div>
          {selectOptions?.length > 0 && (
            <div className={styles.filter}>
              <Form onSubmit={() => {}}>
                {() => (
                  <SelectField
                    value={selectedFilter?.toString()}
                    name="materialName"
                    options={selectOptions}
                    isDarkmode
                    onChange={(value) => setSelectedFilter(Number(value))}
                  />
                )}
              </Form>
            </div>
          )}
        </div>
        <CollectEmissions
          emissions={emissions || []}
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
          setError={setError}
          tableStyle={{
            height: `calc(100vh - 116px - ${bottomHeight}px)`,
          }}
        />
      </div>

      <div ref={bottomRef}>
        <div className={styles.totalWrapper}>
          <div className={styles.total}>
            <div>{t('collect_registration.label_total')}</div>
            <div>
              <span>
                {t('collect_registration.total_weight', {
                  weight: normalizeNumber({
                    value: totalWeight,
                  }),
                })}
              </span>
            </div>
          </div>

          {isJWNETTotalWeightExceeded && (
            <div className={styles.error}>
              {t('collect_registration.jwnet_weight_exceeded_error', {
                value: JWNET_MAXIMUM_WEIGHT,
              })}
            </div>
          )}
        </div>
        <div className={styles.bottomBtn}>
          <button
            className={styles.btnEditing}
            type="button"
            disabled={checkedIds.length === 0}
            onClick={() => {
              setIsWeightNoticeModalOpen(true);
            }}
          >
            {t('collect_registration.button_notify_anomalies')}
          </button>

          <button
            className={styles.btnRequest}
            disabled={checkedIds.length === 0 || isJWNETTotalWeightExceeded}
            onClick={onPreSubmit}
          >
            {t('collect_registration.button_to_recover')}
          </button>

          <button
            className={styles.btnRequest}
            disabled={checkedIds.length === 0}
            onClick={() => {
              setIsCollectPhotoModalOpen(true);
            }}
          >
            {t('collect_registration.button_to_collect_photo')}
          </button>
        </div>
      </div>

      {isWeightNoticeModalOpen && (
        <WeightNoticeModal
          isOpen={isWeightNoticeModalOpen}
          closeEditModal={closeWeightNoticeModal}
          targetIds={checkedIds}
          areaId={params['id']}
          areaName={areaName}
        />
      )}
      {truckModal && siteSelected && (
        <CollectEmissionsSelectTruckModal
          closeModal={closeTruckModal}
          onSubmit={onTruckSubmit}
          isOpen={truckModal}
          site={siteSelected}
        />
      )}

      {isCollectPhotoModalOpen && (
        <CollectPhotoModal
          isOpen={isCollectPhotoModalOpen}
          closeEditModal={closeCollectPhotoModal}
          onSubmit={onSubmit}
          onClose={closeCollectPhotoModal}
          targetEmissions={
            emissions?.filter((emission) => checkedIds.includes(emission.id)) ??
            []
          }
          areaId={params['id']}
          areaName={areaName}
        />
      )}
    </PrimaryTemplate>
  );
}

export default Collects;
