import { Modal } from 'components/atoms/Modal';
import IconWarning from 'components/atoms/icons/IconWarning';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

type Props = {
  onClose: VoidFunction;
  content: string;
};

const ErrorModal = ({ onClose, content }: Props) => {
  const { t } = useTranslation();

  const handleClick = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div className={styles.booking}>
      <Modal>
        <div className={styles.notification}>
          <IconWarning />
          <div className={styles.description}>{content}</div>
          <button className={styles.button} onClick={handleClick}>
            {t('common.return')}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ErrorModal;
