// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { IconPhoto } from 'components/atoms/icons/IconPhoto';
import { IconClose } from 'components/atoms/icons/IconClose';
import { MAX_IMAGE_FILE_SIZE } from 'util/ConstantValues';
import { useTranslation } from 'react-i18next';
import { IconPDF } from 'components/atoms/icons/IconPDF';
import { validateFiles } from 'util/validator';

interface Props {
  onChangePhoto: (e?: string | ArrayBuffer | null) => void;
  fileTypes: string[];
  accept?: string;
  content?: string | null;
  label?: string;
  defaultFile?: string;
  isImage?: boolean;
  exFile?: {
    file: File | undefined;
    setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  };
  onError?: (isError: boolean) => void;
}

export function getBase64(file, callback) {
  const reader = new FileReader();

  reader.addEventListener('load', () => callback(reader.result));

  reader.readAsDataURL(file);
}

const UploadFiles = ({
  onChangePhoto,
  defaultFile,
  fileTypes,
  accept,
  content,
  isImage,
  label,
  exFile,
  onError,
}: Props) => {
  let [currentFile, setCurrentFile] = React.useState<File>();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [error, setError] = React.useState('');
  const [defaultName, setDefaultName] = useState('');
  const { t } = useTranslation();

  if (exFile) {
    currentFile = exFile.file;
    setCurrentFile = exFile.setFile;
  }

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      setCurrentFile(undefined);
      return;
    }

    const msgError = validateFiles({
      files: e.target.files,
      maxSize: MAX_IMAGE_FILE_SIZE,
      fileTypes,
      errorMessages: {
        wrongType: isImage ? t('messages.M_017') : t('messages.M_126'),
        sizeExceeded: t('messages.M_018', {
          value: `${MAX_IMAGE_FILE_SIZE}MB`,
        }),
      },
    });

    if (msgError) {
      setError(msgError);
      onError?.(true);
      setCurrentFile(undefined);
      return;
    }
    setError('');
    onError?.(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getBase64(e.target.files[0], (result: any) => {
      setCurrentFile(e.target.files?.[0]);
      onChangePhoto(result);
    });
  };

  useEffect(() => {
    defaultFile && setDefaultName(defaultFile);
  }, [defaultFile]);

  const renderFileName = () => {
    if (currentFile) {
      return currentFile.name;
    }

    if (isImage || !currentFile) {
      return defaultName;
    }

    return '';
  };

  const renderIcon = () => {
    if (currentFile || defaultName) {
      if (isImage) {
        return (
          <img
            className={styles.photo}
            src={
              currentFile
                ? URL.createObjectURL(currentFile)
                : `${process.env.REACT_APP_CLOUDFRONT}${defaultName}`
            }
          />
        );
      }

      if (!isImage) {
        return <IconPDF />;
      }
    }
    return isImage ? <IconPhoto /> : <IconPDF />;
  };

  return (
    <div className={styles.uploadFile}>
      {label && <p>{label}</p>}
      <div className={`${styles.content} selection-none`}>
        {(currentFile || defaultName) && (
          <div>
            <div className={styles.preview}>
              <div className={styles.pickerFile}>
                <div className={styles.photo}>{renderIcon()}</div>

                <p>{renderFileName()}</p>
              </div>
              <div
                onClick={() => {
                  if (inputRef && inputRef.current?.value) {
                    inputRef.current.value = '';
                    onChangePhoto(undefined);
                  }
                  setCurrentFile(undefined);
                  setDefaultName('');

                  defaultName && onChangePhoto(undefined);
                }}
                className={styles.closeButton}
              >
                <IconClose />
              </div>
            </div>
            <div className={styles.borderBt} />
          </div>
        )}
        <div className={`${styles.picker}`}>
          <div className={styles.contentLeft}>
            {!currentFile && !defaultName && isImage && (
              <div className={styles.photo}>
                {isImage ? <IconPhoto /> : <IconPDF />}
              </div>
            )}
            <p className={styles.textColorCommon}>
              {isImage ? t('common.upload_image') : content}
            </p>
          </div>
          <div>
            <label htmlFor="file-upload" className={styles.customFileUpload}>
              {t('common.button.upload')}
            </label>
            <input
              ref={inputRef}
              id="file-upload"
              type="file"
              accept={isImage ? '.jpg, .jpeg, .png, .heic' : accept}
              onChange={onSelectFile}
            />
          </div>
        </div>
      </div>
      {error && (
        <div className={styles.error}>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default UploadFiles;
