// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

const IconAddCircleOutline20 = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="7.5" stroke="#4D4D4D" strokeWidth="2" />
      <path
        d="M10 6.25V13.75"
        stroke="#4D4D4D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6.25 10H13.75"
        stroke="#4D4D4D"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconAddCircleOutline20;
