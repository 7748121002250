// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import {
  getDashboardsTenantsCompareRecycleRate,
  getDashboardsTenantsCompareTotalEmissionWeight,
  getDashboardsTenantsRateCO2WasteUnits,
  getDashboardsTenantsRateRecycleMethods,
  getDashboardsTenantsRateWasteCosts,
  getDashboardsTenantsRateWasteUnits,
  getDashboardsTenantsWastes,
} from 'apis/tenants';
import { TooltipItem, TooltipModel } from 'chart.js';
import TenantStatistics, {
  TenantStatisticFilter,
} from 'components/organisms/TenantStatistics';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import _snakeCase from 'lodash/snakeCase';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { selectUser } from 'redux/slices/userSlice';
import { createAxios } from 'ts/createAxios';
import { DateTypeRange } from 'util/Enums';
import {
  DashboardsTenantsCompareRecycle,
  DashboardsTenantsCompareTotalEmissionWeight,
  DashboardsTenantsRateCO2WasteUnits,
  DashboardsTenantsRateRecycleMethods,
  DashboardsTenantsRateWasteCosts,
  DashboardsTenantsRateWasteUnits,
  DashboardsTenantsWastes,
} from 'util/Types';
import {
  convertWeight,
  mergeClasses,
  normalizeNumber,
  uniqRandomNumber,
} from 'util/commons';
import { UnitType } from 'util/constants/dashboard';
import Csv from 'util/csv';
import { API_ENDPOINTS } from 'util/endPoints';
import { handleError } from 'util/errorHandler';
import styles from './TenantDashboard.module.scss';

const PRINT_WIDTH = 1123;

const TenantDashboard: React.FC = () => {
  const { t, i18n } = useTranslation();
  const user = useSelector(selectUser);
  const history = useHistory();
  const { tenantId } = useParams<{ tenantId: string }>();
  const dashboardPrintRef = useRef<HTMLDivElement>(null);

  const initialFilter = useMemo(() => {
    return {
      dateFrom: dayjs().startOf('month').format('YYYY/M/DD'),
      dateTo: dayjs().format('YYYY/M/DD'),
    };
  }, []);
  const [filter, setFilter] = useState<TenantStatisticFilter>(initialFilter);

  const [tenantWasteChangesType, setTenantWasteChangesType] =
    useState<DateTypeRange>(DateTypeRange.MONTH);
  const [tenantsRateWasteUnit, setTenantsRateWasteUnit] =
    useState<DashboardsTenantsRateWasteUnits>();
  const [initialTenantRateWasteUnit, setInitialTenantRateWasteUnit] =
    useState<DashboardsTenantsRateWasteUnits>();

  const [tenantsRateRecycleMethod, setTenantsRateRecycleMethod] =
    useState<DashboardsTenantsRateRecycleMethods>();
  const [tenantsRateWasteCost, setTenantsRateWasteCost] =
    useState<DashboardsTenantsRateWasteCosts>();
  const [tenantsWastes, setTenantsWastes] = useState<DashboardsTenantsWastes[]>(
    []
  );
  const [
    tenantsCompareTotalEmissionWeight,
    setTenantsCompareTotalEmissionWeight,
  ] = useState<DashboardsTenantsCompareTotalEmissionWeight>();
  const [tenantsCompareRecycleRate, setTenantsCompareRecycleRate] =
    useState<DashboardsTenantsCompareRecycle>();
  const [analyticFetchTrigger, setAnalyticFetchTrigger] = useState<number>();
  const [tenantsRateCO2WasteUnits, setTenantsRateCO2WasteUnits] =
    useState<DashboardsTenantsRateCO2WasteUnits>();
  const [tenantName, setTenantName] = useState<string | undefined>('');

  useEffect(() => {
    if (tenantId) {
      createAxios()
        .get(API_ENDPOINTS.TENANTS, {
          params: {
            siteId: user.siteId,
          },
        })
        .then((response) => {
          setTenantName(
            response.data.tenants?.find(({ id }) => id?.toString() === tenantId)
              ?.name || ''
          );
        })
        .catch((error) => handleError(error));
    } else {
      setTenantName(undefined);
    }
  }, [tenantId]);

  useEffect(() => {
    fetchDashboardsTenantsRateWasteUnits();
    fetchDashboardsTenantsRateRecycleMethods();
    fetchDashboardsTenantsRateWasteCosts();
    fetchDashboardsTenantsRateCO2WasteUnits();
  }, [filter, analyticFetchTrigger]);

  useEffect(() => {
    fetchDashboardsTenantsCompareTotalEmissionWeights();
    fetchDashboardsTenantsCompareRecycleRate();
  }, []);

  useEffect(() => {
    fetchDashboardsTenantsWastes(tenantWasteChangesType);
  }, [tenantWasteChangesType]);

  const fetchDashboardsTenantsRateWasteUnits = () => {
    getDashboardsTenantsRateWasteUnits({
      params: {
        ...filter,
        tenantId,
      },
      successCallback: (res) => {
        setTenantsRateWasteUnit(res);

        if (!initialTenantRateWasteUnit) {
          setInitialTenantRateWasteUnit(res);
        }
      },
      history,
    });
  };

  const fetchDashboardsTenantsRateRecycleMethods = () => {
    getDashboardsTenantsRateRecycleMethods({
      params: {
        ...filter,
        tenantId,
      },
      successCallback: (res) => {
        setTenantsRateRecycleMethod(res);
      },
      history,
    });
  };

  const fetchDashboardsTenantsRateWasteCosts = () => {
    getDashboardsTenantsRateWasteCosts({
      params: {
        ...filter,
        tenantId,
      },
      successCallback: (res) => {
        setTenantsRateWasteCost(res);
      },
      history,
    });
  };

  const handleFilterData = (filter: TenantStatisticFilter) => {
    setFilter(filter);
    setAnalyticFetchTrigger(uniqRandomNumber());
  };

  const csvHeadNote = `${dayjs(filter.dateFrom).format('YYYY/MM/DD')} - ${dayjs(
    filter.dateTo
  ).format('YYYY/MM/DD')}`;
  const downloadTimeLabel = `${dayjs(filter.dateFrom).format(
    'YYYYMMDD'
  )} - ${dayjs(filter.dateTo).format('YYYYMMDD')}`;

  const handleDownloadWasteRateCsv = () => {
    new Csv({
      headers: [
        ['wasteUnitName', t('tenant_dashboard.item')],
        ['weight', t('tenant_dashboard.waste_volume')],
        ['rate', t('tenant_dashboard.weight_percentage')],
      ],
      headNote: csvHeadNote,
      data:
        tenantsRateWasteUnit?.rateByWasteUnits
          .sort((a, b) => Number(b.weight) - Number(a.weight))
          .map((item) => ({
            wasteUnitName: item.wasteUnitName,
            weight: normalizeNumber({ value: item.weight, toFixed: false }),
            rate: item.rate,
          })) ?? [],
    }).downLoadCsv({
      filename: `${user.tenantName}_${t(
        'tenant_dashboard.rate_of_waste_weight'
      )}_${downloadTimeLabel}`,
    });
  };

  const handleDownloadRecycleRateCsv = () => {
    new Csv({
      headers: [
        ['wasteUnitName', t('tenant_dashboard.processing_method')],
        ['weight', t('tenant_dashboard.waste_volume')],
        ['rate', t('tenant_dashboard.weight_percentage')],
      ],
      headNote: csvHeadNote,
      data:
        tenantsRateRecycleMethod?.recycleMethods.map((item) => ({
          wasteUnitName:
            i18n.language === 'ja' ? item.name : item.englishName || item.name,
          weight: normalizeNumber({ value: item.weight, toFixed: false }),
          rate: item.rate,
        })) ?? [],
    }).downLoadCsv({
      filename: `${user.tenantName}_${t(
        'tenant_dashboard.recycle_rate'
      )}_${downloadTimeLabel}`,
    });
  };

  const handleDownloadCirculationAndProcessing = () => {
    new Csv({
      headers: [
        ['wasteUnitName', t('tenant_dashboard.item')],
        ['weight', t('tenant_dashboard.waste_volume')],
        ['price', t('tenant_dashboard.processing_unit_price')],
        ['cost', t('tenant_dashboard.processing_cost_yen')],
        ['rate', t('tenant_dashboard.processing_cost_percentage')],
      ],
      headNote: csvHeadNote,
      data:
        tenantsRateWasteCost?.rateByWasteCost
          .sort((a, b) => Number(b.cost) - Number(a.cost))
          .map((item) => ({
            wasteUnitName: item.wasteUnitName,
            weight: normalizeNumber({ value: item.weight, toFixed: false }),
            price: normalizeNumber({ value: item.price, toFixed: false }),
            cost: normalizeNumber({ value: item.cost, toFixed: 0 }),
            rate: item.rate,
          })) ?? [],
    }).downLoadCsv({
      filename: `${user.tenantName}_${t(
        'tenant_dashboard.rate_of_processing_cost'
      )}_${downloadTimeLabel}`,
    });
  };

  const handleDownloadCO2EmissionsRateCsv = () => {
    new Csv({
      headers: [
        ['item', t('tenant_dashboard.item')],
        ['CO2', t('tenant_dashboard.CO2_emissions_volume')],
        ['rate', t('tenant_dashboard.rate')],
      ],
      headNote: csvHeadNote,
      data:
        tenantsRateCO2WasteUnits?.rateCO2ByWasteUnits
          .sort((a, b) => Number(b.CO2) - Number(a.CO2))
          .map((item) => ({
            item: item.wasteUnitName,
            CO2: normalizeNumber({ value: item.CO2, toFixed: false }),
            rate: item.rate,
          })) ?? [],
    }).downLoadCsv({
      filename: `${user.tenantName}_${t(
        'tenant_dashboard.CO2_emissions_rate'
      )}_${downloadTimeLabel}`,
    });
  };

  const fetchDashboardsTenantsWastes = (filterType: DateTypeRange) => {
    getDashboardsTenantsWastes({
      params: {
        tenantId,
        typeDateRange: filterType,
      },
      successCallback: (res) => {
        setTenantsWastes(res);
      },
      history,
    });
  };

  const fetchDashboardsTenantsCompareTotalEmissionWeights = () => {
    getDashboardsTenantsCompareTotalEmissionWeight({
      params: {
        tenantId,
      },
      successCallback: (res) => {
        setTenantsCompareTotalEmissionWeight(res);
      },
      failedCallback: () => {
        setTenantsCompareTotalEmissionWeight(undefined);
      },
      history,
    });
  };

  const fetchDashboardsTenantsCompareRecycleRate = () => {
    getDashboardsTenantsCompareRecycleRate({
      params: {
        tenantId,
      },
      successCallback: (res) => {
        setTenantsCompareRecycleRate(res);
      },
      failedCallback: () => {
        setTenantsCompareRecycleRate(undefined);
      },
      history,
    });
  };

  const fetchDashboardsTenantsRateCO2WasteUnits = () => {
    getDashboardsTenantsRateCO2WasteUnits({
      params: {
        ...filter,
        tenantId,
      },
      successCallback: (res) => {
        setTenantsRateCO2WasteUnits(res);
      },
      failedCallback: () => {
        setTenantsRateCO2WasteUnits(undefined);
      },
      history,
    });
  };

  const wasteCostChartTooltipMapper = useMemo(
    () =>
      tenantsRateWasteCost?.rateByWasteCost?.reduce(
        (
          res: Record<
            string,
            {
              cost: string;
              weight: string;
            }
          >,
          { wasteUnitName, cost, weight }
        ) => {
          res[wasteUnitName] = { cost, weight };

          return res;
        },
        {}
      ),
    [tenantsRateWasteCost?.rateByWasteCost]
  );

  const handleExportDashboardPdf = () => {
    if (dashboardPrintRef.current) {
      const exportHeight = Math.max(
        PRINT_WIDTH * Math.sqrt(2),
        dashboardPrintRef.current.scrollHeight
      );

      html2canvas(dashboardPrintRef.current, {
        backgroundColor: '#1B1B1B',
        width: PRINT_WIDTH,
        height: exportHeight,
        windowWidth: PRINT_WIDTH,
        windowHeight: exportHeight,
        scale: 2,
      }).then((canvas) => {
        const data = canvas.toDataURL('image/png');
        const pdf = new jsPDF('portrait', 'px', [canvas.width, canvas.height]);

        pdf.addImage(data, 'PNG', 0, 0, canvas.width, canvas.height);
        pdf.save(
          `${_snakeCase(
            tenantName || user.tenantName || 'tenant'
          )}_Report_${downloadTimeLabel}.pdf`
        );
      });
    }
  };

  const renderStatistic = (isDownload?: boolean) => (
    <TenantStatistics
      filterData={filter}
      onFilterData={handleFilterData}
      analyticsConfig={{
        emissionWeight: tenantsCompareTotalEmissionWeight,
        compareRecycle: tenantsCompareRecycleRate,
        itemMaxWeight: initialTenantRateWasteUnit?.itemMaxWeight,
        recycleItemMinWeight: initialTenantRateWasteUnit?.recycleItemMinWeight,
        totalCost: tenantsRateWasteCost?.totalCost,
        isDownload,
      }}
      donutChartsConfig={{
        wasteRate: {
          data:
            tenantsRateWasteUnit?.rateByWasteUnits?.map((rateByWasteUnit) => ({
              label: rateByWasteUnit.wasteUnitName,
              value:
                Number(tenantsRateWasteUnit?.totalWeight) > 99999
                  ? convertWeight(UnitType.T, Number(rateByWasteUnit.weight))
                  : Number(rateByWasteUnit.weight || 0),
            })) || [],
          type: {
            total:
              Number(tenantsRateWasteUnit?.totalWeight) > 99999
                ? convertWeight(
                    UnitType.T,
                    Number(tenantsRateWasteUnit?.totalWeight)
                  )
                : Number(tenantsRateWasteUnit?.totalWeight || 0),
            unit:
              Number(tenantsRateWasteUnit?.totalWeight) > 99999 ? 't' : 'kg',
          },
          autoSort: true,
          onDownloadCSV: handleDownloadWasteRateCsv,
          chartOptions: {
            downloadable: !isDownload,
          },
        },
        recycleRate: {
          data:
            tenantsRateRecycleMethod?.recycleMethods?.map((recycleMethod) => ({
              label:
                i18n.language === 'ja'
                  ? recycleMethod.name
                  : recycleMethod.englishName || recycleMethod.name,
              value: Number(recycleMethod.rate),
            })) || [],
          type: {
            total: Number(tenantsRateRecycleMethod?.totalRecycleRate || 0),
            unit: '%',
          },
          convertToPercent: false,
          onDownloadCSV: handleDownloadRecycleRateCsv,
          chartOptions: {
            downloadable: !isDownload,
          },
        },
        circulationAndProcessing: {
          data: Number(tenantsRateWasteCost?.totalCost || 0)
            ? tenantsRateWasteCost?.rateByWasteCost?.map((rateByWasteCost) => ({
                label: rateByWasteCost.wasteUnitName,
                value: Number(rateByWasteCost.cost),
              })) || []
            : [],
          extendedDetail:
            tenantsRateWasteCost?.rateByWasteCost?.map((rateByWasteCost) => ({
              label: rateByWasteCost.wasteUnitName,
              value: rateByWasteCost.price,
            })) || [],
          type: {
            total: Number(tenantsRateWasteCost?.totalCost || 0),
            unit: t('tenant_dashboard.yen') || '',
          },
          autoSort: true,
          onDownloadCSV: handleDownloadCirculationAndProcessing,
          modalWithLegends: false,
          chartOptions: {
            chartOptions: {
              plugins: {
                tooltip: {
                  displayColors: false,
                  titleFont: {
                    size: 16,
                    weight: 700,
                  },
                  callbacks: {
                    title: (item: TooltipModel<'doughnut'>) => item[0]?.label,
                    label: (item: TooltipItem<'doughnut'>) => [
                      `${t('common.weight')}: ${normalizeNumber({
                        value:
                          wasteCostChartTooltipMapper?.[item.label]?.weight,
                      })}kg`,
                      `${t('common.cost')}: ${normalizeNumber({
                        value: wasteCostChartTooltipMapper?.[item.label]?.cost,
                      })}${t('tenant_dashboard.yen')}`,
                    ],
                  },
                },
              },
            },
          },
        },
        emissionsCO2: {
          data:
            tenantsRateCO2WasteUnits?.rateCO2ByWasteUnits?.map(
              (rateCO2ByWasteUnit) => ({
                label: rateCO2ByWasteUnit.wasteUnitName,
                value:
                  Number(tenantsRateCO2WasteUnits?.totalCO2) > 99999
                    ? convertWeight(UnitType.T, Number(rateCO2ByWasteUnit.CO2))
                    : Number(rateCO2ByWasteUnit.CO2 || 0),
              })
            ) || [],
          type: {
            total:
              Number(tenantsRateCO2WasteUnits?.totalCO2) > 99999
                ? convertWeight(
                    UnitType.T,
                    Number(tenantsRateCO2WasteUnits?.totalCO2)
                  )
                : Number(tenantsRateCO2WasteUnits?.totalCO2 || 0),
            unit:
              Number(tenantsRateCO2WasteUnits?.totalCO2) > 99999
                ? 't-CO2'
                : 'kg-CO2',
          },
          autoSort: true,
          onDownloadCSV: handleDownloadCO2EmissionsRateCsv,
          chartOptions: {
            downloadable: !isDownload,
          },
        },
      }}
      barChartConfig={{
        title: t('tenant_dashboard.waste_charges'),
        downloadable: !isDownload,
        autoSort: true,
        stackedData: tenantsWastes?.map((waste) => ({
          date: waste.date,
          data: waste.data.map((item) => ({
            label: item.name,
            value: Number(item.weight),
          })),
          day: waste.day,
          month: waste.month,
          year: waste.year,
        })),
        unit: 'kg',
        filterType: tenantWasteChangesType,
        onFilterTypeChange: setTenantWasteChangesType,
        legendDisplayMode: isDownload ? 'grid' : 'col',
      }}
    />
  );

  return (
    <PrimaryTemplate
      hasLogo
      customName={tenantName}
      download={handleExportDashboardPdf}
    >
      <div className={styles.Wrapper}>{renderStatistic()}</div>

      <div
        className={mergeClasses(styles.Wrapper, styles.PrintWrapper)}
        ref={dashboardPrintRef}
        style={{
          width: PRINT_WIDTH,
          padding: 40,
          boxSizing: 'border-box',
          position: 'fixed',
          left: -PRINT_WIDTH * 20,
          top: 0,
          zIndex: -Number.MAX_SAFE_INTEGER,
        }}
      >
        <div
          style={{
            textAlign: 'left',
            marginBottom: 60,
          }}
        >
          <img src="/assets/images/logos.svg" width={(PRINT_WIDTH * 2) / 6} />
        </div>
        {renderStatistic(true)}
      </div>
    </PrimaryTemplate>
  );
};

export default TenantDashboard;
