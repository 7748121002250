// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useFetchMaterialNameFromChainsQuery } from 'apis/purchase/purchaseApi';
import PurchaseContentHeader from 'components/molecules/PurchaseContentHeader';
import PurchaseNavbar from 'components/organisms/PurchaseNavbar';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ChainsMaterialNames, TMenuItem } from 'types/purchase';
import { fnLogout } from 'util/commons';
import { Path } from 'routes';
import './PurchaseTemplate.scss';
import { Breadcrumb } from 'util/Types';

interface Props {
  children?: React.ReactNode;
  title?: string | null;
  className?: string;
  breadcrumb?: Breadcrumb[];
}

const PurchaseTemplate = ({
  children,
  title = '',
  className,
  breadcrumb,
}: Props) => {
  const history = useHistory();

  const { i18n, t } = useTranslation();

  const { data: materialNames } = useFetchMaterialNameFromChainsQuery();

  const getMaterialName = (item: ChainsMaterialNames) => {
    if (
      i18n.language === 'ja' ||
      (i18n.language === 'en' && !item.englishMaterialName)
    ) {
      return item.materialName;
    }

    return item.englishMaterialName;
  };

  // useMemo for render dynamic items from API
  const menuItems: TMenuItem[] = useMemo(
    () => [
      {
        value: t('purchase_nav.dashboard'),
        isCollapse: true,
        path: 'purchase-dashboard',
        children:
          materialNames?.map((x) => ({
            value: getMaterialName(x),
            path: `/purchase-dashboard/${x.id}`,
            action: () => {
              history.push(`/purchase-dashboard/${x.id}`);
            },
          })) ?? [],
      },
      {
        value: t('purchase_nav.product_regist'),
        path: 'purchase-product-registration',
        action: () => {
          history.push(Path.purchaseProductRegistration);
        },
      },
      {
        value: t('purchase_nav.procure'),
        path: 'resource-management',
        isCollapse: true,
        children:
          materialNames?.map((x) => ({
            value: getMaterialName(x),
            path: `/resource-management/${x.id}`,
            action: () => {
              history.push(`/resource-management/${x.id}`);
            },
          })) ?? [],
      },
      {
        value: t('purchase_nav.traceability'),
        path: 'traceability',
        action: () => {
          history.push(Path.purchaseTraces);
        },
      },
      {
        value: t('purchase_nav.resource_search'),
        path: 'resource-search',
        action: () => {
          history.push(Path.purchaseResourceSearch);
        },
      },
      {
        value: t('purchase_nav.history'),
        path: 'purchase-histories',
        action: () => {
          history.push(Path.purchaseHistories);
        },
      },
      {
        value: t('purchase_nav.logout'),
        action: () => fnLogout(history),
      },
    ],
    [i18n.language, materialNames]
  ); // add dependency

  return (
    <div className={`purchase-template ${className}`} id="user-layout">
      <PurchaseNavbar menuItems={menuItems} />
      <div className="content">
        <PurchaseContentHeader title={title} breadcrumb={breadcrumb} />
        <div className="main">{children}</div>
      </div>
    </div>
  );
};

export default PurchaseTemplate;
