// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { getDetailPurchaseSite } from 'apis/operator_site/siteApi';
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import CreateUpdatePurchaseForm from 'components/molecules/CreateUpdatePurchaseForm';
import { ModalSiteState } from 'pages/AdminSiteListManagement/AdminSiteListManagement';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { basePurchaseSiteForm } from 'util/constants/sites';
import { ModalType } from 'util/Enums';
import { PurchaseSiteItem } from 'util/siteManagementTypes';
import styles from './index.module.scss';

interface Props {
  createUpdateModal: ModalSiteState;
  handleCreatePurchaseSite: (create: PurchaseSiteItem) => void;
  handleUpdatePurchaseSite: (update: PurchaseSiteItem) => void;
  onClose?: () => void;
}

const CreateUpdatePurchaseSiteModal: React.FC<Props> = ({
  onClose,
  createUpdateModal,
  handleCreatePurchaseSite,
  handleUpdatePurchaseSite,
}) => {
  const { t } = useTranslation();
  const [inputForm, setInputForm] =
    useState<PurchaseSiteItem>(basePurchaseSiteForm);
  const { type } = createUpdateModal;

  useEffect(() => {
    if (
      createUpdateModal.type === ModalType.update &&
      createUpdateModal?.siteEditing &&
      createUpdateModal?.siteEditing.id
    ) {
      getDetailPurchaseSite(createUpdateModal.siteEditing.id, (data) => {
        if (data) {
          setInputForm({
            ...basePurchaseSiteForm,
            ...data,
            province: data.siteAddresses[0]?.province || '',
            district: data.siteAddresses[0]?.district || '',
            comparisonOfProcurementIntensity: data
              .comparisonOfProcurementIntensity.length
              ? data.comparisonOfProcurementIntensity
              : [
                  {
                    intensityCo2OfProcuredMaterialToCompare: '',
                    procuredMaterialToCompare: '',
                  },
                ],
            carbonPrice: data.carbonPrice || '',
            costDisplayUnit: data.costDisplayUnit || '',
            procurementUnitPrice: data.procurementUnitPrice || '',
            prevYearScope1: data.prevYearScope1 || '',
            prevYearScope2: data.prevYearScope2 || '',
            prevYearInputAmount: data.prevYearInputAmount || '',
          });
        }
      });
    }
  }, [createUpdateModal]);

  return (
    <div className={styles.createTenantModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {type === ModalType.add
                ? t('site_management.modal.create_heading')
                : t('site_management.modal.edit_heading')}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <CreateUpdatePurchaseForm
            createUpdateModal={createUpdateModal}
            onCreatePurchaseSite={handleCreatePurchaseSite}
            onUpdatePurchaseSite={handleUpdatePurchaseSite}
            inputForm={inputForm}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateUpdatePurchaseSiteModal;
