// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import Calendar from 'react-calendar';
import IconCalendarTablet from 'components/atoms/icons/IconCalendarTablet';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { getFormatDate } from 'ts/formatDate';
import { DownloadResourceDataForm } from 'util/Types';
import styles from './AdminSelectRangeDownloadForm.module.scss';
import '../../organisms/DownloadModal/Calendar.scss';
import { DATE_FORMAT } from 'util/ConstantValues';
import dayjs from 'dayjs';

type Props = {
  onSubmitDownloadData: ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }) => void;
};

const AdminSelectRangeDownloadForm: React.FC<Props> = ({
  onSubmitDownloadData,
}) => {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const changeDate = (dates: Array<Date>) => {
    setStartDate(
      getFormatDate(dates[0].toString(), DATE_FORMAT.full_date_dash)
    );
    dates.length > 1 && dates[1]
      ? setEndDate(
          getFormatDate(
            dayjs(dates[1]).startOf('day').toString(),
            DATE_FORMAT.full_date_dash
          )
        )
      : setEndDate('');
  };

  return (
    <div className={styles.adminSelectRangeDownloadForm}>
      <Form<DownloadResourceDataForm>
        onSubmit={() => onSubmitDownloadData({ startDate, endDate })}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
      >
        {(props) => {
          return (
            <form className={styles.formInput}>
              <div className={styles.inputFrame}>
                <div className={styles.targetDateGroup}>
                  <div className={styles.calendarField}>
                    <p>{startDate || '開始日'}</p>
                    <IconCalendarTablet />
                  </div>
                  <div className={styles.calendarField}>
                    <p>{endDate || '終了日'}</p>
                    <IconCalendarTablet />
                  </div>
                </div>
                <div className={'calender-datepick'}>
                  <Calendar
                    calendarType="US"
                    locale={'ja'}
                    allowPartialRange={true}
                    selectRange={true}
                    prev2Label={null}
                    next2Label={null}
                    prevLabel={'◀'}
                    nextLabel={'▶'}
                    maxDate={new Date()}
                    formatDay={(locale, date) => getFormatDate(date, 'D')}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onChange={(activeStartDate, value) => {
                      changeDate(activeStartDate);
                    }}
                    className={'calender'}
                  />
                </div>
              </div>
              <div className={styles.submit}>
                <button
                  className={styles.submitButton}
                  type="button"
                  disabled={!startDate || !endDate}
                  onClick={props.handleSubmit}
                >
                  データのダウンロード
                </button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default AdminSelectRangeDownloadForm;
