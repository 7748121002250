// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './Collects.module.scss';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { CollectComplete } from 'components/organisms/CollectComplete';
import { CollectReceived } from 'components/organisms/CollectReceived';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TabletTab } from 'components/molecules/TabletTab';

function Collects() {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <PrimaryTemplate h1={t('collects.title')} className={styles.container}>
      <TabletTab
        tabItems={[t('collects.collection_list'), t('collects.loading_list')]}
        defaultTabIndex={history.location.hash == '#1' ? 1 : 0}
        renderContent={({ index }) => (
          <div>
            {index === 0 && <CollectReceived />}
            {index === 1 && <CollectComplete />}
          </div>
        )}
      ></TabletTab>
    </PrimaryTemplate>
  );
}

export default Collects;
