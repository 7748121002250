// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import TabExtends from 'components/molecules/TabExtends';
import RecycleQRScanning from 'components/organisms/RecycleQRScanning';
import { RecycleReceived } from 'components/organisms/RecycleReceived';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function Recycle() {
  const { t } = useTranslation();
  const history = useHistory();

  const tabItems = [
    {
      title: t('recycle.tab_left_heading'),
      content: (
        <RecycleReceived
          active={!history.location.hash || history.location.hash === '#0'}
        />
      ),
    },
    {
      title: t('recycle.tab_right_heading'),
      content: <RecycleQRScanning />,
    },
  ];

  return (
    <PrimaryTemplate h1={t('recycle.heading')}>
      <TabExtends tabItems={tabItems} />
    </PrimaryTemplate>
  );
}

export default Recycle;
