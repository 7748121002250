// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { RefObject, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './TabExtends.module.scss';
import { mergeClasses } from 'util/commons';

interface Props {
  tabItems: {
    title: string;
    content: JSX.Element;
  }[];
  className?: string;
  rightSection?: React.ReactNode;
  contentRef?: RefObject<HTMLDivElement>;
  onTabChange?: () => void;
}

const TabExtends = ({
  tabItems,
  className,
  rightSection,
  contentRef,
  onTabChange,
}: Props) => {
  const history = useHistory();

  const [activedTabIndex, setActivedTabIndex] = useState<number>(0);

  const onChangeTab = (tabIndex: number) => {
    history.push(`${window.location.search}#` + tabIndex);
  };

  useEffect(() => {
    setActivedTabIndex(Number(window.location.hash.replace('#', '')));
    onTabChange?.();
  }, [window.location.hash]);

  return (
    <div className={mergeClasses(className, styles.tabExtends)}>
      <div className={styles.tabMenu}>
        <div className={styles.tabItems}>
          {tabItems.map((it, index) => {
            const actived = index === activedTabIndex;
            return (
              <div
                key={it.title}
                className={
                  `${styles.tabItem} ` +
                  (actived ? `${styles.tabItemActive}` : '')
                }
                onClick={() => onChangeTab(index)}
              >
                {it.title}
              </div>
            );
          })}
        </div>

        {rightSection != null && <div>{rightSection}</div>}
      </div>
      <div ref={contentRef}>
        {tabItems.map((tab, index) => (
          <div
            key={`tab-content-${index}`}
            className={mergeClasses({
              hidden: index !== activedTabIndex,
            })}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabExtends;
