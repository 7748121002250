// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import DonutChart from 'components/molecules/DonutChart';
import _snakeCase from 'lodash/snakeCase';
import { FilterObject } from 'pages/Dashboard';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProcurementIntensity } from 'types/dashboard';
import { normalizeNumber } from 'util/commons';
import styles from './ProcurementIntensityOrganism.module.scss';

type Props = {
  filter: FilterObject;
  title: string;
  content: string;
  textCenter: string;
  loading: boolean;
  procurementIntensity?: ProcurementIntensity;
};

const ProcurementIntensityOrganism: React.FC<Props> = ({
  content,
  procurementIntensity,
  textCenter,
  title,
  loading = false,
}) => {
  const { t } = useTranslation();

  const dataLabels = procurementIntensity?.rate.slice(0, 6) || [];

  const otherRate =
    procurementIntensity?.rate
      .slice(6, procurementIntensity?.rate.length)
      .reduce((total, file) => total + Number(file.rate), 0) ?? 0;

  const [convertedData, tooltipValuePercentMapper] = useMemo((): [
    { label: string; value: number }[],
    Record<number, number>
  ] => {
    const convertedData: { label: string; value: number }[] = [];
    const tooltipValuePercentMapper: Record<number, number> = {};

    dataLabels.forEach(({ type, rate }) => {
      const originalValue = (Number(procurementIntensity?.total) * rate) / 100;

      if (originalValue) {
        convertedData.push({
          label: t(`purchase_page.dashboard.${_snakeCase(type)}`),
          value: originalValue,
        });

        tooltipValuePercentMapper[originalValue] = rate;
      }
    });

    if (procurementIntensity?.rate && procurementIntensity?.rate?.length > 6) {
      const originalOtherValue =
        (Number(procurementIntensity.total) * otherRate) / 100;

      if (originalOtherValue) {
        convertedData.push({
          label: t('purchase_page.dashboard.other'),
          value: originalOtherValue,
        });

        tooltipValuePercentMapper[originalOtherValue] = otherRate;
      }
    }

    return [convertedData, tooltipValuePercentMapper];
  }, [procurementIntensity, dataLabels]);

  return (
    <div className={styles.procurementIntensity}>
      {!loading && (
        <DonutChart
          title={title}
          content={content}
          textCenter={textCenter}
          description={t('dashboard.rate_recycle_method_chart.description')}
          data={procurementIntensity?.total ? convertedData : []}
          tooltipMapper={{
            mapper: tooltipValuePercentMapper,
            unit: '%',
          }}
          showPercentValue={false}
          customCore={{
            content: normalizeNumber({
              value: procurementIntensity?.total,
            }),
            tooltipOdd: 1,
          }}
          isDownloadPNG
          emptyMessage={t('dashboard.empty_chart_data') || ''}
          chartOptions={{
            maintainAspectRatio: false,
            responsive: true,
          }}
          height={240}
        />
      )}
    </div>
  );
};

export default ProcurementIntensityOrganism;
