// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { IconDelete } from 'components/atoms/icons/IconDelete';
import styles from './BarcodeICCardForm.module.scss';
import { SiteTenantAuthenticationItem } from 'util/siteManagementTypes';

type Props = {
  authenticationItem: SiteTenantAuthenticationItem | undefined;
  onClickShowBarcode?: () => void;
  onClickShowAddICCard: () => void;
  onClickDeleteBarcode?: (barcode: string) => void;
  onClickDeleteICCard: (icCardId: number) => void;
};

const BarcodeICCardForm: React.FC<Props> = ({
  authenticationItem,
  onClickShowBarcode,
  onClickShowAddICCard,
  onClickDeleteBarcode,
  onClickDeleteICCard,
}) => {
  const barcode =
    authenticationItem?.type === 'tenant' && authenticationItem.barcode;
  const icCards =
    authenticationItem?.type === 'tenant'
      ? authenticationItem.icCards
      : authenticationItem?.adminCards;
  const cardLabel =
    authenticationItem?.type === 'tenant' ? 'ICカード' : '管理カード';

  return (
    <div className={styles.barcodeICCardForm}>
      <div className={styles.barcodeICCardContent}>
        {barcode && (
          <div className={styles.barcodeICCardContentRow}>
            <div className={styles.title}>バーコード</div>
            <div className={styles.barcodeICCardInfo}>
              <div className={styles.barcodeICCardItem}>
                <div
                  onClick={onClickShowBarcode}
                  className={styles.barcodeInfo}
                >
                  {barcode}
                </div>
                {onClickDeleteBarcode && (
                  <div
                    className={styles.iconDelete}
                    onClick={() => {
                      onClickDeleteBarcode(barcode);
                    }}
                  >
                    <IconDelete />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {icCards && (
          <div className={styles.barcodeICCardContentRow}>
            <div className={styles.title}>{cardLabel}:</div>
            <div className={styles.barcodeICCardInfo}>
              {icCards.map((icCardItem) => (
                <div className={styles.barcodeICCardItem} key={icCardItem.id}>
                  <div className={styles.barcodeICCardName}>
                    {icCardItem.card}
                  </div>
                  <div
                    className={styles.iconDelete}
                    onClick={() => onClickDeleteICCard(Number(icCardItem.id))}
                  >
                    <IconDelete />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {icCards && (
        <>
          <div className={styles.line} />
          <div className={styles.submit}>
            <button
              className={styles.submitButton}
              onClick={onClickShowAddICCard}
              type="button"
            >
              {cardLabel}を追加
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default BarcodeICCardForm;
