// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  onClick?: () => void;
}
export const IconId: React.FC<Props> = ({ onClick }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && onClick}
    >
      <path
        d="M2 12.5273C2 12.6377 2.08954 12.7273 2.2 12.7273H3.77536C3.88581 12.7273 3.97536 12.6377 3.97536 12.5273V4.2C3.97536 4.08954 3.88581 4 3.77536 4H2.2C2.08954 4 2 4.08954 2 4.2V12.5273Z"
        fill="#878787"
      />
      <path
        d="M6.40555 12.5273C6.40555 12.6377 6.4951 12.7273 6.60555 12.7273H9.22177C12.1314 12.7273 14 11.2649 14 8.32826C14 5.37985 12.1314 4 9.115 4H6.60555C6.4951 4 6.40555 4.08954 6.40555 4.2V12.5273ZM8.58091 11.3238C8.47045 11.3238 8.38091 11.2343 8.38091 11.1238V5.59165C8.38091 5.48119 8.47045 5.39165 8.58091 5.39165H8.99487C10.8368 5.39165 11.9846 6.19361 11.9846 8.32826C11.9846 10.4511 10.8368 11.3238 8.99487 11.3238H8.58091Z"
        fill="#878787"
      />
      <path
        d="M2 12.5273C2 12.6377 2.08954 12.7273 2.2 12.7273H3.77536C3.88581 12.7273 3.97536 12.6377 3.97536 12.5273V4.2C3.97536 4.08954 3.88581 4 3.77536 4H2.2C2.08954 4 2 4.08954 2 4.2V12.5273Z"
        stroke="#878787"
        strokeWidth="0.1"
      />
      <path
        d="M6.40555 12.5273C6.40555 12.6377 6.4951 12.7273 6.60555 12.7273H9.22177C12.1314 12.7273 14 11.2649 14 8.32826C14 5.37985 12.1314 4 9.115 4H6.60555C6.4951 4 6.40555 4.08954 6.40555 4.2V12.5273ZM8.58091 11.3238C8.47045 11.3238 8.38091 11.2343 8.38091 11.1238V5.59165C8.38091 5.48119 8.47045 5.39165 8.58091 5.39165H8.99487C10.8368 5.39165 11.9846 6.19361 11.9846 8.32826C11.9846 10.4511 10.8368 11.3238 8.99487 11.3238H8.58091Z"
        stroke="#878787"
        strokeWidth="0.1"
      />
    </svg>
  );
};
