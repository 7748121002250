// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import './CheckBox.scss';

interface Props {
  id: string;
  className?: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent) => void;
  disabled?: boolean;
}

export const CheckBox = ({
  id,
  checked,
  onChange,
  disabled,
}: React.PropsWithChildren<Props>) => {
  return (
    <div className="checkbox">
      <input
        id={id}
        checked={checked}
        onChange={(e) => onChange(e)}
        type="checkbox"
        disabled={disabled}
      />
      <label htmlFor={id} className={disabled ? 'disabledLabel' : ''}></label>
    </div>
  );
};
