// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useParams } from 'react-router';
import ResourceManagement from '../ResourceManagement';

const PurchaseResourceManagement = () => {
  const { id: recycleChainId } = useParams() as { id: string };

  return (
    <ResourceManagement key={recycleChainId} recycleChainId={recycleChainId} />
  );
};

export default PurchaseResourceManagement;
