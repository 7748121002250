// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Modal } from 'components/atoms/Modal';
import { IconCloseTablet } from 'components/atoms/icons/IconCloseTablet';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import dayjs from 'dayjs';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import { useMemo, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT, LAYOUT_BREAK_POINTS } from 'util/ConstantValues';
import { RecursivePartial } from 'util/Types';
import {
  cutText,
  generateColorPalette,
  mergeClasses,
  normalizeNumber,
} from 'util/commons';
import { useGetWindowSize } from 'util/hooks/useGetWindowSize';
import DashboardDownloadModal from '../DashboardDownloadModal';
import DonutChart, { DonutChartProps, PluginMode } from '../DonutChart';
import styles from './ExtendableDonutChart.module.scss';

export type ExtendableDonutChartProps = {
  title: string;
  data: {
    label: string;
    value: number;
  }[];
  type?:
    | 'percent'
    | {
        total?: number;
        unit?: string;
      };
  convertToPercent?: boolean;
  autoSort?: boolean;
  maxDisplayItems?: number;
  colorsSetting?: {
    shortColorPalettes?: string[];
    fullColorPalettes?: string[];
    endColors?: string[];
    startColor?: string;
    minimumPrimaryColors?: number;
  };
  chartOptions?: RecursivePartial<DonutChartProps>;
  extendedDetail?:
    | boolean
    | {
        label: string;
        value: number;
      }[];
  onDownloadCSV?: () => void;
  children?: React.ReactNode;
  extendedDetailChartTitle?: {
    donut?: string;
    bar?: string;
  };
  className?: string;
  modalWithLegends?: boolean;
};

const ExtendableDonutChart: React.FC<ExtendableDonutChartProps> = ({
  title,
  data,
  type,
  convertToPercent,
  autoSort,
  maxDisplayItems,
  colorsSetting,
  chartOptions,
  extendedDetail,
  onDownloadCSV,
  children,
  extendedDetailChartTitle,
  className,
  modalWithLegends = true,
}) => {
  const { customCore, ...restChartOptions } = chartOptions ?? {};
  const [isDetailModalOpened, setIsDetailModalOpened] =
    useState<boolean>(false);
  const [isOpenDownload, setOpenDownload] = useState<boolean>(false);
  const { t } = useTranslation();

  const { windowHeight, windowWidth } = useGetWindowSize();

  const createColorPalettes = (dataArr: ExtendableDonutChartProps['data']) => [
    ...generateColorPalette({
      startColor: colorsSetting?.startColor,
      totalColors: Math.max(
        colorsSetting?.minimumPrimaryColors ?? 0,
        dataArr.length - (colorsSetting?.endColors?.length ?? 0)
      ),
      hueStep: 50,
    }),
    ...(colorsSetting?.endColors ?? []),
  ];

  const {
    convertedData,
    fullConvertedData,
    colorsPalette,
    fullColorsPalette,
    tooltipMapper,
    total,
  } = useMemo(() => {
    let convertedData: ExtendableDonutChartProps['data'] = [...data];
    let fullConvertedData: ExtendableDonutChartProps['data'] = [...data];
    const sortedData = [...data];
    sortedData.sort((a, b) => b.value - a.value);

    if (autoSort) {
      convertedData = [...sortedData];
      fullConvertedData = [...sortedData];
    }

    if (maxDisplayItems != null && convertedData.length > maxDisplayItems + 1) {
      const others = sortedData.slice(maxDisplayItems, convertedData.length);

      convertedData = [
        ...convertedData.filter(
          ({ label }) =>
            !others.length || others.every((item) => item.label !== label)
        ),
        {
          label: t('common.other'),
          value: others.reduce((sum: number, { value }) => sum + value, 0),
        },
      ];
    }

    let total = convertedData.reduce((sum: number, val) => sum + val.value, 0);
    let tooltipMapper: Record<number, number> | undefined = undefined;

    if (type === 'percent') {
      if (convertToPercent === false) {
        total = 100;
      }
    } else if (type?.total != null) {
      total = type.total;
    }

    if (convertToPercent && total) {
      convertedData = convertedData.map((item) => {
        const percentValue = (item.value / total) * 100;
        tooltipMapper = {
          ...tooltipMapper,
          [percentValue]: type === 'percent' ? percentValue : item.value,
        };

        return {
          ...item,
          value: percentValue,
        };
      });

      fullConvertedData = fullConvertedData.map((item) => {
        const percentValue = (item.value / total) * 100;
        tooltipMapper = {
          ...tooltipMapper,
          [percentValue]: type === 'percent' ? percentValue : item.value,
        };

        return {
          ...item,
          value: percentValue,
        };
      });
    }

    const colorsPalette =
      colorsSetting?.shortColorPalettes ?? createColorPalettes(convertedData);
    const fullColorsPalette =
      colorsSetting?.fullColorPalettes ??
      createColorPalettes(fullConvertedData);

    return {
      convertedData,
      fullConvertedData,
      colorsPalette,
      fullColorsPalette,
      tooltipMapper,
      total,
    };
  }, [
    data,
    autoSort,
    maxDisplayItems,
    type,
    convertToPercent,
    createColorPalettes,
    colorsSetting?.shortColorPalettes,
    colorsSetting?.fullColorPalettes,
  ]);

  const renderBarChart = () => {
    if (
      !extendedDetail ||
      (typeof extendedDetail === 'object' && !extendedDetail.length)
    ) {
      return null;
    }

    const sortedData =
      typeof extendedDetail === 'object' ? [...extendedDetail] : [...data];

    if (autoSort) {
      sortedData.sort((a, b) => b.value - a.value);
    }

    return (
      <div
        className={mergeClasses(styles.Bar, {
          [styles.BarBox]: children,
        })}
      >
        {extendedDetailChartTitle?.bar && (
          <div className={styles.heading}>
            <div className={styles.headingBox}>
              <p>{extendedDetailChartTitle?.bar}</p>
            </div>
          </div>
        )}

        <div style={{ flex: 1 }}>
          <Chart
            height={
              windowWidth <= LAYOUT_BREAK_POINTS.MD
                ? windowHeight / 2.5
                : undefined
            }
            type="bar"
            data={{
              labels: sortedData.map(({ label }) => cutText(label, 15)),
              datasets: [
                {
                  type: 'bar',
                  data: sortedData.map(({ value }) => value),
                  backgroundColor: fullColorsPalette,
                  datalabels: {
                    display: false,
                  },
                },
              ],
            }}
            options={{
              indexAxis: 'y',
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    title: () => '',
                    label: ({ label, raw }) =>
                      `${label}: ${normalizeNumber({
                        value: String(raw),
                      })}${type === 'percent' ? '%' : type?.unit ?? ''}`,
                  },
                },
              },
              scales: {
                x: {
                  ticks: {
                    color: '#CFCFCF',
                  },
                  grid: {
                    color: '#6F6F6F',
                    borderColor: '#6F6F6F',
                    borderWidth: 1,
                    drawBorder: true,
                  },
                },
                y: {
                  ticks: {
                    color: '#CFCFCF',
                  },
                  grid: {
                    color: 'transparent',
                    borderColor: '#6F6F6F',
                    borderWidth: 1,
                    drawBorder: true,
                  },
                },
              },
            }}
          />
        </div>
      </div>
    );
  };

  const renderLegend = () => {
    if (!extendedDetail) {
      return null;
    }
    const sortedData = [...data];

    if (autoSort) {
      sortedData.sort((a, b) => b.value - a.value);
    }

    return (
      <div className={styles.LegendArea}>
        {sortedData.map((i, idx) => {
          return (
            <div
              key={idx}
              className={mergeClasses(styles.Legend, {
                [styles.Legend2]: idx % 2 > 0,
              })}
            >
              <div>
                <div
                  style={{
                    backgroundColor: fullColorsPalette[idx],
                  }}
                  className={styles.LegendColor}
                />
              </div>
              <p
                className={`${styles.LegendLabel} ${title}-chartLegendLabel`}
                title={i.label}
              >
                {i.label}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  const handleDownloadPNG = async () => {
    const exportChart = document.getElementById(`${title}-exportChart`);
    const canvas = await html2canvas(exportChart as HTMLElement, {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    });

    const dataURL = canvas.toDataURL('image/png');
    downloadjs(
      dataURL,
      `${title} ${dayjs().format(DATE_FORMAT.slaYMDHm)}.png`,
      'image/png'
    );
  };

  return (
    <>
      <div
        className={className}
        onClick={
          data.length && extendedDetail
            ? () => setIsDetailModalOpened(true)
            : undefined
        }
      >
        {children}

        <DonutChart
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...(restChartOptions as any)}
          wrapperStyle={
            children != null
              ? {
                  position: 'fixed',
                  zIndex: -Number.MAX_SAFE_INTEGER,
                  top: -Number.MAX_SAFE_INTEGER,
                  left: -Number.MAX_SAFE_INTEGER,
                }
              : undefined
          }
          title={title}
          data={convertedData}
          colorsPalette={colorsPalette}
          customCore={{
            content:
              type === 'percent'
                ? 100
                : normalizeNumber({
                    value: total,
                  }),
            ...customCore,
          }}
          textCenter={type === 'percent' ? '%' : type?.unit}
          tooltipMapper={{
            mapper: tooltipMapper,
            unit: type === 'percent' ? '%' : type?.unit,
          }}
          showPercentValue={
            type === 'percent' || convertToPercent || type?.unit === '%'
          }
          detailChartConfig={{
            alternativeData: fullConvertedData,
            colorsPalette: fullColorsPalette,
          }}
          handleDownloadCSV={onDownloadCSV}
        />
      </div>

      {isDetailModalOpened && (
        <Modal className={styles.DonutExtendedModalWrapper}>
          <div className={styles.DonutExtendedModal}>
            <div className={styles.ModalTitle}>
              <p>{title}</p>
              <IconCloseTablet onClick={() => setIsDetailModalOpened(false)} />
            </div>

            <div className={styles.ModalBody}>
              <div className={styles.Charts}>
                <div
                  className={mergeClasses(styles.Donut, {
                    [styles.DonutBox]: children,
                  })}
                >
                  <div
                    className={mergeClasses(styles.heading, {
                      [styles.noHeading]: !extendedDetailChartTitle?.donut,
                    })}
                  >
                    {extendedDetailChartTitle?.donut && (
                      <div className={styles.headingBox}>
                        <p>{extendedDetailChartTitle?.donut}</p>
                      </div>
                    )}

                    <div
                      className={styles.buttonDownload}
                      onClick={() => setOpenDownload(true)}
                    >
                      <IconDownload />
                    </div>
                  </div>

                  <DonutChart
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    {...(restChartOptions as any)}
                    data={fullConvertedData}
                    colorsPalette={fullColorsPalette}
                    customCore={{
                      content:
                        type === 'percent'
                          ? 100
                          : normalizeNumber({
                              value: total,
                            }),
                      tooltipOdd: 1,
                      ...customCore,
                    }}
                    textCenter={type === 'percent' ? '%' : type?.unit}
                    tooltipMapper={{
                      mapper: tooltipMapper,
                      unit: type === 'percent' ? '%' : type?.unit,
                    }}
                    chartOnlyMode={PluginMode.Preview}
                  />
                </div>
                {renderBarChart()}
              </div>
              {modalWithLegends && renderLegend()}
            </div>
          </div>
        </Modal>
      )}

      {isOpenDownload && (
        <DashboardDownloadModal
          onClose={() => {
            setOpenDownload(false);
          }}
          handleDownload={(data) => {
            if (data.isImage) {
              handleDownloadPNG();
            }
            if (data.isCsv && onDownloadCSV) {
              onDownloadCSV();
            }
            setOpenDownload(false);
          }}
        />
      )}
    </>
  );
};

ExtendableDonutChart.defaultProps = {
  convertToPercent: true,
};

export default ExtendableDonutChart;
