// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { CustomCheckBox } from 'components/atoms/CustomCheckbox';
import { ErrorText } from 'components/atoms/ErrorText';
import TableFilterCalendar from 'components/molecules/TableFilterCalendar';
import TableFilterMultiSelect from 'components/molecules/TableFilterMultiSelect';
import TableFilterSelect from 'components/molecules/TableFilterSelect';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { mergeClasses } from 'util/commons';
import { DATE_FORMAT } from 'util/ConstantValues';
import { OperatorType } from 'util/Enums';
import styles from './PreRegisResourceSelect.module.scss';
import { ResourceTableProps } from '.';
import { useTranslation } from 'react-i18next';

const ResourceTable: React.FC<ResourceTableProps> = ({
  handleCheckAll,
  filter,
  setFilter,
  resourceData,
  siteOptions,
  materialNameOptions,
  operatorType,
  displayResourceData,
  selectedResources,
  isActionDisabled,
  handleCheckItem,
  handleChangeInput,
  isCheckAllDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <table className={`primary-table ${styles.tablePreRegistResourceSelect}`}>
      <thead>
        <tr>
          <th onClick={handleCheckAll} className={styles.selection}>
            {!isCheckAllDisabled && (
              <div className={styles.checkAllContainer}>
                <span
                  className={mergeClasses(styles.checkAll, {
                    [styles.disabled]: isCheckAllDisabled,
                  })}
                >
                  {t('pre_regist_resource_select.selection')}
                </span>
              </div>
            )}
          </th>
          <th className={styles.dateTimeFilter}>
            <TableFilterCalendar
              label={t('pre_regist_resource_select.date_time')}
              value={filter?.receivedDatetime}
              onChange={(value) =>
                setFilter((prev) => ({ ...prev, receivedDatetime: value }))
              }
              disabled={!resourceData.length}
            />
          </th>
          <th className={styles.receiveFilter}>
            <TableFilterMultiSelect
              label={t('pre_regist_resource_select.receive_from')}
              options={siteOptions}
              values={filter?.siteIds}
              onChange={(value) =>
                setFilter((prev) => ({ ...prev, siteIds: value }))
              }
              disabled={!resourceData.length}
            />
          </th>
          <th className={styles.materialNameFilter}>
            <TableFilterSelect
              label={t('pre_regist_resource_select.material_name')}
              options={materialNameOptions}
              value={filter?.materialName}
              onChange={(value) =>
                setFilter((prev) => ({ ...prev, materialName: value }))
              }
              disabled={!resourceData.length}
            />
          </th>
          {operatorType === OperatorType.Recycle && (
            <th className={styles.grade}>
              {t('pre_regist_resource_select.grade')}
            </th>
          )}
          <th
            className={
              operatorType === OperatorType.Recycle
                ? styles.resourceIdCol
                : styles.resourceId
            }
          >
            {t('pre_regist_resource_select.resource_id')}
          </th>
          <th className={styles.weight}>
            {t('pre_regist_resource_select.weight')}
          </th>
          <th className={styles.inputWeight}>
            {t('pre_regist_resource_select.input_weight')}
          </th>
        </tr>
      </thead>

      <tbody>
        {displayResourceData.length > 0 ? (
          displayResourceData.map((item, index) => {
            const isItemDisabled = isActionDisabled(item);

            return (
              <tr
                key={index}
                className={mergeClasses({
                  [styles.disabledRow]: isItemDisabled,
                })}
              >
                <td className={styles.selection}>
                  {!isItemDisabled && (
                    <CustomCheckBox
                      id={item.processingObjectId}
                      checked={Boolean(
                        selectedResources[item.processingObjectId]?.checked
                      )}
                      onChange={(e) => handleCheckItem(e.target.checked, item)}
                      darkMode
                    />
                  )}
                </td>
                <td className={styles.dateTimeFilter}>
                  {getFormatDateWithCurrentTimezone(
                    item.receivedDatetime,
                    DATE_FORMAT.slaYMDHm
                  )}
                </td>
                <td className={styles.receiveFilter}>{item.site.name}</td>
                <td className={styles.materialNameFilter}>
                  {item.materialName}
                </td>
                {operatorType === OperatorType.Recycle && (
                  <td className={styles.grade}>{item.grade?.name}</td>
                )}
                <td
                  className={
                    operatorType === OperatorType.Recycle
                      ? styles.resourceIdCol
                      : styles.resourceId
                  }
                >
                  {item.processingObjectId}
                </td>
                <td className={styles.weight}>
                  {item.remainingWeight}&nbsp;kg
                </td>
                <td className={styles.inputWeight}>
                  <div
                    className={mergeClasses(
                      styles.inputWeightCol,
                      styles.inputWeightColInput
                    )}
                  >
                    <input
                      className={mergeClasses(styles.input, {
                        [styles.inputError]:
                          selectedResources[item.processingObjectId]?.hasError,
                        [styles.disabled]: isItemDisabled,
                      })}
                      disabled={isItemDisabled}
                      type="text"
                      onChange={(e) => handleChangeInput(e.target.value, item)}
                      value={
                        selectedResources[item.processingObjectId]?.weight || ''
                      }
                    />

                    <span className={styles.unit}>{'kg'}</span>
                  </div>

                  {selectedResources[item.processingObjectId]?.hasError && (
                    <ErrorText
                      text={
                        t('pre_regist_resource_select.message_error', {
                          field: item.remainingWeight,
                        }) || ''
                      }
                      className={styles.error}
                    />
                  )}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td
              colSpan={operatorType === OperatorType.Recycle ? 8 : 7}
              className={styles.msgError}
            >
              {t('messages.M_008')}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default ResourceTable;
