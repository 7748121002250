// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { ErrorText } from 'components/atoms/ErrorText';
import { Label } from 'components/atoms/Label';
import SuggestionList from 'components/atoms/SuggestionList';
import { FieldValidator } from 'final-form';
import React, {
  CSSProperties,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Field } from 'react-final-form';
import { mergeClasses } from 'util/commons';
import styles from './InputDataListField.module.scss';

// temp, will refactor in implement api
export interface Suggestion {
  name: string;
}

interface Props {
  name: string;
  className?: string;
  label?: string | null;
  value?: string;
  required?: boolean;
  hint?: React.ReactNode;
  validate?: FieldValidator<string>;
  type?: string;
  placeholder?: string | null;
  readOnly?: boolean;
  disabled?: boolean;
  dataSuggestions?: Suggestion[]; // temp, will refactor in implement api
  onSelectSuggestItem?: (value: string) => void;
  maxlength?: number;
  handleChange?: (value: string) => void;
  subFix?: ReactElement;
  validRegex?: RegExp;
  id?: string;
  description?: string;
  hidePlaceholderOnFocus?: boolean;
  onWheel?: () => void;
  endExtraText?: string | React.ReactNode;
  circleNumber?: number;
  isDarkMode?: boolean;
  normalizeNumber?: boolean;
  inputStyle?: CSSProperties;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export function InputDataListField({
  label,
  name,
  value,
  type,
  placeholder,
  readOnly,
  disabled,
  className,
  validate,
  required,
  dataSuggestions,
  onSelectSuggestItem,
  maxlength,
  handleChange,
  subFix,
  validRegex,
  id,
  description,
  hidePlaceholderOnFocus,
  onWheel,
  endExtraText,
  circleNumber,
  isDarkMode,
  normalizeNumber,
  inputStyle,
  inputRef,
}: Props) {
  const [inputValue, setInputValue] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState<Suggestion[]>(
    []
  );
  const [placeholderState, setPlaceholderState] = useState(placeholder);

  const onClickOutside = () => {
    setFilteredSuggestions([]);
  };

  useEffect(() => {
    if (placeholder) {
      setPlaceholderState(placeholder);
    }
  }, [placeholder]);

  const handleFilterSuggestions = (value: string) => {
    const filteredSuggestions = dataSuggestions?.filter((suggestion) => {
      return suggestion.name?.toLowerCase().includes(value?.toLowerCase());
    });

    setFilteredSuggestions(filteredSuggestions || []);
  };

  const onChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    handleFilterSuggestions(value);
    handleChange && handleChange(value);
  };

  const ref = inputRef ?? useRef<HTMLInputElement>(null);
  const onSelectSuggestion = (value: string) => {
    const selected =
      filteredSuggestions.find((i) => i.name === value)?.name || '';
    setInputValue(selected);
    ref.current?.focus();
    setFilteredSuggestions([]);
    onSelectSuggestItem && onSelectSuggestItem(selected);
  };

  return (
    <Field
      name={name}
      type={type}
      validate={validate}
      value={value}
      dataSuggestions={dataSuggestions}
    >
      {({ input, meta }) => (
        <div
          className={mergeClasses(styles.textInput, className, {
            [styles.darkMode]: !!isDarkMode,
          })}
        >
          {label && (
            <Label
              text={label}
              circleNumber={circleNumber}
              tag={'div'}
              required={required}
            />
          )}
          <div
            className={`${styles.textInputWrap} ${
              subFix ? styles.textInputWrapHasSubfix : ''
            }`}
          >
            <input
              {...input}
              ref={ref}
              id={id}
              name={name}
              placeholder={placeholderState ?? undefined}
              lang="ja"
              readOnly={readOnly}
              disabled={disabled}
              className={mergeClasses({
                error: meta && meta.touched && meta.error,
                [styles.endExtraTextInput]: endExtraText,
              })}
              autoComplete="new-password"
              onWheel={onWheel}
              onChange={(e) => {
                if (
                  validRegex &&
                  e.target.value &&
                  !validRegex?.test(e.target.value)
                )
                  return;
                if (maxlength) {
                  if (e.target.value.length <= maxlength) {
                    input.onChange(e);
                    onChange(e);
                  }
                } else {
                  input.onChange(e);
                  onChange(e);
                }
              }}
              onFocus={() => {
                handleFilterSuggestions(inputValue);

                if (hidePlaceholderOnFocus) {
                  setPlaceholderState(undefined);
                }
              }}
              onBlur={(e) => {
                e.preventDefault();
                input.onBlur(e);

                if (normalizeNumber && e.target?.value) {
                  input.onChange({
                    ...e,
                    target: {
                      ...e.target,
                      value: Number(e.target.value).toString(),
                    },
                  });
                }

                if (hidePlaceholderOnFocus) {
                  setPlaceholderState(placeholder);
                }
              }}
              maxLength={maxlength}
              style={inputStyle}
            />
            {subFix && subFix}
            {endExtraText && (
              <span
                className={mergeClasses(styles.endExtraText, {
                  [styles.endExtraTextDarkMode]: isDarkMode,
                })}
              >
                {endExtraText}
              </span>
            )}
          </div>
          {inputValue &&
            filteredSuggestions &&
            filteredSuggestions.length > 0 && (
              <SuggestionList
                onClickOutside={onClickOutside}
                selectedSuggestion={inputValue}
                onSelectSuggestion={onSelectSuggestion}
                suggestions={filteredSuggestions}
              />
            )}
          {description && (
            <div className={styles.description}>{description}</div>
          )}
          {meta && meta.touched && meta.error && (
            <ErrorText text={meta.error} className={styles.error} />
          )}
        </div>
      )}
    </Field>
  );
}
