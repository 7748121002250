// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './ScaleSelectOperatorCard.scss';

interface Props {
  id: string;
  name: string;
  siteId: string;
  onClick: (id: string) => void;
}

export function ScaleSelectOperatorCard({ name, siteId, onClick }: Props) {
  return (
    <div
      className="scale-select-card operator-card"
      onClick={() => {
        onClick(siteId);
      }}
    >
      {name}
    </div>
  );
}
