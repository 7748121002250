// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export type AdminSideBarState = {
  isInitialState: boolean;
  activeMenuShowedSubMenus: string[];
  totalUnassignedBrands?: number;
};

export type LocalSettingState = {
  admin: {
    sidebar: AdminSideBarState;
  };
};

const initialState: LocalSettingState = {
  admin: {
    sidebar: {
      isInitialState: false,
      activeMenuShowedSubMenus: [],
    },
  },
};

export const localSettingSlice = createSlice({
  name: 'localSetting',
  initialState,
  reducers: {
    setAdminSidebar: (state, action: PayloadAction<AdminSideBarState>) => {
      return {
        ...state,
        admin: {
          ...state.admin,
          sidebar: action.payload,
        },
      };
    },
    resetAdminSidebar: (state) => {
      return {
        ...state,
        admin: {
          ...state.admin,
          sidebar: initialState.admin.sidebar,
        },
      };
    },
    setTotalUnassignedBrands: (state, action: PayloadAction<number>) => {
      state.admin.sidebar.totalUnassignedBrands = action.payload;
    },
  },
});

export const { setAdminSidebar, resetAdminSidebar, setTotalUnassignedBrands } =
  localSettingSlice.actions;

export const selectAdminSidebar = (state: RootState) =>
  state.localSetting.admin.sidebar;

export default localSettingSlice.reducer;
