// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import styles from './index.module.scss';
import { ResourceManagementItem } from 'util/adminResourceManagementTypes';
import EditEmissionResourceForm from 'components/molecules/EditEmissionResourceForm';
import { handleError } from 'util/errorHandler';
import { createAxios } from 'ts/createAxios';
import { SelectOption } from 'components/molecules/SelectField';
import { getSlaYMDhm } from 'ts/formatDate';
import dayjs from 'dayjs';
import { ItemType } from 'util/Types';

interface Props {
  editData: ResourceManagementItem;
  onClose: () => void;
  onUpdateResource: () => void;
}

const EditEmissionResourceDataModal: React.FC<Props> = ({
  editData,
  onClose,
  onUpdateResource,
}) => {
  const api = createAxios();
  const [inputForm, setInputForm] = useState<ResourceManagementItem>();
  const [categories, setCategories] = useState<SelectOption[]>([]);

  const getCategoriesById = async () => {
    try {
      const response = await api.get(`/categories?siteId=${editData.site.id}`);
      setCategories(
        response.data.categories.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        })
      );
    } catch (error) {
      handleError(error);
    }
  };

  const handleEdit = (form: ResourceManagementItem) => {
    api
      .put(`/resources/emissions/${form.id}`, {
        datetime: getSlaYMDhm(form.datetime),
        categoryId: Number(form.categoryId),
        wasteUnitId: Number(form.wasteUnitId),
        weight: Number(form.weight),
      })
      .then(() => {
        onUpdateResource();
      })
      .catch((e) => {
        handleError(e);
      });
  };

  useEffect(() => {
    getCategoriesById();
  }, []);

  useEffect(() => {
    editData &&
      setInputForm({
        ...editData,
        categoryId: String(editData.category.id),
        siteName: editData.site.name,
        operatorName: editData.operator && editData.operator.name,
        wasteUnitId: String(editData.wasteUnit.id),
        datetime: dayjs
          .tz(new Date(editData.datetime).toISOString())
          .format('YYYY-MM-DDTHH:mm'),
        weight:
          editData.wasteUnit.type === ItemType.OPTIONAL
            ? editData.weight
            : editData.wasteUnitQuantity,
      });
  }, [editData]);
  return (
    <div className={styles.editResourceDataModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>資源を編集する</p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <EditEmissionResourceForm
            initForm={inputForm}
            onSubmit={handleEdit}
            categories={categories}
          />
        </div>
      </Modal>
    </div>
  );
};

export default EditEmissionResourceDataModal;
