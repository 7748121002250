// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './PrimaryBtnField.module.scss';
import React from 'react';
import { TextButton } from 'components/atoms/TextButton';

interface Props {
  className?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  isBottom?: boolean;
}

export function PrimaryBtnField({
  text,
  className,
  onClick,
  type,
  disabled,
  isBottom,
}: Props) {
  return (
    <div className={`${styles.primaryBtn} ${className ? className : ''}`}>
      {isBottom ? (
        <button
          className={styles.btnRequest}
          type={type}
          disabled={disabled}
          onClick={onClick}
        >
          {text}
        </button>
      ) : (
        <TextButton
          buttonType={'default'}
          text={text}
          disabled={disabled}
          type={type ? type : undefined}
          onClick={onClick}
        ></TextButton>
      )}
    </div>
  );
}
