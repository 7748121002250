// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import { Suggestion } from 'components/molecules/InputDataListField';
import CreateItemInputForm, {
  CreateItemInputType,
} from 'components/molecules/WasteInputForm';
import { WasteUnitItem } from 'pages/AdminCustomizeItemsDetail';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CreateItemModal.module.scss';
interface Props {
  onClose?: () => void;
  type: CreateItemInputType;
  itemId?: string;
  wasteUnitNames: Suggestion[];
  materialNames: Suggestion[];
  categoryName: string;
  siteName: string;
  handleFetchData: () => void;
  detailItem?: WasteUnitItem;
}
const CreateItemModal: React.FC<Props> = ({
  onClose,
  type,
  wasteUnitNames,
  materialNames,
  categoryName,
  siteName,
  detailItem,
  handleFetchData,
}) => {
  const { t } = useTranslation();
  return (
    <Modal>
      <div className={styles.modalContent}>
        <div className={styles.customTitle}>
          <p className={styles.descriptionTitle}>
            {detailItem
              ? t('modal_item.edit_title')
              : t('common.button.add_item')}
          </p>
          <div className={styles.top}>
            <IconClose onClick={onClose} />
          </div>
        </div>
        <CreateItemInputForm
          type={type}
          wasteUnitNames={wasteUnitNames}
          materialNames={materialNames}
          categoryName={categoryName}
          siteName={siteName}
          detailItem={detailItem}
          handleFetchData={() => {
            handleFetchData();
            onClose && onClose();
          }}
        />
      </div>
    </Modal>
  );
};

export default CreateItemModal;
