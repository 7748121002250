// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import {
  createCompressionSite,
  createEmissionSite,
  createProduceSite,
  createPurchaseSite,
  updateCompressionSite,
  updateEmissionSite,
  updateProducerSite,
  updatePurchaseSite,
} from 'apis/operator_site/siteApi';
import {
  useCreateRecycleMutation,
  useUpdateRecycleMutation,
} from 'apis/site_recycle/siteRecycleApi';
import AdminUploadFileCSVModal from 'components/molecules/AdminUploadFileCSVModal';
import DeleteItemModal from 'components/molecules/DeleteItemForm/DeleteItemModal';
import TableList from 'components/organisms/AdminOperatorSiteTable/AdminOperatorSiteTable';
import CreateUpdateCollectSiteModal from 'components/organisms/CreateUpdateCollectSiteModal';
import CreateUpdateCompressionsSiteModal from 'components/organisms/CreateUpdateCompressionsSiteModal';
import CreateUpdateEmissionSiteModal from 'components/organisms/CreateUpdateEmissionSiteModal';
import CreateUpdateProducerSiteModal from 'components/organisms/CreateUpdateProducerSiteModal';
import CreateUpdatePurchaseSiteModal from 'components/organisms/CreateUpdatePurchaseSiteModal';
import CreateUpdateSiteRecycleModal from 'components/organisms/CreateUpdateSiteRecycleModal/CreateUpdateSiteRecycleModal';
import { AdminTemplate } from 'components/templates/AdminTemplate';
import { getActive } from 'pages/AdminOperatorListManagement/AdminOperatorListManagement';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Path } from 'routes';
import { createAxios } from 'ts/createAxios';
import { DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import { AdminManagementType, ModalType, OperatorType } from 'util/Enums';
import { CustomizeItemBreadcrumbs } from 'util/Types';
import { handleError, isError } from 'util/errorHandler';
import { useQuery } from 'util/queryParams';
import {
  CompressionSiteItem,
  EmissionSiteItem,
  ProducerSiteItem,
  PurchaseSiteItem,
  ResSites,
  SiteDataItem,
  SiteRecycleItem,
} from 'util/siteManagementTypes';

export interface ModalSiteState {
  isOpen: boolean;
  type: ModalType;
  siteEditing?: SiteDataItem;
}
const ListSite = () => {
  const [listOfSites, setListOfSites] = useState<EmissionSiteItem[]>([]);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [total, setTotal] = useState(0);
  const [breadcrumb, setBreadcrumb] = useState<CustomizeItemBreadcrumbs>({
    operatorName: '-',
  });
  const history = useHistory();
  const query = useQuery();
  const type = query.get('type');
  const api = createAxios();
  const { t } = useTranslation();

  const [createUpdateSiteModal, setCreateUpdateSiteModal] =
    useState<ModalSiteState>({
      isOpen: false,
      type: ModalType.add,
    });
  const [isOpenImportCSV, setOpenImportCSV] = useState(false);
  const { operatorId } = useParams<{ operatorId: string; siteId: string }>();
  const [deleteItem, setDeleteItem] = useState<SiteDataItem>();

  const [createSiteRecycle] = useCreateRecycleMutation();
  const [updateSiteRecycle] = useUpdateRecycleMutation();

  const fetchSites = async (
    keyword?: string,
    nextPage?: number,
    forceRefresh?: boolean
  ) => {
    if (!type || !getActive(type as OperatorType)) {
      return;
    }
    const params = new URLSearchParams([['operatorId', operatorId]]);
    try {
      if (keyword) {
        params.append('keyword', keyword);
      }

      if (nextPage || !keyword) {
        params.append('page', String(nextPage || DEFAULT_PAGE));
        params.append('take', String(PAGE_SIZE));
      }
      const response = await api.get<ResSites>('/sites', { params });

      if (keyword) {
        setListOfSites(response.data.sites);
      } else {
        setListOfSites(
          forceRefresh
            ? response.data.sites
            : [...listOfSites, ...response.data.sites]
        );
      }
      setTotal(response.data.total);
      setCurrentPage(response.data.page);
      setBreadcrumb({ operatorName: response.data.operatorName });
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    if (!type || !getActive(type as OperatorType)) {
      history.push(`${Path.adminOperatorListManagement}?type=Emissions`);
    }
  }, [type]);

  useEffect(() => {
    fetchSites('', DEFAULT_PAGE, true);
  }, []);

  const handleClickAdd = () => {
    setCreateUpdateSiteModal({
      isOpen: true,
      type: ModalType.add,
    });
  };

  const handleClickEditSite = (siteEditing: SiteDataItem) => {
    setCreateUpdateSiteModal({
      isOpen: true,
      type: ModalType.update,
      siteEditing: siteEditing,
    });
  };

  //handle modal
  //emission site
  const handleCreateEmisstionSite = (form: EmissionSiteItem) => {
    createEmissionSite({ operatorId, body: form }, () => {
      onCreateSuccess();
    });
  };
  const handleUpdateEmistionSite = (form: EmissionSiteItem) => {
    updateEmissionSite({ form }, () => {
      onUpdateSuccess();
    });
  };
  // end emission site
  // end delivery site

  const handleCreateCompressionSite = (form: CompressionSiteItem) => {
    createCompressionSite({ operatorId, body: form }, () => {
      onCreateSuccess();
    });
  };
  const handleUpdateCompressionSite = (form: CompressionSiteItem) => {
    updateCompressionSite({ form }, () => {
      onUpdateSuccess();
    });
  };

  const handleCreatePurchaseSite = (form: PurchaseSiteItem) => {
    createPurchaseSite({ operatorId, body: form }, () => {
      onCreateSuccess();
    });
  };

  const handleCreateProducerSite = (form: ProducerSiteItem) => {
    createProduceSite({ operatorId, form }, () => {
      onCreateSuccess();
    });
  };

  const handleUpdatePurchaseSite = (form: PurchaseSiteItem) => {
    updatePurchaseSite({ form }, () => {
      onUpdateSuccess();
    });
  };

  const handleUpdateProduceSite = (form: ProducerSiteItem) => {
    updateProducerSite({ form }, () => {
      onUpdateSuccess();
    });
  };

  const handleClickDeleteSite = (id: string | number | undefined) => {
    api
      .delete(`/sites/${id}`)
      .then(() => {
        fetchSites('', DEFAULT_PAGE, true);
        toast.success(t('messages.M_012'));
        setDeleteItem(undefined);
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const onCreateSuccess = () => {
    fetchSites('', DEFAULT_PAGE, true);
    setCreateUpdateSiteModal({
      isOpen: false,
      type: ModalType.add,
    });
    toast.success(t('messages.M_010'));
  };

  const onUpdateSuccess = () => {
    fetchSites('', DEFAULT_PAGE, true);
    setCreateUpdateSiteModal({
      isOpen: false,
      type: ModalType.add,
    });
    toast.success(t('messages.M_011'));
  };

  const handleCreateEmisstionSiteRecycle = (form: SiteRecycleItem) => {
    const request = {
      data: {
        ...form,
        wastePermit: form.wastePermit,
      },
      operatorId: operatorId,
    };
    createSiteRecycle(request).then((result) => {
      if (!isError(result)) onCreateSuccess();
    });
  };

  const handleUpdateEmisstionSiteRecycle = (
    form: SiteRecycleItem,
    siteEditing: SiteDataItem
  ) => {
    updateSiteRecycle({
      data: form,
      siteId: siteEditing.id,
    }).then((result) => {
      if (!isError(result)) onUpdateSuccess();
    });
  };

  return (
    <AdminTemplate
      h1={t('site_management.heading')}
      active={getActive(type as OperatorType)}
      breadcrumb={[
        {
          label: getActive(type as OperatorType) || '-',
          href: `${Path.adminOperatorListManagement}${window.location.search}`,
        },
        { label: breadcrumb.operatorName || '-' },
      ]}
    >
      {
        <TableList
          type={AdminManagementType.site}
          dataList={listOfSites}
          totalItems={total}
          currentPage={currentPage}
          updateData={fetchSites}
          onClickAddSite={handleClickAdd}
          onClickEditSite={handleClickEditSite}
          onClickDeleteSite={(item) => {
            setDeleteItem(item);
          }}
          onClickImportSiteCSV={() => {
            setOpenImportCSV(true);
          }}
        />
      }
      {createUpdateSiteModal.isOpen && (
        <>
          {type === OperatorType.Recycle && (
            <CreateUpdateSiteRecycleModal
              onClose={() => {
                setCreateUpdateSiteModal({
                  isOpen: false,
                  type: ModalType.add,
                });
              }}
              createUpdateSiteRecycleModal={createUpdateSiteModal}
              onCreateRecycleSite={handleCreateEmisstionSiteRecycle}
              onUpdateRecycleSite={handleUpdateEmisstionSiteRecycle}
            />
          )}
          {type === OperatorType.Emissions && (
            <CreateUpdateEmissionSiteModal
              createUpdateSiteModal={createUpdateSiteModal}
              onClose={() => {
                setCreateUpdateSiteModal({
                  isOpen: false,
                  type: ModalType.add,
                });
              }}
              onCreateSite={handleCreateEmisstionSite}
              onUpdateSite={handleUpdateEmistionSite}
            />
          )}
          {type === OperatorType.Compressions && (
            <CreateUpdateCompressionsSiteModal
              createUpdateSiteModal={createUpdateSiteModal}
              onClose={() => {
                setCreateUpdateSiteModal({
                  isOpen: false,
                  type: ModalType.add,
                });
              }}
              onCreateCompressionSite={handleCreateCompressionSite}
              onUpdateCompressionSite={handleUpdateCompressionSite}
            />
          )}
          {type === OperatorType.Collects && (
            <CreateUpdateCollectSiteModal
              onCreateSuccess={onCreateSuccess}
              createUpdateModal={createUpdateSiteModal}
              onClose={() => {
                setCreateUpdateSiteModal({
                  isOpen: false,
                  type: ModalType.add,
                });
              }}
              operatorId={operatorId}
              onUpdateSuccess={onUpdateSuccess}
            />
          )}
          {type === OperatorType.Purchase && (
            <CreateUpdatePurchaseSiteModal
              createUpdateModal={createUpdateSiteModal}
              onClose={() => {
                setCreateUpdateSiteModal({
                  isOpen: false,
                  type: ModalType.add,
                });
              }}
              handleCreatePurchaseSite={handleCreatePurchaseSite}
              handleUpdatePurchaseSite={handleUpdatePurchaseSite}
            />
          )}
          {type === OperatorType.Producer && (
            <CreateUpdateProducerSiteModal
              createUpdateSiteModal={createUpdateSiteModal}
              onClose={() => {
                setCreateUpdateSiteModal({
                  isOpen: false,
                  type: ModalType.add,
                });
              }}
              onCreateProducerSite={handleCreateProducerSite}
              onUpdateProducerSite={handleUpdateProduceSite}
            />
          )}
        </>
      )}
      {deleteItem && (
        <DeleteItemModal
          item={deleteItem}
          onClose={() => {
            setDeleteItem(undefined);
          }}
          onHandleDelete={handleClickDeleteSite}
        />
      )}
      {isOpenImportCSV && (
        <AdminUploadFileCSVModal
          onClose={() => {
            setOpenImportCSV(false);
          }}
          onSubmitImportFile={() => {
            setOpenImportCSV(false);
            fetchSites('', DEFAULT_PAGE, true);
          }}
          endPoint={`sites/emissions/import?operatorId=${operatorId}`}
        />
      )}
    </AdminTemplate>
  );
};

export default ListSite;
