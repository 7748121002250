// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  onClick?: () => void;
}

export const IconBarcodeV2 = ({ onClick }: Props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 20V4H4.5V20H2.5ZM5.5 20V4H7.5V20H5.5ZM8 4V20H11V4H8ZM12 20V4H13V20H12ZM14 4V20H17V4H14ZM18 20V4H19V20H18ZM20.5 4V20H22.5V4H20.5Z"
        fill="#6F7175"
      />
    </svg>
  );
};
