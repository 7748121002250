// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
const IconListAccept = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.79289 4.29289C9.98043 4.10536 10.2348 4 10.5 4H12.5C12.7652 4 13.0196 4.10536 13.2071 4.29289C13.3946 4.48043 13.5 4.73478 13.5 5C13.5 5.26522 13.3946 5.51957 13.2071 5.70711C13.0196 5.89464 12.7652 6 12.5 6H10.5C10.2348 6 9.98043 5.89464 9.79289 5.70711C9.60536 5.51957 9.5 5.26522 9.5 5C9.5 4.73478 9.60536 4.48043 9.79289 4.29289ZM10.5 2C9.70435 2 8.94129 2.31607 8.37868 2.87868C8.05928 3.19808 7.81933 3.5821 7.67157 4H6.5C5.70435 4 4.94129 4.31607 4.37868 4.87868C3.81607 5.44129 3.5 6.20435 3.5 7V19C3.5 19.7957 3.81607 20.5587 4.37868 21.1213C4.94129 21.6839 5.70435 22 6.5 22H16.5C17.2957 22 18.0587 21.6839 18.6213 21.1213C19.1839 20.5587 19.5 19.7957 19.5 19V15.3649L17.5 17.3649V19C17.5 19.2652 17.3946 19.5196 17.2071 19.7071C17.0196 19.8946 16.7652 20 16.5 20H6.5C6.23478 20 5.98043 19.8946 5.79289 19.7071C5.60536 19.5196 5.5 19.2652 5.5 19V7C5.5 6.73478 5.60536 6.48043 5.79289 6.29289C5.98043 6.10536 6.23478 6 6.5 6H7.67157C7.81933 6.4179 8.05928 6.80192 8.37868 7.12132C8.94129 7.68393 9.70435 8 10.5 8H12.5C13.2956 8 14.0587 7.68393 14.6213 7.12132C14.9407 6.80192 15.1807 6.4179 15.3284 6H16.5C16.7652 6 17.0196 6.10536 17.2071 6.29289C17.3946 6.48043 17.5 6.73478 17.5 7V11.0795L18.9287 9.65087C19.1007 9.47882 19.294 9.34087 19.5 9.23704V7C19.5 6.20435 19.1839 5.44129 18.6213 4.87868C18.0587 4.31607 17.2956 4 16.5 4H15.3284C15.1807 3.5821 14.9407 3.19808 14.6213 2.87868C14.0587 2.31607 13.2956 2 12.5 2H10.5ZM10.5446 15H8.51C7.95772 15 7.51 15.4477 7.51 16C7.51 16.5523 7.95772 17 8.51 17H12.024L11.1509 16.1269C10.8306 15.8066 10.6285 15.4129 10.5446 15ZM8 10C7.44772 10 7 10.4477 7 11C7 11.5523 7.44772 12 8 12H11.2121H13.9142C14.4665 12 14.9142 11.5523 14.9142 11C14.9142 10.4477 14.4665 10 13.9142 10H11.2121H8Z"
        fill="currentColor"
      />
      <path
        d="M12.5 14.6L14.9 17L20.9 11"
        stroke="#E8E8E8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconListAccept;
