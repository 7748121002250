// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { DefaultTFuncReturn } from 'i18next';
import { IconDelete } from '../icons/IconDelete';
import styles from './styles.module.scss';

type TableFilterExpandedValueProps = {
  value?: React.ReactNode;
  valueCondition?: boolean;
  clearCondition?: boolean;
  allowClear?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void;
  placeholder?: string | DefaultTFuncReturn;
  icon?: React.ReactNode;
};

const TableFilterExpandedValue: React.FC<TableFilterExpandedValueProps> = ({
  value,
  valueCondition,
  clearCondition,
  allowClear,
  onChange,
  placeholder,
  icon,
}) => {
  return (
    <div className={styles.valueWrapper}>
      <div className={styles.value}>
        {valueCondition && value ? value : placeholder}
      </div>
      {allowClear && clearCondition ? (
        <IconDelete
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onChange?.(undefined);
          }}
          stroke="#fe3c61"
        />
      ) : (
        icon
      )}
    </div>
  );
};

TableFilterExpandedValue.defaultProps = {
  valueCondition: true,
  allowClear: true,
  clearCondition: true,
};

export default TableFilterExpandedValue;
