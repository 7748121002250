import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Floor } from 'components/molecules/SelectFloorCard';
import { Section } from 'components/molecules/SelectSectionCard';
import { Tenant } from 'components/molecules/SelectTenantCard';
import { CategoryItem, TareData } from 'util/Types';
import { RootState } from '../store';

// 拠点情報を保持する State
export type SiteInfoState = {
  sections: Section[] | null;
  forceReloadSection: boolean;
  tenants: Tenant[] | null;
  floors: Floor[] | null;
  categories: CategoryItem[] | null;
  forceReloadCategories: number[];
  tare: TareData | null;
  tenantInitialized?: boolean;
};

const initialState: SiteInfoState = {
  sections: null,
  forceReloadSection: false,
  tenants: null,
  floors: null,
  categories: null,
  forceReloadCategories: [],
  tare: null,
};

export const siteInfoSlice = createSlice({
  name: 'siteInfo',
  initialState,
  reducers: {
    resetSiteInfo: (state) => {
      state = initialState;
      return state;
    },
    setSections: (state, action: PayloadAction<Section[]>) => {
      state.sections = action.payload || [];
    },
    setTenants: (state, action: PayloadAction<Tenant[]>) => {
      state.tenants = action.payload || [];
      state.tenantInitialized = true;
    },
    setFloors: (state, action: PayloadAction<Floor[]>) => {
      state.floors = action.payload || [];
    },
    setCategories: (state, action: PayloadAction<CategoryItem[]>) => {
      state.categories = action.payload || [];
    },
    setForceReloadCategories: (state, action: PayloadAction<number[]>) => {
      state.forceReloadCategories = action.payload || [];
    },
    subtractForceCategory: (state, action: PayloadAction<number>) => {
      state.forceReloadCategories =
        state.forceReloadCategories?.filter((x) => x !== action.payload) || [];
    },
    setForceReloadSection: (state, action: PayloadAction<boolean>) => {
      state.forceReloadSection = action.payload;
    },
    setTares: (state, action: PayloadAction<TareData>) => {
      state.tare = action.payload || { page: 0, total: 0, tares: [] };
    },
  },
});

export const {
  resetSiteInfo,
  setSections,
  setTenants,
  setFloors,
  setCategories,
  setTares,
  setForceReloadCategories,
  subtractForceCategory,
  setForceReloadSection,
} = siteInfoSlice.actions;

export const selectSections = (state: RootState) =>
  state.siteInfo.sections || [];
export const selectTenants = (state: RootState) => state.siteInfo.tenants || [];
export const selectFloors = (state: RootState) => state.siteInfo.floors || [];
export const selectCategories = (state: RootState) =>
  state.siteInfo.categories || [];
export const selectTare = (state: RootState) => state.siteInfo.tare;
export const selectForceReloadCategories = (state: RootState) =>
  state.siteInfo.forceReloadCategories;
export const selectForceReloadSections = (state: RootState) =>
  state.siteInfo.forceReloadSection;

export const selectTenantsWithNoSection = (state: RootState) =>
  state.siteInfo.tenants?.filter((t) => !t.section) || [];

export const selectTenantsWithSections = (state: RootState) =>
  state.siteInfo.tenants?.filter((t) => t.section) || [];

export const selectSectionsWithNoTenant = (state: RootState) => {
  const sections = state.siteInfo.sections || [];
  const tenants = state.siteInfo.tenants || [];
  return sections
    .map((s) => {
      return {
        ...s,
        tenants: tenants.filter((t) => t.section?.id === s.id),
      };
    })
    .filter((s) => s.tenants.length > 0);
};

export const selectSectionsWithTenants = (state: RootState) => {
  const sections = state.siteInfo.sections || [];
  const tenants = state.siteInfo.tenants || [];
  const floors = state.siteInfo.floors || [];
  const floorWithTenants = floors
    .map((i) => {
      return {
        ...i,
        tenants: tenants.filter((t) => {
          return (
            t.floor &&
            t.section &&
            t.floor?.id === i.id &&
            t.section?.id === i.sectionId
          );
        }),
      };
    })
    .filter((t) => t.tenants.length > 0);

  return sections
    .map((i) => {
      return {
        ...i,
        floors: floorWithTenants.filter((f) => f.sectionId === i.id),
      };
    })
    .filter((s) => {
      return s.floors.length > 0 || tenants.find((i) => i.section?.id === s.id);
    });
};

export const selectTenantsInitialized = (state: RootState) =>
  state.siteInfo.tenantInitialized;

export const isTenantInfoLoaded = (state: RootState) =>
  state.siteInfo.sections && state.siteInfo.tenants && state.siteInfo.floors;

export default siteInfoSlice.reducer;
