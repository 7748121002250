// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import styles from './index.module.scss';
import { ResourceManagementItem } from 'util/adminResourceManagementTypes';
import EditRecycleResourceForm from 'components/molecules/EditRecycleResourceForm';
import { createAxios } from 'ts/createAxios';
import { SelectOption } from 'components/molecules/SelectField';
import { handleError } from 'util/errorHandler';

interface Props {
  editData: ResourceManagementItem;
  onClose: () => void;
  onUpdateSuccess: () => void;
}

const EditRecycleResourceDataModal: React.FC<Props> = ({
  editData,
  onClose,
  onUpdateSuccess,
}) => {
  const api = createAxios();
  const commonsApi = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const [inputForm, setInputForm] = useState<ResourceManagementItem>();
  const [data, setData] = useState<{
    grades: SelectOption[];
    types: SelectOption[];
  }>();
  useEffect(() => {
    editData &&
      data &&
      setInputForm({
        ...editData,
        siteName: editData.site.name,
        operatorName: editData.operator.name,
        // need update after BE update api
        gradeId: editData.grade?.id,
        materialTypeId: String(editData.materialType?.id),
      });
  }, [editData, data]);

  useEffect(() => {
    Promise.all([
      commonsApi.get<{ id: number; name: string }[]>(
        `/commons/grades?siteId=${editData.site.id}`
      ),
      commonsApi.get<{ id: number; name: string }[]>(
        `/commons/material-types?siteId=${editData.site.id}&type=Recycle`
      ), // update later
    ]).then((res) => {
      setData({
        grades: res[0].data.map((i) => {
          return { label: i.name, value: i.id };
        }),
        types: res[1].data.map((i) => {
          return { label: i.name, value: i.id };
        }),
      });
    });
  }, []);

  const handleEdit = (form: ResourceManagementItem) => {
    api
      .put(`/resources/recycle/${form.id}`, {
        weight: form.weight,
        gradeId: Number(form.gradeId),
        materialTypeId: Number(form.materialTypeId),
      })
      .then(() => {
        onUpdateSuccess();
      })
      .catch((e) => {
        handleError(e);
      });
  };

  return (
    <div className={styles.editResourceDataModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>資源を編集する</p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <EditRecycleResourceForm
            initForm={inputForm}
            onSubmit={handleEdit}
            grades={data?.grades || []}
            types={data?.types || []}
          />
        </div>
      </Modal>
    </div>
  );
};

export default EditRecycleResourceDataModal;
