// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import snakeCase from 'lodash/snakeCase';
import { useTranslation } from 'react-i18next';
import { RateWeight } from 'types/dashboard';
import { convertWeight, normalizeNumber } from 'util/commons';
import { UnitType } from 'util/constants/dashboard';
import styles from './ProcessInfoBoxOrganism.module.scss';

const ProcessInfoBoxOrganism = ({
  unitType,
  rateWeight,
}: {
  unitType: UnitType;
  rateWeight?: RateWeight[];
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.processInfoBox}>
      {rateWeight?.map((item, i) => {
        return (
          <div className={styles.generalItem} key={i}>
            <div className={styles.generalItemWrapper}>
              <p className={styles.label}>
                {t(`purchase_page.dashboard.${snakeCase(item.stageName)}`)}
              </p>
              <div className={styles.quantityUnit}>
                <p className={styles.quantity}>
                  {item.weight
                    ? normalizeNumber({
                        value: convertWeight(unitType, Number(item.weight)),
                      })
                    : 0}
                </p>
                <p className={styles.unit}>{unitType}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProcessInfoBoxOrganism;
