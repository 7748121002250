// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import CreateBrandItemInputForm, {
  BrandInput,
} from 'components/molecules/CreateBrandItemInputForm';
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import { ModalBrandState } from 'pages/AdminBrandOwnerManagement';
import { ModalType } from 'util/Enums';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  createUpdateBrandModal: ModalBrandState;
  onClose?: () => void;
  onCreateBrand: (createFloorForm: BrandInput) => void;
  onUpdateBrand: (updateFloorForm: BrandInput) => void;
}
const CreateBrandItemModal: React.FC<Props> = ({
  createUpdateBrandModal,
  onClose,
  onCreateBrand,
  onUpdateBrand,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.createBrandItemModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {createUpdateBrandModal.type === ModalType.add
                ? t('modal_create_brand.title_create')
                : t('modal_create_brand.title_edit')}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <CreateBrandItemInputForm
            createUpdateBrandModal={createUpdateBrandModal}
            onCreateBrand={onCreateBrand}
            onUpdateBrand={onUpdateBrand}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateBrandItemModal;
