import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const CONNECT_SCALE_STORAGE_KEY = 'CONNECT_TO_SCALE';

enum ScaleConnectionStatus {
  ON = 'on',
  OFF = 'off',
}

export const useConnectToScale = () => {
  const { t } = useTranslation();

  const [isTurnedOn, setIsTurnedOn] = useState<boolean>(
    localStorage.getItem(CONNECT_SCALE_STORAGE_KEY) !==
      ScaleConnectionStatus.OFF
  );

  const updateScaleConnectionStatus = (isInitial?: boolean) => {
    try {
      if (!isInitial) {
        window?.connectWithScale?.(!isTurnedOn);

        localStorage.setItem(
          CONNECT_SCALE_STORAGE_KEY,
          isTurnedOn ? ScaleConnectionStatus.OFF : ScaleConnectionStatus.ON
        );
        setIsTurnedOn(!isTurnedOn);
      } else {
        window?.connectWithScale?.(
          localStorage.getItem(CONNECT_SCALE_STORAGE_KEY) !==
            ScaleConnectionStatus.OFF
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e?.message?.includes?.('Android')) {
        if (!isInitial) {
          toast.error(t('emission_input.message_scale_not_supported'));
        } else {
          setIsTurnedOn(false);
        }
      } else if (e.message) {
        alert(e.message);
      }
    }
  };

  useEffect(() => {
    updateScaleConnectionStatus(true);
  }, []);

  return {
    scaleConnected: isTurnedOn,
    handleChangeScaleConnectionStatus: () => updateScaleConnectionStatus(),
  };
};
