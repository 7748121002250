// gkc_hash_code : 596AVJ0U6O4MG74XBQH62VSQ6T
import { uniqBy } from 'lodash';
import {
  TGradeByIdRequest,
  TGradeByIdResponse,
  TGradeByName,
  TMaterial,
} from 'types/purchase';
import { ProvincesDistricts } from 'util/Types';
import { API_ENDPOINTS } from 'util/endPoints';
import {
  AcceptanceGrade,
  GradeCommon,
  MaterialType,
  PostalCodeData,
  RecycleMachine,
} from 'util/siteManagementTypes';
import { commonUrlApi } from './siteApi';

const addTagTypes = ['category'] as const;

const categoryApi = commonUrlApi
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchLocation: build.query<ProvincesDistricts, boolean>({
        query: (isEmissionsType: boolean) => ({
          url: 'commons/provinces-districts',
          method: 'get',
          params: { isEmissionsType },
        }),
        transformResponse: (data: PostalCodeData[]) => {
          const province = uniqBy(data.map((item) => item.province)) || [];
          const district = uniqBy(data.map((item) => item.district)) || [];
          return {
            province,
            district,
          };
        },
        providesTags: ['category'],
      }),
      fetchAcceptanceGrades: build.query<string[], void>({
        query: () => ({
          url: 'commons/acceptance-grades',
          method: 'GET',
        }),
        transformResponse(baseQueryReturnValue: AcceptanceGrade[]) {
          return baseQueryReturnValue.map((item) => item.acceptanceGrade);
        },
        providesTags: ['category'],
      }),
      fetchMateriaTypelNames: build.query<string[], void>({
        query: () => ({
          url: 'commons/material-types/name',
          method: 'GET',
        }),
        transformResponse(baseQueryReturnValue: MaterialType[]) {
          return baseQueryReturnValue.map((item) => item.materialType);
        },
        providesTags: ['category'],
      }),
      fetchGrades: build.query<string[], TGradeByName>({
        query: (params: TGradeByName) => {
          return {
            url: API_ENDPOINTS.COMMON_GRADES_NAME,
            method: 'GET',
            params,
          };
        },
        transformResponse(baseQueryReturnValue: GradeCommon[]) {
          return baseQueryReturnValue.map((item) => item.grade);
        },
        providesTags: ['category'],
      }),
      fetchRecyleMachineSuggestion: build.query<RecycleMachine[], void>({
        query: () => ({
          url: 'commons/recycle-machines',
          method: 'GET',
        }),
        providesTags: ['category'],
      }),
      fetchGradesByRecycleChain: build.query<
        TGradeByIdResponse[],
        TGradeByIdRequest
      >({
        query: (params) => ({
          url: API_ENDPOINTS.COMMON_GRADES_NAME,
          method: 'GET',
          params,
        }),
      }),

      fetchAllMaterial: build.query<TMaterial[], boolean>({
        query: (isEmissionAll: boolean) => ({
          method: 'GET',
          url: API_ENDPOINTS.COMMON_MATERIAL_NAME,
          params: { isEmissionAll },
        }),
      }),
    }),
  });

export const {
  useFetchAcceptanceGradesQuery,
  useFetchMateriaTypelNamesQuery,
  useFetchLocationQuery,
  useFetchGradesQuery,
  useFetchRecyleMachineSuggestionQuery,
  useLazyFetchGradesByRecycleChainQuery,
  useFetchAllMaterialQuery,
} = categoryApi;
