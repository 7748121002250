// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './CompressionDeliveryContent.module.scss';

type Props = {
  contents: string;
};

export const CompressionDeliveryContent = (props: Props) => {
  return (
    <div className={styles.content}>
      <div>：</div>
      <div
        dangerouslySetInnerHTML={{
          __html: props.contents && props.contents.replace(/\n/g, '<br />'),
        }}
      ></div>
    </div>
  );
};
