// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e?: any) => void;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const IconDeleteTare: React.FC<Props> = ({ onClick }) => {
  return (
    <svg
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.3333 36.6667V44.6667M40.6667 36.6667V44.6667M27.3333 31.3333H48.6667M47.3333 31.3333L46.1773 47.5227C46.1294 48.1954 45.8284 48.8251 45.3348 49.2848C44.8412 49.7444 44.1918 50 43.5173 50H32.4827C31.8082 50 31.1588 49.7444 30.6652 49.2848C30.1716 48.8251 29.8706 48.1954 29.8227 47.5227L28.6667 31.3333H47.3333ZM42 31.3333V27.3333C42 26.9797 41.8595 26.6406 41.6095 26.3905C41.3594 26.1405 41.0203 26 40.6667 26H35.3333C34.9797 26 34.6406 26.1405 34.3905 26.3905C34.1405 26.6406 34 26.9797 34 27.3333V31.3333H42Z"
        stroke="#F75354"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1"
        y="1"
        width="74"
        height="74"
        rx="37"
        stroke="#F75354"
        strokeWidth="2"
      />
    </svg>
  );
};
