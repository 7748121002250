// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

export const messageErrors = {
  M_003: 'インターネット接続がありません。',
  M_004: 'この品目カテゴリはシステムに存在しません。',
  M_006: 'この画面にアクセスする権限がありません。',
  M_008: 'データがありません。',
  M_012: '削除しました。',
  M_013: 'システムエラーが発生しました。',
  M_015: 'この{0}は既に登録されています。',
  M_027: 'このアイテムを編集・削除できません。',
  M_028: '指定されたファイル形式をアップロードしてください。',
  M_010: '登録しました。',
  M_011: '保存しました。',
  M_007: 'を入力してください。',
  M_009: '{0}の形式が正しくありません。',
  M_017: 'ファイルはJPEG・ PNG・HEIC 形式を選択して下さい。',
  M_018: 'ファイルのサイズが大きすぎます。{0}より小さい値を入力してください。',
  M_024: '1MB以下のファイルをアップロードしてください。',
  M_026: 'CSVのインポートが完了しました。',
  M_033: 'ファイルはCSV形式を選択して下さい。',
  M_038: 'インポートに失敗しました',
  M_O68: '都道府県中に市区町村は10個まで登録できます。',
  M_109: 'メール送信を処理中です。完了までお待ちください。',
};
