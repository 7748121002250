// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { updateTenantManagement } from 'apis/tenants';
import { BottomField } from 'components/molecules/BottomField';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { useEffect, useRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { selectTenants, setTenants } from 'redux/slices/siteInfoSlice';
import { selectUser } from 'redux/slices/userSlice';
import { Path } from 'routes';
import { createAxios } from 'ts/createAxios';
import { DbManager } from 'util/DbManager';
import { TenantAuthen } from 'util/Enums';
import { API_ENDPOINTS } from 'util/endPoints';
import { handleError } from 'util/errorHandler';
import useGetDevicePlatform from 'util/hooks/useGetDevicePlatform';
import styles from './TenantManagement.module.scss';
import EditableTableCell, {
  EditableTableCellProps,
  EditableTableCellValue,
} from 'components/atoms/EditableTableCell';
import { Option, TenantUpdateParams } from 'util/Types';
import { mergeClasses, validateWithZod } from 'util/commons';
import zod from 'zod';
import { REGEX_OBJECT } from 'util/ConstantValues';
import _debounce from 'lodash/debounce';
import { useScrollToFirstError } from 'util/hooks/useScrollToFirstError';
import { selectLoading } from 'redux/slices/loadingSlice';
import TableFilterMultiSelect from 'components/molecules/TableFilterMultiSelect';

const schema = zod.array(
  zod.object({
    barcode: zod
      .string()
      .regex(REGEX_OBJECT.nativeNumberRegex)
      .optional()
      .nullable(),
    icCards: zod
      .array(
        zod.object({
          id: zod.number().optional(),
          card: zod.string().optional(),
        })
      )
      .optional()
      .nullable(),
    contactName: zod
      .string({
        message: 'messages.M_007',
      })
      .trim()
      .min(1, {
        message: 'messages.M_007',
      }),
    contactEmail: zod
      .string({
        message: 'messages.M_007',
      })
      .trim()
      .regex(REGEX_OBJECT.email, {
        message: 'messages.M_invalid_email',
      })
      .min(1, {
        message: 'messages.M_007',
      }),
  })
);

const TenantManagement: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tenants = useSelector(selectTenants);
  const user = useSelector(selectUser);
  const history = useHistory();
  const device = useGetDevicePlatform();

  const [editing, setEditing] = useState<boolean>(false);
  const [tenantsForm, setTenantsForm] = useState<TenantUpdateParams[]>([
    ...tenants,
  ]);
  const [errors, setErrors] = useState<Record<string, string>>();
  const dirtyFieldsRef = useRef<string[]>([]);
  const { offlineInitialized } = useSelector(selectLoading);
  const [filter, setFilter] = useState<{
    sectionNames?: string[];
    floorNames?: string[];
  }>({});

  const { sectionOptions, floorOptions } = useMemo(() => {
    const sections = new Map<string, Option>();
    const floors = new Map<string, Option>();
    for (const { section, floor } of tenants) {
      if (section) {
        sections.set(section.name, {
          label: section.name,
          value: section.name,
        });
      }

      if (floor) {
        floors.set(floor.name, {
          label: floor.name,
          value: floor.name,
        });
      }
    }

    const sectionOptions = !sections.size
      ? []
      : [
          ...Array.from(sections.values()),
          {
            label: t('tenant_management.no_section'),
            value: '',
          },
        ];
    const floorOptions = !floors.size
      ? []
      : [
          ...Array.from(floors.values()),
          {
            label: t('tenant_management.no_floor'),
            value: '',
          },
        ];

    return {
      sectionOptions,
      floorOptions,
    };
  }, [t, tenants]);

  const fetchTenants = () =>
    createAxios()
      .get(API_ENDPOINTS.TENANTS, {
        params: {
          siteId: user.siteId,
        },
      })
      .then(async (response) => {
        dispatch(setTenants(response.data.tenants));

        if (offlineInitialized) {
          await DbManager.tenants.clear();
          await DbManager.tenants.bulkAdd(response.data.tenants);
        }
      })
      .catch((error) => handleError(error));

  useEffect(() => {
    fetchTenants();
  }, []);

  const { setScrollToFirstError } = useScrollToFirstError({
    errors,
  });

  useEffect(() => {
    const dataSubset = tenants.filter(
      ({ section, floor }) =>
        (!filter.sectionNames?.length ||
          filter.sectionNames.includes(section ? section.name : '')) &&
        (!filter.floorNames?.length ||
          filter.floorNames.includes(floor ? floor.name : ''))
    );

    if (editing) {
      setTenantsForm(
        dataSubset.map((tenant) => ({
          ...tenant,
          ...(user.originalTenantAuthen === TenantAuthen.ICCard &&
            tenant.icCards?.length && {
              icCards: [...tenant.icCards, { card: '' }],
            }),
        }))
      );
    } else {
      setTenantsForm([...dataSubset]);
      setErrors(undefined);
      dirtyFieldsRef.current = [];
    }
  }, [filter, editing, tenants]);

  const handleGoToTenantDashboard = (tenantId: number) => {
    if (device === 'mobile') {
      history.push(`${Path.tenantDashboard}/${tenantId}`);
    } else {
      window.open(`${Path.tenantDashboard}/${tenantId}`, '_blank');
    }
  };

  const handleSubmit = () => {
    setScrollToFirstError(true);

    dirtyFieldsRef.current = [
      ...tenantsForm
        .map((_, index) => [
          `${index}.barcode`,
          `${index}.contactName`,
          `${index}.contactEmail`,
        ])
        .flat(),
    ];

    const convertedTenantsForm = tenantsForm.map((item, index) => {
      if (item.icCards) {
        item.icCards = item.icCards.map((icCard) => {
          const existingSameCard = tenants[index]?.icCards?.find(
            ({ card }) => card === icCard.card
          );

          return existingSameCard ?? icCard;
        });
      }

      return item;
    });

    const validationResult = validateWithZod(schema, convertedTenantsForm);

    if (validationResult.ok) {
      updateTenantManagement({ tenants: convertedTenantsForm }, () => {
        toast.success(t('messages.M_011'));
        setEditing(false);
        fetchTenants();
      });
    } else {
      setErrors(validationResult.errors);
    }
  };

  const handleChangeInput = (params: {
    tenantId: number;
    index: number;
    name?: string;
    value?: EditableTableCellValue | EditableTableCellValue[];
    subIndex?: number;
  }) => {
    const { name, index, tenantId, value, subIndex } = params;

    if (!name) {
      return;
    }

    setScrollToFirstError(false);
    const path = `${index}.${name}`;

    if (!dirtyFieldsRef.current.includes(path)) {
      dirtyFieldsRef.current.push(path);
    }

    const newData = tenantsForm.map((item) => {
      const editItem = { ...item };

      if (editItem.id === tenantId) {
        if (name !== 'icCards') {
          if (!value) {
            delete editItem[name];
          } else {
            editItem[name] = value;
          }

          return editItem;
        }

        if (editItem.icCards?.length) {
          editItem.icCards = editItem.icCards.reduce(
            (res: NonNullable<TenantUpdateParams['icCards']>, icCard, i) => {
              if (i !== subIndex) {
                return [...res, icCard];
              }

              if (value) {
                res.push({
                  card: value.toString(),
                });
              }

              return res;
            },
            []
          );
        } else if (value) {
          editItem.icCards = [
            {
              card: value.toString(),
            },
          ];
        }

        if (
          editItem.icCards &&
          editItem.icCards[editItem.icCards.length - 1].card !== ''
        ) {
          editItem.icCards.push({
            card: '',
          });
        }
      }

      return editItem;
    });

    setTenantsForm(newData);

    _debounce(() => {
      const validationResult = validateWithZod(schema, newData);

      if (validationResult.ok) {
        setErrors(undefined);
      } else {
        setErrors((errors) => ({
          ...errors,
          ...dirtyFieldsRef.current.reduce(
            (res: Record<string, string>, field) => {
              res[field] = validationResult.errors[field];

              return res;
            },
            {}
          ),
        }));
      }
    }, 200)();
  };

  const renderTenantAuthenColumn = (
    item: TenantUpdateParams,
    index: number,
    onChange: EditableTableCellProps['onChange']
  ) => {
    if (user.originalTenantAuthen === TenantAuthen.Barcode) {
      return (
        <EditableTableCell
          editing={editing}
          value={item.barcode}
          name="barcode"
          onChange={onChange}
          regex={REGEX_OBJECT.nativeNumberRegex}
          error={errors?.[`${index}.barcode`]}
          inputProps={{
            maxLength: 15,
          }}
        />
      );
    }

    if (user.originalTenantAuthen === TenantAuthen.ICCard) {
      return (
        <EditableTableCell
          editing={editing}
          value={item.icCards?.map(({ card }) => card)}
          name="icCards"
          onChange={onChange}
          inputProps={{
            maxLength: 32,
          }}
          className={styles.icCardStyles}
          subCellClassName={styles.icCardItem}
        />
      );
    }

    return null;
  };

  return (
    <PrimaryTemplate h1={t('nav.tenant_management')}>
      <div className={styles.tenantManagement}>
        <div
          className={styles.count}
          dangerouslySetInnerHTML={{
            __html:
              t('common.page', {
                page: tenants.length,
              }) ?? '',
          }}
        />

        <table
          className={mergeClasses(styles.tenantTable, {
            [styles.tenantExpandedTable]:
              user.originalTenantAuthen !== TenantAuthen.None,
          })}
        >
          <thead>
            <tr>
              <th>{t('tenant_management.tenant')}</th>
              <th className={styles.SmallCol}>
                <TableFilterMultiSelect
                  label={t('tenant_management.section')}
                  options={sectionOptions}
                  values={filter?.sectionNames}
                  onChange={(value) =>
                    setFilter((prev) => ({ ...prev, sectionNames: value }))
                  }
                  disabled={!sectionOptions.length}
                />
              </th>
              <th className={styles.SmallCol}>
                <TableFilterMultiSelect
                  label={t('tenant_management.floor')}
                  options={floorOptions}
                  values={filter?.floorNames}
                  onChange={(value) =>
                    setFilter((prev) => ({ ...prev, floorNames: value }))
                  }
                  disabled={!floorOptions.length}
                />
              </th>
              {user.originalTenantAuthen !== TenantAuthen.None && (
                <th>
                  {user.originalTenantAuthen === TenantAuthen.ICCard
                    ? t('tenant_management.IC_card_no')
                    : t('tenant_management.barcode_no')}
                </th>
              )}
              <th>{t('tenant_management.contact_name')}</th>
              <th>{t('tenant_management.contact_mail')}</th>
              <th className={styles.SmallCol}>
                {t('tenant_management.report')}
              </th>
            </tr>
          </thead>

          <tbody>
            {tenantsForm.length ? (
              tenantsForm.map((item, index) => {
                const onChange = (params?: {
                  name?: string;
                  value?: EditableTableCellValue | EditableTableCellValue[];
                  subIndex?: number;
                }) =>
                  handleChangeInput({
                    ...params,
                    index,
                    tenantId: item.id,
                  });

                return (
                  <tr key={index}>
                    <EditableTableCell editable={false} value={item.name} />
                    <EditableTableCell
                      editable={false}
                      value={item.section?.name}
                      className={styles.SmallCol}
                    />
                    <EditableTableCell
                      editable={false}
                      value={item.floor?.name}
                      className={styles.SmallCol}
                    />
                    {renderTenantAuthenColumn(item, index, onChange)}
                    <EditableTableCell
                      editing={editing}
                      value={item.contactName}
                      name="contactName"
                      onChange={onChange}
                      error={
                        errors?.[`${index}.contactName`] &&
                        t(errors?.[`${index}.contactName`], {
                          field: t('tenant_management.contact_name'),
                        })
                      }
                      inputProps={{
                        maxLength: 100,
                      }}
                    />
                    <EditableTableCell
                      editing={editing}
                      value={item.contactEmail}
                      name="contactEmail"
                      onChange={onChange}
                      error={
                        errors?.[`${index}.contactEmail`] &&
                        t(errors?.[`${index}.contactEmail`], {
                          field: t('tenant_management.contact_mail'),
                        })
                      }
                      inputProps={{
                        maxLength: 100,
                      }}
                    />
                    <td
                      className={mergeClasses(styles.action, styles.SmallCol)}
                    >
                      {item.isDashboardEnabled && (
                        <button
                          className={styles.btnDashboard}
                          type="button"
                          onClick={() => handleGoToTenantDashboard(item.id)}
                        >
                          {t('tenant_management.view')}
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={7} className={styles.noData}>
                  {t('messages.M_008')}
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <BottomField>
          <div className={styles.bottomBtn}>
            {editing && (
              <button
                className={styles.btnCancel}
                type="button"
                onClick={() => {
                  setEditing(false);
                }}
              >
                {t('common.button.cancel')}
              </button>
            )}

            <button
              className={editing ? styles.btnSave : styles.btnEdit}
              type="button"
              onClick={editing ? handleSubmit : () => setEditing(true)}
              disabled={
                (editing &&
                  errors &&
                  Object.values(errors).some((err) => err)) ||
                !tenantsForm.length
              }
            >
              {editing ? t('common.button.save') : t('common.edit')}
            </button>
          </div>
        </BottomField>
      </div>
    </PrimaryTemplate>
  );
};

export default TenantManagement;
