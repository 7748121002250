// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
const IconSetting = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.175 4.317C13.749 2.561 11.251 2.561 10.825 4.317C10.549 5.452 9.249 5.99 8.253 5.382C6.709 4.442 4.943 6.209 5.883 7.752C6.0243 7.98375 6.10889 8.24559 6.12987 8.51621C6.15085 8.78683 6.10764 9.05859 6.00375 9.30935C5.89985 9.56011 5.73822 9.7828 5.532 9.95929C5.32578 10.1358 5.0808 10.2611 4.817 10.325C3.061 10.751 3.061 13.249 4.817 13.675C5.08056 13.7391 5.32529 13.8645 5.53127 14.0409C5.73726 14.2174 5.8987 14.44 6.00247 14.6906C6.10624 14.9412 6.14942 15.2128 6.12848 15.4832C6.10755 15.7537 6.0231 16.0153 5.882 16.247C4.942 17.791 6.709 19.557 8.252 18.617C8.48375 18.4757 8.74559 18.3911 9.01621 18.3701C9.28683 18.3491 9.55859 18.3924 9.80935 18.4963C10.0601 18.6001 10.2828 18.7618 10.4593 18.968C10.6358 19.1742 10.7611 19.4192 10.825 19.683C11.251 21.439 13.749 21.439 14.175 19.683C14.2391 19.4194 14.3645 19.1747 14.5409 18.9687C14.7174 18.7627 14.94 18.6013 15.1906 18.4975C15.4412 18.3938 15.7128 18.3506 15.9832 18.3715C16.2537 18.3924 16.5153 18.4769 16.747 18.618C18.291 19.558 20.057 17.791 19.117 16.248C18.9757 16.0162 18.8911 15.7544 18.8701 15.4838C18.8491 15.2132 18.8924 14.9414 18.9963 14.6907C19.1001 14.4399 19.2618 14.2172 19.468 14.0407C19.6742 13.8642 19.9192 13.7389 20.183 13.675C21.939 13.249 21.939 10.751 20.183 10.325C19.9194 10.2609 19.6747 10.1355 19.4687 9.95905C19.2627 9.78258 19.1013 9.55999 18.9975 9.30938C18.8938 9.05877 18.8506 8.78721 18.8715 8.51677C18.8924 8.24634 18.9769 7.98466 19.118 7.753C20.058 6.209 18.291 4.443 16.748 5.383C16.5162 5.5243 16.2544 5.60889 15.9838 5.62987C15.7132 5.65085 15.4414 5.60764 15.1907 5.50375C14.9399 5.39985 14.7172 5.23822 14.5407 5.032C14.3642 4.82578 14.2389 4.5808 14.175 4.317Z"
        stroke="#6F7175"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6213 14.1213C15.1839 13.5587 15.5 12.7956 15.5 12C15.5 11.2044 15.1839 10.4413 14.6213 9.87868C14.0587 9.31607 13.2956 9 12.5 9C11.7044 9 10.9413 9.31607 10.3787 9.87868C9.81607 10.4413 9.5 11.2044 9.5 12C9.5 12.7956 9.81607 13.5587 10.3787 14.1213C10.9413 14.6839 11.7044 15 12.5 15C13.2956 15 14.0587 14.6839 14.6213 14.1213Z"
        stroke="#6F7175"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconSetting;
