// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { fetchDashboardsPoolResources } from 'apis/dashboards/dashboardApi';
import { Modal } from 'components/atoms/Modal';
import { IconClose } from 'components/atoms/icons/IconClose';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import dayjs from 'dayjs';
import snakeCase from 'lodash/snakeCase';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { DATE_FORMAT } from 'util/ConstantValues';
import { DashboardsPoolResources } from 'util/Types';
import { normalizeNumber } from 'util/commons';
import Csv from 'util/csv';
import { PoolDashboardMasterFilter } from '../PoolDashboardFilter';
import PoolDashboardResourceTable from './PoolDashboardResourceTable';
import styles from './PoolDashboardResourceTable.module.scss';

type PoolDashboardResourceDataProps = {
  filter?: PoolDashboardMasterFilter;
  fetchTrigger?: number;
};

const PoolDashboardResourceData: React.FC<PoolDashboardResourceDataProps> = ({
  filter,
  fetchTrigger,
}) => {
  const { t, i18n } = useTranslation();
  const user = useSelector(selectUser);
  const [dashboardPoolResources, setDashboardPoolResources] = useState<
    DashboardsPoolResources[]
  >([]);
  const [isOpenDetailModal, setIsOpenDetailModal] = useState<boolean>(false);

  useEffect(() => {
    filter &&
      fetchDashboardsPoolResources(filter, (res) =>
        setDashboardPoolResources(res)
      );
  }, [filter, fetchTrigger]);

  const csvHeader: (string | [string, string])[] = useMemo(() => {
    return [
      ['date', t('pool_dashboard.resources_table.header.date')],
      ['stationName', t('pool_dashboard.resources_table.header.stations')],
      ['siteType', t('pool_dashboard.resources_table.header.site_type')],
      ['siteName', t('pool_dashboard.resources_table.header.site_name')],
      [
        'collectionName',
        t('pool_dashboard.resources_table.header.collect_company'),
      ],
      ['materialName', t('pool_dashboard.resources_table.header.material')],
      ['wasteTypeMaster', t('pool_dashboard.resources_table.header.waste')],
      ['weight', t('pool_dashboard.resources_table.header.weight')],
    ];
  }, [t]);

  const getPoolResourcesCsv = (poolResources: DashboardsPoolResources[]) => {
    return {
      header: csvHeader,
      data: poolResources.map((item) => {
        return {
          date: item.date,
          stationName: item.siteStations.map(({ name }) => name).join(', '),
          siteType: item.siteType,
          siteName: item.siteName,
          collectionName: item.collectionSites.length
            ? item.collectionSites.map(({ name }) => name).join(', ')
            : t('pool_dashboard.filter.no_collection_linkage_option'),
          materialName:
            i18n.language === 'ja'
              ? item.materialName
              : item.englishMaterialName || item.materialName,
          wasteTypeMaster: item.wasteTypeMaster
            .map((wasteType) =>
              t(`pool_dashboard.filter.${snakeCase(wasteType)}`)
            )
            .join(', '),
          weight: normalizeNumber({
            value: item.weight,
          }),
        };
      }),
    };
  };

  const handleDownloadCSV = () => {
    const poolResources = getPoolResourcesCsv(dashboardPoolResources);
    const fileNameCsv = `${user.name}_${getFormatDateWithCurrentTimezone(
      dayjs().format(),
      DATE_FORMAT.YMD
    )}`;

    new Csv({
      headers: poolResources.header,
      data: poolResources.data.flat(),
    }).downLoadCsv({
      filename: fileNameCsv,
    });
  };

  return (
    <>
      <div className={styles.PoolDashboardResourceData}>
        <div className={styles.Header}>
          <p>{t('pool_dashboard.resources_table.title')}</p>
          <div className={styles.DownloadButton} onClick={handleDownloadCSV}>
            <IconDownload />
          </div>
        </div>

        <PoolDashboardResourceTable
          dashboardPoolResources={dashboardPoolResources}
          onClick={() => setIsOpenDetailModal(true)}
        />
      </div>

      {isOpenDetailModal && (
        <Modal className={styles.PoolDashboardResourceTableModalWrapper}>
          <div className={styles.PoolDashboardResourceTableModal}>
            <div className={styles.ModalTitle}>
              <p>{t('pool_dashboard.resources_table.title')}</p>
              <IconClose onClick={() => setIsOpenDetailModal(false)} />
            </div>

            <div className={styles.ModalBody}>
              <PoolDashboardResourceTable
                dashboardPoolResources={dashboardPoolResources}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PoolDashboardResourceData;
