// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import styles from './TareWeightSubtraction.module.scss';
import { useTranslation } from 'react-i18next';
import { IconPlusCircle } from 'components/atoms/icons/IconPlusCircle';
import { IconEditIC } from 'components/atoms/icons/IconEditIC';
import { IconDelete } from 'components/atoms/icons/IconDelete';
import CreateUpdateTareModal, {
  AddEditTareProps,
} from 'components/organisms/CreateUpdateTareModal';
import { ModalType } from 'util/Enums';
import { useEffect, useState } from 'react';
import DeleteTareModal from 'components/molecules/DeleteTareModal';
import { TareInput } from 'components/molecules/CreateUpdateTareInputForm';
import { createAxios } from 'ts/createAxios';
import { handleError } from 'util/errorHandler';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { DbManager } from 'util/DbManager';
import { TareItem as OfflineTareItem } from 'util/Types';
import { normalizeNumber } from 'util/commons';

export interface TareItem {
  id: number;
  name: string;
  weight: number;
}

interface FetchTaresRes {
  tares: TareItem[] | OfflineTareItem[];
  total: number;
}

const TareWeightSubtraction = () => {
  const api = createAxios();
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const [modalAddEditTare, setModalData] = useState<
    AddEditTareProps | undefined
  >();
  const [data, setData] = useState<FetchTaresRes>({
    total: 0,
    tares: [],
  });
  const [deleteItem, setDeleteItem] = useState<TareItem | undefined>();

  const fetchTares = () => {
    api
      .get<FetchTaresRes>(`/tares?siteId=${user.siteId}`)
      .then((res) => {
        setData(res.data);
        const items = res.data.tares.map((tare) => ({
          ...tare,
          siteId: user.siteId,
        }));
        DbManager.tares.clear();
        DbManager.tares.bulkAdd(items);
      })
      .catch((e) => {
        handleError(e);
      });
  };
  useEffect(() => {
    fetchTares();
  }, []);
  const handleEdit = (data: TareInput, id: number) => {
    api
      .put(`/tares/${id}`, {
        name: data.name.trim(),
        weight: data.weight,
      })
      .then(() => {
        fetchTares();
        toast.success(t('messages.M_011'));
        setModalData(undefined);
      })
      .catch((e) => {
        handleError(e);
      });
  };
  const handleCreate = (data: TareInput) => {
    api
      .post('/tares', {
        name: data.name.trim(),
        weight: String(Number(data.weight)),
      })
      .then(() => {
        fetchTares();
        toast.success(t('messages.M_010'));
        setModalData(undefined);
      })
      .catch((e) => {
        handleError(e);
      });
  };
  const handleDelete = (id?: string | number) => {
    api
      .delete(`/tares/${id}`)
      .then(() => {
        fetchTares();
        toast.success(t('messages.M_012'));
        setDeleteItem(undefined);
      })
      .catch((e) => {
        handleError(e);
      });
  };
  return (
    <PrimaryTemplate
      h1={t('tare_management.header')}
      className={styles.tareWeightSubtraction}
    >
      <div className={styles.tareWeightSubtractionContent}>
        <div className={styles.title}>
          <div
            className={styles.countItem}
            dangerouslySetInnerHTML={{
              __html:
                t('common.page', {
                  page: data.total,
                }) ?? '',
            }}
          ></div>

          <button
            className={styles.submitButton}
            onClick={() => {
              setModalData({
                type: ModalType.add,
              });
            }}
          >
            <div className={styles.iconAdd}>
              <IconPlusCircle />
            </div>
            <span>{t('tare_management.add')}</span>
          </button>
        </div>
        <div className={styles.table}>
          <table id={styles.tareWeightSubtractionTable}>
            <thead>
              <tr>
                <th className={styles.tareName}>
                  {t('tare_management.label_name')}
                </th>
                <th className={styles.weight}>
                  {t('tare_management.label_weight')}
                </th>
                <th colSpan={2} className={styles.actionField}>
                  {t('tare_management.label_action')}
                </th>
              </tr>
            </thead>
            <tbody>
              {data.tares && data.tares.length > 0 ? (
                data.tares.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      onClick={() => {
                        //todo
                      }}
                    >
                      <td className={`${styles.tareName} ${styles.tareName1}`}>
                        <p title={item.name}>{item.name}</p>
                      </td>
                      <td className={`${styles.weight} ${styles.weight1}`}>
                        <p>
                          {normalizeNumber({ value: item.weight })}
                          <span>kg</span>
                        </p>
                      </td>
                      <td className={styles.actionField1}>
                        <div className={styles.contentAction}>
                          <div
                            className={styles.actionEdit}
                            onClick={() => {
                              setModalData({
                                type: ModalType.update,
                                tare: item,
                              });
                            }}
                          >
                            <IconEditIC />
                          </div>
                        </div>
                      </td>
                      <td className={styles.actionField1}>
                        <div className={styles.contentAction}>
                          <div
                            className={styles.actionDelete}
                            onClick={() => {
                              setDeleteItem(item);
                            }}
                          >
                            <IconDelete />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3} className={styles.msgError}>
                    {t('messages.M_008')}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {modalAddEditTare && (
        <CreateUpdateTareModal
          onClose={() => {
            setModalData(undefined);
          }}
          tareData={modalAddEditTare}
          onSubmit={(data, id) => {
            if (id) {
              handleEdit(data, id);
            } else {
              handleCreate(data);
            }
          }}
        />
      )}
      {deleteItem && (
        <DeleteTareModal
          item={deleteItem}
          onClose={() => {
            setDeleteItem(undefined);
          }}
          onHandleDelete={handleDelete}
        />
      )}
    </PrimaryTemplate>
  );
};

export default TareWeightSubtraction;
