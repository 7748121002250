// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Modal } from 'components/atoms/Modal';
import { IconClose } from 'components/atoms/icons/IconClose';
import { SelectField } from 'components/molecules/SelectField';
import dayjs from 'dayjs';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { createAxios } from 'ts/createAxios';
import { DATE_FORMAT } from 'util/ConstantValues';
import './WeightNoticeModal.scss';

type DownloadModalProps = {
  id?: string;
  isOpen: boolean;
  areaId: string;
  targetIds: string[];
  closeEditModal: () => void;
  areaName?: string;
};

type NoticeInput = {
  type: string;
  collectState: string;
  note: string;
};

const defaultInput: NoticeInput = {
  type: 'TooFew',
  collectState: 'UnCollect',
  note: '',
};

export const WeightNoticeModal = (props: DownloadModalProps) => {
  const api = createAxios();
  const user = useSelector(selectUser);
  const { t } = useTranslation();

  const type = [
    {
      label: t('notification.modal.select_type_too_few'),
      value: 'TooFew',
    },
    {
      label: t('notification.modal.select_type_too_many'),
      value: 'TooMany',
    },
    {
      label: t('notification.modal.select_type_nothing'),
      value: 'Nothing',
    },
    {
      label: t('notification.modal.select_type_not_separated'),
      value: 'NotSeparated',
    },
    { label: t('notification.modal.select_type_other'), value: 'Other' },
  ];

  const collectState = [
    {
      label: t('notification.modal.select_status_collected'),
      value: 'Collected',
    },
    {
      label: t('notification.modal.select_status_uncollected'),
      value: 'UnCollect',
    },
  ];
  return (
    <section className={'weight-notice-modal'}>
      <Modal>
        <div className="customTitle">
          <div className="title">
            {t('notification.thead_notification_content')}
          </div>
          <div className="top" onClick={props.closeEditModal}>
            <IconClose />
          </div>
        </div>
        <div className="body">
          <Form<NoticeInput>
            onSubmit={(values) => {
              api
                .post('/notices/weight', {
                  areaId: props.areaId,
                  targetIds: props.targetIds,
                  ...values,
                })
                .then(() => {
                  values = defaultInput;
                  props.closeEditModal();
                })
                .catch(() => {
                  alert(t('notification.modal.alert'));
                });
            }}
            mutators={{
              changeValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            initialValues={defaultInput}
          >
            {({ values, handleSubmit, form }) => {
              return (
                <form onSubmit={handleSubmit} className="notice-form">
                  <div className="notice-info">
                    <div className="notice-info-row">
                      <div className="notice-info-label">
                        {t('common.date_time')}
                      </div>
                      <div className="notice-info-contents">
                        : {dayjs().format(DATE_FORMAT.slaYMDHm)}
                      </div>
                    </div>
                    <div className="notice-info-row">
                      <div className="notice-info-label">
                        {t('common.place')}
                      </div>
                      <div className="notice-info-contents">
                        : {props.areaName}
                      </div>
                    </div>
                    <div className="notice-info-row">
                      <div className="notice-info-label">
                        {t('common.manager')}
                      </div>
                      <div className="notice-info-contents">: {user.name}</div>
                    </div>
                  </div>
                  <div className="notice-input">
                    <div className="selectBox">
                      <div className="selectLabel">
                        {t('notification.modal.select_label_content')}
                      </div>
                      <SelectField
                        name="type"
                        options={type}
                        value={values.type}
                        onChange={(value) => {
                          form.mutators.changeValue('type', value);
                        }}
                        isDarkmode
                      />
                    </div>
                    <div className="selectBox">
                      <div className="selectLabel">
                        {t('notification.modal.select_label_status')}
                      </div>
                      <SelectField
                        name="collectState"
                        options={collectState}
                        value={values.collectState}
                        onChange={(value) => {
                          form.mutators.changeValue('collectState', value);
                        }}
                        isDarkmode
                      />
                    </div>
                    <div className="selectBox">
                      <div className="selectLabel">
                        {t('notification.modal.text_area_label_remarks')}
                      </div>
                      <textarea
                        name="note"
                        value={values.note}
                        onChange={(e) => {
                          form.mutators.changeValue('note', e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="submit">
                    <button
                      className="submitButton"
                      type="submit"
                      disabled={false}
                    >
                      {t('common.button.send')}
                    </button>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
      </Modal>
    </section>
  );
};
