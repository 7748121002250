import { fetchWasteUnit } from 'apis/operator_site/siteApi';
import { SubstituteRegistInput } from 'components/organisms/SubstituteRegistInput';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { t } from 'i18next';
import { WasteUnitItem } from 'pages/AdminCustomizeItemsDetail';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Path } from 'routes';
import { ItemSize } from 'util/Enums';
import { ItemType } from 'util/Types';
import { handleError } from 'util/errorHandler';

const SubstituteRegistNew = () => {
  const history = useHistory();
  const { siteId, id } = useParams<{
    siteId: string;
    id: string;
  }>();
  const [wasteUnit, setWasteUnit] = useState<WasteUnitItem>();

  useEffect(() => {
    fetchWasteUnit(Number(id), setWasteUnit).catch((e) => {
      handleError(e);
      history.go(-1);
    });
  }, []);

  return (
    <PrimaryTemplate
      prevFunction={() => {
        setWasteUnit(undefined);
        history.push(Path.collects);
      }}
      h1={t('substitute_regist_new.title')}
      className="substitute-regist-new"
    >
      <SubstituteRegistInput
        substituteRegistFromSiteId={siteId}
        emissionType={{
          id: wasteUnit?.id.toString() ?? '',
          name: wasteUnit?.name ?? '',
          other: false,
          imageUrl: wasteUnit?.imageUrl ?? '',
          materialName: wasteUnit?.materialName ?? '',
          size: ItemSize.LARGE,
          siteId: siteId,
          typeObject: 'wasteUnit',
          convertKg: wasteUnit ? Number(wasteUnit.convertKg) : null,
          quantity: wasteUnit ? Number(wasteUnit.quantity) : null,
          type: wasteUnit?.type === 'Fixed' ? ItemType.FIX : ItemType.OPTIONAL,
          unit: wasteUnit?.unit ?? '',
          inputUnit: wasteUnit?.inputUnit ?? '',
        }}
        afterSubmit={() => {}}
      />
    </PrimaryTemplate>
  );
};

export default SubstituteRegistNew;
