// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import {
  fetchPurchasesCertificatesDownload,
  getPurchasesTracesDetail,
} from 'apis/purchases/purchaseApi';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import PurchaseTraceDataDetailOrganism from 'components/organisms/PurchaseTraceDataDetailOrganism';
import PurchaseTemplate from 'components/templates/PurchaseTemplate';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Path } from 'routes';
import { TraceDataDetail } from 'types/purchase';
import styles from './PurchaseTraceDataDetail.module.scss';
import Csv from 'util/csv';
import { hideLastCharacters } from 'util/commons';
import { OperatorType } from 'util/Enums';
import snakeCase from 'lodash/snakeCase';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { DATE_FORMAT } from 'util/ConstantValues';

const PurchaseTraceDataDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const title = id + ' ' + t('purchase_page.trace_data_detail.trace_data');
  const [traceDetail, setTraceDetail] = useState<TraceDataDetail[]>([]);

  const emissionsHeader: (string | [string, string])[] = useMemo(() => {
    return [
      [
        'deliveredDatetime',
        t('purchase_page.trace_data_detail.delivered_datetime'),
      ],
      [
        'deliveryToSiteName',
        t('purchase_page.trace_data_detail.delivery_to_site_name'),
      ],
      [
        'receivedDatetime',
        t('purchase_page.trace_data_detail.received_datetime'),
      ],
      ['description', t('purchase_page.trace_data_detail.description')],
      ['weight', t('purchase_page.trace_data_detail.weight')],
      ['id', t('purchase_page.trace_data_detail.id')],
    ];
  }, [t]);

  const commonHeader: (string | [string, string])[] = useMemo(() => {
    return [
      ['operatorName', t('purchase_page.trace_data_detail.operator_name')],
      ['siteName', t('purchase_page.trace_data_detail.site_name')],
      ['registDatetime', t('purchase_page.trace_data_detail.regist_datetime')],
      [
        'deliveredDatetime',
        t('purchase_page.trace_data_detail.delivered_datetime'),
      ],
      [
        'deliveryToSiteName',
        t('purchase_page.trace_data_detail.delivery_to_site_name'),
      ],
      [
        'requestedDatetime',
        t('purchase_page.trace_data_detail.requested_datetime'),
      ],
      ['siteName1', t('purchase_page.trace_data_detail.sender')],
      ['description', t('purchase_page.trace_data_detail.material_name')],
      ['materialType', t('purchase_page.trace_data_detail.material_type')],
      ['grade', t('purchase_page.trace_data_detail.grade')],
      ['weight', t('purchase_page.trace_data_detail.weight')],
      ['id', t('purchase_page.trace_data_detail.id')],
    ];
  }, [t]);

  useEffect(() => {
    fetchPurchasesTracesDetail();
  }, []);

  const fetchPurchasesTracesDetail = () => {
    getPurchasesTracesDetail(id, (data) => {
      data && setTraceDetail(data);
    });
  };

  const handleExportCertificate = () => {
    fetchPurchasesCertificatesDownload(id, (data) => {
      const downloadEl = document.createElement('a');

      downloadEl.href = `data:application/pdf;base64, ${data.pdfBase64}`;
      downloadEl.download = data.fileName;
      downloadEl.click();

      downloadEl.remove();
    });
  };

  const getTraceDetailCsv = (detail: TraceDataDetail) => {
    if (detail.stage === OperatorType.Emissions) {
      return {
        headers: emissionsHeader,
        data: detail.data.map((item) =>
          item.data.map((it) => ({
            deliveredDatetime: it.deliveredDatetime
              ? getFormatDateWithCurrentTimezone(
                  it.deliveredDatetime,
                  DATE_FORMAT.slaYMDHm
                )
              : '',
            deliveryToSiteName: it.deliveryToSiteName,
            receivedDatetime: it.receivedDatetime
              ? getFormatDateWithCurrentTimezone(
                  it.receivedDatetime,
                  DATE_FORMAT.slaYMDHm
                )
              : '',
            description: it.description,
            weight: it.weight,
            id: hideLastCharacters(it.id),
          }))
        ),
      };
    }

    return {
      headers: commonHeader,
      data: detail.data.map((item) =>
        item.data.map((it) => ({
          operatorName: it.operatorName,
          siteName: it.siteName,
          registDatetime: it.registDatetime
            ? getFormatDateWithCurrentTimezone(
                it.registDatetime,
                DATE_FORMAT.slaYMDHm
              )
            : '',
          deliveredDatetime: it.deliveredDatetime
            ? getFormatDateWithCurrentTimezone(
                it.deliveredDatetime,
                DATE_FORMAT.slaYMDHm
              )
            : '',
          deliveryToSiteName: it.deliveryToSiteName,
          requestedDatetime: it.requestedDatetime
            ? getFormatDateWithCurrentTimezone(
                it.requestedDatetime,
                DATE_FORMAT.slaYMDHm
              )
            : '',
          siteName1: it.siteName,
          description: it.description,
          materialType: it.materialType,
          grade: it.grade,
          weight: it.weight,
          id: it.id,
        }))
      ),
    };
  };

  const handleDownLoadCsv = (index = 0) => {
    if (index >= traceDetail.length) {
      return;
    }

    if (traceDetail.length) {
      const detailTrace = getTraceDetailCsv(traceDetail[index]);

      const fileNameCsv: string =
        id +
        t(
          `purchase_page.trace_data_detail.${snakeCase(
            traceDetail[index].stage
          )}`
        );

      new Csv({
        // Header mappers
        headers: detailTrace.headers,
        // Data to export
        data: detailTrace.data.flat(),
      }).downLoadCsv({
        filename: fileNameCsv,
      });

      setTimeout(() => handleDownLoadCsv(index + 1), 500);
    }
  };

  return (
    <PurchaseTemplate
      breadcrumb={[
        { href: Path.purchaseTraces, label: t('purchase_nav.traceability') },
        { label: id },
      ]}
    >
      <div className={styles.traceDataDetail}>
        <div className={styles.header}>
          <h1 className={styles.heading}>{title}</h1>
          <div className={styles.actions}>
            <button
              className={styles.downloadCertificate}
              onClick={() => handleDownLoadCsv()}
            >
              <IconDownload />
              {t('common.csv_download')}
            </button>

            <button
              className={styles.downloadCertificate}
              onClick={() => handleExportCertificate()}
            >
              <IconDownload />
              {t('purchase_page.trace_data_detail.certificate_download')}
            </button>
          </div>
        </div>

        <div className={styles.wrapper}>
          <PurchaseTraceDataDetailOrganism traceDetail={traceDetail} />
        </div>
      </div>
    </PurchaseTemplate>
  );
};

export default PurchaseTraceDataDetailPage;
