// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { fetchWasteUnit } from 'apis/operator_site/siteApi';
import { EmissionList } from 'components/organisms/EmissionList';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { WasteUnitItem } from 'pages/AdminCustomizeItemsDetail';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Path } from 'routes';
import { ItemSize } from 'util/Enums';
import { ItemType } from 'util/Types';
import { handleError } from 'util/errorHandler';

type Props = {
  siteId: string;
  wasteUnitIds: string[];
  prev: () => void;
};

const SubstituteRegistWasteUnit = ({ prev, wasteUnitIds, siteId }: Props) => {
  const history = useHistory();
  const [wasteUnits, setWasteUnits] = useState<WasteUnitItem[]>([]);

  const updateWasteUnits = (wasteUnit?: WasteUnitItem) => {
    setWasteUnits((prev) => {
      if (!wasteUnit) {
        return [];
      }

      if (prev) {
        return [...prev, wasteUnit];
      } else {
        return [wasteUnit];
      }
    });
  };

  useEffect(() => {
    for (const wasteUnitId of wasteUnitIds) {
      fetchWasteUnit(Number(wasteUnitId), updateWasteUnits).catch((e) => {
        handleError(e);
      });
    }
  }, []);

  return (
    <PrimaryTemplate
      prevFunction={() => {
        updateWasteUnits(undefined);
        prev();
      }}
      showActions={false}
      className="substitute-regist-waste-unit"
    >
      <EmissionList
        inputTypes={wasteUnits.map((wasteUnit) => {
          return {
            id: wasteUnit.id.toString(),
            other: false,
            name: wasteUnit.name,
            imageUrl: wasteUnit.imageUrl,
            materialName: wasteUnit.materialName,
            size: ItemSize.SMALL,
            siteId: siteId,
            typeObject: 'wasteUnit',
            convertKg: Number(wasteUnit.convertKg),
            quantity: Number(wasteUnit.quantity),
            type: wasteUnit.type === 'Fixed' ? ItemType.FIX : ItemType.OPTIONAL,
            unit: wasteUnit.unit,
            inputUnit: wasteUnit.inputUnit,
          };
        })}
        onSelected={(item) => {
          history.push(`${Path.collectsSubstituteRegist}/${siteId}/${item.id}`);
        }}
        onClickSortButton={() => {}}
        onClickRefreshButton={() => {}}
      />
    </PrimaryTemplate>
  );
};

export default SubstituteRegistWasteUnit;
