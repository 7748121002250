// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import {
  getMaterialNames,
  getRecycleMethods,
  getSiteCollects,
  getStations,
} from 'apis/commons';
import { fetchSiteTypeSuggestion } from 'apis/operator_site/siteApi';
import IconCalendarTablet from 'components/atoms/icons/IconCalendarTablet';
import {
  OptionItem,
  SelectFieldMultipleChoise,
} from 'components/molecules/SelectFieldMultipleChoise';
import TableFilterCalendar from 'components/molecules/TableFilterCalendar';
import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { getSlaFormatYearMonthDate } from 'ts/formatDate';
import styles from './PoolDashboardFilter.module.scss';

export type PoolDashboardMasterFilter = {
  dateFrom?: string;
  dateTo?: string;
  stationIds?: number[];
  collectionSiteIds?: string[];
  siteTypes?: string[];
  wasteTypeMaster?: string[];
  recycleMethodIds?: number[];
  materialNames?: string[];
};

type PoolDashboardFilterProps = {
  filter?: PoolDashboardMasterFilter;
  onSubmit: (filter?: PoolDashboardMasterFilter) => void;
};

const PoolDashboardFilter: React.FC<PoolDashboardFilterProps> = ({
  filter,
  onSubmit,
}) => {
  const { t, i18n } = useTranslation();
  const [tmpFilter, setTmpFilter] = useState<PoolDashboardMasterFilter>({
    ...filter,
  });

  const [stationOptions, setStationOptions] = useState<OptionItem[]>([]);
  const [siteCollectOptions, setSiteCollectOptions] = useState<OptionItem[]>(
    []
  );
  const [siteTypeOptions, setSiteTypeOptions] = useState<OptionItem[]>([]);
  const [materialNameOptions, setMaterialNameOptions] = useState<OptionItem[]>(
    []
  );
  const [recycleMethodOptions, setRecycleMethodOptions] = useState<
    OptionItem[]
  >([]);

  const wasteOptions: OptionItem[] = [
    {
      id: 'IndustrialWaste',
      name: t('pool_dashboard.filter.industrial_waste'),
    },
    { id: 'GeneralWaste', name: t('pool_dashboard.filter.general_waste') },
  ];

  useEffect(() => {
    getStations((res) => setStationOptions(res));
    getSiteCollects((res) => setSiteCollectOptions(res));
    fetchSiteTypeSuggestion((res) => {
      if (res) {
        setSiteTypeOptions(
          res.map((item) => ({
            id: item.siteType,
            name: item.siteType,
          }))
        );
      }
    });
    getMaterialNames({
      isEmissionAll: true,
      successCallback: (res) =>
        setMaterialNameOptions(
          res.map((item) => ({
            id: item.materialName,
            name: item.materialName,
            englishName: item.englishMaterialName,
          }))
        ),
    });
    getRecycleMethods((res) => setRecycleMethodOptions(res));
  }, []);

  const siteCollectOptionsData = useMemo(
    () => [
      ...siteCollectOptions,
      {
        id: 'No Collection Linkage',
        name: t('pool_dashboard.filter.no_collection_linkage_option'),
      },
    ],
    [t, siteCollectOptions]
  );

  function handleChangeFilter<T extends keyof PoolDashboardMasterFilter>(
    name: T,
    value: PoolDashboardMasterFilter[T]
  ) {
    setTmpFilter((prev) => ({
      ...prev,
      [name]: value?.length ? value : undefined,
    }));
  }

  return (
    <div className={styles.PoolDashboardFilter}>
      <Form onSubmit={() => {}}>
        {(props) => (
          <form onSubmit={props.handleSubmit} className={styles.Form}>
            <SelectFieldMultipleChoise
              options={stationOptions}
              onChange={(items) =>
                handleChangeFilter(
                  'stationIds',
                  items.map(({ id }) => Number(id))
                )
              }
              darkMode
              name="stationIds"
              selectedOptions={tmpFilter?.stationIds || []}
              placeholder={t('pool_dashboard.filter.stations')}
            />
            <SelectFieldMultipleChoise
              options={wasteOptions}
              onChange={(items) =>
                handleChangeFilter(
                  'wasteTypeMaster',
                  items.map(({ id }) => id.toString())
                )
              }
              darkMode
              name="wasteTypeMaster"
              selectedOptions={tmpFilter?.wasteTypeMaster || []}
              placeholder={t('pool_dashboard.filter.waste_type')}
            />
            <SelectFieldMultipleChoise
              options={siteCollectOptionsData}
              onChange={(items) =>
                handleChangeFilter(
                  'collectionSiteIds',
                  items.map(({ id }) => id.toString())
                )
              }
              darkMode
              name="collectionSiteIds"
              selectedOptions={tmpFilter?.collectionSiteIds || []}
              placeholder={t('pool_dashboard.filter.collect_companies')}
            />
            <SelectFieldMultipleChoise
              options={siteTypeOptions}
              onChange={(items) =>
                handleChangeFilter(
                  'siteTypes',
                  items.map(({ id }) => id.toString())
                )
              }
              darkMode
              name="siteTypes"
              selectedOptions={tmpFilter?.siteTypes || []}
              placeholder={t('pool_dashboard.filter.site_types')}
            />
            <SelectFieldMultipleChoise
              options={materialNameOptions.map(
                (item) =>
                  ({
                    id: item.id,
                    name:
                      i18n.language === 'ja'
                        ? item.name
                        : item.englishName || item.name,
                  } as OptionItem)
              )}
              onChange={(items) =>
                handleChangeFilter(
                  'materialNames',
                  items.map(({ id }) => id.toString())
                )
              }
              darkMode
              name="materialNames"
              selectedOptions={tmpFilter?.materialNames || []}
              placeholder={t('pool_dashboard.filter.materials')}
            />
            <SelectFieldMultipleChoise
              options={recycleMethodOptions.map(
                (item) =>
                  ({
                    id: item.id,
                    name:
                      i18n.language === 'ja'
                        ? item.name
                        : item.englishName || item.name,
                  } as OptionItem)
              )}
              onChange={(items) =>
                handleChangeFilter(
                  'recycleMethodIds',
                  items.map(({ id }) => Number(id))
                )
              }
              darkMode
              name="recycleMethodIds"
              selectedOptions={tmpFilter?.recycleMethodIds || []}
              placeholder={t('pool_dashboard.filter.processing_methods')}
            />
            <TableFilterCalendar
              expanded={{
                placeholder: t('common.placeholder.select_date_range'),
                icon: <IconCalendarTablet />,
              }}
              containerClassName={styles.DateRangeField}
              portalPositionPoint={(ref) => ref}
              allowClear={false}
              fullWidth
              value={[tmpFilter?.dateFrom || '', tmpFilter?.dateTo || '']}
              onChange={(value) => {
                setTmpFilter((prev) => ({
                  ...prev,
                  dateFrom: value?.[0]
                    ? getSlaFormatYearMonthDate(value[0])
                    : undefined,
                  dateTo: value?.[1]
                    ? getSlaFormatYearMonthDate(value[1])
                    : undefined,
                }));
              }}
            />
            <button
              className={styles.Submit}
              type="button"
              onClick={() => onSubmit(tmpFilter)}
            >
              {t('dashboard.button_apply')}
            </button>
          </form>
        )}
      </Form>
    </div>
  );
};

export default PoolDashboardFilter;
