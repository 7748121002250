// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { createSlice } from '@reduxjs/toolkit';
import { CurrencyConvertResponse } from 'types/purchase';
import { CurrencyUnitType } from 'util/constants/dashboard';

const initialState: CurrencyConvertResponse = {
  base: CurrencyUnitType.JPY,
  date: '',
  rates: { EUR: 0, USD: 0 },
  success: true,
  timestamp: 0,
};

export const currencySlice = createSlice({
  name: 'currency',
  initialState: initialState,
  reducers: {
    setCurrency: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setCurrency } = currencySlice.actions;
export default currencySlice.reducer;
