// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import { IconCloseTablet } from 'components/atoms/icons/IconCloseTablet';
import { Modal } from 'components/atoms/Modal';
import CreateUpdateTareInputForm, {
  TareInput,
} from 'components/molecules/CreateUpdateTareInputForm';
import { TareItem } from 'pages/TareWeightSubtractionManagement';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalType } from 'util/Enums';
import styles from './CreateUpdateTareModal.module.scss';

export interface AddEditTareProps {
  type: ModalType;
  tare?: TareItem;
}
interface Props {
  onClose: () => void;
  tareData: AddEditTareProps;
  onSubmit: (form: TareInput, tareId?: number) => void;
}

const CreateUpdateTareModal: React.FC<Props> = ({
  onClose,
  tareData,
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.tareModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {tareData.type === ModalType.add
                ? t('create_update_tare_modal.title_add')
                : t('create_update_tare_modal.title_edit')}
            </p>
            <div className={styles.top} onClick={onClose}>
              <IconCloseTablet />
            </div>
          </div>
          <CreateUpdateTareInputForm tareData={tareData} onSubmit={onSubmit} />
        </div>
      </Modal>
    </div>
  );
};

export default CreateUpdateTareModal;
