// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const IconCategory: React.FC<Props> = ({
  width = 25,
  height = 24,
  color = '#878787',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.5"
        y="5"
        width="18"
        height="4"
        rx="1"
        stroke={color}
        strokeWidth="2"
      />
      <rect
        x="9.5"
        y="15"
        width="12"
        height="4"
        rx="1"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M5.5 8.5V16C5.5 16.5523 5.94772 17 6.5 17H10"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconCategory;
