// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './TextButton.scss';

export type TextButtonProps = {
  buttonType: 'default' | 'white';
  text: string;
  disabled?: boolean;
  onClick?: VoidFunction;
  type?: string;
};

export const TextButton = (
  props: TextButtonProps & JSX.IntrinsicElements['button']
) => {
  const buttonClassName = `text-button ${props.buttonType}`;

  return (
    <button
      className={buttonClassName}
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.type}
    >
      {props.text}
    </button>
  );
};
