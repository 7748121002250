// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
const IconDashboard = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5014 3C12.5013 3 12.5013 3 12.5012 3C7.53 3 3.5 7.03 3.5 12.0012C3.5 16.9725 7.53 21.0025 12.5012 21.0025C17.4725 21.0025 21.5025 16.9725 21.5025 12.0012H19.5025C19.5025 15.8679 16.3679 19.0025 12.5012 19.0025C8.63457 19.0025 5.5 15.8679 5.5 12.0012C5.5 8.13457 8.63457 5 12.5012 5C12.5013 5 12.5013 5 12.5014 5V3Z"
        fill="#E8E8E8"
      />
      <path
        d="M12.5013 4V11.5012C12.5013 11.7774 12.7252 12.0012 13.0013 12.0012H20.5026"
        stroke="#E8E8E8"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.047 8.21063C21.2216 8.79287 20.75 9.3341 20.1421 9.3341H16.1683C15.616 9.3341 15.1683 8.88639 15.1683 8.3341V4.08882C15.1683 3.45711 15.7502 2.98118 16.3421 3.20196C18.5977 4.04337 20.3538 5.89946 21.047 8.21063Z"
        fill="#E8E8E8"
      />
    </svg>
  );
};
export default IconDashboard;
