// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import './LabelContents.scss';

export type Props = {
  label: string;
  contents?: React.ReactNode | string | null;
  hideOnEmpty?: boolean;
};

export const LabelContents = ({ label, hideOnEmpty, contents }: Props) => {
  if (hideOnEmpty && !contents) {
    return null;
  }

  return (
    <div className="label-contents">
      <div className="title">{label}</div>
      <div>：</div>

      {React.isValidElement(contents) ? (
        <div>{contents}</div>
      ) : (
        <div
          className="contents"
          dangerouslySetInnerHTML={{
            __html:
              contents != null ? String(contents).replace(/\n/g, '<br />') : '',
          }}
        />
      )}
    </div>
  );
};
