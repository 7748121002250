// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import {
  useFetchTracesQuery,
  useLazyFetchTracesQuery,
} from 'apis/purchase/purchaseApi';
import TableLoading from 'components/atoms/TableLoading';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import PurchaseTemplate from 'components/templates/PurchaseTemplate';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Path } from 'routes';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { PurchaseTraces } from 'types/purchase';
import { DATE_FORMAT, DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import Csv from 'util/csv';
import styles from './Traceability.module.scss';
import { normalizeNumber } from 'util/commons';

const Traceability = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [traceData, setTraceData] = useState<PurchaseTraces[]>([]);
  const [args, setArgs] = useState<{ page: number; take: number }>({
    page: DEFAULT_PAGE,
    take: PAGE_SIZE,
  });
  const { data: traces } = useFetchTracesQuery(args);
  const [trigger] = useLazyFetchTracesQuery();

  useEffect(() => {
    if (args.page === 1) {
      setTraceData(traces?.data || []);
    } else {
      setTraceData([...traceData, ...(traces?.data || [])]);
    }
  }, [traces]);

  const handleDetail = (trace) => {
    history.push(`${Path.purchaseTraces}/${trace.id}`);
  };

  const headers: (string | [string, string])[] = useMemo(() => {
    return [
      ['datetime', t('purchase_page.traceability.production_date')],
      ['userName', t('purchase_page.traceability.person_in_charge')],
      ['siteName', t('purchase_page.traceability.producer')],
      ['description', t('purchase_page.traceability.material_name')],
      ['materialType', t('purchase_page.traceability.material_type')],
      ['grade', t('purchase_page.traceability.grade')],
      ['weight', t('purchase_page.traceability.weight') + '(kg)'],
      ['id', t('purchase_page.traceability.product_id')],
    ];
  }, [t]);

  const handleDownLoadCsv = async () => {
    const result = await trigger().unwrap();
    if (result?.data) {
      new Csv({
        headers: headers,
        data:
          result.data.map((trace) => ({
            datetime: getFormatDateWithCurrentTimezone(
              trace.datetime,
              DATE_FORMAT.slaYMDHm
            ),
            userName: trace.userName,
            siteName: trace.siteName,
            description: trace.description,
            materialType: trace.materialType,
            grade: trace.grade,
            weight: trace.weight,
            id: trace.id,
          })) || [],
      }).downLoadCsv({
        filename: t('purchase_page.traceability.fileName'),
      });
    }
  };

  return (
    <PurchaseTemplate title={t('purchase_nav.traceability')}>
      <div className={styles.traceability}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.title}>{t('purchase_nav.traceability')}</div>
            <div className={styles.actions}>
              <button
                className={styles.downloadCsv}
                onClick={() => handleDownLoadCsv()}
              >
                <IconDownload />
                {t('purchase_page.traceability.download_csv')}
              </button>
            </div>
          </div>
          <table className={`primary-table ${styles.primaryTable}`}>
            <thead>
              <tr>
                <th className={styles.td110}>
                  {t('purchase_page.traceability.production_date')}
                </th>
                <th className={styles.td120}>
                  {t('purchase_page.traceability.person_in_charge')}
                </th>
                <th className={styles.td120}>
                  {t('purchase_page.traceability.producer')}
                </th>
                <th className={styles.td100}>
                  {t('purchase_page.traceability.material_name')}
                </th>
                <th className={styles.td100}>
                  {t('purchase_page.traceability.material_type')}
                </th>
                <th className={styles.td100}>
                  {t('purchase_page.traceability.grade')}
                </th>
                <th className={styles.td100}>
                  {t('purchase_page.traceability.weight')}
                </th>
                <th className={styles.td100}>
                  {t('purchase_page.traceability.product_id')}
                </th>
              </tr>
            </thead>
            <tbody>
              {traceData?.length ? (
                traceData.map((trace, i) => {
                  return (
                    <tr key={i} onClick={() => handleDetail(trace)}>
                      <td className={styles.td110}>
                        {getFormatDateWithCurrentTimezone(
                          trace.datetime,
                          DATE_FORMAT.slaYMDHm
                        )}
                      </td>
                      <td className={styles.td120}>{trace.userName}</td>
                      <td className={styles.td120}>{trace.siteName}</td>
                      <td className={styles.td100}>{trace.description}</td>
                      <td className={styles.td100}>{trace.materialType}</td>
                      <td className={styles.td100}>{trace.grade}</td>
                      <td className={styles.td100}>
                        {normalizeNumber({ value: trace.weight })}
                      </td>
                      <td className={styles.td100}>{trace.id}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8} className={styles.nodata}>
                    {t('messages.M_008')}
                  </td>
                </tr>
              )}

              {traceData.length > 0 && (
                <TableLoading
                  onNext={() => {
                    if (args.page * PAGE_SIZE < (traces?.total || PAGE_SIZE)) {
                      setArgs({
                        ...args,
                        page: args.page + 1,
                      });
                    }
                  }}
                />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </PurchaseTemplate>
  );
};

export default Traceability;
