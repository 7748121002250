// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import styles from './LoginTemplate.module.scss';

interface Props {
  children?: React.ReactNode;
}

export function LoginTemplate({ children }: Props) {
  return (
    <div className={styles.loginContainer}>
      <div id={styles.contents}>{children}</div>
      <div id={styles.rightContents}>
        <img src="/assets/images/right.svg" alt="right image" />
      </div>
    </div>
  );
}
