// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './DownloadModal.module.scss';
import './Calendar.scss';
import { Modal } from 'components/atoms/Modal';
import { useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { getFormatDate } from 'ts/formatDate';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import { createAxios } from 'ts/createAxios';
import { EMPTY_DATE } from 'components/molecules/CalendarTextField';
import { useTranslation } from 'react-i18next';
import { IconClose } from 'components/atoms/icons/IconClose';
import IconCalendarPrev from 'components/atoms/icons/IconCalendarPrev';
import IconCalendarNext from 'components/atoms/icons/IconCalendarNext';
import IconCalendarTablet from 'components/atoms/icons/IconCalendarTablet';
import dayjs from 'dayjs';

export type ApiInfo = {
  fileName: string;
  url: string;
  header: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createCsv: (data: any[]) => any[];
};

export type DownloadModalProps = {
  id?: string;
  isOpen: boolean;
  apiInfo: ApiInfo;
  onCancelClick: () => void;
};

export const DownloadModal = (props: DownloadModalProps) => {
  const commonsApi = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchDoneRef = useRef<any>();
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [fetchData, setFetchData] = useState<any[]>([]);
  const [fetchDone, setFetchDone] = useState<boolean>();

  const changeDate = (dates: Array<Date>) => {
    setStartDate(getFormatDate(dates[0].toString(), 'YYYY/MM/DD'));
    if (dates.length > 1 && dates[1]) {
      setEndDate(
        getFormatDate(dayjs(dates[1]).startOf('day').toString(), 'YYYY/MM/DD')
      );
    } else {
      setEndDate('');
    }
  };

  const getData = async (dateFrom, dateTo) => {
    setFetchDone(false);
    if (dateFrom && dateTo) {
      const res = await commonsApi.get(
        props.apiInfo.url + 'dateFrom=' + dateFrom + '&dateTo=' + dateTo
      );
      setFetchData(props.apiInfo.createCsv(res.data));
    } else {
      setFetchData([]);
    }

    setFetchDone(true);
    fetchDoneRef.current.link.click();
  };
  return (
    <section
      className={`${styles.downloadModal} ${
        props.isOpen ? styles.downloadModalOpen : styles.downloadModalClose
      }`}
    >
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.heading}>
            <p className={styles.title}>{t('history_download_modal.title')}</p>
            <div className={styles.top}>
              <IconClose onClick={props.onCancelClick} />
            </div>
          </div>

          <div className={styles.body}>
            <div className={styles.targetDateGroup}>
              <div className={styles.calendarField}>
                <p>{startDate || EMPTY_DATE}</p>
                <IconCalendarTablet />
              </div>
              <p>~</p>
              <div className={styles.calendarField}>
                <p>{endDate || EMPTY_DATE}</p>
                <IconCalendarTablet />
              </div>
            </div>
            <div className={'calendar-tablet'}>
              <Calendar
                calendarType="US"
                locale={localStorage.getItem('i18nextLng') || 'ja'}
                allowPartialRange={true}
                selectRange={true}
                prev2Label={null}
                next2Label={null}
                prevLabel={<IconCalendarPrev />}
                nextLabel={<IconCalendarNext />}
                maxDate={new Date()}
                formatDay={(locale, date) => getFormatDate(date, 'D')}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onChange={(activeStartDate, value) => {
                  changeDate(activeStartDate);
                }}
                className="react-calendar-tablet"
              />
            </div>
          </div>
          <div className={styles.modalBottomField}>
            <div className={styles.bottomBtnField}>
              <div className={styles.primaryBtn}>
                <div className={`${styles.downloadButton} ${styles.default}`}>
                  <button
                    onClick={() => {
                      if (!startDate || !endDate) {
                        return false;
                      }
                      getData(startDate, endDate);
                    }}
                  >
                    {t('history_download_modal.download')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {fetchDone && (
            <CSVLink
              className="hidden"
              filename={props.apiInfo.fileName + '.csv'}
              headers={props.apiInfo.header}
              data={fetchData}
              ref={fetchDoneRef}
            >
              {t('history_download_modal.download')}
            </CSVLink>
          )}
        </div>
      </Modal>
    </section>
  );
};
