// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
export type DatasetLabels = { id: number; name: string; englishName: string };
export const initDatasetLabels = [
  {
    id: 9,
    name: 'リサイクル（サーマル）',
    englishName: 'Recycling (thermal)',
  },
  {
    id: 8,
    name: 'リサイクル（ケミカル）',
    englishName: 'Recycling (chemical)',
  },
  {
    id: 7,
    name: 'リサイクル（マテリアル）',
    englishName: 'Recycling (material)',
  },
  {
    id: 6,
    name: 'リサイクル（POOL）',
    englishName: 'Recycling (POOL)',
  },
  {
    id: 5,
    name: 'リサイクル（飼料化）',
    englishName: 'Recycling (feed)',
  },
  {
    id: 4,
    name: 'リサイクル（肥料化）',
    englishName: 'Recycling (composting)',
  },
  {
    id: 3,
    name: 'リサイクル（代表）',
    englishName: 'Recycling (representative)',
  },
  {
    id: 10,
    name: '不明',
    englishName: 'Unidentified',
  },
  {
    id: 2,
    name: '埋立',
    englishName: 'Landfill',
  },
  {
    id: 1,
    name: '焼却',
    englishName: 'Incineration',
  },
];

export const getBgColor = (id: number) => {
  switch (id) {
    case 1:
      return 'rgba(111, 111, 111, 1)';
    case 2:
      return 'rgba(135, 135, 135, 1)';
    case 3:
      return 'rgba(192, 251, 175, 1)';
    case 4:
      return 'rgba(176, 248, 153, 1)';
    case 5:
      return 'rgba(161, 244, 132, 1)';
    case 6:
      return 'rgba(147, 240, 112, 1)';
    case 7:
      return 'rgba(134, 234, 92, 1)';
    case 8:
      return 'rgba(102, 195, 70, 1)';
    case 9:
      return 'rgba(72, 153, 51, 1)';
    default:
      return 'rgba(207, 207, 207, 1)';
  }
};

export const initDatasetColors = [
  '#86ea5c',
  '#173506',
  '#B0EDA6',
  '#E7E8E9',
  '#B7B7B7',
  '#878787',
  '#5E5E5E',
];

export const labelSliceToDisplay = (
  label: string,
  maxNumOfCharacterDisplay = 20
) => {
  if (!label) {
    return '';
  }

  return label.length > maxNumOfCharacterDisplay
    ? `${label.slice(0, maxNumOfCharacterDisplay)}...`
    : label;
};
