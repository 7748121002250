// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from 'axios';
import { addLoading, endLoading } from 'redux/slices/loadingSlice';
import { store } from 'redux/store';
import { Path } from 'routes';
import { API_DEFAULT_TIMEOUT } from 'util/ConstantValues';
import CookiesAuth from 'util/CookiesAuth';
import { DbManager } from 'util/DbManager';

const getToken = (type: 'token' | 'refreshToken' = 'token') => {
  if (type === 'token') {
    return CookiesAuth.accessToken;
  }

  return CookiesAuth.refreshToken;
};

const handleRefreshToken = async () => {
  try {
    const res = await createAxios('refresh', {
      refresh_token: getToken('refreshToken'),
    }).get('/auth/refresh');

    if (res.data.IdToken) {
      CookiesAuth.accessToken = res.data.IdToken;
    }

    return res.data.IdToken;
  } catch (_) {
    CookiesAuth.logout();
    refreshTokenRequest = null;
    await DbManager.clearExceptWasteRegistrations();
    window.nativeUpdateUser(null);

    alert('認証情報が切れました。再ログインしてください。');

    window.location.href = Path.login;

    return null;
  }
};

let refreshTokenRequest: Promise<string | undefined> | null;

export const createAxios = (
  mode?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers?: any,
  notShowLoading?: boolean,
  otherEndpoint?: string
) => {
  const header = {
    ...headers,
    'Referrer-Policy': 'no-referrer-when-downgrade',
    'Content-Type': 'application/json',
    Authorization: getToken(),
    'Accept-Language': localStorage.getItem('i18nextLng') || 'ja',
    'X-Timezone-Offset': String(new Date().getTimezoneOffset()),
  };

  const instance = axios.create({
    baseURL: otherEndpoint ? otherEndpoint : process.env.REACT_APP_API_URL,
    responseType: 'json',
    headers: header,
    timeout: API_DEFAULT_TIMEOUT,
  });

  instance.interceptors.request.use(
    async (request) => {
      if (!notShowLoading) {
        store.dispatch(addLoading());
      }

      return request;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => {
      if (!notShowLoading) {
        store.dispatch(endLoading());
      }

      return response;
    },
    async (error: AxiosError) => {
      if (!notShowLoading) {
        store.dispatch(endLoading());
      }

      if (
        error.response?.status === 401 &&
        mode !== 'login' &&
        mode !== 'refresh'
      ) {
        refreshTokenRequest = refreshTokenRequest ?? handleRefreshToken();

        const validToken = await refreshTokenRequest;

        if (validToken == null) {
          return Promise.reject();
        }

        const previousToken =
          error.response.config.headers.Authorization?.toString();

        refreshTokenRequest = null;

        const isTokenChanged = Boolean(
          validToken && previousToken && validToken !== previousToken
        );

        if (error.response && isTokenChanged) {
          error.response.config.headers.Authorization = validToken;

          return instance(error.response.config);
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};
