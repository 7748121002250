// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import {
  useFetchSiteInfoByIdQuery,
  useUpdateCompressionMutation,
} from 'apis/compressions_user/compressionUserApi';
import { Modal } from 'components/atoms/Modal';
import { IconClose } from 'components/atoms/icons/IconClose';
import { CompressionEditCard } from 'components/molecules/CompressionEditCard';
import arrayMutators from 'final-form-arrays';
import { useEffect, useMemo, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectUser } from 'redux/slices/userSlice';
import { addition } from 'util/Calc';
import { Compression, Option } from 'util/Types';
import { bigNumberCompare, normalizeNumberString } from 'util/commons';
import { isError } from 'util/errorHandler';
import WeightLimitExceededModal from '../WeightLimitExceededModal';
import styles from './CompressionEditModal.module.scss';

export type DownloadModalProps = {
  id?: string;
  isOpen: boolean;
  closeEditModal: () => void;
  afterSubmit: () => void;
  compressionData: Compression[];
  hasPackingStyles?: boolean;
};

const getSumQuantity = (compressions: Compression[]) => {
  if (compressions.length <= 0) return 0;
  const quantities: Array<number> = compressions.map((compression) => {
    if (!Number.isNaN(compression.weight)) {
      return Number(compression.weight);
    } else {
      return 0;
    }
  });
  return quantities.reduce((sum, quantity) => addition(sum, quantity));
};

export const CompressionEditModal = (props: DownloadModalProps) => {
  const { t } = useTranslation();

  const user = useSelector(selectUser);

  const [alertModal, setAlertModal] = useState<boolean>(false);
  const [updateRecords, setUpdateRecords] = useState([]);

  const closeAlertModal = () => setAlertModal(false);

  const openAlertModal = (records) => {
    setUpdateRecords(records);
    setAlertModal(true);
  };

  const { data: categories } = useFetchSiteInfoByIdQuery(user?.siteId, {
    skip: !user.siteId,
  });

  const grades = useMemo(
    (): Option[] =>
      categories?.grades.map((x) => ({
        label: x.name,
        value: x.id,
      })) || [],
    [categories]
  );

  const packingStyles = useMemo(
    (): Option[] =>
      categories?.packingStyles.map((x: string) => ({
        label: x,
        value: x,
      })) || [],
    [categories]
  );

  const [fnUpdateCompression] = useUpdateCompressionMutation();

  const [fetchData, setFetchData] = useState<Compression[]>(
    props.compressionData
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sumQuantity, setSumQuantity] = useState<number>(
    getSumQuantity(props.compressionData)
  );

  const initialData = useMemo(() => {
    return fetchData.map((m) => {
      const packingExist = packingStyles.some((data: Option) => {
        return data.value === m.packingStyle;
      });
      const packingStyle = packingExist
        ? m.packingStyle
        : packingStyles?.[0]?.value;

      return {
        ...m,
        quantity: m.weight,
        changeGrade: m.grade,
        gradeId: m.gradeId,
        packingStyle,
      };
    });
  }, [fetchData]);

  useEffect(() => {
    if (props.compressionData !== fetchData) {
      setFetchData(props.compressionData);
      setSumQuantity(getSumQuantity(props.compressionData));
    }
  }, [props.compressionData]);

  const onSubmit = (values) => {
    const records = values.inputs.map((data) => ({
      compressionId: data.compressionId,
      weight: normalizeNumberString(data.weight),
      gradeId: Number(data.gradeId),
      packingStyle: data.packingStyle,
      totalInputWeight: data.totalInputWeight,
    }));

    const isShowAlert: boolean = records.some((rec) =>
      bigNumberCompare(rec.weight, rec.totalInputWeight)
    );

    isShowAlert ? openAlertModal(records) : onUpdate(records);
  };

  const onUpdate = async (records) =>
    fnUpdateCompression(records)
      .then((result) => {
        if (isError(result)) return;
        props.afterSubmit();
        toast.success(t('messages.M_127'));
        setUpdateRecords([]);
        props.closeEditModal();
      })
      .catch(() => {
        alert(t('compressions.signup_messages.failed'));
      });

  const handleAlertClose = () => {
    onUpdate(updateRecords).then(() => closeAlertModal());
  };

  return (
    <>
      <section className={styles.downloadModal}>
        <Modal>
          <div className={styles.customTitle}>
            <div className={styles.title}>
              {t('common.button.volume_reduction_correction')}
            </div>
            <div className={styles.top} onClick={props.closeEditModal}>
              <IconClose />
            </div>
          </div>

          <Form
            mutators={{ ...arrayMutators }}
            initialValues={{
              inputs: initialData,
            }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, valid }) => (
              <form onSubmit={handleSubmit}>
                <div className={styles.body}>
                  <FieldArray name="inputs">
                    {({ fields }) =>
                      fields.map((name, i) => (
                        <CompressionEditCard
                          key={i}
                          date={fetchData[i].datetime}
                          label={fetchData[i].description}
                          compressionId={fetchData[i].compressionId}
                          gradeOptions={grades}
                          name={name}
                          packingOptions={packingStyles}
                          hasPackingStyles={props.hasPackingStyles}
                          totalInputWeight={fetchData[i].totalInputWeight}
                          isOverload={bigNumberCompare(
                            fields.value[i].weight,
                            fields.value[i].totalInputWeight
                          )}
                        />
                      ))
                    }
                  </FieldArray>
                </div>
                <div className={styles.submit}>
                  <button
                    className={styles.submitButton}
                    type="submit"
                    disabled={!valid}
                  >
                    {t('common.button.reflect_the_correction')}
                  </button>
                </div>
              </form>
            )}
          </Form>
        </Modal>
      </section>
      <WeightLimitExceededModal
        open={alertModal}
        onSubmit={handleAlertClose}
        onClose={closeAlertModal}
        isDarkmode
      />
    </>
  );
};
