// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './TextInputField.scss';
import React from 'react';
import { Field } from 'react-final-form';
import { FieldValidator } from 'final-form';
import { Label } from 'components/atoms/Label';
import { ErrorText } from 'components/atoms/ErrorText';

interface Props {
  className?: string;
  name: string;
  label?: string;
  value?: string;
  required?: boolean;
  hint?: React.ReactNode;
  validate?: FieldValidator<string>;
  type?: string;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
}

export function TextInputField({
  label,
  name,
  value,
  type,
  placeholder,
  readOnly,
  disabled,
  className,
  validate,
  required,
  maxLength,
}: Props) {
  return (
    <Field name={name} type={type} validate={validate} value={value}>
      {({ input, meta }) => (
        <div className={className ?? 'text-input'}>
          {label && <Label text={label} tag={'div'} required={required} />}
          <input
            {...input}
            name={name}
            placeholder={placeholder}
            readOnly={readOnly}
            disabled={disabled}
            className={meta && meta.touched && meta.error ? 'error' : ''}
            maxLength={maxLength}
          />
          {meta && meta.touched && meta.error && (
            <ErrorText text={meta.error} />
          )}
        </div>
      )}
    </Field>
  );
}
