// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal } from 'components/atoms/Modal';
import { ChangeEvent, useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';

import { IconClose } from 'components/atoms/icons/IconClose';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';
import { Resource } from 'types/purchase';
import { normalizeNumber } from 'util/commons';
import ResourceTable from '../ResourceTable';
import styles from './index.module.scss';

type Props = {
  packingStyle: any[];
  resources: Resource[];
  checkedItems: string[];
  setCheckedItems: (ids: string[]) => void;
  onSubmit: () => void;
  onClose: () => void;
};

const ResourceConfirmModal: React.FC<Props> = ({
  onClose,
  resources,
  checkedItems,
  onSubmit,
  packingStyle,
  setCheckedItems,
}) => {
  const { t } = useTranslation();
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [filteredResources, setFilteredResources] = useState<Resource[]>([]);
  const [allCheckedFlg, setAllCheckedFlg] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      const clonedData = cloneDeep(resources);
      setCheckedIds(checkedItems);
      const newFilteredResources = clonedData.filter((item) =>
        checkedItems.includes(item.id)
      );
      setFilteredResources(newFilteredResources);
      setIsInitialized(true);
    }
  }, [resources, checkedItems, isInitialized]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const itemId = e.target.id;
    const isChecked = e.target.checked;
    let updatedCheckedItems;

    if (isChecked) {
      updatedCheckedItems = [...checkedIds, itemId];
      setCheckedIds(updatedCheckedItems);
    } else {
      updatedCheckedItems = checkedIds.filter((item) => item !== itemId);
      setCheckedIds(updatedCheckedItems);
    }

    setAllCheckedFlg(updatedCheckedItems.length === filteredResources.length);
  };

  const allChecked = () => {
    if (allCheckedFlg) {
      setCheckedItems([]);
      setAllCheckedFlg(false);
    } else {
      const allItemIds = filteredResources.map((item: any) => item.id);
      setCheckedItems(allItemIds);
      setAllCheckedFlg(true);
    }
  };

  return (
    <section className={styles.downloadModal}>
      <Modal>
        <div className={styles.customTitle}>
          <div className={styles.title}>{resources?.[0]?.site?.name}</div>
          <div className={styles.top} onClick={onClose}>
            <IconClose />
          </div>
        </div>

        <div className={styles.label}>
          <p>{t('purchase_page.resource.label')}</p>
        </div>

        <div className={styles.body}>
          <ResourceTable
            allChecked={allChecked}
            resources={filteredResources}
            checkedItems={checkedItems}
            handleChange={handleChange}
          />
        </div>

        <div className={styles.totalRecovery}>
          <div>{t('purchase_page.resource.total')}&nbsp;</div>
          <div className={styles.item}>
            {packingStyle.map((item, index) => {
              return (
                <p key={index}>
                  {`${
                    item.weight && normalizeNumber({ value: item.weight })
                  } kg — [${item.packingStyle}] ${normalizeNumber({
                    value: item.total,
                  })} ${t('purchase_page.resource.pieces')}`}
                </p>
              );
            })}
          </div>
        </div>

        <div className={styles.submit}>
          <button
            className={styles.submitButton}
            onClick={onSubmit}
            disabled={!checkedItems.length}
          >
            {t('purchase_page.resource.shipping_destination_btn')}
          </button>
        </div>
      </Modal>
    </section>
  );
};
export default ResourceConfirmModal;
