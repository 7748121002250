// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './PrevButton.scss';
import { Link } from 'react-router-dom';

export type PrevButtonProps = {
  to?: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

export const PrevButton = (
  props: PrevButtonProps & JSX.IntrinsicElements['button']
) => {
  {
    return props.to ? (
      <Link className={'prev-button'} to={props.to as string}>
        {props.children}
      </Link>
    ) : (
      <button
        className={'prev-button'}
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          }
        }}
      >
        {props.children}
      </button>
    );
  }
};
