// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './DashboardCalendarField.module.scss';
import { useState, useRef } from 'react';
import './Calendar.scss';
import 'react-calendar/dist/Calendar.css';
import { getSlaFormatYearMonthDate } from 'ts/formatDate';
import { useTranslation } from 'react-i18next';
import { IconDashboardCalendar } from 'components/atoms/icons/IconDashboardCalendar';
import dayjs from 'dayjs';
import { SelectDateModal } from 'components/organisms/SelectDateModal';

export type Props = {
  id?: string;
  onChange?: (value: string[]) => void;
};

export function DashboardCalendarField({ onChange }: Props) {
  const inputEl = useRef<HTMLInputElement>(null);
  const [dateFocus, setDateFocus] = useState<boolean>(false);
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<string>(
    getSlaFormatYearMonthDate(dayjs().subtract(6, 'month').format())
  );
  const [endDate, setEndDate] = useState<string>(
    getSlaFormatYearMonthDate(dayjs().format())
  );

  const handleClick = () => {
    inputEl.current?.focus();
  };

  const changeDate = (dates: string[]) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
    setDateFocus(false);
    onChange && onChange([dates[0], dates[1]]);
  };

  return (
    <div className={styles.dashboardCalendarField}>
      <div className={styles.selectDate}>
        <input
          ref={inputEl}
          autoComplete="off"
          name="date"
          type="text"
          placeholder={t('common.date_from_to') || '開始日 ~ 終了日'}
          value={`${startDate || t('common.date_from')} ~ ${
            endDate || t('common.date_to')
          }`}
          readOnly
          onFocus={() => {
            setDateFocus(true);
          }}
        ></input>
        {dateFocus && (
          <SelectDateModal
            isOpen={dateFocus}
            onCancelClick={() => {
              setDateFocus(false);
            }}
            onSave={changeDate}
            defaultDates={[new Date(startDate), new Date(endDate)]}
          />
        )}
        <div className={styles.icon}>
          <IconDashboardCalendar onClick={handleClick} />
        </div>
      </div>
      <div
        className={'over-ray' + (dateFocus ? ' active' : '')}
        onClick={() => {
          setDateFocus(false);
        }}
      ></div>
    </div>
  );
}
