// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { IconCloseTablet } from 'components/atoms/icons/IconCloseTablet';
import { Modal } from 'components/atoms/Modal';
import React, { useState } from 'react';
import styles from './DashboardDownloadModal.module.scss';
import { CustomCheckBox } from 'components/atoms/CustomCheckbox';
import { useTranslation } from 'react-i18next';

interface DownloadType {
  isCsv: boolean;
  isImage: boolean;
}
interface Props {
  onClose: () => void;
  handleDownload?: (data: DownloadType) => void;
}

const DashboardDownloadModal: React.FC<Props> = ({
  onClose,
  handleDownload,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState<DownloadType>({
    isCsv: false,
    isImage: false,
  });
  return (
    <div className={styles.tareModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {t('dashboard.download_modal.title')}
            </p>
            <div className={styles.top} onClick={onClose}>
              <IconCloseTablet />
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.content}>
              <div className={styles.item}>
                <CustomCheckBox
                  id={'1'}
                  checked={state.isImage}
                  onChange={() => {
                    setState((s) => {
                      return {
                        ...s,
                        isImage: !s.isImage,
                      };
                    });
                  }}
                />
                <p>{t('dashboard.download_modal.checkbox_image')}</p>
              </div>
              <div className={styles.item}>
                <CustomCheckBox
                  id={'2'}
                  checked={state.isCsv}
                  onChange={() => {
                    setState((s) => {
                      return {
                        ...s,
                        isCsv: !s.isCsv,
                      };
                    });
                  }}
                />
                <p>{t('dashboard.download_modal.checkbox_csv')}</p>
              </div>
            </div>
            <div className={styles.action}>
              <button
                disabled={!state.isCsv && !state.isImage}
                onClick={() => {
                  handleDownload && handleDownload(state);
                }}
              >
                {t('dashboard.download_modal.button_submit')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DashboardDownloadModal;
