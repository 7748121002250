// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './RecycleProductRegistration.module.scss';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { RecycleComplete } from 'components/organisms/RecycleComplete';
import { useTranslation } from 'react-i18next';

function RecycleProductRegistration() {
  const { t } = useTranslation();

  return (
    <PrimaryTemplate h1={t('recycle_product_registration.heading')}>
      <div className={styles.recycleProductRegistration}>
        <RecycleComplete />
      </div>
    </PrimaryTemplate>
  );
}

export default RecycleProductRegistration;
