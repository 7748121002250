// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import { RefObject, useEffect, useRef, useState } from 'react';

type Props = {
  initialIsVisible?: boolean;
  onClickOutside?: (event?: MouseEvent) => void;
  exceptRefs?: RefObject<HTMLElement>[];
};

function useOutsideClick({
  initialIsVisible,
  onClickOutside,
  exceptRefs,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(initialIsVisible);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        (!exceptRefs?.length ||
          !exceptRefs.some(
            (refItem) =>
              refItem.current && refItem.current.contains(event.target as Node)
          ))
      ) {
        setIsVisible(false);
        typeof onClickOutside === 'function' && onClickOutside(event);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside]);

  return { ref, isVisible, setIsVisible };
}

export default useOutsideClick;
