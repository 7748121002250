// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './HistoryCompressionCompletes.module.scss';
import { useEffect, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import { LabelContents } from 'components/molecules/LabelContents';
import { CompressionCompleteData } from 'util/Types';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from 'util/ConstantValues';
import { normalizeNumber } from 'util/commons';

export type Props = {
  id?: string;
};

export const HistoryCompressionCompletes = () => {
  const commonsApi = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { t } = useTranslation();
  const [compressionCompleteData, setCompressionCompleteData] = useState<
    CompressionCompleteData[]
  >([]);

  const callApi = async () => {
    const res = await commonsApi.get('/work-histories/compression/delivered');
    setCompressionCompleteData(res.data);
  };

  useEffect(() => {
    callApi();
  }, []);
  return (
    <div className={styles.historyCompressionCompletes}>
      {compressionCompleteData &&
        compressionCompleteData.length > 0 &&
        compressionCompleteData.map((item, index) => {
          return (
            <div className={styles.commonCard} key={index}>
              <LabelContents
                label={t('common_history_field.date_time')}
                contents={getFormatDateWithCurrentTimezone(
                  item.datetime,
                  DATE_FORMAT.slaYMDHm
                )}
              ></LabelContents>
              <LabelContents
                label={t('common_history_field.responsible_person')}
                contents={item.userId + '(' + item.userName + ')'}
              ></LabelContents>
              <LabelContents
                label={t('common_history_field.shipping_address')}
                contents={item.deliveryToOperatorName}
              ></LabelContents>
              <LabelContents
                label={t('common_history_field.material_name')}
                contents={item.description}
              ></LabelContents>
              <LabelContents
                label={t('common.quality')}
                contents={item.grade}
              ></LabelContents>
              <LabelContents
                label={t('common_history_field.weight')}
                contents={`${normalizeNumber({ value: item.weight })}kg`}
              ></LabelContents>
              <LabelContents
                label={t('common_history_field.registration_id')}
                contents={item.targetIds.join('\n')}
              ></LabelContents>
            </div>
          );
        })}
    </div>
  );
};
