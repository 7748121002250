import { apiService } from 'apis/site_recycle/siteApi';
import {
  TSitesRequest,
  TSitesResponse,
  TTrucksRequest,
  TTrucksResponse,
} from 'types/Collect';
import {
  InputString,
  TArea,
  TCollectEmission,
  TCreateCollectBySiteId,
} from 'util/Types';

const addTagTypes = ['collect', 'trucks'] as const;

const collectApi = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchCollectById: build.query<TCollectEmission[], InputString>({
        query: (id: string) => ({
          url: `/collects/${id}`,
          method: 'GET',
        }),
        providesTags: (result, error) => {
          if (error) return [];
          return ['collect'];
        },
      }),
      fetchCollectArea: build.query<TArea[], void>({
        query: () => ({
          url: '/collects/areas',
          method: 'GET',
        }),
        providesTags: (result, error) => {
          if (error) return [];
          return ['collect'];
        },
      }),
      createCollectBySiteId: build.mutation<string, TCreateCollectBySiteId>({
        query: (args) => ({
          url: `/collects/${args.id}`,
          method: 'POST',
          data: args.data,
        }),
        invalidatesTags: (result, error) => {
          if (error) return [];
          return ['collect'];
        },
      }),
      fetchTrucks: build.query<TTrucksResponse, TTrucksRequest>({
        query: (args) => ({
          url: '/trucks',
          method: 'GET',
          params: args,
        }),
      }),
      fetchOperatorSiteByType: build.query<TSitesResponse, TSitesRequest>({
        query: (args) => ({
          url: '/sites/admin-operator',
          method: 'GET',
          params: args,
        }),
      }),
    }),
  });

export const {
  useFetchCollectByIdQuery,
  useFetchCollectAreaQuery,
  useCreateCollectBySiteIdMutation,
  useFetchTrucksQuery,
  useFetchOperatorSiteByTypeQuery,
} = collectApi;
