// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useQueryWeightEmissionStatisticMutation } from 'apis/purchase/purchaseApi';
import { dashboardPurchaseEmissonOperatorSiteTotal } from 'apis/site_purchase/purchase';
import DashboardLabel from 'components/atoms/DashboardLabel';
import IconHeatMapDescription from 'components/atoms/icons/IconHeatMapDescription';
import PurchaseResourceSearchForm, {
  DEFAULT_RADIUS_VALUE,
} from 'components/molecules/PurchaseResourceSearchForm';
import PurchaseResourceSearchMap from 'components/molecules/PurchaseResourceSearchMap';
import PurchaseTemplate from 'components/templates/PurchaseTemplate';
import dayjs from 'dayjs';
import _omit from 'lodash/omit';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MaterialStatisticSearch } from 'types/dashboard';
import { EmissionOperatorSiteTotal } from 'types/purchase';
import { DATE_FORMAT } from 'util/ConstantValues';
import { DateTypeRange } from 'util/Enums';
import MaterialStatisticOrganism from '../MaterialStatisticOrganism';
import SitesStatisticOrganism from '../SitesStatisticOrganism';
import styles from './PurchaseResourceSearch.module.scss';
import { formatNumberWithCommas } from 'util/commons';

export const fnQueryParams = (params: MaterialStatisticSearch) => {
  params = {
    ...params,
    dateFrom: dayjs(params.dateFrom).format(DATE_FORMAT.full_date),
    dateTo: dayjs(params.dateTo).format(DATE_FORMAT.full_date),
    radius:
      params.long != null && params.lat != null ? params.radius : undefined,
  };

  // remove unuse params
  return _omit(params, [
    'gradeOption',
    'materialOption',
    'tree',
    'where',
    'address',
  ]);
};

const PurchaseResourceSearch = () => {
  const { t } = useTranslation();

  const [params, setParams] = useState<MaterialStatisticSearch>({
    dateFrom: dayjs().subtract(6, 'month').toString(),
    dateTo: dayjs().toString(), // today
    typeDateRange: DateTypeRange.MONTH,
    gradeOption: false,
    materialOption: false,
    materialNames: [],
    radius: DEFAULT_RADIUS_VALUE,
    grades: [],
    tree: [],
    locations: [],
    where: 'all',
  });

  const [emissionTotal, setEmissionTotal] = useState<EmissionOperatorSiteTotal>(
    { operatorCount: 0, siteCount: 0 }
  );

  useEffect(() => {
    dashboardPurchaseEmissonOperatorSiteTotal((data) => {
      if (data) {
        setEmissionTotal(data);
      }
    });
  }, []);

  const operatorSiteCountlabels = useMemo(
    () => [
      {
        title: t('purchase_page.resource_search.active_companies'),
        value: formatNumberWithCommas(emissionTotal.operatorCount, 2),
      },
      {
        title: t('purchase_page.resource_search.active_locations'),
        value: formatNumberWithCommas(emissionTotal.siteCount),
      },
    ],
    [t, emissionTotal]
  );

  const [queryData, { data }] = useQueryWeightEmissionStatisticMutation();

  const onSearch = (values: MaterialStatisticSearch) => {
    setParams(values);
    const query = fnQueryParams(values);
    queryData(query);
  };

  useEffect(() => {
    const query = fnQueryParams(params);
    queryData(query);
  }, []);

  return (
    <PurchaseTemplate title={t('purchase_page.resource_search.title')}>
      <div className={styles.wrapper}>
        <div className={styles.purchaseResourceSearch}>
          <div className={styles.content}>
            {t('purchase_page.resource_search.content')}
          </div>
          <div className={styles.analysis}>
            <div className={styles.analysis_left}>
              <div className={styles.analysis_left_top}>
                <div className={styles.analysis_left_top_label}>
                  {operatorSiteCountlabels?.map((item) => (
                    <div
                      className={styles.analysis_left_top_label_container}
                      key={item.title}
                    >
                      <DashboardLabel title={item.title} value={item.value} />
                    </div>
                  ))}
                </div>

                <SitesStatisticOrganism />
              </div>
              <div className={styles.analysis_left_bottom}>
                <div className={styles.analysis_left_bottom_header}>
                  <div className={styles.analysis_left_bottom_header_text}>
                    {t(
                      'purchase_page.resource_search.circular_resource_heatmap'
                    )}
                  </div>
                  <div
                    className={styles.analysis_left_bottom_header_description}
                  >
                    <div className={styles.unit}>
                      {t('purchase_page.resource_search.unit_ton')}
                    </div>
                    <div>
                      <IconHeatMapDescription />
                      <div
                        className={
                          styles.analysis_left_bottom_header_description_space
                        }
                      >
                        <span>0</span>
                        <span>10,000</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.analysis_left_bottom_heatmap}>
                  <PurchaseResourceSearchMap
                    heatMapData={data?.siteStatistic || []}
                  />
                </div>
              </div>
            </div>
            <PurchaseResourceSearchForm
              search={params}
              setSearch={onSearch}
              hasTable
              data={data?.materialSiteTypeGradeStatistic || []}
            />
          </div>

          <MaterialStatisticOrganism
            materialStatistic={data?.materialStatistic || []}
            search={params}
            setSearch={onSearch}
          />
        </div>
      </div>
    </PurchaseTemplate>
  );
};

export default PurchaseResourceSearch;
