// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
export const IconPhoto = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="4" fill="#EDEDED" />
      <path
        d="M12 24L16.586 19.414C16.9611 19.0391 17.4697 18.8284 18 18.8284C18.5303 18.8284 19.0389 19.0391 19.414 19.414L24 24M22 22L23.586 20.414C23.9611 20.0391 24.4697 19.8284 25 19.8284C25.5303 19.8284 26.0389 20.0391 26.414 20.414L28 22M22 16H22.01M14 28H26C26.5304 28 27.0391 27.7893 27.4142 27.4142C27.7893 27.0391 28 26.5304 28 26V14C28 13.4696 27.7893 12.9609 27.4142 12.5858C27.0391 12.2107 26.5304 12 26 12H14C13.4696 12 12.9609 12.2107 12.5858 12.5858C12.2107 12.9609 12 13.4696 12 14V26C12 26.5304 12.2107 27.0391 12.5858 27.4142C12.9609 27.7893 13.4696 28 14 28Z"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
