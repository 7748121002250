// gkc_hash_code : 596AVJ0U6O4MG74XBQH62VSQ6T
import styles from './CoordinateInput.module.scss';

type CoordinateInputProps = {
  name: string;
  placeholder?: string;
  value?: string;
  setValue: (value?: string) => void;
};

const CoordinateInput = ({
  name,
  placeholder,
  value,
  setValue,
}: CoordinateInputProps) => {
  return (
    <div className={styles.coordinateInput}>
      <input
        placeholder={placeholder}
        name={name}
        defaultValue={value}
        onChange={(e) => setValue(e?.target?.value)}
      />
    </div>
  );
};

export default CoordinateInput;
