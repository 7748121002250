// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import CustomRadio from 'components/atoms/Radio/CustomRadio';
import styles from './CustomRadioGroup.module.scss';
import { useEffect, useState } from 'react';

interface Props {
  options: { lable: string; value: string | number }[];
  name: string;
  direction?: 'vertical' | 'horizontal';
  defaultChecked: string | number;
  onchange: (value: string | number) => void;
}

const CustomRadioGroup: React.FC<Props> = ({
  options,
  name,
  direction = 'horizontal',
  defaultChecked,
  onchange,
}) => {
  const [currentOptionChecked, setCurentOptionChecked] = useState<
    string | number
  >('');

  useEffect(() => {
    setCurentOptionChecked(defaultChecked);
  }, []);

  const handleChange = (value: string | number) => {
    setCurentOptionChecked(value);
    onchange(value);
  };
  return (
    <div
      className={`${styles.options} ${
        direction === 'vertical'
          ? styles.optionsVertical
          : styles.optionsHorizontal
      }`}
    >
      {options.map((option) => {
        return (
          <div className={styles.radioItem} key={option.value}>
            <CustomRadio
              checked={currentOptionChecked === option.value}
              value={option.value}
              inputName={name}
              onChange={handleChange}
            >
              {option.lable}
            </CustomRadio>
          </div>
        );
      })}
    </div>
  );
};

export default CustomRadioGroup;
