import SideMenu from 'components/molecules/SideMenu';
import { TMenuItem } from 'types/purchase';
import './PurchaseNavbar.scss';

type Props = {
  menuItems: TMenuItem[];
};

const PurchaseNavbar = ({ menuItems }: Props) => {
  return (
    <div className="purchase-side-nav">
      <div className="purchase-logo">
        <img src="/assets/images/logo.svg" alt="logo" height={48} />
      </div>
      <SideMenu menuItems={menuItems} key="side-menu" />
    </div>
  );
};

export default PurchaseNavbar;
