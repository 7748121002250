// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import BarcodeICCardForm from 'components/molecules/BarcodeICCardForm';
import styles from './BarcodeICCardDetailModal.module.scss';
import { SiteTenantAuthenticationItem } from 'util/siteManagementTypes';

type Props = {
  authenticationItem: SiteTenantAuthenticationItem | undefined;
  onClose: () => void;
  onClickShowBarcode?: () => void;
  onClickShowAddICCard: () => void;
  onClickDeleteBarcode?: (barcode: string) => void;
  onClickDeleteICCard: (icCardId: number) => void;
};

const BarcodeICCardDetailModal: React.FC<Props> = ({
  authenticationItem,
  onClose,
  onClickShowBarcode,
  onClickShowAddICCard,
  onClickDeleteBarcode,
  onClickDeleteICCard,
}) => {
  return (
    <div className={styles.barcodeICCardDetailModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {authenticationItem?.name}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>

          <BarcodeICCardForm
            authenticationItem={authenticationItem}
            onClickShowBarcode={onClickShowBarcode}
            onClickShowAddICCard={onClickShowAddICCard}
            onClickDeleteBarcode={onClickDeleteBarcode}
            onClickDeleteICCard={onClickDeleteICCard}
          />
        </div>
      </Modal>
    </div>
  );
};

export default BarcodeICCardDetailModal;
