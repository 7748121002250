// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
export type LabelProps = {
  id?: string;
  className?: string;
  text: string | JSX.Element;
};

export const ErrorText = (props: LabelProps) => {
  return (
    <p id={props.id} className={props.className}>
      {props.text}
    </p>
  );
};
