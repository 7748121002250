// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './ConfirmProductRegistrationModal.module.scss';
import { Modal } from 'components/atoms/Modal';
import { PrimaryBtnField } from 'components/molecules/PrimaryBtnField';
import { IconClose } from 'components/atoms/icons/IconClose';

export type ConfirmProductRegistrationModalProps = {
  title: string;
  isOpen: boolean;
  confirmContents: {
    label: string;
    contents: string;
  }[];
  closeEditModal: () => void;
  onClick: () => void;
  submitLabel?: string;
  alertContent?: {
    title: string;
    content: string;
  };
  continueLabel?: string;
  onSubmitContinue?: () => void;
};

const ConfirmProductRegistrationModal = (
  props: ConfirmProductRegistrationModalProps
) => {
  const {
    title,
    submitLabel,
    isOpen,
    confirmContents,
    closeEditModal,
    onClick,
    alertContent,
    continueLabel,
    onSubmitContinue,
  } = props;

  return (
    <section
      className={`${styles.confirmProductRegistrationModal} ${
        isOpen ? styles.open : styles.closed
      }`}
    >
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.top}>
            <div className={styles.title}>{title}</div>
            <div className={styles.closeIcon} onClick={closeEditModal}>
              <IconClose />
            </div>
          </div>

          <div className={styles.body}>
            {alertContent && (
              <div className={styles.alertContent}>
                <div className={styles.title}>{alertContent.title}</div>
                <div className={styles.content}>{alertContent.content}</div>
              </div>
            )}

            <div className={styles.confirmInfo}>
              {confirmContents.map((item, i) => {
                return (
                  item.label &&
                  item.contents && (
                    <div className={styles.confirmInfoRow} key={i}>
                      <div className={styles.confirmInfoLabel}>
                        {item.label}
                      </div>
                      <div className={styles.confirmInfoContents}>
                        {item.contents}
                      </div>
                    </div>
                  )
                );
              })}
            </div>
            <div className={'modal-bottom-field'}>
              {continueLabel && (
                <PrimaryBtnField
                  className={styles.continueButton}
                  text={continueLabel}
                  onClick={onSubmitContinue}
                  type="button"
                />
              )}

              {submitLabel && (
                <PrimaryBtnField
                  className={styles.submitButton}
                  text={submitLabel}
                  onClick={onClick}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default ConfirmProductRegistrationModal;
