// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './index.module.scss';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Modal } from 'components/atoms/Modal';
import { IconClose } from 'components/atoms/icons/IconClose';
import { SelectField } from 'components/molecules/SelectField';

import { OperatorType } from 'util/Enums';

export enum SiteStage {
  SOURCE = 'source',
  DESTINATION = 'destination',
}

type Site = {
  id: string;
  name: string;
};

export type SitesByStage = {
  [SiteStage.SOURCE]: Site[];
  [SiteStage.DESTINATION]: Site[];
};

export type RouteSetting = {
  id: string;
  name: string;
  sitesByStage: SitesByStage;
};

export type RecycleChainRoutesSettingModalProps = {
  operatorType: OperatorType;
  sitesByStage: SitesByStage;
  routesSetting: RouteSetting[];
  onClose?: () => void;
  onSubmit?: (
    sitesByStage: SitesByStage,
    routesSetting: RouteSetting[]
  ) => void;
};

type DragDropSiteItem = {
  id: SiteStage;
  translationTitle: string;
  sites: Site[];
};

type DragDropRouteItem = {
  id: string;
  name: string;
  sitesStages: DragDropSiteItem[];
};

export const RecycleChainRoutesSettingModal = ({
  operatorType,
  sitesByStage,
  routesSetting,
  onClose,
  onSubmit,
}: RecycleChainRoutesSettingModalProps) => {
  const { t } = useTranslation();
  const [dragDropSites, setDragDropSites] = useState<DragDropSiteItem[]>([
    {
      id: SiteStage.SOURCE,
      translationTitle: 'recycle_chains.routes_setting_modal.site_stage.source',
      sites: [],
    },
    {
      id: SiteStage.DESTINATION,
      translationTitle:
        'recycle_chains.routes_setting_modal.site_stage.destination',
      sites: [],
    },
  ]);
  const siteOptions = () => {
    return dragDropSites.map((dragDropSite) => ({
      label: t(dragDropSite.translationTitle),
      value: dragDropSite.id as string,
    }));
  };

  const [dragDropRoutes, setDragDropRoutes] = useState<DragDropRouteItem[]>([]);

  const [
    isSitesStageDragDropRoutesInvalid,
    setIsSitesStageDragDropRoutesInvalid,
  ] = useState<boolean>(false);
  const [isModifiedDragDropRoutes, setIsModifiedDragDropRoutes] =
    useState<boolean>(false);

  const handleMoveSite = (
    source: { id: string; index: number },
    destination: { id: string; index: number }
  ) => {
    const { id: sourceId, index: sourceIndex } = source;
    const { id: destinationId, index: destinationIndex } = destination;

    const sourceIdData = sourceId.split('-');
    const destinationIdData = destinationId.split('-');

    if (sourceIdData[0] === 'dragDropSites') {
      const newDragDropSites = dragDropSites.map((dragDropSite) => ({
        ...dragDropSite,
        sites: [...dragDropSite.sites],
      }));
      const changedDragDropSites = newDragDropSites[
        +sourceIdData[1]
      ].sites.splice(sourceIndex, 1);

      if (destinationIdData[0] === 'dragDropSites') {
        newDragDropSites[+destinationIdData[1]].sites.splice(
          destinationIndex,
          0,
          changedDragDropSites[0]
        );

        setDragDropSites(newDragDropSites);
      } else {
        const newDragDropRoutes = dragDropRoutes.map((dragDropRoute) => ({
          ...dragDropRoute,
          sitesStages: dragDropRoute.sitesStages.map((sitesStage) => ({
            ...sitesStage,
            sites: [...sitesStage.sites],
          })),
        }));
        const destinationSitesStage =
          newDragDropRoutes[+destinationIdData[1]].sitesStages[
            +destinationIdData[3]
          ];

        if (
          (destinationSitesStage.id === SiteStage.DESTINATION &&
            (destinationSitesStage.sites.some(
              (site) => site.id === changedDragDropSites[0].id
            ) ||
              destinationSitesStage.sites.length >= 1)) ||
          newDragDropSites[+sourceIdData[1]].id != destinationSitesStage.id
        ) {
          return;
        }

        destinationSitesStage.sites.splice(
          destinationIndex,
          0,
          changedDragDropSites[0]
        );

        if (destinationSitesStage.id !== SiteStage.DESTINATION) {
          setDragDropSites(newDragDropSites);
        }

        setDragDropRoutes(newDragDropRoutes);
      }
    } else {
      const newDragDropRoutes = dragDropRoutes.map((dragDropRoute) => ({
        ...dragDropRoute,
        sitesStages: dragDropRoute.sitesStages.map((sitesStage) => ({
          ...sitesStage,
          sites: [...sitesStage.sites],
        })),
      }));
      const sourceSitesStage =
        newDragDropRoutes[+sourceIdData[1]].sitesStages[+sourceIdData[3]];
      const changedDragDropSites = sourceSitesStage.sites.splice(
        sourceIndex,
        1
      );

      if (destinationIdData[0] === 'dragDropSites') {
        const newDragDropSites = dragDropSites.map((dragDropSite) => ({
          ...dragDropSite,
          sites: [...dragDropSite.sites],
        }));

        if (newDragDropSites[+destinationIdData[1]].id != sourceSitesStage.id)
          return;

        newDragDropSites[+destinationIdData[1]].sites.splice(
          destinationIndex,
          0,
          changedDragDropSites[0]
        );

        if (
          newDragDropSites[+destinationIdData[1]].id !== SiteStage.DESTINATION
        ) {
          setDragDropSites(newDragDropSites);
        }

        setDragDropRoutes(newDragDropRoutes);
      } else {
        const destinationSitesStage =
          newDragDropRoutes[+destinationIdData[1]].sitesStages[
            +destinationIdData[3]
          ];

        if (
          (destinationSitesStage.id === SiteStage.DESTINATION &&
            (destinationSitesStage.sites.some(
              (site) => site.id === changedDragDropSites[0].id
            ) ||
              destinationSitesStage.sites.length >= 1)) ||
          destinationSitesStage.id != sourceSitesStage.id
        ) {
          return;
        }

        destinationSitesStage.sites.splice(
          destinationIndex,
          0,
          changedDragDropSites[0]
        );

        setDragDropRoutes(newDragDropRoutes);
      }
    }
  };

  const handleOnDragEnd = (result) => {
    const { source, destination } = result || {};
    if (!source || !destination) {
      return;
    }

    const { droppableId: sourceDroppableId, index: sourceIndex } = source;
    const { droppableId: destinationDroppableId, index: destinationIndex } =
      destination;

    handleMoveSite(
      { id: sourceDroppableId, index: sourceIndex },
      { id: destinationDroppableId, index: destinationIndex }
    );
  };

  useEffect(() => {
    let newIsSitesStageDragDropRoutesInvalid = false;
    let newIsModifiedDragDropRoutes = false;

    dragDropRoutes.forEach((dragDropRoute, dragDropRouteIndex) => {
      let numOfSiteEmpty = 0;

      dragDropRoute.sitesStages.forEach((sitesStage) => {
        const oldSiteStageIds = routesSetting[dragDropRouteIndex].sitesByStage[
          sitesStage.id
        ]
          .map((site) => site.id)
          .join(',');
        const newSiteStageIds = sitesStage.sites
          .map((site) => site.id)
          .join(',');

        if (!newSiteStageIds) {
          numOfSiteEmpty++;
        }

        if (
          !newIsModifiedDragDropRoutes &&
          oldSiteStageIds != newSiteStageIds
        ) {
          newIsModifiedDragDropRoutes = true;
        }
      });

      if (
        !newIsSitesStageDragDropRoutesInvalid &&
        numOfSiteEmpty &&
        numOfSiteEmpty != dragDropRoute.sitesStages.length
      ) {
        newIsSitesStageDragDropRoutesInvalid = true;
      }
    });

    if (
      newIsSitesStageDragDropRoutesInvalid != isSitesStageDragDropRoutesInvalid
    ) {
      setIsSitesStageDragDropRoutesInvalid(
        newIsSitesStageDragDropRoutesInvalid
      );
    }

    if (newIsModifiedDragDropRoutes != isModifiedDragDropRoutes) {
      setIsModifiedDragDropRoutes(newIsModifiedDragDropRoutes);
    }
  }, [dragDropRoutes]);

  useEffect(() => {
    const newDragDropSites = dragDropSites.map((dragDropSite) => ({
      ...dragDropSite,
      sites: sitesByStage ? sitesByStage[dragDropSite.id] : [],
    }));
    setDragDropSites(newDragDropSites);
  }, [sitesByStage]);

  useEffect(() => {
    const newtDragDropRoutes = routesSetting.map((routesSetting) => ({
      id: routesSetting.id,
      name: routesSetting.name,
      sitesStages: [
        {
          id: SiteStage.SOURCE,
          translationTitle:
            'recycle_chains.routes_setting_modal.site_stage.source',
          sites: routesSetting.sitesByStage[SiteStage.SOURCE] || [],
        },
        {
          id: SiteStage.DESTINATION,
          translationTitle:
            'recycle_chains.routes_setting_modal.site_stage.destination',
          sites: routesSetting.sitesByStage[SiteStage.DESTINATION] || [],
        },
      ],
    }));
    setDragDropRoutes(newtDragDropRoutes);
    setIsModifiedDragDropRoutes(false);
  }, [routesSetting]);

  return (
    <div className={styles.recycleChainRoutesSettingModal}>
      <Modal>
        <div className={styles.recycleChainRoutesSettingModalHeader}>
          <h3 className={styles.recycleChainRoutesSettingModalTitle}>
            {t('recycle_chains.routes_setting_modal.title', {
              operator_label: t(
                `common.operator_label.${operatorType.toLowerCase()}`
              ),
            })}
          </h3>
          <div
            className={styles.recycleChainRoutesSettingModalIconClose}
            onClick={onClose}
          >
            <IconClose width={12} height={12} strokeWidth={2} />
          </div>
        </div>

        <Form
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onSubmit={(values) => {
            const sitesByStage = {
              [SiteStage.SOURCE]:
                dragDropSites.find(
                  (dragDropSite) => dragDropSite.id === SiteStage.SOURCE
                )?.sites || [],
              [SiteStage.DESTINATION]:
                dragDropSites.find(
                  (dragDropSite) => dragDropSite.id === SiteStage.DESTINATION
                )?.sites || [],
            };
            const routesSetting = dragDropRoutes.map((dragDropRoute) => ({
              id: dragDropRoute.id,
              name: dragDropRoute.name,
              sitesByStage: {
                [SiteStage.SOURCE]:
                  dragDropRoute.sitesStages.find(
                    (sitesStage) => sitesStage.id === SiteStage.SOURCE
                  )?.sites || [],
                [SiteStage.DESTINATION]:
                  dragDropRoute.sitesStages.find(
                    (sitesStage) => sitesStage.id === SiteStage.DESTINATION
                  )?.sites || [],
              },
            }));

            onSubmit?.(sitesByStage, routesSetting);
          }}
          initialValues={{
            siteStage: SiteStage.SOURCE,
          }}
        >
          {(props) => {
            const currentDragDropSiteIndex = dragDropSites.findIndex(
              (dragDropSite) => dragDropSite.id === props.values.siteStage
            );

            return (
              <form
                className={styles.recycleChainRoutesSettingModalContent}
                onSubmit={props.handleSubmit}
              >
                <DragDropContext
                  onDragEnd={(result) => handleOnDragEnd(result)}
                >
                  <div
                    className={styles.recycleChainRoutesSettingModalContentLeft}
                  >
                    <SelectField name="siteStage" options={siteOptions()} />

                    <Droppable
                      droppableId={`dragDropSites-${currentDragDropSiteIndex}`}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={styles.recycleChainRoutesSettingModalSites}
                        >
                          {dragDropSites
                            .find(
                              (dragDropSite) =>
                                dragDropSite.id === props.values.siteStage
                            )
                            ?.sites.map((site, index) => (
                              <Draggable
                                key={`dragDropSites-${currentDragDropSiteIndex}-siteId_${site.id.toString()}`}
                                draggableId={`dragDropSites-siteId_${site.id.toString()}`}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    key={index}
                                    className={
                                      styles.recycleChainRoutesSettingModalSite
                                    }
                                  >
                                    {site.name}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>

                  <div
                    className={styles.recycleChainRoutesSettingModalContentLine}
                  />

                  <div
                    className={
                      styles.recycleChainRoutesSettingModalContentRight
                    }
                  >
                    <div
                      className={
                        styles.recycleChainRoutesSettingModalContentRoutesSetting
                      }
                    >
                      {dragDropRoutes.map((dragDropRoute, routeIndex) => (
                        <div
                          key={routeIndex}
                          className={
                            styles.recycleChainRoutesSettingModalRouteSetting
                          }
                        >
                          <div
                            className={
                              styles.recycleChainRoutesSettingModalRouteSettingHeader
                            }
                          >
                            <h4
                              className={
                                styles.recycleChainRoutesSettingModalRouteSettingTitle
                              }
                            >
                              {dragDropRoute.name}
                            </h4>
                          </div>
                          <div
                            className={
                              styles.recycleChainRoutesSettingModalRouteSettingContent
                            }
                          >
                            {dragDropRoute.sitesStages.map(
                              (sitesStage, sitesStageIndex) => (
                                <div
                                  key={`${routeIndex}-${sitesStageIndex}`}
                                  className={
                                    styles.recycleChainRoutesSettingModalRouteSettingList
                                  }
                                >
                                  <div
                                    className={
                                      styles.recycleChainRoutesSettingModalRouteSettingListHeader
                                    }
                                  >
                                    <h5
                                      className={
                                        styles.recycleChainRoutesSettingModalRouteSettingListIndex
                                      }
                                    >
                                      {sitesStageIndex + 1}
                                    </h5>
                                    <h5
                                      className={
                                        styles.recycleChainRoutesSettingModalRouteSettingListTitle
                                      }
                                    >
                                      {t(sitesStage.translationTitle)}
                                    </h5>
                                  </div>
                                  <Droppable
                                    droppableId={`dragDropRoutes-${routeIndex}-sitesStage-${sitesStageIndex}`}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={
                                          styles.recycleChainRoutesSettingModalRouteSettingListSites
                                        }
                                      >
                                        {sitesStage.sites.map(
                                          (site, siteIndex) => (
                                            <Draggable
                                              key={`dragDropRoutes-${routeIndex}-sitesStage-${sitesStageIndex}-siteId-${site.id}`}
                                              draggableId={`dragDropRoutes-${routeIndex}-sitesStage-${sitesStageIndex}-siteId-${site.id}`}
                                              index={siteIndex}
                                            >
                                              {(provided) => (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  key={`${routeIndex}-${sitesStageIndex}-${siteIndex}`}
                                                  className={
                                                    styles.recycleChainRoutesSettingModalSite
                                                  }
                                                >
                                                  {site.name}
                                                  <IconClose
                                                    width={10}
                                                    height={10}
                                                    strokeWidth={2.5}
                                                    onClick={() => {
                                                      const dragDropSiteIndex =
                                                        dragDropSites.findIndex(
                                                          (dragDropSite) =>
                                                            dragDropSite.id ===
                                                            sitesStage.id
                                                        );
                                                      handleMoveSite(
                                                        {
                                                          id: `dragDropRoutes-${routeIndex}-sitesStage-${sitesStageIndex}`,
                                                          index: siteIndex,
                                                        },
                                                        {
                                                          id: `dragDropSites-${dragDropSiteIndex}`,
                                                          index:
                                                            dragDropSites[
                                                              dragDropSiteIndex
                                                            ].sites.length,
                                                        }
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              )}
                                            </Draggable>
                                          )
                                        )}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      ))}
                    </div>

                    <div
                      className={
                        styles.recycleChainRoutesSettingModalContentRightLine
                      }
                    />

                    <div
                      className={
                        styles.recycleChainRoutesSettingModalContentFooter
                      }
                    >
                      <button
                        type="submit"
                        className={
                          styles.recycleChainRoutesSettingModalContentSubmitButton
                        }
                        disabled={
                          isSitesStageDragDropRoutesInvalid ||
                          !isModifiedDragDropRoutes
                        }
                      >
                        {t('common.button.keep')}
                      </button>
                    </div>
                  </div>
                </DragDropContext>
              </form>
            );
          }}
        </Form>
      </Modal>
    </div>
  );
};
