import styles from './FilterItemDropDown.module.scss';
import { useTranslation } from 'react-i18next';
import { IconDropDown } from 'components/atoms/icons/IconDropDown';
import { CheckBoxCardV2 } from '../CheckBoxCardV2';

interface Props {
  value: string;
  isOpen: boolean;
  handleOpen: () => void;
  descriptionOptions: string[];
  selectedDescriptions: string[];
  selectEmissionItem: (emissionDescription: string) => void;
}

export function FilterItemDropDown({
  value,
  isOpen,
  handleOpen,
  descriptionOptions,
  selectedDescriptions,
  selectEmissionItem,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <span onClick={handleOpen} className={styles.itemFilter}>
        {t(value)}
        <IconDropDown />
      </span>
      {isOpen && (
        <ul className={styles.dropDown}>
          {descriptionOptions.map((description) => {
            return (
              <li
                key={description}
                onClick={() => {
                  selectEmissionItem(description);
                }}
                className={`${
                  selectedDescriptions.find((item) => item === description)
                    ? styles.selected
                    : ''
                }`}
              >
                <CheckBoxCardV2
                  type="dark"
                  checked={
                    !!selectedDescriptions.find((item) => item === description)
                  }
                >
                  <span className={styles.optionName}>{description}</span>
                </CheckBoxCardV2>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
}
