// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect } from 'react';
import styles from './PhotoPickerMultipleField.module.scss';
import { IconPhoto } from 'components/atoms/icons/IconPhoto';
import { IconClose } from 'components/atoms/icons/IconClose';
import { MAX_IMAGE_FILE_SIZE, typeImages } from 'util/ConstantValues';
import { useTranslation } from 'react-i18next';
import { validateFiles } from 'util/validator';

interface Props {
  label?: string;
  onChangePhoto: (
    results: { name: string; file: string; size: number }[]
  ) => void;
  defaultFile?: { name: string; file: string; size?: number }[];
  maxLength?: number;
  onRemove?: (item: { name: string; file: string }) => void;
  onError?: (isError: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getBase64(file: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve({
        base64: reader.result,
        name: file.name,
        size: file.size / (1024 * 1024),
        blob: file,
      });
  });
}

const PhotoPickerMultipleFields = ({
  label,
  onChangePhoto,
  defaultFile,
  maxLength,
  onRemove,
  onError,
}: Props) => {
  const [currentFile, setCurrentFile] = React.useState<
    { file: string; name: string; size: number; blob?: File }[]
  >([]);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [error, setError] = React.useState('');
  const { t } = useTranslation();

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return;
    }

    if (maxLength && currentFile.length + e.target.files.length > maxLength) {
      setError(t('messages.M_055', { max: maxLength }) || '');
      return;
    }

    const msgError = validateFiles({
      files: e.target.files,
      fileTypes: typeImages,
      maxSize: MAX_IMAGE_FILE_SIZE,
      errorMessages: {
        wrongType: t('messages.M_017'),
        sizeExceeded: t('messages.M_018', {
          value: `${MAX_IMAGE_FILE_SIZE}MB`,
        }),
      },
    });

    if (msgError) {
      setError(msgError);
      onError?.(true);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filePathsPromises: any = [];
    Array.from(e.target.files).forEach((file) => {
      filePathsPromises.push(getBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const output = filePaths.map((result: any) => ({
      file: result.base64,
      name: result.name,
      size: result.size || 0,
      blob: result.blob,
    }));
    const newCurrentFile = currentFile.concat(output);
    const totalSize = newCurrentFile.reduce(
      (total, file) => total + file.size,
      0
    );
    if (totalSize > MAX_IMAGE_FILE_SIZE) {
      setError(
        t('messages.M_018', { value: `${MAX_IMAGE_FILE_SIZE}MB` }) || ''
      );
      onError?.(true);
      return;
    }
    onError?.(false);
    setError('');
    setCurrentFile(newCurrentFile);
    onChangePhoto(newCurrentFile);
  };

  useEffect(() => {
    defaultFile?.length &&
      !currentFile.length &&
      setCurrentFile(
        defaultFile.map((file) => ({ ...file, size: file.size || 0 }))
      );
  }, [defaultFile]);

  return (
    <div className={styles.photoPickerField}>
      {label && <p>{label}</p>}
      <div className={`${styles.photoPickerContent} selection-none`}>
        {currentFile.length ? (
          <div>
            {currentFile.map((file) => {
              return (
                <div className={styles.preview} key={file.name}>
                  <div className={styles.pickerFile}>
                    <div className={styles.photo}>
                      {file.name || file.blob ? (
                        <img
                          className={styles.photo}
                          src={
                            file?.blob
                              ? URL.createObjectURL(file.blob)
                              : `${process.env.REACT_APP_CLOUDFRONT}${file.name}`
                          }
                        />
                      ) : (
                        <IconPhoto />
                      )}
                    </div>
                    <p>{file.name || 'Image Name'}</p>
                  </div>
                  <div
                    onClick={() => {
                      if (inputRef && inputRef.current?.value) {
                        inputRef.current.value = '';
                      }
                      const newFiles = currentFile.filter(
                        (item) => item.name !== file.name
                      );
                      const totalSize = newFiles.reduce(
                        (total, file) => total + file.size,
                        0
                      );
                      if (totalSize <= MAX_IMAGE_FILE_SIZE) {
                        setError('');
                        onError?.(false);
                      }
                      setCurrentFile(newFiles);
                      onChangePhoto(newFiles);
                      onRemove && onRemove(file);
                    }}
                    className={styles.closeButton}
                  >
                    <IconClose />
                  </div>
                </div>
              );
            })}

            <div className={styles.borderBt} />
          </div>
        ) : null}
        <div className={`${styles.picker}`}>
          <div className={styles.contentLeft}>
            {!currentFile.length && (
              <div className={styles.photo}>
                <IconPhoto />
              </div>
            )}
            <p className={styles.textColorCommon}>{t('common.upload_image')}</p>
          </div>
          <div>
            <label
              htmlFor="file-upload"
              className={`${styles.customFileUpload} ${
                maxLength && currentFile.length >= maxLength
                  ? styles.disabled
                  : ''
              }`}
            >
              {t('common.button.upload')}
            </label>
            <input
              disabled={maxLength ? currentFile.length >= maxLength : false}
              ref={inputRef}
              id="file-upload"
              type="file"
              accept=".jpg, .jpeg, .png, .heic"
              onChange={onSelectFile}
              multiple
            />
          </div>
        </div>
      </div>
      {error && (
        <div className={styles.error}>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default PhotoPickerMultipleFields;
