import SideMenu from 'components/molecules/SideMenu';
import { isEqual, slice, split } from 'lodash';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { TMenuItem } from 'types/purchase';
import { mergeClasses } from 'util/commons';
import { IconChevronDown } from '../icons/IconChevronDown';

import './MenuItem.scss';

type Props = {
  value: string;
  children?: TMenuItem[];
  isChild?: boolean;
  path?: string;
  action?: VoidFunction;
  isCollapse?: boolean;
};

const MenuItem = ({
  value,
  children = [],
  isChild = false,
  path,
  action,
  isCollapse = false,
}: Props) => {
  const location = useLocation();
  const [isShow, setShow] = useState<boolean>(false);

  const onShowChild = () => {
    if (children.length > 0) setShow(!isShow);
    if (isChild) setShow(true);
    action?.();
  };

  const active = useMemo(() => {
    const locationSplit = slice(split(location.pathname, '/'), 1, 3);
    if (path && locationSplit.includes(path)) {
      if (locationSplit.length > 1) {
        setShow(!isShow);
      }
      setShow(true);
      return true;
    }

    if (isChild) {
      const pathSplit = slice(split(path, '/'), 1, 3);
      return isEqual(locationSplit, pathSplit);
    }

    return false;
  }, [path, location]);

  return (
    <div key={value}>
      <div
        className={mergeClasses(
          'menu-item',
          isChild ? 'child' : 'parent',
          active ? 'active' : ''
        )}
        onClick={onShowChild}
      >
        {value}
        {isCollapse && <IconChevronDown />}
      </div>
      {isShow && (
        <SideMenu
          menuItems={children}
          key={`${value}_children`}
          active={active}
          isChild
        />
      )}
    </div>
  );
};

export default MenuItem;
