// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import AdminSelectOperatorType from 'components/molecules/AdminSelectOperatorType';
import AdminUploadFileCSVModal from 'components/molecules/AdminUploadFileCSVModal';
import AdminDownloadDataModal from 'components/organisms/AdminDownloadDataModal';
import AdminResourceManagementTable, {
  FilterObject,
} from 'components/organisms/AdminResourceManagementTable/AdminResourceManagementTable';
import EditCompressionsResourceDataModal from 'components/organisms/EditCompressionsResourceDataModal';
import EditEmissionResourceDataModal from 'components/organisms/EditEmissionResourceDataModal';
import EditProducerResourceDataModal from 'components/organisms/EditProducerResourceDataModal';
import EditRecycleResourceDataModal from 'components/organisms/EditRecycleResourceDataModal';
import { AdminTemplate } from 'components/templates/AdminTemplate';
import { stringify } from 'querystring';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Path } from 'routes';
import { createAxios } from 'ts/createAxios';
import {
  FetchResourceData,
  ResourceManagementItem,
} from 'util/adminResourceManagementTypes';
import { DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import { OperatorType } from 'util/Enums';
import { handleError } from 'util/errorHandler';
import { useQuery } from 'util/queryParams';

const AdminEmissionResourceManagement = () => {
  const { t } = useTranslation();
  const [editData, setEditData] = useState<ResourceManagementItem>();
  const [uploadEndpoint, setUploadEndpoint] = useState<string>();
  const [isOpenSelectImportType, setImportType] = useState(false);
  const [isOpenDownloadData, setOpenDownloadData] = useState(false);
  const [total, setTotal] = useState(0);
  const [listResourceManagement, setListResourceManagement] = useState<
    ResourceManagementItem[]
  >([]);
  const [filterObject, setFilterObject] = useState<FilterObject>();
  const query = useQuery();
  const operatorType = query.get('operatorType');
  const keyword = query.get('keyword');

  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const api = createAxios();
  const fetchResourceList = async (
    values?: { inputSearch?: string; operatorType?: string },
    nextPage?: number,
    forceRefresh?: boolean
  ) => {
    try {
      const params = stringify({
        page: nextPage || DEFAULT_PAGE,
        take: PAGE_SIZE,
        keyword: values?.inputSearch || '',
        operatorType: values?.operatorType || OperatorType.Emissions,
      });
      const searchQuery = values?.inputSearch
        ? `&keyword=${values?.inputSearch}`
        : '';
      history.push(
        `/admin-data-resource-management?operatorType=${values?.operatorType}` +
          searchQuery
      );
      const { data } = await api.get<FetchResourceData>(`/resources?${params}`);

      setListResourceManagement(
        forceRefresh
          ? data.resources
          : [...listResourceManagement, ...data.resources]
      );
      setTotal(data.total);
      setCurrentPage(data.page);
    } catch (error) {
      handleError(error);
    }
  };
  const onUpdateSuccess = () => {
    setEditData(undefined);
    toast.success(t('messages.M_011'));
    fetchResourceList(filterObject, DEFAULT_PAGE, true);
  };

  useEffect(() => {
    if (!filterObject && (keyword || operatorType)) {
      const filter = {
        inputSearch: keyword || '',
        operatorType: operatorType || 'Emissions',
      };
      setFilterObject(filter);
      fetchResourceList(filter);
    } else if (!filterObject && !keyword && !operatorType) {
      const filter = {
        operatorType: 'Emissions',
      };
      setFilterObject(filter);
      fetchResourceList(filter);
    }
  }, [operatorType, keyword]);

  return (
    <AdminTemplate
      h1={'資源一覧画面'}
      active={'資源一覧'}
      breadcrumb={[
        { label: 'データ編集', href: Path.adminEmissionResourceManagement },
        { label: '資源一覧' },
      ]}
    >
      <AdminResourceManagementTable
        onClickEdit={(item) => {
          setEditData(item);
        }}
        onClickImport={() => {
          setImportType(true);
        }}
        onClickDownloadData={() => setOpenDownloadData(true)}
        fetchResourceList={fetchResourceList}
        onChangePage={setCurrentPage}
        currentPage={currentPage}
        total={total}
        listResourceManagement={listResourceManagement}
        filterObject={filterObject}
        onChangeFilterObject={setFilterObject}
      />
      {editData && editData.type === OperatorType.Emissions && (
        <EditEmissionResourceDataModal
          editData={editData}
          onClose={() => {
            setEditData(undefined);
          }}
          onUpdateResource={onUpdateSuccess}
        />
      )}
      {editData && editData.type === OperatorType.Compressions && (
        <EditCompressionsResourceDataModal
          editData={editData}
          onClose={() => {
            setEditData(undefined);
          }}
          onUpdateSuccess={onUpdateSuccess}
        />
      )}
      {editData && editData.type === OperatorType.Recycle && (
        <EditRecycleResourceDataModal
          editData={editData}
          onClose={() => {
            setEditData(undefined);
          }}
          onUpdateSuccess={onUpdateSuccess}
        />
      )}
      {editData && editData.type === OperatorType.Producer && (
        <EditProducerResourceDataModal
          editData={editData}
          onClose={() => {
            setEditData(undefined);
          }}
          onUpdateSuccess={onUpdateSuccess}
        />
      )}
      {uploadEndpoint && (
        <AdminUploadFileCSVModal
          onClose={() => {
            setUploadEndpoint(undefined);
          }}
          endPoint={uploadEndpoint}
          onSubmitImportFile={() => {
            setUploadEndpoint(undefined);
            fetchResourceList(filterObject, DEFAULT_PAGE, true);
          }}
          isImportDataResource
        />
      )}

      {isOpenSelectImportType && (
        <AdminSelectOperatorType
          onClose={() => {
            setImportType(false);
          }}
          onSubmit={(endPoint) => {
            setUploadEndpoint(endPoint);
            setImportType(false);
          }}
        />
      )}

      {isOpenDownloadData && (
        <AdminDownloadDataModal onClose={() => setOpenDownloadData(false)} />
      )}
    </AdminTemplate>
  );
};

export default AdminEmissionResourceManagement;
