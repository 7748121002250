// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import snakeCase from 'lodash/snakeCase';
import { useTranslation } from 'react-i18next';
import { DashboardsPoolResources } from 'util/Types';
import { mergeClasses, normalizeNumber } from 'util/commons';
import styles from './PoolDashboardResourceTable.module.scss';

type PoolDashboardResourceTableProps = {
  dashboardPoolResources: DashboardsPoolResources[];
  onClick?: () => void;
};

const PoolDashboardResourceTable: React.FC<PoolDashboardResourceTableProps> = ({
  dashboardPoolResources,
  onClick,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <table className={styles.PoolDashboardResourceTable} onClick={onClick}>
      <thead>
        <tr>
          <th className={styles.SmallCol}>
            {t('pool_dashboard.resources_table.header.date')}
          </th>
          <th className={styles.StationCol}>
            {t('pool_dashboard.resources_table.header.stations')}
          </th>
          <th className={styles.SmallCol}>
            {t('pool_dashboard.resources_table.header.site_type')}
          </th>
          <th>{t('pool_dashboard.resources_table.header.site_name')}</th>
          <th>{t('pool_dashboard.resources_table.header.collect_company')}</th>
          <th>{t('pool_dashboard.resources_table.header.material')}</th>
          <th>{t('pool_dashboard.resources_table.header.waste')}</th>
          <th className={styles.SmallCol}>
            {t('pool_dashboard.resources_table.header.weight')}
          </th>
        </tr>
      </thead>

      <tbody>
        {dashboardPoolResources.length > 0 ? (
          dashboardPoolResources.map((item, index) => (
            <tr key={index}>
              <td className={styles.SmallCol}>
                <p>{item.date}</p>
              </td>
              <td className={styles.StationCol}>
                <p>{item.siteStations.map(({ name }) => name).join(', ')}</p>
              </td>
              <td className={styles.SmallCol}>
                <p>{item.siteType}</p>
              </td>
              <td>
                <p>{item.siteName}</p>
              </td>
              <td>
                <p>
                  {item.collectionSites.length
                    ? item.collectionSites.map(({ name }) => name).join(', ')
                    : t('pool_dashboard.filter.no_collection_linkage_option')}
                </p>
              </td>
              <td>
                <p>
                  {i18n.language === 'ja'
                    ? item.materialName
                    : item.englishMaterialName || item.materialName}
                </p>
              </td>
              <td>
                <p>
                  {item.wasteTypeMaster
                    ?.map((wasteType) =>
                      t(`pool_dashboard.filter.${snakeCase(wasteType)}`)
                    )
                    .join(', ')}
                </p>
              </td>
              <td className={mergeClasses(styles.SmallCol, styles.Weight)}>
                <p>{normalizeNumber({ value: item.weight })}</p>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={8} className={styles.noData}>
              {t('messages.M_008')}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default PoolDashboardResourceTable;
