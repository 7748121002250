// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface SigninState {
  isChecking: boolean;
  isSignin: boolean;
  pathAfterSignin: string;
}

const initialState: SigninState = {
  isChecking: true,
  isSignin: false,
  pathAfterSignin: '',
};

export const signinSlice = createSlice({
  name: 'signin',
  initialState,
  reducers: {
    setIsCheckingSignin: (state, action: PayloadAction<boolean>) => {
      state.isChecking = action.payload;
    },
    setIsSignin: (state, action: PayloadAction<boolean>) => {
      state.isSignin = action.payload;
    },
    setPathAfterSignin: (state, action: PayloadAction<string>) => {
      state.pathAfterSignin = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetPath: (state) => {
      return initialState;
    },
  },
});

export const {
  setIsCheckingSignin,
  setIsSignin,
  setPathAfterSignin,
  resetPath,
} = signinSlice.actions;

export const selectIsSignin = (state: RootState) => state.signin.isSignin;
export const selectIsCheckingSignin = (state: RootState) =>
  state.signin.isChecking;
export const selectPathAfterSignin = (state: RootState) =>
  state.signin.pathAfterSignin;
export default signinSlice.reducer;
