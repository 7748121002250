// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchNextStageSites } from 'apis/site_purchase/purchase';
import { Label } from 'components/atoms/Label';
import { Modal } from 'components/atoms/Modal';
import { IconClose } from 'components/atoms/icons/IconClose';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { HistoryPurchaseDelivery } from 'types/purchase';
import { addition } from 'util/Calc';
import { DATE_FORMAT } from 'util/ConstantValues';
import { CommonSite } from 'util/Types';
import { mergeClasses, normalizeNumber } from 'util/commons';
import { validateDateTime, validateSelectField } from 'util/validator';
import { CalendarField } from '../CalendarField';
import { InputDataListField } from '../InputDataListField';
import { SelectField } from '../SelectField';
import TimeInputField from '../TimeInputField';
import styles from './index.module.scss';

type Props = {
  onClose: () => void;
  onSubmit: (form: any) => void;
  data: HistoryPurchaseDelivery;
};

const UpdateHistoryResourceManagement: React.FC<Props> = ({
  onClose,
  data,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [deliveryToSiteOptions, setDeliveryToSiteOptions] = useState<
    CommonSite[]
  >([]);
  const [totalWeight, setTotalWeight] = useState<number>(0);

  useEffect(() => {
    const total = data.contentDelivery.reduce(
      (prev, emission) => addition(prev, parseFloat(emission.weight)),
      0
    );
    setTotalWeight(total);
  }, [setTotalWeight]);

  const handleSubmit = (form: any) => {
    onSubmit(form);
  };

  useEffect(() => {
    if (data) {
      fetchNextStageSites(
        { ids: data.contentDelivery.map((item) => item.processingObjectId) },
        (data) => {
          setDeliveryToSiteOptions(data);
        }
      );
    }
  }, [data]);

  return (
    <section className={styles.historyModal}>
      <Modal>
        <div className={styles.customTitle}>
          <div className={styles.title}>
            {t('purchase_page.histories.title') || ''}
          </div>
          <div className={styles.top} onClick={onClose}>
            <IconClose />
          </div>
        </div>

        <div className={styles.inputForm}>
          <Form
            onSubmit={handleSubmit}
            initialValues={data}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            validate={(values) => {
              const requestedDatetime =
                values.date && values.time.length === 5
                  ? validateDateTime({
                      compareValue: dayjs(values.date + ' ' + values.time),
                    })
                  : undefined;

              return {
                deliveryToSiteId: validateSelectField(
                  t('purchase_page.histories.destination'),
                  values.deliveryToSiteId
                ),
                time: requestedDatetime,
              };
            }}
          >
            {(props) => {
              return (
                <form className={styles.formInput}>
                  <div className={styles.textFrame}>
                    <div className={styles.time}>
                      <Label
                        className={styles.label}
                        text={
                          t('purchase_page.histories.date_registration') || ''
                        }
                        tag={'div'}
                        required
                      />
                      <div className={styles.inputFrame}>
                        <CalendarField
                          value={getFormatDateWithCurrentTimezone(
                            data.updateDatetime,
                            DATE_FORMAT.full_date
                          )}
                          minDate={new Date()}
                          placeholder={t(
                            'purchase_page.resource.date_placeholder'
                          )}
                          onChange={(value) => {
                            props.form.mutators.setValue('date', value);
                          }}
                          isDarkMode
                          disabled
                        />
                        <div className={styles.timeFrame}>
                          <TimeInputField
                            name=""
                            value={getFormatDateWithCurrentTimezone(
                              data.updateDatetime,
                              DATE_FORMAT.hhss
                            )}
                            isDarkMode
                            onChangeTime={() => null}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.time}>
                      <Label
                        className={styles.label}
                        text={t('purchase_page.histories.date') || ''}
                        tag={'div'}
                        required
                      />
                      <div className={styles.inputFrame}>
                        <CalendarField
                          value={getFormatDateWithCurrentTimezone(
                            data.deliveriedDatetime,
                            DATE_FORMAT.full_date
                          )}
                          minDate={new Date()}
                          placeholder={t(
                            'purchase_page.resource.date_placeholder'
                          )}
                          onChange={(value) => {
                            props.form.mutators.setValue('date', value);
                          }}
                          isDarkMode
                          readOnly
                        />
                        <div className={styles.timeFrame}>
                          <TimeInputField
                            name="time"
                            value={getFormatDateWithCurrentTimezone(
                              data.deliveriedDatetime,
                              DATE_FORMAT.hhss
                            )}
                            isDarkMode
                            onChangeTime={(time) => {
                              props.form.mutators.setValue('time', time);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.textFrame}>
                    <div className={styles.time}>
                      <InputDataListField
                        label={t('purchase_page.histories.ship_form')}
                        name="deliveryFromSiteName"
                        placeholder={t('purchase_page.histories.ship_form')}
                        type="text"
                        required
                        disabled
                        maxlength={50}
                        isDarkMode
                      />
                    </div>

                    <div className={styles.time}>
                      <SelectField
                        label={t('purchase_page.histories.destination')}
                        name="deliveryToSiteId"
                        value={props.values.deliveryToSiteId}
                        options={deliveryToSiteOptions.map((i) => {
                          return { label: i.name, value: i.id };
                        })}
                        placeholder={t('purchase_page.histories.destination')}
                        showPlaceholderOption
                        onChange={() => null}
                        required
                        isDarkmode
                      />
                    </div>
                  </div>

                  <div className={styles.body}>
                    <table className={`primary-table ${styles.primaryTable}`}>
                      <thead>
                        <tr>
                          <th className={styles.type}>
                            {t('purchase_page.histories.registration_id')}
                          </th>
                          <th className={styles.type}>
                            {t('purchase_page.resource.grade')}
                          </th>
                          <th className={styles.type}>
                            {t('purchase_page.histories.material_type')}
                          </th>

                          <th className={styles.label}>
                            {t('purchase_page.histories.packing')}
                          </th>
                          <th className={styles.weight}>
                            {t('purchase_page.resource.weight')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.contentDelivery.length > 0 ? (
                          data.contentDelivery?.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td className={styles.type}>
                                  {item.processingObjectId}
                                </td>
                                <td className={styles.type}>
                                  {item.gradeName}
                                </td>
                                <td className={styles.type}>
                                  {item.materialTypeName}
                                </td>
                                <td className={styles.label}>
                                  {item.packingStyle}
                                </td>
                                <td className={styles.weight}>
                                  {normalizeNumber({ value: item.weight })}
                                  <span>kg</span>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <div
                            className={mergeClasses('no-data', styles.noData)}
                          >
                            {t('messages.M_008')}
                          </div>
                        )}
                      </tbody>
                    </table>
                    <div className={styles.total}>
                      <p>
                        {t('purchase_page.resource.total')}&nbsp;
                        <span>
                          {totalWeight &&
                            normalizeNumber({ value: totalWeight })}
                          kg
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className={styles.submit}>
                    <button
                      className={styles.submitButton}
                      type="submit"
                      disabled={props.invalid || props.values.time?.length < 5}
                      onClick={props.handleSubmit}
                    >
                      {t('purchase_page.histories.edit_button')}
                    </button>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
      </Modal>
    </section>
  );
};
export default UpdateHistoryResourceManagement;
