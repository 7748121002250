// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './style.module.scss';

type TooltipProps = {
  children?: React.ReactNode;
  content?: React.ReactNode;
};

const Tooltip: React.FC<TooltipProps> = ({ children, content }) => {
  if (!children && !content) {
    return null;
  }

  if (!content) {
    return <>{children}</>;
  }

  return (
    <div className={styles.tooltip}>
      {children}
      <span className={styles.tooltipText}>{content}</span>
    </div>
  );
};

export default Tooltip;
