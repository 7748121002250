// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useState, useEffect } from 'react';
import { ImagePath } from 'util/ConstantValues';
import { DbManager } from 'util/DbManager';

type OfflineFirstImageProps = Omit<
  React.ImgHTMLAttributes<HTMLImageElement>,
  'src'
> & {
  cacheBucket: 'categoryImages';
  src?: string | null;
  fallbackImage?: string | false;
  onlineHost?: string;
};

const OfflineFirstImage: React.FC<OfflineFirstImageProps> = ({
  src,
  fallbackImage,
  cacheBucket,
  onlineHost,
  ...rest
}) => {
  const [image, setImage] = useState<{
    url?: string;
    type?: 'online' | 'offline';
  }>();

  useEffect(() => {
    const getImages = async () => {
      if (!src) {
        if (fallbackImage) {
          setImage({
            url: fallbackImage,
            type: 'online',
          });
        }

        return;
      }

      const cachedImages = await DbManager[cacheBucket]
        .where('path')
        .equals(src)
        .first();

      if (cachedImages?.content) {
        setImage({
          url: URL.createObjectURL(cachedImages.content),
          type: 'offline',
        });
      } else {
        setImage({
          url: `${onlineHost}${src}`,
          type: 'online',
        });
      }
    };

    getImages();
  }, [src]);

  useEffect(() => {
    return () => {
      if (image?.url && image.type === 'offline') {
        URL.revokeObjectURL(image.url);
      }
    };
  }, [image]);

  return <img src={image?.url} {...rest} />;
};

OfflineFirstImage.defaultProps = {
  fallbackImage: ImagePath.defaultImageDarkMode,
  onlineHost: process.env.REACT_APP_CLOUDFRONT,
};

export default OfflineFirstImage;
