// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e?: any) => void;
}
export const IconEdit: React.FC<Props> = ({ onClick }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M10.4011 5.14053H5.11459C4.55377 5.14053 4.01591 5.36332 3.61935 5.75988C3.22279 6.15644 3 6.69429 3 7.25512V18.8854C3 19.4462 3.22279 19.984 3.61935 20.3806C4.01591 20.7772 4.55377 21 5.11459 21H16.7448C17.3057 21 17.8435 20.7772 18.2401 20.3806C18.6366 19.984 18.8594 19.4462 18.8594 18.8854V13.5989M17.3644 3.64551C17.5595 3.44355 17.7928 3.28245 18.0508 3.17163C18.3088 3.06081 18.5863 3.00247 18.867 3.00003C19.1478 2.99759 19.4263 3.0511 19.6861 3.15742C19.946 3.26374 20.1821 3.42076 20.3806 3.6193C20.5792 3.81785 20.7362 4.05395 20.8425 4.31382C20.9489 4.5737 21.0024 4.85215 20.9999 5.13292C20.9975 5.41369 20.9391 5.69117 20.8283 5.94916C20.7175 6.20715 20.5564 6.44048 20.3544 6.63554L11.2765 15.7135H8.28647V12.7234L17.3644 3.64551Z"
        stroke="#6F7175"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
