// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import { ModalType, TenantAuthen } from 'util/Enums';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import CreateUpdateSiteBaseForm from 'components/molecules/CreateUpdateSiteBaseForm';
import CreateUpdateSiteSizeForm from 'components/molecules/CreateUpdateSiteSizeForm';
import CreateUpdateSiteDateForm from 'components/molecules/CreateUpdateSiteDateForm';
import {
  baseEmissionSiteForm,
  EmissionSiteItem,
} from 'util/siteManagementTypes';
import { ModalSiteState } from 'pages/AdminSiteListManagement/AdminSiteListManagement';
import {
  fetchProvinceDistrictSuggestion,
  fetchSiteTypeSuggestion,
  getDetailSite,
} from 'apis/operator_site/siteApi';
import dayjs from 'dayjs';
import { uniqBy } from 'lodash';
interface Props {
  createUpdateSiteModal: ModalSiteState;
  onClose?: () => void;
  onCreateSite: (createEmissionSiteForm: EmissionSiteItem) => void;
  onUpdateSite: (upateEmissionSiteForm: EmissionSiteItem) => void;
}

const CreateUpdateEmissionSiteModal: React.FC<Props> = ({
  createUpdateSiteModal,
  onClose,
  onCreateSite,
  onUpdateSite,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [inputForm, setInputForm] =
    useState<EmissionSiteItem>(baseEmissionSiteForm);
  const [provinceSuggestion, setProvincSuggestion] = useState<string[]>([]);
  const [districtSuggestion, setDistrictSuggestion] = useState<string[]>([]);
  const [siteTypeSuggestion, setSiteTypeSuggestion] = useState<string[]>([]);

  useEffect(() => {
    fetchProvinceDistrictSuggestion(false, (data) => {
      if (data) {
        setProvincSuggestion(uniqBy(data.map((item) => item.province)));
        setDistrictSuggestion(uniqBy(data.map((item) => item.district)));
      }
    });
    fetchSiteTypeSuggestion((data) => {
      if (data) {
        setSiteTypeSuggestion(uniqBy(data.map((item) => item.siteType)));
      }
    });
  }, []);

  useEffect(() => {
    if (
      createUpdateSiteModal.type === ModalType.update &&
      createUpdateSiteModal?.siteEditing &&
      createUpdateSiteModal?.siteEditing.id
    ) {
      getDetailSite(createUpdateSiteModal.siteEditing.id, (data) => {
        if (data) {
          setInputForm({
            ...baseEmissionSiteForm,
            id: data.id || '',
            address: data.address || '',
            ediConfirmationKey: data.ediConfirmationKey || '',
            ediSubscriberNumber: data.ediSubscriberNumber || '',
            latitude: data.latitude ? data.latitude : '',
            longitude: data.longitude || '',
            postalCode: data.postalCode || '',
            name: data.name || '',
            englishName: data.englishName || '',
            workingDay: data.workingDay || [],
            startTime: data.startTime || '',
            endTime: data.endTime || '',
            startTime2: data.startTime2 || '',
            endTime2: data.endTime2 || '',
            staff: data.staff || '',
            staffTel: data.staffTel || '',
            staffEmail: data.staffEmail || '',
            billingAddress: data.billingAddress || '',
            dueDate: data.dueDate || 0,
            invoiceDate: data.invoiceDate || 0,
            paymentDate: data.paymentDate || 0,
            systemUsageFee: data.systemUsageFee || '',
            originalInvoice: data.originalInvoice || false,
            siteType: data.siteType || '',
            floorSpace: data.floorSpace || '',
            vehicleLimit: data.vehicleLimit || '',
            networkOperator: data.networkOperator || '',
            dataSyncTime: data?.dataSyncTime || null,
            emails: data.emails
              ? data.emails.map((item, index) => {
                  return {
                    id: +`${dayjs().valueOf()}${index}`,
                    value: item,
                  };
                })
              : [],
            images: data?.imageUrls
              ? data.imageUrls.map((item) => {
                  return {
                    name: item,
                    file: '',
                  };
                })
              : [],
            grade: data?.grades[0]?.name || '',
            district: data?.siteAddresses[0]?.district || '',
            province: data?.siteAddresses[0]?.province || '',
            tenantAuthen: data?.tenantAuthen || TenantAuthen.None,
            station: data.siteStations.map((station) => station.name),
          });
        }
      });
    }
  }, [createUpdateSiteModal]);

  const handleNextStep2 = (baseForm: EmissionSiteItem) => {
    setInputForm(baseForm);
    setStep(2);
  };
  const handleNextStep3 = (sizeForm: EmissionSiteItem) => {
    setInputForm(sizeForm);
    setStep(3);
  };
  const handlePrevStep1 = (sizeForm: EmissionSiteItem) => {
    setInputForm(sizeForm);
    setStep(1);
  };

  const handlePrevStep2 = (dateForm: EmissionSiteItem) => {
    setInputForm(dateForm);
    setStep(2);
  };

  const handleSubmit = (dateForm: EmissionSiteItem) => {
    const fullForm = { ...inputForm, ...dateForm };
    createUpdateSiteModal.type === ModalType.add
      ? onCreateSite(fullForm)
      : onUpdateSite(fullForm);
  };
  return (
    <div
      className={`${styles.createUpdateSiteModal} ${
        step === 2 ? styles.sizeModal : step === 3 ? styles.dateModal : ''
      }`}
    >
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {createUpdateSiteModal.type === ModalType.add
                ? t('site_management.modal.create_heading')
                : t('site_management.modal.edit_heading')}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          {step === 1 && (
            <CreateUpdateSiteBaseForm
              initForm={inputForm}
              type={createUpdateSiteModal.type}
              onNextStep={handleNextStep2}
              provinceSuggestion={provinceSuggestion}
              districtSuggestion={districtSuggestion}
            />
          )}
          {step === 2 && (
            <CreateUpdateSiteSizeForm
              initForm={inputForm}
              type={createUpdateSiteModal.type}
              siteTypeSuggestion={siteTypeSuggestion}
              onNextStep={handleNextStep3}
              onPrevStep={handlePrevStep1}
            />
          )}

          {step === 3 && (
            <CreateUpdateSiteDateForm
              onPrevStep={handlePrevStep2}
              initForm={inputForm}
              type={createUpdateSiteModal.type}
              onSubmit={handleSubmit}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CreateUpdateEmissionSiteModal;
