// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './TabletTab.module.scss';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  className?: string;
  tabItems: string[];
  defaultTabIndex: number;
  renderContent: (item: { index: number; tab: string }) => React.ReactNode;
  setTabIndex?: (tabIndex: number) => void;
}

export const TabletTab = ({
  className,
  tabItems,
  defaultTabIndex,
  renderContent,
  setTabIndex,
}: Props) => {
  const [activedTabIndex, setActivedTabIndex] = useState(
    defaultTabIndex ? defaultTabIndex : 0
  );
  useEffect(() => {
    if (setTabIndex) {
      setTabIndex(activedTabIndex);
    }
  }, [activedTabIndex]);
  const history = useHistory();

  return (
    <div className={className}>
      <div className={styles.tabMenu}>
        <div className={styles.tabItems}>
          {tabItems.map((it, index) => {
            const actived = index === activedTabIndex;
            return (
              <div
                key={it}
                className={
                  `${styles.tabItem} ` +
                  (actived ? `${styles.tabItemActive}` : '')
                }
                onClick={() => {
                  setActivedTabIndex(index);
                  history.push(`${window.location.search}#` + index);
                }}
              >
                {it}
              </div>
            );
          })}
        </div>
      </div>
      <div>
        {tabItems.map((tab, index) => (
          <div
            key={`tab-content-${index}`}
            className={index !== activedTabIndex ? 'hidden' : ''}
          >
            {renderContent({ index, tab })}
          </div>
        ))}
      </div>
    </div>
  );
};
