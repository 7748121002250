// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useUpdateAppLanguage = () => {
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    try {
      window?.changeAppLanguage(language);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (!e.message?.includes?.('Android')) {
        console.error(e);
      }
    }
  }, [language]);
};
