import { Modal } from 'components/atoms/Modal';
import { IconClose } from 'components/atoms/icons/IconClose';
import i18n from 'i18n';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { TCompressionBooking } from 'types/compression';
import { REGEX_OBJECT } from 'util/ConstantValues';
import { InputDataListField } from '../InputDataListField';
import styles from './bookingPrintModal.module.scss';
import { validateNumberField } from 'util/validator';
import { toast } from 'react-toastify';
import { normalizeNumberString } from 'util/commons';

type BookingPrintModalProps = {
  onClose: VoidFunction;
  compression: TCompressionBooking;
};

const BookingPrintModal = ({
  onClose,
  compression,
}: BookingPrintModalProps) => {
  const { t } = useTranslation();

  const onSubmit = (values: { quantity: number }) => {
    if (window && window.regisCompressionBooking) {
      try {
        window.regisCompressionBooking(
          JSON.stringify(compression),
          Number(normalizeNumberString(values.quantity.toString())),
          i18n.language
        );
        onClose();
      } catch (e) {
        toast.error(t('messages.M_134'));
        onClose();
      }
    }
  };

  return (
    <div className={styles.bookingPrintModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {t('compressions.print_title_modal')}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <Form
            onSubmit={onSubmit}
            validate={(values) => ({
              quantity: validateNumberField(
                t('compressions.print'),
                50,
                values.quantity,
                true
              ),
            })}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <InputDataListField
                  name="quantity"
                  placeholder={t('compressions.quantity_placeholder')}
                  label={t('compressions.print')}
                  validRegex={REGEX_OBJECT.number}
                  required
                />
                <button className={styles.btn} disabled={!props.valid}>
                  {t('compressions.button_print')}
                </button>
              </form>
            )}
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default BookingPrintModal;
