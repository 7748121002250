// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import styles from './RecycleChainDesignAddSiteForm.module.scss';
import { Form, FormRenderProps } from 'react-final-form';
import { InputDataListField } from 'components/molecules/InputDataListField';
import { SelectField, SelectOption } from 'components/molecules/SelectField';
import { fetchMaterial } from 'apis/operator_site/siteApi';
import AdminRecycleChainStepItem from 'components/molecules/AdminRecycleChainStepItem';
import { useHistory, useParams } from 'react-router';
import { Path } from 'routes';
import { RecycleChainDesignPut } from '../RecycleChainDesignDetailForm';
import { OperatorMenuItem, OperatorType } from 'util/Enums';
import { createAxios } from 'ts/createAxios';
import { OperatorTypeStepEnglish, REGEX_OBJECT } from 'util/ConstantValues';
import {
  RecycleChainRoutesSettingModal,
  RecycleChainRoutesSettingModalProps,
  RouteSetting,
  SiteStage,
  SitesByStage,
} from '../RecycleChainRoutesSettingModal';
import { handleError } from 'util/errorHandler';
import { toast } from 'react-toastify';
import { SettingDeliveryFormData } from 'util/settingDeliveryType';
import {
  validateFieldEN,
  validateSelectField,
  validateStringField,
} from 'util/validator';
import WarningModal from 'components/molecules/WarningModal';

export type SiteItem = {
  name: string;
  id: string;
  stageSiteId: number;
  fuelType: string | null;
  truckType: string | null;
  deliverRelationId?: number;
};
export type StepItem = {
  name: OperatorMenuItem;
  sites: SiteItem[];
  id?: number;
};
type AddSiteResponse = {
  id: number; // id of recycle chain created
  chainStages: {
    id: number;
    stage: string;
    stageIndex: number;
  }[];
};
type DeliverFromSiteItem = {
  deliverRelationId: number;
  id: string; // site id
  name: string;
};
type CollectFromSiteItem = {
  collectRelationId: number;
  id: string; // site id
  name: string;
};
type StageSiteItem = {
  id: number;
  site: SiteItem;
  deliveryFromSites: DeliverFromSiteItem[];
  deliveryToSite: SiteItem & DeliverFromSiteItem;
};
type StageResponse = {
  id: number;
  stageIndex: number;
  stage: OperatorType;
  stageSites: StageSiteItem[];
};
export type UpdateStageItem = {
  id?: number;
  stage: string;
  newStageSites: (Partial<Pick<SiteItem, 'fuelType' | 'truckType'>> & {
    siteId: string;
  })[];
  updateStageSites: (Pick<SiteItem, 'fuelType' | 'truckType'> & {
    id: number;
  })[];
  deleteStageSites: string[];
  fuelType?: string;
  truckType?: string;
};
type RouteUpdate = {
  id: string;
  name: string;
  stageSiteId: number;
  sitesByStage: SitesByStage;
};

type ChainStageItem = {
  id: number;
  stageIndex: number;
  stage: string;
  fuelType: string;
  truckType: string;
  stageSites: {
    id: number;
    fuelType: string;
    truckType: string;
    site: {
      id: string;
      name: string;
    };
  }[];
};

type RecycleChainData = {
  id: number;
  name: string;
  materialName: string;
  englishMaterialName: string;
  chainStages: ChainStageItem[];
};
const RecycleChainDesignAddSiteForm: React.FC<{
  chainForm?: RecycleChainDesignPut;
  setChainForm: (form: RecycleChainDesignPut) => void;
  type: 'add' | 'edit';
}> = ({ chainForm, setChainForm, type }) => {
  const { id: chainDetailId } = useParams<{ id: string }>();
  const history = useHistory();
  const api = createAxios();
  const [steps, setSteps] = useState<StepItem[]>([]);
  const [listOfMaterial, setListOfMaterial] = useState<SelectOption[]>([]);
  const [isSavedSite, setIsSavedSite] = useState(false);
  const [isUpdatedSite, setIsUpdatedSite] = useState(false);
  const [materialName, setMaterialName] = useState('');
  const [routesSettingModalData, setRoutesSettingModalData] =
    useState<RecycleChainRoutesSettingModalProps>();
  const [chainStagesChanged, setChainStagesChanged] = useState<
    UpdateStageItem[]
  >([]);
  const [isChangeInfoUpdated, setIsChangeInfoUpdated] = useState(false);
  const [createdChainId, setCreatedChainId] = useState<string | number>('');
  const [isDeliverySettingsWarningModal, setIsDeliverySettingsWarningModal] =
    useState<boolean>(false);

  const handleShowRoutesSettingModal = (stage: StepItem, index: number) => {
    stage?.id &&
      api
        .get(`/recycle-chains/setting-routes/${stage.id}`)
        .then((res: { data: StageResponse }) => {
          const { data } = res;
          const setuppedSites = data.stageSites
            .map((stageSite) =>
              stageSite.deliveryFromSites.concat(stageSite.deliveryToSite)
            )
            .flat();
          const initRouteSetting = data.stageSites.map((site) => ({
            id: site.site.id,
            name: site.site.name,
            sitesByStage: {
              [SiteStage.SOURCE]: site.deliveryFromSites.map(
                (item: CollectFromSiteItem | DeliverFromSiteItem) => ({
                  id: item.id,
                  name: item.name,
                })
              ),
              [SiteStage.DESTINATION]: [site.deliveryToSite].filter(
                (item) => item.id
              ),
            },
          }));
          setRoutesSettingModalData({
            operatorType: OperatorTypeStepEnglish[stage.name],
            sitesByStage: {
              [SiteStage.SOURCE]:
                steps[index - 1]?.sites?.filter(
                  (site) => !setuppedSites.find((item) => item.id === site.id)
                ) || [],
              [SiteStage.DESTINATION]: steps[index + 1]?.sites || [],
            },
            routesSetting: initRouteSetting,
            onClose: () => setRoutesSettingModalData(undefined),
            onSubmit: (sitesByStage, routesSetting) => {
              const isNotSetup = data.stageSites.every(
                (item) =>
                  !item.deliveryFromSites.length && !item.deliveryToSite?.id
              );
              isNotSetup
                ? handleSetupRoutes(stage, index + 1, routesSetting)
                : handleUpdateRoutes(
                    stage,
                    index + 1,
                    initRouteSetting,
                    routesSetting.map((route, i) => ({
                      ...route,
                      stageSiteId: data.stageSites[i].id,
                    }))
                  );
            },
          });
        });
  };

  const handleSetupRoutes = (
    stage: StepItem,
    index: number,
    routesSetting: RouteSetting[]
  ) => {
    const request = {
      id: createdChainId || Number(chainDetailId),
      routesOfChain: [
        {
          stageIndex: index,
          stage: OperatorTypeStepEnglish[stage.name],
          routes: routesSetting.map((route) => ({
            siteId: route.id,
            deliverFromSiteIds:
              route?.sitesByStage[SiteStage.SOURCE]?.map((item) => item.id) ||
              [],
            deliverToSiteId:
              route.sitesByStage[SiteStage.DESTINATION][0]?.id || null,
          })),
        },
      ],
    };
    api
      .post(`/recycle-chains/setting-routes`, request)
      .then((res) => {
        toast.success(res.data || '保存しました。');
        setRoutesSettingModalData(undefined);
      })
      .catch((error) => handleError(error));
  };

  const handleUpdateRoutes = (
    stage: StepItem,
    index: number,
    initRouteSetting: RouteSetting[],
    routesSetting: RouteUpdate[]
  ) => {
    //api update route
    const request = {
      routesOfChain: [
        {
          id: stage.id, // id của chain stages
          stageIndex: index,
          stage: OperatorTypeStepEnglish[stage.name],
          routes: routesSetting.map((route, i) => {
            const newDeliverToSiteId =
              route.sitesByStage[SiteStage.DESTINATION][0]?.id !==
              initRouteSetting[i].sitesByStage[SiteStage.DESTINATION][0]?.id
                ? route.sitesByStage[SiteStage.DESTINATION][0]?.id || ''
                : undefined;
            return {
              id: route.stageSiteId,
              siteId: route.id,
              newDeliverFromSiteIds: route.sitesByStage[SiteStage.SOURCE]
                .filter(
                  (item) =>
                    !initRouteSetting[i].sitesByStage[SiteStage.SOURCE].find(
                      (init) => init.id === item.id
                    )
                )
                .map((site) => site.id),
              deleteDeliverFromSiteIds: initRouteSetting[i].sitesByStage[
                SiteStage.SOURCE
              ]
                .filter(
                  (init) =>
                    !route.sitesByStage[SiteStage.SOURCE].find(
                      (item) => item.id === init.id
                    )
                )
                .map((site) => site.id),
              ...(newDeliverToSiteId !== undefined
                ? { newDeliverToSiteId: newDeliverToSiteId || null }
                : {}),
            };
          }),
        },
      ],
    };
    api
      .put(
        `/recycle-chains/${createdChainId || chainDetailId}/setting-routes`,
        request
      )
      .then((res) => {
        toast.success(res.data || '保存しました。');
        setRoutesSettingModalData(undefined);
      })
      .catch((error) => handleError(error));
  };

  useEffect(() => {
    fetchMaterial((data) => {
      if (data) {
        setListOfMaterial(
          data.map((item) => {
            return {
              value: item.materialName,
              label: item.materialName,
            };
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    if (chainForm) {
      setSteps(
        chainForm.chainStages.map((item) => ({
          name: item.stage,
          id: item.id,
          sites: item?.sites || [],
        }))
      );
      setMaterialName(chainForm.materialName);
      setChainStagesChanged(
        chainForm.chainStages.map((stage) => ({
          id: stage.id,
          stage: OperatorTypeStepEnglish[stage.stage],
          fuelType: stage.fuelType || '',
          truckType: stage.truckType || '',
          newStageSites: [],
          deleteStageSites: [],
          updateStageSites: [],
        }))
      );
    }
  }, [chainForm]);

  const onAddSite = (sites: SiteItem[], index: number) => {
    const newSteps = steps.map((step, i) =>
      i === index ? { ...step, sites } : step
    );
    setSteps(newSteps);

    const newChainStagesChanged: UpdateStageItem[] = chainStagesChanged.map(
      (chainStage, i) =>
        i === index
          ? {
              ...chainStage,
              newStageSites: chainForm
                ? sites
                    .filter(
                      (site) =>
                        !chainForm.chainStages[index]?.sites?.find(
                          (item) => item.id === site.id
                        )
                    )
                    ?.map((item) => ({
                      siteId: item.id,
                    })) || []
                : [],
              deleteStageSites: chainForm
                ? chainForm.chainStages[index]?.sites
                    ?.filter(
                      (site) => !sites.find((item) => item.id === site.id)
                    )
                    ?.map((item) => item.id) || []
                : [],
            }
          : chainStage
    );
    setChainStagesChanged(newChainStagesChanged);
    type === 'edit' && setIsUpdatedSite(true);
  };

  const appendChainSettingDelivery = (
    stageId: number,
    settingDeliveryData: SettingDeliveryFormData
  ) => {
    return chainStagesChanged.map((chainStage) => {
      if (chainStage.id !== stageId) {
        return chainStage;
      }

      if (!settingDeliveryData.siteIds) {
        return {
          ...chainStage,
          fuelType: settingDeliveryData.fuelOfType,
          truckType: settingDeliveryData.truckOfType,
          updateStageSites: [],
          newStageSites: [],
          deleteStageSites: [],
        };
      }

      return {
        ...chainStage,
        newStageSites: [],
        deleteStageSites: [],
        updateStageSites: settingDeliveryData.siteIds.map((id) => ({
          id,
          fuelType: settingDeliveryData.fuelOfType,
          truckType: settingDeliveryData.truckOfType,
        })),
      };
    });
  };

  const onSaveSite = (form: RecycleChainDesignPut) => {
    api
      .post('recycle-chains', {
        name: form.chainName.trim(),
        materialName: form.materialName,
        englishMaterialName: form.englishMaterialName.trim(),
        chainStages: steps.map((step, index) => ({
          stageIndex: index + 1,
          stage: OperatorTypeStepEnglish[step.name],
          stageSites: step.sites.map((site) => site.id),
        })),
      })
      .then((res: { data: AddSiteResponse }) => {
        const newSteps = steps.map((step, index) => ({
          ...step,
          id: res.data.chainStages.find(
            (stage) => stage.stageIndex === index + 1
          )?.id,
        }));
        setSteps(newSteps);
        setCreatedChainId(res.data.id);
        toast.success('登録しました。');
        setIsSavedSite(true);
        getRecycleChainDetail(res.data.id);
      })
      .catch((err) => handleError(err));
  };

  const onSaveUpdateSite = (
    chainName: string,
    englishMaterialName: string,
    callback?: () => void,
    settingDeliveryForm?: SettingDeliveryFormData,
    stageId?: number
  ) => {
    let appendedChain: UpdateStageItem[] | undefined = undefined;

    if (settingDeliveryForm && stageId) {
      appendedChain = appendChainSettingDelivery(stageId, settingDeliveryForm);
    }

    if (appendedChain) {
      setChainStagesChanged(appendedChain);
      type === 'edit' && setIsUpdatedSite(true);
    }

    const request = {
      name: chainName.trim(),
      englishMaterialName: englishMaterialName.trim(),
      chainStages: appendedChain || chainStagesChanged,
    };

    api
      .put(`/recycle-chains/${createdChainId || chainDetailId}`, request)
      .then((res) => {
        callback
          ? callback()
          : history.push(Path.adminRecycleChainDesignManagement);
        toast.success(res.data || '保存しました。');

        getRecycleChainDetail(createdChainId || chainDetailId);
      })
      .catch((error) => handleError(error));
  };

  const handleCheckNeedUpdateSite = ({ chainName, englishMaterialName }) => {
    isUpdatedSite || isChangeInfoUpdated
      ? onSaveUpdateSite(chainName, englishMaterialName, () =>
          setIsSavedSite(true)
        )
      : setIsSavedSite(true);
  };

  const getRecycleChainDetail = (id?: number | string) => {
    const chainId = id ?? chainDetailId;

    chainId &&
      api
        .get(`/recycle-chains/${chainId}`)
        .then((res: { data: RecycleChainData }) => {
          const { data } = res;
          setChainForm({
            chainName: data.name,
            materialName: data.materialName,
            englishMaterialName: data.englishMaterialName,
            chainStages: data.chainStages
              .sort((a, b) => a.stageIndex - b.stageIndex)
              .map((stage) => ({
                stage: OperatorMenuItem[stage.stage],
                id: stage.id,
                fuelType: stage.fuelType,
                truckType: stage.truckType,
                sites: stage.stageSites.map(
                  ({ site, fuelType, truckType, id }) => ({
                    ...site,
                    stageSiteId: id,
                    fuelType,
                    truckType,
                  })
                ),
              })),
          });
        });
  };

  const checkSettingDeliveryAllProcessesAndSites = () => {
    const hasChainDeliveryBeenSet = chainForm?.chainStages.every(
      (item, index) => {
        const idx = index + 1;
        if (
          idx <= 1 ||
          idx === Number(chainForm?.chainStages.length) ||
          item.stage === OperatorMenuItem.Producer
        ) {
          return true;
        }

        if (item.stage === OperatorMenuItem.Collects) {
          return item?.sites?.every((site) => site.fuelType && site.truckType);
        }

        return item.fuelType && item.truckType;
      }
    );

    if (hasChainDeliveryBeenSet) {
      history.push(Path.adminRecycleChainDesignManagement);
    } else {
      setIsDeliverySettingsWarningModal(true);
    }
  };

  useEffect(() => {
    getRecycleChainDetail();
  }, [chainDetailId]);

  const handleTrimValues = (
    props: FormRenderProps<
      RecycleChainDesignPut,
      Partial<RecycleChainDesignPut>
    >
  ) => {
    props.form.mutators.setValue('chainName', props.values.chainName.trim());
    props.form.mutators.setValue(
      'englishMaterialName',
      props.values.englishMaterialName.trim()
    );
  };

  return (
    <div className={styles.recycleChainDesignAddSiteForm}>
      <Form<RecycleChainDesignPut>
        onSubmit={onSaveSite}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        initialValues={{
          chainName: chainForm?.chainName || '',
          materialName: chainForm?.materialName || '',
          englishMaterialName: chainForm?.englishMaterialName || '',
        }}
        validate={(values) => {
          return {
            chainName: validateStringField('名前', 100, values.chainName),
            materialName: validateSelectField('材料選択', values.materialName),
            englishMaterialName: validateFieldEN(
              '材料名（英語)',
              values.englishMaterialName,
              100
            ),
          };
        }}
      >
        {(props) => {
          return (
            <form className={styles.recycleForm}>
              <div className={styles.inputFrame}>
                <div className={styles.chainName}>
                  <InputDataListField
                    required
                    label={'名前'}
                    placeholder={'名前'}
                    name="chainName"
                    disabled={isSavedSite}
                    maxlength={100}
                    handleChange={() =>
                      type === 'edit' && setIsChangeInfoUpdated(true)
                    }
                  />
                </div>
                <div className={styles.materialName}>
                  <SelectField
                    required
                    label={'材料選択'}
                    placeholder={'プラスチック'}
                    name={'materialName'}
                    options={
                      type === 'edit' || isSavedSite
                        ? listOfMaterial.concat({
                            value: chainForm?.materialName || '',
                            label: chainForm?.materialName || '',
                          })
                        : listOfMaterial
                    }
                    disabled={type === 'edit' || isSavedSite}
                    onChange={(value) => {
                      setMaterialName(String(value));
                      setSteps(steps.map((step) => ({ ...step, sites: [] })));
                    }}
                  />

                  <InputDataListField
                    label="材料名（英語)"
                    placeholder="材料名（英語)"
                    name="englishMaterialName"
                    required
                    disabled={isSavedSite}
                    validRegex={REGEX_OBJECT.halfWidthCharacter}
                    maxlength={100}
                    handleChange={() =>
                      type === 'edit' && setIsChangeInfoUpdated(true)
                    }
                  />
                </div>
              </div>
              <div className={styles.siteArea}>
                <div className={styles.siteList} id="style-1">
                  {steps.map((step, index) => (
                    <AdminRecycleChainStepItem
                      key={index}
                      id={step.id}
                      index={index + 1}
                      step={step}
                      steps={steps}
                      materialName={materialName}
                      isSavedSite={isSavedSite}
                      chainForm={chainForm}
                      excludeSites={
                        step.name === OperatorMenuItem.Compressions
                          ? steps
                              .filter(
                                (item, i) =>
                                  item.name === OperatorMenuItem.Compressions &&
                                  i !== index &&
                                  item.sites.length > 0
                              )
                              .map((item) => item.sites)
                              .flat()
                          : []
                      }
                      onAddSite={(sites) => onAddSite(sites, index)}
                      onClickSetupRoute={() =>
                        handleShowRoutesSettingModal(step, index)
                      }
                      onSettingDelivery={(
                        settingDeliveryData,
                        successCallback
                      ) => {
                        onSaveUpdateSite(
                          props.values.chainName,
                          props.values.englishMaterialName,
                          () => {
                            setIsSavedSite(true);
                            successCallback();
                          },
                          settingDeliveryData,
                          step.id
                        );
                      }}
                    />
                  ))}
                </div>
                {type === 'add' &&
                  (!isSavedSite ? (
                    <button
                      className={styles.submitButton}
                      disabled={
                        !props.values.chainName ||
                        !props.values.materialName ||
                        !props.values.englishMaterialName ||
                        steps.some((item) => !item.sites.length)
                      }
                      onClick={(e) => {
                        handleTrimValues(props);
                        props.handleSubmit(e);
                      }}
                      type="button"
                    >
                      {'ルート設定へ'}
                    </button>
                  ) : (
                    <div>
                      <button
                        className={styles.submitButton}
                        type="button"
                        onClick={checkSettingDeliveryAllProcessesAndSites}
                      >
                        {'チェーンリストに戻る'}
                      </button>
                    </div>
                  ))}
                {type === 'edit' &&
                  (!isSavedSite ? (
                    <button
                      className={styles.submitButton}
                      disabled={
                        props.invalid ||
                        !props.values.chainName ||
                        !props.values.englishMaterialName ||
                        steps.some((step) => !step.sites.length)
                      }
                      onClick={() => {
                        handleTrimValues(props);
                        handleCheckNeedUpdateSite({
                          chainName: props.values.chainName,
                          englishMaterialName: props.values.englishMaterialName,
                        });
                      }}
                      type="button"
                    >
                      {'ルート設定へ'}
                    </button>
                  ) : (
                    <button
                      className={styles.submitButton}
                      onClick={checkSettingDeliveryAllProcessesAndSites}
                      type="button"
                    >
                      {'チェーンリストに戻る'}
                    </button>
                  ))}
              </div>
            </form>
          );
        }}
      </Form>
      {routesSettingModalData && (
        <RecycleChainRoutesSettingModal {...routesSettingModalData} />
      )}

      {isDeliverySettingsWarningModal && (
        <WarningModal
          title="確認"
          content="配送設定が完了していません。本当に戻りますか？"
          buttonConfirm="配送設定が完了していません。本当に戻りますか？"
          onClose={() => setIsDeliverySettingsWarningModal(false)}
          onConfirm={() => history.push(Path.adminRecycleChainDesignManagement)}
        />
      )}
    </div>
  );
};
export default RecycleChainDesignAddSiteForm;
