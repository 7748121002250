// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useMemo } from 'react';
import styles from './style.module.scss';
import { mergeClasses } from 'util/commons';

type BadgeProps = {
  show?: boolean;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  color?: React.CSSProperties['color'];
  content?: React.ReactNode;
};

const Badge: React.FC<BadgeProps> = ({
  show,
  className,
  style,
  placement,
  color,
  content,
  children,
}) => {
  if (!show) {
    return <>{children}</>;
  }

  const badgeColor = useMemo(() => {
    switch (color) {
      case 'red':
        return '#d32c1b';

      default:
        return color;
    }
  }, [color]);

  const renderBadge = () => {
    return (
      <div
        className={mergeClasses(styles.badge, className, {
          ...(placement && { [styles[placement]]: true }),
        })}
        style={{
          ...(badgeColor && { backgroundColor: badgeColor }),
          ...style,
        }}
      >
        {content}
      </div>
    );
  };

  if (children) {
    return (
      <div className={styles.badgeWrapper}>
        {children}
        {renderBadge()}
      </div>
    );
  }

  return renderBadge();
};

Badge.defaultProps = {
  placement: 'right',
};

export default Badge;
