// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { apiService } from 'apis/site_recycle/siteApi';
import {
  WorkHistoriesEmissionsCollectedResponse,
  WorkHistoriesEmissionsRequestResponse,
  WorkHistoriesParams,
} from 'types/workHistoriesTypes';

const addTagTypes = [
  'WorkHistoriesEmissionRequest',
  'WorkHistoriesEmissionCollected',
] as const;

const workHistoriesApi = apiService
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchWorkHistoriesEmissionRequest: build.query<
        WorkHistoriesEmissionsRequestResponse,
        Partial<WorkHistoriesParams>
      >({
        query: (args) => ({
          url: '/work-histories/emission/requests',
          method: 'GET',
          params: args,
        }),
        providesTags: ['WorkHistoriesEmissionRequest'],
      }),
      fetchWorkHistoriesEmissionCollected: build.query<
        WorkHistoriesEmissionsCollectedResponse,
        Partial<WorkHistoriesParams>
      >({
        query: (args) => ({
          url: '/work-histories/emission/collected',
          method: 'GET',
          params: args,
        }),
        providesTags: ['WorkHistoriesEmissionCollected'],
      }),
    }),
  });

export const {
  useFetchWorkHistoriesEmissionRequestQuery,
  useFetchWorkHistoriesEmissionCollectedQuery,
} = workHistoriesApi;
