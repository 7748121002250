// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import { TraceDataDetail } from 'types/purchase';
import { hideLastCharacters, normalizeNumber } from 'util/commons';
import snakeCase from 'lodash/snakeCase';
import styles from './PurchaseTraceDataDetailOrganism.module.scss';
import { useTranslation } from 'react-i18next';
import { addition } from 'util/Calc';
import { OperatorType } from 'util/Enums';

type Props = {
  traceDetail: TraceDataDetail[];
};

const PurchaseTraceDataDetailOrganism: React.FC<Props> = ({ traceDetail }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.purchaseTraceDataDetailOrganism}>
      {traceDetail.map((trace) => {
        const totalWeight = trace.data.reduce(
          (sum, item) =>
            addition(
              sum,
              Number(
                item.data.reduce(
                  (sum, it) => addition(sum, Number(it.weight)),
                  0
                )
              )
            ),
          0
        );

        return (
          <div className={styles.purchaseTraceDataDetailWrapper}>
            <div className={styles.processBox}>
              <div className={styles.processName}>
                {t(`purchase_page.trace_data_detail.${snakeCase(trace.stage)}`)}
              </div>
              <div className={styles.processWeight}>
                {normalizeNumber({ value: totalWeight }) || 0} kg
              </div>
            </div>

            {trace.data.map((traceContent, i) => (
              <div className={styles.processDetail} key={i}>
                <div className={styles.processDetailHeader}>
                  <div
                    className={styles.operator}
                    title={traceContent.operatorName}
                  >
                    {traceContent.operatorName}
                  </div>
                  <div className={styles.site} title={traceContent.siteName}>
                    {traceContent.siteName}
                  </div>
                </div>
                <div className={styles.processDetailContent}>
                  {traceContent.data.map((item) => (
                    <div className={styles.processDetailItem}>
                      <div className={styles.registerId}>
                        {trace.stage === OperatorType.Emissions
                          ? hideLastCharacters(item.id)
                          : item.id}
                      </div>
                      <div className={styles.weight}>
                        <span>{normalizeNumber({ value: item.weight })}</span>
                        kg
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default PurchaseTraceDataDetailOrganism;
