// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { AdminTemplate } from 'components/templates/AdminTemplate';
import { BrandItem } from 'util/Types';
import { ModalType } from 'util/Enums';
import { useTranslation } from 'react-i18next';
import { Path } from 'routes';
import AdminBrandItemTable from 'components/organisms/AdminBrandItemTable';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { getBrandDetail } from 'apis/brands';

export interface ModalBrandState {
  isOpen: boolean;
  type: ModalType;
  brandEditing?: BrandItem;
  operatorId?: string;
}

const AdminBrandOwnerManagementDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [brandName, setBrandName] = useState<string>();

  useEffect(() => {
    getBrandDetail({
      brandId: id,
      successCallback: (data) => setBrandName(data.name),
    });
  }, []);

  return (
    <AdminTemplate
      h1={t('brand_items.heading')}
      active={t('brand_management.heading') || ''}
      breadcrumb={[
        {
          label: t('brand_management.heading'),
          href: Path.adminBrandOwnerManagement,
        },
        { label: brandName || '' },
      ]}
    >
      <AdminBrandItemTable />
    </AdminTemplate>
  );
};

export default AdminBrandOwnerManagementDetail;
