// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import { IconCloseTablet } from 'components/atoms/icons/IconCloseTablet';
import { IconCopy } from 'components/atoms/icons/IconCopy';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ScanICCard.module.scss';
import uniqBy from 'lodash/uniqBy';

const ScanICCard = () => {
  const { t } = useTranslation();
  const [isShowModal, setIsShowModal] = useState(false);
  const [ICCardList, setICCardList] = useState<string[]>([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const input = e.target.querySelector('input');
    setICCardList(uniqBy([...ICCardList, input.value].filter((item) => item)));
    setIsShowModal(true);
    try {
      window && window.verifyICCardSuccess(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCallFunctionInit = () => {
    try {
      window && window.tenantAuthICCardInitial();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleCallFunctionInit();
  }, []);

  return (
    <PrimaryTemplate h1={t('scan_ic_card.title')} className="scan_ic_card">
      <div className={styles.scanICCard}>
        {isShowModal ? (
          <div className={styles.modalICCards}>
            <div className={styles.modalICCardsContent}>
              <div className={styles.modalICCardsHeader}>
                <span>{t('scan_ic_card.modal.title')}</span>
                <IconCloseTablet
                  onClick={() => {
                    setIsShowModal(false);
                    setICCardList([]);
                  }}
                />
              </div>
              <div className={styles.modalICCardsBody}>
                {ICCardList.map((icCart, index) => (
                  <div key={index} className={styles.icCardItem}>
                    <span>{icCart}</span>
                    <IconCopy
                      onClick={() => {
                        navigator.clipboard.writeText(icCart);
                        if (window && window.copyToClipboard) {
                          window.copyToClipboard(icCart);
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p className={styles.message}>{t('scan_ic_card.message')}</p>
            <div className={styles.empty}></div>
          </div>
        )}
        <form onSubmit={handleSubmit} id="input-ic-cards">
          <input name="icCard" />
          <button type="submit"></button>
        </form>
      </div>
    </PrimaryTemplate>
  );
};

export default ScanICCard;
