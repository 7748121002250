// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import styles from './index.module.scss';
import SettingDeliveryForm from 'components/molecules/SettingDeliveryForm';
import {
  SettingDeliveryFormData,
  WasteFuelUnits,
} from 'util/settingDeliveryType';
import { RecycleChainDesignPut } from '../RecycleChainDesignDetailForm';
import { SiteItem } from '../RecycleChainDesignAddSiteForm';

interface Props {
  wasteFuelUnits: WasteFuelUnits[];
  onClose?: () => void;
  onSubmit: (settingDeliveryFormData: SettingDeliveryFormData) => void;
  id?: number;
  chainForm?: RecycleChainDesignPut;
  selectSiteProps?: {
    selectedSiteIds: number[];
    sites: SiteItem[];
  };
}

const SettingDeliveryModal: React.FC<Props> = ({
  wasteFuelUnits,
  onClose,
  onSubmit,
  id,
  chainForm,
  selectSiteProps,
}) => {
  const [inputForm, setInputForm] = useState<SettingDeliveryFormData>({
    fuelOfType: '',
    truckOfType: '',
    ...(selectSiteProps?.selectedSiteIds && {
      siteIds: selectSiteProps.selectedSiteIds,
    }),
  });

  useEffect(() => {
    if (chainForm) {
      const settingDeliveryFind = chainForm?.chainStages.find(
        (item) => item.id === id
      );

      setInputForm({
        fuelOfType: selectSiteProps ? '' : settingDeliveryFind?.fuelType || '',
        truckOfType: selectSiteProps
          ? ''
          : settingDeliveryFind?.truckType || '',
        ...(selectSiteProps?.selectedSiteIds && {
          siteIds: selectSiteProps.selectedSiteIds,
        }),
      });
    }
  }, [chainForm]);

  return (
    <div className={`${styles.settingDeliveryModal}`}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {selectSiteProps ? '回収情報の設定' : '配送情報の設定'}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <SettingDeliveryForm
            sites={selectSiteProps?.sites}
            wasteFuelUnits={wasteFuelUnits}
            initForm={inputForm}
            handleSubmit={onSubmit}
          />
        </div>
      </Modal>
    </div>
  );
};

export default SettingDeliveryModal;
