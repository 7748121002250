// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './CollectComplete.module.scss';
import { useEffect, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { useTranslation } from 'react-i18next';
import { CompressionDeliveryContent } from 'components/molecules/CompressionDeliveryContent';
import { DATE_FORMAT } from 'util/ConstantValues';
import { toast } from 'react-toastify';
import { BottomField } from 'components/molecules/BottomField';
import { EmissionEditModal } from '../EmissionEditModal';
import { UncollectEmission } from 'util/Types';
import { IconDelete } from 'components/atoms/icons/IconDelete';
import { ConfirmModal } from '../ConfirmModal';
import { normalizeNumber } from 'util/commons';

export type Collect = {
  id: string;
  collectedAreaId: string;
  collectedAreaName: string;
  collectedDatetime: string;
  deliveryUserId: string;
  deliveryUserName: string;
  weight: string;
  description: string;
  emissionIds: string[];
  substituteRegist: boolean;
};

export function CollectComplete() {
  const api = createAxios();
  const { t } = useTranslation();
  const [collects, setCollects] = useState<Collect[]>([]);
  const [substituteRegists, setSubstituteRegists] = useState<
    UncollectEmission[]
  >([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [collectToConfirmDelete, setCollectToConfirmDelete] = useState<
    Collect | undefined
  >();

  const submit = async () => {
    try {
      await api.post('/deliveries', {
        deliveryIds: collects.map((c) => c.id),
      });
      toast.success(t('messages.M_088'));
      callGetCollectsApi();
      callGetSubstituteRegistApi();
    } catch (e) {
      alert(t('collects.error'));
    }
  };

  const callGetCollectsApi = async () => {
    try {
      const response = await api.get('/collects');
      setCollects(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const callGetSubstituteRegistApi = async () => {
    try {
      const response = await api.get('collects/substitute-regists');
      setSubstituteRegists(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const callDeleteSubstituteRegistApi = async (id: string) => {
    try {
      await api.delete(`collects/substitute-regists/${id}`);
      toast.success(t('messages.M_012'));
    } catch (_) {
      toast.error(t('messages.M_013'));
    }
  };

  const handleOnDeleteSubstituteRegistData = async () => {
    if (
      !collectToConfirmDelete ||
      collectToConfirmDelete.emissionIds.length > 1
    ) {
      return;
    }
    await callDeleteSubstituteRegistApi(collectToConfirmDelete.emissionIds[0]);

    setCollectToConfirmDelete(undefined);
    callGetCollectsApi();
    callGetSubstituteRegistApi();
  };

  useEffect(() => {
    callGetCollectsApi();
    callGetSubstituteRegistApi();
  }, []);

  return (
    <div className={styles.collectComplete}>
      <div className="scroll-contents">
        {collects.map((collect, i) => {
          return (
            <div className={styles.collectCompleteCard} key={i}>
              <div className={styles.collectCompleteContentCol}>
                <div className={styles.collectCompleteContent}>
                  <div className={styles.collectCompleteLabel}>
                    {t('common_history_field.date_time')}
                  </div>
                  <CompressionDeliveryContent
                    contents={getFormatDateWithCurrentTimezone(
                      collect.collectedDatetime,
                      DATE_FORMAT.slaYMDHm
                    )}
                  />
                </div>
                <div className={styles.collectCompleteContent}>
                  <div className={styles.collectCompleteLabel}>
                    {t('common_history_field.responsible_person')}
                  </div>
                  <CompressionDeliveryContent
                    contents={
                      collect.deliveryUserId +
                      '(' +
                      collect.deliveryUserName +
                      ')'
                    }
                  />
                </div>
                <div className={styles.collectCompleteContent}>
                  <div className={styles.collectCompleteLabel}>
                    {t('common_history_field.place')}
                  </div>
                  <CompressionDeliveryContent
                    contents={collect.collectedAreaName}
                  />
                </div>
                <div className={styles.collectCompleteContent}>
                  <div className={styles.collectCompleteLabel}>
                    {t('common_history_field.item')}
                  </div>
                  <CompressionDeliveryContent contents={collect.description} />
                </div>
                <div className={styles.collectCompleteContent}>
                  <div className={styles.collectCompleteLabel}>
                    {t('common_history_field.weight')}
                  </div>
                  <CompressionDeliveryContent
                    contents={
                      normalizeNumber({
                        value: collect.weight,
                      }) + 'kg'
                    }
                  />
                </div>
                {collect.emissionIds.map((i, idx) => {
                  return (
                    <div key={idx} className={styles.collectCompleteContent}>
                      <div className={styles.collectCompleteLabel}>
                        {idx === 0
                          ? t('common_history_field.registration_id')
                          : ''}
                      </div>
                      <CompressionDeliveryContent contents={i} />
                    </div>
                  );
                })}
              </div>
              <div className={styles.collectCompleteActionCol}>
                {collect.substituteRegist && (
                  <div
                    className={styles.actionDelete}
                    onClick={() => {
                      setCollectToConfirmDelete(collect);
                    }}
                  >
                    <IconDelete />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      <BottomField>
        <div className={styles.bottomBtn}>
          {substituteRegists.length > 0 && (
            <button
              className={styles.btnEditing}
              type="button"
              disabled={false}
              onClick={() => {
                setIsEditModalOpen(true);
              }}
            >
              {t('collects.edit_substitute_regist_data')}
            </button>
          )}

          <button
            className={styles.btnRequest}
            type="button"
            disabled={collects.length == 0}
            onClick={submit}
          >
            {t('collects.request_receipt')}
          </button>
        </div>
      </BottomField>
      {isEditModalOpen && (
        <EmissionEditModal
          onClose={() => {
            setIsEditModalOpen(false);
          }}
          afterSubmit={() => {
            callGetCollectsApi();
            callGetSubstituteRegistApi();
          }}
          emissionData={substituteRegists}
        />
      )}
      <ConfirmModal
        title={t('common.alert.delete')}
        submitLabel={t('common.button.delete') || '削除'}
        alert={t('collects.substitute_regist_delete_alert') || ''}
        isOpen={collectToConfirmDelete !== undefined}
        closeEditModal={() => {
          setCollectToConfirmDelete(undefined);
        }}
        onClick={handleOnDeleteSubstituteRegistData}
        confirmContents={[
          {
            label: t('common_history_field.item'),
            contents: collectToConfirmDelete?.description || '',
          },
          {
            label: t('common_history_field.weight'),
            contents: (collectToConfirmDelete?.weight || 0) + 'kg',
          },
          {
            label: 'ID',
            contents: (collectToConfirmDelete?.emissionIds || []).join('/'),
          },
        ]}
      />
    </div>
  );
}
