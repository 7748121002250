// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  onClick?: () => void;
}
export const IconImport: React.FC<Props> = ({ onClick }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M8.05765 16.787C7.18257 16.788 6.33789 16.4559 5.68545 15.8543C5.03301 15.2528 4.61868 14.424 4.52184 13.5269C4.42499 12.6297 4.65245 11.7272 5.16063 10.9923C5.66881 10.2575 6.42198 9.74183 7.27584 9.54424C7.02879 8.35569 7.24957 7.11457 7.8896 6.09393C8.52964 5.07329 9.5365 4.35672 10.6887 4.10188C11.8409 3.84703 13.0441 4.07477 14.0335 4.73501C15.0229 5.39524 15.7175 6.43388 15.9646 7.62244H16.0534C17.155 7.6213 18.2177 8.04243 19.0353 8.80409C19.8528 9.56575 20.3668 10.6136 20.4775 11.7442C20.5882 12.8748 20.2877 14.0075 19.6344 14.9224C18.981 15.8374 18.0214 16.4692 16.9418 16.6953M15.165 14.0376L12.4997 11.2883M12.4997 11.2883L9.83449 14.0376M12.4997 11.2883V19.8312"
        stroke="#6F7175"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
