// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { IconFixedItem } from 'components/atoms/icons/IconFixedItem';
import { getUnitLabel, mergeClasses } from 'util/commons';
import { ItemSize } from 'util/Enums';
import { CategoryItem, ItemType } from 'util/Types';
import styles from './EmissionSelectCard.module.scss';

export type Props = {
  item: CategoryItem;
  onSelected: (item: CategoryItem) => void;
};

export const EmissionSelectCard = ({ item, onSelected }: Props) => {
  return (
    <div
      className={`${styles.item} ${
        item.size === ItemSize.MEDIUM
          ? styles.itemMedium
          : item.size === ItemSize.SMALL
          ? styles.itemSmall
          : styles.itemBig
      }`}
    >
      <div
        className={styles.item_content}
        onClick={() => {
          onSelected(item);
        }}
      >
        <div className={styles.emissionContent}>
          <p>{item.name}</p>

          {item.type === ItemType.FIX && (
            <div
              className={mergeClasses(styles.customizeItemQuantity, {
                [styles.ellipseText1]:
                  item.size === ItemSize.MEDIUM || item.size === ItemSize.SMALL,
              })}
            >{`${item.convertKg}kg/${item.quantity}${getUnitLabel(
              item.unit
            )}`}</div>
          )}
        </div>
        {item.type === ItemType.FIX && (
          <div className={styles.optionsArea}>
            <div className={`${styles.itemType}`}>
              <IconFixedItem />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
