/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useState } from 'react';
import {
  Popover as RTPopover,
  PopoverProps as RTPopoverProps,
  ArrowContainer,
  ArrowContainerProps,
} from 'react-tiny-popover';
import _debounce from 'lodash/debounce';
import { uniqRandomNumber } from 'util/commons';

type PopoverProps = Omit<RTPopoverProps, 'content' | 'isOpen'> & {
  content?: RTPopoverProps['content'] | React.ReactNode | null;
  autoGen?:
    | {
        wrapperStyle?: React.CSSProperties;
        lines?: number;
        odd?: number;
      }
    | boolean;
  popUpStyle?: React.CSSProperties;
  arrowContainerConfigs?: Partial<ArrowContainerProps>;
  onTextOverflow?: (value: boolean) => void;
};

const Popover: React.FC<PopoverProps> = ({
  children,
  content,
  autoGen,
  popUpStyle,
  arrowContainerConfigs,
  onTextOverflow,
  ...rest
}) => {
  const [popoverOpened, setPopoverOpened] = useState<boolean>(false);
  const [popContent, setPopContent] = useState<React.ReactNode>();
  const isFirstTimeRender = useRef<boolean>(true);
  const id = useRef<string>(`popover-wrapper:${uniqRandomNumber()}`);

  useEffect(() => {
    if (!autoGen) {
      setPopContent(content);
    }

    if (isFirstTimeRender.current && autoGen) {
      setPopContent(content ?? children);
      isFirstTimeRender.current = false;
    }
  }, [content, children, autoGen]);

  useLayoutEffect(() => {
    const handleShowPopover = () => {
      if (autoGen) {
        const el = document.getElementById(id.current);

        if (!el) {
          return;
        }

        if (
          el.scrollHeight <=
          el.clientHeight + (typeof autoGen === 'object' ? autoGen.odd ?? 0 : 0)
        ) {
          setPopContent(undefined);
          onTextOverflow?.(false);
        } else {
          setPopContent(content ?? children);
          onTextOverflow?.(true);
        }
      }
    };

    handleShowPopover();

    const handleShowPopoverOnResize = _debounce(handleShowPopover, 200);

    window.addEventListener('resize', handleShowPopoverOnResize);

    return () => {
      window.removeEventListener('resize', handleShowPopoverOnResize);
    };
  }, [autoGen, children, content]);

  if (!children && !popContent) {
    return null;
  }

  if (!popContent && !autoGen) {
    return <>{children}</>;
  }

  const renderTooltipChildren = () => {
    if (autoGen) {
      return (
        <div
          id={id.current}
          style={{
            ...(typeof autoGen === 'object' ? autoGen.wrapperStyle : undefined),
            wordBreak: 'break-word',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp:
              typeof autoGen === 'boolean' ? 1 : autoGen.lines ?? 1,
            whiteSpace: 'initial',
            WebkitBoxOrient: 'vertical',
            display: '-webkit-box',
          }}
          onMouseEnter={() => popContent && setPopoverOpened(true)}
          onMouseLeave={() => popContent && setPopoverOpened(false)}
        >
          {children}
        </div>
      );
    }

    return React.cloneElement(children, {
      onMouseEnter: () => setPopoverOpened(true),
      onMouseLeave: () => setPopoverOpened(false),
    });
  };

  return (
    <RTPopover
      isOpen={popoverOpened}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowSize={8}
          arrowColor="#4D4D4DE5"
          {...arrowContainerConfigs}
        >
          <div
            style={{
              background: '#4D4D4DE5',
              padding: '12px',
              fontSize: '12px',
              color: 'white',
              lineHeight: '16px',
              borderRadius: '4px',
              ...popUpStyle,
            }}
          >
            {popContent}
          </div>
        </ArrowContainer>
      )}
      {...rest}
    >
      {renderTooltipChildren()}
    </RTPopover>
  );
};

Popover.defaultProps = {
  positions: ['right', 'left', 'top', 'bottom'],
  transformMode: 'relative',
};

export default Popover;
