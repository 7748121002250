// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import { useEffect, useState } from 'react';
import styles from './ScanICCardPlugin.module.scss';
import { IconCloseTablet } from 'components/atoms/icons/IconCloseTablet';
import { IconCopy } from 'components/atoms/icons/IconCopy';
import { useTranslation } from 'react-i18next';
import uniqBy from 'lodash/uniqBy';

type Props = {
  onScanSuccess?: (iccard: string) => void;
};
const ScanICCardPlugin: React.FC<Props> = ({ onScanSuccess }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [ICCardList, setICCardList] = useState<string[]>([]);
  const { t } = useTranslation();
  const handleSubmit = (e) => {
    e.preventDefault();
    const input = e.target.querySelector('input');
    if (onScanSuccess) {
      onScanSuccess(input.value);
    } else {
      setICCardList(
        uniqBy([...ICCardList, input.value].filter((item) => item))
      );
      setIsShowModal(true);
    }
  };

  const handleCallFunctionInit = () => {
    try {
      window && window.tenantAuthICCardInitial();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleCallFunctionInit();
  }, []);

  return (
    <div className={styles.scanICCard}>
      {isShowModal ? (
        <div className={styles.modalICCards}>
          <div className={styles.modalICCardsContent}>
            <div className={styles.modalICCardsHeader}>
              <span>{t('scan_ic_card.modal.title')}</span>
              <IconCloseTablet onClick={() => setIsShowModal(false)} />
            </div>
            <div className={styles.modalICCardsBody}>
              {ICCardList.map((icCart, index) => (
                <div key={index} className={styles.icCardItem}>
                  <span>{icCart}</span>
                  <IconCopy
                    onClick={() => {
                      navigator.clipboard.writeText(icCart);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <p className={styles.message}>{t('scan_ic_card.message')}</p>
          <div className={styles.empty}></div>
        </div>
      )}
      <form onSubmit={handleSubmit} id="input-ic-cards">
        <input name="icCard" />
        <button type="submit"></button>
      </form>
    </div>
  );
};

export default ScanICCardPlugin;
