import { ErrorText } from 'components/atoms/ErrorText';
import IconClock from 'components/atoms/icons/IconClock';
import { Label } from 'components/atoms/Label';
import { FieldValidator } from 'final-form';
import React, { useEffect, useRef } from 'react';
import { Field } from 'react-final-form';
import useOutsideClick from 'util/hooks/useClickOutside';
import styles from './TimeInputField.module.scss';
import { mergeClasses } from 'util/commons';

interface TimeInputFieldProps {
  disabled?: boolean;
  onChangeTime: (date: string) => void;
  placeholder?: {
    hours: string;
    minutes: string;
  };
  name: string;
  label?: string;
  value?: string;
  required?: boolean;
  validate?: FieldValidator<string>;
  type?: string;
  isDarkMode?: boolean;
}

const TimeInputField: React.FC<TimeInputFieldProps> = ({
  value,
  disabled,
  onChangeTime,
  placeholder,
  label,
  name,
  required,
  type,
  validate,
  isDarkMode,
}) => {
  const [isFocus, setFocus] = React.useState(false);
  const inputHoursRef = useRef<HTMLInputElement>(null);
  const inputMinutesRef = useRef<HTMLInputElement>(null);
  const [state, setState] = React.useState({
    hours: value ? value.split(':')[0] : '',
    minutes: value ? value.split(':')[1] : '',
  });

  useEffect(() => {
    if (value) {
      setState({
        hours: value ? value.split(':')[0] : '',
        minutes: value ? value.split(':')[1] : '',
      });
    }
  }, [value]);

  const isNumeric = (value: string) => {
    return /^-?\d+$/.test(value);
  };

  const handleFocusOut = () => {
    if (isFocus) {
      setFocus(false);
      if (state.hours || state.minutes) {
        const date = state.hours + ':' + state.minutes;
        onChangeTime && onChangeTime(date);
      } else {
        onChangeTime && onChangeTime('');
      }
    }
  };

  React.useEffect(() => {
    if (state.hours || state.minutes) {
      const date = state.hours + ':' + state.minutes;
      onChangeTime && onChangeTime(date);
    } else {
      onChangeTime && onChangeTime('');
    }
  }, [state]);

  const onClickOutside = () => {
    handleFocusOut();
  };
  const { ref } = useOutsideClick({ onClickOutside });
  return (
    <Field name={name} type={type} validate={validate} value={value}>
      {({ input, meta }) => {
        return (
          <div className={styles.inputFrame}>
            {label && <Label text={label} tag={'div'} required={required} />}
            <div
              ref={ref}
              className={mergeClasses(
                isDarkMode ? styles.inputForm : styles.textInput,
                {
                  [styles.inputDarkMode]: !!disabled,
                }
              )}
              onClick={() => {
                inputHoursRef.current?.focus();
              }}
            >
              <div
                className={isDarkMode ? styles.fieldDarkMode : styles.inputArea}
              >
                <input
                  type="text"
                  pattern="\d*"
                  ref={inputHoursRef}
                  onFocus={(e) => {
                    setFocus(true);
                    e.target.value = '';
                    e.target.value = state.hours;
                  }}
                  autoComplete={'off'}
                  placeholder={placeholder ? placeholder.hours : '--'}
                  value={state.hours}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    const textLength = e.target.value.length;
                    if (textLength === 0) {
                      setState({ ...state, hours: '' });
                      return;
                    }
                    if (!isNumeric(e.target.value)) {
                      return;
                    }
                    if (textLength > 2) {
                      inputMinutesRef.current?.focus();
                      return;
                    }
                    setState((s) => {
                      return {
                        ...s,
                        hours: e.target.value,
                      };
                    });
                  }}
                  disabled={disabled}
                  onBlur={(e) => {
                    input.onBlur();
                    const { value } = e.target;
                    if (Number(value) < 10 && value !== '') {
                      setState((s) => {
                        return {
                          ...s,
                          hours: '0' + Number(value).toString(),
                        };
                      });
                    }
                    if (Number(value) > 23) {
                      setState((s) => {
                        return {
                          ...s,
                          hours: '23',
                        };
                      });
                    }
                  }}
                />
                <span>:</span>
                <input
                  type="text"
                  pattern="\d*"
                  ref={inputMinutesRef}
                  onFocus={(e) => {
                    setFocus(true);
                    e.target.value = '';
                    e.target.value = state.minutes;
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  autoComplete={'off'}
                  placeholder={placeholder ? placeholder.minutes : '--'}
                  value={state.minutes}
                  onChange={(e) => {
                    const textLength = e.target.value.length;
                    if (textLength === 0) {
                      setState({ ...state, minutes: '' });
                      return;
                    }
                    if (!isNumeric(e.target.value)) {
                      return;
                    }
                    if (textLength > 2) {
                      inputMinutesRef.current?.focus();
                      return;
                    }
                    setState((s) => {
                      return {
                        ...s,
                        minutes: e.target.value,
                      };
                    });
                  }}
                  disabled={disabled}
                  onBlur={(e) => {
                    input.onBlur();
                    const { value } = e.target;
                    if (Number(value) < 10 && value !== '') {
                      setState((s) => {
                        return {
                          ...s,
                          minutes: '0' + Number(value).toString(),
                        };
                      });
                    }
                    if (Number(value) > 59) {
                      setState((s) => {
                        return {
                          ...s,
                          minutes: '59',
                        };
                      });
                    }
                  }}
                />
              </div>
              <div
                className={mergeClasses(styles.icon, {
                  [styles.iconDisabled]: !!disabled,
                })}
              >
                <IconClock />
              </div>
            </div>
            {meta && meta.touched && meta.error && (
              <ErrorText text={meta.error} className={styles.errorText} />
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default TimeInputField;
