// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Bar } from 'react-chartjs-2';
import styles from './ComparisonOfProcurementIntensityOrganism.module.scss';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ComparisonOfProcurementIntensity } from 'types/dashboard';
import PreviewChartModal from 'components/molecules/PreviewChartModal';
import {
  cutText,
  downloadPNG,
  forceBreakLine,
  formatNumberWithCommas,
} from 'util/commons';
import { ChartData, ChartOptions } from 'chart.js';

const ComparisonOfProcurementIntensityOrganism = ({
  comparisonOfProcurementIntensity,
}: {
  comparisonOfProcurementIntensity: ComparisonOfProcurementIntensity;
}) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  const data: ChartData<'bar'> = {
    labels:
      comparisonOfProcurementIntensity?.comparisonOfProcurementIntensity.map(
        (data) =>
          cutText(
            data.procuredMaterialToCompare,
            window.innerWidth > 1670 ? 7 : 4
          )
      ),
    datasets: [
      {
        maxBarThickness: 100,
        data:
          comparisonOfProcurementIntensity?.comparisonOfProcurementIntensity.map(
            (data) => {
              const value = Number(
                data.intensityCo2OfProcuredMaterialToCompare ?? 0
              );

              return Math.round((value + Number.EPSILON) * 100) / 100;
            }
          ) || [],
        minBarLength: 1.5,
        backgroundColor: '#86EA5C',
        borderRadius: 4,
        datalabels: {
          display: true,
          color: '#CFCFCF',
          anchor: 'end',
          align: 'end',
          formatter: (value) => formatNumberWithCommas(value),
        },
      },
    ],
  };

  const chartRef = useRef<any>();
  const plugins = (isPreview?: boolean) => {
    return [
      {
        id: 'customCanvasBackgroundColor',
        beforeDraw: (chart: any) => {
          const { ctx } = chart;
          ctx.save();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = isPreview ? '#1B1B1B' : 'rgba(0, 0, 0, 0)';
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        },
      },
    ];
  };

  const options: ChartOptions<'bar'> = {
    indexAxis: 'x',
    layout: {
      padding: 24,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: ([tooltipItem]) =>
            tooltipItem?.dataIndex != null
              ? forceBreakLine({
                  text: comparisonOfProcurementIntensity
                    .comparisonOfProcurementIntensity[tooltipItem.dataIndex]
                    ?.procuredMaterialToCompare,
                  breakNode: 17,
                })
              : '',
          label: (tooltipItem) => tooltipItem.formattedValue,
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          color: '#CFCFCF',
        },
      },
      y: {
        stacked: true,
        grid: {
          color: '#3C3C3C',
        },
        title: {
          text: '',
          display: true,
          color: '#CFCFCF',
        },
        ticks: {
          color: '#CFCFCF',
          font: {
            size: 12,
          },
        },
      },
    },
  };

  const comparisonOfProcurementIntensityLength =
    comparisonOfProcurementIntensity.comparisonOfProcurementIntensity.length;

  return (
    <div className={styles.comparisonOfProcurementIntensity}>
      <div className={styles.heading}>
        <p>
          {t('purchase_page.dashboard.comparison_of_procurement_intensity')}
        </p>

        {comparisonOfProcurementIntensityLength > 0 && (
          <div
            className={styles.buttonDownload}
            onClick={() =>
              downloadPNG(
                chartRef,
                t('purchase_page.dashboard.comparison_of_procurement_intensity')
              )
            }
          >
            <IconDownload />
          </div>
        )}
      </div>

      {comparisonOfProcurementIntensityLength > 0 ? (
        <div className={styles.chartContainer}>
          <div className={styles.miniChart}>
            <Bar
              onClick={() => setOpen(true)}
              options={options}
              data={data}
              plugins={plugins()}
              ref={chartRef}
            />
          </div>
        </div>
      ) : (
        <div className={styles.msgEmpty}>
          <p>{t('messages.M_008')}</p>
        </div>
      )}

      {isOpen && (
        <PreviewChartModal
          title={
            t('purchase_page.dashboard.comparison_of_procurement_intensity') ||
            '調達原単位の比較'
          }
          children={
            <div className={styles.chartBox}>
              <Bar
                options={{
                  ...options,
                  maintainAspectRatio: true,
                }}
                data={data}
                plugins={plugins(true)}
              />
              <div className={styles.description}>
                {t('purchase_page.dashboard.description')}
              </div>
            </div>
          }
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default ComparisonOfProcurementIntensityOrganism;
