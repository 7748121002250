// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './EmissionList.module.scss';
import { useState, useEffect } from 'react';
import { EmissionSelectCard } from 'components/molecules/EmissionSelectCard';
import { CategoryItem } from 'util/Types';
import { ItemSize } from 'util/Enums';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import EmissionChildSelect from 'components/molecules/EmissionChildSelect';
import { useTranslation } from 'react-i18next';
import { RoleType } from 'util/Enums';
import { useQuery } from 'util/queryParams';
import { selectTenants } from 'redux/slices/siteInfoSlice';
import { useCheckTenantIdQuery } from 'util/hooks/useCheckTenantIdQuery';

type Props = {
  inputTypes: CategoryItem[];
  onClickSortButton: () => void;
  onSelected: (item: CategoryItem) => void;
  onClickRefreshButton?: () => void;
};

export function EmissionList({
  inputTypes,
  onClickSortButton,
  onSelected,
  onClickRefreshButton,
}: Props) {
  const [largeItems, setLargeItems] = useState<CategoryItem[]>([]);
  const [mediumSmallItemsGroup, setMediumSmallItemsGroup] = useState<
    CategoryItem[][]
  >([]);
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const query = useQuery();
  const tenantId = query.get('tenantId');
  const tenants = useSelector(selectTenants);
  const tenantName = tenants.find((tenant) => tenant.id === Number(tenantId));

  useCheckTenantIdQuery();

  const makeItemMediumSmallGroupList = (items: CategoryItem[]) => {
    const listMedium = items.filter((item) => item?.size === ItemSize.MEDIUM);
    const listMediumCount = Math.ceil(listMedium.length / 2);

    const output: CategoryItem[][] = [];
    Array.from(Array(listMediumCount).keys()).forEach((index) => {
      output.push(
        listMedium[index * 2 + 1]
          ? [listMedium[index * 2], listMedium[index * 2 + 1]]
          : [listMedium[index * 2]]
      );
    });

    const smallItems = items.filter((item) => item?.size === ItemSize.SMALL);
    if (smallItems.length) {
      if (output[output.length - 1] && output[output.length - 1].length < 2) {
        output.splice(
          output.length - 1,
          1,
          smallItems.length >= 2
            ? output[output.length - 1].concat([smallItems[0], smallItems[1]])
            : output[output.length - 1].concat([smallItems[0]])
        );
        const sliceSmallItems = smallItems.slice(
          smallItems.length >= 2 ? 2 : 1
        );
        const listSmallCount = Math.ceil(sliceSmallItems.length / 4);
        sliceSmallItems.length &&
          Array.from(Array(listSmallCount).keys()).forEach((index) => {
            const listPush = [
              sliceSmallItems[index * 4],
              sliceSmallItems[index * 4 + 1],
              sliceSmallItems[index * 4 + 2],
              sliceSmallItems[index * 4 + 3],
            ];
            output.push(listPush.filter((item) => item));
          });
      } else {
        const listSmallCount = Math.ceil(smallItems.length / 4);

        Array.from(Array(listSmallCount).keys()).forEach((index) => {
          const listPush = [
            smallItems[index * 4],
            smallItems[index * 4 + 1],
            smallItems[index * 4 + 2],
            smallItems[index * 4 + 3],
          ];

          output.push(listPush.filter((item) => item));
        });
      }
    }

    return output;
  };
  useEffect(() => {
    if (!inputTypes.length) return;

    setLargeItems(inputTypes.filter((item) => item?.size === ItemSize.LARGE));
    setMediumSmallItemsGroup(
      makeItemMediumSmallGroupList(
        inputTypes.filter((item) => item?.size !== ItemSize.LARGE)
      )
    );
  }, [inputTypes]);

  return (
    <div className={styles.compressionRegistration}>
      <div className={styles.tenantNameMobile}>{tenantName?.name || ''}</div>
      <div className={styles.emissionListHeader}>
        <div
          className="count-list"
          dangerouslySetInnerHTML={{
            __html:
              t('common.page', {
                page: inputTypes.length || 0,
              }) ?? '',
          }}
        />

        <div className={styles.emissionListHeaderTenantName}>
          {tenantName?.name || ''}
        </div>
        {inputTypes.length > 0 && user.role === RoleType.AdminSite ? (
          <div className={styles.emissionListHeaderSortButton}>
            <button onClick={onClickSortButton}>
              {t('common.button.sort')}
            </button>
            {onClickRefreshButton && (
              <button onClick={onClickRefreshButton}>
                {t('common.button.reload')}
              </button>
            )}
          </div>
        ) : (
          <div className={styles.emissionListHeaderSortButton}></div>
        )}
      </div>

      <div className={styles.gridArea}>
        {largeItems.map((item) => {
          return item.typeObject === 'category' || !item.imageUrl ? (
            <EmissionSelectCard
              key={item.id}
              item={item}
              onSelected={onSelected}
            />
          ) : (
            <EmissionChildSelect
              key={item.id}
              item={item}
              onClick={onSelected}
            />
          );
        })}
        {mediumSmallItemsGroup.map((group, index) => {
          return (
            <div
              className={`${styles.mediumItemsGroup} ${
                (largeItems.length + index) % 4 === 0 ? styles.customChange : ''
              } `}
              key={index}
            >
              {group.map((item, i) => {
                return item.typeObject === 'category' || !item.imageUrl ? (
                  <EmissionSelectCard
                    key={item.id}
                    item={item}
                    onSelected={onSelected}
                  />
                ) : (
                  <EmissionChildSelect
                    classNameSelectSize={
                      (largeItems.length + index) % 4 === 0 &&
                      i % 2 === 0 &&
                      item.size === ItemSize.SMALL
                        ? styles.customEmissionChildSelect
                        : ''
                    }
                    key={item.id}
                    item={item}
                    onClick={onSelected}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
