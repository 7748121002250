// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import { ModalType } from 'util/Enums';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { ProducerSiteItem } from 'util/siteManagementTypes';
import { ModalSiteState } from 'pages/AdminSiteListManagement/AdminSiteListManagement';
import {
  fetchAcceptanceGrades,
  fetchAcceptanceMaterials,
  fetchGrades,
  fetchMaterialTypeNames,
  fetchProvinceDistrictSuggestion,
  getDetailProducerSite,
} from 'apis/operator_site/siteApi';
import uniqBy from 'lodash/uniqBy';
import CreateUpdateProducerSiteForm from 'components/molecules/CreateUpdateProducerSiteForm';
import { baseProducerSiteForm } from 'util/constants/sites';
import {
  fetchMachineTypeSuggestions,
  fetchPackingStyleSuggestions,
  fetchProducerMethodSuggestions,
  fetchQualityInspectionSuggestions,
} from 'apis/commons/suggestsApi';

interface Props {
  createUpdateSiteModal: ModalSiteState;
  onClose?: () => void;
  onCreateProducerSite: (createProducerForm: ProducerSiteItem) => void;
  onUpdateProducerSite: (updateProducerForm: ProducerSiteItem) => void;
}

const CreateUpdateProducerSiteModal: React.FC<Props> = ({
  createUpdateSiteModal,
  onClose,
  onCreateProducerSite,
  onUpdateProducerSite,
}) => {
  const { t } = useTranslation();
  const [inputForm, setInputForm] =
    useState<ProducerSiteItem>(baseProducerSiteForm);
  const [provinceSuggestions, setProvinceSuggestions] = useState<string[]>([]);
  const [districtSuggestions, setDistrictSuggestions] = useState<string[]>([]);
  const [machineTypeSuggestions, setMachineTypeSuggestions] = useState<
    string[]
  >([]);
  const [acceptanceGradeSuggestions, setAcceptanceGradeSuggestions] = useState<
    string[]
  >([]);
  const [enrollableGradeSuggestions, setEnrollableGradeSuggestions] = useState<
    string[]
  >([]);
  const [enrollableTypeSuggestions, setEnrollableTypeSuggestions] = useState<
    string[]
  >([]);
  const [acceptanceMaterialSuggestions, setAcceptanceMaterialSuggestions] =
    useState<string[]>([]);
  const [packingStyleSuggestions, setPackingStyleSuggestions] = useState<
    string[]
  >([]);
  const [qualityInspectionSuggestions, setQualityInspectionSuggestions] =
    useState<string[]>([]);
  const [producerMethodSuggestions, setProducerMethodSuggestions] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (
      createUpdateSiteModal.type === ModalType.update &&
      createUpdateSiteModal?.siteEditing &&
      createUpdateSiteModal?.siteEditing.id
    ) {
      getDetailProducerSite(createUpdateSiteModal.siteEditing.id, (data) => {
        if (data) {
          setInputForm({
            ...baseProducerSiteForm,
            ...data,
            machineType: data.machineType
              .filter((item) => item !== '')
              .map((item) => item),
            province: data.siteAddresses[0]?.province || '',
            district: data.siteAddresses[0]?.district || '',
            enrollableGrade: data.enrollableGrade.map((item) => item.name),
            enrollableGradeDefault: data.enrollableGrade,
            enrollableType:
              data?.enrollableType?.map((item) => item.name) || [],
            enrollableTypeDefault: data.enrollableType,
            address: data.address || '',
            latitude: data.latitude ? data.latitude : '',
            longitude: data.longitude || '',
            postalCode: data.postalCode || '',
          });
        }
      });
    }
  }, [createUpdateSiteModal]);

  useEffect(() => {
    fetchProvinceDistrictSuggestion(false, (data) => {
      if (data) {
        setProvinceSuggestions(uniqBy(data.map((item) => item.province)));
        setDistrictSuggestions(uniqBy(data.map((item) => item.district)));
      }
    });
    fetchMachineTypeSuggestions('Producer', (data) => {
      if (data) {
        setMachineTypeSuggestions(data.map((item) => item.machineType));
      }
    });
    fetchGrades((data) => {
      if (data) {
        setEnrollableGradeSuggestions(data.map((item) => item.grade));
      }
    });
    fetchAcceptanceGrades((data) => {
      if (data) {
        setAcceptanceGradeSuggestions(data.map((item) => item.acceptanceGrade));
      }
    });
    fetchMaterialTypeNames((data) => {
      if (data) {
        setEnrollableTypeSuggestions(data.map((item) => item.materialType));
      }
    });
    fetchAcceptanceMaterials((data) => {
      if (data) {
        setAcceptanceMaterialSuggestions(
          data.map((item) => item.acceptanceMaterial)
        );
      }
    });
    fetchPackingStyleSuggestions('Producer', (data) => {
      if (data) {
        setPackingStyleSuggestions(data.map((item) => item.packingStyle));
      }
    });
    fetchQualityInspectionSuggestions((data) => {
      if (data) {
        setQualityInspectionSuggestions(
          data.map((item) => item.qualityInspectionMethod)
        );
      }
    });
    fetchProducerMethodSuggestions((data) => {
      if (data) {
        setProducerMethodSuggestions(data.map((item) => item.producerMethod));
      }
    });
  }, []);

  return (
    <div className={`${styles.createUpdateSiteModal}`}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {createUpdateSiteModal.type === ModalType.add
                ? t('site_management.modal.create_heading')
                : t('site_management.modal.edit_heading')}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <CreateUpdateProducerSiteForm
            provinceSuggestions={provinceSuggestions}
            districtSuggestions={districtSuggestions}
            machineTypeSuggestions={machineTypeSuggestions}
            enrollableGradeSuggestions={enrollableGradeSuggestions}
            acceptanceGradeSuggestions={acceptanceGradeSuggestions}
            enrollableTypeSuggestions={enrollableTypeSuggestions}
            packingStyleSuggestions={packingStyleSuggestions}
            qualityInspectionSuggestions={qualityInspectionSuggestions}
            acceptanceMaterialSuggestions={acceptanceMaterialSuggestions}
            producerMethodSuggestions={producerMethodSuggestions}
            onCreateProducerSite={onCreateProducerSite}
            onUpdateProducerSite={onUpdateProducerSite}
            initForm={inputForm}
            setInputForm={setInputForm}
            type={createUpdateSiteModal.type}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateUpdateProducerSiteModal;
