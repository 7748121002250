// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import styles from './index.module.scss';
import { InputDataListField } from '../InputDataListField';
import { IconDelete } from 'components/atoms/icons/IconDelete';
import { IconAddCircleOutline } from 'components/atoms/icons/IconAddCircleOutline';
import arrayMutators from 'final-form-arrays';
import {
  isValidTime,
  validateEmailAddressNotRequired,
  validateExactLength,
  validateId,
  validateRequiredSelectField,
  validateSiteAddressesField,
  validateStringField,
} from 'util/validator';
import { SelectFieldMultipleChoise } from '../SelectFieldMultipleChoise';
import { FieldArray } from 'react-final-form-arrays';
import { ModalType } from 'util/Enums';
import { ModalSiteState } from 'pages/AdminSiteListManagement/AdminSiteListManagement';
import {
  CollectSiteInput,
  ProvinceDistrict,
  Vehicle,
} from 'util/siteManagementTypes';
import { uniqBy } from 'lodash';
import TimeInputField from '../TimeInputField';
import { InputTagField } from '../InputTagField';
import { useTranslation } from 'react-i18next';
import { Label } from 'components/atoms/Label';
import { REGEX_OBJECT } from 'util/ConstantValues';

interface Props {
  createUpdateModal: ModalSiteState;
  vehicleTypes: Vehicle[];
  acceptanceMaterials: string[];
  provinceSuggestion: ProvinceDistrict[];
  onSubmit: (form: CollectSiteInput) => void;
  acceptanceGrades: string[];
  inputForm: CollectSiteInput;
}
const CreateUpdateCollectSiteInputForm: React.FC<Props> = ({
  createUpdateModal,
  vehicleTypes,
  provinceSuggestion,
  acceptanceMaterials,
  onSubmit,
  acceptanceGrades,
  inputForm,
}) => {
  const { t } = useTranslation();
  const [formHasError, setFormHasError] = useState<boolean>(false);

  const MAX_PROVINCE = 200;

  const provinces = useMemo(() => {
    const uniqProvinces = uniqBy(provinceSuggestion.map((i) => i.province));
    return uniqProvinces.map((i) => {
      return { id: i, name: i };
    });
  }, [provinceSuggestion]);

  return (
    <div className={styles.createBody}>
      <Form<CollectSiteInput>
        onSubmit={onSubmit}
        initialValues={inputForm}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
          ...arrayMutators,
        }}
        validate={(values) => {
          return {
            id: validateId('拠点ID', 20, values.id),
            name: validateStringField('拠点名', 100, values.name),
            vehicleType: validateStringField(
              'トラック種類',
              100,
              values.vehicleType
            ),
            siteAddresses: validateSiteAddressesField(
              values.siteAddresses,
              '都道府県',
              MAX_PROVINCE
            ),
            acceptanceMaterials: validateRequiredSelectField(
              values.acceptanceMaterials,
              '受け取り材料'
            ),
            acceptanceGrades: validateRequiredSelectField(
              values.acceptanceGrades,
              '受け取りグレード'
            ),
            ediSubscriberNumber: validateExactLength({
              value: values.ediSubscriberNumber,
              length: 7,
            }),
            ediConfirmationKey: validateExactLength({
              value: values.ediConfirmationKey,
              length: 8,
            }),
          };
        }}
      >
        {(props) => {
          return (
            <form className={styles.formInput} noValidate>
              <div className={styles.inputFrame}>
                <InputDataListField
                  label={'拠点ID'}
                  placeholder="拠点ID"
                  name="id"
                  required
                  maxlength={20}
                  disabled={createUpdateModal.type === ModalType.update}
                />
                <InputDataListField
                  label="拠点名"
                  placeholder="拠点名"
                  name="name"
                  required
                  maxlength={100}
                />
                <InputDataListField
                  label="EDI加入者番号"
                  placeholder="EDI加入者番号"
                  name="ediSubscriberNumber"
                  maxlength={7}
                  validRegex={REGEX_OBJECT.numberStringOnly}
                />
                <InputDataListField
                  label="EDI確認キー"
                  placeholder="EDI確認キー"
                  name="ediConfirmationKey"
                  maxlength={8}
                  validRegex={REGEX_OBJECT.numberStringOnly}
                />
                <InputDataListField
                  label="トラック種類"
                  placeholder="トラック種類"
                  name="vehicleType"
                  required
                  dataSuggestions={vehicleTypes.map((i) => {
                    return { name: i.vehicleType };
                  })}
                  onSelectSuggestItem={(value) => {
                    props.form.mutators.setValue('vehicleType', value);
                  }}
                  maxlength={100}
                />
                <InputTagField
                  tagValues={props.values.truckNumbers}
                  label={'トラックナンバー'}
                  placeholder="トラックナンバー"
                  name="truckNumbers"
                  onChangeData={(tags) => {
                    props.form.mutators.setValue('truckNumbers', uniqBy(tags));
                  }}
                  maxlength={30}
                  maxTags={200}
                  onError={setFormHasError}
                  maxTagError={`トラックナンバーを200個まで入力してください。`}
                />

                <Label
                  className={styles.headingServiceArea}
                  text="対応エリア"
                  tag={'div'}
                />

                <SelectFieldMultipleChoise
                  label="都道府県"
                  placeholder="都道府県"
                  name="siteAddresses"
                  options={provinces}
                  required
                  selectedOptions={props.values.siteAddresses}
                  onChange={(selectedItems) => {
                    props.form.mutators.setValue(
                      'siteAddresses',
                      selectedItems
                    );
                  }}
                  maxTagError={
                    props.values.siteAddresses &&
                    props.values.siteAddresses.length > MAX_PROVINCE
                      ? `都道府県は${MAX_PROVINCE}個まで登録できます。`
                      : ''
                  }
                />
                <div className={styles.quantity}>
                  <div className={styles.quantityFieldWidth}>
                    <TimeInputField
                      label="回収可能時間（開始）"
                      name="startTime"
                      value={props.values.startTime}
                      onChangeTime={(time) => {
                        props.form.mutators.setValue('startTime', time);
                      }}
                      validate={(value) => {
                        return isValidTime('回収可能時間（開始）', value);
                      }}
                    />
                  </div>
                  <div
                    className={`${styles.quantityFieldWidth} ${styles.space}`}
                  >
                    <TimeInputField
                      label="回収可能時間（終了）"
                      name="endTime"
                      value={props.values.endTime}
                      onChangeTime={(time) => {
                        props.form.mutators.setValue('endTime', time);
                      }}
                      validate={(value) => {
                        return isValidTime('回収可能時間（終了）', value);
                      }}
                    />
                  </div>
                </div>
                <InputTagField
                  tagValues={props.values.acceptanceMaterials}
                  label={'受け取り材料'}
                  placeholder="受け取り材料"
                  name="acceptanceMaterials"
                  dataSuggestions={acceptanceMaterials.map((item) => {
                    return {
                      name: item,
                    };
                  })}
                  onChangeData={(tags) => {
                    props.form.mutators.setValue('acceptanceMaterials', tags);
                  }}
                  uniqueTags
                  required
                  maxTags={100}
                  onError={setFormHasError}
                  maxTagError={`受け取り材料を100個まで入力してください。`}
                />
                <InputTagField
                  tagValues={props.values.acceptanceGrades}
                  label={'受け取りグレード'}
                  placeholder="受け取りグレード"
                  name="acceptanceGrades"
                  dataSuggestions={acceptanceGrades.map((item) => {
                    return {
                      name: item,
                    };
                  })}
                  onChangeData={(tags) => {
                    props.form.mutators.setValue('acceptanceGrades', tags);
                  }}
                  uniqueTags
                  required
                  maxTags={100}
                  maxTagError={`受け取りグレードを100個まで入力してください。`}
                  onError={setFormHasError}
                />
                <TimeInputField
                  label="通知受信時間"
                  name="noticeTime"
                  value={props.values.noticeTime}
                  onChangeTime={(time) => {
                    props.form.mutators.setValue('noticeTime', time);
                  }}
                  validate={(value) => {
                    return isValidTime('通知受信時間', value);
                  }}
                />
                <p className={styles.labelEmail}>通知受信メールアドレス</p>
                <FieldArray name="emails">
                  {({ fields }) => {
                    return (
                      <div>
                        {fields.map((name, index) => (
                          <div key={index} className={styles.email}>
                            <div className={styles.emailField}>
                              <InputDataListField
                                placeholder="通知受信メールアドレス"
                                name={`${name}.email`}
                                validate={validateEmailAddressNotRequired}
                              />

                              {fields.length && fields.length > 1 && (
                                <div
                                  className={styles.deleteIcon}
                                  onClick={() => {
                                    fields.remove(index);
                                  }}
                                >
                                  <IconDelete size={16} />
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                        <div
                          className={
                            props.values.emails.length < 3
                              ? styles.addEmailField
                              : styles.addEmailFieldDisabled
                          }
                        >
                          <div>
                            <div
                              onClick={() => {
                                if (props.values.emails.length < 3) {
                                  fields.push('emails');
                                }
                              }}
                            >
                              <IconAddCircleOutline />
                              <p>メールを追加</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </FieldArray>
              </div>
              <div className={styles.submit}>
                <button
                  disabled={props.invalid || formHasError}
                  className={styles.submitButton}
                  type="button"
                  onClick={props.handleSubmit}
                >
                  {createUpdateModal.type === ModalType.add
                    ? t('site_management.modal_create_site.button_create')
                    : t('site_management.modal_update_site.button_update')}
                </button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default CreateUpdateCollectSiteInputForm;
