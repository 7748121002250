// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { createAxios } from 'ts/createAxios';
import { OfflineSiteResponse } from 'types/offline';
import { API_ENDPOINTS } from 'util/endPoints';

export const getOfflineSiteData = async (params: {
  successCallback: (data: OfflineSiteResponse) => Promise<void>;
}) => {
  const { successCallback } = params;

  try {
    const { data } = await createAxios(
      undefined,
      undefined,
      true,
      undefined
    ).get(API_ENDPOINTS.OFFLINE_SITE_DATA);

    await successCallback(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
