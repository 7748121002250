// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './CustomCsvDownloadButton.scss';

import { useTranslation } from 'react-i18next';

import { IconDownload } from '../icons/IconDownload';

type CustomCsvDownloadButtonProps = {
  disabled?: boolean;
  onClick?: VoidFunction;
};

export const CustomCsvDownloadButton = (
  props: CustomCsvDownloadButtonProps
) => {
  const { t } = useTranslation();

  return (
    <button
      className="csv-button"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <IconDownload />
      {t('common.csv_download')}
    </button>
  );
};
