// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { fetchDashboardsPoolRatioData } from 'apis/dashboards/dashboardApi';
import ExtendableDonutChart from 'components/molecules/ExtendableDonutChart';
import { SelectField } from 'components/molecules/SelectField';
import snakeCase from 'lodash/snakeCase';
import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { DashboardsPoolRatioData, Option } from 'util/Types';
import { convertWeight, mergeClasses, uniqRandomNumber } from 'util/commons';
import { UnitType } from 'util/constants/dashboard';
import { API_ENDPOINTS } from 'util/endPoints';
import { PoolDashboardMasterFilter } from '../PoolDashboardFilter';
import styles from './PoolDashboardDonutCharts.module.scss';

type PoolDashboardDonutChartsProps = {
  className?: string;
  filter?: PoolDashboardMasterFilter;
  fetchTrigger?: number;
};

enum PoolDonutChartType {
  STATIONS = 'stations',
  WASTE_TYPES = 'wasteTypes',
  SITE_TYPES = 'siteTypes',
  MATERIALS = 'materials',
}

const PoolDashboardDonutCharts: React.FC<PoolDashboardDonutChartsProps> = ({
  className,
  filter,
  fetchTrigger,
}) => {
  const { t, i18n } = useTranslation();
  const [chartType, setChartType] = useState<PoolDonutChartType>(
    PoolDonutChartType.STATIONS
  );
  const [ratioData, setRatioData] = useState<DashboardsPoolRatioData>();
  const [chartKey, setChartKey] = useState<number>();

  const MAXIMUM_DONUT_CHART_ITEMS = 15;
  const GRAY_COLORS_PALETTE = ['#E7E8E9', '#B7B7B7', '#878787', '#5E5E5E'];
  const MINIMUM_PRIMARY_COLORS_NUM = 4;

  const chartTypeOptions = useMemo(
    (): Option[] => [
      {
        label: t('pool_dashboard.donut_chart_filter.station'),
        value: PoolDonutChartType.STATIONS,
      },
      {
        label: t('pool_dashboard.donut_chart_filter.waste_type'),
        value: PoolDonutChartType.WASTE_TYPES,
      },
      {
        label: t('pool_dashboard.donut_chart_filter.site_type'),
        value: PoolDonutChartType.SITE_TYPES,
      },
      {
        label: t('pool_dashboard.donut_chart_filter.material'),
        value: PoolDonutChartType.MATERIALS,
      },
    ],
    [t]
  );

  const chartTypeTitleMapper = useMemo(
    () => ({
      [PoolDonutChartType.STATIONS]: t('pool_dashboard.chart_labels.stations'),
      [PoolDonutChartType.WASTE_TYPES]: t(
        'pool_dashboard.chart_labels.waste_type'
      ),
      [PoolDonutChartType.SITE_TYPES]: t(
        'pool_dashboard.chart_labels.site_types'
      ),
      [PoolDonutChartType.MATERIALS]: t(
        'pool_dashboard.chart_labels.materials'
      ),
    }),
    [t]
  );

  useEffect(() => {
    if (filter) {
      switch (chartType) {
        case PoolDonutChartType.WASTE_TYPES:
          fetchDashboardsPoolRatioData(
            API_ENDPOINTS.DASHBOARDS_POOL_RATIO_OF_WASTES,
            filter,
            (res) => setRatioData(res)
          );
          break;
        case PoolDonutChartType.SITE_TYPES:
          fetchDashboardsPoolRatioData(
            API_ENDPOINTS.DASHBOARDS_POOL_RATIO_OF_TYPES,
            filter,
            (res) =>
              setRatioData({
                ...res,
                items: res.items?.map((item) => ({
                  ...item,
                  name: item.type || '',
                })),
              })
          );
          break;
        case PoolDonutChartType.MATERIALS:
          fetchDashboardsPoolRatioData(
            API_ENDPOINTS.DASHBOARDS_POOL_RATIO_OF_MATERIALS,
            filter,
            (res) => setRatioData(res)
          );
          break;
        default:
          fetchDashboardsPoolRatioData(
            API_ENDPOINTS.DASHBOARDS_POOL_RATIO_OF_STATIONS,
            filter,
            (res) => setRatioData(res)
          );
      }
    }
  }, [filter, chartType, fetchTrigger]);

  useEffect(() => {
    setChartKey(uniqRandomNumber());
  }, [i18n.language, ratioData?.items]);

  const renderFilterSelect = () => (
    <div className={styles.Filter}>
      <Form onSubmit={() => {}}>
        {() => (
          <SelectField
            name="chartType"
            options={chartTypeOptions}
            value={chartType}
            isDarkmode
            onChange={(value) => setChartType(value as PoolDonutChartType)}
          />
        )}
      </Form>

      <p>{t('pool_dashboard.donut_chart_select_guide')}</p>
    </div>
  );

  const renderChartNote = () => {
    if (!ratioData?.items.length || chartType !== PoolDonutChartType.STATIONS) {
      return null;
    }

    return (
      <div className={styles.NoteMessage}>
        <p>{t('pool_dashboard.donut_chart_message') || ''}</p>
      </div>
    );
  };

  return (
    <div className={mergeClasses(styles.PoolDashboardDonutCharts, className)}>
      <ExtendableDonutChart
        key={chartKey}
        className={styles.PoolDashboardExtendableDonutChart}
        title={chartTypeTitleMapper[chartType]}
        data={
          ratioData?.items.map((item) => ({
            label:
              chartType === PoolDonutChartType.WASTE_TYPES
                ? t(`pool_dashboard.filter.${snakeCase(item.name)}`)
                : item.name,
            value:
              Number(ratioData?.total) > 99999
                ? convertWeight(UnitType.T, Number(item.weight))
                : Number(item.weight || 0),
          })) || []
        }
        chartOptions={{
          emptyMessage: t('dashboard.empty_chart_data') || '',
          percentShowLimit: 4,
          isDownloadPNG: true,
          customAction: renderFilterSelect(),
          chartOptions: {
            maintainAspectRatio: false,
          },
          legendMode: 'column',
          description: renderChartNote(),
          customCore: {
            tooltipOdd: 1,
          },
          datasetsConfig: {
            borderWidth: 0,
          },
        }}
        autoSort
        maxDisplayItems={MAXIMUM_DONUT_CHART_ITEMS}
        colorsSetting={{
          endColors: GRAY_COLORS_PALETTE,
          minimumPrimaryColors: MINIMUM_PRIMARY_COLORS_NUM,
        }}
        type={{
          unit: Number(ratioData?.total) > 99999 ? 't' : 'kg',
        }}
      />

      {renderChartNote()}
    </div>
  );
};

export default PoolDashboardDonutCharts;
