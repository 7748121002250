import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useHistory } from 'react-router';
import { Path } from 'routes';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { TCompressionBooking } from 'types/compression';
import { DATE_FORMAT } from 'util/ConstantValues';
import { mergeClasses, normalizeNumber } from 'util/commons';
import { CompressionDeliveryContent } from '../CompressionDeliveryContent';
import styles from './CompressionBookingItem.module.scss';

type CompressionBookingItemProps = {
  compression: TCompressionBooking;
  key: number;
};

const CompressionBookingItem = ({
  compression,
  key,
}: CompressionBookingItemProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onClickQRCode = () => {
    history.push(
      `${Path.shippingRegistrationDetail}?deliveryId=${compression.deliveryId}`,
      compression
    );
  };

  return (
    <div key={key} className={styles.CompressionBookingItem}>
      <div className={mergeClasses(styles.w65, styles.content)}>
        <div className={styles.contentRow}>
          <div className={styles.left}>{t('compressions.delivery_date')}</div>
          <div className={styles.right}>
            <CompressionDeliveryContent
              contents={getFormatDateWithCurrentTimezone(
                compression.requestedDatetime,
                DATE_FORMAT.slaYMDHm
              )}
            />
          </div>
        </div>
        <div className={styles.contentRow}>
          <div className={styles.left}>
            {t('compressions.delivery_destination')}
          </div>
          <div className={styles.right}>
            <CompressionDeliveryContent
              contents={compression.temporaryTargetSite.name}
            />
          </div>
        </div>
        <div className={styles.contentRow}>
          <div className={styles.left}>{t('compressions.material_name')}</div>
          <div className={styles.right}>
            <CompressionDeliveryContent contents={compression.description} />
          </div>
        </div>
        <div className={styles.contentRow}>
          <div className={styles.left}>{t('compressions.grade')}</div>
          <div className={styles.right}>
            <CompressionDeliveryContent contents={compression.grade} />
          </div>
        </div>
        <div className={styles.contentRow}>
          <div className={styles.left}>{t('compressions.weight')}</div>
          <div className={styles.right}>
            <CompressionDeliveryContent
              contents={`${normalizeNumber({ value: compression.weight })}kg`}
            />
          </div>
        </div>
      </div>
      <div className={styles.w35}>
        <div className={styles.QRContainer} onClick={onClickQRCode}>
          <QRCode
            size={80}
            className={styles.QRCode}
            value={compression.qrCode}
            viewBox={`0 0 80 80`}
          />
        </div>
      </div>
    </div>
  );
};

export default CompressionBookingItem;
