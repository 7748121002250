// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import DonutChart from 'components/molecules/DonutChart';
import dayjs from 'dayjs';
import i18n from 'i18n';
import { FilterObject } from 'pages/Dashboard';
import React, { useEffect, useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { createAxios } from 'ts/createAxios';
import { DATE_FORMAT } from 'util/ConstantValues';
import { RoleType } from 'util/Enums';
import { normalizeNumber } from 'util/commons';
import { API_ENDPOINTS } from 'util/endPoints';
import styles from './RateRecycleMethodChart.module.scss';

interface RateRecycleMethod {
  name: string;
  rate: number;
  weight: number;
  englishName: string;
}
interface RateRecycleMethodResponse {
  totalRate: string;
  rateRecycleMethods: RateRecycleMethod[];
  dateRange?: string;
  wasteUnits: string[];
  industries: string[];
  tenants: string[];
  sites: string[];
  isYearData: boolean;
}

interface Props {
  filter: FilterObject;
  isAdminOperatorSiteView?: boolean;
}

const RateRecycleMethodChart: React.FC<Props> = ({
  filter,
  isAdminOperatorSiteView,
}) => {
  const user = useSelector(selectUser);
  const commonsApi = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { t } = useTranslation();
  const [data, setData] = React.useState<RateRecycleMethodResponse>();
  const [loading, setLoading] = React.useState(false);
  const [downloadData, setDownloadData] =
    React.useState<RateRecycleMethodResponse>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadCSVRef = React.useRef<any>();

  const { adminOperatorView, adminSiteView } = useMemo(
    () => ({
      adminOperatorView:
        user.role === RoleType.AdminOperator && !isAdminOperatorSiteView,
      adminSiteView:
        user.role === RoleType.AdminSite || isAdminOperatorSiteView,
    }),
    [user.role, isAdminOperatorSiteView]
  );

  const brandItems = filter?.brandItems?.filter((item) => item.id != null);

  const commonParams = React.useMemo(() => {
    return {
      dateFrom: filter.dates.from,
      dateTo: filter.dates.to,
      siteIds: filter?.sites?.map((item) => item.id) || [],
      siteNames: filter?.sites?.map((item) => item.name) || [],
      wasteUnitNames: filter.wasteUnitNames || [],
      industries: filter.industries || [],
      tenantIds:
        filter?.tenants?.filter((item) => !!item.id)?.map((item) => item.id) ||
        [],
      tenantNames:
        filter?.tenants
          ?.filter((item) => !!item.id)
          ?.map((item) => item.name) || [],
      brandItemIds: brandItems?.map((item) => item.id) || [],
      brandItemNames: brandItems?.map((item) => item.name) || [],
      isDashboardSite: filter.isDashboardSite,
    };
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    commonsApi
      .post<RateRecycleMethodResponse>(
        API_ENDPOINTS.DASHBOARD_RATE_RECYCLE_METHODS,
        commonParams
      )
      .then((res) => {
        setData(res.data);
        setLoading(false);
      });
  }, [commonParams]);

  const handleDownloadCSV = () => {
    commonsApi
      .post(API_ENDPOINTS.DASHBOARD_RATE_RECYCLE_METHODS_EXPORT, commonParams)
      .then((res) => {
        setDownloadData(res.data);
        setTimeout(() => {
          downloadCSVRef.current.link.click();
        }, 500);
      });
  };
  const otherRate = data?.rateRecycleMethods
    .slice(6, data.rateRecycleMethods.length)
    .reduce((total, file) => total + Number(file.rate), 0);

  const dataLabels = data?.rateRecycleMethods.slice(0, 6) || [];
  return (
    <div className={styles.site}>
      {!loading && (
        <DonutChart
          title={
            t('dashboard.rate_recycle_method_chart.name') || '処理方法割合'
          }
          data={[
            ...dataLabels.map((i) => {
              return {
                label:
                  i18n.language === 'ja' ? i.name : i.englishName || i.name,
                value: Number(Number(i.rate).toFixed(2)),
              };
            }),
            ...(data && data.rateRecycleMethods.length > 6
              ? [
                  {
                    label: t('dashboard.rate_recycle_method_chart.other'),
                    value: Number(otherRate?.toFixed(2)) || 0,
                  },
                ]
              : []),
          ]}
          totalRate={normalizeNumber({ value: data?.totalRate })}
          handleDownloadCSV={handleDownloadCSV}
          emptyMessage={t('dashboard.empty_chart_data') || ''}
        />
      )}
      <CSVLink
        className="hidden"
        filename={
          t('dashboard.rate_recycle_method_chart.file_csv_name', {
            date_time: dayjs().format(DATE_FORMAT.slaYMDHm),
          }) + '.csv'
        }
        data={[
          // header
          [
            t('dashboard.rate_recycle_method_chart.process_method'),
            t('dashboard.rate_recycle_method_chart.waste_volume') + '(kg)',
            t('dashboard.rate_recycle_method_chart.weight_percentage') + '(%)',
          ],
          downloadData?.dateRange
            ? [
                t('dashboard.recycling_rate_csv.date_range'),
                downloadData?.dateRange || '',
              ]
            : [],
          downloadData?.sites && adminOperatorView
            ? [
                t('dashboard.recycling_rate_csv.site_name'),
                downloadData?.sites?.join(', ') || '',
              ]
            : [],
          downloadData?.industries && adminSiteView
            ? [
                t('dashboard.recycling_rate_csv.business_type'),
                downloadData?.industries?.join(', ') || '',
              ]
            : [],
          downloadData?.tenants && adminSiteView
            ? [
                t('dashboard.recycling_rate_csv.tenant_name'),
                downloadData?.tenants?.join(', ') || '',
              ]
            : [],
          downloadData?.tenants && user.role === RoleType.BrandOwner
            ? [
                t('dashboard.recycling_rate_csv.store_name'),
                downloadData?.tenants?.join(', ') || '',
              ]
            : [],
          downloadData?.wasteUnits
            ? [
                t('dashboard.recycling_rate_csv.item'),
                downloadData?.wasteUnits?.join(', ') || '',
              ]
            : [],
          ...(downloadData?.rateRecycleMethods.map((item) => {
            return [
              i18n.language === 'ja' ? item.name : item.englishName,
              normalizeNumber({ value: item.weight, toFixed: false }),
              Number(item.rate).toFixed(2),
            ];
          }) || []),
        ].filter((data) => data.length)}
        ref={downloadCSVRef}
      >
        {t('download.volume_reduction_registration')}
      </CSVLink>
    </div>
  );
};

export default RateRecycleMethodChart;
