// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { fetchDashboardsPoolAverageEmissionStatistics } from 'apis/dashboards/dashboardApi';
import BubbleMap, { BubbleMapItem } from 'components/molecules/BubbleMap';
import DateTimeUnitTabButton from 'components/molecules/DateTimeUnitTabButton';
import snakeCase from 'lodash/snakeCase';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTypeRange } from 'util/Enums';
import { AverageEmissionStatistics } from 'util/Types';
import { mergeClasses, normalizeNumber } from 'util/commons';
import { PoolDashboardMasterFilter } from '../PoolDashboardFilter';
import styles from './PoolDashboardResourceMap.module.scss';

type PoolDashboardMapBubbleItem = BubbleMapItem<
  Omit<AverageEmissionStatistics, 'latitude' | 'longitude' | 'avgWeight'>
>;

type PoolDashboardResourceMapProps = {
  className?: string;
  filter?: PoolDashboardMasterFilter;
  fetchTrigger?: number;
};

const PoolDashboardResourceMap: React.FC<PoolDashboardResourceMapProps> = ({
  className,
  filter,
  fetchTrigger,
}) => {
  const { t } = useTranslation();
  const [averageEmissionStatistics, setAverageEmissionStatistics] = useState<
    AverageEmissionStatistics[]
  >([]);
  const [activeTab, setActiveTab] = useState<DateTypeRange>(
    DateTypeRange.MONTH
  );

  useEffect(() => {
    fetchDashboardsPoolAverageEmissionStatistics(
      { ...filter, typeDateRange: activeTab },
      (res) => setAverageEmissionStatistics(res)
    );
  }, [filter, activeTab, fetchTrigger]);

  const data = useMemo(() => {
    return averageEmissionStatistics.reduce(
      (acc: PoolDashboardMapBubbleItem[], currentItem) => {
        const val: PoolDashboardMapBubbleItem = {
          id: currentItem.id,
          name: currentItem.name,
          lat: currentItem.latitude,
          lng: currentItem.longitude,
          value: Number(currentItem.avgWeight),
          operatorName: currentItem.operatorName,
          siteStations: currentItem.siteStations,
        };

        acc.push(val);

        return acc;
      },
      []
    );
  }, [averageEmissionStatistics]);

  const TD = ({
    value,
    leftSide,
  }: {
    value: string | number | null;
    leftSide?: boolean;
  }) => (
    <td
      className={mergeClasses('text-left', styles.tdContent, {
        [styles.pr]: leftSide,
      })}
    >
      {value}
    </td>
  );

  const mapTooltipDraw = useCallback(
    (item: PoolDashboardMapBubbleItem) => (
      <table className="'primary-table'">
        <tbody>
          <tr className={styles.bold}>
            <td
              colSpan={2}
              className={mergeClasses('text-left', styles.tdContent)}
            >
              {item.siteStations.map((station) => (
                <span
                  key={station.id}
                  className={item.siteStations.length > 1 ? styles.station : ''}
                >
                  {station.name}
                </span>
              ))}
            </td>
          </tr>
          <>
            <tr>
              <TD value={t('pool_dashboard.bubble_map.operator')} leftSide />
              <TD value={item.operatorName} />
            </tr>
            <tr>
              <TD value={t('pool_dashboard.bubble_map.site')} leftSide />
              <TD value={item.name} />
            </tr>
            <tr>
              <TD
                value={`${t('pool_dashboard.bubble_map.weight')} (${t(
                  `purchase_page.resource_search.${snakeCase(activeTab)}`
                )})`}
                leftSide
              />
              <TD
                value={`${normalizeNumber({
                  value: item.value,
                })} kg`}
              />
            </tr>
          </>
        </tbody>
      </table>
    ),
    [t, activeTab]
  );

  return (
    <div className={mergeClasses(styles.PoolDashboardResourceMap, className)}>
      <div className={styles.ActionButtons}>
        <DateTimeUnitTabButton
          disableFilterByYearAndDay={false}
          dateFrom={filter?.dateFrom}
          dateTo={filter?.dateTo}
          activeTab={activeTab}
          setActiveTab={(value) => setActiveTab(value)}
        />
      </div>

      <BubbleMap data={data} tooltipDraw={mapTooltipDraw} />
    </div>
  );
};

export default PoolDashboardResourceMap;
