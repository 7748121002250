// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  onClick?: () => void;
}
export const IconSearch: React.FC<Props> = ({ onClick }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.8 3.60061C5.95131 3.60061 5.13737 3.93775 4.53726 4.53787C3.93714 5.13799 3.6 5.95192 3.6 6.80061C3.6 7.64931 3.93714 8.46324 4.53726 9.06336C5.13737 9.66347 5.95131 10.0006 6.8 10.0006C7.64869 10.0006 8.46263 9.66347 9.06274 9.06336C9.66286 8.46324 10 7.64931 10 6.80061C10 5.95192 9.66286 5.13799 9.06274 4.53787C8.46263 3.93775 7.64869 3.60061 6.8 3.60061ZM2 6.80061C1.9999 6.04518 2.17811 5.30038 2.52014 4.62681C2.86216 3.95323 3.35834 3.36989 3.96832 2.92423C4.5783 2.47858 5.28485 2.18318 6.03052 2.06208C6.77618 1.94098 7.53991 1.99758 8.25957 2.22729C8.97924 2.457 9.63452 2.85333 10.1721 3.38405C10.7097 3.91477 11.1145 4.56488 11.3535 5.28152C11.5925 5.99816 11.6589 6.76109 11.5475 7.50825C11.436 8.25542 11.1497 8.96573 10.712 9.58141L14.5656 13.435C14.7113 13.5859 14.792 13.788 14.7901 13.9977C14.7883 14.2075 14.7042 14.4081 14.5559 14.5565C14.4075 14.7048 14.2069 14.7889 13.9971 14.7908C13.7874 14.7926 13.5853 14.7119 13.4344 14.5662L9.5816 10.7134C8.86349 11.224 8.01868 11.5271 7.13974 11.5895C6.2608 11.6519 5.38166 11.4711 4.59864 11.067C3.81562 10.6629 3.15895 10.051 2.70058 9.29849C2.24221 8.54595 1.99983 7.68176 2 6.80061Z"
        fill="#9EA0A2"
      />
    </svg>
  );
};
