// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { mergeClasses } from 'util/commons';
import './Modal.scss';

export type ModalProps = {
  children?: React.ReactNode;
  buttonType?: 'default' | 'white';
  isSmall?: boolean;
  style?: React.CSSProperties;
  className?: string;
};

export const Modal = ({ isSmall, children, style, className }: ModalProps) => {
  return (
    <div className={mergeClasses('modal', className)} style={style}>
      <div className={'contents' + (isSmall ? ' small' : '')}>{children}</div>
    </div>
  );
};
