// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './CollectEmissions.module.scss';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { CheckBox } from 'components/atoms/CheckBox';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from 'util/ConstantValues';
import { TCollectEmission } from 'util/Types';
import { normalizeNumber } from 'util/commons';

export type Emission = TCollectEmission & {
  id: string;
  weight: string;
  description: string;
  datetime: string;
  materialName: string;
  wasteUnit: {
    id: number;
    name: string;
    materialName: string;
  };
};

interface Props {
  emissions: Emission[];
  setError: (error: string) => void;
  checkedIds: string[];
  setCheckedIds: Dispatch<SetStateAction<string[]>>;
  tableStyle?: React.CSSProperties;
}

export function CollectEmissions({
  emissions,
  setError,
  checkedIds,
  setCheckedIds,
  tableStyle,
}: Props) {
  const { t } = useTranslation();

  const filteredArray = useMemo(() => {
    return emissions.filter(({ id }) => checkedIds.includes(id));
  }, [emissions, checkedIds]);

  useEffect(() => {
    const selectedDuplicatedMaterialNames =
      filteredArray.length > 0 &&
      !filteredArray.every(
        (item, _, array) => item.materialName === array[0].materialName
      );
    if (selectedDuplicatedMaterialNames) {
      setError(t('collect_registration.submit_error_material_name'));
    } else {
      setError('');
    }
  }, [filteredArray]);

  const handleChange = (id: string) => {
    setCheckedIds((prev: string[]) => {
      if (prev?.includes(id)) {
        return prev.filter((e) => e !== id);
      }

      return [...prev, id];
    });
  };

  const handleCheckAll = () => {
    if (checkedIds.length >= emissions.length) {
      setCheckedIds([]);
    } else {
      setCheckedIds(emissions.map(({ id }) => id));
    }
  };

  return (
    <div className={styles.collectList}>
      <table
        className={`primary-table ${styles.primaryTable}`}
        style={tableStyle}
      >
        <thead>
          <tr>
            <th
              id="select"
              className={styles.checkBox}
              onClick={handleCheckAll}
            >
              {t('collect_registration.select_all')}
            </th>
            <th className={styles.label}>
              {t('collect_registration.content')}
            </th>
            <th className={styles.content}>
              {t('collect_registration.materialName')}
            </th>
            <th className={styles.content}>
              {t('collect_registration.date_time')}
            </th>
          </tr>
        </thead>
        <tbody>
          {emissions.map((emission, i) => {
            return (
              <tr key={i}>
                <td className={styles.checkBox}>
                  <CheckBox
                    id={emission.id}
                    checked={checkedIds.includes(emission.id)}
                    onChange={() => handleChange(emission.id)}
                  />
                </td>
                <td className={styles.label}>
                  {`${emission.wasteUnit.name} ${normalizeNumber({
                    value: emission.weight,
                  })}`}
                  kg
                </td>
                <td className={styles.content}>{emission.materialName}</td>
                <td className={styles.content}>
                  {getFormatDateWithCurrentTimezone(
                    emission.datetime,
                    DATE_FORMAT.slaMDHm
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
