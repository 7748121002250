// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { createAxios } from 'ts/createAxios';
import { handleError } from 'util/errorHandler';

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers }) => {
    try {
      const axiosInstance = createAxios();
      const result = await axiosInstance({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });
      return { data: result.data, status: result.status };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      handleError(err);
      return {
        error: {
          data: err.response?.data || err.message,
          status: err.response?.status,
        },
      };
    }
  };

export const apiService = createApi({
  reducerPath: 'apiService',
  baseQuery: axiosBaseQuery({
    baseUrl: '',
  }),
  refetchOnMountOrArgChange: true,
  endpoints: () => ({}),
});

export const commonUrlApi = createApi({
  reducerPath: 'commonUrlApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_COMMONS_URL || '',
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ['commons'],
  endpoints: () => ({}),
});
