import AdminConfirmInvoiceContent from 'components/organisms/AdminConfirmInvoiceContent';
import { AdminTemplate } from 'components/templates/AdminTemplate';
import { Path } from 'routes';
import { useQuery } from 'util/queryParams';

const AdminConfirmInvoice = () => {
  const query = useQuery();
  const siteIds = query.get('siteIds');
  const date = query.get('date');
  const operator = query.get('operator');
  const getParams = () => {
    let query = '?';
    if (operator) {
      query += 'operator=' + operator + '&';
    }
    if (date) {
      const dates = date.split('-');
      if (dates[0]) {
        query += 'year=' + dates[0] + '&';
      }
      if (dates[1]) {
        query += 'month=' + dates[1];
      }
    }
    return query;
  };
  return (
    <AdminTemplate
      h1={'請求内容確認'}
      active={''}
      breadcrumb={[
        {
          label: '請求管理',
          href: `${Path.invoiceManagegement}${getParams()}`,
        },
        {
          label: '請求書プレビュー',
        },
      ]}
    >
      <AdminConfirmInvoiceContent siteIds={siteIds} date={date} />
    </AdminTemplate>
  );
};

export default AdminConfirmInvoice;
