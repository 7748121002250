// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { InputDataListField } from '../InputDataListField';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { PostalCodeData, SiteAddressData } from 'util/siteManagementTypes';
import { POSTAL_CODE_LENGTH, REGEX_OBJECT } from 'util/ConstantValues';
import { LoadingSpiner } from 'components/atoms/LoadingSpiner';
import { FormRenderProps } from 'react-final-form';
import _debounce from 'lodash/debounce';
import {
  fetchProvinceDistrictWithPostalCode,
  fetchTudetWithAddress,
} from 'apis/operator_site/siteApi';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { mergeClasses, objectFetch } from 'util/commons';
import { ModalType } from 'util/Enums';

type Props<T> = {
  provinceSuggestions: string[];
  districtSuggestions: string[];
  formProps: FormRenderProps<T, Partial<T>>;
  mode: ModalType;
  prefix?: ReactNode;
  suffix?: ReactNode;
  isVertical?: boolean;
  addressMaxLength?: number;
};

type PostalCode = PostalCodeData &
  Partial<{
    latitude: string;
    longitude: string;
    postalCode: string;
  }>;

function PostalCodeForm<T extends PostalCode>({
  provinceSuggestions,
  districtSuggestions,
  formProps,
  mode,
  prefix,
  suffix,
  isVertical,
  addressMaxLength,
}: Props<T>) {
  const { t } = useTranslation();

  const isInit = useRef<boolean>(true);
  const formChanged = useRef<boolean>(false);
  const [latitudeData, setLatitudeData] = useState<string>();
  const [longitudeData, setLongitudeData] = useState<string>();
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [provinceData, setProvinceData] = useState<string>();
  const [districtData, setDistrictData] = useState<string>();
  const [loadingPostalCode, setLoadingPostalCode] = useState(false);

  const handleChangePostalCode = <T,>(
    formProps: FormRenderProps<T, Partial<T>>,
    value: string
  ) => {
    formChanged.current = true;
    formProps.form.mutators.setValue('postalCode', value);

    if (value.length === POSTAL_CODE_LENGTH) {
      _debounce(() => {
        fetchPostalCodeData(formProps, value);
      }, 500)();
    } else {
      setDistrictData('');
      setProvinceData('');
    }
  };

  const fetchPostalCodeData = async <T,>(
    formProps: FormRenderProps<T, Partial<T>>,
    postalCode: string
  ) => {
    setLoadingPostalCode(true);

    fetchProvinceDistrictWithPostalCode(postalCode, (data?: PostalCodeData) => {
      setDistrictData(data?.district);
      setProvinceData(data?.province);

      formProps.form.mutators.setValue('province', data?.province || '');
      formProps.form.mutators.setValue('district', data?.district || '');
      formProps.form.mutators.setValue('address', data?.address || '');

      handleChangeAddress({
        formProps,
        values: data ?? {},
        forceUpdate: true,
      });
    }).finally(() => setLoadingPostalCode(false));
  };

  const handleChangeAddress = useCallback(
    _debounce(
      <T,>(params: {
        formProps: FormRenderProps<T, Partial<T>>;
        values: Partial<PostalCodeData>;
        forceUpdate?: boolean;
      }) => {
        formChanged.current = true;

        const { formProps, values, forceUpdate } = params;

        Object.entries(values).forEach(([key, value]) => {
          formProps.form.mutators.setValue(key, value);
        });

        fetchAddressData({
          formProps,
          values,
          forceUpdate,
        });
      },
      500
    ),
    []
  );

  const fetchAddressData = <T,>(params: {
    formProps: FormRenderProps<T, Partial<T>>;
    values: Partial<PostalCodeData>;
    forceUpdate?: boolean;
  }) => {
    const { formProps, values, forceUpdate } = params;
    const updatingKeys = Object.keys(values);
    const newDataKeys: (keyof PostalCodeData)[] = [
      'province',
      'district',
      'address',
    ];

    const newData = newDataKeys.reduce((rs: PostalCodeData, key) => {
      rs[key] =
        values[key] ||
        (forceUpdate || updatingKeys.includes(key)
          ? ''
          : objectFetch(formProps.values, key));

      return rs;
    }, {} as PostalCodeData);

    if (Object.values(newData).every((val) => Boolean(val))) {
      setLoadingAddress(true);
      fetchTudetWithAddress(
        `${newData.province || ''}${newData.district || ''}${
          values.address || ''
        }`,
        (data?: SiteAddressData) => {
          setLatitudeData(data?.latitude);
          setLongitudeData(data?.longitude);

          if (data) {
            formProps.form.mutators.setValue(
              'latitude',
              data?.latitude && String(data.latitude)
            );
            formProps.form.mutators.setValue(
              'longitude',
              data?.longitude && String(data.longitude)
            );
          } else {
            formProps.form.mutators.setValue('latitude', '');
            formProps.form.mutators.setValue('longitude', '');
          }
        }
      ).finally(() => setLoadingAddress(false));
    } else {
      formProps.form.mutators.setValue('latitude', '');
      formProps.form.mutators.setValue('longitude', '');
      setLatitudeData('');
      setLongitudeData('');
    }
  };

  useEffect(() => {
    if (isInit.current && mode === ModalType.update && !formChanged.current) {
      if (formProps.values.postalCode?.length === POSTAL_CODE_LENGTH) {
        setLoadingPostalCode(true);

        fetchProvinceDistrictWithPostalCode(
          formProps.values.postalCode,
          (data?: PostalCodeData) => {
            setDistrictData(data?.district);
            setProvinceData(data?.province);
          }
        ).finally(() => {
          setLoadingPostalCode(false);
          isInit.current = false;
        });
      }

      if (
        formProps.values.address &&
        formProps.values.district &&
        formProps.values.province
      ) {
        setLoadingAddress(true);

        fetchTudetWithAddress(
          `${formProps.values.province || ''}${
            formProps.values.district || ''
          }${formProps.values.address || ''}`,
          (data?: SiteAddressData) => {
            setLatitudeData(data?.latitude);
            setLongitudeData(data?.longitude);
          }
        ).finally(() => {
          setLoadingAddress(false);
          isInit.current = false;
        });
      }
    }
  }, [formProps.values, mode]);

  return (
    <div className={styles.inputFrame}>
      <div className={mergeClasses({ [styles.textFrame]: !isVertical })}>
        {prefix}
        <InputDataListField
          label={t('site_management.modal.field_postal_code')}
          placeholder={t('site_management.modal.field_postal_code')}
          name="postalCode"
          handleChange={(data) => handleChangePostalCode(formProps, data)}
          validRegex={REGEX_OBJECT.number}
          maxlength={POSTAL_CODE_LENGTH}
          subFix={
            loadingPostalCode ? (
              <div className={styles.loadingSpinner}>
                <LoadingSpiner width={20} height={20} color="#4FA99B" />
              </div>
            ) : undefined
          }
          disabled={loadingPostalCode}
          className={styles.inputField}
          onWheel={() => (document.activeElement as HTMLElement).blur()}
        />
        {suffix}
      </div>

      <div className={mergeClasses({ [styles.textFrame]: !isVertical })}>
        <InputDataListField
          label={t('site_management.modal.field_province')}
          placeholder={t('site_management.modal.field_province')}
          subFix={
            loadingPostalCode ? (
              <div className={styles.loadingSpinner}>
                <LoadingSpiner width={20} height={20} color="#4FA99B" />
              </div>
            ) : undefined
          }
          disabled={!!provinceData}
          name="province"
          required
          maxlength={100}
          dataSuggestions={provinceSuggestions.map((item) => {
            return { name: item };
          })}
          onSelectSuggestItem={(value) =>
            handleChangeAddress({
              formProps,
              values: { province: value },
            })
          }
          handleChange={(value) => {
            handleChangeAddress({
              formProps,
              values: { province: value },
            });
          }}
          className={styles.inputField}
        />
        <InputDataListField
          label={t('site_management.modal.field_district')}
          placeholder={t('site_management.modal.field_district')}
          subFix={
            loadingPostalCode ? (
              <div className={styles.loadingSpinner}>
                <LoadingSpiner width={20} height={20} color="#4FA99B" />
              </div>
            ) : undefined
          }
          disabled={!!districtData}
          name="district"
          required
          maxlength={100}
          dataSuggestions={districtSuggestions.map((item) => {
            return { name: item };
          })}
          onSelectSuggestItem={(value) => {
            handleChangeAddress({
              formProps,
              values: { district: value },
            });
          }}
          handleChange={(value) => {
            handleChangeAddress({
              formProps,
              values: { district: value },
            });
          }}
          className={styles.inputField}
        />

        <div className={styles.address}>
          <InputDataListField
            label={t('site_management.modal.field_address')}
            placeholder={t('site_management.modal.field_address')}
            name="address"
            required
            maxlength={addressMaxLength ?? 200}
            subFix={
              loadingAddress ? (
                <div className={styles.loadingSpinner}>
                  <LoadingSpiner width={20} height={20} color="#4FA99B" />
                </div>
              ) : undefined
            }
            className={styles.inputField}
            handleChange={(value) =>
              handleChangeAddress({
                formProps,
                values: { address: value },
              })
            }
          />
        </div>
        <InputDataListField
          label={t('site_management.modal.field_latitude')}
          placeholder={t('site_management.modal.field_latitude')}
          name="latitude"
          required
          validRegex={REGEX_OBJECT.onlyThreeIntegersAndNotLimitDecimals}
          disabled={!!latitudeData || loadingAddress}
          subFix={
            loadingAddress ? (
              <div className={styles.loadingSpinner}>
                <LoadingSpiner width={20} height={20} color="#4FA99B" />
              </div>
            ) : undefined
          }
          className={styles.inputField}
        />
        <InputDataListField
          label={t('site_management.modal.field_longitude')}
          placeholder={t('site_management.modal.field_longitude')}
          name="longitude"
          required
          validRegex={REGEX_OBJECT.onlyThreeIntegersAndNotLimitDecimals}
          disabled={!!longitudeData || loadingAddress}
          subFix={
            loadingAddress ? (
              <div className={styles.loadingSpinner}>
                <LoadingSpiner width={20} height={20} color="#4FA99B" />
              </div>
            ) : undefined
          }
          className={styles.inputField}
        />
      </div>
    </div>
  );
}

export default PostalCodeForm;
