// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { IconDelete } from 'components/atoms/icons/IconDelete';
import { ImagePath } from 'util/ConstantValues';
import { IconEdit } from 'components/atoms/icons/IconEdit';
import { BrandItem } from 'util/Types';
import styles from './index.module.scss';
import { useHistory } from 'react-router-dom';
import { Path } from 'routes';
import Badge from 'components/atoms/Badge';
import { DEFAULT_EMPTY_VALUE } from 'util/commons';
import { ChangeEvent } from 'react';

type Props = {
  brandItem: BrandItem;
  onHandleEditBrand: (brandItem: BrandItem) => void;
  onHandleDeleteBrand: (brandItem: BrandItem) => void;
};

const BrandRowItem: React.FC<Props> = ({
  brandItem,
  onHandleEditBrand,
  onHandleDeleteBrand,
}) => {
  const history = useHistory();

  return (
    <tr
      className={styles.row}
      key={brandItem.id}
      onClick={() =>
        history.push(`${Path.adminBrandOwnerManagement}/${brandItem.id}`)
      }
    >
      <td className={styles.operator}>
        <div className={styles.contentName} title={brandItem.id}>
          <Badge
            content={brandItem.totalUnassignedItems}
            show={Boolean(brandItem.totalUnassignedItems)}
            color="red"
          >
            <p>{brandItem.id || DEFAULT_EMPTY_VALUE}</p>
          </Badge>
        </div>
      </td>
      <td className={styles.image}>
        <div className={styles.contentImage}>
          <img
            loading="lazy"
            src={
              brandItem.imageUrl
                ? `${process.env.REACT_APP_CLOUDFRONT}${brandItem.imageUrl}`
                : ImagePath.defaultImage
            }
          />
        </div>
      </td>
      <td className={styles.name}>
        <div className={styles.contentName} title={brandItem.name}>
          <p>{brandItem.name || DEFAULT_EMPTY_VALUE}</p>
        </div>
      </td>
      <td className={styles.address}>
        <div className={styles.contentName} title={brandItem.address}>
          <p>{brandItem.address || DEFAULT_EMPTY_VALUE}</p>
        </div>
      </td>
      <td className={styles.action}>
        <div className={styles.contentAction}>
          <div className={styles.actionEdit}>
            <IconEdit
              onClick={(e: ChangeEvent) => {
                e.stopPropagation();
                onHandleEditBrand(brandItem);
              }}
            />
          </div>
          <div className={styles.actionDelete}>
            <IconDelete
              onClick={(e: ChangeEvent) => {
                e.stopPropagation();
                onHandleDeleteBrand(brandItem);
              }}
            />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default BrandRowItem;
