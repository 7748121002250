// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { IconArrowLeft } from '../icons/IconArrowLeft';
import { IconArrowRight } from '../icons/IconArrowRight';
import './index.scss';
import { mergeClasses } from 'util/commons';

const CustomPagination: React.FC<ReactPaginateProps> = ({
  className,
  ...props
}) => {
  return (
    <ReactPaginate
      className={mergeClasses('customPagination', className)}
      {...props}
    />
  );
};

CustomPagination.defaultProps = {
  nextLabel: <IconArrowRight />,
  previousLabel: <IconArrowLeft />,
  breakLabel: '...',
  renderOnZeroPageCount: null,
  pageRangeDisplayed: 3,
  marginPagesDisplayed: 1,
};

export default CustomPagination;
