// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
const IconCurrencyUnitUSD = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6667 10.6667C14.4574 10.6667 12.6667 11.86 12.6667 13.3333C12.6667 14.8067 14.4574 16 16.6667 16C18.876 16 20.6667 17.1933 20.6667 18.6667C20.6667 20.14 18.876 21.3333 16.6667 21.3333M16.6667 10.6667V21.3333M16.6667 10.6667C18.1467 10.6667 19.44 11.2027 20.132 12M16.6667 10.6667V9.33333M16.6667 21.3333V22.6667M16.6667 21.3333C15.1867 21.3333 13.8934 20.7973 13.2014 20M28.6667 16C28.6667 17.5759 28.3563 19.1363 27.7532 20.5922C27.1502 22.0481 26.2663 23.371 25.152 24.4853C24.0377 25.5996 22.7148 26.4835 21.2589 27.0866C19.803 27.6896 18.2425 28 16.6667 28C15.0908 28 13.5304 27.6896 12.0745 27.0866C10.6186 26.4835 9.29571 25.5996 8.18141 24.4853C7.0671 23.371 6.18319 22.0481 5.58013 20.5922C4.97708 19.1363 4.66669 17.5759 4.66669 16C4.66669 12.8174 5.93097 9.76516 8.18141 7.51472C10.4318 5.26428 13.4841 4 16.6667 4C19.8493 4 22.9015 5.26428 25.152 7.51472C27.4024 9.76516 28.6667 12.8174 28.6667 16Z"
        stroke="#878787"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconCurrencyUnitUSD;
