// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import { Form } from 'react-final-form';
import { InputDataListField } from '../InputDataListField';
import { ModalBrandState } from 'pages/AdminBrandOwnerManagement';
import { ModalType } from 'util/Enums';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { typeImages } from 'util/ConstantValues';
import UploadFiles from '../UploadFiles';
import {
  validateId,
  validateRequiredField,
  validateStringField,
} from 'util/validator';
import { InputTagField } from '../InputTagField';

export type BrandInput = {
  nameBrand: string;
  address: string;
  photo: string | undefined;
  id: string;
  brandItemNames: string[];
};

interface Props {
  createUpdateBrandModal: ModalBrandState;
  onCreateBrand: (createFloorForm: BrandInput) => void;
  onUpdateBrand: (updateFloorForm: BrandInput) => void;
}

const CreateBrandItemInputForm: React.FC<Props> = ({
  createUpdateBrandModal,
  onCreateBrand,
  onUpdateBrand,
}) => {
  const { t } = useTranslation();
  const { brandEditing } = createUpdateBrandModal;

  return (
    <div className={styles.createBody}>
      <Form<BrandInput>
        onSubmit={(values: BrandInput) => {
          if (createUpdateBrandModal.type === ModalType.add) {
            onCreateBrand(values);
          } else {
            onUpdateBrand(values);
          }
        }}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        keepDirtyOnReinitialize
        initialValuesEqual={() => true}
        initialValues={
          brandEditing
            ? {
                nameBrand: brandEditing?.name?.trim(),
                address: brandEditing?.address?.trim(),
                photo: brandEditing?.imageUrl,
                id: brandEditing?.id?.trim(),
                brandItemNames:
                  brandEditing?.brandItems?.map(({ name }) => name) ?? [],
              }
            : {}
        }
        validate={(values) => {
          return {
            id: validateId(
              t('modal_create_brand.label_operator_id'),
              20,
              values.id
            ),
            nameBrand: validateStringField(
              t('modal_create_brand.label_brand_name'),
              100,
              values.nameBrand
            ),
            address: validateStringField(
              t('modal_create_brand.label_address'),
              200,
              values.address
            ),
            brandItemNames: validateRequiredField(
              values.brandItemNames,
              t('modal_create_brand.label_brand_item') || ''
            ),
          };
        }}
      >
        {(props) => {
          return (
            <form onSubmit={props.handleSubmit} className={styles.formInput}>
              <div className={styles.inputFrame}>
                <InputDataListField
                  label={t('modal_create_brand.label_operator_id') || ''}
                  placeholder={t('modal_create_brand.label_operator_id') || ''}
                  name="id"
                  required
                  maxlength={20}
                  disabled={brandEditing ? true : false}
                />
                <InputDataListField
                  label={t('modal_create_brand.label_brand_name') || ''}
                  placeholder={t('modal_create_brand.label_brand_name') || ''}
                  name="nameBrand"
                  required
                  maxlength={100}
                />
                <InputTagField
                  label={t('modal_create_brand.label_brand_item') || ''}
                  name="brandItemNames"
                  tagValues={props.values.brandItemNames ?? []}
                  onChangeData={(tags) => {
                    props.form.mutators.setValue('brandItemNames', tags);
                  }}
                  maxlength={100}
                  required
                  uniqueTags
                  placeholder={
                    t('modal_create_brand.placeholder_brand_item') || ''
                  }
                />
                <InputDataListField
                  label={t('modal_create_brand.label_address') || ''}
                  placeholder={t('modal_create_brand.label_address') || ''}
                  name="address"
                  required
                  maxlength={200}
                />
                <UploadFiles
                  label={t('modal_create_brand.label_photo_picker') || ''}
                  isImage
                  fileTypes={typeImages}
                  defaultFile={
                    createUpdateBrandModal.type === ModalType.update
                      ? brandEditing?.imageUrl
                      : ''
                  }
                  onChangePhoto={(e) => {
                    props.form.mutators.setValue('photo', e);
                  }}
                />
              </div>

              <div className={styles.line} />

              <div className={styles.submit}>
                <button
                  className={`${styles.submitButton} ${
                    props.invalid ? styles.disableButton : ''
                  }`}
                  disabled={props.invalid}
                  type="submit"
                >
                  {createUpdateBrandModal.type === ModalType.add
                    ? t('modal_create_brand.button_create_brand')
                    : t('modal_create_brand.button_update_brand')}
                </button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default CreateBrandItemInputForm;
