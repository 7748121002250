// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './reset.scss';
import './index.scss';
import './App.scss';
import Routes from './routes';
import { LoadingModal } from 'components/molecules/LoadingModal';
import { selectLoading } from 'redux/slices/loadingSlice';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OfflineModal from 'components/molecules/OfflineModal';
import { useInitOfflineSiteData } from 'util/hooks/useInitOfflineSiteData';
import { useSyncWasteRegistrations } from 'util/hooks/useSyncWasteRegistrations';
import UnScopedActionTrigger from 'components/atoms/UnScopedActionTrigger';
import { useUpdateAppLanguage } from 'util/hooks/useUpdateAppLanguage';
import { useCheckOfflineUsage } from 'util/hooks/useCheckOfflineUsage';
import { OperatorType, RoleType } from 'util/Enums';

declare global {
  interface Window {
    postEmissions: (data: string, lang: string) => void;
    postCompressions: (data: string, lang: string) => void;
    setICCardsData: (data: string) => void;
    copyToClipboard: (data: string) => void;
    verifyICCardSuccess: (status: boolean) => void;
    tenantAuthICCardInitial: () => void;
    sendScannedText: (text: string) => void;
    regisEmissionInitial: () => void;
    regisCompressionInitial: () => void;
    regisCompressionBooking: (
      data: string,
      quantity: number,
      lang: string
    ) => void;
    requestPrinterConnection: () => void;
    connectWithScale: (data: boolean) => void;
    uploadFileTeraokaCsv: (token?: string) => void;
    changeAppLanguage: (lng: string) => void;
    useWebsiteOffline: (val?: boolean) => void;
    nativeUpdateUser: (
      data: {
        operatorType: OperatorType;
        role: RoleType;
      } | null
    ) => void;
  }
}

function App() {
  const loading = useSelector(selectLoading);

  useInitOfflineSiteData();
  useSyncWasteRegistrations();
  useUpdateAppLanguage();

  const { showOfflineModal } = useCheckOfflineUsage();

  return (
    <>
      <div className="App">
        <Routes />
      </div>
      <LoadingModal zIndex={100000} hasCash={true} isOpen={loading.isLoading} />
      <ToastContainer style={{ zIndex: 100001 }} />
      <UnScopedActionTrigger />
      {showOfflineModal && <OfflineModal />}
    </>
  );
}

export default App;
