// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import { PrevButton } from 'components/atoms/PrevButton';

import './ScaleTemplate.scss';
import { useTranslation } from 'react-i18next';

export type PrevProps = {
  label?: string;
  href?: string;
  onClick?: () => void;
};

interface Props {
  children?: React.ReactNode;
  download?: VoidFunction;
  h1?: string;
  prev?: PrevProps;
}

export function ScaleTemplate({ children, h1, prev }: Props) {
  const { t } = useTranslation();
  return (
    <div id="scale-contents">
      <div className="main">
        <header>
          <div className="prev">
            {prev && (
              <PrevButton to={prev.href} onClick={prev.onClick}>
                {prev.label ?? t('common.return')}
              </PrevButton>
            )}
          </div>
          {h1 !== undefined ? (
            <h1>{h1}</h1>
          ) : (
            <div className="logo h1">POOL</div>
          )}
        </header>
        <div className="contents">{children}</div>
      </div>
    </div>
  );
}
