// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  onClick?: () => void;
}
export const IconAdd: React.FC<Props> = ({ onClick }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect width="18" height="18" rx="9" fill="#EDEDED" />
      <path
        d="M8.28571 14V9.71429H4V8.28571H8.28571V4H9.71429V8.28571H14V9.71429H9.71429V14H8.28571Z"
        fill="#333333"
      />
    </svg>
  );
};
