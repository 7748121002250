// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './ScaleSelect.scss';
import { useEffect, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import { ScaleTemplate } from 'components/templates/ScaleTemplate';
import { ScaleSelectTypeCard } from 'components/molecules/ScaleSelectTypeCard';
import { useHistory } from 'react-router-dom';
import { ScaleOperator } from 'util/Types';
import { Path } from 'routes';
import { useTranslation } from 'react-i18next';

const ScaleSelect = () => {
  const api = createAxios();
  const history = useHistory();
  const [operatorInfo, setOperatorInfo] = useState<ScaleOperator>();
  const { t } = useTranslation();

  const callApi = async (id: string) => {
    try {
      api
        .get('/scales/operators/' + id)
        .then((res) => {
          setOperatorInfo(res.data);
          // 選択肢が一つの場合は選択画面をスキップする
          if (res.data.waste.length == 1) {
            onClick(res.data.waste[0].id);
          }
        })
        .catch(() => {
          alert(t('scale_select_type.alert.error_get_operator'));
          history.push(Path.scaleSelectOperator);
          return;
        });
    } catch (e) {
      console.log(e);
    }
  };

  const setScaleWaste = (waste: { id: string; name: string }) => {
    localStorage.setItem('wasteId', waste.id);
    localStorage.setItem('wasteName', waste.name);
  };

  useEffect(() => {
    // ストレージから削除
    localStorage.removeItem('wasteId');
    localStorage.removeItem('wasteName');

    const checkOperatorId = localStorage.getItem('operatorId');
    const siteId = localStorage.getItem('siteId');

    if (!checkOperatorId || !siteId) {
      history.push(Path.scaleSelectOperator);
      return;
    }
    callApi(siteId);
  }, []);

  const onClick = (id: string) => {
    const waste = operatorInfo?.waste.filter((w) => w.id == id)[0];
    if (waste) {
      setScaleWaste(waste);
      history.push(Path.scaleEmission);
    } else {
      alert(t('scale_select_type.alert.error_select'));
    }
  };

  return (
    <ScaleTemplate
      h1={
        localStorage.getItem('operatorName') +
        ` ${t('scale_select_type.heading')}`
      }
      prev={{ href: Path.scaleSelectOperator }}
    >
      <div className="description">{t('scale_select_type.description')}</div>
      <div
        className={
          'scale-select' +
          (operatorInfo && operatorInfo.waste.length > 3 ? ' column-2' : '')
        }
      >
        {operatorInfo &&
          operatorInfo.waste.map((w) => {
            return (
              <ScaleSelectTypeCard
                key={w.id}
                id={w.id}
                name={w.name}
                onClick={onClick}
              />
            );
          })}
      </div>
    </ScaleTemplate>
  );
};

export default ScaleSelect;
