// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  onClick?: () => void;
}
export const IconArrowDown: React.FC<Props> = ({ onClick }) => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M1 1L4.85858 4.85858C4.93668 4.93668 5.06332 4.93668 5.14142 4.85858L9 1"
        stroke="#24252A"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
