// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './BottomBtn.scss';
import { PrimaryBtnField } from 'components/molecules/PrimaryBtnField';
import { BottomField } from 'components/molecules/BottomField';

interface Props {
  className?: string;
  text: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  onClick?: () => void;
  disabled?: boolean;
  fixedBottom?: boolean;
}

export function BottomBtn({ text, type, className, onClick, disabled }: Props) {
  return (
    <BottomField>
      <div className="bottom-btn-field">
        <PrimaryBtnField
          className={className}
          type={type}
          text={text}
          disabled={disabled}
          isBottom={true}
          onClick={onClick}
        ></PrimaryBtnField>
      </div>
    </BottomField>
  );
}
