// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
const IconCalendarPrev = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5001 15.8334L6.66675 10L12.5001 4.16669"
        stroke="#E7E7E7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconCalendarPrev;
