// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useEffect, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import { BottomField } from 'components/molecules/BottomField';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { UnCollectEmissions } from 'components/organisms/UnCollectEmissions';
import { EmissionEditModal } from 'components/organisms/EmissionEditModal';
import { UnCollectEmissionObj } from 'util/Types';
import { addition } from 'util/Calc';
import styles from './UnCollects.module.scss';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CustomPagination from 'components/atoms/CustomPagination';
import { getEmissions } from 'apis/emissions/emissionApi';
import { GetListParams } from 'types/api';
import { DEFAULT_PAGE } from 'util/ConstantValues';
import { normalizeNumber } from 'util/commons';

const RECORDS_OF_PAGE = 20;

const UnCollects = () => {
  const api = createAxios();
  const [emissions, setEmissions] = useState<UnCollectEmissionObj>({
    page: 0,
    total: 0,
    data: [],
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [totalWeight, setTotalWeight] = useState<number>(0);
  const [descriptionOptions, setDescriptionOptions] = useState<string[]>([]);
  const [selectedDescriptions, setSelectedDescriptions] = useState<string[]>(
    []
  );
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { t } = useTranslation();

  const filteredEmissions =
    selectedDescriptions.length > 0
      ? emissions.data.filter((emission) =>
          selectedDescriptions.includes(emission.description)
        )
      : emissions.data;

  const onChange = (ids: string[]) => {
    setCheckedIds(ids);
  };

  const onSubmit = async () => {
    if (checkedIds.length > 0) {
      api
        .post('/collects/request', { emissionIds: checkedIds })
        .then(function () {
          toast.success(t('messages.M_086'));
          callApi({
            page: currentPage + 1,
          });
          setCheckedIds([]);
        })
        .catch(function () {
          alert(t('un_collect.submit_collect_request_fail'));
        });
    } else {
      alert(t('un_collect.submit_collect_request_not_passed_validate'));
    }
  };

  const constructUniqueDescriptionList = (emissions) => {
    const uniqueDescriptions = new Set<string>(
      emissions.map((emission) => emission.description)
    );
    setDescriptionOptions(Array.from<string>(uniqueDescriptions));
  };

  const callApi = (params?: GetListParams) => {
    getEmissions({
      params: {
        ...params,
        take: RECORDS_OF_PAGE,
        page: params?.page || DEFAULT_PAGE,
      },
      successCallback: (res) => {
        const emissions = res.data ?? [];
        constructUniqueDescriptionList(emissions);
        setEmissions(res);
        setPageCount(Math.ceil(res.total / RECORDS_OF_PAGE));
      },
    });
  };

  useEffect(() => {
    callApi({
      page: currentPage + 1,
    });
  }, [itemOffset]);

  useEffect(() => {
    const total = filteredEmissions
      .filter((emission) => checkedIds.includes(emission.id))
      .reduce(
        (prev, emission) => addition(prev, parseFloat(emission.weight)),
        0
      );
    setTotalWeight(total);
  }, [filteredEmissions, checkedIds, setTotalWeight]);

  const onSetSelectedDescriptions = (selectedDescription: string[]) => {
    setSelectedDescriptions(selectedDescription);
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);

    const newOffset = Math.ceil(event.selected * RECORDS_OF_PAGE);
    setItemOffset(newOffset);
  };

  return (
    <PrimaryTemplate
      h1={t('un_collect.title')}
      className={styles.unCollectPage}
    >
      <div className={styles.unCollectHead}>
        <div
          className={styles.countList}
          dangerouslySetInnerHTML={{
            __html:
              t('common.page', {
                page: filteredEmissions.length || 0,
              }) ?? '',
          }}
        />

        <div className={styles.totalImport}>
          <div className={styles.total}>
            {t('un_collect.total_recovery')}
            <span>&nbsp;{normalizeNumber({ value: totalWeight })}kg</span>
          </div>
        </div>
      </div>

      <UnCollectEmissions
        emissions={filteredEmissions}
        descriptionOptions={descriptionOptions}
        onChange={onChange}
        onSetSelectedDescriptions={onSetSelectedDescriptions}
        selectedDescriptions={selectedDescriptions}
        itemOffset={itemOffset}
      />
      <BottomField>
        <div className={styles.bottom}>
          <CustomPagination
            pageCount={pageCount}
            onPageChange={handlePageClick}
          />

          <div className={styles.bottomBtn}>
            <button
              className={styles.btnEditing}
              type="button"
              disabled={filteredEmissions.length === 0}
              onClick={() => {
                setIsEditModalOpen(true);
              }}
            >
              {t('un_collect.edit_data_collect')}
            </button>

            <button
              className={styles.btnRequest}
              type="button"
              disabled={checkedIds.length === 0}
              onClick={onSubmit}
            >
              {t('un_collect.submit_collect_request')}
            </button>
          </div>
        </div>
      </BottomField>

      {isEditModalOpen && (
        <EmissionEditModal
          onClose={() => setIsEditModalOpen(false)}
          afterSubmit={() => {
            callApi({
              page: currentPage + 1,
            });
          }}
          emissionData={filteredEmissions}
        />
      )}
    </PrimaryTemplate>
  );
};

export default UnCollects;
