// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { normalizeNumber } from 'util/commons';
import styles from './DashboardLabel.module.scss';

type DashboardLabelProps = {
  title?: string;
  value?: string | number;
};

const DashboardLabel = ({ title, value }: DashboardLabelProps) => {
  return (
    <div className={styles.container}>
      {title && <div className={styles.text}>{title}</div>}
      {value && (
        <div className={styles.value}>{normalizeNumber({ value })}</div>
      )}
    </div>
  );
};

export default DashboardLabel;
