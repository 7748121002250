import React from 'react';
import useOnScreen from 'util/hooks/useOnScreen';

interface Props {
  onNext: () => void;
}
const TableLoading: React.FC<Props> = ({ onNext }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  React.useEffect(() => {
    if (isVisible) {
      onNext();
    }
  }, [isVisible]);
  return (
    <div>
      <div
        ref={ref}
        style={{
          height: '1px',
          width: '100%',
          backgroundColor: 'transparent',
        }}
      />
    </div>
  );
};
export default TableLoading;
