// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import styles from './index.module.scss';
import { ResourceManagementItem } from 'util/adminResourceManagementTypes';
import EditCompressionResourceForm from 'components/molecules/EditCompressionResourceForm';
import { createAxios } from 'ts/createAxios';
import { handleError } from 'util/errorHandler';

interface Props {
  editData: ResourceManagementItem;
  onClose: () => void;
  onUpdateSuccess: () => void;
}

export interface SelectOption {
  label: string;
  value: string | number;
}

const EditCompressionsResourceDataModal: React.FC<Props> = ({
  editData,
  onClose,
  onUpdateSuccess,
}) => {
  const api = createAxios();
  const commonsApi = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const [inputForm, setInputForm] = useState<ResourceManagementItem>();
  const [grades, setGrades] = useState<SelectOption[]>([]);

  useEffect(() => {
    editData &&
      grades.length > 0 &&
      setInputForm({
        ...editData,
        siteName: editData.site.name,
        operatorName: editData.operator.name,
        gradeId: editData.grade?.id,
      });
  }, [editData, grades]);

  useEffect(() => {
    commonsApi
      .get<{ id: number; name: string; gradeId: number }[]>(
        `/commons/grades?siteId=${editData.site.id}&type=Compressions`
      )
      .then((res) => {
        setGrades(
          res.data.map((i) => {
            return { label: i.name, value: i.id };
          })
        );
      });
  }, []);

  const handleEdit = (form: ResourceManagementItem) => {
    api
      .put(`/resources/compressions/${form.id}`, {
        weight: form.weight,
        gradeId: Number(form.gradeId),
      })
      .then(() => {
        onUpdateSuccess();
      })
      .catch((e) => {
        handleError(e);
      });
  };

  return (
    <div className={styles.editResourceDataModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>資源を編集する</p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <EditCompressionResourceForm
            initForm={inputForm}
            onSubmit={handleEdit}
            grades={grades}
          />
        </div>
      </Modal>
    </div>
  );
};

export default EditCompressionsResourceDataModal;
