// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DATE_FORMAT } from 'util/ConstantValues';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('ja');
dayjs.tz.setDefault('Asia/Tokyo');

export const getFormatDate = (date: string, format: string) => {
  return dayjs(date.toString().replace('Z', '')).format(format);
};

export const getFormatDateTime = (date: string) => {
  const FORMAT = 'MM/DD HH:mm';
  return getFormatDate(date, FORMAT);
};

export const getSlaYMDhm = (date: string) => {
  if (!date) {
    return '-';
  }
  const FORMAT = 'YYYY/MM/DD HH:mm';
  return getFormatDate(date, FORMAT);
};

export const getSlaFormatYearMonthDate = (date: string) => {
  const FORMAT = 'YYYY/MM/DD';
  return getFormatDate(date, FORMAT);
};

export const getFormatDateWithCurrentTimezone = (
  date: string,
  format: string
) => {
  const utcTime = dayjs(date).subtract(9, 'hour').format(DATE_FORMAT.slaYMDHms);
  return dayjs(utcTime).format(format);
};

export const getMonthNameFromMonthNumber = (monthNumber: number) => {
  // month start from 0 - 11
  return new Date(
    dayjs().month(monthNumber).format(DATE_FORMAT.slaYMDHm)
  ).toLocaleString('en-us', { month: 'short' });
};

export const getUtcFormatDateTime = (date: string, format: string) =>
  dayjs(date).utc().format(format);

export const requestFormatDateTime = (param: string) =>
  dayjs(param).tz('Asia/Tokyo').format(DATE_FORMAT.slaYMDHm);
