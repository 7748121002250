// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './TextInputField.scss';
import React from 'react';
import { Field } from 'react-final-form';
import { FieldValidator } from 'final-form';
import { Label } from 'components/atoms/Label';
import { ErrorText } from 'components/atoms/ErrorText';
import { IconUser } from 'components/atoms/icons/IconUser';
import { IconKey } from 'components/atoms/icons/IconKey';
import { IconId } from 'components/atoms/icons/IconId';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  name: string;
  label?: string;
  value?: string;
  required?: boolean;
  hint?: React.ReactNode;
  validate?: FieldValidator<string>;
  type?: string;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  icon: 'user' | 'id' | 'password';
}

export function TextInputFieldLogin({
  label,
  name,
  value,
  type,
  placeholder,
  readOnly,
  disabled,
  className,
  validate,
  required,
  maxLength,
  icon,
}: Props) {
  const { t } = useTranslation();
  return (
    <Field name={name} type={type} validate={validate} value={value}>
      {({ input, meta }) => (
        <div className={className ?? 'text-input-login'}>
          {label && (
            <div className="label-wraper">
              <Label text={label} tag={'div'} required={required} />{' '}
              <span className="error-text">*</span>
            </div>
          )}
          <div className="input-wraper">
            <div className="icon">
              {icon === 'user' ? (
                <IconUser />
              ) : icon === 'password' ? (
                <IconKey />
              ) : (
                <IconId />
              )}
            </div>
            <input
              {...input}
              name={name}
              placeholder={placeholder}
              readOnly={readOnly}
              disabled={disabled}
              className={meta && meta.touched && meta.error ? 'error' : ''}
              maxLength={maxLength}
            />
          </div>

          {meta && meta.touched && meta.error && (
            <ErrorText text={meta.error(t)} />
          )}
        </div>
      )}
    </Field>
  );
}
