// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { AdminTemplate } from 'components/templates/AdminTemplate';
import AdminRecycleChainDesignTable from 'components/organisms/AdminRecycleChainDesignTable';

const AdminRecyleChainDesignListManagement = () => {
  return (
    <AdminTemplate
      h1={'リサイクルチェーンリスト'}
      active={'リサイクルチェーンデザイン'}
      breadcrumb={[
        {
          label: 'リサイクルチェーンリスト',
        },
      ]}
    >
      <AdminRecycleChainDesignTable />
    </AdminTemplate>
  );
};

export default AdminRecyleChainDesignListManagement;
