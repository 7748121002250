// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import {
  deleteHistoryPurchaseDelivery,
  fetchHistoriesPurchaseDelivery,
  updateHistoryPurchaseDelivery,
} from 'apis/site_purchase/purchase';
import TableLoading from 'components/atoms/TableLoading';
import { IconClose } from 'components/atoms/icons/IconClose';
import { IconDelete } from 'components/atoms/icons/IconDelete';
import { IconEditIC } from 'components/atoms/icons/IconEditIC';
import DeleteTareModal from 'components/molecules/DeleteTareModal';
import UpdateHistoryResourceManagement from 'components/molecules/UpdateHistoryResourceManagement';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  getFormatDateWithCurrentTimezone,
  requestFormatDateTime,
} from 'ts/formatDate';
import {
  GetHistoryPurchaseDeliveryParams,
  HistoryPurchaseDelivery,
  UpdateHistoryPurchaseDelivery,
} from 'types/purchase';
import { DATE_FORMAT, DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import { mergeClasses, normalizeNumber } from 'util/commons';
import styles from './index.module.scss';

type PurchaseHistoryResourceManagementProps = {
  active: boolean;
};

const PurchaseHistoryResourceManagement = ({
  active = false,
}: PurchaseHistoryResourceManagementProps) => {
  const { t } = useTranslation();

  const [deleteItem, setDeleteItem] = useState<string>();
  const [historiesPurchaseDelivery, setHistoriesPurchaseDelivery] = useState<
    HistoryPurchaseDelivery[]
  >([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE);
  const [updateHistoryResource, setUpdateHistoryResource] =
    useState<HistoryPurchaseDelivery>();

  const handleGetHistoriesPurchaseDelivery = (
    params?: GetHistoryPurchaseDeliveryParams,
    forceRefresh?: boolean
  ) => {
    fetchHistoriesPurchaseDelivery({
      params: {
        ...params,
        take: PAGE_SIZE,
        page: params?.page || DEFAULT_PAGE,
      },
      successCallback: (data) => {
        setHistoriesPurchaseDelivery(
          forceRefresh
            ? data.data
            : [...historiesPurchaseDelivery, ...(data.data || [])]
        );
        setTotalItems(data?.total || 0);
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (form: any) => {
    const deliveryDate =
      form.date ??
      getFormatDateWithCurrentTimezone(
        form.deliveriedDatetime,
        DATE_FORMAT.full_date
      );
    const deliveryTime =
      form.time ??
      getFormatDateWithCurrentTimezone(
        form.deliveriedDatetime,
        DATE_FORMAT.hhss
      );

    const formData: UpdateHistoryPurchaseDelivery = {
      deliveriedDatetime: requestFormatDateTime(
        deliveryDate + ' ' + deliveryTime
      ),
      deliveryToSiteId: form.deliveryToSiteId,
    };

    updateHistoryPurchaseDelivery(form.id, { form: formData }, () => {
      toast.success(t('messages.M_011'));
      setUpdateHistoryResource(undefined);
      handleGetHistoriesPurchaseDelivery(
        { page: currentPage || DEFAULT_PAGE },
        true
      );
    });
  };

  const handleDeleteHistory = () => {
    if (deleteItem) {
      deleteHistoryPurchaseDelivery(deleteItem, () => {
        toast.success(t('messages.M_012'));
        setDeleteItem(undefined);
        handleGetHistoriesPurchaseDelivery(
          { page: currentPage || DEFAULT_PAGE },
          true
        );
      });
    }
  };

  useEffect(() => {
    if (active) {
      handleGetHistoriesPurchaseDelivery();
    } else {
      setHistoriesPurchaseDelivery([]);
      setCurrentPage(DEFAULT_PAGE);
      setTotalItems(0);
    }
  }, [active]);

  return (
    <div className={styles.productRegistration}>
      <div className={styles.table}>
        <table id={styles.tareWeightSubtractionTable}>
          <thead>
            <tr>
              <th className={styles.date}>
                {t('purchase_page.histories.date_time')}
              </th>
              <th className={styles.shipping}>
                {t('purchase_page.histories.ship_form')}
              </th>
              <th className={styles.shipping}>
                {t('purchase_page.histories.destination')}
              </th>
              <th className={styles.shipping}>
                {t('purchase_page.histories.deliveries')}
              </th>
              <th colSpan={2} className={styles.actionField}>
                {t('tare_management.label_action')}
              </th>
            </tr>
          </thead>
          <tbody>
            {historiesPurchaseDelivery?.length > 0 ? (
              historiesPurchaseDelivery.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className={styles.date}>
                      <p>
                        {getFormatDateWithCurrentTimezone(
                          item.deliveriedDatetime,
                          DATE_FORMAT.slaYMDHm
                        )}
                      </p>
                    </td>
                    <td className={styles.shipping}>
                      <p>{item.deliveryFromSiteName}</p>
                    </td>
                    <td className={styles.shipping}>
                      <p>{item.deliveryToSiteName}</p>
                    </td>
                    <td className={styles.shipping}>
                      <p>
                        {item.contentDelivery[0].packingStyle}
                        <span>
                          {` ${normalizeNumber({
                            value: item.contentDelivery[0].weight,
                          })} kg`}
                        </span>
                      </p>
                    </td>
                    <td className={styles.actionField1}>
                      <div className={styles.contentAction}>
                        <div
                          className={mergeClasses(styles.actionEdit, {
                            [styles.disabledBtn]: !!item.received,
                          })}
                          onClick={() => {
                            if (!item.received) {
                              setUpdateHistoryResource(item);
                            }
                          }}
                        >
                          <IconEditIC />
                        </div>
                      </div>
                    </td>
                    <td className={styles.actionField1}>
                      <div className={styles.contentAction}>
                        <div
                          className={mergeClasses(styles.actionDelete, {
                            [styles.disabledBtn]: !!item.received,
                          })}
                          onClick={() => {
                            if (!item.received) {
                              setDeleteItem(item.id);
                            }
                          }}
                        >
                          <IconDelete />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5} className={styles.msgError}>
                  {t('messages.M_008')}
                </td>
              </tr>
            )}
            {historiesPurchaseDelivery?.length > 0 && (
              <TableLoading
                onNext={() => {
                  if (
                    historiesPurchaseDelivery.length >= PAGE_SIZE &&
                    historiesPurchaseDelivery.length < totalItems
                  ) {
                    handleGetHistoriesPurchaseDelivery({
                      page: currentPage + 1,
                    });
                    setCurrentPage(currentPage + 1);
                  }
                }}
              />
            )}
          </tbody>
        </table>
      </div>

      {deleteItem && (
        <DeleteTareModal
          onClose={() => {
            setDeleteItem(undefined);
          }}
          onHandleDelete={handleDeleteHistory}
          content={t('purchase_page.histories.content_delete')}
          closeModalIcon={<IconClose />}
        />
      )}

      {updateHistoryResource && (
        <UpdateHistoryResourceManagement
          onClose={() => setUpdateHistoryResource(undefined)}
          data={updateHistoryResource}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default PurchaseHistoryResourceManagement;
