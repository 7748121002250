// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  onClick?: () => void;
  width?: string | number;
  height?: string | number;
}
export const IconUser: React.FC<Props> = ({
  onClick,
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 4.25C6.75 3.42157 7.42157 2.75 8.25 2.75C9.07843 2.75 9.75 3.42157 9.75 4.25C9.75 5.07843 9.07843 5.75 8.25 5.75C7.42157 5.75 6.75 5.07843 6.75 4.25ZM8.25 1.25C6.59315 1.25 5.25 2.59315 5.25 4.25C5.25 5.90685 6.59315 7.25 8.25 7.25C9.90685 7.25 11.25 5.90685 11.25 4.25C11.25 2.59315 9.90685 1.25 8.25 1.25ZM6 7.25C3.92893 7.25 2.25 8.92893 2.25 11V13C2.25 14.184 3.34408 14.75 4.19008 14.75H11.8099C12.6559 14.75 13.75 14.184 13.75 13V11C13.75 8.92893 12.0711 7.25 10 7.25C9.77117 7.25 9.56204 7.31725 9.38956 7.4192C9.02601 7.63409 8.6263 7.75 8.20826 7.75C7.89566 7.75 7.59472 7.68527 7.31196 7.56404C6.96175 7.41389 6.50817 7.25 6 7.25ZM3.75 11C3.75 9.75736 4.75736 8.75 6 8.75C6.19713 8.75 6.42288 8.81491 6.72089 8.94267C7.183 9.1408 7.68454 9.25 8.20826 9.25C8.88364 9.25 9.51981 9.06855 10.0819 8.75146C11.2866 8.79455 12.25 9.78477 12.25 11V13C12.25 13.0448 12.2364 13.0878 12.1709 13.1392C12.0946 13.1991 11.9644 13.25 11.8099 13.25H4.19008C4.03565 13.25 3.90543 13.1991 3.8291 13.1392C3.76365 13.0878 3.75 13.0448 3.75 13V11Z"
        fill="#878787"
      />
    </svg>
  );
};
