// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './Traces.scss';
import { useEffect, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import { AdminTemplate } from 'components/templates/AdminTemplate';
import { CalendarField } from 'components/molecules/CalendarField';
import { CsvDownloadButton } from 'components/atoms/CsvDownloadButton';
import { getSlaYMDhm } from 'ts/formatDate';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OperatorType } from 'util/Enums';
import { formatNumberWithCommas } from 'util/commons';

type TraceListData = {
  id: string;
  datetime: string;
  userId: string;
  userName: string;
  operatorName: string;
  siteName: string;
  description: string;
  materialType: string;
  grade: string;
  weight: string;
  type: string;
};

const Traces = () => {
  const api = createAxios();
  const [traces, setTraces] = useState<TraceListData[]>([]);
  const history = useHistory();
  const { t } = useTranslation();

  const search = async (date: string | null) => {
    let params = '';
    if (date) {
      params = '?targetDate=' + date;
    }

    api.get('/traces' + params).then((res) => {
      setTraces(res.data);
    });
  };

  useEffect(() => {
    search(null);
  }, []);

  return (
    <AdminTemplate
      h1={t('common.sidebar_trace')}
      active="トレーサビリティ"
      breadcrumb={[{ label: t('common.sidebar_trace') }]}
      className="traces-page"
    >
      <div
        className={'countList'}
        dangerouslySetInnerHTML={{
          __html:
            t('common.page', {
              page: traces.length || 0,
            }) ?? '',
        }}
      />
      <div className="search-area">
        <CalendarField
          maxDate={new Date()}
          onChange={(value) => {
            search(value);
          }}
        />
        <CsvDownloadButton
          fileName={t('common.csv_file_name')}
          headers={[
            t('traces.thead_date'),
            t('traces.thead_thead_person'),
            t('traces.thead_manufacturer'),
            t('traces.thead_intermediate_processing'),
            t('traces.thead_producer'),
            t('traces.thead_material_name'),
            t('traces.thead_kinds'),
            t('traces.thead_quality'),
            t('traces.thead_weight'),
            t('traces.thead_id'),
          ]}
          fetchData={traces.map((item) => {
            return [
              getSlaYMDhm(item.datetime),
              item.userId + '(' + item.userName + ')',
              `${item.type === OperatorType.Recycle ? item.siteName : ''}`,
              `${item.type === OperatorType.Compressions ? item.siteName : ''}`,
              `${item.type === OperatorType.Producer ? item.siteName : ''}`,
              item.description,
              `${item.type === OperatorType.Recycle ? item.materialType : ''}`,
              item.grade,
              formatNumberWithCommas(item.weight) + 'kg',
              item.id,
            ];
          })}
        />
      </div>
      <div className="scroll-wrapper">
        <table id="trace-table">
          <thead>
            <tr>
              <th className="c--date">{t('traces.thead_date')}</th>
              <th className="c--staff">{t('traces.thead_thead_person')}</th>
              <th className="c--operator">{t('traces.thead_manufacturer')}</th>
              <th className="c--operator">
                {t('traces.thead_intermediate_processing')}
              </th>
              <th className="c--description">{t('traces.thead_producer')}</th>
              <th className="c--description">
                {t('traces.thead_material_name')}
              </th>
              <th className="c--type">{t('traces.thead_kinds')}</th>
              <th className="c--grade">{t('traces.thead_quality')}</th>
              <th className="c--weight">{t('traces.thead_weight')}</th>
              <th className="c--id">{t('traces.thead_id')}</th>
            </tr>
          </thead>
          <tbody>
            {traces && traces.length ? (
              traces.map((item, i) => {
                return (
                  <tr
                    key={i}
                    onClick={() => {
                      history.push('/traces/' + item.id);
                    }}
                  >
                    <td className="c--date">
                      <p>{getSlaYMDhm(item.datetime) || '-'}</p>
                    </td>
                    <td className="left c--staff">
                      <p>{`${item.userId}${
                        item.userName ? ` (${item.userName})` : ''
                      }`}</p>
                    </td>
                    <td className="left c--operator">
                      <p>
                        {item.type === OperatorType.Recycle
                          ? item.siteName
                          : '-'}
                      </p>
                    </td>
                    <td className="left c--operator">
                      <p>
                        {item.type === OperatorType.Compressions
                          ? item.siteName
                          : '-'}
                      </p>
                    </td>
                    <td className="left c--description">
                      <p>
                        {item.type === OperatorType.Producer
                          ? item.siteName
                          : '-'}
                      </p>
                    </td>
                    <td className="left c--description">
                      <p>{item.description || '-'}</p>
                    </td>
                    <td className="left c--type">
                      <p>
                        {item.type === OperatorType.Recycle
                          ? item.materialType
                          : '-'}
                      </p>
                    </td>
                    <td className="left c--grade">
                      <p>{item.grade || '-'}</p>
                    </td>
                    <td className="c--weight">
                      <p>
                        {item.weight
                          ? formatNumberWithCommas(item.weight)
                          : '-'}
                        kg
                      </p>
                    </td>
                    <td className="left c--id">
                      <p>{item.id || '-'}</p>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="msg-error">{t('messages.M_008')}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </AdminTemplate>
  );
};

export default Traces;
