import { Form } from 'react-final-form';
import { operatorTypeData } from 'util/ConstantValues';
import { OperatorTypeForm } from 'util/Types';
import { CheckBoxCardV2 } from '../CheckBoxCardV2';
import styles from './AdminOperatorTypeForm.module.scss';

interface Props {
  initOperatorType: OperatorTypeForm;
  onNextStep: (operatorTypeForm: OperatorTypeForm) => void;
}

const AdminOperatorTypeForm: React.FC<Props> = ({
  initOperatorType,
  onNextStep,
}) => {
  return (
    <div className={styles.adminOperatorTypeForm}>
      {initOperatorType && (
        <Form<OperatorTypeForm>
          onSubmit={(values: OperatorTypeForm) => onNextStep(values)}
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          initialValues={initOperatorType || {}}
        >
          {(props) => {
            const operatorTypes = props.values.operatorType;
            const handleChangeOperatorType = (e) => {
              const id = e.target.id;
              props.form.mutators.setValue(
                'operatorType',
                operatorTypes.includes(id)
                  ? operatorTypes.filter((operator) => operator !== id)
                  : operatorTypes.concat(id)
              );
            };

            return (
              <form onSubmit={props.handleSubmit} className={styles.formInput}>
                <div className={styles.inputFrame}>
                  <div className={styles.operatorTypeFrame}>
                    {operatorTypeData.map((operator) => {
                      return (
                        <div
                          className={styles.operatorTypeFrameItem}
                          key={operator.value}
                        >
                          <CheckBoxCardV2
                            id={`${operator.value}`}
                            onChange={handleChangeOperatorType}
                            checked={props.values.operatorType?.includes(
                              operator.value
                            )}
                          >
                            <span className={styles.operatorTypeFrameItemLabel}>
                              {operator.label}
                            </span>
                          </CheckBoxCardV2>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className={styles.submit}>
                  <button
                    className={styles.submitButton}
                    disabled={!operatorTypes.length}
                    type="submit"
                  >
                    ダウンロード範囲選択へ
                  </button>
                </div>
              </form>
            );
          }}
        </Form>
      )}
    </div>
  );
};

export default AdminOperatorTypeForm;
