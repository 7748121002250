// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import styles from './style.module.scss';
import _debounce from 'lodash/debounce';
import Tooltip from '../Tooltip';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'util/commons';

type UnCollectChainTagProps = {
  collectionOperatorName?: string;
  emissionStatus?: string;
};

const UnCollectChainTag: React.FC<UnCollectChainTagProps> = ({
  collectionOperatorName,
  emissionStatus,
}) => {
  const [tooltipContent, setTooltipContent] = useState<string>();
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const content = useMemo(() => {
    if (emissionStatus === 'Closed') {
      return t('un_collect.no_next_stage');
    }

    return `${t('un_collect.collector')}: ${
      collectionOperatorName ?? t('un_collect.no_collector')
    }`;
  }, [collectionOperatorName, emissionStatus, t]);

  useLayoutEffect(() => {
    const handleShowTooltip = () => {
      if (ref.current) {
        if (ref.current.scrollHeight <= ref.current.clientHeight) {
          setTooltipContent(undefined);
        } else {
          setTooltipContent(content);
        }
      }
    };

    handleShowTooltip();

    const handleShowTooltipOnResize = _debounce(handleShowTooltip, 200);

    window.addEventListener('resize', handleShowTooltipOnResize);

    return () => {
      window.removeEventListener('resize', handleShowTooltipOnResize);
    };
  }, [collectionOperatorName, emissionStatus]);

  return (
    <Tooltip content={tooltipContent}>
      <div
        className={mergeClasses(styles.chainTag, {
          [styles.noChain]: emissionStatus === 'Closed',
        })}
        ref={ref}
      >
        {content}
      </div>
    </Tooltip>
  );
};

export default UnCollectChainTag;
