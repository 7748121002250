// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import { InvoiceEditMailPut } from 'components/molecules/InvoiceEditMailModalForm/InvoiceEditMailForm';
import { createAxios } from 'ts/createAxios';
import { InvoiceStatus } from 'util/Types';
import { handleError } from 'util/errorHandler';

export type InvoiceItem = {
  id: number;
  name: string;
  originalInvoice: boolean;
  isSendInvoice: boolean;
  invoiceStatus: InvoiceStatus;
};

export type InvoiceRequestParam = {
  operatorId: string;
  date: string;
};

export type DetailInvoiceTemplateData = {
  id: number;
  siteId: string;
  title: string;
  body: string;
  emails: string[];
};

export type UpdateInvoiceTemplate = {
  siteId: string;
  title: string;
  emails: string[];
  bodyTemplate: string;
};

export const getListInvoice = async ({
  params,
  callback,
}: {
  params?: InvoiceRequestParam;
  callback?: (data: InvoiceItem[]) => void;
}) => {
  try {
    const api = createAxios();
    const { data } = await api.get(
      `/invoices/sites?operatorId=${params?.operatorId}&date=${params?.date}`
    );
    data && callback && callback(data);
  } catch (error) {
    handleError(error);
  }
};

export const getDetailInvoiceTemplate = async ({
  siteId,
  callback,
}: {
  siteId?: string;
  callback?: (data: DetailInvoiceTemplateData) => void;
}) => {
  try {
    const api = createAxios();
    const { data } = await api.get(
      `/invoices/invoice-template?siteId=${siteId}`
    );
    data && callback && callback(data);
  } catch (error) {
    handleError(error);
  }
};

export const updateInvoiceTemplate = async ({
  params,
  callback,
}: {
  params?: InvoiceEditMailPut;
  callback?: (data: { data: string }) => void;
}) => {
  try {
    const api = createAxios();
    const { data } = await api.put(`/invoices/invoice-template`, {
      siteId: params?.siteId || 1,
      title: params?.title || '',
      emails: params?.emails || [],
      bodyTemplate: params?.bodyTemplate || '',
    });
    data && callback && callback(data);
  } catch (error) {
    handleError(error);
  }
};
