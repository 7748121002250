// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { InputDataListField } from '../InputDataListField';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { Label } from 'components/atoms/Label';
import PhotoPickerMultipleFields from '../PhotoPickerMultipleFields';
import { EmissionSiteItem } from 'util/siteManagementTypes';
import { REGEX_OBJECT } from 'util/ConstantValues';
import { ModalType } from 'util/Enums';
import {
  validateSpecialCharactersWithJapanese,
  validateStringField,
} from 'util/validator';
import { mergeClasses } from 'util/commons';

interface Props {
  initForm: EmissionSiteItem;
  siteTypeSuggestion: string[];
  type: ModalType;
  onNextStep: (createUpdateSiteBaseForm: EmissionSiteItem) => void;
  onPrevStep: (createUpdateSiteBaseForm: EmissionSiteItem) => void;
}

const CreateUpdateSiteSizeForm: React.FC<Props> = ({
  initForm,
  siteTypeSuggestion,
  type,
  onNextStep,
  onPrevStep,
}) => {
  const { t } = useTranslation();
  const [imagesDelete, setImagesDelete] = useState<string[]>([]);
  const [imagesAdded, setImageAdded] = useState<
    { name: string; file: string }[]
  >([]);
  const [formHasError, setFormHasError] = useState(false);

  useEffect(() => {
    initForm?.imagesAdded && setImageAdded(initForm.imagesAdded);
    initForm?.imagesDelete && setImagesDelete(initForm.imagesDelete);
  }, [initForm]);

  return (
    <div className={styles.createBody}>
      <Form<EmissionSiteItem>
        onSubmit={(values: EmissionSiteItem) => {
          onNextStep({
            ...values,
            ...(type === ModalType.update ? { imagesAdded } : {}),
            ...(type === ModalType.update ? { imagesDelete } : {}),
          });
        }}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        initialValues={initForm}
        validate={(values) => {
          return {
            siteType: validateStringField(
              t('site_management.modal.field_size_type'),
              100,
              values.siteType
            ),
            grade: validateSpecialCharactersWithJapanese(
              t('site_management.modal.field_grade'),
              100,
              values.grade
            ),
          };
        }}
      >
        {(props) => {
          return (
            <form className={styles.formInput}>
              <div className={styles.inputFrame}>
                <div className={styles.sizeFrame}>
                  <InputDataListField
                    label={
                      t('site_management.modal.field_size_type') || '拠点種類'
                    }
                    placeholder={
                      t('site_management.modal.field_size_type') || '拠点種類'
                    }
                    name="siteType"
                    required
                    maxlength={100}
                    dataSuggestions={siteTypeSuggestion.map((item) => {
                      return { name: item };
                    })}
                    onSelectSuggestItem={(value) => {
                      props.form.mutators.setValue('siteType', value);
                    }}
                  />
                  <div className={styles.floorSizeInput}>
                    <InputDataListField
                      label={
                        t('site_management.modal.field_floor_space') ||
                        '延床面積'
                      }
                      placeholder={
                        t('site_management.modal.field_floor_space') ||
                        '延床面積'
                      }
                      name="floorSpace"
                      maxlength={10}
                      validRegex={REGEX_OBJECT.numberWithDot}
                      subFix={
                        <span className={styles.floorSizeUnit}>
                          m<span className={styles.sup}>2</span>
                        </span>
                      }
                    />
                  </div>
                  <InputDataListField
                    label={t('site_management.modal.field_grade') || 'グレード'}
                    placeholder={
                      t('site_management.modal.field_grade') || 'グレード'
                    }
                    name="grade"
                    required
                    maxlength={100}
                  />
                  <InputDataListField
                    label={
                      t('site_management.modal.field_vehicle_limit') ||
                      'ごみ庫車両制限'
                    }
                    placeholder={
                      t('site_management.modal.field_vehicle_limit') ||
                      'ごみ庫車両制限'
                    }
                    maxlength={100}
                    name="vehicleLimit"
                  />
                  <InputDataListField
                    label={
                      t('site_management.modal.field_network_operator') ||
                      'ネットワークキャリア・Wifi情報'
                    }
                    placeholder={
                      t('site_management.modal.field_network_operator') ||
                      'ネットワークキャリア・Wifi情報'
                    }
                    maxlength={200}
                    name="networkOperator"
                  />
                </div>
                <Label
                  className={styles.headingImages}
                  text={
                    t('site_management.modal.field_site_images') ||
                    'ごみ置き場の様子'
                  }
                  tag={'div'}
                />
                <PhotoPickerMultipleFields
                  defaultFile={initForm.images}
                  onChangePhoto={(images) => {
                    type === ModalType.update &&
                      setImageAdded(
                        images
                          .filter(
                            (image) =>
                              !initForm.images.find(
                                (item) => item.name === image.name
                              )
                          )
                          .map((file) => ({ file: file.file, name: file.name }))
                      );
                    props.form.mutators.setValue('images', images);
                  }}
                  maxLength={3}
                  onError={setFormHasError}
                  onRemove={(item) => {
                    type === ModalType.update &&
                      !item.file &&
                      setImagesDelete(imagesDelete.concat(item.name));
                  }}
                />
              </div>
              <div className={styles.submit}>
                <button
                  type="button"
                  className={`${styles.prevButton}`}
                  onClick={() =>
                    onPrevStep({
                      ...props.values,
                      ...(type === ModalType.update ? { imagesAdded } : {}),
                      ...(type === ModalType.update ? { imagesDelete } : {}),
                    })
                  }
                >
                  {t('site_management.modal.button_prev')}
                </button>
                <button
                  className={mergeClasses(styles.submitButton, {
                    [styles.disableButton]: props.invalid || formHasError,
                  })}
                  disabled={props.invalid || formHasError}
                  type="button"
                  onClick={props.handleSubmit}
                >
                  {t('site_management.modal.button_next')}
                </button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default CreateUpdateSiteSizeForm;
