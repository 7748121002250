// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';

import { AdminNavbar } from 'components/organisms/AdminNavbar';
import { AdminContentHeader } from 'components/organisms/AdminContentHeader';

import './AdminTemplate.scss';

type Breadcrumb = {
  label: string;
  href?: string;
};
interface Props {
  children?: React.ReactNode;
  footerChildren?: React.ReactNode;
  mainHeaderActionChildren?: React.ReactNode;
  h1: string;
  active?: string;
  breadcrumb?: Breadcrumb[];
  className?: string;
}

export function AdminTemplate({
  children,
  footerChildren,
  mainHeaderActionChildren,
  h1,
  active,
  breadcrumb,
  className,
}: Props) {
  return (
    <div id="admin-main" className={className}>
      <AdminNavbar active={active} />

      <div className="admin-contents">
        <div className="admin-contents__header">
          <AdminContentHeader breadcrumb={breadcrumb} />
        </div>

        <div className="admin-contents__main">
          <div className="admin-contents__main__header">
            <h1 className="admin-contents__main__header__title">{h1}</h1>
            <div className="admin-contents__main__header__actions">
              {mainHeaderActionChildren}
            </div>
          </div>

          <div className="admin-contents__main__body">{children}</div>
        </div>

        <div className="admin-contents__footer">{footerChildren}</div>
      </div>
    </div>
  );
}
