// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useEffect, useState } from 'react';
import { generateColorPalette } from 'util/commons';
import { ColorsPaletteKeys, StorageKeys } from 'util/Enums';

type ColorsSetting = Partial<Record<ColorsPaletteKeys, string[]>>;

export const useGenerateColorsPalette = (params: {
  key: ColorsPaletteKeys | string;
  numOfColors?: number;
  hueStep?: number;
  startColor?: string;
  randomSaturationAndBrightness?: boolean;
  cache?: boolean;
  skipStartColor?: boolean;
}) => {
  const {
    key,
    numOfColors,
    hueStep,
    startColor,
    randomSaturationAndBrightness,
    cache = true,
    skipStartColor,
  } = params;

  const [colorsSetting, setColorsSetting] = useState<ColorsSetting>(
    cache
      ? (() => {
          const data = localStorage.getItem(StorageKeys.COLORS_SETTING);

          try {
            return JSON.parse(data || '{}');
          } catch {
            return {};
          }
        })()
      : {}
  );

  useEffect(() => {
    if (!numOfColors) {
      return;
    }

    const missedColors = numOfColors - (colorsSetting[key]?.length || 0);

    if (missedColors > 0) {
      const newData = {
        ...colorsSetting,
        [key]: [
          ...(colorsSetting[key] || []),
          ...generateColorPalette({
            startColor:
              colorsSetting[key]?.[(colorsSetting[key]?.length ?? 1) - 1] ??
              startColor,
            totalColors: missedColors,
            hueStep,
            randomSaturationAndBrightness,
            skipStartColor,
          }),
        ],
      };

      setColorsSetting(newData);

      if (cache) {
        localStorage.setItem(
          StorageKeys.COLORS_SETTING,
          JSON.stringify(newData)
        );
      }
    }
  }, [
    key,
    numOfColors,
    cache,
    hueStep,
    startColor,
    randomSaturationAndBrightness,
    skipStartColor,
  ]);

  return {
    colorsPalette: colorsSetting[key] || [],
  };
};
