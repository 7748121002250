const IconWarning = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.6666 32C56.6666 45.6231 45.6229 56.6667 31.9999 56.6667C18.3769 56.6667 7.33325 45.6231 7.33325 32C7.33325 18.377 18.3769 7.33337 31.9999 7.33337C45.6229 7.33337 56.6666 18.377 56.6666 32ZM58.6666 32C58.6666 46.7276 46.7275 58.6667 31.9999 58.6667C17.2723 58.6667 5.33325 46.7276 5.33325 32C5.33325 17.2724 17.2723 5.33337 31.9999 5.33337C46.7275 5.33337 58.6666 17.2724 58.6666 32ZM29.3333 21.3334C29.3333 19.8606 30.5272 18.6667 31.9999 18.6667C33.4727 18.6667 34.6666 19.8606 34.6666 21.3334V34.6667C34.6666 36.1395 33.4727 37.3334 31.9999 37.3334C30.5272 37.3334 29.3333 36.1395 29.3333 34.6667V21.3334ZM29.3333 42.6667C29.3333 41.194 30.5272 40 31.9999 40C33.4727 40 34.6666 41.194 34.6666 42.6667C34.6666 44.1395 33.4727 45.3334 31.9999 45.3334C30.5272 45.3334 29.3333 44.1395 29.3333 42.6667Z"
        fill="#F75354"
      />
    </svg>
  );
};

export default IconWarning;
