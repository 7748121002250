// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { IconChevronRight } from 'components/atoms/icons/IconChevronRight';
import LanguageSelect from 'components/atoms/LanguageSelect';
import UserDropdown from 'components/atoms/UserDropdown';
import { Link } from 'react-router-dom';
import { cutText } from 'util/commons';
import { Breadcrumb } from 'util/Types';
import './PurchaseContentHeader.scss';

type Props = {
  title?: string | null;
  breadcrumb?: Breadcrumb[];
};

const PurchaseContentHeader = ({ title, breadcrumb }: Props) => {
  return (
    <div className="purchase-header">
      {breadcrumb ? (
        <div className="breadcrumb">
          {breadcrumb?.map((b, index) => {
            return b.href ? (
              <span key={index}>
                <Link to={b.href} title={b.label}>
                  {cutText(b.label)}
                </Link>

                <IconChevronRight />
              </span>
            ) : (
              <span key={index} title={b.label}>
                {cutText(b.label)}
              </span>
            );
          })}
        </div>
      ) : (
        <div className="title">
          <span>{title}</span>
        </div>
      )}

      <div className="actions">
        <LanguageSelect />
        <UserDropdown />
      </div>
    </div>
  );
};

export default PurchaseContentHeader;
