// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  onClick?: () => void;
}

export const IconOutlineCloudUpload = ({ onClick }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M7.55765 16.787C6.68257 16.788 5.83789 16.4559 5.18545 15.8543C4.53301 15.2528 4.11868 14.424 4.02184 13.5269C3.92499 12.6297 4.15245 11.7272 4.66063 10.9923C5.16881 10.2575 5.92198 9.74183 6.77584 9.54424C6.52879 8.35569 6.74957 7.11457 7.3896 6.09393C8.02964 5.07329 9.0365 4.35672 10.1887 4.10188C11.3409 3.84703 12.5441 4.07477 13.5335 4.73501C14.5229 5.39524 15.2175 6.43388 15.4646 7.62244H15.5534C16.655 7.6213 17.7177 8.04243 18.5353 8.80409C19.3528 9.56575 19.8668 10.6136 19.9775 11.7442C20.0882 12.8748 19.7877 14.0075 19.1344 14.9224C18.481 15.8374 17.5214 16.4692 16.4418 16.6953M14.665 14.0376L11.9997 11.2883M11.9997 11.2883L9.33449 14.0376M11.9997 11.2883V19.8312"
        stroke="#24252A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
