// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './AdminSiteDetail.module.scss';
import { AdminTemplate } from 'components/templates/AdminTemplate';
import { useHistory, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Tab } from 'components/molecules/Tab';
import CustomizeListCategory from 'components/organisms/CustomizeListCategory';
import { Path } from 'routes';
import { CustomizeItemBreadcrumbs } from 'util/Types';
import AdminTenantManagement from 'components/organisms/AdminTenantManagement';
import { useTranslation } from 'react-i18next';

const AdminCustomizeItems = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { operatorId } = useParams<{
    siteId: string;
    operatorId: string;
  }>();
  const [breadcrumb, setBreadcrumb] = useState<CustomizeItemBreadcrumbs>({
    operatorName: '-',
    siteName: '-',
  });

  return (
    <AdminTemplate
      h1={t('site_detail.heading')}
      active="排出"
      breadcrumb={[
        {
          label: t('common.sidebar_operator'),
          href: `${Path.adminOperatorListManagement}${window.location.search}`,
        },
        {
          label: breadcrumb.operatorName || '-',
          href: `${Path.adminOperatorListManagement}/${operatorId}${window.location.search}`,
        },
        {
          label: breadcrumb.siteName || '-',
        },
      ]}
    >
      <Tab
        className={styles.tabAdminCustomize}
        tabItems={[t('site_detail.tab_left'), t('site_detail.tab_right')]}
        defaultTabIndex={history.location.hash == '#1' ? 1 : 0}
        renderContent={({ index }) => (
          <div>
            <h4 className={styles.titleCustomize}>
              {index === 1
                ? t('site_detail.tab_right_heading')
                : t('site_detail.tab_left_heading')}
            </h4>
            {index === 0 && (
              <AdminTenantManagement
                onSetBreadcrumb={(operatorName, siteName) => {
                  setBreadcrumb({ operatorName, siteName });
                }}
              />
            )}
            {index === 1 && (
              <CustomizeListCategory
                onSetBreadcrumb={(operatorName, siteName) => {
                  setBreadcrumb({ operatorName, siteName });
                }}
              />
            )}
          </div>
        )}
      ></Tab>
    </AdminTemplate>
  );
};

export default AdminCustomizeItems;
