import ShippingRegistrationDetailContent from 'components/organisms/ShippingRegistrationDetailContent';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Path } from 'routes';
import { TCompressionBooking } from 'types/compression';

const ShippingRegistrationDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onBack = () => {
    history.push(Path.shippingRegistration);
  };

  const compression = useMemo(() => {
    return history.location.state as TCompressionBooking;
  }, [history.location.state]);

  return (
    <PrimaryTemplate h1={t('compressions.heading')} prevFunction={onBack}>
      {compression && (
        <ShippingRegistrationDetailContent compression={compression} />
      )}
    </PrimaryTemplate>
  );
};

export default ShippingRegistrationDetail;
