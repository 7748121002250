// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import { ModalType, OperatorType } from 'util/Enums';
import styles from './index.module.scss';
import CreateUpdateAccountForm from 'components/molecules/CreateAccountInputForm';
import {
  AccountForm,
  AccountOperator,
  baseAccountForm,
  ModalAccountState,
} from 'util/accountManagementTypes';
import { createAxios } from 'ts/createAxios';
import { handleError } from 'util/errorHandler';
import { ResSites } from 'util/siteManagementTypes';
import { Truck } from 'util/Types';
import { fetchTruckOption, getTenants } from 'apis/operator_site/siteApi';
import { Tenant } from 'components/molecules/SelectTenantCard';

interface Props {
  createUpdateAccountModal: ModalAccountState;
  onClose?: () => void;
  onCreateAccount: (createAccountForm: AccountForm) => void;
  onUpdateAccount: (updateCompressionSiteForm: AccountForm) => void;
}

const CreateUpdateAccountModal: React.FC<Props> = ({
  createUpdateAccountModal,
  onClose,
  onCreateAccount,
  onUpdateAccount,
}) => {
  const [inputForm, setInputForm] = useState<AccountForm>(baseAccountForm);
  const [operators, setOperators] = useState<AccountOperator[]>([]);
  const [trucks, setTrucks] = useState<Truck[]>([]);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const api = createAxios();

  const { accountEditing } = createUpdateAccountModal;

  useEffect(() => {
    if (accountEditing) {
      if (accountEditing.type !== OperatorType.AdminPool) {
        api
          .get<{ operators: AccountOperator[] }>(
            `/operators?type=${accountEditing.operator.type}`
          )
          .then((res) => {
            setOperators(res.data.operators);
          })
          .catch((e) => handleError(e));
        api
          .get<ResSites>(`/sites?operatorId=${accountEditing.operator.id}`)
          .then((res) => {
            setInputForm({
              ...inputForm,
              sites: res.data.sites,
              id: accountEditing?.id,
              name: accountEditing?.name,
              operatorId: accountEditing.operator.id,
              operatorType: accountEditing.operator.type,
              siteId: accountEditing.siteId,
              role: accountEditing.type,
              truckId: accountEditing.truckId,
              operatorPoolId: accountEditing.operatorPoolId,
              stationIds: accountEditing.userStations.map(({ id }) => id),
              tenantId: accountEditing.tenantId,
            });
          })
          .catch((e) => handleError(e));
        fetchTruckOption(accountEditing.siteId, (data) => {
          if (data?.trucks) {
            setTrucks(data.trucks);
          }
        });
        accountEditing?.siteId && fetchTenants(accountEditing.siteId);
      } else {
        setInputForm({
          ...inputForm,
          id: accountEditing?.id,
          name: accountEditing?.name,
          operatorType: accountEditing.type,
          role: accountEditing.type,
          operatorPoolId: accountEditing.operatorPoolId,
          stationIds: accountEditing.userStations.map(({ id }) => id),
          tenantId: accountEditing.tenantId,
        });
      }
    }
  }, []);

  const fetchTenants = (siteId: string) => {
    getTenants(siteId, (data) => {
      if (data?.tenants) {
        setTenants(data.tenants);
      }
    });
  };

  const handleSubmit = (form: AccountForm) => {
    createUpdateAccountModal.type === ModalType.add
      ? onCreateAccount(form)
      : onUpdateAccount(form);
  };
  return (
    <div className={`${styles.createUpdateAccountModal}`}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {createUpdateAccountModal.type === ModalType.add
                ? 'アカウントを追加する'
                : 'アカウントを編集する'}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <CreateUpdateAccountForm
            initForm={inputForm}
            type={createUpdateAccountModal.type}
            handleSubmit={handleSubmit}
            operators={operators}
            trucks={trucks}
            setTrucks={setTrucks}
            operatorType={accountEditing?.type}
            tenants={tenants}
            fetchTenants={fetchTenants}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateUpdateAccountModal;
