// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import DonutChart from 'components/molecules/DonutChart';
import dayjs from 'dayjs';
import { FilterObject } from 'pages/Dashboard';
import React, { useEffect, useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { createAxios } from 'ts/createAxios';
import { DATE_FORMAT } from 'util/ConstantValues';
import { RoleType } from 'util/Enums';
import { normalizeNumber } from 'util/commons';
import { API_ENDPOINTS } from 'util/endPoints';
import styles from './RateOfWasteByItemChart.module.scss';

interface RateWasteItem {
  wasteUnitName: string;
  rate: number;
  weight: number;
}

interface RateBrandItem {
  brandItemName: string;
  rate: number;
  weight: number;
}

interface RateRecycleMethodResponse {
  totalRate: string;
  wasteUnits: string[];
  industries: string[];
  tenants: string[];
  sites: string[];
  isYearData: boolean;
  rateByWasteUnits?: RateWasteItem[];
  dateFrom?: string;
  dateTo?: string;
  dateRange?: string;
  rateByBrandItems?: RateBrandItem[];
}

interface Props {
  filter: FilterObject;
  isAdminOperatorSiteView?: boolean;
}

const RateOfWasteByItemChart: React.FC<Props> = ({
  filter,
  isAdminOperatorSiteView,
}) => {
  const commonsApi = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const [data, setData] = React.useState<RateRecycleMethodResponse>();
  const [loading, setLoading] = React.useState(false);
  const [downloadData, setDownloadData] =
    React.useState<RateRecycleMethodResponse>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadCSVRef = React.useRef<any>();

  const { adminOperatorView, adminSiteView } = useMemo(
    () => ({
      adminOperatorView:
        user.role === RoleType.AdminOperator && !isAdminOperatorSiteView,
      adminSiteView:
        user.role === RoleType.AdminSite || isAdminOperatorSiteView,
    }),
    [user.role, isAdminOperatorSiteView]
  );

  const brandItems = filter?.brandItems?.filter((item) => item.id != null);

  const commonParams = React.useMemo(() => {
    return {
      dateFrom: filter.dates.from,
      dateTo: filter.dates.to,
      siteIds: filter?.sites?.map((item) => item.id) || [],
      siteNames: filter?.sites?.map((item) => item.name) || [],
      wasteUnitNames: filter.wasteUnitNames || [],
      industries: filter.industries || [],
      tenantIds:
        filter?.tenants?.filter((item) => !!item.id)?.map((item) => item.id) ||
        [],
      tenantNames:
        filter?.tenants
          ?.filter((item) => !!item.id)
          ?.map((item) => item.name) || [],
      brandItemIds: brandItems?.map((item) => item.id) || [],
      brandItemNames: brandItems?.map((item) => item.name) || [],
      isDashboardSite: filter.isDashboardSite,
    };
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    commonsApi
      .post<RateRecycleMethodResponse>(
        user.role === RoleType.BrandOwner
          ? API_ENDPOINTS.DASHBOARD_BRAND_ITEMS
          : API_ENDPOINTS.DASHBOARD_WASTE_UNITS,
        commonParams
      )
      .then((res) => {
        setData(res.data);
        setLoading(false);
      });
  }, [commonParams]);

  const handleDownloadCSV = () => {
    commonsApi
      .post(
        user.role === RoleType.BrandOwner
          ? API_ENDPOINTS.DASHBOARD_BRAND_ITEMS_EXPORT
          : API_ENDPOINTS.DASHBOARD_WASTE_UNITS_EXPORT,
        commonParams
      )
      .then((res) => {
        setDownloadData(res.data);
        setTimeout(() => {
          downloadCSVRef.current.link.click();
        }, 500);
      });
  };

  const rateRecycleDataKey: keyof RateRecycleMethodResponse =
    user.role === RoleType.BrandOwner ? 'rateByBrandItems' : 'rateByWasteUnits';

  const dataLabels = data?.[rateRecycleDataKey]?.slice(0, 6) || [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const otherRate = (data?.[rateRecycleDataKey] as any)?.reduce(
    (total: number, { rate }: RateWasteItem | RateBrandItem, index: number) => {
      if (index >= 6) {
        return total + rate;
      }

      return total;
    },
    0
  );

  const downloadBrandItem = downloadData?.[rateRecycleDataKey] || undefined;

  const dataRateItem = data?.[rateRecycleDataKey] || [];

  return (
    <div className={styles.site}>
      {!loading && (
        <DonutChart
          title={t('dashboard.rate_by_waste_chart.name') || '品目割合'}
          data={[
            ...dataLabels.map((i) => {
              return {
                label: i.brandItemName ?? i.wasteUnitName,
                value: Number(Number(i.rate).toFixed(2)),
              };
            }),
            ...(dataRateItem && dataRateItem.length > 6
              ? [
                  {
                    label: t('dashboard.rate_recycle_method_chart.other'),
                    value: Number(otherRate?.toFixed(2)) || 0,
                  },
                ]
              : []),
          ]}
          handleDownloadCSV={handleDownloadCSV}
          emptyMessage={t('dashboard.empty_chart_data') || ''}
        />
      )}
      <CSVLink
        className="hidden"
        filename={
          t('dashboard.rate_by_waste_chart.file_csv_name', {
            date_time: dayjs().format(DATE_FORMAT.slaYMDHm),
          }) + '.csv'
        }
        data={[
          // header
          [
            t('dashboard.rate_by_waste_chart.item_name'),
            t('dashboard.rate_by_waste_chart.item_weight') + '(kg)',
            t('dashboard.rate_by_waste_chart.rate') + '(%)',
          ],
          downloadData?.dateRange && user.role === RoleType.BrandOwner
            ? [
                t('dashboard.recycling_rate_csv.date_range'),
                downloadData?.dateRange || '',
              ]
            : [],
          downloadData?.dateFrom && downloadData.dateTo
            ? [
                t('dashboard.recycling_rate_csv.date_range'),
                `${downloadData.dateFrom}~${downloadData.dateTo}`,
              ]
            : [],
          downloadData?.sites &&
          downloadData?.sites.length > 0 &&
          adminOperatorView
            ? [
                t('dashboard.recycling_rate_csv.site_name'),
                downloadData?.sites?.join(', ') || '',
              ]
            : [],
          downloadData?.industries &&
          downloadData?.industries.length > 0 &&
          adminSiteView
            ? [
                t('dashboard.recycling_rate_csv.business_type'),
                downloadData?.industries?.join(', ') || '',
              ]
            : [],
          downloadData?.tenants &&
          downloadData?.tenants.length > 0 &&
          adminSiteView
            ? [
                t('dashboard.recycling_rate_csv.tenant_name'),
                downloadData?.tenants?.join(', ') || '',
              ]
            : [],
          downloadData?.tenants &&
          downloadData?.tenants.length > 0 &&
          user.role === RoleType.BrandOwner
            ? [
                t('dashboard.recycling_rate_csv.store_name'),
                downloadData?.tenants?.join(', ') || '',
              ]
            : [],
          downloadData?.wasteUnits && downloadData?.wasteUnits.length > 0
            ? [
                t('dashboard.recycling_rate_csv.item'),
                downloadData?.wasteUnits?.join(', ') || '',
              ]
            : [],
          ...(downloadBrandItem?.map((item) => {
            return [
              item?.name ?? item.wasteUnitName,
              normalizeNumber({ value: item.weight, toFixed: false }),
              Number(item.rate).toFixed(2),
            ];
          }) || []),
        ].filter((data) => data.length)}
        ref={downloadCSVRef}
      >
        {t('download.volume_reduction_registration')}
      </CSVLink>
    </div>
  );
};

export default RateOfWasteByItemChart;
