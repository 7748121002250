// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './CheckboxField.module.scss';

type CheckboxFieldProps = {
  input?: React.InputHTMLAttributes<HTMLInputElement>;
  title?: React.ReactNode;
};

const CheckboxField = (props: CheckboxFieldProps) => {
  return (
    <label className={styles.checkbox}>
      <input {...props?.input} type="checkbox" />
      <span className={styles.checkmark}></span>
      {props.title && <span className={styles.text}>{props.title}</span>}
    </label>
  );
};

export default CheckboxField;
