// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import MapBox from 'components/atoms/MapBox';
import MapCircle from 'components/atoms/MapCircle';
import { useEffect, useMemo, useState } from 'react';
import { uniqRandomNumber } from 'util/commons';
import { TOKYO_COORDINATES } from 'util/ConstantValues';

export type BubbleMapItem<T> = T & {
  value: number;
  lng?: string | number;
  lat?: string | number;
};

type BubbleMapProps<T> = {
  displayLimit?:
    | {
        maxRadius?: number;
        minRadius?: number;
        maxDisplayValue?: number;
      }
    | false;
  data?: BubbleMapItem<T>[];
  tooltipDraw?: (it: BubbleMapItem<T>) => React.ReactNode;
  darkMode?: boolean;
};

function BubbleMap<T>({
  displayLimit,
  data = [],
  tooltipDraw,
  darkMode,
}: BubbleMapProps<T>) {
  const [mapKey, setMapKey] = useState<number>();
  const [map, setMap] = useState<google.maps.Map>();
  const [touchedBubbleId, setTouchedBubbleId] = useState<number>();

  useEffect(() => {
    setMapKey(uniqRandomNumber());
  }, [data]);

  const pointers = useMemo(
    (): BubbleMapItem<T>[] => data.sort((a, b) => b.value - a.value),
    [data]
  );

  const bounds = useMemo(() => {
    if (!data.length) {
      return [TOKYO_COORDINATES as google.maps.LatLngAltitude];
    }

    return data.map(
      ({ lat, lng }) =>
        ({
          lat: Number(lat),
          lng: Number(lng),
        } as google.maps.LatLngAltitude)
    );
  }, [data]);

  const {
    minValue,
    maxValue,
    minBubbleTmpRadius,
    maxBubbleTmpRadius,
    valueOverRadius,
  } = useMemo(() => {
    const minValue = pointers[pointers.length - 1]?.value ?? 0;
    const maxValue = pointers[0]?.value ?? 0;
    let valueOverRadius = false;

    const cvMaxRadius = displayLimit
      ? displayLimit.maxRadius || maxValue
      : maxValue;
    const cvMinRadius = displayLimit
      ? displayLimit.minRadius || minValue
      : minValue;
    const cvMaxDisplayValue = displayLimit
      ? displayLimit.maxDisplayValue || maxValue
      : maxValue;

    let minBubbleTmpRadius = (minValue / cvMaxDisplayValue) * cvMaxRadius;
    let maxBubbleTmpRadius = (maxValue / cvMaxDisplayValue) * cvMaxRadius;

    if (minBubbleTmpRadius < cvMinRadius) {
      minBubbleTmpRadius = cvMinRadius;
      maxBubbleTmpRadius = (maxValue / minValue) * cvMinRadius;
    }

    if (maxBubbleTmpRadius > cvMaxRadius) {
      minBubbleTmpRadius = cvMinRadius;
      maxBubbleTmpRadius = cvMaxRadius;
      valueOverRadius = true;
    }

    return {
      minValue,
      maxValue,
      minBubbleTmpRadius,
      maxBubbleTmpRadius,
      valueOverRadius,
    };
  }, [pointers, displayLimit]);

  const convertValue = (value: number, pointers: BubbleMapItem<T>[]) => {
    if (pointers.length === 1) {
      return 50;
    }

    if (value === minValue) {
      return minBubbleTmpRadius;
    }

    if (value === maxValue) {
      return maxBubbleTmpRadius;
    }

    if (valueOverRadius) {
      const cvMaxRadius = displayLimit
        ? displayLimit.maxRadius || maxValue
        : maxValue;
      const cvMinRadius = displayLimit
        ? displayLimit.minRadius || minValue
        : minValue;

      return (
        ((value - minValue) / (maxValue - minValue)) *
          (cvMaxRadius - cvMinRadius) +
        cvMinRadius
      );
    }

    return (value / minValue) * minBubbleTmpRadius;
  };

  const bubbles = useMemo(
    () =>
      pointers.map((item, index) => (
        <MapCircle
          id={index}
          center={{
            lat: Number(item.lat),
            lng: Number(item.lng),
          }}
          radius={convertValue(item.value, pointers)}
          key={index}
          zIndex={index}
          content={tooltipDraw?.(item)}
          touchedId={touchedBubbleId}
          onTouch={setTouchedBubbleId}
        />
      )),
    [pointers, tooltipDraw, touchedBubbleId]
  );

  const fitMap = (map: google.maps.Map) => {
    const bounds = new window.google.maps.LatLngBounds();

    bubbles.forEach((x) => {
      const centerCircle = new window.google.maps.Circle(x.props);
      const gBounds = centerCircle.getBounds();
      if (gBounds) bounds.union(gBounds);
    });

    map.fitBounds(bounds);
  };

  useEffect(() => {
    if (map && pointers.length) {
      fitMap(map);
    }
  }, [map, pointers, tooltipDraw]);

  return (
    <MapBox
      key={mapKey}
      bounds={bounds}
      drawInMap={bubbles}
      onCreatedMap={setMap}
      isDarkMode={darkMode}
      isCenter={data.length > 0}
      onMouseDown={() => setTouchedBubbleId(undefined)}
    />
  );
}

BubbleMap.defaultProps = {
  darkMode: true,
  displayLimit: {
    minRadius: 10,
    maxRadius: 170,
    maxDisplayValue: 100000,
  },
};

export default BubbleMap;
