// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import { apiService, commonUrlApi } from 'apis/site_recycle/siteApi';
import categoryWasteUnitSlice from './slices/categoryWasteUnitSlice';
import currencyReducer from './slices/currencySlice';
import historyReducer from './slices/historySlice';
import loadingReducer from './slices/loadingSlice';
import localSettingSlice from './slices/localSettingSlice';
import purchaseMaterialsReducer from './slices/purchaseMaterials';
import signinReducer from './slices/signinSlice';
import siteInfoSlice from './slices/siteInfoSlice';
import userReducer from './slices/userSlice';

export const store = configureStore({
  reducer: {
    [apiService.reducerPath]: apiService.reducer,
    [commonUrlApi.reducerPath]: commonUrlApi.reducer,
    signin: signinReducer,
    user: userReducer,
    history: historyReducer,
    loading: loadingReducer,
    localSetting: localSettingSlice,
    siteInfo: siteInfoSlice,
    categoryWasteUnit: categoryWasteUnitSlice,
    purchaseMaterials: purchaseMaterialsReducer,
    currency: currencyReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiService.middleware,
      commonUrlApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
