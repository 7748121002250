import MenuItem from 'components/atoms/MenuItem';
import { TMenuItem } from 'types/purchase';

type Props = {
  menuItems: TMenuItem[];
  active?: boolean;
  isChild?: boolean;
};

const SideMenu = ({ menuItems, isChild = false }: Props) => {
  return (
    <div className="purchase-menu">
      {menuItems.map((menu, index) => (
        <MenuItem
          key={index}
          value={menu.value}
          isChild={isChild}
          children={menu.children}
          path={menu.path}
          action={menu.action}
          isCollapse={menu.isCollapse}
        />
      ))}
    </div>
  );
};

export default SideMenu;
