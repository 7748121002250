// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './AdminInvoiceTable.module.scss';
import { PAGE_SIZE } from 'util/ConstantValues';
import TableLoading from 'components/atoms/TableLoading';
import { messageErrors } from 'util/messageErrors';
import { CheckBoxCardV2 } from 'components/molecules/CheckBoxCardV2';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Path } from 'routes';
import { InvoiceItem } from 'apis/invoice';
import { InvoiceStatus } from 'util/Types';

interface Props {
  invoiceData: InvoiceItem[];
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  date: string;
  operator: string;
}

const AdminInvoiceTable = ({
  setCurrentPage,
  currentPage,
  invoiceData,
  date,
  operator,
}: Props) => {
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selected, setSelected] = useState<any>([]);
  const [isDisableTr, setIsDisableTr] = useState<boolean>(false);

  useEffect(() => {
    setSelected([]);
    setIsDisableTr(false);
  }, [invoiceData.length]);

  return (
    <div className={styles.adminInvoiceTable}>
      <div className={styles.tableList}>
        <div className="scroll-wrapper">
          <table id={styles.adminInvoiceTable} className="">
            <thead>
              <tr>
                <th className={styles.siteName}>拠点</th>
                <th className={styles.status}>送信ステータス</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {invoiceData && invoiceData.length > 0 ? (
                invoiceData.map((item, i) => {
                  const isChecked = selected.find((s) => s.id === item.id);
                  return (
                    <tr
                      className={
                        isDisableTr && !selected.find((i) => i === item.id)
                          ? styles.disabledTr
                          : ''
                      }
                      key={i}
                    >
                      <td className={styles.siteName}>
                        {item.originalInvoice === true && <span>郵送</span>}
                        <p title={item.name}>{item.name || '-'}</p>
                      </td>

                      <td className={styles.status}>
                        {item.invoiceStatus === InvoiceStatus.Success && (
                          <p>送信済</p>
                        )}
                        {item.invoiceStatus === InvoiceStatus.Failed && (
                          <p>送信失敗</p>
                        )}
                      </td>
                      <td key={item.id}>
                        <div>
                          <CheckBoxCardV2
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onChange={(e: any) => {
                              const id = e.target.id;
                              const newSelectedOptions = selected.includes(id)
                                ? selected.filter((i) => i !== id)
                                : selected.concat(id);

                              setSelected(newSelectedOptions);

                              if (newSelectedOptions.length >= 10) {
                                setIsDisableTr(true);
                              } else {
                                setIsDisableTr(false);
                              }
                            }}
                            id={String(item.id)}
                            checked={isChecked}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className={styles.msgError}>{messageErrors.M_008}</td>
                </tr>
              )}
              {invoiceData && invoiceData.length > 0 && (
                <TableLoading
                  onNext={() => {
                    if (
                      invoiceData.length >= PAGE_SIZE &&
                      invoiceData.length < currentPage
                    ) {
                      setCurrentPage(currentPage + 1);
                    }
                  }}
                />
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className={styles.submit}>
        <button
          onClick={() => {
            const params = `?siteIds=${selected.join(
              ','
            )}&date=${date}&operator=${operator}`;
            history.push(`${Path.adminConfirmInvoice}${params}`);
          }}
          disabled={!selected || selected.length === 0}
          className={styles.submitButton}
          type="submit"
        >
          確認
        </button>
      </div>
    </div>
  );
};

export default AdminInvoiceTable;
