// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
export const IconPDF = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="4" fill="#CFCFCF" />
      <path
        d="M27.4346 24V18.048H31.2346V19.232H28.8666V20.536H30.8986V21.728H28.8666V24H27.4346Z"
        fill="#878787"
      />
      <path
        d="M21.6064 24V18.048H23.3184C25.1344 18.048 26.3344 18.92 26.3344 20.992C26.3344 23.064 25.1344 24 23.3984 24H21.6064ZM23.0384 22.848H23.2304C24.1904 22.848 24.8704 22.408 24.8704 20.992C24.8704 19.576 24.1904 19.184 23.2304 19.184H23.0384V22.848Z"
        fill="#878787"
      />
      <path
        d="M16.1143 24V18.048H18.2023C19.5143 18.048 20.5943 18.512 20.5943 19.984C20.5943 21.4 19.4983 22.024 18.2423 22.024H17.5463V24H16.1143ZM17.5463 20.896H18.1703C18.8663 20.896 19.2023 20.568 19.2023 19.984C19.2023 19.384 18.8183 19.176 18.1303 19.176H17.5463V20.896Z"
        fill="#878787"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 10C14.2044 10 13.4413 10.3161 12.8787 10.8787C12.3161 11.4413 12 12.2044 12 13V27C12 27.7957 12.3161 28.5587 12.8787 29.1213C13.4413 29.6839 14.2043 30 15 30H25C25.7957 30 26.5587 29.6839 27.1213 29.1213C27.6839 28.5587 28 27.7957 28 27V25H26V27C26 27.2652 25.8946 27.5196 25.7071 27.7071C25.5196 27.8946 25.2652 28 25 28H15C14.7348 28 14.4804 27.8946 14.2929 27.7071C14.1054 27.5196 14 27.2652 14 27V13C14 12.7348 14.1054 12.4804 14.2929 12.2929C14.4804 12.1054 14.7348 12 15 12L20.5859 12.0001L25.5858 17H27.9566C27.877 16.624 27.6899 16.2757 27.414 15.9998L22.0001 10.5859C21.6251 10.2109 21.1163 10.0001 20.586 10H15Z"
        fill="#878787"
      />
    </svg>
  );
};
