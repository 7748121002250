// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './AdminContentHeader.scss';

import { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectUser } from 'redux/slices/userSlice';
import { IconChevronRight } from 'components/atoms/icons/IconChevronRight';
import { IconArrowDown } from 'components/atoms/icons/IconArrowDown';
import { fnLogout } from 'util/commons';

type Breadcrumb = {
  label: string;
  href?: string;
};

type Props = {
  breadcrumb?: Breadcrumb[];
};

export function AdminContentHeader({ breadcrumb }: Props) {
  const history = useHistory();
  const { t } = useTranslation();

  const user = useSelector(selectUser);

  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const dropdownContainerRef = useRef<HTMLDivElement>(null);

  const labelSliceToDisplay = (
    label: string,
    maxNumOfCharacterDisplay = 20
  ) => {
    if (!label) {
      return '';
    }

    return label.length > maxNumOfCharacterDisplay
      ? `${label.slice(0, maxNumOfCharacterDisplay)}...`
      : label;
  };

  const handleSignOut = () => fnLogout(history);

  useEffect(() => {
    const handleClickOutsideDropdown = (event) => {
      if (
        dropdownContainerRef.current &&
        !(dropdownContainerRef.current as HTMLDivElement).contains(event.target)
      ) {
        setIsShowDropdown(false);
      }
    };

    window.addEventListener('click', handleClickOutsideDropdown);

    return () => {
      window.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  return (
    <header className="admin-content-header">
      <div className="admin-content-header__breadcrumb">
        {breadcrumb?.map((b, index) => {
          return b.href ? (
            <span key={index}>
              <Link to={b.href} title={b.label}>
                {labelSliceToDisplay(b.label)}
              </Link>

              <IconChevronRight />
            </span>
          ) : (
            <span key={index} title={b.label}>
              {labelSliceToDisplay(b.label)}
            </span>
          );
        })}
      </div>

      <div
        ref={dropdownContainerRef}
        className="admin-content-header__dropdown-container"
      >
        <div
          className="admin-content-header__dropdown-toggle"
          onClick={() => setIsShowDropdown((isShowDropdown) => !isShowDropdown)}
        >
          <div className="admin-content-header__avatar">
            <img src="/assets/images/ic-avatar.svg" alt="ava" />
          </div>
          <p title={user.name}>{labelSliceToDisplay(user.name, 20)}</p>
          <IconArrowDown />
        </div>

        {isShowDropdown && (
          <div className="admin-content-header__dropdown-menu">
            <div
              className="admin-content-header__dropdown-item"
              onClick={handleSignOut}
            >
              {t('common.sidebar_logout')}
            </div>
          </div>
        )}
      </div>
    </header>
  );
}
