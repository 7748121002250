import { createSlice } from '@reduxjs/toolkit';
import { purchaseApi } from 'apis/purchase/purchaseApi';
import { ChainsMaterialNames } from 'types/purchase';

const initialState: ChainsMaterialNames[] = [];

export const purchaseMaterialsSlice = createSlice({
  initialState: initialState,
  name: 'materials',
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      purchaseApi.endpoints.fetchMaterialNameFromChains.matchFulfilled,
      (state, { payload }) => {
        return payload || [];
      }
    );
  },
});

export default purchaseMaterialsSlice.reducer;
