import { apiService } from 'apis/site_recycle/siteApi';
import { CompressionBookingResponse } from 'types/compression';
import { addition } from 'util/Calc';
import {
  Compression,
  PreRegisResource,
  UserCompressionCreateRequest,
  UserCompressionInfo,
} from 'util/Types';
import { API_ENDPOINTS } from 'util/endPoints';

const addTagTypes = ['userCompression'] as const;

const compressionUserApi = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchAllCompressions: build.query<Compression[], void>({
        query: () => ({
          url: '/compressions',
          method: 'GET',
        }),
        providesTags: (result, error) => {
          if (error) return [];
          return ['userCompression'];
        },
      }),
      fetchCompressionInfo: build.query<
        { receiveTotal: number; registTotal: number },
        void
      >({
        query: () => ({
          url: '/compressions/info',
          method: 'GET',
        }),
        providesTags: ['userCompression'],
        transformResponse(baseQueryReturnValue: UserCompressionInfo) {
          const receiveWeight =
            baseQueryReturnValue.receives.reduce(
              (prev, receive) => addition(prev, parseFloat(receive.weight)),
              0
            ) || 0;
          const registWeight =
            baseQueryReturnValue.compressions.reduce(
              (prev, compression) =>
                addition(prev, parseFloat(compression.weight)),
              0
            ) || 0;
          return {
            receiveTotal: receiveWeight,
            registTotal: registWeight,
          };
        },
      }),
      createCompression: build.mutation<void, UserCompressionCreateRequest>({
        query: (data) => ({
          url: '/compressions',
          method: 'POST',
          data,
        }),
        invalidatesTags: (result, error) => (error ? [] : ['userCompression']),
      }),
      fetchSiteInfoById: build.query({
        query: (siteId: string | null) => ({
          url: `sites/${siteId}/info`,
          method: 'GET',
        }),
      }),
      updateCompression: build.mutation({
        query: (data) => ({
          url: '/compressions',
          method: 'PUT',
          data: {
            compression: data,
          },
        }),
        invalidatesTags: (result, error) => (error ? [] : ['userCompression']),
      }),
      createCompressionDeliveries: build.mutation({
        query: (data) => ({
          url: '/compressions/booking-deliveries',
          method: 'POST',
          data: data,
        }),
        invalidatesTags: (result, error) => (error ? [] : ['userCompression']),
      }),
      fetchCompressionBookings: build.query<CompressionBookingResponse, void>({
        query: () => ({
          url: '/compressions/bookings',
          method: 'GET',
        }),
        providesTags: ['userCompression'],
      }),
      fetchNextStageSites: build.mutation({
        query: (data) => ({
          url: '/compressions/next-stage-sites',
          method: 'POST',
          data: data,
        }),
        transformResponse(
          baseQueryReturnValue: { id: string; name: string }[]
        ) {
          return baseQueryReturnValue.map((x) => ({
            label: x.name,
            value: x.id,
          }));
        },
        invalidatesTags: (result, error) => (error ? [] : ['userCompression']),
      }),
      fetchCompressionResourceInput: build.query<PreRegisResource[], void>({
        query: () => ({
          url: API_ENDPOINTS.COMPRESSIONS_INPUT_RESOURCE,
          method: 'GET',
        }),
      }),
    }),
  });

export const {
  useFetchAllCompressionsQuery,
  useFetchCompressionInfoQuery,
  useCreateCompressionMutation,
  useFetchSiteInfoByIdQuery,
  useUpdateCompressionMutation,
  useCreateCompressionDeliveriesMutation,
  useFetchCompressionBookingsQuery,
  useFetchNextStageSitesMutation,
  useFetchCompressionResourceInputQuery,
} = compressionUserApi;
