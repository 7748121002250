// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import LanguageSelect from 'components/atoms/LanguageSelect';
import { IConChevronLeft } from 'components/atoms/icons/IconChevronLeft';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import { IconReload } from 'components/atoms/icons/IconReload';
import { Navbar } from 'components/organisms/Navbar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from 'redux/slices/userSlice';
import { RoleType } from 'util/Enums';
import './PrimaryTemplate.scss';
import useNetworkConnection from 'util/hooks/useNetworkConnection';

interface Props {
  children?: React.ReactNode;
  download?: VoidFunction;
  prevFunction?: () => void;
  className?: string;
  hasLogo?: boolean;
  h1?: string | null;
  prev?: string;
  withPageReload?: boolean;
  // show site name, language select and navbar on right side of header
  showActions?: boolean;
  customName?: string;
}

export function PrimaryTemplate({
  children,
  download,
  withPageReload = true,
  h1,
  prev,
  prevFunction,
  className,
  hasLogo,
  showActions = true,
  customName,
}: Props) {
  const user = useSelector(selectUser);
  const labelSliceToDisplay = (
    label: string,
    maxNumOfCharacterDisplay = 20
  ) => {
    if (!label) {
      return '';
    }
    return label.length > maxNumOfCharacterDisplay
      ? `${label.slice(0, maxNumOfCharacterDisplay)}...`
      : label;
  };
  const { t } = useTranslation();

  const isOnline = useNetworkConnection();

  const getCustomName = () => {
    if (customName != null) {
      return customName;
    }

    return user.role === RoleType.AdminOperator ||
      user.role === RoleType.BrandOwner
      ? user.operatorName
      : user.role === RoleType.AdminSite || user.role === RoleType.UserNormal
      ? user.siteName
      : user.role === RoleType.AdminTenant
      ? user.tenantName || ''
      : '';
  };

  return (
    <div>
      <header className={'emission'}>
        <div className="disp-info flex">
          {prev && (
            <div className="prev">
              <Link to={prev}>
                <IConChevronLeft />
                <span>{t('common.return')}</span>
              </Link>
            </div>
          )}
          {!prev && prevFunction && (
            <div className="back" onClick={prevFunction}>
              <IConChevronLeft />
              <span>{t('common.return')}</span>
            </div>
          )}
          {download && (
            <div className="download" onClick={download}>
              <IconDownload />
            </div>
          )}

          <h1>
            {h1}
            {hasLogo && <img src="/assets/images/logos.svg" alt="logo" />}
            {withPageReload && isOnline && (
              <span className="reload">
                <IconReload />
              </span>
            )}
          </h1>

          <div hidden={!showActions}>
            <div className="site-name" title={getCustomName()}>
              {labelSliceToDisplay(getCustomName(), 20)}
            </div>

            <div className="language">
              <LanguageSelect />
            </div>
            <Navbar />
          </div>
        </div>
      </header>

      <div className={'contents' + (className ? ' ' + className : '')}>
        {children}
      </div>
    </div>
  );
}
