// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import CreateUpdateTenantInputForm from 'components/molecules/CreateUpdateTenantInputForm/CreateUpdateTenantInputForm';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalType } from 'util/Enums';
import { BrandItem, FloorItem, SectionItem, TenantForm } from 'util/Types';
import { TenantCreateEditModalType } from '../AdminTenantManagement';
import styles from './CreateUpdateTenantModal.module.scss';
import {
  TenantIndustry,
  getWasteUnitsSite,
  getWasteUnitsTenant,
} from 'apis/tenants';
import CreateUpdateSettingItemSite from '../CreateUpdateSettingItemSite';
import { baseTenantForm } from 'util/ConstantValues';
import { mergeClasses } from 'util/commons';
import { useParams } from 'react-router-dom';

interface Props {
  onClose: () => void;
  createUpdateModal: TenantCreateEditModalType;
  brandList: BrandItem[];
  floorList: FloorItem[];
  sectionList: SectionItem[];
  onSubmit: (createTenantForm: TenantForm, tenantId?: number) => void;
  tenantIndustries: TenantIndustry[];
}

const CreateUpdateTenantModal: React.FC<Props> = ({
  brandList,
  floorList,
  sectionList,
  onClose,
  createUpdateModal,
  onSubmit,
  tenantIndustries,
}) => {
  const { t } = useTranslation();
  const { tenantEditing } = createUpdateModal;
  const { siteId } = useParams<{
    siteId: string;
  }>();

  const [step, setStep] = useState<number>(1);
  const [initValues, setInitValuesForm] = useState<TenantForm>(baseTenantForm);

  useEffect(() => {
    if (
      createUpdateModal.type === ModalType.update &&
      tenantEditing &&
      tenantEditing.id
    ) {
      getWasteUnitsTenant(tenantEditing.id.toString(), (res) => {
        setInitValuesForm({
          ...baseTenantForm,
          ...(tenantEditing.section?.id && {
            sectionId: tenantEditing.section.id.toString(),
          }),
          ...(tenantEditing.floor?.id && {
            floorId: tenantEditing.floor.id.toString(),
          }),
          ...(tenantEditing.brand?.id && {
            brandId: tenantEditing.brand.id.toString(),
          }),
          name: tenantEditing.name,
          industry: tenantEditing.industry,
          tenantNumber: tenantEditing.tenantNumber,
          contactName: tenantEditing.contactName,
          contactEmail: tenantEditing.contactEmail,
          inputTenantItems: res,
          isDashboardEnabled: tenantEditing.isDashboardEnabled,
        });
      });
    } else {
      fetchWasteUnitsSite(siteId);
    }
  }, [createUpdateModal]);

  const handleNextStep2 = (tenantForm: TenantForm) => {
    setInitValuesForm(tenantForm);
    setStep(2);
  };

  const handlePrevStep1 = (tenantForm: TenantForm) => {
    setInitValuesForm(tenantForm);
    setStep(1);
  };

  const handleSubmit = (createTenantForm: TenantForm, tenantId?: number) => {
    const fullValuesForm = { ...initValues, ...createTenantForm };
    onSubmit(fullValuesForm, tenantId);
    onClose();
  };

  const fetchWasteUnitsSite = (siteId: string) => {
    getWasteUnitsSite(siteId, (res) => {
      setInitValuesForm({ ...initValues, inputTenantItems: res });
    });
  };

  return (
    <div
      className={mergeClasses(styles.createTenantModal, {
        [styles.settingItemSiteModal]: step === 2,
      })}
    >
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {createUpdateModal.type === ModalType.add
                ? t('common.button.add_tenant')
                : t('common.button.edit_tenant')}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>

          {step === 1 && (
            <CreateUpdateTenantInputForm
              initValues={initValues}
              createUpdateModal={createUpdateModal}
              onNextStep={handleNextStep2}
              brandList={brandList.map((brand) => {
                return { value: brand.id, label: brand.name };
              })}
              floorList={floorList}
              sectionList={sectionList.map((section) => {
                return { value: section.id, label: section.name };
              })}
              tenantIndustries={tenantIndustries}
            />
          )}

          {step === 2 && (
            <CreateUpdateSettingItemSite
              initValues={initValues}
              createUpdateModal={createUpdateModal}
              onPrevStep={handlePrevStep1}
              onSubmit={handleSubmit}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CreateUpdateTenantModal;
