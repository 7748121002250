// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import { IconDashboardCO2 } from 'components/atoms/icons/IconDashboardCO2';
import { IconDashboardEmission } from 'components/atoms/icons/IconDashboardEmission';
import { IconDashboardFinalDisposal } from 'components/atoms/icons/IconDashboardFinalDisposal';
import { IconDashboardRecycled } from 'components/atoms/icons/IconDashboardRecycled';
import { IconDashboardRecyclingRate } from 'components/atoms/icons/IconDashboardRecyclingRate';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { RoleType } from 'util/Enums';
import styles from './DashboardGeneralArea.module.scss';
import {
  Co2EmissionsStatisticsType,
  RateRecycleStatisticsType,
} from 'pages/Dashboard';
import { useTranslation } from 'react-i18next';
import { normalizeNumber } from 'util/commons';
import BigNumber from 'bignumber.js';

const DashboardGeneralArea = ({
  rateRecycleStatistics,
  co2EmissionsStatistics,
  isAdminOperatorSiteView,
}: {
  rateRecycleStatistics?: RateRecycleStatisticsType;
  co2EmissionsStatistics?: Co2EmissionsStatisticsType;
  isAdminOperatorSiteView?: boolean;
}) => {
  const user = useSelector(selectUser);
  const { t } = useTranslation();

  return (
    <div
      className={`${styles.dashboardGeneralArea} ${
        user.role === RoleType.AdminSite || isAdminOperatorSiteView
          ? styles.adminSite
          : ''
      }`}
    >
      <div className={styles.generalItem}>
        <div className={styles.generalItemWraper}>
          <IconDashboardEmission />
          <p className={styles.quantity}>{`${
            rateRecycleStatistics?.totalWeight
              ? normalizeNumber({
                  value: rateRecycleStatistics?.totalWeight / 1000,
                })
              : 0
          }t`}</p>
          <p className={styles.label}>{t('dashboard.total_weight')}</p>
        </div>
      </div>
      <div className={styles.generalItem}>
        <div className={styles.generalItemWraper}>
          <IconDashboardRecycled />
          <p className={styles.quantity}>{`${
            rateRecycleStatistics?.totalRecycleWeight
              ? normalizeNumber({
                  value: rateRecycleStatistics?.totalRecycleWeight / 1000,
                })
              : 0
          }t`}</p>
          <p className={styles.label}>{t('dashboard.total_recycle_weight')}</p>
        </div>
      </div>
      <div className={styles.generalItem}>
        <div className={styles.generalItemWraper}>
          <IconDashboardFinalDisposal />
          <p className={styles.quantity}>{`${
            rateRecycleStatistics?.totalFinalDisposal
              ? normalizeNumber({
                  value: rateRecycleStatistics?.totalFinalDisposal / 1000,
                })
              : 0
          }t`}</p>
          <p className={styles.label}>{t('dashboard.total_final_disposal')}</p>
        </div>
      </div>
      <div className={styles.generalItem}>
        <div className={styles.generalItemWraper}>
          <IconDashboardRecyclingRate />
          <p className={styles.quantity}>{`${
            rateRecycleStatistics?.recycleRate || 0
          }%`}</p>
          <p className={styles.label}>{t('dashboard.total_recycle_rate')}</p>
        </div>
      </div>
      <div className={styles.generalItem}>
        <div className={styles.generalItemWraper}>
          <IconDashboardCO2 />
          <p className={styles.quantity}>{`${
            co2EmissionsStatistics?.totalCO2Emissions
              ? normalizeNumber({
                  value: new BigNumber(
                    co2EmissionsStatistics.totalCO2Emissions
                  ).dividedBy(1000),
                })
              : 0
          }t`}</p>
          <p className={styles.label}>{t('dashboard.total_co2_emission')}</p>
        </div>
      </div>
    </div>
  );
};

export default DashboardGeneralArea;
