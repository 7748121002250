// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { getSitesEmissionsSiteStations } from 'apis/operator_site/siteApi';
import TableLoading from 'components/atoms/TableLoading';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetListParams } from 'types/api';
import { DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import { EmissionsSiteStation } from 'util/Types';
import styles from './SiteManagement.module.scss';
import useGetDevicePlatform from 'util/hooks/useGetDevicePlatform';
import { useHistory } from 'react-router';
import { Path } from 'routes';

const SiteManagement = () => {
  const { t } = useTranslation();
  const [emissionsSiteStation, setEmissionsSiteStation] =
    useState<EmissionsSiteStation>({
      page: 0,
      total: 0,
      sites: [],
    });
  const history = useHistory();

  const deviceType = useGetDevicePlatform();

  useEffect(() => {
    fetchSitesEmissionsSiteStations();
  }, []);

  const fetchSitesEmissionsSiteStations = (params?: GetListParams) => {
    getSitesEmissionsSiteStations({
      params: {
        ...params,
        take: PAGE_SIZE,
        page: params?.page || DEFAULT_PAGE,
      },
      successCallback: (res) => {
        setEmissionsSiteStation({
          ...emissionsSiteStation,
          ...res,
          sites: [...emissionsSiteStation.sites, ...(res.sites || [])],
        });
      },
    });
  };

  const handleOpenDashboard = (siteId: string, siteName: string) => {
    const url = `${Path.dashboard}?siteId=${siteId}&siteName=${siteName}`;

    if (deviceType === 'mobile') {
      history.push(url);
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <PrimaryTemplate h1={t('nav.site_management')}>
      <div className={styles.SiteManagement}>
        <div
          className={styles.CountList}
          dangerouslySetInnerHTML={{
            __html:
              t('common.page', {
                page: emissionsSiteStation?.total,
              }) ?? '',
          }}
        />

        <table className={styles.SiteManagementTable}>
          <thead>
            <tr>
              <th className={styles.SiteName}>
                {t('sites_management.site_name')}
              </th>
              <th className={styles.Station}>
                {t('sites_management.station')}
              </th>
              <th className={styles.Action}>{t('common.action')}</th>
            </tr>
          </thead>

          <tbody>
            {emissionsSiteStation?.sites.length > 0 ? (
              emissionsSiteStation?.sites.map((item, index) => (
                <tr key={index}>
                  <td className={styles.SiteName}>
                    <p>{item.name}</p>
                  </td>
                  <td className={styles.Station}>
                    <div className={styles.StationTags}>
                      {item.siteStations?.map((station) => (
                        <span
                          className={styles.StationTagItem}
                          key={station.id}
                        >
                          {station.name}
                        </span>
                      ))}
                    </div>
                  </td>

                  <td className={styles.Action}>
                    <button
                      className={styles.BtnDashboard}
                      type="button"
                      onClick={() => handleOpenDashboard(item.id, item.name)}
                    >
                      {t('sites_management.dashboard')}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className={styles.NoData}>
                  {t('messages.M_008')}
                </td>
              </tr>
            )}

            {emissionsSiteStation?.sites?.length > 0 && (
              <TableLoading
                onNext={() => {
                  if (
                    emissionsSiteStation.sites.length >= PAGE_SIZE &&
                    emissionsSiteStation.sites.length <
                      emissionsSiteStation.total
                  ) {
                    fetchSitesEmissionsSiteStations({
                      page: (emissionsSiteStation.page || DEFAULT_PAGE) + 1,
                    });
                  }
                }}
              />
            )}
          </tbody>
        </table>
      </div>
    </PrimaryTemplate>
  );
};

export default SiteManagement;
