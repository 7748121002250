// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { DATE_FORMAT, QUANTITY_INPUT_MAX_LENGTH } from 'util/ConstantValues';
import { ItemType, UncollectEmission, WasteUnit } from 'util/Types';
import { validateInputEmissionNumber } from 'util/validator';
import styles from './EmissionEditItem.module.scss';
import { IconFixedItem } from 'components/atoms/icons/IconFixedItem';
import UnCollectChainTag from 'components/atoms/UnCollectChainTag';
import { getUnitLabel } from 'util/commons';

export type Props = {
  emissionEditData: UncollectEmission;
  onChange: (e: string) => void;
};

export const EmissionEditItem = ({ emissionEditData, onChange }: Props) => {
  const getInputUnit = (waste_unit?: WasteUnit) => {
    return waste_unit?.type === ItemType.OPTIONAL
      ? 'kg'
      : waste_unit?.type === ItemType.FIX &&
        emissionEditData?.wasteUnit?.inputUnit !== null
      ? `${emissionEditData?.wasteUnit?.inputUnit}`
      : ' ';
  };

  const getLabelItem = (item: UncollectEmission) => {
    return item.wasteUnit?.type === ItemType.FIX
      ? `${item.type} ${item.wasteUnit?.convertKg}kg/ ${
          item.wasteUnit?.quantity
        } ${getUnitLabel(item.wasteUnit?.unit)}`
      : item.type;
  };

  return (
    <div>
      <div className={styles.emissionEditItem}>
        <div
          className={`${styles.emissionEditItemCol} ${styles.emissionEditItemColDate}`}
        >
          <div className={styles.optionsArea}>
            {emissionEditData.wasteUnit?.type === ItemType.FIX && (
              <div className={styles.emissionType}>
                <IconFixedItem width={16} height={16} />
              </div>
            )}
            <UnCollectChainTag
              collectionOperatorName={emissionEditData.collectionOperatorName}
              emissionStatus={emissionEditData.emissionStatus}
            />
          </div>
          <div className={styles.emissionDate}>
            {getFormatDateWithCurrentTimezone(
              emissionEditData.datetime,
              DATE_FORMAT.slaYMDHm
            )}
          </div>
        </div>

        <div
          className={`${styles.emissionEditItemCol} ${styles.emissionEditItemColLabel}`}
          title={getLabelItem(emissionEditData)}
        >
          <p>{getLabelItem(emissionEditData)}</p>
        </div>

        <div
          className={`${styles.emissionEditItemCol} ${styles.emissionEditItemColInput}`}
        >
          <input
            lang="en"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onInput={(e: any) => e.target.value.replace(/[^0-9]*/g, '')}
            maxLength={QUANTITY_INPUT_MAX_LENGTH}
            className={styles.input}
            type="text"
            onChange={(e) => {
              if (
                e.target.value === '' ||
                e.target.value == '0' ||
                !validateInputEmissionNumber(
                  e.target.value,
                  emissionEditData.wasteUnit?.type as ItemType
                )
              ) {
                onChange(e.target.value);
              }
            }}
            value={
              emissionEditData.wasteUnit?.type === ItemType.OPTIONAL
                ? emissionEditData?.weight || ''
                : emissionEditData?.wasteUnitQuantity || ''
            }
          />
          <span className={styles.unit}>
            {getInputUnit(emissionEditData.wasteUnit) || ''}
          </span>
        </div>
      </div>
    </div>
  );
};
