// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { LabelContents } from 'components/molecules/LabelContents';
import { useTranslation } from 'react-i18next';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { DATE_FORMAT } from 'util/ConstantValues';
import { WorkHistoriesEmissionsCollected } from 'types/workHistoriesTypes';
import styles from './HistoryCollectCard.module.scss';
import { EdiStatus } from 'util/Types';
import EdiStatusTag from 'components/atoms/EdiStatusTag';
import { OperatorType, RoleType } from 'util/Enums';
import { normalizeNumber } from 'util/commons';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';

type Props = {
  emission: WorkHistoriesEmissionsCollected;
};

export const HistoryCollectCard = ({ emission }: Props) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  return (
    <div className={styles.historyCollectCard}>
      <LabelContents
        label={t('history_emission_card.card_registration_id')}
        contents={emission.id}
      />
      <LabelContents
        label={t('common.content')}
        contents={emission.emission.description}
      />
      <LabelContents
        label={t('common.weight')}
        contents={normalizeNumber({ value: emission.emission.weight }) + 'kg'}
      />
      <div className={styles.requestBorder} />
      <LabelContents
        label={t('history_emission_card.card_discharge_date_time')}
        contents={getFormatDateWithCurrentTimezone(
          emission.emission.datetime,
          DATE_FORMAT.slaYMDHm
        )}
      />
      <LabelContents
        label={t('history_emission_card.card_site')}
        contents={emission.emission.site}
      />
      {user.role !== RoleType.AdminTenant && (
        <LabelContents
          label={t('history_emission_card.card_ejector')}
          contents={emission.emission.tenant}
        />
      )}
      <div className={styles.requestBorder} />
      {emission.collect && (
        <div className={styles.request}>
          <LabelContents
            label={t('histories.label_csv_collection_date_time')}
            contents={getFormatDateWithCurrentTimezone(
              emission.collect.datetime,
              DATE_FORMAT.slaYMDHm
            )}
          />
          <LabelContents
            label={t('histories.label_csv_delivery_company')}
            contents={emission.collect.operatorName}
          />
          <LabelContents
            label={t('histories.label_csv_recover')}
            contents={
              emission.collect.userId + '(' + emission.collect.userName + ')'
            }
          />
          {emission.collect.ediStatus != null &&
            ![EdiStatus.None, EdiStatus.Failed, EdiStatus.Processing].includes(
              emission.collect.ediStatus
            ) && (
              <LabelContents
                label={t('histories.label_manifest_number')}
                contents={emission.collect.ediNumber}
                hideOnEmpty
              />
            )}
          <LabelContents
            label={t('histories.label_edi_id')}
            hideOnEmpty
            contents={
              emission.collect.ediStatus != null &&
              emission.collect.ediStatus !== EdiStatus.None && (
                <EdiStatusTag
                  status={emission.collect.ediStatus}
                  operatorType={OperatorType.Emissions}
                />
              )
            }
          />
        </div>
      )}
    </div>
  );
};
