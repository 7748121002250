import { MouseEvent } from 'react';

// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
type IconRemoveTagProps = {
  color?: string;
  onClick?: (e?: MouseEvent<SVGSVGElement>) => void;
};

export const IconRemoveTag: React.FC<IconRemoveTagProps> = ({
  color,
  onClick,
}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={(e) => onClick?.(e)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.57606 2.57581C2.68858 2.46333 2.84116 2.40014 3.00026 2.40014C3.15936 2.40014 3.31195 2.46333 3.42446 2.57581L6.00026 5.15161L8.57606 2.57581C8.63141 2.51851 8.69762 2.4728 8.77082 2.44135C8.84402 2.40991 8.92275 2.39335 9.00242 2.39266C9.08209 2.39197 9.1611 2.40715 9.23483 2.43732C9.30857 2.46749 9.37556 2.51204 9.4319 2.56837C9.48823 2.62471 9.53279 2.6917 9.56295 2.76544C9.59312 2.83918 9.6083 2.91819 9.60761 2.99785C9.60692 3.07752 9.59037 3.15625 9.55892 3.22945C9.52748 3.30266 9.48177 3.36886 9.42446 3.42421L6.84866 6.00001L9.42446 8.57581C9.53376 8.68897 9.59423 8.84053 9.59287 8.99785C9.5915 9.15517 9.5284 9.30566 9.41715 9.4169C9.30591 9.52815 9.15542 9.59125 8.9981 9.59262C8.84078 9.59398 8.68922 9.53351 8.57606 9.42421L6.00026 6.84841L3.42446 9.42421C3.3113 9.53351 3.15974 9.59398 3.00242 9.59262C2.8451 9.59125 2.69462 9.52815 2.58337 9.4169C2.47213 9.30566 2.40902 9.15517 2.40766 8.99785C2.40629 8.84053 2.46677 8.68897 2.57606 8.57581L5.15186 6.00001L2.57606 3.42421C2.46358 3.3117 2.40039 3.15911 2.40039 3.00001C2.40039 2.84091 2.46358 2.68833 2.57606 2.57581Z"
        fill={color ?? '#6F7175'}
      />
    </svg>
  );
};
