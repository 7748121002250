// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { useTranslation } from 'react-i18next';
import styles from './ImportCSV.module.scss';
import { createAxios } from 'ts/createAxios';
import { useRef, useState } from 'react';
import { getBase64 } from 'components/molecules/UploadFiles';
import { ErrorText } from 'components/atoms/ErrorText';
import { IconDelete } from 'components/atoms/icons/IconDelete';
import { msg } from 'util/message';
import { formatBytes } from 'ts/formatBytes';
import { IconImport } from 'components/atoms/icons/IconImport';
import { handleError } from 'util/errorHandler';
import { toast } from 'react-toastify';
import { validateFiles } from 'util/validator';

const ImportCSV = () => {
  const api = createAxios();
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [fileObject, setFileObject] = useState<{
    file: File | undefined;
    base64: string;
  }>({
    file: undefined,
    base64: '',
  });
  const [error, setError] = useState('');
  const [errorShow, setErrorShow] = useState(false);

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      setFileObject({ file: undefined, base64: '' });
      return;
    }
    setErrorShow(false);

    const msgError = validateFiles({
      files: e.target.files,
    });

    if (msgError) {
      setError(msgError);
      e.target.value = '';
      return;
    }
    setError('');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getBase64(e.target.files[0], (res: any) => {
      setFileObject({ file: e.target.files?.[0], base64: res });
      e.target.value = '';
    });
  };

  const handleSubmitImportFile = async () => {
    try {
      const currentTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      await api.post(`/emissions/import?timezone=${currentTz}`, {
        file: fileObject.base64,
      });
      toast.success(t('messages.M_026'));
    } catch (e) {
      handleError(e, true);
      setErrorShow(true);
    }
  };
  return (
    <PrimaryTemplate h1={t('nav.importCSV')}>
      <div className={styles.importCSV}>
        <div className={styles.modalContent}>
          <div className={styles.description}>
            <div className={styles.uploadFileContent}>
              <div className={styles.uploadFileIcon}>
                <IconImport />
              </div>
              <span>{t('upload_csv.select_to_import')}</span>
              <p>{t('upload_csv.file_size')}</p>
              <label htmlFor="file-upload" className={styles.customFileUpload}>
                <p className={styles.uploadFileButton}>
                  {t('upload_csv.select_file')}
                </p>
              </label>
              <input
                ref={inputRef}
                id="file-upload"
                name="file"
                type="file"
                accept=".csv"
                onChange={handleChangeFile}
              />
            </div>
          </div>

          {fileObject.file && (
            <div className={styles.uploadFileInfo}>
              <div className={styles.uploadFileName}>
                <span>{fileObject.file?.name}</span>
              </div>

              {!errorShow ? (
                <div className={styles.uploadFileAction}>
                  <div className={styles.uploadFileSize}>
                    {formatBytes(fileObject.file?.size)}
                  </div>
                  <div
                    className={styles.deleteIcon}
                    onClick={() => {
                      if (inputRef && inputRef.current?.value) {
                        inputRef.current.value = '';
                      }
                      setFileObject({ file: undefined, base64: '' });
                      setError('');
                    }}
                  >
                    <IconDelete />
                  </div>
                </div>
              ) : (
                <ErrorText
                  text={t('messages.M_038') || msg('M_038')}
                  className="error"
                />
              )}
            </div>
          )}

          {error && (
            <div className={styles.error}>
              <p>{error}</p>
            </div>
          )}

          <div className={styles.uploadFileButtonSubmit}>
            <button
              disabled={!fileObject.file || errorShow}
              className={styles.submitButton}
              type="button"
              onClick={handleSubmitImportFile}
            >
              {t('upload_csv.button_upload')}
            </button>
          </div>
        </div>
      </div>
    </PrimaryTemplate>
  );
};

export default ImportCSV;
