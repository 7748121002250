// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
export type SettingDeliveryFormData = {
  fuelOfType: string;
  truckOfType: string;
  siteIds?: number[];
};

export type WasteFuelUnits = {
  fuelType: string;
  truckType: string;
};

export enum fuelOfType {
  Gasoline = 'Gasoline', // ガソリン
  LightOil = 'Light oil', // 軽油
}

export const fuelTypeList = [
  {
    value: fuelOfType.Gasoline,
    label: 'ガソリン',
  },
  {
    value: fuelOfType.LightOil,
    label: '軽油',
  },
];
