/* eslint-disable @typescript-eslint/no-explicit-any */
// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './AdminDownloads.scss';
import { useState, useRef } from 'react';
import { createAxios } from 'ts/createAxios';
import { AdminTemplate } from 'components/templates/AdminTemplate';
import { CalendarTextField } from 'components/molecules/CalendarTextField';
import { getFormatDate, getSlaYMDhm } from 'ts/formatDate';
import Calendar from 'react-calendar';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const AdminDownloads = () => {
  const { t } = useTranslation();
  const api = createAxios();
  const fetchEmissionRequestsDoneRef = useRef<any>();
  const fetchEmissionRequestedDoneRef = useRef<any>();
  const fetchCollectReceivesDoneRef = useRef<any>();
  const fetchCompressionReceivesDoneRef = useRef<any>();
  const fetchCompressionDeliveredDoneRef = useRef<any>();
  const fetchRecycleReceivesDoneRef = useRef<any>();
  const fetchRecycleCompletedDoneRef = useRef<any>();
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [fetchEmissionRequestsData, setFetchEmissionRequestsData] = useState<
    any[]
  >([]);
  const [fetchEmissionRequestedData, setFetchEmissionRequestedData] = useState<
    any[]
  >([]);
  const [fetchCollectReceivesData, setFetchCollectReceivesData] = useState<
    any[]
  >([]);
  const [fetchCompressionReceivesData, setFetchCompressionReceivesData] =
    useState<any[]>([]);
  const [fetchCompressionDeliveredData, setFetchCompressionDeliveredData] =
    useState<any[]>([]);
  const [fetchRecycleReceivesData, setFetchRecycleReceivesData] = useState<
    any[]
  >([]);
  const [fetchRecycleCompletedData, setFetchRecycleCompletedData] = useState<
    any[]
  >([]);

  const changeDate = (dates: Array<Date>) => {
    setStartDate(getFormatDate(dates[0].toString(), 'YYYY/MM/DD'));
    if (dates.length > 1 && dates[1]) {
      setEndDate(
        getFormatDate(dayjs(dates[1]).startOf('day').toString(), 'YYYY/MM/DD')
      );
    } else {
      setEndDate('');
    }
  };

  const getData = async (dateFrom, dateTo) => {
    let params = '';
    if (dateFrom && dateTo) {
      params = '?dateFrom=' + dateFrom + '&dateTo=' + dateTo;

      //排出データ
      await api.get('/csv-exports/emission/unrequests' + params).then((res) => {
        if (res.data) {
          setFetchEmissionRequestsData(res.data);
        } else {
          setFetchEmissionRequestsData([]);
        }
      });

      //回収依頼データ
      await api.get('/csv-exports/emission/requested' + params).then((res) => {
        if (res.data) {
          setFetchEmissionRequestedData(res.data);
        } else {
          setFetchEmissionRequestedData([]);
        }
      });

      //配送(1)データ
      await api.get('/csv-exports/collect/receives' + params).then((res) => {
        if (res.data) {
          setFetchCollectReceivesData(res.data);
        } else {
          setFetchCollectReceivesData([]);
        }
      });

      //中間処理受領データ
      await api
        .get('/csv-exports/compression/receives' + params)
        .then((res) => {
          if (res.data) {
            setFetchCompressionReceivesData(res.data);
          } else {
            setFetchCompressionReceivesData([]);
          }
        });

      //中間処理登録データ
      await api
        .get('/csv-exports/compression/delivered' + params)
        .then((res) => {
          if (res.data) {
            setFetchCompressionDeliveredData(res.data);
          } else {
            setFetchCompressionDeliveredData([]);
          }
        });

      //リサイクル-受領データ
      await api.get('/csv-exports/recycle/receives' + params).then((res) => {
        if (res.data) {
          setFetchRecycleReceivesData(res.data);
        } else {
          setFetchRecycleReceivesData([]);
        }
      });

      //リサイクル-登録データ
      await api.get('/csv-exports/recycle/complete' + params).then((res) => {
        if (res.data) {
          setFetchRecycleCompletedData(res.data);
        } else {
          setFetchRecycleCompletedData([]);
        }
      });
    } else {
      setFetchEmissionRequestsData([]);
      setFetchEmissionRequestedData([]);
      setFetchCollectReceivesData([]);
      setFetchCompressionReceivesData([]);
      setFetchCompressionDeliveredData([]);
      setFetchRecycleReceivesData([]);
      setFetchRecycleCompletedData([]);
    }
    fetchEmissionRequestsDoneRef.current.link.click(); //排出データ
    fetchEmissionRequestedDoneRef.current.link.click(); //回収依頼データ
    fetchCollectReceivesDoneRef.current.link.click(); //配送(1)データ
    fetchCompressionReceivesDoneRef.current.link.click(); //中間処理受領データ
    fetchCompressionDeliveredDoneRef.current.link.click(); //中間処理登録データ
    fetchRecycleReceivesDoneRef.current.link.click(); //リサイクル-受領データ
    fetchRecycleCompletedDoneRef.current.link.click(); //リサイクル-登録データ
  };

  return (
    <AdminTemplate
      h1={t('common.sidebar_download')}
      active="データダウンロード"
      breadcrumb={[{ label: t('common.sidebar_download') }]}
      className="download-page"
    >
      <div className="title left bold">{t('common.csv_download_range')}</div>
      <div id="data-downloads">
        <div className="half-contents">
          <div className="body" translate="no">
            <div className={'target-date-group'}>
              <CalendarTextField value={startDate} />
              <span className="distance">~</span>
              <CalendarTextField value={endDate} />
            </div>
            <div className={'calender-datepick'}>
              <Calendar
                calendarType="US"
                locale={localStorage.getItem('i18nextLng') || 'ja'}
                allowPartialRange={true}
                selectRange={true}
                prev2Label={null}
                next2Label={null}
                prevLabel={'◀'}
                nextLabel={'▶'}
                maxDate={new Date()}
                formatDay={(locale, date) => getFormatDate(date, 'D')}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onChange={(activeStartDate, value) => {
                  changeDate(activeStartDate);
                }}
                className={'calender'}
              />
            </div>
          </div>
          <div className="modal-bottom-field">
            <div className="bottom-btn-field">
              <div className="submit">
                <button
                  className="submitButton"
                  onClick={() => {
                    if (!startDate || !endDate) {
                      return false;
                    }
                    getData(startDate, endDate);
                  }}
                >
                  {t('common.download')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        <CSVLink
          className="hidden"
          filename={t('download.emission_data') + '.csv'}
          headers={[
            t('download.thead_business_id'),
            t('download.thead_business_name'),
            t('download.thead_base_name'),
            t('download.thead_post_code'),
            t('download.thead_address'),
            t('download.thead_registration_id'),
            t('download.thead_date'),
            t('download.thead_time'),
            t('download.thead_item'),
            t('download.thead_arbitrary_fixed'),
            t('download.thead_quantity'),
            t('download.thead_weight'),
            t('download.thead_grade'),
            t('download.thead_tenant'),
            t('download.thead_brand_owner'),
          ]}
          data={fetchEmissionRequestsData.map((item) => {
            return [
              item.operatorId,
              item.operatorName,
              item.siteName,
              item.sitePostalCode,
              item.siteAddress,
              item.emissionId,
              getSlaYMDhm(item.emissionRegistDate).substr(0, 10),
              getSlaYMDhm(item.emissionRegistTime).substr(-5),
              item.emissionDescription.split(' ')[0],
              item.unit ? '定型' : '任意',
              item.unit,
              item.weight,
              item.grade,
              item.tenant,
              item.brandOwner,
            ];
          })}
          ref={fetchEmissionRequestsDoneRef}
        >
          {t('common.sidebar_operator')}
        </CSVLink>
      }
      {
        <CSVLink
          className="hidden"
          filename={t('download.collection_data') + '.csv'}
          headers={[
            t('download.thead_business_id'),
            t('download.thead_business_name'),
            t('download.thead_base_name'),
            t('download.thead_post_code'),
            t('download.thead_address'),
            t('download.thead_registration_id'),
            t('download.thead_date'),
            t('download.thead_time'),
            t('download.thead_item'),
            t('download.thead_arbitrary_fixed'),
            t('download.thead_quantity'),
            t('download.thead_weight'),
          ]}
          data={fetchEmissionRequestedData.map((item) => {
            return [
              item.operatorId,
              item.operatorName,
              item.siteName,
              item.sitePostalCode,
              item.siteAddress,
              item.emissionId,
              getSlaYMDhm(item.emissionRegistDate).substr(0, 10),
              getSlaYMDhm(item.emissionRegistTime).substr(-5),
              item.emissionDescription.split(' ')[0],
              item.unit ? '定型' : '任意',
              item.unit,
              item.weight,
            ];
          })}
          ref={fetchEmissionRequestedDoneRef}
        >
          {t('download.collection_request')}
        </CSVLink>
      }
      {
        <CSVLink
          className="hidden"
          filename={'回収データ' + '.csv'}
          headers={[
            t('download.thead_business_id'),
            t('download.thead_business_name'),
            t('download.thead_user_id'),
            t('download.thead_user_name'),
            t('download.thead_request_point'),
            t('download.thead_post_code'),
            t('download.thead_address'),
            t('download.thead_date'),
            t('download.thead_time'),
            t('download.thead_registration_id'),
            t('download.thead_request'),
            t('download.thead_item'),
            t('download.thead_arbitrary_fixed'),
            t('download.thead_quantity'),
            t('download.thead_weight'),
          ]}
          data={fetchCollectReceivesData.map((item) => {
            return [
              item.operatorId,
              item.operatorName,
              item.userId,
              item.userName,
              item.siteName,
              item.sitePostalCode,
              item.siteAddress,
              getSlaYMDhm(item.receivedDate).substr(0, 10),
              getSlaYMDhm(item.receivedDateTime).substr(-5),
              item.processingObjectId,
              item.collectedDeliveried,
              item.emissionDescription.split(' ')[0],
              item.unit ? '定型' : '任意',
              item.unit,
              item.weight,
            ];
          })}
          ref={fetchCollectReceivesDoneRef}
        >
          {t('common.sidebar_delivery')}(1)
        </CSVLink>
      }
      {
        <CSVLink
          className="hidden"
          filename={t('download.volume_reduction_received_data') + '.csv'}
          headers={[
            t('download.thead_business_id'),
            t('download.thead_business_name'),
            t('download.thead_base_name'),
            t('download.thead_date'),
            t('download.thead_time'),
            t('download.thead_received_registration_id'),
          ]}
          data={fetchCompressionReceivesData.map((item) => {
            return [
              item.operatorId,
              item.operatorName,
              item.siteName,
              getSlaYMDhm(item.receivedDate).substr(0, 10),
              getSlaYMDhm(item.receivedDateTime).substr(-5),
              item.processingObjectId,
            ];
          })}
          ref={fetchCompressionReceivesDoneRef}
        >
          {t('download.volume_reduction_receipt')}
        </CSVLink>
      }
      {
        <CSVLink
          className="hidden"
          filename={t('download.volume_reduction_registration_data') + '.csv'}
          headers={[
            t('download.thead_business_id'),
            t('download.thead_business_name'),
            t('download.thead_date'),
            t('download.thead_time'),
            t('download.thead_reduced_volume_registration_item'),
            t('download.thead_reduced_volume_registration_quality'),
            t('download.thead_reduced_volume_registration_weight'),
            t('download.thead_reduced_volume_registration_reduction_id'),
            t('download.thead_delivery_registration_datetime'),
          ]}
          data={fetchCompressionDeliveredData.map((item) => {
            return [
              item.operatorId,
              item.operatorName,
              getSlaYMDhm(item.registDate).substr(0, 10),
              getSlaYMDhm(item.registDatetime).substr(-5),
              item.description,
              item.grade,
              item.weight,
              item.processingObjectId,
              item.deliveryRegistDateTime === ''
                ? ''
                : getSlaYMDhm(item.deliveryRegistDateTime),
            ];
          })}
          ref={fetchCompressionDeliveredDoneRef}
        >
          {t('download.volume_reduction_registration')}
        </CSVLink>
      }
      {
        <CSVLink
          className="hidden"
          filename={t('download.recycled_receipt_data') + '.csv'}
          headers={[
            t('download.thead_business_id'),
            t('download.thead_business_name'),
            t('download.thead_date'),
            t('download.thead_time'),
            t('download.thead_received_data_volume_reduction_id'),
          ]}
          data={fetchRecycleReceivesData.map((item) => {
            return [
              item.operatorId,
              item.operatorName,
              getSlaYMDhm(item.receivedDate).substr(0, 10),
              getSlaYMDhm(item.receivedDateTime).substr(-5),
              item.processingObjectId,
            ];
          })}
          ref={fetchRecycleReceivesDoneRef}
        >
          {t('download.recycled_receipt')}
        </CSVLink>
      }
      {
        <CSVLink
          className="hidden"
          filename={t('download.recycle_registration_data') + '.csv'}
          headers={[
            t('download.thead_business_id'),
            t('download.thead_business_name'),
            t('download.thead_date'),
            t('download.thead_time'),
            t('download.thead_product_registration_item'),
            t('download.thead_product_registration_type'),
            t('download.thead_product_registration_quality'),
            t('download.thead_product_registration_weight'),
            t('download.thead_product_registration_product_id'),
          ]}
          data={fetchRecycleCompletedData.map((item) => {
            return [
              item.operatorId,
              item.operatorName,
              getSlaYMDhm(item.registDate).substr(0, 10),
              getSlaYMDhm(item.registDatetime).substr(-5),
              item.description,
              item.materialType.name,
              item.grade,
              item.weight,
              item.processingObjectId,
            ];
          })}
          ref={fetchRecycleCompletedDoneRef}
        >
          {t('dowload.recycle_registration')}
        </CSVLink>
      }
    </AdminTemplate>
  );
};

export default AdminDownloads;
