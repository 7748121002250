// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Modal } from 'components/atoms/Modal';
import { IconCloseTablet } from 'components/atoms/icons/IconCloseTablet';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import { memo, useCallback, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { DateTypeRange } from 'util/Enums';
import { normalizeNumber } from 'util/commons';
import DateTimeUnitTabButton from '../DateTimeUnitTabButton';
import styles from './LineChart.module.scss';
import html2canvas from 'html2canvas';
import downloadjs from 'downloadjs';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'util/ConstantValues';

export type TimeLineData =
  | {
      date: string;
      day: number;
      month: number;
      year: number;
      value?: number | null;
      isPredictive?: boolean;
    }
  | {
      label: string;
      value?: number | null;
      isPredictive?: boolean;
    };

export type TimeLineChartProps = {
  title: string;
  noteMessage?: string;
  data?: TimeLineData[];
  download?: boolean | (() => void);
  height?: number;
  filterType?: DateTypeRange | false;
  onFilterTypeChange?: (filter: DateTypeRange) => void;
  unit?: string;
  legends?: {
    normal?: string;
    predictive?: string;
  };
};

const TimeLineChart: React.FC<TimeLineChartProps> = ({
  title,
  noteMessage,
  data = [],
  download,
  height,
  filterType,
  onFilterTypeChange,
  unit,
  legends,
}) => {
  const chartDownloadRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const getTimeLabel = (data: TimeLineData) => {
    if ('label' in data) {
      return data.label;
    }

    if (filterType != null) {
      if (filterType === DateTypeRange.YEAR) {
        return data.year.toString();
      }

      if (filterType === DateTypeRange.DAY) {
        return `${data.year}/${data.month
          .toString()
          .padStart(2, '0')}/${data.day.toString().padStart(2, '0')}`;
      }
    }

    return `${data.year}/${data.month.toString().padStart(2, '0')}`;
  };

  const handleDownload = async () => {
    if (!download) {
      return;
    }

    if (download === true) {
      if (chartDownloadRef.current) {
        const canvas = await html2canvas(chartDownloadRef.current, {
          backgroundColor: 'rgba(0, 0, 0, 0)',
        });

        downloadjs(
          canvas.toDataURL('image/png'),
          `${title} ${dayjs().format(DATE_FORMAT.slaYMDHm)}.png`,
          'image/png'
        );
      }

      return;
    }

    download();
  };

  const renderChart = useCallback(() => {
    if (!data?.length) {
      return (
        <div className={styles.Empty}>
          {t('dashboard.empty_chart_data') || ''}
        </div>
      );
    }

    const timeLabels = data.map((i) => getTimeLabel(i));

    const [predictiveDataSet, normalDataSet]: [
      (number | null)[],
      (number | null)[]
    ] = data.reduce(
      (
        [predictiveSet, normalSet]: [(number | null)[], (number | null)[]],
        { value, isPredictive }
      ) => {
        predictiveSet.push(value ?? null);
        normalSet.push(!isPredictive && value != null ? value : null);

        return [predictiveSet, normalSet];
      },
      [[], []]
    );

    return (
      <div className={styles.ChartWrapper}>
        <div className={styles.ChartBox}>
          <Chart
            {...{
              onClick: () => setIsOpenModal(true),
              style: {
                cursor: 'pointer',
              },
            }}
            type="line"
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    title: () => '',
                    label: ({ label, raw, datasetIndex }) =>
                      datasetIndex === 0
                        ? `${label}: ${normalizeNumber({
                            value: String(raw),
                          })}${unit ?? ''}`
                        : '',
                  },
                },
              },
              maintainAspectRatio: false,
              scales: {
                x: {
                  grid: {
                    color: '#5e5e5e',
                    borderDash: [5, 5],
                  },
                  ticks: {
                    color: '#CFCFCF',
                    padding: 10,
                    font: {
                      size: 10,
                    },
                  },
                },
                y: {
                  beginAtZero: true,
                  grid: {
                    color: '#5e5e5e',
                  },
                  ticks: {
                    padding: 12,
                    color: '#CFCFCF',
                    font: {
                      size: 12,
                    },
                  },
                },
              },
            }}
            data={{
              labels: timeLabels,
              datasets: [
                {
                  data: predictiveDataSet,
                  borderWidth: 2,
                  borderDash: [5, 5],
                  borderColor: '#86EA5C',
                  pointBackgroundColor: '#86EA5C',
                  pointRadius: 0,
                  pointHitRadius: 12,
                },
                {
                  data: normalDataSet,
                  borderWidth: 2,
                  borderColor: '#86EA5C',
                  pointBackgroundColor: '#86EA5C',
                  pointRadius: 2,
                  pointHitRadius: 12,
                },
              ],
            }}
          />
        </div>
      </div>
    );
  }, [data]);

  const renderLegends = () => {
    if (!data?.length || !legends || (!legends.normal && !legends.predictive)) {
      return null;
    }

    return (
      <div className={styles.LegendWrapper}>
        {legends?.normal && (
          <div className={styles.LegendItem}>
            <div className={styles.LegendSolidLine} />
            <p
              className={`${styles.LegendLabel} ${title}-chartLegendLabel`}
              title={legends?.normal}
            >
              {legends?.normal}
            </p>
          </div>
        )}

        {legends?.predictive && (
          <div className={styles.LegendItem}>
            <div className={styles.LegendDashedLine} />
            <p
              className={`${styles.LegendLabel} ${title}-chartLegendLabel`}
              title={legends?.predictive}
            >
              {legends?.predictive}
            </p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.LineChart}>
      <div className={styles.Header}>
        <div className={styles.Heading}>
          <p className={styles.Title}>{title}</p>
          <p className={styles.NoteMessage}>{noteMessage}</p>
        </div>
        <div className={styles.Actions}>
          {filterType !== false && (
            <DateTimeUnitTabButton
              disableFilterByYearAndDay={false}
              activeTab={filterType}
              setActiveTab={onFilterTypeChange}
            />
          )}

          {download && (
            <button
              className={styles.DownloadButton}
              disabled={!data?.length}
              onClick={handleDownload}
            >
              <IconDownload />
            </button>
          )}
        </div>
      </div>

      <div
        className={styles.Chart}
        style={{
          minHeight: height,
        }}
      >
        {renderChart()}
        {renderLegends()}
      </div>

      {isOpenModal && (
        <Modal className={styles.TimeLineChartModalWrapper}>
          <div className={styles.TimeLineChartModal}>
            <div className={styles.ModalTitle}>
              <p>{title}</p>
              <IconCloseTablet onClick={() => setIsOpenModal(false)} />
            </div>

            <div className={styles.ModalBody}>
              <p className={styles.NoteMessage}>{noteMessage}</p>
              <div className={styles.Charts}>
                {renderChart()}
                {renderLegends()}
              </div>
            </div>
          </div>
        </Modal>
      )}

      {download === true && (
        <div
          style={{
            padding: '0 16px 16px',
            position: 'fixed',
            zIndex: -Number.MAX_SAFE_INTEGER,
            top: -Number.MAX_SAFE_INTEGER,
            left: -Number.MAX_SAFE_INTEGER,
            width: 1200,
            height: 650,
            display: 'flex',
            flexDirection: 'column',
          }}
          ref={chartDownloadRef}
        >
          {renderChart()}
          {renderLegends()}
        </div>
      )}
    </div>
  );
};

TimeLineChart.defaultProps = {
  download: true,
};

export default memo(TimeLineChart);
