// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './ScaleSelectOperator.scss';
import { useEffect, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import { ScaleTemplate } from 'components/templates/ScaleTemplate';
import { ScaleSelectOperatorCard } from 'components/molecules/ScaleSelectOperatorCard';
import { useHistory } from 'react-router-dom';
import { Path } from 'routes';
import { useSelector } from 'react-redux';
import { selectLoading } from 'redux/slices/loadingSlice';
import { useTranslation } from 'react-i18next';

type EmissionOperator = {
  id: string;
  name: string;
  siteId: string;
};

const ScaleSelectOperator = () => {
  const api = createAxios();
  const history = useHistory();
  const [operators, setOperators] = useState<EmissionOperator[]>([]);
  const loading = useSelector(selectLoading);
  const { t } = useTranslation();

  const callApi = async () => {
    try {
      const res = await api.get('/scales/operators');
      setOperators(res.data);

      if (res.data.length == 1) {
        setOperator(res.data[0]);
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setOperator = async (operator: EmissionOperator) => {
    localStorage.setItem('operatorId', operator.id);
    localStorage.setItem('operatorName', operator.name);
    localStorage.setItem('siteId', operator.siteId);

    const res = await api.get('/scales/operators/' + operator.siteId);
    if (res.data.waste.length == 1) {
      localStorage.setItem('wasteId', res.data.waste[0].id);
      localStorage.setItem('wasteName', res.data.waste[0].name);
      history.push(Path.scaleEmission);
    } else {
      history.push(Path.scaleSelectType);
    }
    return;
  };

  useEffect(() => {
    // アイテムをリセット
    localStorage.removeItem('operatorId');
    localStorage.removeItem('operatorName');
    localStorage.removeItem('siteId');
    localStorage.removeItem('wasteId');
    localStorage.removeItem('wasteName');
    callApi();
  }, []);

  const onClick = async (siteId: string) => {
    const operator = operators.filter((o) => o.siteId == siteId)[0];
    setOperator(operator);
    return;
  };

  return (
    <ScaleTemplate>
      {loading.isLoading ? (
        ''
      ) : (
        <>
          <div className="description">
            {t('scale_select_operator.description')}
          </div>
          <div
            className={
              'scale-select' + (operators.length > 3 ? ' column-2' : '')
            }
          >
            {operators.map((o) => {
              return (
                <ScaleSelectOperatorCard
                  id={o.id}
                  name={o.name}
                  siteId={o.siteId}
                  onClick={onClick}
                  key={o.id}
                />
              );
            })}
          </div>
        </>
      )}
    </ScaleTemplate>
  );
};

export default ScaleSelectOperator;
