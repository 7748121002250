// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Tenant } from '../SelectTenantCard';
import styles from './SelectFloorCard.module.scss';
export interface Floor {
  id: number;
  name: string;
  sectionId: number;
  tenants?: Tenant[];
}
interface Props {
  floor: Floor;
  isSelected: boolean;
  onSelectFloor: (floor: Floor) => void;
}
const SelectFloorCard: React.FC<Props> = ({
  floor,
  isSelected,
  onSelectFloor,
}) => {
  return (
    <div
      key={floor.id}
      className={`${styles.floor} ${isSelected ? styles.selected : ''}`}
      onClick={() => {
        onSelectFloor(floor);
      }}
    >
      <div className={styles.floorItem}>
        <p title={floor.name}>{floor.name}</p>
      </div>
    </div>
  );
};

export default SelectFloorCard;
