import {
  PayloadAction,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { ItemSize } from 'util/Enums';
import { CategoryItem } from 'util/Types';

const categoryWasteUnitAdapter = createEntityAdapter<{
  categoryId: string;
  wasteUnit: CategoryItem[];
}>({
  selectId: (categoryWasteUnit) => categoryWasteUnit.categoryId,
});

const categoryWasteUnitState = categoryWasteUnitAdapter.getInitialState({});

const makeItemMediumSmallGroupList = (items: CategoryItem[]) => {
  const listMedium = items.filter((item) => item?.size === ItemSize.MEDIUM);
  const listMediumCount = Math.ceil(listMedium.length / 2);

  const output: CategoryItem[][] = [];
  Array.from(Array(listMediumCount).keys()).forEach((index) => {
    output.push(
      listMedium[index * 2 + 1]
        ? [listMedium[index * 2], listMedium[index * 2 + 1]]
        : [listMedium[index * 2]]
    );
  });

  const smallItems = items.filter((item) => item?.size === ItemSize.SMALL);
  if (smallItems.length) {
    if (output[output.length - 1] && output[output.length - 1].length < 2) {
      output.splice(
        output.length - 1,
        1,
        smallItems.length >= 2
          ? output[output.length - 1].concat([smallItems[0], smallItems[1]])
          : output[output.length - 1].concat([smallItems[0]])
      );
      const sliceSmallItems = smallItems.slice(smallItems.length >= 2 ? 2 : 1);
      const listSmallCount = Math.ceil(sliceSmallItems.length / 4);
      sliceSmallItems.length &&
        Array.from(Array(listSmallCount).keys()).forEach((index) => {
          const listPush = [
            sliceSmallItems[index * 4],
            sliceSmallItems[index * 4 + 1],
            sliceSmallItems[index * 4 + 2],
            sliceSmallItems[index * 4 + 3],
          ];
          output.push(listPush.filter((item) => item));
        });
    } else {
      const listSmallCount = Math.ceil(smallItems.length / 4);

      Array.from(Array(listSmallCount).keys()).forEach((index) => {
        const listPush = [
          smallItems[index * 4],
          smallItems[index * 4 + 1],
          smallItems[index * 4 + 2],
          smallItems[index * 4 + 3],
        ];

        output.push(listPush.filter((item) => item));
      });
    }
  }

  return output;
};

export const categoryWasteUnitSlice = createSlice({
  name: 'categoryWasteUnit',
  initialState: categoryWasteUnitState,
  reducers: {
    setCategoryWasteUnit: (
      state,
      action: PayloadAction<{ categoryId: string; wasteUnit: CategoryItem[] }>
    ) => {
      categoryWasteUnitAdapter.setOne(state, action.payload);
    },
    resetCategoryWasteUnit: () => {
      return categoryWasteUnitAdapter.getInitialState({});
    },
  },
});

const { selectById } = categoryWasteUnitAdapter.getSelectors();

const selectCategoryWasteUnitState = (state: RootState) =>
  state.categoryWasteUnit;

export const selectWasteUnitsByCategoryId = (categoryId: string) => {
  return createSelector(
    selectCategoryWasteUnitState,
    (categoryWasteUnitState) => {
      const entity = selectById(categoryWasteUnitState, categoryId);
      return entity ? entity.wasteUnit : [];
    }
  );
};

export const selectMediumSmallWasteUnitByCategoryId = (categoryId: string) => {
  return createSelector(
    selectCategoryWasteUnitState,
    (categoryWasteUnitState) => {
      const entity = selectById(categoryWasteUnitState, categoryId);
      const wasteUnit = entity ? entity.wasteUnit : [];
      return makeItemMediumSmallGroupList(
        wasteUnit.filter((item) => item?.size !== ItemSize.LARGE)
      );
    }
  );
};

export const selectLargeWasteUnitByCategoryId = (categoryId: string) => {
  return createSelector(
    selectCategoryWasteUnitState,
    (categoryWasteUnitState) => {
      const entity = selectById(categoryWasteUnitState, categoryId);
      const wasteUnit = entity ? entity.wasteUnit : [];
      return wasteUnit.filter((item) => item?.size === ItemSize.LARGE);
    }
  );
};

export const { setCategoryWasteUnit, resetCategoryWasteUnit } =
  categoryWasteUnitSlice.actions;

export default categoryWasteUnitSlice.reducer;
