// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useTranslation } from 'react-i18next';
import { getEdiStatusLabel, mergeClasses } from 'util/commons';
import { EdiStatus } from 'util/Types';
import style from './style.module.scss';
import { OperatorType } from 'util/Enums';

type EditStatusTagProps = {
  status?: EdiStatus;
  operatorType?: OperatorType.Emissions | OperatorType.Collects;
  className?: string;
};

const EdiStatusTag: React.FC<EditStatusTagProps> = ({
  status,
  operatorType,
  className,
}) => {
  const { t } = useTranslation();

  if (status == null || status === EdiStatus.None) {
    return null;
  }

  const getStyle = (): React.CSSProperties | undefined => {
    switch (status) {
      case EdiStatus.Success:
      case EdiStatus.TransportDone:
      case EdiStatus.ProcessDone:
        return {
          backgroundColor: '#d2fdc6',
          color: '#000',
        };

      case EdiStatus.Processing:
        return {
          backgroundColor: '#e7e7e7',
          color: '#000',
        };

      case EdiStatus.Failed:
      case EdiStatus.TransportError:
      case EdiStatus.ProcessError:
        return {
          backgroundColor: '#f75354',
        };

      default:
        return undefined;
    }
  };

  return (
    <div
      className={mergeClasses(style.ediStatusTag, className)}
      style={getStyle()}
    >
      {getEdiStatusLabel({
        status,
        t,
        operatorType,
      })}
    </div>
  );
};

export default EdiStatusTag;
