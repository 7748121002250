// gkc_hash_code : 0D30ELUTRZ1J0KLGGPHD83YZTB
import { Modal } from 'components/atoms/Modal';
import { IconClose } from 'components/atoms/icons/IconClose';
import { useTranslation } from 'react-i18next';
import { mergeClasses, uniqRandomNumber } from 'util/commons';
import styles from './WeightLimitExceededModal.module.scss';

type ModalProps = {
  open: boolean;
  onSubmit: VoidFunction;
  onClose: VoidFunction;
  isAlert?: boolean;
  isDarkmode?: boolean;
};

const WeightLimitExceededModal = ({
  open = false,
  onSubmit,
  onClose,
  isAlert = false,
  isDarkmode = false,
}: ModalProps) => {
  const { t } = useTranslation();

  const handleClose = (e) => {
    e.preventDefault();
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return open ? (
    <div
      className={mergeClasses(styles.weightLimit, {
        [styles.darkmode]: !!isDarkmode,
      })}
      key={uniqRandomNumber()}
    >
      <Modal>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            {isAlert
              ? t('weight_limit_modal.alert.title')
              : t('weight_limit_modal.confirm.title')}
          </div>
          <div className={styles.closeIcon} onClick={handleClose}>
            <IconClose />
          </div>
        </div>
        <div className={mergeClasses('body', styles.contentContainer)}>
          {isAlert
            ? t('weight_limit_modal.alert.content')
            : t('weight_limit_modal.confirm.content')}
        </div>
        <button className={styles.closeBtn} onClick={handleSubmit}>
          {isAlert
            ? t('weight_limit_modal.alert.closeLabel')
            : t('weight_limit_modal.confirm.closeLabel')}
        </button>
      </Modal>
    </div>
  ) : null;
};

export default WeightLimitExceededModal;
