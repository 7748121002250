// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
type IconReloadProps = {
  onReload?: VoidFunction;
};

export const IconReload = ({ onReload }: IconReloadProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        onReload ? onReload() : location.reload();
      }}
    >
      <path d="M19.419 15.0018H15Z" fill="#E8E8E8" />
      <path
        d="M4 4.00182V9.00182H4.582M4.582 9.00182C5.24585 7.35995 6.43568 5.98473 7.96503 5.09168C9.49438 4.19863 11.2768 3.83823 13.033 4.06696C14.7891 4.29569 16.4198 5.10061 17.6694 6.3556C18.919 7.61058 19.7168 9.24468 19.938 11.0018M4.582 9.00182H9M20 20.0018V15.0018H19.419M19.419 15.0018C18.7542 16.6427 17.564 18.0169 16.0348 18.9091C14.5056 19.8014 12.7237 20.1613 10.9681 19.9327C9.21246 19.7041 7.5822 18.8997 6.33253 17.6455C5.08287 16.3914 4.28435 14.7583 4.062 13.0018M19.419 15.0018H15"
        stroke="#E8E8E8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
