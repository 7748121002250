import { Field } from 'react-final-form';
import { mergeClasses } from 'util/commons';
import { Label } from '../Label';
import styles from './InputField.module.scss';

type InputFieldProps = React.ComponentPropsWithoutRef<'input'> & {
  name: string;
  label?: string | null;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  darkMode?: boolean;
  arrayIndex?: number; // for field array
  regex?: RegExp;
};

const InputField = (props: InputFieldProps) => {
  return (
    <Field
      name={props.name}
      render={({ input, meta }) => (
        <div className={styles.inputField}>
          {props.label && (
            <Label
              text={props.label}
              circleNumber={props.arrayIndex}
              tag={'div'}
              required={props.required}
            />
          )}
          <div className={styles.wrapper}>
            {props.prefix && (
              <div className={styles.prefix}>{props.prefix}</div>
            )}
            <input
              {...input}
              {...props}
              className={mergeClasses(styles.input, props.className)}
              onChange={(e) => {
                if (
                  props.regex &&
                  e.target.value &&
                  !props.regex?.test(e.target.value)
                )
                  return;
                input.onChange(e);
              }}
              onBlur={(e) => {
                input.onBlur(e);
                props.onBlur?.(e);
              }}
            />
            {props.suffix && (
              <div className={styles.suffix}>{props.suffix}</div>
            )}
          </div>
          {meta.error && meta.touched && (
            <p className={styles.error}>{meta.error}</p>
          )}
        </div>
      )}
    />
  );
};

export default InputField;
