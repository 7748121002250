// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import { BarcodeICCardInput } from 'util/Types';
import AddICCardForm from '../AddICCardForm';
import styles from './AddICCardModal.module.scss';
import { SiteTenantAuthenticationItem } from 'util/siteManagementTypes';

type Props = {
  authenticationItem: SiteTenantAuthenticationItem | undefined;
  onClose: () => void;
  onSubmitAddICCard: (e: BarcodeICCardInput, tenantId?: number) => void;
};

const AddICCardModal: React.FC<Props> = ({
  authenticationItem,
  onClose,
  onSubmitAddICCard,
}) => {
  return (
    <div className={styles.addICCardModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {authenticationItem?.name}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>

          <AddICCardForm
            authenticationItem={authenticationItem}
            onSubmitAddICCard={(e, tenantId?) => {
              onClose();
              onSubmitAddICCard(e, tenantId);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AddICCardModal;
