// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en/translation.json';
import jp from './locales/jp/translation.json';

export const resources = {
  ja: {
    translation: jp,
  },

  en: {
    translation: en,
  },
} as const;

const getLang = () => {
  const currentLang = localStorage.getItem('i18nextLng');
  if (currentLang && ['ja', 'en'].includes(currentLang)) {
    return currentLang;
  }
  return 'ja';
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: getLang(),
    fallbackLng: ['ja'],
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

export default i18n;
