// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useFetchWorkHistoriesEmissionCollectedQuery } from 'apis/work_histories/emission';
import TableLoading from 'components/atoms/TableLoading';
import { HistoryCollectCard } from 'components/molecules/HistoryCollectCard';
import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import { Site } from 'util/Types';
import {
  WorkHistoriesEmissionsCollected,
  WorkHistoriesParams,
} from 'types/workHistoriesTypes';
import styles from './HistoryCollects.module.scss';
import { RoleType } from 'util/Enums';

export type Props = {
  id?: string;
  sites: Site[];
  setSiteId: (id: string) => void;
  isActive: boolean;
};

export const HistoryCollects = ({
  sites,
  setSiteId,
  isActive = false,
}: Props) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const [data, setData] = useState<WorkHistoriesEmissionsCollected[]>([]);

  const [args, setArgs] = useState<WorkHistoriesParams>({
    page: DEFAULT_PAGE,
    take: PAGE_SIZE,
  });

  const { data: emissions } = useFetchWorkHistoriesEmissionCollectedQuery(
    args,
    {
      skip: !args.siteId || !isActive,
    }
  );

  useEffect(() => {
    setArgs({
      ...args,
      siteId: sites[0]?.childSites[0]?.id,
    });
    setSiteId(sites[0]?.childSites[0]?.id);
  }, [sites]);

  useEffect(() => {
    if (args.page > 1) {
      setData([...data, ...(emissions?.emissionsCollected || [])]);
    } else {
      setData(emissions?.emissionsCollected || []);
    }
  }, [emissions]);

  return (
    <div className={styles.historyEmissions}>
      <div className={styles.selectForm}>
        <Form<string> onSubmit={() => {}} keepDirtyOnReinitialize>
          {() => (
            <form>
              <div className={styles.labelSelect}>
                <div className={styles.label}>
                  <label>
                    {user.role === RoleType.AdminTenant
                      ? t('tenant_histories.tenant_name')
                      : t('histories.emission.select_site_field')}
                  </label>
                </div>
                <div className={styles.siteName}>
                  {user.role === RoleType.AdminTenant
                    ? user.tenantName
                    : user.siteName}
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>

      {data?.map((emission, index) => {
        return <HistoryCollectCard key={index} emission={emission} />;
      })}
      <TableLoading
        onNext={() => {
          if (
            data.length >= PAGE_SIZE &&
            data.length < (emissions?.total || PAGE_SIZE)
          ) {
            setArgs({
              ...args,
              page: args.page + 1,
            });
          }
        }}
      />
    </div>
  );
};
