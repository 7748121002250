// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Modal } from 'components/atoms/Modal';
import { IconCloseTablet } from 'components/atoms/icons/IconCloseTablet';
import { useTranslation } from 'react-i18next';
import { MaterialSiteTypeGradeStatistic } from 'types/purchase';
import { normalizeNumber } from 'util/commons';
import PurchaseResourceSearchTable from '../PurchaseResourceSearchTable';
import styles from './PurchaseResourceSearchModal.module.scss';

type PurchaseResourceSearchModalProps = {
  title: string;
  data: MaterialSiteTypeGradeStatistic[];
  onClose: VoidFunction;
  totalWeight?: string;
};

const PurchaseResourceSearchModal = ({
  title,
  data,
  onClose,
  totalWeight,
}: PurchaseResourceSearchModalProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.searchModal}>
      <Modal>
        <div className={styles.content}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>{title}</p>
            <div className={styles.top} onClick={onClose}>
              <IconCloseTablet />
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.table}>
              <div className={styles.total}>
                <span className={styles.text}>
                  {t('purchase_page.resource_search.results', {
                    records: normalizeNumber({ value: data.length }),
                  })}
                </span>

                {totalWeight != null && (
                  <span className={styles.text}>{`${t(
                    'purchase_page.resource.total'
                  )} ${normalizeNumber({
                    value: totalWeight,
                  })} t`}</span>
                )}
              </div>
              <PurchaseResourceSearchTable data={data} isHighLight />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PurchaseResourceSearchModal;
