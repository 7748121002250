// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useHistory } from 'react-router-dom';
import { Path } from 'routes';
import { Floor } from '../SelectFloorCard';
import { Section } from '../SelectSectionCard';
import styles from './SelectTenantCard.module.scss';
export interface Tenant {
  id: number;
  name: string;
  brand: {
    id: string;
    name: string;
  };
  industry?: string;
  section?: Omit<Section, 'siteId' | 'floors' | 'tenants'>;
  floor?: Omit<Floor, 'sectionId' | 'tenants'>;
  barcode?: string;
  icCards?: {
    id: number;
    card: string;
  }[];
  contactName?: string;
  contactEmail?: string;
  isDashboardEnabled?: boolean;
}
const SelectTenantCard: React.FC<{ tenant: Tenant }> = ({ tenant }) => {
  const history = useHistory();
  return (
    <div
      key={tenant.id}
      className={styles.tenantItem}
      onClick={() => {
        history.push(`${Path.emissionNew}?tenantId=${tenant.id}`);
      }}
    >
      <p title={tenant.name}>{tenant.name}</p>
    </div>
  );
};

export default SelectTenantCard;
