// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import { Form } from 'react-final-form';
import styles from './CreateUpdateSectionTenantInputForm.module.scss';
import { InputDataListField } from '../InputDataListField';
import { validateMaxLength } from 'util/validator';
import { ModalType } from 'util/Enums';
import { createAxios } from 'ts/createAxios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleError } from 'util/errorHandler';
import { useTranslation } from 'react-i18next';

interface Props {
  type: ModalType;
  onSubmit: () => void;
  sectionName: string;
  sectionId: string;
}

type SectionInput = {
  section: string;
};

const CreateUpdateSectionTenantInputForm: React.FC<Props> = ({
  type,
  onSubmit,
  sectionName,
  sectionId,
}) => {
  const api = createAxios();
  const { siteId } = useParams<{ siteId: string }>();
  const { t } = useTranslation();

  const callAPICreateSectionTenant = async (e: SectionInput) => {
    try {
      await api.post('/sections', {
        name: e.section.trim(),
        siteId: siteId,
      });
      onSubmit();
      toast.success(t('messages.M_010'));
    } catch (error) {
      handleError(error);
    }
  };

  const callAPIUpdateSectionTenant = async (e: SectionInput, id: string) => {
    try {
      await api.put(`/sections/${id}`, {
        name: e.section.trim(),
      });
      onSubmit();
      toast.success(t('messages.M_010'));
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className={styles.createBody}>
      <Form<SectionInput>
        initialValues={{ section: sectionName }}
        onSubmit={(e) => {
          if (type === ModalType.add) {
            callAPICreateSectionTenant(e);
          } else {
            callAPIUpdateSectionTenant(e, sectionId);
          }
        }}
        mutators={{
          changeField: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        validate={(values) => {
          return {
            name:
              !values.section || values.section.trim().length === 0
                ? ''
                : undefined,
          };
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} className={styles.formInput}>
            <div className={styles.inputFrame}>
              <InputDataListField
                validate={(value) =>
                  validateMaxLength(value, 100, t('tenant.thead_section'))
                }
                label={t('tenant.thead_section') || 'セクション'}
                placeholder={t('tenant.thead_section') || 'セクション'}
                name="section"
                required
                onSelectSuggestItem={(value) => {
                  props.form.mutators.changeField('section', value);
                }}
                maxlength={100}
              />
            </div>

            <div className={styles.line} />

            <div className={styles.submit}>
              <button
                className={styles.submitButton}
                disabled={props.invalid}
                type="submit"
              >
                {type === ModalType.add
                  ? t('common.modal_create.add_button')
                  : t('common.modal_update.edit_button')}
              </button>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

export default CreateUpdateSectionTenantInputForm;
