// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { IconAddCircleOutline } from 'components/atoms/icons/IconAddCircleOutline';
import { IconChevronRight } from 'components/atoms/icons/IconChevronRight';
import { IconDelete } from 'components/atoms/icons/IconDelete';
import { IconEdit } from 'components/atoms/icons/IconEdit';
import { ChangeEvent, useState } from 'react';
import { FloorItem, SectionItem } from 'util/Types';
import styles from './AdminSectionFloorRowItem.module.scss';

type Props = {
  section: SectionItem;
  floors: FloorItem[];
  onClickEditSection: (section: SectionItem) => void;
  onClickDeleteSection: (section: SectionItem) => void;
  onClickEditFloor: (sectionId: number, floor: FloorItem) => void;
  onClickDeleteFloor: (floor: FloorItem) => void;
  onClickAddFloor: (sectionId: number) => void;
};
const AdminSectionFloorRowItem: React.FC<Props> = ({
  section,
  floors,
  onClickEditSection,
  onClickDeleteSection,
  onClickEditFloor,
  onClickDeleteFloor,
  onClickAddFloor,
}) => {
  const [isOpenFloors, setIsOpenFloors] = useState(false);
  return (
    <>
      <tr key={section.id}>
        <td
          className={styles.name}
          onClick={() => floors.length > 0 && setIsOpenFloors(!isOpenFloors)}
        >
          <div className={styles.wraperName}>
            {floors.length > 0 && (
              <div
                className={`${
                  isOpenFloors ? styles.arrowDown : styles.arrowRight
                }`}
              >
                <IconChevronRight />
              </div>
            )}
            <p className={styles.contentName}>{section.name}</p>
          </div>
        </td>
        <td className={styles.action}>
          <div className={styles.contentAction}>
            <div className={styles.actionAdd}>
              <IconAddCircleOutline
                onClick={() => onClickAddFloor(section.id)}
              />
            </div>
            <div className={styles.actionEdit}>
              <IconEdit
                onClick={(e: ChangeEvent) => {
                  e.stopPropagation();
                  onClickEditSection(section);
                }}
              />
            </div>
            <div className={styles.actionDelete}>
              <IconDelete
                onClick={(e: ChangeEvent) => {
                  e.stopPropagation();
                  onClickDeleteSection(section);
                }}
              />
            </div>
          </div>
        </td>
      </tr>
      {isOpenFloors && floors.length > 0
        ? floors.map((floor) => {
            return (
              <tr key={floor.id}>
                <td
                  className={styles.name}
                  onClick={() => setIsOpenFloors(!isOpenFloors)}
                >
                  <p className={styles.contentNameChild}>{floor.name}</p>
                </td>
                <td className={styles.action}>
                  <div className={styles.contentActionChild}>
                    <div className={styles.actionEdit}>
                      <IconEdit
                        onClick={(e: ChangeEvent) => {
                          e.stopPropagation();
                          onClickEditFloor(floor.id, floor);
                        }}
                      />
                    </div>
                    <div className={styles.actionDelete}>
                      <IconDelete
                        onClick={(e: ChangeEvent) => {
                          e.stopPropagation();
                          onClickDeleteFloor(floor);
                        }}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            );
          })
        : null}
    </>
  );
};

export default AdminSectionFloorRowItem;
