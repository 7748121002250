// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Tab } from 'components/molecules/Tab';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import PurchaseTemplate from 'components/templates/PurchaseTemplate';
import PurchaseHistoryResourceManagement from 'components/organisms/PurchaseHistoryResourceManagement';
import PurchaseHistoryProductRegistration from 'components/organisms/PurchaseHistoryProductRegistration';

const PurchaseHistories = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <PurchaseTemplate
      title={t('purchase_page.histories.header_title')}
      className={styles.histories}
    >
      <Tab
        className={styles.tabAcceptance}
        tabItems={[
          t('purchase_page.histories.tab_left_heading'),
          t('purchase_page.histories.tab_right_heading'),
        ]}
        defaultTabIndex={history.location.hash == '#1' ? 1 : 0}
        renderContent={({ index }) => (
          <div>
            {index === 0 && (
              <PurchaseHistoryResourceManagement
                active={history.location.hash === '#0'}
              />
            )}

            {index === 1 && (
              <PurchaseHistoryProductRegistration
                active={history.location.hash === '#1'}
              />
            )}
          </div>
        )}
      />
    </PurchaseTemplate>
  );
};

export default PurchaseHistories;
