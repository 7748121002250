// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import { ModalType } from 'util/Enums';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import {
  baseCompressionSiteForm,
  CompressionSiteItem,
} from 'util/siteManagementTypes';
import { ModalSiteState } from 'pages/AdminSiteListManagement/AdminSiteListManagement';
import {
  fetchAcceptanceGrades,
  fetchAcceptanceMaterials,
  fetchGrades,
  fetchMaterialTypeNames,
  fetchProvinceDistrictSuggestion,
  getDetailCompressionSite,
} from 'apis/operator_site/siteApi';
import uniqBy from 'lodash/uniqBy';
import CreateUpdateCompressionSiteForm from 'components/molecules/CreateUpdateCompressionSiteForm';
import {
  fetchMachineTypeSuggestions,
  fetchPackingStyleSuggestions,
} from 'apis/commons/suggestsApi';

interface Props {
  createUpdateSiteModal: ModalSiteState;
  onClose?: () => void;
  onCreateCompressionSite: (
    createCompressionSiteForm: CompressionSiteItem
  ) => void;
  onUpdateCompressionSite: (
    createCompressionSiteForm: CompressionSiteItem
  ) => void;
}

const CreateUpdateCompressionsSiteModal: React.FC<Props> = ({
  createUpdateSiteModal,
  onClose,
  onCreateCompressionSite,
  onUpdateCompressionSite,
}) => {
  const { t } = useTranslation();
  const [inputForm, setInputForm] = useState<CompressionSiteItem>(
    baseCompressionSiteForm
  );
  const [provinceSuggestions, setProvinceSuggestions] = useState<string[]>([]);
  const [districtSuggestions, setDistrictSuggestions] = useState<string[]>([]);
  const [machineTypeSuggestions, setMachineTypeSuggestions] = useState<
    string[]
  >([]);
  const [enrollableGradeSuggestions, setEnrollableGradeSuggestions] = useState<
    string[]
  >([]);
  const [acceptanceGradeSuggestions, setAcceptanceGradeSuggestions] = useState<
    string[]
  >([]);
  const [enrollableTypeSuggestions, setEnrollableTypeSuggestions] = useState<
    string[]
  >([]);
  const [acceptanceMaterialSuggestions, setAcceptanceMaterialSuggestions] =
    useState<string[]>([]);
  const [packingStyleSuggestions, setPackingStyleSuggestions] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (
      createUpdateSiteModal.type === ModalType.update &&
      createUpdateSiteModal?.siteEditing &&
      createUpdateSiteModal?.siteEditing.id
    ) {
      getDetailCompressionSite(createUpdateSiteModal.siteEditing.id, (data) => {
        if (data) {
          setInputForm({
            ...baseCompressionSiteForm,
            ...data,
            machineType: data.machineType
              .filter((item) => item !== '')
              .map((item) => item),
            enrollableGrade: data.enrollableGrade.map((item) => item.name),
            enrollableType:
              data?.enrollableType?.map((item) => item.name) || [], //CHECK
            enrollableTypeDefault: data.enrollableType,
            wastePermitDefault: data?.wastePermitUrl,
            enrollableGradeDefault: data.enrollableGrade,
            address: data.address || '',
            province: data.siteAddresses[0]?.province || '',
            district: data.siteAddresses[0]?.district || '',
            images: data?.imageUrls
              ? data.imageUrls.map((item) => {
                  return {
                    name: item,
                    file: '',
                  };
                })
              : [],
            wastePermit: data?.wastePermitUrl,
            latitude: data.latitude ? data.latitude : '',
            longitude: data.longitude || '',
            postalCode: data.postalCode || '',
          });
        }
      });
    }
  }, [createUpdateSiteModal]);

  useEffect(() => {
    fetchProvinceDistrictSuggestion(false, (data) => {
      if (data) {
        setProvinceSuggestions(uniqBy(data.map((item) => item.province)));
        setDistrictSuggestions(uniqBy(data.map((item) => item.district)));
      }
    });
    fetchMachineTypeSuggestions('Compressions', (data) => {
      if (data) {
        setMachineTypeSuggestions(data.map((item) => item.machineType));
      }
    });
    fetchGrades((data) => {
      if (data) {
        setEnrollableGradeSuggestions(data.map((item) => item.grade));
      }
    });

    fetchAcceptanceGrades((data) => {
      if (data) {
        setAcceptanceGradeSuggestions(data.map((item) => item.acceptanceGrade));
      }
    });
    fetchAcceptanceMaterials((data) => {
      if (data) {
        setAcceptanceMaterialSuggestions(
          data.map((item) => item.acceptanceMaterial)
        );
      }
    });
    fetchMaterialTypeNames((data) => {
      if (data) {
        setEnrollableTypeSuggestions(data.map((item) => item.materialType));
      }
    });
    fetchPackingStyleSuggestions('Compressions', (data) => {
      if (data) {
        setPackingStyleSuggestions(data.map((item) => item.packingStyle));
      }
    });
  }, []);

  return (
    <div className={`${styles.createUpdateSiteModal}`}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {createUpdateSiteModal.type === ModalType.add
                ? t('site_management.modal.create_heading')
                : t('site_management.modal.edit_heading')}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <CreateUpdateCompressionSiteForm
            provinceSuggestions={provinceSuggestions}
            districtSuggestions={districtSuggestions}
            machineTypeSuggestions={machineTypeSuggestions}
            enrollableGradeSuggestions={enrollableGradeSuggestions}
            acceptanceGradeSuggestions={acceptanceGradeSuggestions}
            acceptanceMaterialSuggestions={acceptanceMaterialSuggestions}
            enrollableTypeSuggestions={enrollableTypeSuggestions}
            packingStyleSuggestions={packingStyleSuggestions}
            onCreateCompressionSite={onCreateCompressionSite}
            onUpdateCompressionSite={onUpdateCompressionSite}
            initForm={inputForm}
            setInputForm={setInputForm}
            type={createUpdateSiteModal.type}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateUpdateCompressionsSiteModal;
