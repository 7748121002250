// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './SelectDateModal.module.scss';
import './Calendar.scss';
import { Modal } from 'components/atoms/Modal';
import { useState, useEffect } from 'react';
import { getFormatDate, getSlaFormatYearMonthDate } from 'ts/formatDate';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import { EMPTY_DATE } from 'components/molecules/CalendarTextField';
import { useTranslation } from 'react-i18next';
import { IconClose } from 'components/atoms/icons/IconClose';
import IconCalendarPrev from 'components/atoms/icons/IconCalendarPrev';
import IconCalendarNext from 'components/atoms/icons/IconCalendarNext';
import IconCalendarTablet from 'components/atoms/icons/IconCalendarTablet';
import dayjs from 'dayjs';

export type DownloadModalProps = {
  isOpen: boolean;
  onSave: (dates: string[]) => void;
  onCancelClick: () => void;
  defaultDates: Date[];
};

export const SelectDateModal = (props: DownloadModalProps) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  useEffect(() => {
    setStartDate(
      getFormatDate(props.defaultDates[0].toString(), 'YYYY/MM/DD') || ''
    );
    setEndDate(
      getFormatDate(props.defaultDates[1].toString(), 'YYYY/MM/DD') || ''
    );
  }, [props.defaultDates]);

  const changeDate = (dates: Array<Date>) => {
    setStartDate(getFormatDate(dates[0].toString(), 'YYYY/MM/DD'));
    if (dates.length > 1 && dates[1]) {
      setEndDate(
        getFormatDate(dayjs(dates[1]).startOf('day').toString(), 'YYYY/MM/DD')
      );
    } else {
      setEndDate('');
    }
  };

  const saveDates = () => {
    props.onSave([
      getSlaFormatYearMonthDate(startDate.toString()),
      getSlaFormatYearMonthDate(dayjs(endDate).startOf('day').toString()),
    ]);
  };
  return (
    <section
      className={`${styles.downloadModal} ${
        props.isOpen ? styles.downloadModalOpen : styles.downloadModalClose
      }`}
    >
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.heading}>
            <div className={styles.top}>
              <IconClose onClick={props.onCancelClick} />
            </div>
          </div>

          <div className={styles.body}>
            <div className={styles.targetDateGroup}>
              <div className={styles.calendarField}>
                <p>{startDate || EMPTY_DATE}</p>
                <IconCalendarTablet />
              </div>
              <p>~</p>
              <div className={styles.calendarField}>
                <p>{endDate || EMPTY_DATE}</p>
                <IconCalendarTablet />
              </div>
            </div>
            <div className={'calendar-tablet'}>
              <Calendar
                defaultValue={[
                  props.defaultDates[0] || new Date(),
                  props.defaultDates[1] || new Date(),
                ]}
                calendarType="US"
                locale={localStorage.getItem('i18nextLng') || 'ja'}
                allowPartialRange={true}
                selectRange={true}
                prev2Label={null}
                next2Label={null}
                prevLabel={<IconCalendarPrev />}
                nextLabel={<IconCalendarNext />}
                minDate={new Date(dayjs().subtract(6, 'year').format())}
                maxDate={new Date()}
                formatDay={(locale, date) => getFormatDate(date, 'D')}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onChange={(activeStartDate, value) => {
                  changeDate(activeStartDate);
                }}
                className="react-calendar-tablet"
              />
            </div>
          </div>
          <div className={styles.modalBottomField}>
            <div className={styles.bottomBtnField}>
              <div className={styles.primaryBtn}>
                <div className={`${styles.downloadButton} ${styles.default}`}>
                  <button
                    onClick={() => {
                      if (!startDate || !endDate) {
                        return false;
                      }
                      saveDates();
                    }}
                    disabled={!startDate || !endDate}
                  >
                    {t('common.button.save')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
};
