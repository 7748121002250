// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import {
  useCreateCompressionDeliveriesMutation,
  useFetchNextStageSitesMutation,
} from 'apis/compressions_user/compressionUserApi';
import styles from './CompressionDelivery.module.scss';
import { useEffect, useState } from 'react';
import { CheckBox } from 'components/atoms/CheckBox';
import { BottomField } from 'components/molecules/BottomField';
import { CompressionDeliveryContent } from 'components/molecules/CompressionDeliveryContent';
import ExpectedDestinationModal from 'components/molecules/ExpectedDestinationModal';
import { CompressionEditModal } from 'components/organisms/CompressionEditModal';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectUser } from 'redux/slices/userSlice';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { addition } from 'util/Calc';
import { Compression, Option } from 'util/Types';
import { isError } from 'util/errorHandler';
import { DATE_FORMAT, LanguageValue } from 'util/ConstantValues';
import { mergeClasses, normalizeNumber } from 'util/commons';
import { IconFilter } from 'components/atoms/icons/IconFilter';
import { SelectField } from 'components/molecules/SelectField';
import { createAxios } from 'ts/createAxios';
import ErrorModal from 'components/molecules/ErrorModal';

interface Props {
  setRefresh: (isRefresh: boolean) => void;
  isRefresh: boolean;
}

const CompressionDelivery: React.FC<Props> = ({ setRefresh, isRefresh }) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const [checkedItems, setCheckedItems] = useState({});
  const [allCheckedFlg, setAllCheckedFlg] = useState<boolean>(false);
  const [totalWeight, setTotalWeight] = useState<number>(0);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [modalError, setModalError] = useState<boolean>(false);
  const [modalExpected, setModalExpected] = useState<boolean>(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [modalNoData, setModalNoData] = useState<boolean>(false);

  const [fnCreateBookingDeliveries] = useCreateCompressionDeliveriesMutation();
  const [fnNextStage] = useFetchNextStageSitesMutation();

  const fnGetNextStage = async (
    siteId: string | null,
    compressionsIds: string[]
  ) => {
    return await fnNextStage({
      siteId,
      compressionsIds,
    });
  };
  const [compressions, setCompressions] = useState<Compression[]>([]);
  const [compressionsOrigin, setCompressionsOrigin] = useState<Compression[]>(
    []
  );
  const [dataFetched, setDataFetched] = useState(false);

  const langLocalStorage = localStorage.getItem('i18nextLng') as LanguageValue;

  useEffect(() => {
    calcTotal();
  }, [checkedItems, setTotalWeight]);

  const calcTotal = () => {
    const checkedList = Object.keys(checkedItems).filter(
      (key) => checkedItems[key] == true
    );

    const total =
      compressions
        ?.filter((compression) =>
          checkedList.includes(compression.compressionId)
        )
        .reduce(
          (prev, compression) => addition(prev, parseFloat(compression.weight)),
          0
        ) || 0;
    setTotalWeight(total);
  };

  const handleChange = (e) => {
    setCheckedItems({
      ...checkedItems,
      [e.target.id]: e.target.checked,
    });
  };

  const uniqueNamesSet = new Set(
    compressionsOrigin.map((item) => item.description)
  );

  const materialNameOption = Array.from(uniqueNamesSet).map((name) => ({
    value: name,
    label: name,
  }));

  const callGetCompressionsApi = async (description?: string) => {
    const res = await createAxios().get('/compressions', {
      params: { description },
    });
    setCompressions(res.data);
    if (!dataFetched) {
      setCompressionsOrigin(res.data);
      setDataFetched(true);
    }
  };

  const handleSubmitFilter = (value?: string) => {
    callGetCompressionsApi(value);
    setCheckedItems({});
    setAllCheckedFlg(false);
  };

  useEffect(() => {
    callGetCompressionsApi();
    setDataFetched(false);
  }, [isRefresh]);

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const submit = async () => {
    try {
      const records: Compression[] = Object.entries(checkedItems).reduce(
        (rs: Compression[], [key, value]) => {
          if (value) {
            const matchingItem = compressions?.find(
              (x) => x.compressionId === key
            );
            if (matchingItem) {
              rs.push(matchingItem);
            }
          }
          return rs;
        },
        []
      );

      const filter = records.filter(
        (x) => x?.description === records[0]?.description
      );
      if (filter.length !== records.length) {
        setModalError(true);
      } else {
        fnGetNextStage(
          user.siteId,
          Object.keys(checkedItems).filter((key) => checkedItems[key] == true)
        )
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((rs: any) => {
            if (rs.data.length > 0) {
              setOptions(rs.data);
              setModalExpected(true);
            } else setModalNoData(true);
          });
      }
    } catch (e) {
      alert(t('receives.signup_messages.failed'));
      setIsEditModalOpen(false);
    }
  };

  const handleSubmit = async (stage: string) => {
    try {
      setModalExpected(false);
      const data = {
        compressionsIds: Object.keys(checkedItems).filter((compressionsId) => {
          return checkedItems[compressionsId];
        }),
        temporaryTargetSiteId: stage,
      };

      const result = await fnCreateBookingDeliveries(data);

      if (isError(result)) return;
      setIsEditModalOpen(false);
      setAllCheckedFlg(false);
      setCheckedItems({});
      setCompressions([]);
      callGetCompressionsApi();
      setRefresh(!isRefresh);
      toast.success(t('messages.M_129'));
    } catch (e) {
      alert(t('receives.signup_messages.failed'));
      setIsEditModalOpen(false);
    }
  };

  const allChecked = () => {
    if (allCheckedFlg) {
      // 全チェック -> 全チェック外し
      Object.keys(checkedItems).forEach(function (key) {
        checkedItems[key] = false;
      });
      setCheckedItems(checkedItems);
    } else {
      compressions.forEach(function (compression) {
        checkedItems[compression.compressionId] = true;
      });
      // 全チェック
      setCheckedItems(checkedItems);
    }
    calcTotal();
    setAllCheckedFlg(!allCheckedFlg);
  };

  useEffect(() => {
    calcTotal();
  }, [checkedItems, setTotalWeight]);

  return (
    <>
      <Form<Compression> onSubmit={() => {}}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={styles.compressionDelivery}>
            <table className={`primary-table ${styles.primaryTable}`}>
              <thead>
                <tr>
                  <th className={styles.checkBox} onClick={allChecked}>
                    {t('common.collective_selection')}&nbsp;
                  </th>
                  <th className={styles.label}>{t('common.content')}</th>
                  <th className={mergeClasses(styles.content, styles.filter)}>
                    <div className={styles.materialName}>
                      <Form onSubmit={() => {}}>
                        {(props) => {
                          return (
                            <form onSubmit={props.handleSubmit}>
                              <div
                                className={mergeClasses(styles.selectName, {
                                  ...(langLocalStorage && {
                                    [styles[langLocalStorage]]: true,
                                  }),
                                })}
                              >
                                <div className={styles.iconFilter}>
                                  <IconFilter />
                                </div>

                                <SelectField
                                  name="materialName"
                                  placeholder={t('common.material_name')}
                                  options={materialNameOption}
                                  value={props.values.language}
                                  onChange={(value) =>
                                    handleSubmitFilter(value.toString())
                                  }
                                  isDarkmode
                                  showValue
                                />
                              </div>
                            </form>
                          );
                        }}
                      </Form>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {compressions?.map((compression, i) => {
                  return (
                    <tr key={i}>
                      <td className={styles.checkBox}>
                        <div className={styles.checkBoxContent}>
                          <CheckBox
                            id={compression.compressionId}
                            checked={
                              checkedItems[compression.compressionId] ?? false
                            }
                            onChange={handleChange}
                          ></CheckBox>
                        </div>
                      </td>
                      <td className={styles.label}>
                        <div className={styles.labelText}>
                          {t('common.date_time')}
                        </div>
                        <div className={styles.labelText}>
                          {t('common.person')}
                        </div>
                        <div className={styles.labelText}>
                          {t('common.material_name')}
                        </div>
                        <div className={styles.labelText}>
                          {t('common.grade')}
                        </div>
                        <div className={styles.labelText}>
                          {t('common.weight')}
                        </div>
                        <div className={styles.labelText}>
                          {t('download.thead_volume_reduction_id')}
                        </div>
                      </td>
                      <td className={styles.content}>
                        <CompressionDeliveryContent
                          contents={getFormatDateWithCurrentTimezone(
                            compression.datetime,
                            DATE_FORMAT.slaYMDHm
                          )}
                        />
                        <CompressionDeliveryContent
                          contents={`${compression.userId}(${
                            compression.userName || ''
                          })`}
                        />
                        <CompressionDeliveryContent
                          contents={compression.description}
                        />
                        <CompressionDeliveryContent
                          contents={compression.grade.name}
                        />
                        <CompressionDeliveryContent
                          contents={`${normalizeNumber({
                            value: compression.weight,
                          })}kg`}
                        />
                        <CompressionDeliveryContent
                          contents={compression.compressionId}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <BottomField>
              <div className={styles.total}>
                <div>{t('compressions.button_label')}</div>
                <div>
                  <span>{t('common.total')}&nbsp;&nbsp;</span>
                  <span>{`${normalizeNumber({ value: totalWeight })}kg`}</span>
                </div>
              </div>
              <div className={styles.bottomBtn}>
                <button
                  className={styles.btnEditing}
                  type="button"
                  disabled={compressions?.length === 0}
                  onClick={() => {
                    setIsEditModalOpen(true);
                  }}
                >
                  {t('common.button.volume_reduction_correction')}
                </button>

                <button
                  className={styles.btnRequest}
                  disabled={
                    Object.keys(checkedItems).filter(function (index) {
                      return checkedItems[index];
                    }).length === 0
                  }
                  onClick={() => {
                    submit();
                  }}
                >
                  {t('compressions.button_deliver')}
                </button>
              </div>
            </BottomField>
          </form>
        )}
      </Form>
      {isEditModalOpen && (
        <CompressionEditModal
          isOpen={isEditModalOpen}
          closeEditModal={closeEditModal}
          afterSubmit={() => {
            callGetCompressionsApi();
          }}
          compressionData={
            compressions?.filter((i) => i.siteId === user.siteId) || []
          }
        />
      )}
      {modalExpected && (
        <ExpectedDestinationModal
          title={t('compressions.expected_title')}
          onClose={() => setModalExpected(false)}
          options={options}
          onSubmit={handleSubmit}
          submitButtonLabel={t('compressions.submit_expected')}
        />
      )}
      {modalError && (
        <ErrorModal
          onClose={() => setModalError(false)}
          content={t('compressions.select_same_material')}
        />
      )}

      {modalNoData && (
        <ErrorModal
          onClose={() => setModalNoData(false)}
          content={t('compressions.noData')}
        />
      )}
    </>
  );
};
export default CompressionDelivery;
