// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './EmissionNew.scss';
import { useEffect, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { EmissionList } from 'components/organisms/EmissionList';
import { CategoryItem } from 'util/Types';
import { ItemSize } from 'util/Enums';
import { toast } from 'react-toastify';
import { EmissionInput } from 'components/organisms/EmissionInput';
import { useHistory } from 'react-router';
import { Path } from 'routes';
import { handleError } from 'util/errorHandler';
import { useTranslation } from 'react-i18next';

import SortEmissionsDragDropSection, {
  EmissionBySizeData,
  emissionBySizeDataFromEmissions,
} from 'components/organisms/SortEmissionsDragDropSection';
import EmissionConfirmLeavePageModal from 'components/molecules/EmissionConfirmLeavePageModal';
import EmissionSuccessModal from 'components/molecules/EmissionSuccessModal';
import {
  selectCategories,
  selectForceReloadSections,
  selectTenants,
  setCategories,
  setForceReloadCategories,
  setForceReloadSection,
  setTenants,
} from 'redux/slices/siteInfoSlice';
import { DbManager } from 'util/DbManager';
import { cacheImageUrls } from 'util/hooks/useInitOfflineSiteData';
import { selectLoading } from 'redux/slices/loadingSlice';
import { LoadingModal } from 'components/molecules/LoadingModal';
import { Category } from 'components/organisms/CustomizeListCategory';
import ErrorModal from 'components/molecules/ErrorModal';
import { API_ENDPOINTS } from 'util/endPoints';

type EmissionCategoryUpdate = {
  id: number;
  size: ItemSize;
  sortIndex: number;
  name: string;
  typeObject: 'category' | 'wasteUnit';
};

const EmissionNew = () => {
  const dispatch = useDispatch();
  const api = createAxios();
  const history = useHistory();
  const user = useSelector(selectUser);
  const categories = useSelector(selectCategories);
  const tenants = useSelector(selectTenants);
  const { offlineInitialized } = useSelector(selectLoading);
  const forceReloadSection = useSelector(selectForceReloadSections);
  const { t } = useTranslation();

  const [inputEmission, setInputEmission] = useState<
    CategoryItem | undefined
  >();
  const [noSiteId, setNoSiteId] = useState<boolean>(false);
  const [
    isShowSortEmissionsDragDropSection,
    setIsShowSortEmissionsDragDropSection,
  ] = useState<boolean>(false);
  const [emissionBySizeData, setEmissionBySizeData] =
    useState<EmissionBySizeData>(emissionBySizeDataFromEmissions([]));
  const [isModifiedEmissionsOrder, setIsModifiedEmissionsOrder] =
    useState<boolean>(false);
  const [isShowConfirmLeavePageModal, setIsShowConfirmLeavePageModal] =
    useState<boolean>(false);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const [isShowSubstituteRegistModal, setIsShowSubstituteRegistModal] =
    useState<boolean>(false);

  const callCategoryApi = async (forceReload?: boolean) => {
    if (!offlineInitialized) {
      return;
    }

    if (!forceReload || !navigator.onLine) {
      const cachedSites = await DbManager.sites.toArray();

      if (cachedSites.length) {
        const cachedCategories = await DbManager.categories
          .orderBy('sortIndex')
          .toArray();
        dispatch(setCategories(cachedCategories));

        return;
      }
    }

    api
      .get('/emissions/categories')
      .then(async (response) => {
        const data = (response.data as (CategoryItem & Category)[]) || [];
        dispatch(setCategories(data));

        const cachedCategories = await DbManager.categories
          .where('id')
          .anyOf(data.map(({ id }) => id))
          .toArray();

        const cachedWasteUnitMapper = cachedCategories.reduce(
          (
            rs: Record<string, CategoryItem[]>,
            { id, wasteUnitsOneCategory }
          ) => {
            if (wasteUnitsOneCategory) {
              rs[id] = wasteUnitsOneCategory;
            }

            return rs;
          },
          {}
        );

        await DbManager.categories.clear();
        await DbManager.categories.bulkAdd(
          data.map((cat) => ({
            ...cat,
            ...(cachedWasteUnitMapper[cat.id.toString()] && {
              wasteUnitsOneCategory: cachedWasteUnitMapper[cat.id.toString()],
            }),
            sortIndex: cat.sortIndex ?? 0,
          }))
        );

        const imgUrls: string[] =
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          response.data?.reduce((rs: string[], item: any) => {
            if (item?.imageUrl) {
              rs.push(item.imageUrl);
            }

            return rs;
          }, []) ?? [];
        await cacheImageUrls(imgUrls);

        dispatch(
          setForceReloadCategories(response.data.map((x) => x.id) || [])
        );
      })
      .catch(() => {
        toast.error(t('messages.M_013'));
      });
  };

  const callTenantApi = async (forceReload?: boolean) => {
    if (!offlineInitialized) {
      return;
    }

    if (!forceReload || !navigator.onLine) {
      const cachedSites = await DbManager.sites.toArray();

      if (cachedSites.length) {
        const cachedTenants = await DbManager.tenants.orderBy('name').toArray();
        dispatch(setTenants(cachedTenants));

        return;
      }
    }

    api
      .get(API_ENDPOINTS.TENANTS, {
        params: {
          siteId: user.siteId,
        },
      })
      .then(async (response) => {
        dispatch(setTenants(response.data.tenants));

        await DbManager.tenants.clear();
        await DbManager.tenants.bulkAdd(response.data.tenants);
      })
      .catch((error) => handleError(error));
  };

  const reload = () => {
    callCategoryApi(true);
    callTenantApi(true);
  };

  const emissionCategoriesUpdateManyApi = async (
    emissions: EmissionCategoryUpdate[],
    successCallback: () => void
  ) => {
    try {
      await api.patch('/emissions/categories', {
        categories: emissions,
      });
      successCallback();
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    if (!user.siteId) {
      setNoSiteId(true);
    }

    if (offlineInitialized) {
      if (categories.length === 0) {
        callCategoryApi();
      }

      if (tenants.length === 0) {
        callTenantApi();
      }
    }
  }, [offlineInitialized]);

  useEffect(() => {
    if (navigator.onLine && forceReloadSection) {
      callCategoryApi(forceReloadSection).then(() => {
        dispatch(setForceReloadSection(!forceReloadSection));
      });
    }
  }, [forceReloadSection]);

  useEffect(() => {
    setEmissionBySizeData(emissionBySizeDataFromEmissions(categories));
  }, [categories]);

  useEffect(() => {
    const onBeforeUnload = (event) => {
      if (isModifiedEmissionsOrder) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isModifiedEmissionsOrder]);

  const handleClickSortButton = () => {
    setIsShowSortEmissionsDragDropSection(true);
  };

  const handleChangeEmissionsOrder = (isModified: boolean) => {
    setIsModifiedEmissionsOrder(isModified);
  };

  const handleSubmitEmissionsOrder = (
    emissionBySizeData: EmissionBySizeData
  ) => {
    let newEmissionTypes: CategoryItem[] = [];
    let emissionCategories: EmissionCategoryUpdate[] = [];
    const sortIndexBySize = {
      [ItemSize.LARGE]: 0,
      [ItemSize.MEDIUM]: 0,
      [ItemSize.SMALL]: 0,
    };

    [ItemSize.LARGE, ItemSize.MEDIUM, ItemSize.SMALL].forEach(
      (itemSize: ItemSize) => {
        newEmissionTypes = newEmissionTypes.concat(
          emissionBySizeData[itemSize].map((emission) => ({
            ...emission,
            size: itemSize,
          }))
        );
        emissionCategories = emissionCategories.concat(
          emissionBySizeData[itemSize].map((emission) => ({
            id: +emission.id,
            name: emission.name,
            size: itemSize,
            typeObject: emission.typeObject,
            sortIndex: ++sortIndexBySize[itemSize],
          }))
        );
      }
    );

    emissionCategoriesUpdateManyApi(emissionCategories, () => {
      dispatch(setCategories(newEmissionTypes));
      setIsModifiedEmissionsOrder(false);
      setIsShowSuccessModal(true);
    });
  };

  const handleCloseConfirmLeavePageModal = () => {
    setIsShowConfirmLeavePageModal(false);
  };

  const handleSubmitConfirmLeavePageModal = () => {
    setIsShowConfirmLeavePageModal(false);
    setIsShowSortEmissionsDragDropSection(false);
    setIsModifiedEmissionsOrder(false);
  };

  const handleSubmitSuccessModal = () => {
    callCategoryApi(true);
    setIsShowSuccessModal(false);
    setIsShowSortEmissionsDragDropSection(false);
  };

  const prevFunction = () => {
    if (inputEmission) {
      setInputEmission(undefined);
    } else if (isShowSortEmissionsDragDropSection && isModifiedEmissionsOrder) {
      setIsShowConfirmLeavePageModal(true);
    } else if (
      isShowSortEmissionsDragDropSection &&
      !isModifiedEmissionsOrder
    ) {
      setIsShowSortEmissionsDragDropSection(false);
    } else {
      history.push(Path.selectTenant);
    }
  };

  if (!offlineInitialized) {
    return (
      <LoadingModal
        zIndex={100000}
        hasCash={true}
        isOpen={!offlineInitialized}
      />
    );
  }

  if (noSiteId) {
    return (
      <PrimaryTemplate
        prevFunction={
          inputEmission || user.isShowTenants ? prevFunction : undefined
        }
        className="emission-new"
        hasLogo
      >
        <div className="errors mt--px-30">
          {t('emission_new.no_site_1')}
          <br />
          {t('emission_new.no_site_2')}
        </div>
      </PrimaryTemplate>
    );
  }

  return (
    <PrimaryTemplate
      prevFunction={
        inputEmission || user.isShowTenants ? prevFunction : undefined
      }
      className="emission-new"
      hasLogo
    >
      {!inputEmission && isShowSubstituteRegistModal && (
        <ErrorModal
          content={t('emission_new.substitute_regist_warning') || ''}
          onClose={() => {
            setIsShowSubstituteRegistModal(false);
          }}
        />
      )}

      {!inputEmission &&
        (isShowSortEmissionsDragDropSection ? (
          <div className="emission-new__sort-emissions-drag-drop-section">
            <SortEmissionsDragDropSection
              emissionBySizeData={emissionBySizeData}
              onChange={handleChangeEmissionsOrder}
              onSubmit={handleSubmitEmissionsOrder}
            />

            {isShowConfirmLeavePageModal && (
              <EmissionConfirmLeavePageModal
                onClose={handleCloseConfirmLeavePageModal}
                onSubmit={handleSubmitConfirmLeavePageModal}
              />
            )}

            {isShowSuccessModal && (
              <EmissionSuccessModal onSubmit={handleSubmitSuccessModal} />
            )}
          </div>
        ) : (
          <EmissionList
            inputTypes={categories}
            onClickSortButton={handleClickSortButton}
            onClickRefreshButton={reload}
            onSelected={(item) => {
              if (item.typeObject === 'category') {
                history.push(
                  `${Path.emissionNew}/${item.id}${window.location.search}`
                );
              } else if (item.substituteRegistRelations?.length) {
                setIsShowSubstituteRegistModal(true);
              } else {
                setInputEmission(item);
              }
            }}
          />
        ))}

      {inputEmission && (
        <EmissionInput
          emissionType={inputEmission}
          afterSubmit={() => {
            setInputEmission(undefined);
          }}
        />
      )}
    </PrimaryTemplate>
  );
};

export default EmissionNew;
