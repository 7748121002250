// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import { messageErrors } from './messageErrors';

export type Codes = keyof typeof messageErrors;

export function msg(code: Codes, ...args: (string | number)[]): string {
  const rawMsg = messageErrors[code] as string;

  if (!rawMsg) {
    return 'エラー';
  }

  return rawMsg.replace(/{(\d+)}/g, function (match, number) {
    const param = args[number];

    return param ? `${param}` : match;
  });
}
