// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
interface Props {
  onClick?: () => void;
}
export const IconKey: React.FC<Props> = ({ onClick }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && onClick}
    >
      <path
        d="M4.25 14.5C3.90625 14.5 3.61188 14.3787 3.36688 14.136C3.12188 13.8933 2.99958 13.602 3 13.2619V7.07143C3 6.73095 3.1225 6.43938 3.3675 6.19671C3.6125 5.95405 3.90667 5.83292 4.25 5.83333H4.875V4.59524C4.875 3.73889 5.17979 3.00883 5.78938 2.40505C6.39896 1.80127 7.13583 1.49959 8 1.5C8.86458 1.5 9.60167 1.80189 10.2112 2.40567C10.8208 3.00944 11.1254 3.7393 11.125 4.59524V5.83333H11.75C12.0937 5.83333 12.3881 5.95467 12.6331 6.19733C12.8781 6.44 13.0004 6.73137 13 7.07143V13.2619C13 13.6024 12.8775 13.894 12.6325 14.1366C12.3875 14.3793 12.0933 14.5004 11.75 14.5H4.25ZM4.25 13.2619H11.75V7.07143H4.25V13.2619ZM6.125 5.83333H9.875V4.59524C9.875 4.07937 9.69271 3.64087 9.32812 3.27976C8.96354 2.91865 8.52083 2.7381 8 2.7381C7.47917 2.7381 7.03646 2.91865 6.67188 3.27976C6.30729 3.64087 6.125 4.07937 6.125 4.59524V5.83333Z"
        fill="#878787"
      />
    </svg>
  );
};
