// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { uniqBy } from 'lodash';
import { ModalSiteState } from 'pages/AdminSiteListManagement/AdminSiteListManagement';
import { useState } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { PdfType, REGEX_OBJECT } from 'util/ConstantValues';
import { ModalType } from 'util/Enums';
import {
  RecycleMachine,
  SiteDataItem,
  SiteRecycleItem,
} from 'util/siteManagementTypes';
import {
  validateExactLength,
  validateFieldEN,
  validateId,
  validateMaxLength,
  validatePostCodeForm,
  validateRequiredField,
  validateStringField,
} from 'util/validator';
import { InputDataListField } from '../InputDataListField';
import { InputTagField } from '../InputTagField';
import UploadFiles from '../UploadFiles';
import styles from './CreateUpdateSiteRecycleInputForm.module.scss';
import { normalizeNumberString } from 'util/commons';
import PostalCodeForm from '../PostalCodeForm';

interface Props {
  suggestion: {
    provinceSuggestion: string[];
    districtSuggestion: string[];
    acceptanceGrades: string[];
    materialTypes: string[];
    grades: string[];
  };
  createUpdateSiteRecycleModal: ModalSiteState;
  onCreateRecycleSite: (createFloorForm: SiteRecycleItem) => void;
  onUpdateRecycleSite: (
    updateFloorForm: SiteRecycleItem,
    siteEditing: SiteDataItem
  ) => void;
  intitalValue: SiteRecycleItem;
  machineType?: RecycleMachine[];
  acceptanceMaterialSuggestions: string[];
  packingStyleSuggestions: string[];
}

const CreateUpdateSiteRecycleInputForm: React.FC<Props> = ({
  suggestion,
  createUpdateSiteRecycleModal,
  onCreateRecycleSite,
  onUpdateRecycleSite,
  machineType,
  intitalValue,
  acceptanceMaterialSuggestions,
  packingStyleSuggestions,
}) => {
  const { t } = useTranslation();
  const { siteEditing } = createUpdateSiteRecycleModal;
  const [step, setStep] = useState<1 | 2>(1);
  const [file, setFile] = useState<File>();
  const [formHasError, setFormHasError] = useState(false);

  const handleChangeStep = () => {
    setStep(step === 1 ? 2 : 1);
    setFormHasError(false);
  };

  const stepValidate = (currentStep: number, values: SiteRecycleItem) => {
    if (currentStep === 1) {
      return {
        id: validateId(t('site_management.site_id'), 20, values.id),
        name: validateStringField(
          t('download.thead_base_name'),
          100,
          values.name
        ),
        ediSubscriberNumber: validateExactLength({
          value: values.ediSubscriberNumber,
          length: 7,
        }),
        ediConfirmationKey: validateExactLength({
          value: values.ediConfirmationKey,
          length: 8,
        }),
        ...validatePostCodeForm(values),
      };
    }

    // currentStep = 2
    return {
      enrollableType: validateFieldEN(
        t('site_management.modal.field_waste_permits'),
        values.enrollableType
      ),
      enrollableGrade: validateRequiredField(
        values.enrollableGrade,
        t('site_management.enrollable_grade') || ''
      ),
      recycleMethod: validateRequiredField(
        values.recycleMethod,
        t('site_management.recycle_method') || ''
      ),
      acceptanceMaterial: validateRequiredField(
        values.acceptanceMaterial,
        t('site_management.receivable_material_name') || ''
      ),
      packingStyles: validateRequiredField(
        values.packingStyles,
        t('site_management.packing_styles') || ''
      ),
      prevYearInputAmount: validateRequiredField(
        values.prevYearInputAmount,
        t('site_management.prev_year_input_amount') || ''
      ),
      prevYearScope1: validateRequiredField(
        values.prevYearScope1,
        t('site_management.prev_year_scope_1') || ''
      ),
      prevYearScope2: validateRequiredField(
        values.prevYearScope2,
        t('site_management.prev_year_scope_2') || ''
      ),
    };
  };

  const renderStep1 = (
    props: FormRenderProps<SiteRecycleItem, Partial<SiteRecycleItem>>
  ) => (
    <>
      {/* Step 1 */}
      {/* id */}
      <div className={styles.inputFrame}>
        <InputDataListField
          validate={(value) =>
            validateMaxLength(value, 20, t('site_management.site_id'))
          }
          label={t('site_management.site_id')}
          placeholder={t('site_management.site_id') || ''}
          name="id"
          disabled={createUpdateSiteRecycleModal.type === ModalType.update}
          required
          maxlength={20}
        />
      </div>

      {/* name */}
      <div className={styles.inputFrame}>
        <InputDataListField
          validate={(value) =>
            validateMaxLength(value, 100, t('download.thead_base_name'))
          }
          label={t('download.thead_base_name')}
          placeholder={t('download.thead_base_name') || ''}
          name="name"
          required
          maxlength={100}
        />
        <InputDataListField
          validate={(value) =>
            validateFieldEN(t('site_management.modal.site_name_en'), value, 100)
          }
          label={t('site_management.modal.site_name_en')}
          placeholder={t('site_management.modal.site_name_en')}
          name="englishName"
          required
          maxlength={100}
          validRegex={REGEX_OBJECT.halfWidthCharacter}
        />
      </div>

      <div className={styles.inputFrame}>
        <InputDataListField
          label="EDI加入者番号"
          placeholder="EDI加入者番号"
          name="ediSubscriberNumber"
          maxlength={7}
          validRegex={REGEX_OBJECT.numberStringOnly}
        />
        <InputDataListField
          label="EDI事業場番号"
          placeholder="EDI事業場番号"
          name="ediOfficeNumber"
          maxlength={3}
          validRegex={REGEX_OBJECT.number}
        />
      </div>

      <PostalCodeForm
        provinceSuggestions={suggestion.provinceSuggestion}
        districtSuggestions={suggestion.districtSuggestion}
        formProps={props}
        mode={createUpdateSiteRecycleModal.type}
        prefix={
          <InputDataListField
            label="EDI確認キー"
            placeholder="EDI確認キー"
            maxlength={8}
            name="ediConfirmationKey"
            validRegex={REGEX_OBJECT.numberStringOnly}
          />
        }
      />
    </>
  );

  const renderStep2 = (
    props: FormRenderProps<SiteRecycleItem, Partial<SiteRecycleItem>>
  ) => (
    <div className={styles.inputFrame}>
      {/* Step2 */}
      {/* acceptanceGrade */}
      <InputTagField
        label={t('site_management.grade_handled')}
        placeholder={t('site_management.grade_handled') || ''}
        name="acceptanceGrade"
        maxlength={100}
        tagValues={props.values.acceptanceGrade}
        dataSuggestions={suggestion.acceptanceGrades.map((item) => ({
          name: item,
        }))}
        onChangeData={(tags) => {
          props.form.mutators.setValue('acceptanceGrade', uniqBy(tags));
        }}
      />
      {/* enrollableType */}
      <InputTagField
        tagValues={props.values.enrollableType}
        label={t('site_management.enrollable_type')}
        placeholder={t('site_management.enrollable_type') || ''}
        name="enrollableType"
        maxlength={100}
        required
        dataSuggestions={suggestion.materialTypes.map((item) => ({
          name: item,
        }))}
        uniqueTags
        onChangeData={(tags) => {
          props.form.mutators.setValue('enrollableType', tags);
        }}
        validRegex={REGEX_OBJECT.halfWidthCharacter}
      />
      {/* enrollableGrade */}
      <InputTagField
        tagValues={props.values.enrollableGrade}
        label={t('site_management.enrollable_grade')}
        placeholder={t('site_management.enrollable_grade') || ''}
        name="enrollableGrade"
        required
        dataSuggestions={suggestion.grades.map((item) => {
          return {
            name: item,
          };
        })}
        uniqueTags
        maxlength={100}
        onChangeData={(tags) => {
          props.form.mutators.setValue('enrollableGrade', tags);
        }}
      />
      {/* recycleMethod */}
      <InputTagField
        label={t('site_management.recycle_method')}
        placeholder={t('site_management.recycle_method') || ''}
        name="recycleMethod"
        required
        maxlength={100}
        uniqueTags
        tagValues={props.values.recycleMethod}
        onChangeData={(tags) => {
          props.form.mutators.setValue('recycleMethod', tags);
          console.log(props.values.recycleMethod);
        }}
      />
      {/* machineType */}
      <InputTagField
        label={t('site_management.recycle_machine')}
        placeholder={t('site_management.recycle_machine') || ''}
        name="machineType"
        dataSuggestions={machineType?.map((item) => ({
          name: item.recycleMachine,
        }))}
        uniqueTags
        maxlength={100}
        tagValues={props.values.machineType}
        onChangeData={(tags) => {
          props.form.mutators.setValue('machineType', tags);
        }}
      />
      {/* Upload pdf */}
      <div className={styles.provinceWrap}>
        <UploadFiles
          label={t('site_management.modal.field_pdf') || ''}
          content={t('common.upload_pdf')}
          defaultFile={
            createUpdateSiteRecycleModal.type === ModalType.update
              ? intitalValue?.wastePermit
              : ''
          }
          onChangePhoto={(e) => {
            props.form.mutators.setValue('wastePermit', e ?? '');
          }}
          onError={setFormHasError}
          fileTypes={PdfType}
          accept=".pdf"
          exFile={{
            file: file,
            setFile,
          }}
        />
      </div>
      {/* receivableMaterialName */}
      <InputTagField
        label={t('site_management.receivable_material_name')}
        placeholder={t('site_management.receivable_material_name') || ''}
        name="acceptanceMaterial"
        required
        maxlength={100}
        tagValues={props.values.acceptanceMaterial}
        dataSuggestions={acceptanceMaterialSuggestions.map((item) => ({
          name: item,
        }))}
        uniqueTags
        onChangeData={(tags) => {
          props.form.mutators.setValue('acceptanceMaterial', tags);
        }}
      />
      {/* packingStyles */}
      <InputTagField
        label={t('site_management.packing_styles')}
        placeholder={t('site_management.packing_styles') || ''}
        name="packingStyles"
        required
        maxlength={100}
        tagValues={props.values.packingStyles}
        dataSuggestions={packingStyleSuggestions.map((item) => ({
          name: item,
        }))}
        uniqueTags
        onChangeData={(tags) => {
          props.form.mutators.setValue('packingStyles', tags);
        }}
      />
      {/* staffTel */}
      <InputDataListField
        label={t('site_management.staff_tel')}
        placeholder={t('site_management.staff_tel')}
        name="staffTel"
        maxlength={15}
        validRegex={REGEX_OBJECT.number}
      />
      {/* prevYearInputAmount */}
      <InputDataListField
        label={t('site_management.prev_year_input_amount')}
        placeholder={t('site_management.prev_year_input_amount')}
        name="prevYearInputAmount"
        validRegex={REGEX_OBJECT.decimalNumberWithDot5}
        endExtraText="kg"
        required
      />
      {/* prevYearScope1 */}
      <InputDataListField
        label={t('site_management.prev_year_scope_1')}
        placeholder={t('site_management.prev_year_scope_1')}
        name="prevYearScope1"
        validRegex={REGEX_OBJECT.decimalNumberWithDot5}
        endExtraText="kg-CO2"
        required
      />
      {/* prevYearScope2 */}
      <InputDataListField
        label={t('site_management.prev_year_scope_2')}
        placeholder={t('site_management.prev_year_scope_2')}
        name="prevYearScope2"
        validRegex={REGEX_OBJECT.decimalNumberWithDot10}
        endExtraText="kg-CO2"
        required
      />
    </div>
  );

  const renderSubmitButton = (
    props: FormRenderProps<SiteRecycleItem, Partial<SiteRecycleItem>>
  ) => (
    <button
      className={styles.submitButton}
      disabled={props.invalid || formHasError}
      type="button"
      onClick={(e) => {
        e.preventDefault();
        step === 1 ? handleChangeStep() : props.handleSubmit();
      }}
    >
      {step === 1
        ? t('site_management.modal_create_site.button_next')
        : createUpdateSiteRecycleModal.type === ModalType.add
        ? t('site_management.modal_create_site.button_create')
        : t('site_management.modal_update_site.button_update')}
    </button>
  );

  return (
    <div className={styles.createBody}>
      <Form<SiteRecycleItem>
        initialValues={intitalValue}
        onSubmit={(value) => {
          const record = {
            ...value,
            prevYearInputAmount: value.prevYearInputAmount
              ? normalizeNumberString(String(value.prevYearInputAmount))
              : value.prevYearInputAmount,
            prevYearScope1: value.prevYearScope1
              ? normalizeNumberString(String(value.prevYearScope1))
              : value.prevYearScope1,
            prevYearScope2: value.prevYearScope2
              ? normalizeNumberString(String(value.prevYearScope2))
              : value.prevYearScope2,
          };

          if (createUpdateSiteRecycleModal.type === ModalType.add) {
            onCreateRecycleSite(record);
          } else {
            siteEditing && onUpdateRecycleSite(record, siteEditing);
          }
        }}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        validate={(values) => stepValidate(step, values)}
      >
        {(props) => {
          return (
            <form className={styles.formInput}>
              {/* fix cache render ID -> materials_handled */}
              {step === 1 && renderStep1(props)}
              {step === 2 && renderStep2(props)}
              <div className={styles.submit}>
                {step === 2 && (
                  <button
                    className={styles.nextButton}
                    type="button"
                    onClick={handleChangeStep}
                  >
                    {t('site_management.modal_create_site.button_back')}
                  </button>
                )}
                {renderSubmitButton(props)}
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default CreateUpdateSiteRecycleInputForm;
