import CompressionBookingItem from 'components/molecules/CompressionBookingItem';
import { useTranslation } from 'react-i18next';
import { TCompressionBooking } from 'types/compression';
import { mergeClasses } from 'util/commons';
import styles from './CompressionBooking.module.scss';

type CompressionBookingProps = {
  compressions: TCompressionBooking[];
};

const CompressionBooking = ({ compressions }: CompressionBookingProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.bookingContainer}>
      <div className={styles.heading}>
        <div
          className={mergeClasses(
            styles.headingItem,
            styles.w65,
            styles.textLeft
          )}
        >
          {t('compressions.content')}
        </div>
        <div className={mergeClasses(styles.headingItem, styles.w35)}>
          {t('compressions.qr')}
        </div>
      </div>
      <div className={styles.items}>
        {compressions.map((compression, index) => (
          <CompressionBookingItem compression={compression} key={index} />
        ))}
      </div>
    </div>
  );
};

export default CompressionBooking;
