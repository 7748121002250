// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import CreateUpdateSectionTenantInputForm from 'components/molecules/CreateUpdateSectionTenantInputForm/CreateUpdateSectionTenantInputForm';
import { ModalSectionState } from 'pages/AdminSectionFloorManagement';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalType } from 'util/Enums';
import styles from './CreateUpdateSectionTenantModal.module.scss';

interface Props {
  onClose: () => void;
  onSubmit: () => void;
  createUpdateSectionModal: ModalSectionState;
}

const CreateUpdateSectionTenantModal: React.FC<Props> = ({
  onClose,
  onSubmit,
  createUpdateSectionModal,
}) => {
  const { t } = useTranslation();

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <div className={styles.createUpdateSectionTenantModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {createUpdateSectionModal.type === ModalType.add
                ? t('tenant.modal_create_section.title')
                : t('tenant.modal_update_section.title')}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <CreateUpdateSectionTenantInputForm
            sectionId={createUpdateSectionModal.sectionId}
            sectionName={createUpdateSectionModal.sectionName}
            type={createUpdateSectionModal.type}
            onSubmit={handleSubmit}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateUpdateSectionTenantModal;
