// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import styles from './index.module.scss';

interface Props {
  id?: string;
  className?: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent) => void;
  type?: 'dark' | 'default';
}

export const CustomCheckBoxV2 = ({
  id,
  checked,
  onChange,
  type,
}: React.PropsWithChildren<Props>) => {
  return (
    <div
      className={`${styles.customCheckBox}  ${
        type === 'dark' ? styles.dark : ''
      }`}
    >
      <input
        id={id}
        checked={checked}
        onChange={(e) => onChange(e)}
        type="checkbox"
      />
      <label htmlFor={id}></label>
    </div>
  );
};
